<template>
  <div class="card mb-4"> 
    <div class="card-header">
      <h6 class="card-title" v-if="user.id == null">Nuevo usuario</h6>
      <h6 class="card-title" v-else>Editar usuario </h6>
      <b-button variant="primary size-sm" v-if="user.id != null" @click="resetForm">
        Nuevo usuario
      </b-button> 
    </div>

    <b-card-body>
      <form class="form" @submit.prevent>
        <b-form-group>
          <label for="input-live">Nombre Completo:</label>
          <b-form-input id="input-live" type="text" v-model="user.name"
            aria-describedby="input-live-help input-live-feedback" placeholder="Nombre completo..." trim></b-form-input>
        </b-form-group>

        <b-form-group>
          <label for="input-live">Email:</label>
          <b-form-input id="input-live" class="email" v-model="user.email"
            aria-describedby="input-live-help input-live-feedback" placeholder="Email" trim  autocomplete="off"/>
        </b-form-group>

        <b-form-group>
          <label for="input-live">Contraseña:</label>
          <b-form-input id="input-live" class="form-control" type="password" v-model="user.password"
            aria-describedby="input-live-help input-live-feedback" placeholder="Contraseña..." trim autocomplete="new-password"/>
        </b-form-group>
  
        <b-form-group>
          <label for="input-live">Confirmar Contraseña:</label>
          <b-form-input id="input-live" class="form-control" type="password"
            v-model="user.password_confirmation" aria-describedby="input-live-help input-live-feedback"
            placeholder="Confirmar Contraseña..." trim autocomplete="off"/>
        </b-form-group>

        <b-form-group>
          <label for="input-live">
            Sucursal:
          </label>
          <b-form-select v-model="user.branchoffice_id" ref="select">
            <b-form-select-option v-for="branchOffice in branchOffices" :key="branchOffice.id"
              :value="branchOffice.id">{{ branchOffice.name }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
    
        <b-form-group>
          <label class="control-label" for="input-live">
            Rol de acceso:
          </label>
          <b-form-select v-model="user.role_id" ref="select">
            <b-form-select-option v-for="role in roles" :key="role.id"
              :value="role.id">{{ role.name }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
        
        <b-form-group>
          <label class="control-label">Cajas</label>
            <treeselect class="multiList" v-model="user.funds_linked"
              :options="funds"
              :multiple="true"
              :flat="true"
              :autoSelectAncestors="true"
              :autoDeselectDescendants="true"
              :open-on-click="true"
              value-consists-of="ALL_WITH_INDETERMINATE"
            />
        </b-form-group>
      </form>
    </b-card-body>
    <div class="card-footer">
      <a @click="saveUser()" 
        href="#import_products"
        data-toggle="tab">
        <b-button variant="orange" size="lg">Guardar usuario</b-button>
      </a>

    </div>
  </div>

</template>
<script>
import Treeselect from '@riophae/vue-treeselect'


export default {
  components: {Treeselect},
  props: ['userId'],
  mounted() {
    this.getRolesData();
    if (this.userId != null) {
      this.getUser(this.userId);
    } else {
      this.resetForm();
    }
    this.getFunds();
  },
  data: function () {
    return {
      modo: 'nuevo',
      user: {
        name: '',
        email: '',
        password: null,
        password_confirmation: null,
        branchoffice_id: null,
        role_id: null,
        funds_linked:[],
      },
      emptyUser: {
        name: '',
        email: '',
        password: null,
        password_confirmation: null,
        branchoffice_id: null,
        role_id: 1,
        funds_linked:[],
      },
      roles: [],
      branchOffices: [],
      selected: null,
      funds: [],
    };
  },
  computed: {
    
  },
  watch: {
    userId: function(value) {
      if (value != null) {
        this.getUser(value);
      } else {
        this.resetForm();
      }            
    }
  },
  methods: {
    getRolesData() {
      const vm = this;
      this.$http
        .get('/api/configs/role/getData', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          vm.roles = response.data.data.roles;
          vm.branchOffices = response.data.data.branchoffices;
        });
    },
    saveUser() {
      var vm = this;
      if (vm.user.id != null) {
        vm.$http
          .post(`/api/users/edit/${vm.user.id}`, vm.user)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      } else {
        this.$http
          .post("/api/users/add", vm.user)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      }
    },
    getUser(id) {
      var vm = this;
      this.modo = "editar";
      this.$http.get(`/api/users/show/${id}`)
      .then((response) => {
        // const { id, description, name, permissions } = response.data.data;
        // const allowed = permissions.map(perm => perm.id);
        // vm.role = { id, description, name, allowed };
        const user = response.data.data;
        vm.user = {...user, branchoffice_id: user.bo_id};

      });
    },
    getFunds() {
      var vm = this;
      this.$http.get(`/api/funds/getFunds`)
      .then((response) => {
        vm.funds = response.data.data.map(function (item) {
          item.label = item.name
          return item
        });
      });
    },
    resetForm() {
      this.user = Object.assign({}, this.emptyUser);
    },
  }
}

</script>