<template>
  <b-container fluid>
    <div class="loading" v-if="isLoading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Transportes</h4>
      </b-col>
    </div>
    <!-- ----------------------- BUSCADOR -------------------------- -->
    <b-row>
      <b-col cols="12 col-md-3">
        <b-form inline class="d-flex justify-content-start my-2"
          @submit.stop.prevent="doSearch"
        >
          <div class="input-group">
            <input
              v-model="doSearch"
              type="search"
              placeholder="Buscar..."
              class="form-control"
            >
          </div>
        </b-form>
      </b-col>
    </b-row>
    <!-- ----------------------- TABLAS -------------------------- -->
    <b-row>
      <b-col cols="md-8">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          @edit="getTransport"
          @delete="deleteTransport"
          ref="table"
        />
      </b-col>
      <!-- ----------------------- FORMULARIO -------------------------- -->
      <b-col cols="md-4">
        <div class="card mb-4">
          <div class="card-header">
            <h6 v-if="transport.id == null">
              Nuevo transporte
            </h6>
            <h6 v-else>Editar transporte</h6>
            <b-button variant="outline-primary size-sm" 
              v-if="transport.id != null" @click="transport = emptyTransport">
              Nuevo transporte
            </b-button>
          </div>
          <div class="card-body">
            <b-form @submit.prevent>
              <b-form-group class="form-group">
                <label class="control-label mt-3" for="input-live">Nombre:</label>
                <b-form-input
                  id="input-live"
                  v-model="transport.name"
                  :state="nameState"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Nombre del transporte"
                  trim
                ></b-form-input>                  
                <b-form-invalid-feedback
                  v-if="nameState === false"
                  id="input-live-feedback"
                >
                  El nombre debe tener un mínimo de 3 letras
                </b-form-invalid-feedback>                  
                <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
              </b-form-group>
                <div class="row">
                  <div class="col-md-6">
                      <b-form-group>
                        <label class="control-label" for="input-live">Dirección:</label>
                        <b-form-input
                            id="input-live"
                            v-model="transport.address"
                            :state="nameState"
                            aria-describedby="input-live-help input-live-feedback"
                            placeholder="Dirección"
                            trim
                        ></b-form-input>                  
                        <b-form-invalid-feedback
                            v-if="nameState === false"
                            id="input-live-feedback"
                        >El nombre debe tener un mínimo de 3 letras
                        </b-form-invalid-feedback>                  
                        <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                      </b-form-group>
                  </div>
                  <div class="col-md-6">
                      <b-form-group>
                        <label class="control-label" for="input-live">Código Postal:</label>
                        <b-form-input
                            id="input-live"
                            v-model="transport.postalcode"
                            :state="nameState"
                            aria-describedby="input-live-help input-live-feedback"
                            placeholder="Código Postal"
                            trim
                        ></b-form-input>                  
                        <b-form-invalid-feedback
                            v-if="nameState === false"
                            id="input-live-feedback"
                        >El nombre debe tener un mínimo de 3 letras
                        </b-form-invalid-feedback>                  
                        <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                      </b-form-group>
                  </div>                  
                </div>
                <div class="row">
                  <div class="col-md-6">
                      <b-form-group>
                        <label class="control-label" for="input-live">Localidad:</label>
                        <b-form-input
                            id="input-live"
                            v-model="transport.city"
                            :state="nameState"
                            aria-describedby="input-live-help input-live-feedback"
                            placeholder="Localidad"
                            trim
                        ></b-form-input>                  
                        <b-form-invalid-feedback
                            v-if="nameState === false"
                            id="input-live-feedback"
                        >El nombre debe tener un mínimo de 3 letras
                        </b-form-invalid-feedback>                  
                        <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                      </b-form-group>
                  </div>
                  <div class="col-md-6">
                      <b-form-group>
                        <label class="control-label" for="input-live">Provincia:</label>
                        <b-form-select class="form-control" v-model="transport.province">
                            <b-form-select-option :value="null" disabled>Seleccionar</b-form-select-option>
                            <b-form-select-option value="Capital Federal">CABA</b-form-select-option>
                            <b-form-select-option value="Buenos Aires">Buenos Aires</b-form-select-option>
                            <b-form-select-option value="Catamarca">Catamarca</b-form-select-option>
                            <b-form-select-option value="Chaco">Chaco</b-form-select-option>
                            <b-form-select-option value="Chubut">Chubut</b-form-select-option>
                            <b-form-select-option value="Corrientes">Corrientes</b-form-select-option>
                            <b-form-select-option value="Cordoba">Córdoba</b-form-select-option>
                            <b-form-select-option value="Entre Rios">Entre Ríos</b-form-select-option>
                            <b-form-select-option value="Formosa">Formosa</b-form-select-option>
                            <b-form-select-option value="Jujuy">Jujuy</b-form-select-option>
                            <b-form-select-option value="La Pampa">La Pampa</b-form-select-option>
                            <b-form-select-option value="La Rioja">La Rioja</b-form-select-option>
                            <b-form-select-option value="Mendoza">Mendoza</b-form-select-option>
                            <b-form-select-option value="Misiones">Misiones</b-form-select-option>
                            <b-form-select-option value="Neuquen">Neuquen</b-form-select-option>
                            <b-form-select-option value="Rio Negro">Río Negro</b-form-select-option>
                            <b-form-select-option value="Salta">Salta</b-form-select-option>
                            <b-form-select-option value="San Juan">San Juan</b-form-select-option>
                            <b-form-select-option value="San Luis">San Luis</b-form-select-option>
                            <b-form-select-option value="Santa Cruz">Santa Cruz</b-form-select-option>
                            <b-form-select-option value="Santa Fe">Santa Fe</b-form-select-option>
                            <b-form-select-option value="Santiago del Estero">Santiago del Estero</b-form-select-option>
                            <b-form-select-option value="Tierra del Fuego">Tierra del Fuego</b-form-select-option>
                            <b-form-select-option value="Tucuman">Tucumán</b-form-select-option>
                        </b-form-select>                  
                        <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                      </b-form-group>
                  </div>                  
                </div>
                <div class="row">
                  <div class="col-md-6">
                      <b-form-group>
                        <label class="control-label" for="input-live">Teléfono 1:</label>
                        <b-form-input
                            id="input-live"
                            v-model="transport.phone_1"
                            :state="nameState"
                            type="number"
                            aria-describedby="input-live-help input-live-feedback"
                            placeholder="Teléfono 1"
                            trim
                        ></b-form-input>                  
                        <b-form-invalid-feedback
                            v-if="nameState === false"
                            id="input-live-feedback"
                        >El nombre debe tener un mínimo de 3 letras
                        </b-form-invalid-feedback>                  
                        <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                      </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group>
                      <label class="control-label" for="input-live">Teléfono 2:</label>
                      <b-form-input
                          id="input-live"
                          v-model="transport.phone_2"
                          :state="nameState"
                          type="number"
                          aria-describedby="input-live-help input-live-feedback"
                          placeholder="Teléfono 2"
                          trim
                      ></b-form-input>                  
                      <b-form-invalid-feedback
                          v-if="nameState === false"
                          id="input-live-feedback"
                      >El nombre debe tener un mínimo de 3 letras
                      </b-form-invalid-feedback>                  
                      <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                    </b-form-group>
                  </div>                  
                </div>
                <b-form-group>
                  <label class="control-label" for="input-live">Destinos</label>
                  <b-form-textarea
                      id="input-live"
                      v-model="transport.routes"
                      aria-describedby="input-live-help input-live-feedback"
                      trim
                      rows="3"
                      max-rows="6"
                  ></b-form-textarea>                    
                </b-form-group>
                <b-form-group>
                  <label class="control-label" for="input-live">Observaciones</label>
                  <b-form-textarea
                      id="input-live"
                      v-model="transport.notes"
                      aria-describedby="input-live-help input-live-feedback"
                      trim
                      rows="3"
                      max-rows="6"
                  ></b-form-textarea>
                </b-form-group> 
              </b-form>
            </div>
            <div class="card-footer">
            <a v-if="nameState === null" @click="saveTransport()">
              
              <b-button variant="orange" size="lg" data-toggle="tab" >Guardar transporte</b-button>
            </a>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import { mapState } from 'vuex';

export default {
    components: {
      VdtnetTable
    },
    data: function() {
      return {
        doSearch: '',   
        isLoading: false,
        opts: {
          language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
          ajax: {
            url: process.env.VUE_APP_BASE_URL+"/api/transports/list",
            type: "GET",
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          },
        },
        fields: {
          name: { label: "Nombre", searchable: true },
          address: { label: "Dirección" },
          phone_1: { label: "Teléfono 1" },
          phone_2: { label: "Teléfono 2" },
          // posible error con la siguiente linea (id)
          id: { visible: false },
          postalcode: { visible: false },
          city: { visible: false }, 
          province: { visible: false }, 
          routes: { visible: false }, 
          notes: { visible: false }, 
          actions: {
            isLocal: true,
            label: "Acciones",
            width: "123px",
            defaultContent:
              '<div class="divActions">' +
              '<button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">'+
              '<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>'+
              '<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>'+
              '</svg></button>' +
              '<button data-action="delete" data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
              '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
              '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
              '</svg></button>' +
              "</div>",
          },
        },
        transport: {
          id: null,
          name: "",
          address: "",
          phone_1: "",
          phone_2: "",
          postalcode: "",
          city: "", 
          province: "", 
          routes: "", 
          notes: "", 
        },
        emptyTransport: {
          id: null,
          name: "",
          address: "",
          phone_1: "",
          phone_2: "",
          postalcode: "",
          city: "", 
          province: "", 
          routes: "", 
          notes: "", 
        },        
      };
    },
    computed: {
      nameState() {
        return this.transport.name.length < 3 && this.transport.name.length > 0
          ? false
          : null;
      },
      ...mapState('transports',{
        transports: state => state.transports,
      }),    
    },
    created() {
    this.$store.dispatch('transports/getTransports')
  },
    watch: {
      doSearch(newValue, oldValue) {
        if(newValue.length >= 4 || oldValue.length >= 4) {
          this.$refs.table.search(this.doSearch)
        }
        if(newValue === '' && oldValue.length > 0) {
          this.$refs.table.search(this.doSearch)
        }
      },
    },
    methods: {
      getTransport(data) {
        var vm = this;
        vm.isLoading = true
        this.$http.get("/api/transports/getTransport/" + data.id).then((response) => {
          vm.transport = response.data.data;
          vm.isLoading = false
        });
      },
      saveTransport() {
      this.isLoading = true
      if (this.transport.id != null) {
        this.$http
          .post("/api/transports/edit/" + this.transport.id, this.transport)
          .then((response) => {
            
            this.$refs.table.reload();
            this.transport = this.emptyTransport;
            this.isLoading = false
          });
        } else {
          this.$http.post("/api/transports/add", this.transport).then((response) => {
            
            this.$refs.table.reload();
            this.transport = this.emptyTransport;
            this.isLoading = false
          });
        }
      },
      deleteTransport(data) {
        this.isLoading = true
        // borra de la db, no queda con deleted_at
      if (confirm("Esta seguro que desea borrar este transporte?") == true) {
        this.$http
          .post("/api/transports/delete", { id: data.id })
          .then((response) => {
            
            if (response.data.code == 201) {
              this.$refs.table.reload();
              this.transport = this.emptyTransport;
              this.isLoading = false
            }
          });
        }
      },
    }
  }
</script>