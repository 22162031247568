<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Cajas</h4>
      </b-col>
    </div>
    <!-- -------------- CABECERA ------------------ -->
    <div class="row statsreport text-center">

      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="stat">
          <div class="number">{{ formatoMoneda2Dec(balanceTotal) }}</div>
          <div class="desc">Saldo actual en efectivo y bancos</div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 border-r-l">
        <div class="stat">
          <div class="number mr-2">{{ formatoMoneda2Dec(balanceCash) }} -</div>
          <div class="number">{{ formatoDinamicoMonedaUSD(balanceCashUSD) }}</div>
          <div class="desc">Saldo actual en cajas de efectivo</div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="stat">
          <div class="number mr-2">{{ formatoMoneda2Dec(balanceBank) }} -</div>
          <div class="number">{{ formatoDinamicoMonedaUSD(balanceBankUSD) }}</div>
          <div class="desc">Saldo actual en cuentas bancarias</div>
        </div>
      </div>
    </div>
    <!-- -------------------- BUSCADOR -------------------------- -->
    <b-form inline class="d-flex justify-content-between my-2">
      <input
        v-model="doSearch"
        type="search"
        class="form-control input-sm"
        placeholder="Buscar"
        aria-controls="categories"
        />
    </b-form>
    <b-row>
      <b-col cols="md-8">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          @openModal="openModal"
          @updateFund="updateFund"
          ref="table"
          />
      </b-col>
      <b-col cols="md-4">
        <NuevaCaja :fundId="fundIdParent" @confirmsave="refreshTable()" />
      </b-col>
    </b-row>

    <CierreCajaModal v-if="showModal"
        :fund_id="modalFundId"
        @load="loading = true"
        @closeDone="refreshBalances"
        @closemodal="closeModal"/>
      
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import CierreCajaModal from './Cierre-caja-modal.vue'
import NuevaCaja from "./../sub-components/Nueva-caja.vue";
import formatMoneyMixin from "./../../mixins/formatMoneyMixin";
import { mapState, mapGetters } from 'vuex'
import axios from 'axios';
export default {
  components: {
    VdtnetTable,
    NuevaCaja,
    CierreCajaModal
  },
  mixins: [formatMoneyMixin],
   
  data: function () {
    return {
      doSearch: "",
      loading: false,
      fundIdParent: null,
      fund: null,
      funds: [],
      showModal: false,
      modalFundId : null,
      balanceTotal: 0,
      balanceCash: 0,
      balanceBank: 0,
      balanceCashUSD: 0,
      balanceBankUSD: 0,
      balanceTotal: 0,
      dolarBlue: null,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/funds/list",
          type: "GET",
          data: (d) => {
            d.branchoffice_id = this.branchofficeId         
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
      },
      fields: {
        id: { visible: false },
        name: { label: "Nombre", searchable: true },
        type: { label: "Tipo de caja",searchable: true },
        balance: {
          label: "Saldo actual",
          render: (data, type, funds) => { 
            const currency = funds.currency == '$' ? this.formatoMoneda2Dec(funds.balance) : this.formatoDinamicoMonedaUSD(funds.balance);
            const defaultContent = currency;
            return defaultContent;
          }
        },
        actions: {
          isLocal: true,
          label: "Acciones",
          width: "137px",
          render: function (data, type, full) {
            const fundDetailUrl = this.$parent.fundDetailUrl(full.id);
            const button = full.type == "Efectivo" ? `<button data-action="openModal" class="btn btn-sm btn-light mr-2" title="Cerrar caja"><i class="bi bi-key"></i></button>
            <a href="${fundDetailUrl}" class="btn btn-sm btn-light">Ver Detalle</a>` : 
            `<a href="${fundDetailUrl}" class="btn btn-sm btn-light">Ver Detalle</a>`;

            const defaultContent = button;
            return defaultContent;
          },
        },
      },
    };
  },
  computed:{ 
    ...mapState('branchoffices',{
      branchofficeId: state => state.selectedBranchofficeId,
    }),
  },
  mounted() {
    this.getTotals();
    this.getDolarBlue();
  },
  watch: {
    doSearch(newValue, oldValue) {
      if (newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch);
      }
      if (newValue === "" && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch);
      }
    },
    branchofficeId (newValue, oldValue) {
     this.refreshTable();
    },
  },
  methods: {
    refreshTable() {
      var vm = this;
      vm.$refs.table.reload();
    },
    fundDetailUrl(fundId) {
      return `/cajas/detalle-caja/${fundId}`;
    },
    openModal(data){
        this.modalFundId = data.id;
        this.showModal = true;
    },
    closeModal(){
        this.modalFundId = null;
        this.showModal = false;
    },
    refreshBalances(){
      this.refreshTable();
      this.getTotals();
    },
    getTotals() {
      var vm = this;
      vm.balanceTotal = 0;
      vm.balanceCash = 0;
      vm.balanceBank = 0;
      vm.balanceCashUSD = 0;
      vm.balanceBankUSD = 0;
      this.$http.get("/api/funds/list" , {params: this.branchofficeId})
      .then((response) => {
          vm.funds = response.data.data;
      }).then(function () {
        for (let i = 0; i < vm.funds.length; i++) {
          vm.balanceTotal = +vm.balanceTotal;

          if (vm.funds[i].type == "Efectivo") {
            if(vm.funds[i].currency == "$"){
              vm.balanceCash = +vm.balanceCash + +vm.funds[i].balance;
              vm.balanceTotal = +vm.balanceTotal + +vm.funds[i].balance;
            }else{
              vm.balanceCashUSD = +vm.balanceCashUSD + +vm.funds[i].balance;
              vm.balanceTotal = +vm.balanceTotal + (vm.funds[i].balance * vm.dolarBlue);
            }
          }

          if (vm.funds[i].type == "Banco") {
            if(vm.funds[i].currency == "$"){
              vm.balanceBank = +vm.balanceBank + +vm.funds[i].balance;
              vm.balanceTotal = +vm.balanceTotal + +vm.funds[i].balance;
            }else{
              vm.balanceBankUSD = +vm.balanceBankUSD + +vm.funds[i].balance;
              vm.balanceTotal = +vm.balanceTotal + (vm.funds[i].balance * vm.dolarBlue);
            }
          }
        }
      });
    },
    getDolarBlue(){
      var vm = this;
      fetch('https://api.bluelytics.com.ar/v2/latest')
      .then(response => response.json())
      .then(data => vm.dolarBlue = data.blue.value_sell);
    },
    updateFund(data){
      this.fundIdParent = data.id;
    }
  },
};
</script>
