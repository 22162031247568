<template>
    <b-container fluid>

        <b-table-simple sticky-header striped small caption-top responsive>
            <b-thead head-variant="light">
                <b-tr role="row">
                    <b-th>Fecha</b-th>
                    <b-th>#</b-th>
                    <b-th>Proveedor</b-th>
                    <b-th>Monto facturas</b-th>
                    <b-th>Monto pagos</b-th>
                    <b-th>Total imputado</b-th>
                    <b-th>Pago de origen</b-th>
                    <b-th class="col-sm-1"></b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr role="row" v-for="(loc, index) in allocationsList" :key="index" >
                    <b-td>{{ formatoDate2Fecha(loc.created_at) }}</b-td>
                    <b-td>{{loc.id}}</b-td>
                    <b-td>{{ loc.provider_name }}</b-td>
                    <b-td>$ {{parseFloat(loc.debit).toFixed(2)}}</b-td>
                    <b-td>$ {{parseFloat(loc.credit).toFixed(2)}}</b-td>
                    <b-td class="bold">$ {{parseFloat(loc.purchaseAllocation).toFixed(2)}}</b-td>
                    <b-td>
                        <span v-if="loc.origin_receipt!=null">RC {{loc.origin.pos}}-{{loc.origin.number}}</span>
                    </b-td>
                    <b-td class="col-sm-1">
                        <b-button-group>
                            <b-dropdown right variant="light">
                                <b-dropdown-item><router-link :to="'/compras/detalle-imputacion-proveedor/' + loc.id" >Ver detalle</router-link></b-dropdown-item>
                                <b-dropdown-item><a href="javascript:void(0);" @click="deleteAllocation(loc.id)">Desimputar</a></b-dropdown-item>
                            </b-dropdown>
                        </b-button-group>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <!-- PAGINACION-->
        <b-pagination>
            <b-row>
                <b-col cols="sm-12">    
                    <b-ul class="pagination float-right">
                        <b-li class="paginate_button previous" v-bind:class="[{'disabled': page==1}]">
                            <a href="#" v-on:click="prevPage()">Anterior</a>
                        </b-li>

                        <b-li v-if="(page - (pageInterval/2))>0" class="paginate_button">
                            <a href="#" v-on:click="prevPage()">...</a>
                        </b-li>

                        <template v-for="index in totalPages">

                            <b-li :key="index" v-if="inIntervalPage(index)" class="paginate_button" v-bind:class="[{'active': page==index}]">
                                <a href="#" v-on:click="changePage(index)">{{index}}</a>
                            </b-li>

                        </template>

                        <b-li v-if="totalPages>(page + (pageInterval/2))" class="paginate_button">
                            <a href="#" v-on:click="nextPage()">...</a>
                        </b-li>

                        <b-li class="paginate_button next" v-bind:class="[{'disabled': page>=totalPages}]">
                            <a href="#" v-on:click="nextPage()">Siguiente</a>
                        </b-li>
                    </b-ul>
                </b-col>
            </b-row>
        </b-pagination>
    </b-container>
</template>

<script>

import formatDateMixin from "../../../mixins/formatDateMixin";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";

export default {
    props: ['provider','fromDate','toDate'],
    mixins: [formatDateMixin,formatMoneyMixin],
    data() {
        return {
            allocationsList:[],
            page:1,
            rowCount:9,
            totalItems:0,
            totalPages:1,
            pageInterval:22,
        }
    },
    mounted(){
        this.getAllocations();
    },
    methods: {
        getAllocations(){
            var vm= this;
            var outdata = {
                'provider': vm.provider,
                'fromDate':vm.fromDate,
                'toDate':vm.toDate,
                'page':vm.page,
                'rowCount':vm.rowCount
            };
            axios.post('/api/payments/getAllocations', outdata)
            .then(response => {
                if(response.data) {
                vm.allocationsList = response.data.data.list;
                vm.totalItems=response.data.data.totalItems;
              }
            })
            .catch(error => {
              alert(error);
            })
        },
        deleteAllocation(id){
            if (confirm("Esta operación es irreversible, todos los comprobantes volverán al estado anterior de la imputación. ¿Desea continuar?")){
                axios.get('/api/payments/deleteAllocation/'+id)
                .then(response => {
                if(response.data.success) {
                  this.getAllocations();
                  this.$emit('allocationDeleted');
                }else{
                  
                }
            })
            .catch(error => {
              alert(error);
            })
            }
        },
        prevPage(){
            this.page = this.page-1;
            this.getAllocations();
        },
        nextPage(){
            this.page = this.page +1;
            this.getAllocations();
        },
        changePage(data){
            this.page = data;
            this.getAllocations();
        },
        inIntervalPage(index){
            if(this.totalPages<=this.pageInterval){return true;}

            let limitprev = this.page - (this.pageInterval/2);
            let limitnext = this.page + (this.pageInterval/2);

            if(limitprev<0){
                limitprev=0;
                limitnext=this.pageInterval;
            }

            if(index>limitprev && index<limitnext){
                return true;
            }

            return false;
        },
    },
    watch: {
      'client': {
          handler: function (a) {
              this.page=1;
              this.getAllocations();
            }
      },
      'fromDate': {
          handler: function (a) {this.getAllocations();}
      },
      'toDate': {
          handler: function (a) {this.getAllocations();}
      },
      'allocationsList':{
          handler: function(a){
              this.totalPages = Math.ceil(this.totalItems / this.rowCount);
          }
      }
  },

}
</script>
