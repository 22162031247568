<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="loading" v-if="isLoading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Consolidado Anual - Ventas vs. Egresos</h4>
      </b-col>
    </div>
    <!------------- FILTROS ----------------->
    <div class="card bg-light rounded-sm p-3 my-2 border">
      <div class="row d-flex justify-content-between">
        <div class="col col-md-2">
          <b-form-group>
            <b-form-select v-if="fundCategoriesLoaded" v-model="filters.category_id" style="width: 208px;">
              <b-form-select-option :value="null">- Categorías de caja -</b-form-select-option>
              <b-form-select-option v-for="category in filteredCategories" :key="category.id" :value="category.id" v-html="category.name"></b-form-select-option>
            </b-form-select>
            <div v-else>
              Cargando categorías...
            </div>
          </b-form-group>
        </div>
        <!------------- BOTONES ----------------->
        <div class="col col-md-1">
          <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary" value="Exportar CSV">
        </div>
      </div>
    </div>
    <b-row>
      <b-col>
  <!------------- TABLA ----------------->
        <b-table-simple striped small caption-top responsive class="mt-2">
            <b-thead head-variant="light">
                <b-tr>
                    <b-th></b-th>
                    <b-th>Enero</b-th>
                    <b-th>Febrero</b-th>
                    <b-th>Marzo</b-th>
                    <b-th>Abril</b-th>
                    <b-th>Mayo</b-th>
                    <b-th>Junio</b-th>
                    <b-th>Julio</b-th>
                    <b-th>Agosto</b-th>
                    <b-th>Septiembre</b-th>
                    <b-th>Octubre</b-th>
                    <b-th>Noviembre</b-th>
                    <b-th>Diciembre</b-th>
                    <b-th>Consolidado</b-th>
                </b-tr>
            </b-thead>

            <b-tbody>
                <b-tr v-for="(item, index) in incomes" :key="index" :class="isSpecialRow(item)">
                    <b-td>{{ item.name }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.enero) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.febrero) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.marzo) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.abril) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.mayo) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.junio) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.julio) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.agosto) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.septiembre) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.octubre) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.noviembre) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.diciembre) }}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(item.consolidado) }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>

  <script>
  import { mapState } from 'vuex'
  import formatMoneyMixin from "../../mixins/formatMoneyMixin";


  export default {
    mixins: [formatMoneyMixin],
    data: function() {
      return {
        isLoading: false,
        incomes: [],
        filters:{
          category_id:null,
        },
       
      };
    },
    mounted() {
      this.getData();
    },
    computed: {
      ...mapState('fundsCategory',{
        fundsCategories: state => state.fundsCategories,
      }),
      ...mapState('fundsCategory', {
        loading: state => state.loading,
      }),
      fundCategoriesLoaded() {
        return Array.isArray(this.fundsCategories);
      },
      filteredCategories() {
        const filtered = [];
        this.fundsCategories.forEach(category => {
          if(category.type === 'out'){
            filtered.push(category);
          }
        });
        return filtered;
      },
    },
    created () {
      this.$store.dispatch('fundsCategory/getFundsCategories').then(() => {
      })
    },
    methods: {
      getData(){
        var vm= this;
        axios.post('/api/reports/getYearlyConsolidated',vm.filters)
          .then(response => {
            if(response.data.success) {                
              vm.incomes = response.data.data;
            } else {
              vm.incomes = [];
            }
          })
          .catch(error => {
            alert(error);
          });
    },
      exportCSV(){
        let vm = this;
        axios.post('/api/reports/CSVyearlyConsolidated', vm.filters)
        .then(response => {
        });
      },
      isSpecialRow(item) {
        switch (item.name) {
          case 'INGRESOS':
            return 'ingresos-row';
          case 'EGRESOS':
            return 'egresos-row';
          case 'RESULTADO OPERATIVO':
            return 'resultado-operativo-row';
          case 'RENDIMIENTO':
            return 'rendimiento-row';
          default:
            return '';
        }
      },
    },
    watch: {
      'filters.category_id': {handler: function (a) {this.getData();}},
    },
  };
  </script>

  <style>
  .active {
    font-weight: 800;
  }

  .ingresos-row {
    background-color: lightgreen !important; 
  }

  .egresos-row {
    background-color:  rgba(232, 29, 29, 0.796)!important; 
  }

  .resultado-operativo-row {
    background-color: rgb(253, 202, 19) !important; 
  }

  .rendimiento-row {
    background-color: rgb(253, 253, 116) !important;
  }
  </style>