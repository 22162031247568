<template>
  <b-container fluid>
    <div class="row pt-3">
      <h2>
          <label for="">Detalle nota de pedido</label>
      </h2>
    </div>
    <b-card class="bg-light d-flex justify-content-between mb-4 p-0">
      <b-row class="mt-3">
        <!-- ------- DATOS DE NOTA DE PEDIDO -------- -->
        <b-col cols="sm-3" v-if="ordernote.letter != null">
          <b-form-group>
            <h2 class="control-label">FC: {{ordernote.letter}} {{ordernote.pos}}- {{ordernote.number}}</h2>
          </b-form-group>
        </b-col> 
        
        <b-col cols="sm-3">
          <b-form-group>
          <h2 class="control-label">Cliente: {{ordernote.client.name}}</h2>
          </b-form-group>
        </b-col>
        <!-- @if (Auth::user()->branchoffice->options->salesclerk_module && $ordernote->client->clerk!=null) -->
        <b-col cols="sm-3">
          <b-form-group>
              <h2 class="control-label">Vendedor: {{clerkname}}</h2>
          </b-form-group>
        </b-col>
        <!-- @endif -->
        <b-col cols="sm-3">
            <b-form-group>
            <h2 class="control-label">Fecha: {{createdDateFormatted}}</h2>
            </b-form-group>
        </b-col>
        <b-row v-if="ordernote.response != null">
          <b-col cols="md-6">
            <b-form-group>
            <label class="control-label">CAE: {{ordernote.response['CAE']}}</label>
            </b-form-group>
          </b-col>
          <b-col cols="md-6">
            <b-form-group>
            <label class="control-label">CAE Fech. Venc.: {{ordernote.response['CAEFchVto']}}</label>
            </b-form-group>
          </b-col>
        </b-row>
      </b-row>
    </b-card>
    <!-- --------- TABLAS ------ -->
    <b-row>
      <!-- ------ TABLA ------ -->
      <b-col cols="md-8">
        <b-table-simple sticky-header small caption-top responsive>
          <b-thead head-variant="light">
            <b-tr>
            <b-th> Item </b-th>
            <b-th class="col-small"> Cantidad </b-th>
            <b-th class="col-small"> Precio unitario </b-th>
            <b-th class="col-small"> Total </b-th>
              </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="value in ordernote.ordernote_details"
            :key="value.ordernote_id"
            role="row"
            class="add">
            <b-td>{{value.description}}</b-td>
            <b-td>{{value.quantity}}</b-td>
            <b-td>{{formatoDinamicoMoneda(value.price)}}</b-td>
            <b-td>{{formatoDinamicoMoneda(value.price * value.quantity)}}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <!-- ------ TABLA TOTALES ------ -->
      <b-col cols="md-4">
        <div class="card mb-4">
          <div class="card-header">
              <h3>Totales de la venta</h3>
          </div>
          <b-card-body style="font-size: medium;">
            <div v-if="ordernote.discount > 0">
              <b-row>
                <!-- ------ Sub total ------ -->
                <b-col cols="md-6 text-left">
                  SUBTOTAL:
                </b-col>
                <b-col cols="md-6 text-left">
                  <span>{{formatoDinamicoMoneda(ordernote.subtotal + ordernote.discount)}}</span>
                </b-col>
              </b-row>
              <!-- ------ Descuento ------ -->
              <b-row>
                <b-col cols="md-6 text-left">
                  DESCUENTO:
                </b-col>
                <b-col cols="md-6 text-left">
                  <span>{{formatoDinamicoMoneda(ordernote.discount)}}</span>
                </b-col>
              </b-row>
            </div>
            <!-- ------ Sub total ------ -->
            <b-row>
              <b-col cols="md-6 text-left">
                SUBTOTAL:
              </b-col>
              <b-col cols="md-6 text-left">
                <span>{{formatoDinamicoMoneda(ordernote.subtotal)}}</span>
              </b-col>
            </b-row>
            <!-- ---- TOTAL -------- -->
            <b-row style="font-weight: bold;">
              <b-col cols="md-6 text-left mt-3">
                TOTAL:
              </b-col>
              <b-col cols="md-6 text-left mt-3">
                <span id="htmlTotal">{{formatoDinamicoMoneda(ordernote.total)}}</span>
              </b-col>
            </b-row>
            
          </b-card-body>
        </div>
        <!-- ------ NOTA PARA CLIENTE ------ -->
        <b-form-group v-if="ordernote.notes !=''" class="form-group" style="margin-top:20px">
          <label>Nota para el cliente</label>
          <textarea v-model="ordernote.notes" disabled="disabled" name="note" rows="3" class="form-control"></textarea>
          </b-form-group>
      </b-col>
    </b-row>
    <!-- ------ BOTON ------ -->
    <b-row class="receipt-footer d-flex justify-content-end mb-3">
      <DownloadDoc :pdfurl="pdfurl" :open="false" :filename="ordernote.client.name+ordernote.created_at+'.pdf'">
        <b-button variant="outline-primary" size="lg" class="mr-4">Descargar</b-button>
      </DownloadDoc>
      <DownloadDoc :pdfurl="pdfurl" :open="true" :filename="ordernote.client.name+ordernote.created_at+'.pdf'">
        <b-button variant="orange" size="lg">Imprimir</b-button>
      </DownloadDoc>
    </b-row>
  </b-container>
</template>

<script>
import DownloadDoc from "../../global/DownloadDoc";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";

export default {
  components: {
    DownloadDoc
  },
  mixins: [formatMoneyMixin],
  props: ["orderNoteId"],
  
  data: function() {
    return {
       pdfurl: process.env.VUE_APP_BASE_URL+'/api/ordernotes/onPDF/' + this.orderNoteId,
       ordernote: {
          letter: null,
          pos: null,
          number: null,
          client_id: null,
          subtotal: null,
          discount: null,
          taxes: null,
          total: null,
          ordernote_details: [{
            ordernote_id: null,
            description: null,
            quantity: null,
            price: null
          }],
          client: {
            name: null,
            clerk: {
              name: null
            }
          }
        }
    }
  },
  computed: {
    createdDateFormatted() {
      return new Date(this.ordernote.created_at).toLocaleDateString('es-AR')
    },
    clerkname(){
      return this.ordernote.client.clerk != undefined ? this.ordernote.client.clerk.name: ''
    }
  },
  mounted() {
    this.getOrderNoteDetail(this.orderNoteId);
    },
  methods: {
    getOrderNoteDetail(orderNoteId){
        var vm= this;
        this.$http.get('/api/ordernotes/getOrdernote/' + orderNoteId)
          .then(response => {
            if(response.data.success) {
                vm.ordernote = response.data.data;                
                console.log(vm.ordernote)
                console.log(vm.ordernote.ordernote_details)
            }
        })
        .catch(error => {
            alert(error);
        })
    },
  }
};
</script>

<style scoped>

.fix-footer {
  padding-bottom: 100px;
}
.receipt-footer {
  position: fixed;
  bottom:0;
  left:0;
  z-index:100;
  background:rgb(242, 244, 247,0.5);
  border-top: 1px solid #4477BD;
  text-align: right;
  padding:20px;
  width: 100%;
}
.totals {
  margin-bottom: 0;
}

.totals li {
  border-bottom:1px solid #d3d9e4;
  padding:10px 0;
  font-size: 1.75rem;
}

.totals li:first-child {
  padding-top:0;
}

.totals li:last-child {
  border-bottom:0;
  padding-bottom:0;
}

.totals li .form-group .input-group .input-group-addon {
  padding:0 12px;
  background:#fff;
  border-radius: 0px;
  border-left:0;
  border-top:0;
}

.totals li .form-group .input-group input {
  border-radius:0px;
  border-right: 0;
  border-top:0;
  background: #fff;
  height: 20px;
}

.affix {
  margin-right:15px;
}

</style>
-->