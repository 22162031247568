<template>
<b-container>
    <div v-if="receipt" class="ticket">
        <!-- ORIGINAL -->
        <p>
            <br><span id="tipo"></span>
            <span><p>RECIBO DE PAGO</p></span>
            <span><br>COMPROBANTE NO VALIDO COMO FACTURA</span>
            <br>
                <br>{{authUser.branchoffices[0].name}}
                <br>{{authUser.branchoffices[0].business_name}}
                <br>{{authUser.branchoffices[0].address}}
                <br>{{authUser.branchoffices[0].city}}
                <br>{{authUser.branchoffices[0].state}}
        </p>
        <p>
                <br>CUIT: {{authUser.branchoffices[0].cuit}}
                <br>IIBB: {{authUser.branchoffices[0].iibb}}
                <br>{{authUser.branchoffices[0].iva_type}}
                <br>
                <br>Inicio de activ. : {{ moment(authUser.branchoffices[0].startdate).format('DD-MM-YYYY')}}
        </p>
        <br>{{'RECIBO DE PAGO'}} Nro. {{receipt.number}}
        <br>FECHA: {{ moment().format('DD-MM-YYYY') }}
        <br>HORA: {{ moment().format('HH:mm:SS')  }}
        <br>
        <br>CLIENTE: {{receipt.client_name}}
        <table class="mt-3">
            <br>EFECTIVO:{{"".concat(formatoDinamicoMoneda(receipt.total)).padEnd(25, " ")}}
            <br>
            <br>TOTAL:{{" ".concat(formatoDinamicoMoneda(receipt.total)).padEnd(29, " ")}}
            <br>
            <br>SALDO CC:{{" ".concat(formatoDinamicoMoneda(balance)).padEnd(29, " ")}}
            <br>            
            <!--p v-for="tpcheck in sale.tpchecks" :key="tpcheck.id">
            <br>CHEQUE:{{"".concat(formatoDinamicoMoneda(tpcheck.amount)).padEnd(27 ," ")}}
            <br>BANCO: {{tpcheck.name}}
            <br>NUMERO: {{tpcheck.number}}
            </p>
            <p v-id="totalca>0">
            <br>CTA. CTE.:{{"".concat(formatoDinamicoMoneda(totalca)).padEnd(24, " ")}}
            </p-->
        </table>


        <p class="centrado mt-3">¡GRACIAS POR SU COMPRA!<br></p>
    </div>

    <button class="btn btn-lg btn-orange" @click="imprimir">Imprimir</button>
    <button class="btn btn-lg btn-primary ml-5" onclick="window.location.href = '/ventas/cobranzas'">Volver</button>
</b-container>
</template>

<script>
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import { mapState } from 'vuex'

export default {
    props: ['receipt_id'],
    mixins: [formatMoneyMixin],
    data: function() {
    return {
            receipt: null,
            clientId: null,
            balance: null,
        }
    },
    mounted() {
        this.getReceipt(this.receipt_id);
    },
    created () {
      this.$store.dispatch('authUser/getAuthUser')
    },
    computed: {
        ...mapState('authUser',{
         authUser: state => state.authUser,
      }),
      totalca () {
            return this.sale.client_accounts.reduce((partialSum, e) => partialSum + e.amount, 0);
        }
    },
    methods: {
        getReceipt(receiptId){
            var vm=this;
            axios.get('/api/collections/getReceipt/' + receiptId)
            .then(response => {
                if(response.data.success) {
                    vm.receipt = response.data.data;
                    vm.clientId = response.data.data.client_id;
                    vm.updateBalanceValue();
                }
            })
            .catch(error => {
                alert(error);
            })
        },
        imprimir() {
            var ua = navigator.userAgent.toLowerCase();
            var isAndroid = ua.indexOf("android") > -1;

            if (isAndroid) {
                document.getElementById('tipo').innerHTML = 'ORIGINAL';
                window.print();
            }else{
                document.getElementById('tipo').innerHTML = 'ORIGINAL';
                window.print();
            }
        },
        updateBalanceValue(){
        var vm=this;
        this.$http.get('/api/clients/getBalance/' +  vm.clientId)
            .then(response => {
              if(response.data.data) {
                vm.balance = parseFloat(response.data.data);
              }else{
                vm.balanceValue = 0.00;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    },
    
}
</script>

<style>
* {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
}

td,
th,
tr,
table {
    margin: 2px;
    border-top: 1px solid black;
    border-collapse: collapse;
}

td.producto,
th.producto {
    width: 75px;
    max-width: 75px;
}

td.cantidad,
th.cantidad {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

td.precio,
th.precio {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

.centrado {
    text-align: center;
    align-content: center;
}

.ticket {
    color: black;
    width: 155px;
    max-width: 155px;
}

.imglogo {
    max-width: inherit;
    width: inherit;
}

#duplicado{
    display: none !important;
}

@media print {
    .oculto-impresion,
    .oculto-impresion * {
        display: none !important;
        height: 0;
    }
}
</style>