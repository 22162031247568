<template>
    <b-container fluid>
        <b-table-simple sticky-header striped>
            <b-thead head-variant="light">
                <b-tr role="row">
                    <b-th>Fecha</b-th>
                    <b-th>#</b-th>
                    <b-th>Cliente</b-th>
                    <b-th>Monto facturas</b-th>
                    <b-th>Monto pagos</b-th>
                    <b-th>Total imputado</b-th>
                    <b-th>Pago de origen</b-th>
                    <b-th class="col-sm-1"></b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr role="row" v-for="(loc, index) in allocationsList" :key="index" >
                    <b-td>{{ formatDate(loc.created_at) }}</b-td>
                    <b-td>{{loc.id}}</b-td>
                    <b-td>{{ loc.client_name }}</b-td>
                    <b-td>$ {{parseFloat(loc.debit).toFixed(2)}}</b-td>
                    <b-td>$ {{parseFloat(loc.credit).toFixed(2)}}</b-td>
                    <b-td class="bold">$ {{parseFloat(loc.saleAllocation).toFixed(2)}}</b-td>
                    <b-td>
                        <span v-if="loc.origin_receipt!=null">RC {{loc.origin.pos}}-{{loc.origin.number}}</span>
                    </b-td>
                    <b-td>
                        <b-button-group>
                                <b-dropdown right variant="light">
                                    <b-dropdown-item :href="'/ventas/cobranzas/detalle-imputacion/' + loc.id" >Ver detalle</b-dropdown-item>
                                    <b-dropdown-item><a href="javascript:void(0);" @click="deleteAllocation(loc.id)">Desimputar</a></b-dropdown-item>
                                </b-dropdown>
                        </b-button-group>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-pagination>
            <div class="row">
                <div class="col-sm-12">
                    <div class="dataTables_paginate paging_simple_numbers pull_right" id="table_paginate">
                        <ul class="pagination float-right">
                            <li class="paginate_button previous" v-bind:class="[{'disabled': page==1}]">
                                <a href="#" v-on:click="prevPage()">Anterior</a>
                            </li>

                            <li v-if="(page - (pageInterval/2))>0" class="paginate_button">
                                <a href="#" v-on:click="prevPage()">...</a>
                            </li>

                            <template v-for="index in totalPages">

                                <li :key="index" v-if="inIntervalPage(index)" class="paginate_button" v-bind:class="[{'active': page==index}]">
                                    <a href="#" v-on:click="changePage(index)">{{index}}</a>
                                </li>

                            </template>

                            <li v-if="totalPages>(page + (pageInterval/2))" class="paginate_button">
                                <a href="#" v-on:click="nextPage()">...</a>
                            </li>

                            <li class="paginate_button next" v-bind:class="[{'disabled': page>=totalPages}]">
                                <a href="#" v-on:click="nextPage()">Siguiente</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </b-pagination>
    </b-container>
</template>

<script>

import moment from 'moment';

export default {
    props: ['client','fromDate','toDate'],
    data() {
        return {
            allocationsList:[],
            page:1,
            rowCount:9,
            totalItems:0,
            totalPages:1,
            pageInterval:22,
        }
    },
    mounted(){
        this.getAllocations();
    },
    methods: {
        getAllocations(){
            var vm= this;
            var outdata = {
                'client': vm.client,
                'fromDate':vm.fromDate,
                'toDate':vm.toDate,
                'page':vm.page,
                'rowCount':vm.rowCount
            };
            this.$http.post('/api/collections/getAllocations', outdata)
            .then(response => {
                if(response.data) {
                vm.allocationsList = response.data.data.list;
                vm.totalItems=response.data.data.totalItems;
              }
            })
            .catch(error => {
              alert(error);
            })
        },
        deleteAllocation(id){
            if (confirm("Esta operación es irreversible, todos los comprobantes volverán al estado anterior de la imputación. ¿Desea continuar?")){
                this.$http.get('/api/collections/deleteAllocation/'+id)
                .then(response => {
                if(response.data.success) {
                    this.getAllocations();
                    this.$emit('allocationDeleted');
                }else{
                  
                }
            })
            .catch(error => {
              alert(error);
            })
            }
        },
        formatDate(value){
            if (value){
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        prevPage(){
            this.page = this.page-1;
            this.getAllocations();
        },
        nextPage(){
            this.page = this.page +1;
            this.getAllocations();
        },
        changePage(data){
            this.page = data;
            this.getAllocations();
        },
        inIntervalPage(index){
            if(this.totalPages<=this.pageInterval){return true;}

            let limitprev = this.page - (this.pageInterval/2);
            let limitnext = this.page + (this.pageInterval/2);

            if(limitprev<0){
                limitprev=0;
                limitnext=this.pageInterval;
            }

            if(index>limitprev && index<limitnext){
                return true;
            }

            return false;
        },
    },
    watch: {
      'client': {
          handler: function (a) {
              this.page=1;
              this.getAllocations();
            }
      },
      'fromDate': {
          handler: function (a) {this.getAllocations();}
      },
      'toDate': {
          handler: function (a) {this.getAllocations();}
      },
      'allocationsList':{
          handler: function(a){
              this.totalPages = Math.ceil(this.totalItems / this.rowCount);
          }
      }
  },

}
</script>
