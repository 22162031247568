import { render, staticRenderFns } from "./Subnavbar-compras.vue?vue&type=template&id=6f779e17&scoped=true"
import script from "./Subnavbar-compras.vue?vue&type=script&lang=js"
export * from "./Subnavbar-compras.vue?vue&type=script&lang=js"
import style0 from "./Subnavbar-compras.vue?vue&type=style&index=0&id=6f779e17&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f779e17",
  null
  
)

export default component.exports