<template>
  <b-container fluid>
    <div class="loading" v-if="isLoading">Loading&#8230;</div>
    <!-- ----------------------- BUSCAR ------------------------ -->
    <b-row>
      <b-col cols="12 col-md-3">
        <b-form inline class="d-flex justify-content-between my-2"
          @submit.stop.prevent="doSearch">
          <div class="input-group">
            <input
              v-model="doSearch"
              type="search"
              placeholder="Buscar..."
              class="form-control"
            >
          </div>
        </b-form>
      </b-col>
    </b-row>
    <!-- ----------------------- TABLAS ----------------------- -->
    <b-row>
      <b-col cols="md-8">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          @edit="getSerialNumber"
          @delete="deleteSerialNumber"
          @reloaded="tableReloaded"
          ref="table"
        />
      </b-col>
      <!-- ----------------------- FORMULARIO ------------------- -->
      <b-col cols="md-4">
       <EditSerialNumber 
       :serialNumberId="serialNumberId" 
       v-on:saved="editSaved" 
       v-on:saving="editSaving" 
       v-on:loaded="editLoaded" 
       :productId="productId"
       />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import EditSerialNumber from './EditSerialNumber.vue';
let serialNumbersApiRoute = process.env.VUE_APP_BASE_URL+"/api/serialnumbers/";

export default {
  props: ['productId'],
  components: {
    VdtnetTable,EditSerialNumber
  },
  data: function () {
    return {
      
      doSearch: '',
      isLoading: false,
      opts: {
        ajax: {
          url: serialNumbersApiRoute + "list",
          type: "GET",
          headers: {Authorization: "Bearer " + localStorage.getItem("token")},
          data:  {
            product_id : this.productId
          }
        },
      },
      fields: {
        code: {label: "Numero de serie", searchable: true},
        product_name: {label: "Producto", searchable: true},
        provider_name: {label: "Proveedor", searchable: true},
        client_name: {label: "Cliente", searchable: true},
        id: {visible: false},
        actions: {
          isLocal: true,
          label: "Acciones",
          width: "123px",
          defaultContent:
              '<div class="divActions">' +
              '<button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">'+
              '<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>'+
              '<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>'+
              '</svg></button>' +
              '<button data-action="delete" data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
              '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
              '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
              '</svg></button>' +
              "</div>",
        },
      },
      serialNumberId: null,
    };
  },
  watch: {
    doSearch(newValue, oldValue) {
      if (newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch)
      }
      if (newValue === '' && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch)
      }
    },
  },
  methods: {
    emptySerialNumber() { 
      return {
        id: null,
        code: "",
        product_id: this.productId,
        provider_id : null
        }
      },
    getSerialNumber(data) {
      if(this.serialNumberId == data.id) return;
      this.serialNumberId = data.id;
      this.isLoading = true
    },
    deleteSerialNumber(data) {
      if (confirm("Esta seguro que desea borrar?") == true) {
        this.isLoading = true
        this.$http
            .post(serialNumbersApiRoute + "delete", {id: data.id})
            .then((response) => {
              
              if (response.data.code == 200) {
                this.$refs.table.reload();
                this.serialNumber = this.emptySerialNumber();
              }
            });
      }
    },
    tableReloaded(){
       this.isLoading = false;
    },
    editSaved(){
      this.serialNumberId = null;
      this.$refs.table.reload();
    },
    editSaving(){
      this.isLoading = true
    },
    editLoaded(){
      this.isLoading = false
    }
  },
};
</script>

<style>
.divActions {
  float: right;
}

.marginButton {
  margin-left: 10px;
}
</style>
