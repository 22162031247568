<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header row">
            <div class="col-md-12">
              <div v-if="limited">
                <div v-if="( (header.pos!=0) && (total>=10000) && ( (header.letter=='C') || (header.letter=='B')) )" class="row">
                  <div class="col-md-12 text-center">
                    <p><b>¡Recordá que para realizar una factura mayor a $9999,99, tenés que informar a la AFIP todos los datos del cliente!</b></p>
                  </div>
                </div>
              </div>
              <div class="row">
                <b-col class="d-flex justify-content-start">
                <h4>Metodo de pago</h4>
                </b-col>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">
                  <h6>Total: $ {{ round(total,2) }}</h6>
                  <div v-if="payarray.discount>0"><b>a pagar con descuento: $ {{(total - payarray.discount) | formatoMoneda2Dec}}</b><br></div>
                    <div v-if="total>0"><b>pagado en recibo: $ {{round(totalpayed,2)}}</b><br></div>
                </div>
                <div class="col-md-6 pull-right">
                  <b-button v-if='limited' class="modal-default-button" variant="light" size="lg" @click="modified">
                    Modificar
                  </b-button>
                  <b-button class="modal-default-button" variant="light" size="lg" @click="cancel">
                    Cancelar
                  </b-button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">
                  <ul class="list-unstyled">
                    <li><h2>Efectivo: <b>${{ payarray.cash }}</b></h2></li>
                    <li v-if="payarray.discount>0">Descuento: <b>${{ payarray.discount }}</b>
                    </li>
                    <li v-for="tpcheck in payarray.tpchecks" :key="tpcheck.number">
                      Cheque - Numero: <b>{{tpcheck.number}}</b> / Monto: <b>${{ round(tpcheck.amount,2) }}</b>
                    </li>
                    <li v-for="creditcard in payarray.creditcards" :key="creditcard.number">
                      {{creditcard.name}} - Cupon: <b>{{creditcard.coupon}}</b> / Monto <b>${{ round(creditcard.amount,2) }}</b>
                    </li>
                    <li v-for="cc in payarray.accounts" :key="cc.amount">
                      Cuenta corriente - Monto: <b> ${{cc.amount}}</b> / Vencimiento: <b>{{ cc.days }}</b>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <div v-if="limited">
                    <h2 v-if="change>0">Vuelto: $ {{ round(change,2) }}</h2>
                    <h2 v-if="diference!=0">Diferencia: $ {{ round(diference,2) }}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-body">
            <div class="row">
              <!--Desc pago anticipado-->
              <div class="col-2">
                  <div class="form-group">
                      <label class="control-label">Punto de Venta: </label>
                      <select v-if="posnumbers.length > 1" v-model="header.posId" class="form-control">
                          <option v-for="pos in posnumbers" :key="pos.id" :value="pos.id">{{pos.number}} - {{pos.type}} - Suc.{{pos.bo_id}}</option>
                      </select>
                      <input v-else v-model="header.posId" type="text" class="form-control" disabled>
                  </div>
              </div>
              <!--Desc pago anticipado-->
              <div v-if="fromCC" class="col-4">
                  <div class="form-group col-border-full" v-bind:class="[{'disabled-container': discountType==1}]">
                      <input type="radio" v-bind:value="0" v-model="discountType">
                      <label>Descuento por pago anticipado</label>
                      <div class="row" v-bind:class="[{'disabled': discountType==1}]">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">$</span>
                                    </div>

                                      <input v-model.number="discountValue"
                                          v-on:change="setDiscountValue()"
                                          @focus="discountFocus ='value'"
                                          @blur="discountFocus = ''"
                                          type="number" min="0" step="0.01"
                                          class="form-control is-invalid"
                                          v-bind:class="[{'focused': discountFocus=='value'}]"
                                          placeholder="Valor neto"
                                          required>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">%</span>
                                    </div>

                                      <input v-model.number="discountPercent"
                                          v-on:change="setDiscountPercent()"
                                          @focus="discountFocus = 'percent'"
                                          @blur="discountFocus = ''"
                                          type="number" min="0" step="0.01" max="100"
                                          class="form-control is-invalid"
                                          v-bind:class="[{'focused': discountFocus=='percent'}]"
                                          placeholder="Porcentaje"
                                          required>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <span class="footnote">(Este descuento se generará como una nota de crédito)</span>
                  </div>
              </div>
              <!--Desc aplicado en venta-->
              <div v-if="fromCC" class="col-6">
                    <div class="form-group col-border-full" v-bind:class="[{'disabled-container': discountType==0}]">
                        <input type="radio" v-bind:value="1" v-model="discountType">
                        <label>Descuento aplicado en venta:</label>
                        <div class="row" v-bind:class="[{'disabled': discountType==0}]">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text">%</span>
                                      </div>

                                        <input v-model.number="discountOnSale" type="number" placeholder="Porcentaje" min="0" step="0.01" max="100" class="form-control is-invalid" required>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="footnote">(Este descuento NO generará una nota de crédito y sólo afecta el cálculo de comisiones)</span>
                    </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-6">
                <div class="form-group col-border">
                  <label><h2>Efectivo</h2></label>
                  <input v-model.number="cash" type="number" min="0" step="0.01" class="form-control">
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label><h2>Caja destino</h2></label>
                  <select v-model="payarray.fund_id" class="form-control" name="fund_id">
                    <option v-for="fund in funds" :key='fund.id' :value="fund.id">{{fund.name.toUpperCase()}}</option>
                  </select>
                </div>
              </div>
            </div>
            
            <div v-if="showCreditCard" class="row">
              <div class="col-12">
                <credit-card v-on:creditcardcancel="showCreditCard=false" v-on:creditcardsaved="creditCardSave"/>
              </div>
            </div>

            <div v-if="showTpCheck" class="row">
              <div class="col-12">
                <tp-check v-on:tpcheckcancel="showTpCheck=false" v-on:tpchecksaved="tpCheckSave"/>
              </div>
            </div>

            <div v-if="showCc" class="row">
              <client-account v-on:clientAccountSaved="clientAccountSaved" v-on:clientAccountCancel="showCc=false"/>
            </div>

            <!--Nota para cliente-->
            <div class="row">
              <div class="col-12 col-md-6">
                <hr>
                <div class="form-group col-border">
                  
                    <h6>
                      <label for="">Concepto</label>
                    </h6>
                    <textarea v-model="payarray.saleNote" class="form-control" rows="2"></textarea>
                  
                </div>
              </div>
            </div>
          </div>

          <!--FOOTER-->
          <b-row class="modal-footer">
            <b-col class="pull-left" lg="4">
              <button v-if="paymentMethods.cuenta_corriente==1" @click="showCc=!showCc" type="button" class="btn btn-lg btn-light mr-2">Cuenta corriente</button>
              <button v-if="paymentMethods.tarjetas==1" @click="showCreditCard=!showCreditCard" type="button" class="btn btn-lg btn-light mr-2">Tarjetas</button>
              <button v-if="paymentMethods.cheques==1" @click="showTpCheck=!showTpCheck" type="button" class="btn btn-lg btn-light">Cheques</button>
            </b-col>
            <b-col lg="4">
              <button v-if="(round(diference,2)==0) || (limited==0)" type="button" @click="finishReceipt" class="btn btn-lg btn-orange modal-default-button">Finalizar venta</button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
import creditCard from './Credit-card.vue';
import clientAccount from './Client-account.vue';
import tpCheck from './Tp-check.vue';
import formatMoneyMixin from "./../../mixins/formatMoneyMixin";
import roundMixin from "./../../mixins/roundMixin.js";

export default {
  props: ['total', 'paymentMethods', 'header', 'limited', 'fundDefault', 'fromCC'],
  mixins: [formatMoneyMixin , roundMixin ,],
  data: function() {
    return {
      showCreditCard: false,
      showTpCheck: false,
      showCc: false,
      discountFocus:'',
      totalpayed: 0,
      diference: 0,
      change: 0,
      funds: [],
      posnumbers:[],
      payarray: {
        cash: 0,
        fund_id: 0,
        accounts: [],
        creditcards: [],
        tpchecks: [],
        saleNote:'',
        discount:0,
      },
      limitcc: null,
      discountValue: 0,
      discountPercent:0,
      discountOnSale:0,
      discountType:0,
    
    }
  },
  mounted: function () {
    this.payarray.fund_id = this.fundDefault;
    this.payarray.cash = this.total;
    this.getSalepoints();
    this.getFunds();
    this.getLimitCC();
    this.calc();
  },
  watch: {
    payarray: {
      handler: function () {
        this.calc();
      }, deep: true
    },
    'totalpayed': {
        handler: function (a) {this.updateCommissionData();}
    },
    'payarray.discount': {
        handler: function (a) {this.updateCommissionData();}
    },
    // 'discountPercent': {
    //     handler: function (a) {this.setDiscountPercent();}
    // },
    // 'discountValue': {
    //     handler: function (a) {this.setDiscountValue();}
    // },
    'discountOnSale': {
        handler: function (a) {this.setDiscountOnSale();}
    }
  },
  methods: {
    calc: function () {
      this.totalpayed = 0;

      var interestCreditCards = this.payarray.creditcards.map(el => el.interest).reduce((acc, currentValue) => acc + currentValue, 0);
      var totalCreditCards = this.payarray.creditcards.map(el => el.amount).reduce((acc, currentValue) => acc + currentValue, 0);
      var totalTpChecks = this.payarray.tpchecks.map(el => el.amount).reduce((acc, currentValue) => acc + currentValue, 0);
      var totalAccounts = this.payarray.accounts.map(el => el.amount).reduce((acc, currentValue) => acc + currentValue, 0);

      var withoutcash = this.round(totalCreditCards,2) + this.round(interestCreditCards,2) + this.round(totalTpChecks,2) + this.round(totalAccounts,2);

      var cosa = this.total - withoutcash;
      var totalcash = this.payarray.cash;

      this.change = totalcash - cosa;

      this.totalpayed = this.round(totalcash,2) + this.round(withoutcash,2);
      this.diference = this.round(this.total,2) - this.round(this.totalpayed,2);
    },
    creditCardSave: function(obj) {
      this.payarray.creditcards.push(obj);
      this.showCreditCard=false;
      this.$emit('paymentmethodschanged',this.payarray);
    },
    tpCheckSave: function(obj) {
      this.payarray.tpchecks.push(obj);
      this.showTpCheck=false;
    },
    fromdateUpdated: function (date) {
      this.header.service_fromdate = date;
    },
    clientAccountSaved: function (obj) {
      this.payarray.accounts.push(obj);
      this.showCc=false;
    },
    modified: function () {
      this.$emit('paymentmethodschanged',{});
      this.$emit('close');
    },
    cancel: function () {
        if (!this.fromCC){
            if (!this.limited) {
                window.location.href = '/ventas/cc-clientes/detalle/'+this.header.client_id;
            } else {
                window.location.href = '/sales/create';
            }
        }else{
            this.modified();
        }
    },
    finishReceipt: function () {
      this.$emit('paymentmethodsclose',this.payarray);
      this.$emit('close');
    },
    getFunds() {
      var vm = this;
      this.$http.get('/api/funds/list')
            .then(response => {
              if(response.data) {
                vm.funds = response.data.data.filter(fund => fund.type != "Tarjeta de Credito");
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    getSalepoints() {
      var vm = this;
      this.$http.get('/api/configs/pos/list')
            .then(response => {
              if(response.data) {
                vm.posnumbers = response.data.data;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    getLimitCC() {
      this.$http.get('/api/clients/getLimitClientAccount/' + this.header.client_id)
            .then(response => {
              if(response.data) {
                if(response.data.data < 0) {
                  this.limitcc = 0;
                } else {
                  this.limitcc = response.data.data;
                }
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    setDiscountValue() {
      var app = this;
      this.payarray.discount = app.discountValue;
      this.discountPercent= app.round((app.discountValue * 100)/app.total,2) ;
    },
    setDiscountPercent() {
      var app = this;
      app.payarray.discount = app.round((app.discountPercent * app.total)/100,2);
      app.discountValue = app.payarray.discount;
      this.updateCommissionData()
    },
    setDiscountOnSale: function(data){
      var app = this;
      app.payarray.discount = 0;
      app.discountValue = 0;
      app.discountPercent=0;
      this.updateCommissionData()
    },
    updateCommissionData(){
        var comdata = {
            'discountPercent': this.discountPercent,
            'discountOnSale': this.discountOnSale,
            'discountType':this.discountType,
            'totalToCom' : this.totalpayed,
        };
        this.$emit('comissionUpdated',comdata);
    },
    round(a,b){
      return (a);
    }
  },
  computed: {
      cash: {
            get: function () {
                return this.payarray.cash;
            },
            set: function (value) {
                if (value==''){
                    this.payarray.cash=0;
                }else{
                    this.payarray.cash=value;
                }
            }
        },
  },
  components: {
    clientAccount,
    tpCheck,
    creditCard
  }
}
</script>

<style scoped>

  .modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }
  
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .modal-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    overflow-y: scroll;
  }
  
  .modal-header {
    display: flex;
    align-items: center;
  }
  
  .modal-default-button {
    float: right;
    margin-left: 10px;
  }
  
  .modal-footer {
    position: fixed;
    bottom:0;
    left:0;
    z-index:9998;
    background: #F2F4F7;
    border-top: 1px solid #4477BD;
    padding:20px;
    width: 100%;
  }
  
  .modal-enter {
    opacity: 0;
  }
  
  .modal-leave-active {
    opacity: 0;
  }
  
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>



.modal-mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 100%;
  height: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  overflow-y: scroll;
}

.modal-header {
  display: flex;
  align-items: center;
}

.modal-body {
  margin: 20px 0;
}

.col-border {
  border-bottom:1px solid #d3d9e4;
  padding-bottom: 15px;

}

.col-border-full {
  border:1px solid #d3d9e4;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.modal-body th {
  width: 130px;
  margin-right: 15px;
  display: inline-block;
}
.modal-body th:first-child {
  width: 250px;
}

.modal-body th .form-group {
  margin-bottom: 0;
}
.closeCheck{

  border-radius: 20px;
}
.pad{
  display: flex;
  margin-right:20px;
}
.modal-default-button {
  float: right;
  margin-left: 10px;
}

.modal-footer {
  position: fixed;
  bottom:0;
  left:0;
  z-index:9998;
  background: #F2F4F7;
  border-top: 1px solid #4477BD;
  text-align: right;
  padding:20px;
  width: 100%;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.disabled {
  pointer-events: none;
}

.disabled-container {
  color:darkgray;
  background-color:#d3d9e4;
}

.footnote{
    font-size: small;
    font-weight: bold;
    color: darkgray;
}

.focused{
    background-color: #f5c05d;
    font-weight: bold;
    border: 0px solid orange;
}
</style>
