<template>
  <b-container fluid>
    <b-row>  
      <b-col cols="sm-3">
        <label><b>Proveedor</b></label>
        <b-form-group class="my-4">
          <typeahead :name="'provider'" 
          :placeh="'Ingrese proveedor'" 
          :clean-on="false" 
          v-on:choosed="providerChoosed" 
          :default="'null'" 
          :externalsrc="srcClients" 
          limit-chars="4"/>
          <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
        </b-form-group>
      </b-col>

      <b-col cols="sm-2">
        <label><b>Fecha de emisión</b></label>
        <b-form-group v-if="showChoosedate">
          <datepicker-component v-on:datechoosed="dateUpdated"/>
        </b-form-group>
      </b-col>
      <b-col cols="sm-1">
        <label><b>Tipo</b></label>
        <div v-if="header.doctype != 'R'">
          <b-select v-model="header.type" class="form-control select-iva">
              <option value="FC">FC</option>
              <option value="ND">ND</option>
              <option value="NC">NC</option>
          </b-select>
          <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
        </div>
      </b-col>
      <b-col cols="sm-1" v-if="header.doctype != 'R'">
        <label class="control-label"><b>Letra</b></label>
        <b-select v-model="header.letter" class="form-control select-iva">
            <option value="A">A</option>
            <option value="M">M</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="E">E</option>
            <option value="X">X</option>
        </b-select>
        <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
      </b-col>
      <b-col cols="sm-2">
        <b-form-group>
          <label><b>Punto de Venta</b></label>
          <b-form-input v-model="header.pos" type="text" class="form-control"></b-form-input>
          <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
        </b-form-group>
      </b-col>    
      <b-col cols="sm-2">
        <b-form-group>
          <label><b>Número</b></label>
          <b-form-input v-model="header.number" id="number" type="text" class="form-control"></b-form-input>
          <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
        </b-form-group>
      </b-col>    
      <b-col cols="sm-1" v-if="header.doctype != 'R'">
        <b-form-group>
          <label class="control-label">Productos</label>
          <br>
          <input class="prod" v-model="header.loadproducts" type="checkbox"/>
        </b-form-group>
      </b-col>
      
    </b-row>
  </b-container>
</template>

<script>
import typeahead from '../../../global/Typeahead.vue';
import datepickerComponent from '../../../global/Datepicker-component.vue';

export default {
  props: ['company_typetax', 'showChoosedate', 'config'],
  data: function() {
    return {
      header: { 
        provider_id: null, 
        number: null, 
        type: 'FC', 
        letter: null, 
        receipt_date: null, 
        pos: null, 
        loadproducts:  null, 
        doctype: null,
      },
      srcClients: '/api/providers/getProviders'
    }
  },
  mounted: function () {
    this.$emit('headerchanged', this.header);
  },
  watch: {
    'header': { // Esto envia los datos de header cada vez que cambian al receipt donde seran adjuntados al payload
      handler: function (a) {
        this.$emit('headerchanged', this.header);
      }, deep: true,
    },
    config:function(value){
      this.header.doctype = this.config.doctype ;
      this.header.loadproducts = this.config.loadproducts;
    }
  },
  methods: {
    dateUpdated: function (date) {
      var app = this;
      app.header.receipt_date = date;
    },
    providerChoosed(data) {
      this.header.provider_id=data.id;
    }
   },
   components:{
    typeahead,
    datepickerComponent
  }
}
</script>

<style>
  .prod {
    width: 28px;
    height: 28px;
  }
</style>
