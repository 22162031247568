<template>
    <b-container fluid>
        <!-- --------- BUSCADOR -------------- -->
        <div class="row py-2">
            <b-col class="d-flex justify-content-start">
                <h4>Pago a proveedores</h4>
            </b-col>
        </div>
        <!-- --------- BUSCADOR -------------- -->
        <b-row>
            <b-col cols="md-3 mt-1">
                <b-form-group>
                    <label class="control-label"><b>Proveedor:</b></label>
                        <typeahead
                        class="mt-1"
                        :name="'provider'"
                        :placeh="'Busqueda de proveedor por nombre'"
                        :clean-on="false"
                        v-on:choosed="providerUpdate"
                        v-on:empty="providerEmpty"
                        :default="'null'"
                        :externalsrc="'/api/providers/getProviders'"
                        limit-chars="4"/>
                </b-form-group>
            </b-col>
            <b-col cols="md-3 mt-2">
                <b-form-group>
                    <label class="control-label"><b>Desde:</b></label>
                    <datepicker-component v-on:datechoosed="fromDateUpdated" :dateLabel="'Seleccione una fecha'"/>
                </b-form-group>
            </b-col>
            <b-col cols="md-3 mt-2">
                <b-form-group>
                    <label class="control-label"><b>Hasta:</b></label>
                    <datepicker-component v-on:datechoosed="toDateUpdated"/>
                </b-form-group>
            </b-col>
            <b-col cols="md-3" class="mt-4">
                <b-nav align="right">
                    <b-button-group size="lg">
                        <b-button variant="secondary" v-on:click="activeTab=0" v-bind:class="[{'active': activeTab==0}]">Pagos</b-button>
                        <b-button variant="secondary" v-on:click="activeTab=1" v-bind:class="[{'active': activeTab==1}]">Ord. de pago</b-button>
                        <b-button variant="secondary" v-on:click="activeTab=2" v-bind:class="[{'active': activeTab==2}]">Imputaciones</b-button>
                    </b-button-group>
                </b-nav>
            </b-col>
        </b-row>
        
        <b-card class="bg-light mb-4 p-0">
            <div class="row d-flex justify-content-between align-items-center"  v-if="payload.header.provider_id" v-show="activeTab==0">
                <b-col cols="md-3">
                    <b-form-group>
                        <label class="control-label"><b>SALDO EN CC:</b></label>
                        <div>
                            <h3>{{balanceText}}</h3>
                        </div>
                    </b-form-group>
                </b-col>  
                <b-col cols="md-3">
                    <b-form-group>
                        <label class="control-label"><b>CONCILIACION:</b></label>
                        <div>
                            <h3 v-bind:class="{'rojo': (concilValue<0)}">{{concilText}}</h3>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="md-3 text-right">
                    <div class="form-check form-check-inline">
                        <label class="form-check-input" for="historical"> Mostrar histórico</label>
                        <input class="form-check-label" id="historical" name="historical" type="checkbox" v-model="historical">
                    </div>
                </b-col>
            </div>
            <div v-else class="row d-flex align-items-center" style="height: 63px;">
                <div class="col">
                    <h4>Buscar un proveedor para ver la información</h4>
                </div>
            </div>
        </b-card>
        <div class="d-flex justify-content-end mb-3">
            <button type="button" id="allocation-btn" class="btn btn-md btn-outline-primary btn-fixed" @click="checkAllocations()">Imputar</button>
            <button type='submit' class='btn btn-md btn-orange btn-fixed ml-2' @click="ifModalPayment()">Nueva Orden de pago</button>
        </div>

        <b-row v-show="activeTab==0">
            <b-col cols="md-6">
                <div v-if="tableDocFields.length>0" class="col-sm-12 table table-light row-border dataTable no-footer">
                    <label class="control-label mt-2">Busqueda por factura:</label>
                    <input v-model="docFilter" class="form-control mb-2">
                </div>
                <table class="table table-light table-striped mb-5">
                    <thead>
                        <tr role="row">
                            <th><input type="checkbox" v-model="selectAllDocs"></th>
                            <th>Fecha</th>
                            <th>Factura</th>
                            <th>Deuda</th>
                            <th>Saldo</th>
                            <th class="col-sm-1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr role="row" v-for="(doc, index) in tableDocFieldsFiltered" :key="index" v-bind:class="[{selected: doc.rowSelected},{'verde': doc.payment},{'azul': doc.historical}]">
                            <td>
                                <input type="checkbox"
                                v-model="selectedDocs"
                                v-bind:value="doc"
                                @click="selectRow($event, doc)">
                            </td>
                            <td>{{ doc.date }}</td>
                            <td>{{ doc.docname }}</td>
                            <td>{{ formatoDinamicoMoneda(doc.value)}}</td>
                            <td>{{ formatoDinamicoMoneda(doc.debt)}}</td>
                            <td class="col-sm-1">
                                <b-button-group v-if="doc.docname !='SALDO INICIAL'" class="pull-right">
                                    <b-dropdown right variant="light">
                                        <span class="caret"></span>
                                        <b-dropdown-item><router-link :to="'/compras/index/detalle/' + doc.purchase_id" >Ver detalle</router-link></b-dropdown-item>
                                        <b-dropdown-item v-if="doc.docname !='SALDO INICIAL'"><a a href="javascript:void(0);" @click="cancelPurchase(doc.purchase_id)" >Anular comprobante</a></b-dropdown-item>    
                                    </b-dropdown>
                                </b-button-group>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-col>

            <b-col cols="md-6">
                <div v-if="tablePayFields.length>0" class="col-sm-12 table table-light row-border dataTable no-footer">
                    <label class="control-label mt-2">Busqueda por orden de pago:</label>
                    <input v-model="payFilter" class="form-control mb-2">
                </div>

                <b-table-simple striped small caption-top responsive>
                    <b-thead>
                        <b-tr role="row">
                            <b-th><input type="checkbox" v-model="selectAllPays"></b-th>
                            <b-th>Fecha</b-th>
                            <b-th>Numero</b-th>
                            <b-th>Valor</b-th>
                            <b-th>Saldo</b-th>
                            <b-th class="col-sm-1"></b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                                            <b-tr role="row" v-for="(po, index) in tablePayFieldsFiltered" :key="index" v-bind:class="[{selected: po.rowSelected},{'azul': po.historical}]">
                                                <b-td>
                                                    <input type="checkbox"
                                                    v-model="selectedPays"
                                                    v-bind:value="po"
                                                    @click="selectRow($event, po)">
                                                </b-td>
                                                <b-td>{{ po.date }}</b-td>
                                                <b-td>{{ po.payorder }}</b-td>
                                                <b-td>{{ formatoDinamicoMoneda(po.value)}}</b-td>
                                                <b-td>{{ formatoDinamicoMoneda(po.rest)}}</b-td>
                                                <b-td class="col-sm-1">
                                                    <b-button-group>
                                                        <b-dropdown right variant="light">
                                                            <span class="caret"></span>
                                                            <b-dropdown-item><router-link :to="'/compras/detalle-orden-de-pago/' + po.po_id" >Ver detalle</router-link></b-dropdown-item>
                                                            <b-dropdown-item @click="cancelPayorder(po.id)">Cancelar orden</b-dropdown-item>
                                                        </b-dropdown>
                                                    </b-button-group>
                                                </b-td>
                                            </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        
        <b-row v-if="activeTab==1">
            <payorders-table
            :provider="payload.header.provider_id"
            :fromDate="fromDate"
            :toDate="toDate"
            :watchTarget="tablePayFields"
            v-on:cancelPay="cancelPayorder"/>
        </b-row>
        
        <b-row v-if="activeTab==2">
            <allocations-table
            v-on:allocationDeleted="refreshTables"
            :provider="payload.header.provider_id"
            :fromDate="fromDate"
            :toDate="toDate"/>
        </b-row>

        <pay-order-payment-methods
            :header="payload.header"
            :fundDefault="config.fund_default"
            :emisionData="config.emisionData"
            v-on:paymentmethodschanged="paymentMethodsUpdated"
            v-on:paymentmethodsclose="paymentMethodsClose"
            v-bind:payment-methods="payment_methods"
            v-bind:total="concilValue"
            v-if="showModal"
            :limited="false"
            :checks='wallet'
            @close="showModal = false"/>

    </b-container>
</template>


<script>

import payOrderPaymentMethods from '../../paymentmethods/Pay-order-payment-methods.vue';
import typeahead from '../../global/Typeahead.vue';
import datepickerComponent from '../../global/Datepicker-empty.vue';
import payordersTable from './Ordenes-de-pago.vue';
import allocationsTable from './Imputaciones.vue';
import formatDateMixin from "../../../mixins/formatDateMixin";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";

import { mapState } from 'vuex'

export default {
  components: {
    payOrderPaymentMethods,
    typeahead,
    datepickerComponent,
    payordersTable,
    allocationsTable
  },
  mixins: [formatDateMixin,formatMoneyMixin],
    data() {
        return {
            activeTab: 0,
            showModal: false,
            wallet: null,
            payload: {
            header: {
                provider_id : null,
                pos: 0
            },
            paymentMethods: []
            },
            fromDate: null,
            toDate:null,
            historical: null,
            concilValue: 0.00,
            balanceValue:0.00,
            tableDocFields:[],
            tablePayFields:[],
            selectedDocs:[],
            selectedPays:[],
            docFilter:'',
            payFilter:'',
            printURL:'',
            payment_methods:null
        }
    },
    mounted() {
        this.historical=false;
        this.testDocs=true;
    },
    created () {
        this.$store.dispatch('authUser/getAuthUser')
    },
    computed: {
        ...mapState('authUser',{
            authUser: state => state.authUser,
        }),
        
        config: {
            get: function () { return  this.authUser.branchoffices[0].configs;}
        },
        branchofficeData:{
            get: function () { 
            return {
                'name' : this.authUser.branchoffices[0].name,
                'bsns_name' : this.authUser.branchoffices[0].business_name,
                'cuit' : this.authUser.branchoffices[0].cuit,
            }
            }
        },
        concilText: {
            get: function () {
                return '$ ' + this.concilValue.toFixed(2).replace(".",",").replace(/\d(?=(\d{3})+\,)/g, '$&.');
            }
        },
        balanceText: {
            get: function () {
                return '$ ' + this.balanceValue.toFixed(2).replace(".",",").replace(/\d(?=(\d{3})+\,)/g, '$&.');
            }
        },

        selectAllDocs: {
            get: function () {
                if((this.selectedDocs.length == this.tableDocFields.length) && (this.tableDocFields.length!=0)){
                    return true;
                }else{
                    return false;
                }
            },
            set: function (value) {
                var selected = [];

                if (value) {
                    for (var doc in this.tableDocFields) {
                        this.tableDocFields[doc].rowSelected =true;
                        this.selectedDocs.push(this.tableDocFields[doc]);
                    }
                }else{
                    for (var doc in this.tableDocFields) {
                        this.tableDocFields[doc].rowSelected =false;
                    }
                    this.selectedDocs = selected;
                }
            }
        },
        selectAllPays: {
            get: function () {
                if ((this.selectedPays.length == this.tablePayFields.length) && (this.tablePayFields.length!=0)){
                    return true;
                }else{
                    return false;
                }
            },
            set: function (value) {
                var selected = [];

                if (value) {
                    for (var pay in this.tablePayFields) {
                        this.tablePayFields[pay].rowSelected =true;
                        this.selectedPays.push(this.tablePayFields[pay]);
                    }
                }else{
                    for (var pay in this.tablePayFields) {
                        this.tablePayFields[pay].rowSelected =false;
                    }
                    this.selectedPays=selected;
                }
            }
        },
        tableDocFieldsFiltered:{
            get: function () {
                let vm = this;
                return this.tableDocFields.filter(e => e.docname.includes(vm.docFilter));
            }
        },
        tablePayFieldsFiltered:{
            get: function () {
                let vm = this;
                return this.tablePayFields.filter(e => e.payorder.includes(vm.payFilter));
            }
        }
    },
  watch: {
      'selectedDocs': {
          handler: function (a) {this.updateConcilValue();}
      },
      'selectedPays': {
          handler: function (a) {this.updateConcilValue();}
      },
      'historical': {
          handler: function (a) {this.refreshTables();}
      },
      authUser: function(value) {
        if(value==null) return;
        this.payment_methods = Object.assign({}, this.authUser.branchoffices[0].payments_methods);
        this.payment_methods.cuenta_corriente=0;

      },
    
  },

  methods: {
    SwitchTab() {
        this.activeTab = 1
    },
    fromDateUpdated: function (date) {
      this.fromDate = date;
      this.refreshTables();
    },
    toDateUpdated: function (date) {
      this.toDate = date;
      this.refreshTables();
    },
    paymentMethodsUpdated(obj) {
        this.payload.paymentMethods=obj;
    },
    paymentMethodsClose(obj) {
        this.payload.paymentMethods=obj;
        if ((obj.cash >0) || (obj.accounts.length>0) || (obj.creditcards.length>0) || (obj.tpchecks.length>0) || (obj.sfchecks.length>0)){
            this.sendReceipt();
        }
    },
    sendReceipt() {
      var vm= this;
      var outdata = {
          'header': vm.payload.header,
          'paymentMethods' :vm.payload.paymentMethods,
          'selectedDocs' : vm.selectedDocs,
          'selectedPay' : vm.selectedPays,
        };
      this.concilValue = 0.00;
      axios.post('/api/payments/addAllocationPayment', outdata)
          .then((response) => {
            if (response.data.success) {
                if (response.data.data.printInvoice == true) {
                    window.open(response.data.data.urlInvoice, '_blank');
              } else {
                
              }
              this.refreshTables();
            } else {
              
            }
          }).catch((error) => {
            console.log('error: '+error);
          })
    },
    selectRow(event, item, type, target) {
        item.rowSelected = !item.rowSelected;
    },
    providerUpdate(data) {
      this.payload.header.provider_id=data.id;
      this.updateBalanceValue();
      this.refreshTables();
      this.selectedDocs=[];
      this.selectedPays=[];
    },
    providerEmpty(){
        this.payload.header.provider_id=null;
        this.refreshTables();
        this.selectedDocs=[];
        this.selectedPays=[];
    },
    getProvidersList(){
        var vm = this;
        axios.get('/api/providers/list')
            .then(response => {
              if(response.data) {
                vm.providerList = response.data.data;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    refreshTables(){
        var vm= this;
        var outdata = {
            'provider_id': vm.payload.header.provider_id,
            'historical' : vm.historical,
            'posFilter':vm.posFilter,
            'fromDate':vm.fromDate,
            'toDate':vm.toDate
        };
        axios.post('/api/payments/getListPurchases', outdata)
            .then(response => {
              if(response.data) {
                vm.tableDocFields = response.data;
              }
            })
            .catch(error => {
              alert(error);
            })

        axios.post('/api/payments/getListPayorders', outdata)
            .then(response => {
              if(response.data) {
                vm.tablePayFields = response.data;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    updateConcilValue(){
        var vm=this;
        var outdata ={
            'provider': vm.payload.header.provider_id,
            'selectedDocs' : vm.selectedDocs,
            'selectedPay' : vm.selectedPays
        };

        axios.post('/api/payments/getConciliation', outdata)
            .then(response => {
              if(response.data.data) {
                vm.concilValue = parseFloat(response.data.data);
              }else{
                vm.concilValue = 0.00;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    updateBalanceValue(){
        var vm=this;
        axios.get('/api/providers/getBalance/' +  vm.payload.header.provider_id)
            .then(response => {
              if(response.data.data) {
                vm.balanceValue = parseFloat(response.data.data);
              }else{
                vm.balanceValue = 0.00;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    checkAllocations(){
        if (this.selectedDocs.length<=0){
            alert("Necesita seleccionar comprobantes");
            return false;
        }

        if (confirm("Se realizará la imputación indicada")){
            if (this.concilValue>0){
                if(confirm("El pago no es suficiente para cubrir los comprobantes seleccionados ¿Desea ingresar un pago por el monto restante ahora?")){
                    this.showModal = true;
                }else{
                    this.allocate();
                }
            }else{
                this.allocate();
            }
        }
    },
    allocate(){
        var vm=this;
        var outdata ={
            'provider': vm.payload.header.provider_id,
            'selectedDocs' : vm.selectedDocs,
            'selectedPay' : vm.selectedPays
        };

        axios.post('/api/payments/addAllocation', outdata)
            .then(response => {
              if(response.data.success) {
                  this.refreshTables();
              }else{
                  
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    ifModalPayment(){
        if (this.concilValue>=0){ this.showModal = true;
        }else{ alert("No se pueden crear pagos con valor negativo");}
    },
    cancelPayorder(data){
        if (confirm("¿Está seguro que desea eliminar este comprobante? Se desarmarán también todas las imputaciones en las que se encuentre asociado")){
            axios.get('/api/payments/cancelPayorder/'+data)
            .then(response => {
                if(response.data.success) {
                  this.refreshTables();
                }else{
                  alert(response.message);
                }
            })
            .catch(error => {
              alert(error.message);
            })
        }
    },
    cancelPurchase(id){
        var outdata={'id':id};
        if (confirm("¿Está seguro que desea anular este comprobante? No aparecerá en el listado de pago a proveedores pero podrá verse en la vista de compras")){
            axios.post('/api/purchases/cancel',outdata)
            .then(response => {
                if(response.data.success) {
                  this.refreshTables();
                }else{
                  alert(response.message);
                }
            })
            .catch(error => {
              alert(error.message);
            })
        }
    }
  },
}
</script>   
