<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>IVA Compras</h4>
      </b-col>
    </div>
    <b-row class="statsreport text-center">
      <b-col cols="lg-2 md-2 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number small">{{ formatoDinamicoMoneda(subtotal) }}</div>
          <div class="desc">Total neto gravado </div>
        </div>
      </b-col>
      <b-col cols="lg-1 md-1 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span>{{ formatoDinamicoMoneda(ewamount) }}</span></div>
          <div class="desc">Retencion de ganancias</div>
        </div>
      </b-col>
      <b-col cols="lg-1 md-1 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span>{{ formatoDinamicoMoneda(ewivaamount) }}</span></div>
          <div class="desc">Retencion de IVA</div>
        </div>
      </b-col>
      <b-col id="tax25" cols="lg-1 md-1 sm-1 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span class="tax25">{{ formatoDinamicoMoneda(tax25) }}</span></div>
          <div class="desc">IVA 2,5%</div>
        </div>
      </b-col>
      <b-col id="tax50" cols="lg-1 md-1 sm-1 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span class="tax50">{{ formatoDinamicoMoneda(tax50) }}</span></div>
          <div class="desc">IVA 5%</div>
        </div>
      </b-col>
      <b-col id="tax105" cols="lg-1 md-1 sm-1 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span class="tax105">{{ formatoDinamicoMoneda(tax105) }}</span></div>
          <div class="desc">IVA 10,5%</div>
        </div>
      </b-col>
      <b-col id="tax210" cols="lg-1 md-1 sm-1 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span class="tax210">{{ formatoDinamicoMoneda(tax210) }}</span></div>
          <div class="desc">IVA 21%</div>
        </div>
      </b-col>
      <b-col id="tax270" cols="lg-1 md-1 sm-1 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span class="tax270">{{ formatoDinamicoMoneda(tax270) }}</span></div>
          <div class="desc">IVA 27%</div>
        </div>
      </b-col>
      <b-col cols="lg-1 md-1 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number small">{{ formatoDinamicoMoneda(tax) }}</div>
          <div class="desc">Total IVA</div>
        </div>
      </b-col>
      <b-col cols="lg-2 md-2 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number small">{{ formatoDinamicoMoneda(total) }}</div>
          <div class="desc">Total COMPRAS </div>
        </div>
      </b-col>
    </b-row>

    <div class="card bg-light rounded-sm p-3 my-2 border">
        <div class="row d-flex justify-content-between">
          <div class="col col-md-2">
            <b-form-group>
                <select class="form-control btn-light load-data" id="invoiceType" name="invoiceType" v-model="invoiceType">
                    <option selected="selected" value="">Todos los tipos</option>
                    <option value="FC">FC</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                </select> 
            </b-form-group>
          </div>
          <div class="col col-md-2">
            <b-form-group>
                <BranchofficeSelect v-if="hasPermission" v-on:selectedBranchofficeId='selectedBranchofficeId'/>
            </b-form-group>
          </div>
          <div class="col col-md-2">
            <b-form-group>
              <label class="control-label">Fecha: </label>
              <Daterange :opendir="'left'" @datepicked="dateCreated" name="daterange"/>
            </b-form-group>
          </div>
          <div class="col col-md-2">
            <b-form-group>
              <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary mr-2" value="Exportar CSV">
              <input type="button" @click="exportPDF()" class="btn btn-md btn-outline-primary" value="Exportar PDF">
            </b-form-group>
          </div>
      </div>
    </div>

    <b-row >
      <b-col cols="md-12">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          ref="table"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import VdtnetTable from "vue-datatables-net";
import Daterange from "../global/Daterange-component.vue";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import formatDateMixin from "../../mixins/formatDateMixin";
import exportsMixin from "../../mixins/exportsMixin";
import BranchofficeSelect from "./../sub-components/branchoffice-select.vue";


export default{
  components: {
    VdtnetTable,
    Daterange,
    BranchofficeSelect
  },
  mixins: [formatMoneyMixin,formatDateMixin,exportsMixin],
  data: function() {
    return {
      hasPermission : true,
      loading: false,
      totalSales:0,
      totalCash:0,
      totalCC:0,
      totalTpCheks:0,
      totalCards:0,
      posList:[],
      invoiceType: "",
      startDate: null,
      endDate: null,
      branchoffice_id:null,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/reports/ivapurchases",
          type: "GET",
          data: (d) => {
            d.invoiceType= this.invoiceType,
            d.daterange= this.daterange,
            d.branchoffice_id = this.branchoffice_id;
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
          
        processing: true,
        pageLength: 50,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        order: [[0, 'desc']],
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
      },
      subtotal: null,
      ewamount: null,
      ewivaamount: null,
      tax25: null,
      tax50: null,
      tax105: null,
      tax210: null,
      tax270: null,
      tax: null,
      total: null,
    }
  },
  mounted() {
  },
  computed:{
    fields() { return  {
        dateHour: { label: "Fecha" },
        receipt: { label: "Comprobante" },
        provider_name: { label: "Proveedor" },
        provider_cuit: { label: "Cuit" },
        'taxes.2.5': { label: "2,5%",
          render: this.formatoDinamicoMoneda,
        },
        'taxes.5': { label: "5%",
          render: this.formatoDinamicoMoneda,
        },
        'taxes.10.5': { label: "10,5%",
          render: this.formatoDinamicoMoneda,
        },
        'taxes.21': { label: "21%",
          render: this.formatoDinamicoMoneda,
        },
        'taxes.27': { label: "27%",
          render: this.formatoDinamicoMoneda,
        },
        'taxes.internalTax': { label: "Impuestos Internos",
          render: this.formatoDinamicoMoneda,
        },
        'taxes.perceptionTax': { label: "Perc. IVA",
          render: this.formatoDinamicoMoneda,
        },
        'taxes.lofty': { label: "Excento",
          render: this.formatoDinamicoMoneda,
        },
        'taxes.otherTax': { label: "Otros Impuestos",
          render: this.formatoDinamicoMoneda,
        },
        'taxes.iibb': { label: "Perc. IIBB",
          render: this.formatoDinamicoMoneda,
        },

        subtotal: { label: "Subtotal",
          render: this.formatoDinamicoMoneda,
        },

        total: { label: "Total", render: this.formatoDinamicoMoneda },
      };
    },
    daterange() {
      if (this.startDate === null || this.endDate === null) {
        return null;
      }
      const startDate = this.formatoFecha2Daterange(this.startDate);
      const endDate = this.formatoFecha2Daterange(this.endDate);
      return `${startDate} - ${endDate}`;
    },
    filters() {
      return {
          invoiceType: this.invoiceType,
          daterange: this.daterange,
          branchoffice_id : this.branchoffice_id
        };
    }
  },
  methods: {
    selectedBranchofficeId(value){
      this.branchoffice_id = value;
    },
    refreshTable() {
        if (this.$refs.table !== undefined)
          this.$refs.table.reload();
      },
    dateCreated(date) {
      this.startDate = date.startDate;
      this.endDate = date.endDate;
    },
    getTotals() {
      var vm = this;
      vm.loading = true;
      this.$http.get(process.env.VUE_APP_BASE_URL+"/api/reports/statsivapurchases", {params: this.filters}).then((response) => {
        vm.subtotal = response.data.data.subtotal;
        vm.ewamount = response.data.data.ewamount;
        vm.ewivaamount = response.data.data.ewivaamount;
        vm.tax25 = response.data.data.tax25;
        vm.tax50 = response.data.data.tax50;
        vm.tax105 = response.data.data.tax105;
        vm.tax210 = response.data.data.tax210;
        vm.tax270 = response.data.data.tax270;
        vm.tax = response.data.data.tax;
        vm.total = response.data.data.total;
        vm.loading = false;
      }); 
    },
    exportCSV() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/CSVpurchaseIVA", this.filters)
      .then((response) => {
      });
    },
    exportPDF() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/PDFpurchaseIVA", this.filters)
      .then((response) => {
      });
    },
  },
  watch: {
    filters() {
      this.refreshTable();
      this.getTotals();
    }
  }
}
</script>
