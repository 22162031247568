<template>

  <b-col>
    <b-card>
      <h4 v-if="serialNumber.id == null" class="panel-title">Nuevo numero de serie</h4>
      <h4 v-else class="panel-title">Editar numero de serie ID {{serialNumberId}}</h4>
      <b-button variant="outline-primary" size="sm"
        type="button"
        v-if="serialNumber.id != null"
        @click="serialNumber = getNewSerialNumber()">
        Nuevo numero de serie
      </b-button>
      <b-form class="form" @submit.prevent>
        <label class="control-label" for="input-live">Numero de serie:</label>
        <b-form-input
          id="input-live"
          v-model="serialNumber.code"
          :state="codeState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Numero de serie"
          trim>
        </b-form-input>
        <!-- This will only be shown if the preceding input has an invalid state -->
        <b-form-invalid-feedback v-if="codeState === false" id="input-live-feedback">
          El numero de serie debe tener un mínimo de 3 letras
        </b-form-invalid-feedback>
        <!-- This is a form text block (formerly known as help block) -->
        <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
        <label class="control-label mt-3" for="input-live">Proveedor:</label>
        <!--<b-form-input>-->
          <typeahead style="margin-top: 1px;"
            :name="'provider'" 
            :plh="'Ingrese proveedor'" 
            :clean-on="false" 
            :state="providerState"
            v-on:choosed="providerChoosed" 
            :default="providerName" 
            :externalsrc="srcProviders" 
            limit-chars="2"
            v-on:empty="providerEmpty"
          />
        <!--/b-form-input-->
        <!-- This will only be shown if the preceding input has an invalid state -->
        <b-form-invalid-feedback v-if="providerState === false" id="input-live-feedback">
          Debe seleccionar un Proveedor
        </b-form-invalid-feedback>
        <!-- This is a form text block (formerly known as help block) -->
        <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
        <label class="control-label mt-3" for="input-live">Cliente:</label>
        <typeahead style="margin-top: 1px;"
          :name="'client'" 
          :plh="'Ingrese cliente'" 
          :clean-on="false"
          v-on:choosed="clientChoosed" 
          :default="clientName" 
          :externalsrc="srcClients" 
          limit-chars="2"
          v-on:empty="clientEmpty" 
        />
        <b-button variant="orange" size="lg" class="mt-3"
          v-if="codeState !== null && providerState !== null"
          @click="saveSerialNumber()"> Guardar Nro de Serie
        </b-button>
        <b-button variant="orange" size="lg" class="mt-3"
          v-else @click="saveSerialNumber()"> Editar Nro de Serie
        </b-button>
      </b-form>
    </b-card>
  </b-col>
</template>

<script>
import typeahead from './../../../global/Typeahead.vue';

export default {
  props: ['productId','serialNumberId'],
  components: {
    typeahead
  },
  data: function () {
    return {
      isLoading: false,
      serialNumber: {
        id: null,
        code: "",
        product_id: this.productId,
        provider_id : null,
      },
      srcProviders: '/api/providers/getProviders',
      srcClients: '/api/clients/getClients',
      serialNumbersApiRoute : "/api/serialnumbers/"
    };
  },

  computed: {
    codeState() {
      return this.serialNumber.code.length < 3 ? false : null;
    },
    providerState() {
      return this.serialNumber.provider_id == null  ? false : null ;
    },
    providerName() {
      return this.serialNumber.provider == undefined? '': this.serialNumber.provider.name;
    },
    clientName() {
      return this.serialNumber.client == undefined? '': this.serialNumber.client.name;
    },

  },
  watch: {
    serialNumberId: {
      handler: function (serialNumberId) {
        if(serialNumberId != null)
          this.getSerialNumber(serialNumberId);
        else
          this.serialNumber = this.getNewSerialNumber();
      }
    }
  },
  methods: {
    getNewSerialNumber() { 
      return {
        id: null,
        code: "",
        product_id: this.productId,
        provider_id : null
        }
      },
    getSerialNumber(id) {
      var vm = this;
      vm.isLoading = true
      this.$http.get(this.serialNumbersApiRoute + "getSerialNumber/" + id).then((response) => {
        vm.isLoading = false
        this.serialNumber = response.data.data;
        this.$emit('loaded');
      });
    },
    providerChoosed(data) {
      this.serialNumber.provider_id = data.id;
    },
    providerEmpty(){
        this.serialNumber.provider_id = null;
    },
    clientChoosed(data) {
      this.serialNumber.client_id = data.id;
    },
    clientEmpty(){
        this.serialNumber.client_id = null;
    },
    saveSerialNumber() {
      this.$emit('saving');
      this.serialNumber.product_id= this.productId;
      if (this.serialNumber.id != null) {
        this.isLoading = true
        this.$http
            .post(this.serialNumbersApiRoute + "edit/" + this.serialNumber.id, this.serialNumber)
            .then((response) => {
              
              this.serialNumber = this.getNewSerialNumber();
              this.isLoading = false;
              this.$emit('saved');
            });
      } else {
        this.isLoading = true
        this.$http.post(this.serialNumbersApiRoute + "add", this.serialNumber).then((response) => {
          
          this.serialNumber = this.getNewSerialNumber();
          this.isLoading = false;
          this.$emit('saved');
        });
      }
    }
  },
};
</script>

<style>
.divActions {
  float: right;
}

.marginButton {
  margin-left: 10px;
}
</style>
