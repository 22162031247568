<template>
    <b-container fluid>
        <div class="loading" v-if="isLoading">Loading&#8230;</div>
        <div class="row mt-3">
            <b-col class="d-flex justify-content-start">
                <h4>Listado de Ordenes de pago</h4>
            </b-col>
        </div>
        <b-row class="d-flex justify-content-between">
            <!-- -------------------- BUSCADOR -------------------------- -->
            <b-col cols="md-2">
                <input
                v-model="filters.qclient" 
                type="search" 
                class="form-control my-2" 
                placeholder="Buscar por proveedor"
                aria-controls="coupons" />    
            </b-col>
            <b-col cols="md-2">
                <input
                v-model="filters.quser" 
                type="search" 
                class="form-control my-2" 
                placeholder="Buscar por usuario"
                aria-controls="coupons" />    
            </b-col>
            <b-col cols="md-2">
                <input
                v-model="filters.qnumber" 
                type="search" 
                class="form-control my-2" 
                placeholder="Buscar por nro. de orden de pago"
                aria-controls="coupons" />    
            </b-col>
            <div class="col col-md-2">
            <b-form-group>
              <Daterange :opendir="'left'" :filtered="true" @datepicked="dateCreated"/>
            </b-form-group>
          </div>
          <div class="col col-md-2">
            <b-form-group>
              <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary mr-2" value="Exportar CSV">
            </b-form-group>
          </div>
        </b-row>
        <!-- ----------------------- TABLA -------------------------- -->
        <b-row>
            <b-col>
                <vdtnet-table 
                :fields="fields" 
                :opts="opts" 
                :selectCheckbox="0" 
                ref="table" 
                />
            </b-col>
        </b-row> 


    </b-container>
</template>

<script>

import myaxios from 'axios';
import VdtnetTable from "vue-datatables-net";
import formatDateMixin from "../../mixins/formatDateMixin";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import Daterange from "../global/Daterange-component.vue";



export default {
    components: {
        VdtnetTable,
        Daterange,
    },
    mixins: [formatDateMixin,formatMoneyMixin],
    data() {
        return {
            receiptList:[],
            isLoading:false,
            recFilter:'',
            page:1,
            rowCount:9,
            totalItems:0,
            totalPages:1,
            pageInterval:22,
            doSearch: "",
            startDate:null,
            endDate:null,
            filters:{
                qprovider:'',
                quser:'',
                qnumber:'',
                fromDate: null,
                toDate: null
            },
            opts: {
                language: {
                url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
                },
                ajax: {
                url: process.env.VUE_APP_BASE_URL+"/api/payments/index",
                type: "GET",
                data: (d) => {
                    d.filters= this.filters;
                    return d.data
                },
                headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                },
                processing: true,
                pageLength: 15,
                searching: true,
                lengthChange: true,
                serverSide: true,
                fixedHeader: true
            },
            fields: {
                date: {
                    label: "Fecha",
                    searchable: true,
                    render: (data) => this.formatoFechaHora(data),
                },
                number: { label: "Numero",searchable:true  },
                provider: { label: "Proveedor",searchable:true  },
                user: { label: "Usuario",searchable:true  },
                total: { 
                    label: "Total",
                    searchable:true,
                    render: this.formatoDinamicoMoneda, 
                },
            },
        }
    },
    mounted() {
      this.setDefaultDateRange();
    },
    methods: {
        getReceiptList(){
            var vm= this;
            var outdata = {
                'client_id': vm.client,
                'fromDate':vm.fromDate,
                'toDate':vm.toDate,
                'page':vm.page,
                'rowCount':vm.rowCount
            };

            this.$http.post('/api/collections/getReceipts', outdata)
            .then(response => {
                if(response.data) {
                vm.receiptList = response.data.data.list;
                vm.totalItems=response.data.data.totalItems;
              }
            })
            .catch(error => {
              alert(error);
            })
        },
        refreshTable() {
            var vm = this;
            if (vm.$refs.table !== undefined) vm.$refs.table.reload();
        },
        dateCreated(date) {
            this.filters.fromDate = date.startDate;
            this.filters.toDate = date.endDate;
            this.refreshTable();
        },
        setDefaultDateRange() {
            const today = new Date();
            const startDate = new Date();
            startDate.setDate(today.getDate() - 30);

            this.filters.fromDate = this.formatDate(startDate);
            this.filters.toDate = this.formatDate(today);
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const day = ('0' + date.getDate()).slice(-2);
            return `${day}/${month}/${year}`;
        },
        exportCSV() {
            this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/CSVpayorderList", this.filters)
            .then((response) => {
            });
        },
    },
    watch: {
    doSearch(newValue, oldValue) {
      if (newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch);
      }
      if (newValue === "" && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch);
      }
    },
    'filters.qclient'(newValue) {
      if (this.searchDelay) {
        clearTimeout(this.searchDelay);
      }
      
      this.searchDelay = setTimeout(() => {
        this.refreshTable();
      }, 700);
    },
    'filters.quser'(newValue) {
      if (this.searchDelay) {
        clearTimeout(this.searchDelay);
      }
      
      this.searchDelay = setTimeout(() => {
        this.refreshTable();
      }, 700);
    },
    'filters.qnumber'(newValue) {
      if (this.searchDelay) {
        clearTimeout(this.searchDelay);
      }
      
      this.searchDelay = setTimeout(() => {
        this.refreshTable();
      }, 700);
    },
  },

}
</script>
<style>
.disabled {
  pointer-events: none;
}
</style>
