import axios from 'axios'

export const getSalesclerks = ({ commit }) => {
  commit('setLoading', true);
  axios.get('/api/salesclerks/getSalesclerks')
    .then(response => {
      commit('getSalesclerks', response.data.data)
    })
    // fail
    .catch(e => {
      commit('getSalesclerks', e.response)
    })
    .finally(() => {
      commit('setLoading', false);
    });
}