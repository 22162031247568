<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <h5 class="modal-title">Cierre de caja</h5>
          </div>
          <div class="modal-body">
              <slot name="body">
                  <div class="loading" v-if="loading">Loading&#8230;</div>
                  <div class="row d-flex justify-content-center">
                      <div class="col-12 col-md-6 p-4">
                          <label class="control-label">Remanente:</label>
                          <input class="form-control" type="number" v-model="remainder" placeholder="Ingrese el monto..."> 
                      </div>
                      <div class="col-12 col-md-6 p-4">
                        <div class="form-group">
                          <label for="destinationFund">Caja destino:</label>
                          <select class="form-control" id="destinationFund" v-model="selectedFund">
                            <option :value="0">Ninguna</option>
                            <option v-for="fund in funds" :key="fund.id" :value="fund.id"> {{ fund.name }}</option>
                          </select>
                        </div>
                      </div>
                  </div>
                  <div class="row d-flex justify-content-between mt-5">
                      <div class="col-6">
                          <button type="button" class="btn btn-orange btn-block" @click="closeFund()">
                              Cerrar caja
                          </button>
                      </div>
                      <div class="col-6">
                          <button type="button" class="btn btn-outline-primary btn-block" @click="close()">
                              Cancelar
                          </button>
                      </div>
                  </div>
              </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment';
import 'moment/locale/es';

export default {
  props: ['fund_id'],
  data: function() {
      return {
          loading:false,
          remainder:null,
          fund: [],
          funds: [],
          selectedFund: null,
          balance: null,
          movement: {
              id: null,
              type: "out",
              amount: null,
              fund_id: this.fund_id,
              sale_id: null,
              coupon_id: null,
              tpcheck_id: null,
              purchase_id: null,
              category_id: null,
              cc_id: null,
              user_id: null,
              pac_id: null,
              category: null,
              origen: "",
              in: null,
              out: null,
          },
          transfer: {
            to_fund_id: null,
            amount: null,
            dolar: 0,
          },
    
      }
  },
  mounted() {
      this.getTotal();
      this.getFund();
      this.getFunds();
  },
  methods: {
    close(){
        this.$emit('closemodal');
    },
    getTotal() {
        var vm = this;
        this.$http.get("/api/funds/getBalance/" + vm.fund_id)
        .then((response) => {
            vm.balance = response.data.data;
        });
    },
    getFund(){
        var vm = this;
        this.$http.get("/api/funds/getFund/" + vm.fund_id)
        .then((response) => {
            vm.fund = response.data.data;
        });
    },
    getFunds() {
      var vm = this;
      this.$http
        .get("/api/funds/list")
        .then((response) => {
          vm.funds = response.data.data;
          vm.funds = vm.funds.filter(fund => fund.type === 'Efectivo');
        });
    },
    closeFund(){
      var vm = this;
      var id = this.fund_id;
      var amount = vm.balance - vm.remainder;

      if(this.selectedFund === 0){
        if (confirm("¿Está seguro que desea cerrar esta caja?") == true){
          vm.movement.amount = vm.balance;
          vm.movement.category_id = 6;
          vm.movement.description = "CIERRE DE CAJA DEL " + moment().format('LL') + ' - Retiro: $' +amount;
          vm.movement.type = "out";
          this.$http
          .post("/api/funds/movement/" + this.fund_id, vm.movement)
          .then((response) => {             
            vm.movement.amount = vm.remainder;
            vm.movement.category_id = 5;
            vm.movement.description = "APERTURA DE CAJA";
            vm.movement.type = "in";
            this.$http
            .post("/api/funds/movement/" + this.fund_id, vm.movement)
            .then((response) => {
              vm.$emit('closemodal');
              vm.$emit('closeDone'); 
            });
          });
        }
      }else {
        if (confirm("¿Está seguro que desea cerrar esta caja?") == true){
          vm.movement.amount = vm.balance;
          vm.movement.category_id = 6;
          vm.movement.description = "CIERRE DE CAJA DEL " + moment().format('LL') + ' - Retiro: $' +amount;
          vm.movement.type = "out";
          this.$http
          .post("/api/funds/movement/" + this.fund_id, vm.movement)
          .then((response) => {             
            vm.movement.amount = vm.balance;
            vm.movement.category_id = 5;
            vm.movement.description = "APERTURA DE CAJA";
            vm.movement.type = "in";
            this.$http
            .post("/api/funds/movement/" + this.fund_id, vm.movement)
            .then((response) => {
              vm.transfer.to_fund_id = this.selectedFund;
              vm.transfer.amount = amount;
              this.$http
              .post("/api/funds/transfer/" + this.fund_id, vm.transfer)
              .then((response) => {
                vm.$emit('closemodal');
                vm.$emit('closeDone');
              });  
            });
          });
        }
      }
    }
  },
}
</script>

<style scoped>

.pricePanel{
    background-color: #F2F4F7;
    font-weight: bold;
    border:1px solid #d3d9e4;
    text-align: center;
    width: 216px;
    height: 38px;
    display: table-cell;
    vertical-align: middle;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

  .modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 90vw;
    margin: 0px auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    max-height: 90vh;
    overflow-y: auto;
  }

  @media (min-width: 768px) {
    .modal-container {
        width: 50vw; /* Para pantallas de escritorio */
    }
  }

  .modal-header {
    display: flex;
    align-items: center;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .footer{
      display: flex;
      justify-content: flex-end;
      gap: 30px;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
