<template>
    <b-container fluid>
      <div class="row pt-3">
      <h2>
          <label for="">Detalle de imputacion recibos</label>
      </h2>
    </div>
    <b-card class="bg-light d-flex justify-content-between mb-4 p-0">
      <div class="row">
        <div class="col-sm-4">
          <h2 class="control-label">Imputación # {{allocation.id}}</h2>
        </div>
        <div class="col-sm-4">
          <h2 class="control-label">Cliente: {{clientName}}</h2>
        </div>
        <div class="col-sm-4">
          <h2 class="control-label">Fecha: {{formatoDate2Fecha(allocation.created_at)}}</h2>      
        </div>
      </div>
    </b-card>
    
    <div class="row">
      <div class="col-md-6">
        <label>Facturas:</label>
          <b-table hover :items="invoices" :fields="invoicesFields" :busy="isBusy">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(sale.id)="data">
                  <a :href="`/ventas/index/detalle/${data.value}`" class="btn btn-sm btn-light">Ver detalle</a>
              </template>
          </b-table>
      </div>
      <div class="col-md-6">
        <label>Recibos:</label>
          <b-table hover :items="payments" :fields="paymentsFields" :busy="isBusy">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
          </template>
              <template #cell(id)="data">
                  <a :href="`/ventas/cobranzas/detalle-recibo/${data.value}`" class="btn btn-sm btn-light">Ver detalle</a>
              </template>
          </b-table>
      </div>
    </div>
    
    <b-row class="receipt-footer d-flex justify-content-end mb-3">
      <DownloadDoc :pdfurl="pdfurl" :open="false" :filename="allocation.provider_name+allocation.created_at+'.pdf'">
        <b-button variant="outline-primary" size="lg" class="mr-4">Descargar</b-button>
      </DownloadDoc>
      <DownloadDoc :pdfurl="pdfurl" :open="true" :filename="allocation.provider_name+allocation.created_at+'.pdf'">
        <b-button variant="orange" size="lg">Imprimir</b-button>
      </DownloadDoc>
    </b-row>
  </b-container>
</template>
<script>
import DownloadDoc from "../global/DownloadDoc";
import formatDateMixin from "../../mixins/formatDateMixin";
import myaxios from 'axios';

export default {
  components: {
    DownloadDoc
  },
  props: ['allocationId'],
  mixins: [formatDateMixin],
  
  data() {
    return {
      pdfurl: process.env.VUE_APP_BASE_URL+'/api/collections/locPDF/' + this.allocationId,
      showModal: false,
      showModalRet: false,
      allocation:'',
      allocationDetails:[],
      invoices:[],
      payments:[],
      invoicesFields: [
          {
            key: 'created_at',
            label: 'Fecha',
            sortable: true,
            formatter: (value, key, item) => {
              return this.formatoFechaHora(value);
            }
          },
          {
            key: 'type',
            label: 'Tipo',
            sortable: true
          },
          {
            key: 'number',
            label: 'Numero',
            sortable: false
          },
          {
            key: 'amount',
            label: 'Importe',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          },
          {
            key: 'pivot.partial',
            label: 'En Recibo',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          }
          ,
          {
            key: 'sale.id',
            label: 'Ver Detalle',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          }
      ],
      paymentsFields: [
          {
            key: 'created_at',
            label: 'Fecha',
            sortable: true
          },
          {
            key: 'type',
            label: 'Tipo',
            sortable: true
          },
          {
            key: 'number',
            label: 'Numero',
            sortable: false
          },
          {
            key: 'amount',
            label: 'Importe',
            sortable: true,
            variant: 'danger'
          },
          {
            key: 'pivot.partial',
            label: 'Imputado',
            sortable: true,
            variant: 'danger'
          }
          ,
          {
            key: 'id',
            label: 'Ver Detalle',
            sortable: true,
            variant: 'danger'
          }
      ],
    }
  },
  computed:{
    pos() {
      return this.allocation.pos
    },
    number() { return this.allocation.number },
    clientName() { return this.allocation.client != undefined ? this.allocation.client.name :''},
    created_at() { return this.allocation.created_at },
    isBusy() { return this.allocation == '' }
  },
  methods: {
    getDetailTotal(type) { 
      return this.receiptDetails.filter(d => d.type == type)
        .reduce((accumulator, object) => { return accumulator + object.amount; }, 0);
    },
    getAllocationDetail() {
      var vm = this;
      this.$http.get('/api/collections/getAllocationDetail/' + this.allocationId)
            .then(response => {
              if(response.data.success) {
                vm.allocation = response.data.data.allocation;
                vm.invoices = response.data.data.invoices;
                vm.invoices.forEach(element => {
                    element.type = element.sale.type + '-' + element.sale.letter;
                    element.number = element.sale.pos + '-' + element.sale.number;
                });

                vm.payments = response.data.data.payments
                vm.payments.forEach(element => {
                    element.type = element.receipt_id != null? 'RC': 'RT';
                    element.number = element.receipt_id != null? element.receipt.number: element.withholding.number;
                });
               
              }
            })
            .catch(error => {
              alert(error);
            })
    },
  },
  mounted() {
    this.getAllocationDetail();
  }
}
</script>
<style>

.btn-orange {
    background: #f5c05d;
    background: -ms-linear-gradient(top, #f5c05d 0%, #F2AB27 100%);
    background: linear, to bottom, #f5c05d 0%, #F2AB27 100%;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='#ffffff',GradientType=0 );
    color: #fff;
    text-shadow: 0px 0px 2px #e4931b;
    border: 1px solid #F2AB27;
    border-bottom: 1px solid #e4931b;
}
</style>

<style lang="css">
    .fix-footer {
      padding-bottom: 100px;
    }
    .receipt-footer {
      position: fixed;
      bottom:0;
      left:0;
      z-index:100;
      background:rgb(242, 244, 247,0.5);
      border-top: 1px solid #4477BD;
      text-align: right;
      padding:20px;
      width: 100%;
    }
    .totals {
      margin-bottom: 0;
    }
    
    .totals li {
      border-bottom:1px solid #d3d9e4;
      padding:10px 0;
      font-size: 1.75rem;
    }
    
    .totals li:first-child {
      padding-top:0;
    }
    
    .totals li:last-child {
      border-bottom:0;
      padding-bottom:0;
    }
    
    .totals li .form-group .input-group .input-group-addon {
      padding:0 12px;
      background:#fff;
      border-radius: 0px;
      border-left:0;
      border-top:0;
    }
    
    .totals li .form-group .input-group input {
      border-radius:0px;
      border-right: 0;
      border-top:0;
      background: #fff;
      height: 20px;
    }
    
    .affix {
      margin-right:15px;
    }
    
    
    </style>
