<template>
    <b-nav align="center" class="lasubnav d-flex align-items-center py-1">   
      <b-nav-item><router-link :to="PrincipalLink" class="text-white" active-class="active">Principal</router-link></b-nav-item>
      <b-nav-item><router-link :to="puntosVentasLink" class="text-white" active-class="active">Puntos de venta</router-link></b-nav-item>
      <v-guard :permissions="['CONFIGURACION_PROMOCIONES']">
      <b-nav-item><router-link :to="promocionesLink" class="text-white" active-class="active">Promociones</router-link></b-nav-item>
      </v-guard>
      <b-nav-item><router-link :to="usuariosSistemaLink" class="text-white" active-class="active">Usuarios del sistema </router-link></b-nav-item>
      <b-nav-item><router-link :to="rolesPermisosLink" class="text-white" active-class="active">Roles y permisos</router-link></b-nav-item>
      <v-guard :permissions="['ECOMMERCE']">
      <b-nav-item><router-link :to="ecommerceLink" class="text-white" active-class="active">E-commerce</router-link></b-nav-item>
      </v-guard>
      <b-nav-item><router-link :to="generalLink" class="text-white" active-class="active">General</router-link></b-nav-item>
      <b-nav-item><router-link :to="tarjetasLink" class="text-white" active-class="active">Tarjetas</router-link></b-nav-item>
      <!-- <BranchofficeSelect v-if="getHasPermission" /> -->
    </b-nav>

  </template>
  
  <script>
  // import BranchofficeSelect from "./../sub-components/branchoffice-select.vue";
  export default {
   /*  components: {
      // BranchofficeSelect
    },
    data : function () { 
      return {
        hasPermission : false
      }
    }, */
    computed: {
      /* getHasPermission() {
          return this.hasPermission
      }, */
      PrincipalLink() {
        return { name: 'Principal' }
      },
      puntosVentasLink() {
        return { name: 'Puntos de venta' }
      },
      promocionesLink() {
        return { name: 'Promociones' }
      },
      usuariosSistemaLink() {
        return { name: 'Usuarios del sistema' }
      },
      rolesPermisosLink() {
        return { name: 'Roles y permisos' }
      },
      ecommerceLink() {
        return { name: 'E-commerce' }
      },
      generalLink() {
        return { name: 'General' }
      },
      tarjetasLink() {
        return { name: 'Tarjetas' }
      },
    },
    /*  methods: {
      getAllBranchofficesFundsPermission() {
        this.$http
          .get("/api/configs/role/permissions/names", {
             headers: { 
              Authorization: "Bearer " + localStorage.getItem("token")
            },
          })
          .then((response) => {
            this.hasPermission = response.data.data.includes('ACCESS_ALL_BRANCHOFFICES_FUNDS');
          })
      },
    },
    created() {
      this.getAllBranchofficesFundsPermission();
    }, */
  }
  </script>
  
  <style scoped>
.lasubnav {
  animation: opacity 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  background: #2d538a;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.active {
  font-weight: bold;
  color: white; 
  background-color: orange;
  border-radius: 10px 10px 0px 0px;
  padding: 11px;
}
</style>