<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
        <label class="control-label" for="input-live">Caja de destino:</label>
        <b-form-select id="fund" name="fund" v-model="transfer.to_fund_id" @change="getFundSelected">
          <option :value="null">Ninguna</option>
          <option v-for="fund in funds" :key="fund.id" :value="fund.id"> {{ fund.name }}</option>
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group>
        <label class="control-label" for="input-live">Monto:</label>
        <b-form-input v-model="transfer.amount" type="number"></b-form-input>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group v-if="fund.currency !== toFund.currency && toFund.type >= 0">
        <label class="control-label" for="input-live">Cotizacion:</label>
          <b-form-input type="text" disable class="disable" v-model="dolarBlue">{{ dolarBlue }}</b-form-input>
      </b-form-group>
    </b-col>
    <b-col class="d-flex justify-content-end mt-4">
        <b-button variant="orange" size="lg" :disabled="disabledSave" @click="saveTransfer()">Guardar transferencia</b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  mounted() {
    this.getFunds();
    this.resetForm();
    this.getDolarBlue();
    this.getFund();
  },

  methods: {
    filterFund() {
      this.funds = this.funds.filter((c) => c.id != this.fundId);
    },

    getFunds() {
      var vm = this;
      this.$http
        .get("/api/funds/list")
        .then((response) => {
          vm.funds = response.data.data;
        })
        .then(function () {
          vm.filterFund();
        });
    },

    saveTransfer() {
      var vm = this;
      vm.transfer.dolar = vm.dolarBlue;
      vm.disabledSave = true; 
      this.$http
        .post("/api/funds/transfer/" + this.fundId, this.transfer)
        .then((response) => {
          
          vm.resetForm();
          vm.$emit("confirmsave");
          vm.disabledSave = false;

        });
    },

    resetForm() {
      this.transfer = Object.assign({}, this.emptyTransfer);
    },

    getDolarBlue(){
      var vm = this;
      fetch('https://api.bluelytics.com.ar/v2/latest')
      .then(response => response.json())
      .then(data => vm.dolarBlue = data.blue.value_sell);      
    },

    getFundSelected() {
      var vm = this;
      var id = document.getElementById("fund").value;
      if(id !== ''){
        this.$http.get("/api/funds/getFund/"+ id)
        .then((response) => {
          vm.toFund = response.data.data;
        })
      }else{
        vm.toFund = [];
      }
    },

    getFund() {
      var vm = this;
      this.$http.get("/api/funds/getFund/"+ this.fundId)
      .then((response) => {
        vm.fund = response.data.data;
      })
      
      
    },
  },
  props: ["fundId"],

  data() {
    return {
      transfer: {
        to_fund_id: null,
        amount: null,
        dolar: 0,
      },

      emptyTransfer: {
        to_fund_id: null,
        amount: null,
      },

      transfers: [],

      funds: [],
      dolarBlue: null,
      fund: [],
      toFund: [],
      disabledSave: false,
    };
  },
};
</script> 