<template>

    <div class="typeahead__container">
      <i class="glyphicon glyphicon-asterisk" v-if="loading"></i>
      <template v-else>
        <i v-show="isEmpty">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
          </svg>
        </i>
        <i v-show="isDirty" @click="reset">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>
        </i>
      </template>

      <input :name="name"
            type="text"
            class="form-control"
            :ref="customClass"
            autocomplete="off"
            v-model="query"
            :placeholder="placeh"
            @keydown.down="down"
            @keydown.up="up"
            @keydown.enter="hit"
            @keydown.esc="reset"
            @blur="resetCustom"
            @input="update"/>

      <ul v-show="hasItems">
        <li v-for="(item, key) in items" :class="activeClass(key)" @mousedown.prevent="hit" @mousemove="setActive(key)" :key="key">
          <strong v-if="item.code" class="name" v-text="item.code + ' - '"></strong>
          <span class="name" v-text="item.name + ' - '"></span>
          <span class="screen-name" v-text="item.screen_name"></span>
          <strong v-if="item.price" class="screen-name" v-text="formatoDinamicoMoneda(item.price)"></strong>
        </li>
      </ul>
    </div>

</template>

<script>
import VueTypeahead from 'vue-typeahead'
import formatMoneyMixin from "../../../../mixins/formatMoneyMixin";

  export default {
    extends: VueTypeahead,
    mixins: [formatMoneyMixin],
    props: ['default', 'externalsrc', 'limitChars', 'cleanOn', 'customClass', 'placeh', 'name'],
    data () {
      return {
        query: '',
        id : {},
        src: this.externalsrc,
        minChars: this.limitChars
      }
    },
    mounted: function () {
      this.firsttime();
    },
    watch: {
      id: function (value) {
        this.$emit('choosed',value);
      }
    },
   methods: {
       onHit (item) {
         this.query = (this.cleanOn) ? '' : item.name;
         this.id = item
         this.items = [];
       },
       firsttime: function () {
         var app = this;
         if (app.default) window.axios.get(this.externalsrc+'?q='+app.default)
         .then(function (response) {
           if (response.data.data.length>0) {
             app.id=response.data.data[0];
             app.query=response.data.data[0].name;
           }
         })
         .catch(function (error) {
           console.log(error);
         });
       },
       resetCustom(){
        if (this.id.id===0){
          this.reset()
        }
        this.items = [];
      },

      prepareResponseData (response) {
        this.id.id=0;
        return response.data;
      }
    }
  }
</script>
