<template>
  <b-container fluid class="mt-3">
    <div class="row mt-3">
        <b-col class="d-flex justify-content-start">
          <h4>Existencias en stock</h4>
        </b-col>
    </div>
    <transition name="fade">
      <div v-if="showAlert" class="alert alert-danger alert-dismissible fade show d-flex" role="alert">
        <i class="bi bi-info-circle mr-2"></i>
        <strong>Ayuda:</strong>
        <p class="ml-1">
          Para modificar los valores, ingrese el total de stock a sumar y luego presione la tecla Enter.
          Por ejemplo, si desea sumar 10, borre el número existente en la celda, ingrese 10 y luego presione Enter.
          En caso de querer restar stock, ingrese el total a sustraer con el signo menos adelante y luego presione Enter.
          Por ejemplo, si desea restar 10, borre el número existente en la celda, ingrese "-10" y luego presione Enter.
        </p>
        <button type="button" class="button-close ml-5" data-bs-dismiss="alert" aria-label="Close" @click="closeAlert">
          <i class="bi-x"></i>
        </button>
      </div>
    </transition>
    <div class="card bg-light rounded-sm p-3 my-2 border">
      <div class="row d-flex justify-content-between">
        <div class="col col-md-2">
          <!------------- FILTROS ----------------->
          <input v-model="filters.qname" class="form-control" placeholder="Buscar nombre...">
        </div>
        <div class="col col-md-2">
          <input v-model="filters.qcode" class="form-control" placeholder="Buscar por codigo...">
        </div>
        <!------------- BOTONES ----------------->
        <div class="col col-md-1">
          <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary" value="Exportar CSV">
        </div>
      </div>  
    </div>
  <!------------ TABLA CON EDITOR---------->
    <b-row>
      <b-col class="mt-2" cols="12">
        <b-table-simple striped small caption-top responsive class="mt-2">
          <b-thead head-variant="light">
            <b-tr>
              <b-th>Producto <a href="#" v-on:click="sortList('name')" class="texto-negro">▼</a></b-th>
              <b-th v-for="warehouse in warehouses" :key="warehouse.id">{{ warehouse.name }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(product, index) in products" :key="index">
              <b-td>{{ product.name }}</b-td>
              <b-td v-for="warehouse in warehouses" :key="warehouse.id">
                <div class="input-group">
                  <input v-model="product.stock[warehouse.id]" @input="prepareSend(product, warehouse.id)" @blur="stopEditingProduct" @keyup.enter="saveStock(product, warehouse.name)" class="input-cell bg-transparent">
                  <span class="input-group-text bg-transparent">U</span>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <!-- PAGINACION -->
    <div class="row vdtnet-footer">
      <div class="col-sm-10 col-md-3">
        <div class="vdtnetable1_info">
            Mostrando {{ (filters.page - 1) * filters.itemsPerPage + 1 }} a {{ Math.min(filters.page * filters.itemsPerPage, products.length) }} de {{ totalItems }} registros
        </div>
      </div>
      <div class="col-sm-10 col-md-7">
        <div class="dataTables_paginate paging_simple_numbers">
          <ul class="pagination">
            <li class="page-item" v-bind:class="[{'disabled': filters.page==1}]">
              <a class="page-link" href="#" v-on:click="prevPage()">Anterior</a>
            </li>
            <li v-if="(filters.page - (pageInterval/2))>0" class="page-item">
              <a class="page-link" href="#" v-on:click="prevPage()">...</a>
            </li>
            <template v-for="index in totalPages">
              <li :key="index" v-if="inIntervalPage(index)" class="page-item" v-bind:class="[{'active': filters.page==index}]">
                <a class="page-link" href="#" v-on:click="changePage(index)">{{index}}</a>
              </li>
            </template>
            <li v-if="totalPages>(filters.page + (pageInterval/2))" class="page-item">
              <a class="page-link" href="#" v-on:click="nextPage()">...</a>
            </li>
            <li class="page-item" v-bind:class="[{'disabled': filters.page>=totalPages}]">
              <a class="page-link" href="#" v-on:click="nextPage()">Siguiente</a>
            </li>
          </ul>
        </div>
        <div class="dataTables_length">
          <label class="mr-2">Mostrar: </label>
          <b-form-select v-model="filters.itemsPerPage" style="width: 60px;">
            <option :value="15" selected="selected">15</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
          </b-form-select>
          <label class="ml-2 mr-5">registros</label>
        </div>
      </div>
    </div>
  </b-container>
</template>

  <script>
  
  export default {
    data: function() {
      return {
        filters:{
          qname: '',
          qcode: '',
          page:1,
          itemsPerPage:15,
          active:1,
          sorting:{
            col:'',
            order:'desc'
          }
        },
        isLoading: false,
        warehouses: [],
        products: [],
        totalPages: 1,
        pageInterval: 16,
        totalItems: 0,
        showAlert: true,
        movement: {
          product_id: null,
          quantity: null,
          type: null,
          destination_select: null,
          reason: "Movimiento manual desde Existencias en stock"

        },
        
      }
    },
    mounted() {
      this.getWarehouses();
      this.getStock();
    },
    methods: {
      getWarehouses(){
        axios.get('/api/warehouses/list')
        .then(response => {
          if(response.status == 200){
            this.warehouses = response.data.data;
          }
        })
      },
      getStockWithDelay(){
        clearTimeout(this.timeoutId);
          this.timeoutId = setTimeout(()=>this.getStock(), 1500);
      },
      getStock(){
        let vm = this;
        axios.post('/api/existences/list', vm.filters)
        .then(response => {
          if(response.status == 200){
            vm.products = response.data.data.products;
            vm.totalPages = response.data.data.totalPages;
            vm.totalItems = response.data.data.totalItems;
          }
        })
      },
      refreshTable() {
        var vm = this;
        if (vm.$refs.table !== undefined) vm.$refs.table.reload();
      },
      exportCSV(){
        this.$http.post(process.env.VUE_APP_BASE_URL+"/api/existences/exportCSV")
        .then((response) => {
        });
      },
      closeAlert() {
        this.showAlert = false;
      },
      sortList(col){
        if(this.filters.sorting.col == col){
            if(this.filters.sorting.order=='asc'){
                this.filters.sorting.order='desc';
            }else{
                this.filters.sorting.order='asc';
            }
        }else{
            this.filters.sorting.col=col;
            this.filters.sorting.order='asc';
        }
        this.getStock();
      },
      prevPage(){
        if(this.filters.page>1){
            this.filters.page = this.filters.page-1;
            this.getStock();
        }
      },
      nextPage(){
        if (this.filters.page<this.totalPages){
            this.filters.page = this.filters.page +1;
            this.getStock();
        }
      },
      changePage(data){
        this.filters.page = data;
        this.getStock();
      },
      inIntervalPage(index){
        if(this.totalPages<=this.pageInterval){return true;}

        let limitprev = this.filters.page - (this.pageInterval/2);
        let limitnext = this.filters.page + (this.pageInterval/2);

        if(limitprev<0){
            limitprev=0;
            limitnext=this.pageInterval;
        }

        if(index>limitprev && index<limitnext){return true;}

        return false;
      },
      prepareSend(product, warehouse) {
        console.log(warehouse);
        this.movement.product_id = product.id;
        this.movement.quantity = parseInt(product.stock[warehouse]);
        this.movement.destination_select = warehouse;
        console.log('id de producto: ' + this.movement.product_id);
        console.log('stock: ' + this.movement.quantity);
        console.log('sucursal destino: ' + this.movement.destination_select);
        if(this.movement.quantity > 0){
          this.movement.type = "in";
        }else{
          this.movement.type = "out";
          this.movement.quantity = Math.abs(parseInt(product.stock[warehouse]));
        }
      },
      saveStock(product, warehouse){
        let vm = this;
        if (confirm(`Se modificara el total de stock de ${product.name} en el deposito ${warehouse}, los cambios son irreversibles. ¿Está seguro que desea continuar?`) == true){
          this.$http.post("/api/movements/add", this.movement)
          .then((response) => {
            vm.getStock();
          });
        }
      },
      stopEditingProduct() {
        this.editingProductIndex = null;
      },
    },
    watch: {
      'filters.qname': {handler: function (a) {this.getStockWithDelay();}},
      'filters.qcode': {handler: function (a) {this.getStockWithDelay();}},
      'filters.page': {handler: function (a) {this.getStock();}},
      'filters.itemsPerPage': {handler: function (a) {this.getStock();}},
    },
  };
  </script>

  <style>
  .active {
    font-weight: 800;
  }

  .button-close{
    background: transparent;
    border: none;
  }
  
  .input-cell{
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: calc(1.5em + 0.75rem + 2px);
  }

  .input-group {
    justify-content: center;
  }

  .texto-negro{
    color: black;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>