<template>
    <b-container fluid>

        <b-table-simple sticky-header striped small caption-top responsive>
            <b-thead head-variant="light">
                <b-tr role="row">
                    <b-th>Fecha</b-th>
                    <b-th>Numero</b-th>
                    <b-th>Proveedor</b-th>
                    <b-th>Monto</b-th>
                    <b-th class="col-sm-1"></b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr role="row " v-for="(po, index) in payorderListFiltered" :key="index" >
                    <b-td>{{ formatoDate2Fecha(po.created_at) }}</b-td>
                    <b-td><a :href="'/compras/detalle-orden-de-pago/' + po.id ">{{po.number}}</a></b-td>
                    <b-td>{{po.provider_name}}</b-td>
                    <b-td>{{ formatoDinamicoMoneda(po.total)}}</b-td>
                    <b-td class="col-sm-1">
                        <b-button-group>
                            <button title="Descargar PDF" type="button" class="btn btn-light marginButton mr-4" href="javascript:void(0);" @click="getPDF(po.id, po.number)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">'+
                                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>'+
                                </svg>
                            </button>
                            <button title="Cancelar pago" type="button" class="btn btn-light marginButton" @click="cancelPayorder(po.pac_id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
                                </svg>
                            </button>                 
                        </b-button-group>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-pagination>
            <b-ul class="pagination float-right">
                <b-li class="paginate_button previous" v-bind:class="[{'disabled': page==1}]">
                    <a href="#" v-on:click="prevPage()">Anterior</a>
                </b-li>

                <b-li v-if="(page - (pageInterval/2))>0" class="paginate_button">
                    <a href="#" v-on:click="prevPage()">...</a>
                </b-li>

                <template v-for="index in totalPages">

                    <b-li :key="index" v-if="inIntervalPage(index)" class="paginate_button" v-bind:class="[{'active': page==index}]">
                        <a href="#" v-on:click="changePage(index)">{{index}}</a>
                    </b-li>

                </template>

                <b-li v-if="totalPages>(page + (pageInterval/2))" class="paginate_button">
                    <a href="#" v-on:click="nextPage()">...</a>
                </b-li>

                <b-li class="paginate_button next" v-bind:class="[{'disabled': page>=totalPages}]">
                    <a href="#" v-on:click="nextPage()">Siguiente</a>
                </b-li>
            </b-ul>
        </b-pagination>
    </b-container>
</template>

<script>

import formatDateMixin from "../../../mixins/formatDateMixin";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";
import myaxios from 'axios';

export default {
    props: ['provider','fromDate','toDate','watchTarget'],
    mixins: [formatDateMixin,formatMoneyMixin],
    data() {
        return {
            payorderList:[],
            poFilter:'',
            page:1,
            rowCount:9,
            totalItems:0,
            totalPages:1,
            pageInterval:22,
        }
    },
    mounted(){
        this.getPayorderList();
    },
    computed: {
        payorderListFiltered:{
            get: function () {
                let vm = this;
                return this.payorderList.filter(e => e.number.includes(vm.poFilter));
            }
        }
    },
    methods: {
        getPayorderList(){
            var vm= this;
            var outdata = {
                'provider_id': vm.provider,
                'fromDate':vm.fromDate,
                'toDate':vm.toDate,
                'page':vm.page,
                'rowCount':vm.rowCount
            };
            axios.post('/api/payments/getPayorders', outdata)
            .then(response => {
                if(response.data) {
                vm.payorderList = response.data.data.list;
                vm.totalItems=response.data.data.totalItems;
              }
            })
            .catch(error => {
              alert(error);
            })
        },
        cancelPayorder(value){
            this.$emit('cancelPay',value);
        },
        prevPage(){
            this.page = this.page-1;
            this.getPayorderList();
        },
        nextPage(){
            this.page = this.page +1;
            this.getPayorderList();
        },
        changePage(data){
            this.page = data;
            this.getPayorderList();
        },
        inIntervalPage(index){
            if(this.totalPages<=this.pageInterval){return true;}

            let limitprev = this.page - (this.pageInterval/2);
            let limitnext = this.page + (this.pageInterval/2);

            if(limitprev<0){
                limitprev=0;
                limitnext=this.pageInterval;
            }

            if(index>limitprev && index<limitnext){
                return true;
            }

            return false;
        },
        getPDF(id, payorderNumber){
            axios.get('/api/payments/poPDF/' + id)
            .then(res => {
                const uninterceptedAxiosInstance = myaxios.create();
                uninterceptedAxiosInstance.get(res.data, { responseType: 'blob' })
                .then((response) => {
                    const blob = new Blob([response.data], { type: 'application/pdf' })
                    const currentDate = new Date();
                    const formattedDate = currentDate.toLocaleDateString().replace(/\//g, '-');
                    const fileName = 'Orden_de_pago_'+ payorderNumber +'_'+this.payorderListFiltered[0].provider_name + '_' + formattedDate + '.pdf';
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = fileName;
                    console.log(link.download);
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
                .catch(error => {
                alert(error);
                })
                .catch(error => {
                    alert(error);
                })
            })    
        }
    },
    watch: {
      'provider': {
          handler: function (a) {
              this.page=1;
              this.getPayorderList();
            }
      },
      'fromDate': {
          handler: function (a) {this.getPayorderList();}
      },
      'toDate': {
          handler: function (a) {this.getPayorderList();}
      },
      'watchTarget': {
          handler: function (a) {this.getPayorderList();}
      },
      'payorderList':{
          handler: function(a){
              this.totalPages = Math.ceil(this.totalItems / this.rowCount);
          }
      }
  },

}
</script>