

export default {
  data: function() {
    return {
      configurations: {
        CURRENCY_DECIMAL_SEPARATOR: '.',
        CURRENCY_DECIMAL_DIGITS: '2',
        CURRENCY_THOUSAND_SEPARATOR: ',',
      },
      configurationsUSD: {
        CURRENCY_DECIMAL: ',',
        CURRENCY_DIGITS: '2',
        CURRENCY_THOUSAND: '.',
      },
      configurationLoaded: false,
    };
  },
  mounted() {
    this.getConfigurations();
  },
  methods: {
    getConfigurations() {
      var vm = this;
      vm.configurationLoaded = false;
      this.$http.get('/api/configs/getConfigurations').then((response) => {
        vm.configurations = response.data.data;
        vm.configurationLoaded = true;
      });
    },
    formatoDinamicoMoneda(number) {
      const numeroFormateado = $.fn.dataTable.render
        .number(
          this.configurations.CURRENCY_DECIMAL_SEPARATOR,
          this.configurations.CURRENCY_THOUSAND_SEPARATOR,
          Number(this.configurations.CURRENCY_DECIMAL_DIGITS)
        )
        .display(number || 0);
      return `$ ${numeroFormateado}`;
    },
    formatoDinamicoMonedaUSD(number) {
      const numeroFormateado = $.fn.dataTable.render
        .number(
          this.configurationsUSD.CURRENCY_DECIMAL,
          this.configurationsUSD.CURRENCY_THOUSAND,
          Number(this.configurationsUSD.CURRENCY_DIGITS)
        )
        .display(number || 0);
      return `US$ ${numeroFormateado}`;
    },
    formatoMoneda2Dec(amount) {
      return this.$options.filters.formatoMoneda2Dec(amount);
    },
  },
  filters: {
    // formato con 2 decimales. Si es n° entero muestra ,00
    formatoMoneda2Dec: function(amount) {
      return (
        '$ ' +
        (amount
          ? parseFloat(amount).toLocaleString('de-DE', {
              style: 'decimal',
              currency: 'ARS',
              minimumFractionDigits: 2,
            })
          : '0,00')
      );
    },
    // formato con 2 decimales si tiene decimales, y sin decimales si es n° entero.
    formatoMonedaOptDec: function(amount) {
      if (amount) {
        if (amount % 1 !== 0) {
          return (
            '$ ' +
            (amount
              ? parseFloat(amount).toLocaleString('de-DE', {
                  style: 'decimal',
                  currency: 'ARS',
                  minimumFractionDigits: 2,
                })
              : '0,00')
          );
        } else {
          return (
            '$ ' +
            (amount
              ? parseFloat(amount).toLocaleString('de-DE', {
                  style: 'decimal',
                  currency: 'ARS',
                  minimumFractionDigits: 0,
                })
              : '0,00')
          );
        }
      } else {
        return '0,00';
      }
    },
    formatoAme2Dec: function(amount) {
      return (
        'US$ ' +
        (amount
          ? parseFloat(amount).toLocaleString('en-US', {
              style: 'decimal',
              currency: 'USD',
              minimumFractionDigits: 2,
            })
          : '0.00')
      );
    },
  },
};
