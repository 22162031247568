<template>

  <b-form-select v-bind:value="value" v-on:input="$emit('input', $event)" >
    <b-form-select-option :value="null" disabled>Seleccionar</b-form-select-option>
    <b-form-select-option v-for="banco in listadoBancos" :key="banco.codigo" :value="banco.denominacion">
      {{ banco.denominacion }}</b-form-select-option>
  </b-form-select>


</template>
<script>
export default {
  data: () => {
    return {};
  },
  props: ['value'],
  computed: {
    listadoBancos() {
      return [

        {
          "codigo": "00007",
          "denominacion": "BANCO GALICIA S.A.U."
        },
        {
          "codigo": "00011",
          "denominacion": "BANCO NACION ARGENTINA"
        },
        {
          "codigo": "00014",
          "denominacion": "BANCO PROVINCIA BS AS"
        },
        {
          "codigo": "00015",
          "denominacion": "I.C.B.C."
        },
        {
          "codigo": "00016",
          "denominacion": "CITIBANK N.A."
        },
        {
          "codigo": "00017",
          "denominacion": "BANCO BBVA ARG S.A."
        },
        {
          "codigo": "00020",
          "denominacion": "BANCO PROV CORDOBA S.A."
        },
        {
          "codigo": "00027",
          "denominacion": "BANCO SUPERVIELLE S.A."
        },
        {
          "codigo": "00029",
          "denominacion": "BANCO CIUDAD BUENOS AIRES"
        },
        {
          "codigo": "00034",
          "denominacion": "BANCO PATAGONIA S.A."
        },
        {
          "codigo": "00044",
          "denominacion": "BANCO HIPOTECARIO S.A."
        },
        {
          "codigo": "00045",
          "denominacion": "BANCO DE SAN JUAN S.A."
        },
        {
          "codigo": "00065",
          "denominacion": "BANCO MUNICIPAL DE ROSARIO"
        },
        {
          "codigo": "00072",
          "denominacion": "BANCO SANTANDER RIO S.A."
        },
        {
          "codigo": "00083",
          "denominacion": "BANCO DEL CHUBUT S.A."
        },
        {
          "codigo": "00086",
          "denominacion": "BANCO DE SANTA CRUZ S.A."
        },
        {
          "codigo": "00093",
          "denominacion": "BANCO LA PAMPA SOCIEDAD"
        },
        {
          "codigo": "00094",
          "denominacion": "BANCO DE CORRIENTES S.A."
        },
        {
          "codigo": "00097",
          "denominacion": "BANCO PROVINCIA NEUQUEN S.A."
        },
        {
          "codigo": "00143",
          "denominacion": "BRUBANK S.A.U."
        },
        {
          "codigo": "00147",
          "denominacion": "BANCO INTERFINANZAS S.A."
        },
        {
          "codigo": "00150",
          "denominacion": "HSBC BANK ARGENTINA S.A."
        },
        {
          "codigo": "00158",
          "denominacion": "OPEN BANK ARGENTINA S.A."
        },
        {
          "codigo": "00165",
          "denominacion": "JPMORGAN CHASE BANK"
        },
        {
          "codigo": "00191",
          "denominacion": "BANCO CREDICOOP COOPERATIVO"
        },
        {
          "codigo": "00198",
          "denominacion": "BANCO DE VALORES S.A."
        },
        {
          "codigo": "00247",
          "denominacion": "BANCO ROELA S.A."
        },
        {
          "codigo": "00254",
          "denominacion": "BANCO MARIVA S.A."
        },
        {
          "codigo": "00259",
          "denominacion": "BANCO ITAU S.A."
        },
        {
          "codigo": "00266",
          "denominacion": "BNP PARIBAS"
        },
        {
          "codigo": "00268",
          "denominacion": "BANCO PROVINCIA TIERRA DEL FUEGO"
        },
        {
          "codigo": "00269",
          "denominacion": "BANCO REPUBLICA DEL URUGUAY"
        },
        {
          "codigo": "00277",
          "denominacion": "BANCO SAENZ S.A."
        },
        {
          "codigo": "00281",
          "denominacion": "BANCO MERIDIAN S.A."
        },
        {
          "codigo": "00285",
          "denominacion": "BANCO MACRO S.A."
        },
        {
          "codigo": "00299",
          "denominacion": "BANCO COMAFI S.A."
        },
        {
          "codigo": "00300",
          "denominacion": "BANCO INVERSION Y COMERCIO EXTERIOR"
        },
        {
          "codigo": "00301",
          "denominacion": "BANCO PIANO S.A."
        },
        {
          "codigo": "00305",
          "denominacion": "BANCO JULIO SOCIEDAD ANONIMA"
        },
        {
          "codigo": "00309",
          "denominacion": "BANCO RIOJA S.A."
        },
        {
          "codigo": "00310",
          "denominacion": "BANCO DEL SOL S.A."
        },
        {
          "codigo": "00311",
          "denominacion": "NUEVO BANCO DEL CHACO S. A."
        },
        {
          "codigo": "00312",
          "denominacion": "BANCO VOII S.A."
        },
        {
          "codigo": "00315",
          "denominacion": "BANCO DE FORMOSA S.A."
        },
        {
          "codigo": "00319",
          "denominacion": "BANCO CMF S.A."
        },
        {
          "codigo": "00321",
          "denominacion": "BANCO DE SANTIAGO DEL ESTERO S.A."
        },
        {
          "codigo": "00322",
          "denominacion": "BANCO INDUSTRIAL S.A."
        },
        {
          "codigo": "00330",
          "denominacion": "NUEVO BANCO SANTA FE S.A."
        },
        {
          "codigo": "00331",
          "denominacion": "BANCO CETELEM ARGENTINA S.A."
        },
        {
          "codigo": "00332",
          "denominacion": "BANCO DE SERVICIOS FINANCIEROS S.A."
        },
        {
          "codigo": "00336",
          "denominacion": "BANCO BRADESCO ARGENTINA S.A.U."
        },
        {
          "codigo": "00338",
          "denominacion": "BANCO DE SERVICIOS Y TRANSACCIONES S.A."
        },
        {
          "codigo": "00339",
          "denominacion": "RCI BANQUE S.A."
        },
        {
          "codigo": "00340",
          "denominacion": "BACS BANCO DE CREDITO Y SECURITIZACION S"
        },
        {
          "codigo": "00341",
          "denominacion": "BANCO MASVENTAS S.A."
        },
        {
          "codigo": "00384",
          "denominacion": "WILOBANK S.A.U."
        },
        {
          "codigo": "00386",
          "denominacion": "NUEVO BANCO DE ENTRE RIOS S.A."
        },
        {
          "codigo": "00389",
          "denominacion": "BANCO COLUMBIA S.A."
        },
        {
          "codigo": "00426",
          "denominacion": "BANCO BICA S.A."
        },
        {
          "codigo": "00431",
          "denominacion": "BANCO COINAG S.A."
        },
        {
          "codigo": "00432",
          "denominacion": "BANCO DE COMERCIO S.A."
        },
        {
          "codigo": "00435",
          "denominacion": "BANCO SUCREDITO REGIONAL S.A.U."
        },
        {
          "codigo": "00448",
          "denominacion": "BANCO DINO S.A."
        },
        {
          "codigo": "00515",
          "denominacion": "BANK OF CHINA LIMITED SUCURSAL BUENOS AI"
        },
        {
          "codigo": "44059",
          "denominacion": "FORD CREDIT COMPAÑIA FINANCIERA S.A."
        },
        {
          "codigo": "44077",
          "denominacion": "COMPAÑIA FINANCIERA ARGENTINA S.A."
        },
        {
          "codigo": "44088",
          "denominacion": "VOLKSWAGEN FINANCIAL SERVICES COMPAÑIA F"
        },
        {
          "codigo": "44090",
          "denominacion": "IUDU COMPAÑÍA FINANCIERA S.A."
        },
        {
          "codigo": "44092",
          "denominacion": "FCA COMPAÑIA FINANCIERA S.A."
        },
        {
          "codigo": "44093",
          "denominacion": "GPAT COMPAÑIA FINANCIERA S.A.U."
        },
        {
          "codigo": "44094",
          "denominacion": "MERCEDES-BENZ COMPAÑÍA FINANCIERA ARGENT"
        },
        {
          "codigo": "44095",
          "denominacion": "ROMBO COMPAÑÍA FINANCIERA S.A."
        },
        {
          "codigo": "44096",
          "denominacion": "JOHN DEERE CREDIT COMPAÑÍA FINANCIERA S."
        },
        {
          "codigo": "44098",
          "denominacion": "PSA FINANCE ARGENTINA COMPAÑÍA FINANCIER"
        },
        {
          "codigo": "44099",
          "denominacion": "TOYOTA COMPAÑÍA FINANCIERA DE ARGENTINA"
        },
        {
          "codigo": "45030",
          "denominacion": "NARANJA DIGITAL COMPAÑÍA FINANCIERA S.A."
        },
        {
          "codigo": "45056",
          "denominacion": "MONTEMAR COMPAÑIA FINANCIERA S.A."
        },
        {
          "codigo": "45072",
          "denominacion": "TRANSATLANTICA COMPAÑIA FINANCIERA S.A."
        },
        {
          "codigo": "65203",
          "denominacion": "CREDITO REGIONAL COMPAÑIA FINANCIERA S.A"
        }
      ];
    }
  }
}
</script>
