<template>
  <div>
    <subnavbar-ventas></subnavbar-ventas>
    <router-view config="config" payment-methods="payment_methods" ></router-view>
  </div>
</template>

<script>
import SubnavbarVentas from '../navbars/Subnavbar-ventas.vue'
export default {
  components: {
    SubnavbarVentas
  }
};
</script>