import branchofficesApi from '../../api/branchoffices'

// initial state
const state = () => ({
    loading: false,
    branchoffices: [],
    selectedBranchofficeId: ""
})
  
  // getters
const getters = {
}
  
  // actions
const actions = {
  async getAllBranchoffices ({ commit }) {
    const branchoffices = await branchofficesApi.getBranchoffices()
    commit('setBranchoffices', branchoffices)
  },
  readSelectedBranchofficeId ({ commit }) {
    var localId = localStorage.getItem('branchofficeId');
    localId = localId == undefined ? "" :localId; 
    commit('setSelectedBranchofficeId', localId)
  },
  setSelectedBranchofficeId ({ commit }, selectedBranchofficeId) {
    localStorage.branchofficeId = selectedBranchofficeId;
    commit('setSelectedBranchofficeId', selectedBranchofficeId)
  },
  async getLoading({ commit }) {
    commit('setLoading', true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));  
    } catch (error) {
    
    } finally {
      commit('setLoading', false);
    }
  }
}
  
// mutations
const mutations = {
  setBranchoffices (state, branchoffices) {
    state.branchoffices = branchoffices
  },
  setSelectedBranchofficeId (state, selectedBranchofficeId) {
    state.selectedBranchofficeId = selectedBranchofficeId
  },
  setLoading(state, value) {
    state.loading = value;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}