<template>
  <b-container fluid>
    <!-- -------------------- CABECERA -------------------------- -->
    <div class="stats">
        <b-row>
          <h2>
            <label for="">Comisiones</label>
          </h2>
          <div class="col-lg-12 text-center">
            <div class="stat">
              <div class="number"> {{ formatoDinamicoMoneda(balance)}} </div>
              <div class="desc"> Balance de comisiones de {{clerk.name}} </div>
            </div>
          </div>
        </b-row>
    </div>

    <!-- -------------------- LIQUIDAR -------------------------- -->
    <b-row class="d-flex justify-content-end">
      <b-col cols="md-6 my-2">
        <b-card>
          <b-card-header>
            <h6 v-html="'Liquidar por monto' + commissionDescription"></h6>
          </b-card-header>
            <b-row class="mt-2">
              <b-col cols="md-5">
                <label class="control-label" for="input-live"><b>Caja de origen:</b></label>
                <b-form-select class="form-control" v-model="fund">
                  <option v-for="fund in funds" :key="fund.id" :value="fund">{{ fund.name }}</option>
                </b-form-select>
              </b-col>
              
              <b-col cols="md-5">
                <label class="control-label"><b>Monto:</b></label>
                <b-form-input v-model="amount" name="amount" type="number" class="form-control" min="0" step="0.01" value ="0.00" required>
                </b-form-input>
              </b-col>

              <b-col cols="md-2" class="d-flex justify-content-end mt-4">
                <b-button class="ml-2" variant="light" size="lg" @click=liquidarPorMonto()>Liquidar</b-button>
              </b-col>
            </b-row>

  
        </b-card>
      </b-col>
    </b-row>

    <b-row class="d-flex align-items-center">
      <b-col cols="md-8" class="form-inline d-flex justify-content-end">
          <input v-model="historical" type="checkbox" value="true" checked="disabled">
          <label for="historical" class="invoiced">Mostrar histórico</label>
      </b-col>
    </b-row>    
      <!-- -------------------- TABLA -------------------------- -->
      <b-row>
        <div class="col-md-12">
          <vdtnet-table
            :fields="fields"
            :opts="opts"
            :selectCheckbox="1"
            @partialSelect="partialSelect"
            @dischargeOne="dischargeOne"
            @removeOne="removeOne"
            ref="table"
          /> 
        </div>
      </b-row>
  </b-container>
</template>

<script>
import VdtnetTable from 'vue-datatables-net';
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";
import formatDateMixin from "../../../mixins/formatDateMixin";
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-buttons-bs4';

export default {
  props: ["salesClerkId"],
  components: {
    VdtnetTable
  },
  mixins: [formatMoneyMixin, formatDateMixin],
  data: function() {
    return {
      historical:false,
      opts: {        
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/salesclerks/getCommissions/" + this.salesClerkId,
          type: 'GET',
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
          data: (d)=> {
              d.historical= this.historical;
              return d;
            }
          },
          pageLength: 50,
          searching: true,
          searchDelay: 1500,
          destroy: true,
          ordering: true,
          lengthChange: true,
          serverSide: true,
          fixedHeader: true,   
          proccessing: false,
          bDestroy: true,    
          dom: 'Bfrtip',
          buttons: [
              {
                  extend: 'print',
                  text: 'Imprimir seleccionados',
                  exportOptions: {
                      modifier: {selected: true},
                      columns: [1,2,3,4,5,6,7],
                  },
                  customize: function ( win ) {
                      $(win.document.body).find( 'table' )
                          .css('align', 'left');
                      $(win.document.body).find( 'table' )
                          .css( 'font-size', '12pt' );
                      $(win.document.body).find( 'tr' )
                          .append('<td style="width=40pt;"></td>');
                      $(win.document.body).find( 'td' )
                          .css( 'min-width', '100pt' );
                      $(win.document.body).find( 'td' )
                          .css( 'white-space', 'nowrap' );
                  }
              },
          ],
          select: { style: "multi" },
          order: [[ 0, "desc" ]], 
      },
      fields: {
        created_at: { label: "Fecha", searchable: true , render: this.formatoFechaHora},
        client: { label: "Cliente"},
        doc: { label: "Motivo"},
        total: { label: "Monto" , render: this.formatoDinamicoMoneda},
        fee_percent: { label: "%"},
        fee_total: { label: "Comisión" , render: this.formatoDinamicoMoneda},
        debt: { label: "A rendir" , render: this.formatoDinamicoMoneda},
        status: { label: "Estado" },       
        action:  { 
          label: "Accion:",
          defaultContent:
            '<div class="divActions btn-group-sm">' +
              '<button data-action="partialSelect" data-toggle="tooltip" title="Liquidar parcial" type="button" class="btn btn-light">Parcial</button>' +
              '<button data-action="dischargeOne" data-toggle="tooltip" title="Liquidar" type="button" class="btn btn-light">Liquidar</button>' +
            '<button data-action="removeOne" data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light"><i class="fas fa-trash-alt"></i>Eliminar</button>' +
            "</div>",
        },
      },
      clerk: {
        id: null,
        name: null,
        balance: '',
      },
      funds: [],
      fund: {
        id: null,
        name: null
      },
      commissionId:null,
      commissionDescription:'',
      amount:0
    }
  },
  mounted() {
    this.getSalesClerkDetail(this.salesClerkId);
    this.getBalance(this.salesClerkId);
    this.getFunds();
  },
  watch:{
    'historical': { handler: function (a) { this.$refs.table.reload();}} 
  },
  computed: {
    balance: {
      get: function () {
        return this.clerk.balance;
      }
    },
  },
  methods: {   
    getSalesClerkDetail(salesClerkId){
        var vm= this;
        this.$http.get('/api/salesclerks/getSalesclerk/' + salesClerkId)
          .then(response => {
            if(response.data.code == 200) {
              console.log(response);
              vm.clerk.id = response.data.data.id;
              vm.clerk.name = response.data.data.name;
            }
        })
        .catch(error => {
            alert(error);
        })
    },
    getBalance(salesClerkId){
        var vm= this;
        this.$http.get('/api/salesclerks/getBalance/' + salesClerkId)
          .then(response => {
            if(response.data.success) {
                vm.clerk.balance = response.data.data;
            }
        })
        .catch(error => {
            alert(error);
        })
    },
    getFunds() {
      var vm = this;
      this.$http.get("/api/funds/list").then((response) => {
        vm.funds = response.data.data;
      });
    },
    liquidarPorMonto() {
      this.$http.post("/api/salesclerks/newDischarge", {
          clerk_id: this.salesClerkId ,
          amount: this.amount,
          fund_origin: this.fund.id,
          commissionId: this.commissionId
        })
        .then((response) => {
          console.log(response);
          if (response.data.code == 201) {
            this.amount = 0;
            this.commissionId = null;
            this.commissionDescription = '';
            this.getBalance(this.salesClerkId);
            this.$refs.table.reload();
          }
          
      });
    },
    dischargeOne(data) {
      this.$http.post("/api/salesclerks/dischargeOne/" + data.id, {
          fund_origin: this.fund.id
        })
        .then((response) => {
          console.log(response);

          if (response.data.code == 201) {
            this.amount = 0;
            this.getBalance(this.salesClerkId);
            this.$refs.table.reload();
          }
          
      });
    },
    partialSelect(data) {
      if(this.commissionId == data.id)
      {
        this.commissionId = null;
        this.amount = 0;
        this.commissionDescription = '';
        return;
      }
      this.commissionId = data.id;
      this.amount = data.fee_total - data.discharged;
      this.commissionDescription = data.doc;
    },
    removeOne(data){
      this.$http.get("/api/salesclerks/deleteCommission/" + data.id)
        .then((response) => {
          console.log(response);

          if (response.data.code == 201) {
            this.amount = 0;
            this.getBalance(this.salesClerkId);
            this.$refs.table.reload();
          }
        });
    },
  },
}

</script>