<template>
   <pos-documents :configurl="createFromBGLink" />
</template>
<script>
import PosDocuments from "../pos-document/receipt/Receipt.vue";
export default {
   props: ['budgetId'],
   components: {
      PosDocuments
   },
   computed: {
      createFromBGLink() {
         return "/api/sales/createfromBG/" + this.budgetId
      }
   }
};
</script>