<template>
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="form-group" style="margin-bottom: 10px;">
                <input class="form-control-file" type="file" name="import_file" v-on:change="selectedFile($event)"/>
            </div>
            <button class="btn btn-white btn-sm" @click="subir">Subir</button>
        </div>
    </div>
</template>

<script>

export default {
  data: function () {
    return {
        file: null
    };
  },
  methods: {
    selectedFile(event) {
        this.file = event.target.files[0]
    },
    subir() {
        let formData = new FormData();
        formData.append('productfile', this.file);
        this.$http
            .post("/api/products/import", formData, {headers: {
                'Content-Type': 'multipart/form-data'
            }})
            .then((response) => {
                console.log(response.data);
            });
      },
  },
};
</script>