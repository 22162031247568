<template>
   <pos-documents :configurl="createFromSNLink" />
</template>
<script>
import PosDocuments from "../pos-document/receipt/Receipt.vue";
export default {
   props: ['shippingNoteId'],
   components: {
      PosDocuments
   },
   computed: {
      createFromSNLink() {
         return "/api/sales/createfromSN/" + this.shippingNoteId
      }
   }
};
</script>