<template>
  <div class="modal-mask-product">
    <div class="modal-mask-product">
      <div class="modal-dialog">
        <div class="modal-content p-3 rounded">
          <div class="modal-header">
            <h4>
              Ingresá la retencion de ganancias
            </h4>
            <b-button variant="primary size-sm" @click="close">
              <i class="bi bi-x-lg"></i>
            </b-button>
          </div>
          <div class='modal-body'>
            <div class="form-group col-md-12">
                <label>Buscar hasta:</label>
                <datepicker-component v-on:datechoosed="docsDateUpdated"/>
            </div>
            <div class="form-group col-md-12">
                <label class="control-label">Fecha de retención:</label>
                <datepicker-component v-on:datechoosed="dateUpdated"/>
            </div>
            <div class="form-group col-md-12">
                <label>Valor:</label>
                <input type="number" v-model="ew.amount" min="0" step="0.01" class="form-control" placeholder="0.00">
                <br>
            </div>
            <div class="form-group col-md-12">
                <label>Comprobantes disponibles:</label>
                <multiselect class="multiList" v-model="ew.outDocs"
                :options="inDocs"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                track-by="id"
                label="name"
                placeholder="Seleccione comprobantes"/>
                <div v-if="showWarning"><label class="text-danger">Algunos de los documentos seleccionados no eran aptos. Es posible que ya se les haya aplicado una retención.</label></div>
            </div>
            <div class="form-group col-md-12">
              <label for="exampleFormControlTextarea1">Notas:</label>
              <textarea v-model="ew.note" class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type='submit' class='btn btn-lg btn-orange' v-on:click="saveew">Pasar Retencion</button>
          </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import datepickerComponent from '../global/Datepicker-empty.vue';
import Multiselect from 'vue-multiselect'

export default {
  props: ['client_id', 'selectedDocs','fromCC'],
  data() {
    return {
      ew: {
        outDocs: [],
        client_id: this.client_id,
        amount: 0,
        date: null,
        note:'',
      },
      inDocs: [],
      dateDocs: null,
      showWarning: false,
    }
  },
  mounted: function () {
        this.getDocs();
    },
  methods: {
    close() {
      this.$emit('close');
    },
    pluck(array, key) {
        return array.map(o => o[key]);
    },
    saveew() {
      let app = this;
      if (app.ew.amount<=0){
          alert("Ingrese un monto válido");
          return;
      }

      if (app.ew.outDocs.length<=0){
          if (confirm("No se ha seleccionado ningún comprobante para aplicar la retención ¿Desea crearla de todas maneras?")==false){
              return;
          }
      }

      this.$emit('updated', this.ew);
      this.$emit('close');
    },
    dateUpdated: function (date) {
      this.ew.date = date;
    },
    docsDateUpdated: function (date) {
      this.dateDocs = date;
      this.getDocs();
    },
    getDocs: function () {
        var app = this;
        var outdata = {
            'client_id': app.client_id,
            'toDate' : app.dateDocs,
        };

        this.$http.post('/api/clients/getDocsForWithholding', outdata)
        .then((response) => {
                if (response.data.success) {
                    app.inDocs = response.data.data;
                    app.checkSelected();
                }
            }).catch((error) => {
                console.log('error: '+error);
            });
    },
    checkSelected: function(){
        if(this.selectedDocs.length>0){
            var docs = this.selectedDocs.map(o=>o['sale_id']);
            var init= [];

            for (var doc in this.inDocs) {
                console.log(this.inDocs[doc].id);
                if (docs.includes(this.inDocs[doc]['id'])){
                    init.push(this.inDocs[doc]);
                }
            }

            if (init.length<this.selectedDocs.length)
            { this.showWarning = true;}

            this.ew.outDocs=init;
        }
    }
  },
  components: {
    datepickerComponent,
    Multiselect
  }
}
</script>

