<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
            <!-- <div class="modal-header">

            </div> -->
            <div class="modal-body row">
                <slot name="body">
                    <div class="row">
                        <div class="col-md-3"><label class="control-label">Busqueda por grupo:</label></div>
                        <div class="col-md-6"><input v-model="groupFilter" class="form-control"></div>
                    </div>
                    <br>
                    <div v-for="group in groupsFiltered" :key='group.id' class="row">
                        <div class="col-md-12">
                            <div class="panel panel-default">
                                <div style="height: 30px;padding:3px" class="panel-heading">
                                    <div class="col-md-12">
                                        <label class="panel-title">{{group.name}}</label>
                                        <button type="button" class="close" aria-label="Delete" @click="delGroup(group.id)">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="panel-body">
                                    <span v-if="group.attributes.length==0">Aún no hay atributos en este grupo</span>
                                    <div v-for="attribute in group.attributes" :key='attribute.id' class="col-md-2 borderonly">
                                        <input type="checkbox"
                                            v-model="selectedAtts"
                                            v-bind:value="attribute"
                                            class="form-check">

                                        <span v-if="group.class=='color'" class="dot" :style="getHexa(attribute.data)" :title="attribute.name"/>
                                        <small v-if="group.class=='color'" class="">{{attribute.name}}</small>

                                        <span v-if="group.class=='tag'" class="tag">{{attribute.name}}</span>

                                        <span v-if="group.class=='list'">{{attribute.name}}</span>

                                        <button type="button" class="close" aria-label="Delete" @click="delAtt(attribute.id)">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-11 borderonly">
                                            <div v-if="editAttInGroup !=group.id">
                                                <button class="btn btn-link" @click="editAttInGroup = group.id" >
                                                    <b>    Nuevo atributo  >></b>
                                                </button>
                                            </div>
                                            <div v-if="editAttInGroup == group.id">
                                                <div class="col-md-3">
                                                    <input v-model="newAtt.name" placeholder="Nombre" class="form-control">
                                                </div>
                                                <div v-if="group.class=='color'" class="col-md-3">
                                                    <colorpick :color="attColorCode" v-model="attColorCode" />
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="checkbox" v-model="newAtt.active">
                                                    Habilitar para tienda
                                                </div>
                                                <div class="col-md-2">
                                                    <span @click="saveAtt()" class="glyphicon glyphicon-ok-sign ok-payment-method" aria-hidden="true" title="Guardar"></span>
                                                    <span @click="resetAtt()" class="glyphicon glyphicon-minus-sign delete-product" aria-hidden="true" title="Borrar"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div  class="col-md-12">
                            <div v-show="!showNewGroup" class="borderblock">
                                <button class="btn btn-link" @click="showNewGroup = true" >
                                    <b>    Nuevo grupo  >></b>
                                </button>
                            </div>
                            <div v-show="showNewGroup">
                                <div class="col-md-12 borderblock">
                                    <div class="col-md-3">
                                        <label class="control-label">Nombre del grupo:</label>
                                        <input v-model="newGroup.name" placeholder="Nombre de grupo" class="form-control">
                                    </div>
                                    <div class="col-md-4">
                                        <label class="control-label">Estilo:</label>
                                        <select v-model="newGroup.class" class="form-control">
                                            <option value="list" selected>Lista desplegable</option>
                                            <option value="tag">Etiquetas</option>
                                            <option value="color">Recuadro de color</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                        <br>
                                        <input type="checkbox" v-model="newGroup.active">
                                        <label class="control-label">Habilitar para tienda</label>
                                    </div>
                                    <div class="col-md-1">
                                        <span @click="saveGroup()" class="glyphicon glyphicon-ok-sign ok-payment-method" aria-hidden="true" title="Guardar"></span>
                                        <span @click="resetGroup()" class="glyphicon glyphicon-minus-sign delete-product" aria-hidden="true" title="Borrar"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slot>
            </div>
            <div class="modal-footer">
                <slot name="footer">
                    <div class="row">
                        <div class="col-md-12 footer">
                            <button class="btn btn-lg btn-light" @click="close()">Cancelar</button>

                            <button v-if="target=='product'" class="btn btn-lg btn-orange" @click="saveVariant()">Guardar variante</button>
                            <button v-else class="btn btn-lg btn-orange" @click="linkAttributes()">Asignar atributos</button>
                        </div>
                    </div>
                </slot>
            </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import colorpick from '../../global/Colorpicker.vue'

export default {
    props: ['prod_id','target','linked_atts'],
    data: function() {
        return {
            groups:[],
            selectedAtts:[],
            showNewGroup:false,
            editAttInGroup: null,
            groupFilter:'',
            newGroup:{
                name:'',
                class:'list',
                active:true,
            },
            newAtt:{
                name:'',
                data:'',
                active:true
            },
        }
    },
    components: {colorpick},
    mounted() {
        this.loadGroups();
        if(this.target!='product'){
            this.selectedAtts=this.linked_atts;
        }
    },
    methods: {
        close(){
            this.$emit('closemodal');
        },
        saveVariant(){
            if (this.selectedAtts.length<1){
                alert("No hay atributos seleccionados");
            }else{
                let newVar ={
                    'product_id' : this.prod_id,
                    'attributes' : this.selectedAtts,
                    'stock' : 0
                };
                this.$emit('newVariant', newVar);
                this.close();
            }
        },
        linkAttributes(){
            if (this.selectedAtts.length<1){
                alert("No hay atributos seleccionados");
            }else{
                let result = this.filterGroups();
                this.$emit('updateAtts', result);
                this.close();
            }
        },
        filterGroups(){
            let vm=this;
            let result = [];
            vm.groups.forEach(function (g) {
                let temp = [];
                g.attributes.forEach(function(a){
                    let isInArray = vm.selectedAtts.findIndex(function(i){ return i.id === a.id }) !== -1;
                    if(isInArray==true){
                        temp.push(a);
                    }
                });
                if(temp.length>0){
                    let gr = g;
                    gr.attributes = temp;
                    result.push(gr);
                }
            });
            return result;
        },
        saveGroup() {
            let vm = this;
            this.$http.post("/api/variants/newAttGroup", vm.newGroup)
            .then((response) => {
                if (response.status==200) {
                    let newg = response.data.data;
                    console.log(newg);
                    vm.resetGroup();
                    vm.loadGroups();
                    vm.$emit('linkgroup',newg);
                } else {
                    
                    vm.resetGroup();
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
        },
        resetGroup() {
            this.newGroup.name='';
            this.newGroup.class='list';
            this.newGroup.active=true;
            this.showNewGroup=false;
        },
        saveAtt() {
            let vm = this;
            let outData = this.newAtt;
            outData.type_id = this.editAttInGroup;
            this.$http.post("/api/variants/newAttribute", outData)
            .then((response) => {
                if (response.status==200) {
                    vm.resetAtt();
                    vm.loadGroups();
                } else {
                    
                    vm.resetAtt();
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
        },
        resetAtt() {
            this.newAtt.name='';
            this.newAtt.data='';
            this.newAtt.type_id=null;
            this.newGroup.active=true;
            this.editAttInGroup=null;
        },
        loadGroups(){
            let vm= this;
            let outdata={
                'product' : null,
                'filter' : [],
            };
            if(this.target=='product'){
                outdata.product= vm.prod_id;
                outdata.filter = this.linked_atts.map(function(e){return e['id'];});
            }
            this.$http.post("/api/variants/listGroups", outdata)
            .then((response) => {
                if (response.status==200) {
                    vm.groups = response.data.data;
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
        },
        getHexa(field){
            let data = JSON.parse(field);
            return 'background-color: ' + data.code + ';';
        },
        delGroup(id){
            let vm= this;
            if(confirm("¿Está seguro que desea eliminar el grupo y todos sus atributos?")==true){
                this.selectedAtts.splice(this.selectedAtts.findIndex(function(i){return i.type_id === id;}));
                this.$http.get("/api/variants/deleteGroup/" + id)
                    .then((response) => {
                        if (response.status==200) {
                            vm.loadGroups();
                            vm.$emit('reload',0);
                            vm.$emit('unlinkgroup',id);
                        } else {
                            
                        }
                    }).catch((error) => {
                        console.log('error: '+error);
                    })
            }
        },
        delAtt(id){
            let vm= this;
            if(confirm("¿Está seguro que desea eliminar el atributo indicado?")==true){
                this.selectedAtts.splice(this.selectedAtts.findIndex(function(i){return i.id === id;}), 1);
                this.$http.get("/api/variants/deleteAttribute/" + id)
                    .then((response) => {
                        if (response.status==200) {
                            vm.loadGroups();
                            vm.$emit('reload',id);
                        } else {
                            
                        }
                    }).catch((error) => {
                        console.log('error: '+error);
                    })
            }
        }
	},
    computed: {
        attColorCode:{
            get: function () {
                if (this.newAtt.data!=''){
                    return JSON.parse(this.newAtt.data);
                }else{
                    return '#FFFFFF';
                }
            },
            set: function (value) {
                let data ={};
                data.code = value;
                this.newAtt.data= JSON.stringify(data);
            }
        },
        groupsFiltered:{
            get: function () {
                let vm = this;
                return this.groups.filter(e => e.name.toLowerCase().includes(vm.groupFilter.toLowerCase()));
            }
        },
	},
}
</script>

<style scoped>

.borderblock {
    border:1px solid #d3d9e4;
    background-color: #f2f4f7;
    border-radius: 5px;
    padding: 5px;
  }

.borderonly{
    border:1px solid #d3d9e4;
    border-radius: 5px;
    padding: 2px;
    margin: 10px;
}

.tag {
  display: inline-block;
  min-width: 45px;
  line-height: 30px;
  height: 30px;
  text-align: center;
  padding: 2px;
  margin: 5px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 4px;
  color: #222222;
  border:1px solid #d3d9e4a1;
  background-color: #f2f4f7a9;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  border:1px solid #222222;
}

.buttonbox{
    max-width: 80%;
    margin: 10px;
}

  .modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 70vw;
    margin: 0px auto;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    max-height: 90vh;
    overflow-y: auto;
  }

  .modal-header {
    display: flex;
    align-items: center;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .footer{
      display: flex;
      justify-content: flex-end;
      gap: 30px;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
