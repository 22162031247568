<template>
  <div v-if="showSuccess" class="alertasuccess">
    <span>
      {{ showSuccess }}
    </span> 
    <button type="button" @click="clearbtn">×</button>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SuccessApi',
  computed: {
    ...mapGetters({
      showSuccess: 'success/showSuccess'
    }),
  },
  methods: {
    ...mapActions({
      clear: 'success/clearSuccess'
    }),
    clearbtn () {
      this.clear();
    },
  }
}
</script>
<style scoped>
  .alertasuccess {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding-right: 4rem;
    position: fixed;
    width: 30%;
    position: fixed;
    top: 210px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;

  }
  .alertasuccess span {
    color: #0b2e13;
    font-weight: 700;
    transition: all 200ms ease-in;
    margin: 0;
    padding: 5px 0;

  }
  .alertasuccess button {
    position: absolute;
    top: 6PX;
    right: 6PX;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
    padding: 0;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
</style>