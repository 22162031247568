<template>

    <div class="typeahead__container">
      <i class="glyphicon glyphicon-asterisk" v-if="loading"></i>
      <template v-else>
        <i class="glyphicon glyphicon-search" v-show="isEmpty"></i>
        <i class="glyphicon glyphicon-remove" v-show="isDirty" @click="reset"></i>
      </template>

      <input :name="name"
             type="text"
             class="form-control"
             :ref="customClass"
             autocomplete="off"
             v-model="query"
             :placeholder="placeh"
             @keydown.down="down"
             @keydown.up="up"
             @keydown.enter="hit"
             @keydown.esc="reset"
             @blur="resetCustom"
             @input="handleSearchInput"/>

      <ul v-show="hasItems">
        <li v-for="(item, key) in items" :class="activeClass(key)" @mousedown.prevent="hit" @mousemove="setActive(key)" :key="key">
          <span class="name" v-text="item.name"></span>
          <span class="screen-name" v-text="item.screen_name"></span>
          <span class="screen-name" v-text="item.price">$</span>
        </li>
      </ul>
    </div>

</template>

<script>
import VueTypeahead from 'vue-typeahead'
  export default {
    extends: VueTypeahead,

    props: ['default', 'externalsrc', 'limitChars', 'cleanOn', 'customClass', 'placeh', 'name'],
    data () {
      return {
        query: '',
        id : {},
        src: this.externalsrc,
        minChars: this.limitChars,
        searchTimer: null
      }
    },
    mounted: function () {
      this.firsttime();
    },
    watch: {
      id: function (value) {
        this.$emit('choosed',value);
      },
      isEmpty: function (value) {
          if (value == true){this.$emit('empty');}
      },
      default: {
        handler: function (v) {
          this.firsttime();
        }
      }
    },
   methods: {
       onHit (item) {
         this.query = (this.cleanOn) ? '' : item.name;
         this.id = item
         this.items = [];
       },
       handleSearchInput() {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        if (this.query && this.query.trim() !== '') {
          this.searchTimer = setTimeout(() => {
            this.firsttime(this.query);
          }, 1000);
        } else {
          this.items = [];
        } 
      },
       firsttime: function (query) {
         var app = this;
         if (app.default) {
            this.$http.get(this.externalsrc+'?q='+query)
            .then(function (response) {
              if (response.data.data.length>0) {
                app.items = response.data.data;
                if (!app.id) {
                  app.id = response.data.data[0];
                  app.query = response.data.data[0].name;
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            })
         } else {
          var app = this;
          var defaultQuery = this.default !== undefined ? this.default : null;
          this.$http.get(this.externalsrc + '?q=' + (defaultQuery || query))
          .then(function (response) {
            if (response.data.data.length > 0) {
              app.items = response.data.data;
              if (app.default !== undefined && app.default !== null && !app.id) {
                app.id = response.data.data[0];
                app.query = response.data.data[0].name;
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
         }
       },
       resetCustom(item){
        if (this.id.id===0){
          this.reset()
        }
        this.items = [];
      },

      prepareResponseData (response) {
        this.id.id=0;
        return response.data;
      }
    }
  }
</script>
