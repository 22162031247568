import axios from 'axios'

export const getTransports = ({ commit }) => {
  commit('setLoading', true);
  axios.get('/api/transports/getTransports')
    .then(response => {
      commit('getTransports', response.data.data)
    })
    // fail
    .catch(e => {
      commit('getTransports', e.response)
    })
    .finally(() => {
      commit('setLoading', false);
    });
}