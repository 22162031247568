<template>
    <b-container fluid>
        <div class="row">
            <div class="col-9">
                <label class="control-label"><h2>Cheques propios disponibles en cartera</h2></label>
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">
                                    <input v-model="numFilter" class="form-control" placeholder="Numero"/>
                                </th>
                                <th scope="col">Banco</th>
                                <th scope="col">Emisor</th>
                                <th scope="col">Valor</th>
                                <th scope="col">Fecha cobro</th>
                            </tr>
                        </thead>
                        <tbody class="overflow-auto">
                            <tr role="row" v-for="(check, index) in tpChecksFiltered" :key="index" v-bind:class="[{selected: check.rowSelected},{deselected: !check.rowSelected}]">
                                <td class="col-sm-1">
                                    <input type="checkbox"
                                        v-model="selectedChecks"
                                        v-bind:value="check"
                                        />
                                </td>
                                <td>{{check.number}}</td>
                                <td>{{check.bank_name}}</td>
                                <td>{{check.name}}</td>
                                <td>$ {{check.amount}}</td>
                                <td>{{check.date_due}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table table-sm">
                        <tr role="row">
                            <th class="col-sm-5">
                                <label> Cheques seleccionados:&nbsp;  </label>
                                <span>{{selectedChecks.length}}</span>
                            </th>
                            <th class="col-sm-5">
                                <label> Monto total: </label>
                                <span> $ {{totalAmount}}</span>
                            </th>
                            <th class="col-sm-5">
                                <span @click="cancelSelection" aria-hidden="true" title="Eliminar" class="mr-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                    </svg>
                                </span>
                                <span @click="acceptSelection" class="glyphicon glyphicon-ok-sign ok-payment-method" aria-hidden="true" title="Grabar">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                    </svg>
                                </span>
                            </th>
                        </tr>
                    </table>

                </div>
            </div>
            <div class="col-3">
                <label><h2>Seleccionados</h2></label>
                <div class="table-check" style="height:165px;">
                    <table style="width:100%;background-color: #F2F4F7">
                        <tbody>
                            <tr role="row" v-for="(check, index) in selectedChecks" :key="index">
                                <td class="col-sm-2">N° {{check.number}}</td>
                                <td class="col-sm-3"> $ {{check.amount}}</td>
                                <td class="col-sm-1">
                                    <span @click="remove(check)" aria-hidden="true" title="Remover">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                        </svg>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
	    </div>
    </b-container>
</template>


<script>

export default {
	props: ['checks','endpoint'],
	data() {
		return {
			tpChecks: [],
            selectedChecks:[],
            numFilter:'',
            totalAmount:0,
		}
	},
    mounted() {
        this.getWallet();
        this.selectedChecks = this.checks.filter(function (el) {return el.id != null;});
    },
	methods: {
		clientChoosed: function(obj) {
			this.$emit('updateChecks', obj);
		},
        getWallet: function(){
            var vm = this;
            this.$http.get(vm.endpoint)
            .then(response => {
              if(response.data) {
                vm.tpChecks = response.data.data;
              }
            })
            .catch(error => {
              alert(error);
            })
        },
        acceptSelection(){
            this.$emit('updateChecks',this.selectedChecks);
        },
        cancelSelection(){
            for (var check in this.tpChecks) {
                this.tpChecks[check].rowSelected =false;
            }
            this.selectedChecks=[];
        },
        remove(obj){
            this.selectedChecks.splice(obj,1);
            this.tpChecks[obj].rowSelected=!this.tpChecks[obj].rowSelecte;
        }
	},
    computed:{
        tpChecksFiltered:{
            get: function () {
                let vm = this;
                return this.tpChecks.filter(e => e.number.includes(vm.numFilter));
            }
        },
    },
    watch:{
        'selectedChecks': {
            handler: function (a) {
                var amount =0;
                for (var chk in this.selectedChecks) {
                    amount = amount + this.selectedChecks[chk].amount;
                }
                this.totalAmount = amount;
            }
      },
    }
}
</script>
