import Vue from 'vue'
import App from './App.vue'
import router from './router'

if (!window.Vue) { // esto es solo para pos-documents
  window.Vue = Vue;
}

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "bootstrap-icons/font/bootstrap-icons.css";


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import './assets/sass/app.scss';

import jquery from "jquery";
window.$ = window.jQuery = jquery;

require('datatables.net-bs4');

require('datatables.net-select-bs4');
const VdtnetTable = require('vue-datatables-net');
Vue.use(VdtnetTable)

require('chart.js');
// vue-charts package
require('hchs-vue-charts');
Vue.use(VueCharts);

import VueSocialChat from 'vue-social-chat'
Vue.use(VueSocialChat)

import axios from './services/index.js';
Vue.prototype.$http = axios; // Esto es para que ande el typeahead
window.axios = axios; // esto es solo para pos-documents

import store from './store';

import moment from 'moment'
Vue.prototype.moment = moment

Vue.config.productionTip = false

new Vue({
  router,
  store,
  permissions() {
    return JSON.parse(localStorage.getItem("permissions"));
  },
  render: h => h(App)
}).$mount('#app')
