<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Saldos de Cuentas corrientes de proveedores</h4>
      </b-col>
    </div>
    <b-row>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="stats">
          <div class="stat">
            <div class="number">
              {{ formatoDinamicoMoneda(total)}}
            </div>
            <div class="desc"> Balance de cuentas corrientes <p v-if="toDate!= null"> hasta {{ toDate }}</p></div>
          </div>
        </div>
      </div>
    </b-row>
   
    <div class="card bg-light rounded-sm p-3 my-2 border">
      <div class="row d-flex justify-content-between">
        <div class="col col-md-2">
          <b-form-group>
            <label class="control-label">Hasta fecha:</label>
            <datepicker-component v-on:datechoosed="toDateUpdated" :dateLabel="'Seleccione una fecha'"/>
          </b-form-group>
        </div>
        <div class="col col-md-2">
          <b-form-group>
            <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary mr-2" value="Exportar CSV">
            <input type="button" @click="exportPDF()" class="btn btn-md btn-outline-primary" value="Exportar PDF">
          </b-form-group>
        </div>

        </div>
    </div>

    <b-row>
      <b-col cols="md-12">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          ref="table"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import formatMoneyMixin from "./../../mixins/formatMoneyMixin";
import formatDateMixin from "../../mixins/formatDateMixin";
import exportsMixin from "../../mixins/exportsMixin";
import datepickerComponent from '../global/Datepicker-empty.vue';

export default {
 components: {
  VdtnetTable,
  datepickerComponent,  
 }, 
 mixins: [formatMoneyMixin,formatDateMixin,exportsMixin],
 data: function() {
    return {
      loading: false,
      toDate:null,
      total:0,

      providers: [],
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax:  {
          url: process.env.VUE_APP_BASE_URL+"/api/reports/pacbalancestodate",
          type: "GET",
          data: (d) => {
            d.toDate = this.toDate
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
        processing: true,
        pageLength: 50,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        order: [[3, 'desc']],
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
      },
      fields: {
        name: { label: "Nombre" },
        cuit: { label: "CUIT" },
        business: { label: "Razon social " },
        total: { label: "Total" , render: this.formatoDinamicoMoneda},
      },
      
    };
  },
computed:{
    filters(){
      return {
        toDate : this.toDate
      }
    }
},
mounted(){
    this.getTotals();
  },
methods: {
  reload(){
      this.getTotals();
      this.refreshTable();
    },
    refreshTable() {  
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    },
    toDateUpdated: function (date) {
      this.toDate = date;
    },
    getTotals() {
      this.loading = true;
      this.$http.get(process.env.VUE_APP_BASE_URL+"/api/reports/pacbalancestodatetotal" , {params:this.filters})
      .then((response) => {
        this.total = response.data.data;
        this.loading = false;
      });    
    },
    exportCSV() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/CSVPacBalances", this.filters)
      .then((response) => {
      });
    },
    exportPDF() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/PDFpacbalances", this.filters)
      .then((response) => {
      });
    },
  },
  watch: {
      filters() {
        this.reload();
      },
    },
  }

</script>