<template>
    <b-container fluid>
        <div class="row mt-3">
            <b-col class="d-flex justify-content-start">
                <h4>Configuracion de la tienda online</h4>
            </b-col>
        </div>
        <b-card no-body class="my-4 p-3">
            <b-tabs content-class="mt-2" align="center">
                <!-- Pestaña de configuraciones -->
                <b-tab title="Configuracion de Ecommerce" active>
                    <b-row>
                        <b-col cols="md-4">
                            <div class="card mb-4">
                                <b-card-header>
                                    <h6>Opciones de usuarios</h6>
                                </b-card-header>
                                <b-card-body class="d-flex align-items-start flex-column">
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="config.page_active" type="checkbox" class="md-check">
                                            Activar página de E-commerce
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="config.allow_sale_unlogged" type="checkbox" class="md-check">
                                            Permitir compras de usuarios no logueados
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="config.show_public_prices" type="checkbox" class="md-check">
                                            Permitir que los precios se vean sin estar logueado
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="config.show_stock" type="checkbox" class="md-check">
                                            Mostrar disponibilidad de stock en producto
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="config.variant_check" type="checkbox" class="md-check">
                                            Verificar productos con variantes seleccionada.
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="config.sale_without_stock" type="checkbox" class="md-check">
                                            Permitir venta de productos sin stock
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="config.show_pricelists" type="checkbox" class="md-check">
                                            Mostrar listas mayoristas y minoristas a todos los usuarios
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="config.show_mercadopago" type="checkbox" class="md-check">
                                            Usar Mercado Pago como medio de pago
                                        </label>
                                    </div>
                                    <div v-show="config.show_mercadopago">
                                        <div class="form-group">
                                            <label class="control-label">Mercado Pago Token:</label>
                                            <input type="input" class="form-control" v-model="config.mercadopago_app_token" >
                                        </div>
                                    </div>
                                    <div class="form-group form-md-line-input form-md-floating-label mt-4">
                                        <label class="control-label">Asignar lista de precios (minorista) por defecto:</label>
                                        <select class="form-control edited" v-model="config.pricelist_retail">
                                            <option :value="0">Ninguna</option>
                                            <option v-for="price in priceLists" :key="price.id" :value="price.id">{{price.name}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group form-md-line-input form-md-floating-label" v-bind:class="[{'disabled': !config.show_pricelists}]">
                                        <label class="control-label">Asignar lista de precios mayorista por defecto:</label>
                                        <select class="form-control edited" v-model="config.pricelist_wholesale" v-bind:class="[{'disabled': !config.show_pricelists}]">
                                            <option :value="0">Ninguna</option>
                                            <option v-for="price in priceLists" :key="price.id" :value="price.id">{{price.name}}</option>
                                        </select>
                                    </div>
                                </b-card-body>
                            </div>
                        </b-col>
                        <!-- Filtros y metodos de pago-->
                        <b-col cols="md-4">
                            <div class="card mb-4">
                                <b-card-header>
                                    <h6>Filtros</h6>
                                </b-card-header>
                                <b-card-body class="d-flex align-items-start flex-column">
                                    <label class="control-label">Filtros en el menu lateral de la tienda:</label>
                                    <div class="checkbox" disabled>
                                        <label>
                                        <input v-model="config.fillers.filters.categories" disabled type="checkbox" class="md-check">
                                            <b>Categorias</b>
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="config.fillers.filters.brands" type="checkbox" class="md-check">
                                            Marcas
                                        </label>
                                    </div>
                                    <div class="checkbox">
                                        <label>
                                        <input v-model="config.fillers.filters.groups" type="checkbox" class="md-check">
                                            Grupos de atributos:
                                        </label>
                                    </div>
                                    <hr>
                                    <div class="col-12" v-if="config.fillers.filters.groups">
                                        <b-table-simple caption-top responsive>
                                            <b-thead>
                                                <b-tr>
                                                    <b-th colspan="4">Grupo</b-th>
                                                    <b-th colspan="4">Estilo</b-th>
                                                    <b-th colspan="4">Acciones</b-th>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody>
                                                <b-tr v-for="(group, index) in attGroups" :key="index">
                                                    <b-td colspan="4">{{group.name}}</b-td>
                                                    <b-td colspan="4">
                                                        <span v-if="group.class=='color'">Color</span>
                                                        <span v-if="group.class=='tag'">Etiqueta</span>
                                                        <span v-if="group.class=='list'">Lista</span>
                                                    </b-td>
                                                    <b-td colspan="4">
                                                        <input type="checkbox"
                                                            v-model="config.activeGroups"
                                                            v-bind:value="group.id"/>
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody>
                                        </b-table-simple>
                                    </div>
                                </b-card-body>
                                <b-card-header>
                                    <h6>Métodos de pago</h6>
                                </b-card-header>
                            </div>
                        </b-col>
    
                        <!-- Datos de contacto -->
                        <b-col cols="md-4">
                            <div class="card mb-4">
                                <b-card-header>
                                    <h6>Datos de contacto</h6>
                                </b-card-header>
                                <b-card-body>
                                    <b-form-group>
                                        <label class="control-label">Email de contacto:</label>
                                        <input type="email" class="form-control" v-model="config.contact.email" placeholder="contacto@email.com">
                                    </b-form-group>
                                    <b-form-group>
                                        <label class="control-label">Telefonos de contacto</label>
                                        <b-row>
                                            <b-col cols="md-6">
                                                <input type="text" class="form-control" placeholder="Telefono #1" v-model="config.contact.phone1">
                                            </b-col>
                                            <b-col cols="md-6">
                                                <input type="text" class="form-control" placeholder="Telefono #2" v-model="config.contact.phone2">
                                            </b-col>
                                        </b-row>
                                    </b-form-group>                    
                                    <b-form-group>
                                        <label class="control-label">Direccion</label>
                                        <input type="text" placeholder="Dirección" class="form-control" v-model="config.contact.address1">
                                    </b-form-group>
                                    <b-form-group>
                                        <label class="control-label">Cuidad</label>
                                        <input type="text" placeholder="Localidad - Provincia - Pais" class="form-control" v-model="config.contact.address2">
                                    </b-form-group>
                                    <b-form-group>
                                        <label class="control-label">Horarios de contacto:</label>
                                        <input type="email" class="form-control" v-model="config.contact.workhours" placeholder="00:00 lun - 00:00 dom">
                                    </b-form-group>
                                    <b-form-group>
                                        <label>Facebook</label>
                                        <b-icon icon="facebook" class="ml-2"></b-icon>
                                        <input type="text" class="form-control" placeholder="facebook.com/web" v-model="config.networks.facebook">
                                    </b-form-group>
                                    <b-form-group>
                                        <label>Twitter</label>
                                        <b-icon icon="twitter" class="ml-2"></b-icon>
                                        <input type="text" class="form-control" placeholder="twitter.com/web" v-model="config.networks.twitter">
                                    </b-form-group>
                                    <b-form-group>
                                        <label>Instagram</label>
                                        <b-icon icon="instagram" class="ml-2"></b-icon>
                                        <input type="text" class="form-control" placeholder="instagram.com/web" v-model="config.networks.instagram">
                                    </b-form-group>
                                    <b-form-group>
                                        <label>whatsapp</label>
                                        <b-icon icon="whatsapp" class="ml-2"></b-icon>
                                        <input type="text" class="form-control" placeholder="# whatsapp" v-model="config.networks.whatsapp">
                                    </b-form-group>
                                    <b-form-group>
                                        <label>Telegram</label>
                                        <b-icon icon="telegram" class="ml-2"></b-icon>
                                        <input type="text" class="form-control" placeholder="# telegram" v-model="config.networks.telegram">
                                    </b-form-group>
                                </b-card-body>
                            </div>
                        </b-col>
                    </b-row>
                </b-tab>
                
                <!-- Pestaña de personalizacion de pagina -->
                <b-tab title="Diseño de Pagina">
                    <b-row>
                        <b-col cols="md-4">
                            <div class="card mb-4">
                                <b-card-header>
                                    <h6>Imágenes</h6>
                                </b-card-header>
                                <div class="card-body">
                                    <b-row class="mt-3">
                                        <b-col cols="md-6">
                                            <h6 text-aling="center">Logo de e-commerce</h6>
                                            <vue-upload-multiple-image
                                                @upload-success="uploadImageSuccessLogo"
                                                @before-remove="beforeRemoveLogo"
                                                @edit-image="editImageLogo"
                                                dragText="260 x 80"
                                                browseText="Buscar logo"
                                                primaryText=""
                                                markIsPrimaryText=""
                                                popupText="Logo"
                                                dropText="Arrastre y suelte"
                                                :multiple="true"
                                                :maxImage="1"
                                                :showEdit="false"
                                                :data-images="logoPrev"
                                                idUpload="logoUp"
                                                idEdit="logoEd"
                                            />
                                        </b-col>
                                        <b-col cols="md-6">
                                            <h6 text-aling="center">FavIcon</h6>
                                            <vue-upload-multiple-image
                                                @upload-success="uploadImageSuccessIcon"
                                                @before-remove="beforeRemoveIcon"
                                                @edit-image="editImageIcon"
                                                dragText="16 x 16"
                                                browseText="Buscar icono"
                                                primaryText=""
                                                markIsPrimaryText=""
                                                popupText="Imagenes"
                                                dropText="Arrastre y suelte"
                                                :multiple="true"
                                                :maxImage="1"
                                                :showEdit="false"
                                                :data-images="iconPrev"
                                                idUpload="iconUp"
                                                idEdit="iconEd"
                                            />
                                        </b-col>
                                        <b-col cols="md-6">
                                            <h6 text-aling="center">Imagenes de portada</h6>
                                            <vue-upload-multiple-image
                                                @upload-success="uploadImageSuccessDash"
                                                @before-remove="beforeRemoveDash"
                                                @edit-image="editImageDash"
                                                dragText="1000 x 530"
                                                browseText="Buscar imagen"
                                                primaryText=""
                                                markIsPrimaryText=""
                                                popupText="Imagenes"
                                                dropText="Arrastre y suelte"
                                                :multiple="true"
                                                :maxImage="3"
                                                :showEdit="false"
                                                :data-images="dashPrev"
                                                idUpload="dashUp"
                                                idEdit="dashEd"
                                            />
                                        </b-col>
                                        <b-col cols="md-6">
                                            <h6 text-aling="center">Cabecera "Nosotros"</h6>
                                            <vue-upload-multiple-image
                                                @upload-success="uploadImageSuccessAbout"
                                                @before-remove="beforeRemoveAbout"
                                                @edit-image="editImageAbout"
                                                dragText="1500 x 500"
                                                browseText="Buscar cabecera"
                                                primaryText=""
                                                markIsPrimaryText=""
                                                popupText="Imagenes"
                                                dropText="Arrastre y suelte"
                                                :multiple="true"
                                                :maxImage="1"
                                                :showEdit="false"
                                                :data-images="aboutPrev"
                                                idUpload="aboutUp"
                                                idEdit="aboutEd"
                                            />
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="md-4">
                            <div class="card mb-4">
                                <b-card-header>
                                    <h6>Textos</h6>
                                </b-card-header>
                                <b-card-body>
                                    <b-form-group>
                                        <h6>Titulo de la pagina:</h6>
                                        <input type="title" class="form-control" v-model="config.page_title" placeholder="Titulo">
                                    </b-form-group>
                                    <b-form-group>
                                        <h6>Texto "Acerca de nosotros":</h6>
                                        <div class="form-group">
                                            <textarea rows="5" v-model="config.fillers.aboutus" placeholder="Escribe aqui información sobre la actividad de tu empresa..." class="form-control"></textarea>
                                        </div>
                                    </b-form-group>
                                    <b-form-group>
                                        <div class="checkbox">
                                            <label>
                                                <input v-model="config.fillers.alerts.news.active" type="checkbox" class="md-check mr-1">
                                                <b>Banner de noticias</b>
                                            </label>
                                        </div>
                                        <textarea rows="3" v-model="config.fillers.alerts.news.message" class="form-control" placeholder="Escribe aqui un mensaje que se mostrara en la parte superior de la pagina..."
                                        v-bind:class="[{'disabledarea': !config.fillers.alerts.news.active}]" />
                                    </b-form-group>
                                    <b-form-group>
                                        <div class="checkbox">
                                            <label>
                                                <input v-model="config.fillers.alerts.welcome.active" type="checkbox" class="md-check mr-1">
                                                <b>Alerta de bienvenida</b>
                                            </label>
                                        </div>
                                        <textarea rows="3" v-model="config.fillers.alerts.welcome.message" class="form-control" placeholder="Escribe aqui un mensaje que se mostrara al ingresar en la pagina..."
                                        v-bind:class="[{'disabledarea': !config.fillers.alerts.welcome.active}]"/>
                                    </b-form-group>
                                </b-card-body>
                            </div>
                        </b-col>
                        <b-col cols="md-4">
                            <div class="card mb-4">
                                <b-card-header>
                                    <h6>Colores</h6>
                                </b-card-header>
                                <b-form-group>
                                    <b-card>
                                        <b-col class="col-md-12 pagepanel mt-2">
                                            <div class="barcolorprimary" :style="{ background: config.styles.colorPrimary}">
                                                <span :style="{color : hover.alert, cursor: 'pointer'}" @mouseover="hover.alert = config.styles.colorAlert" @mouseout="hover.alert='#FFFFFF'">
                                                    @ @ @
                                                </span>
                                            </div>
                                            <div class="barcoloraccent" :style="{ background: config.styles.colorAccent}"></div>
            
                                            <div class="barcolormenu">
                                                <br><br>
                                                <b-icon icon="check-square" :style="{ color: config.styles.colorAlert}"/>
                                                Lista
            
                                                <span class="barcolortag" :style="{ background: hover.tag.background, color: hover.tag.text, border: hover.tag.border}"
                                                @mouseover="tagOnMouseOver()" @mouseout="tagOnMouseOut()">
                                                    etiqueta
                                                </span>
                                            </div>
                                            <div class="mt-2">
                                                <b-button size="sm" class="barcolorbutton mr-2" :style="{ background: hover.button}"
                                                @mouseover="hover.button = config.styles.colorAccentDark"
                                                @mouseout="hover.button = config.styles.colorAccent"
                                                @mousedown="hover.button = config.styles.colorAccentLight"
                                                @mouseup="hover.button = config.styles.colorAccentDark">
                                                    Botón ejemplo
                                                </b-button>
                
                                                <b-button variant="light" size="sm" @click="resetColors()"> Restablecer diseño</b-button>
                                            </div>
            
            
                                            <div>
                                                <span :style="{ color: hover.secondary, cursor:'pointer'}" @mouseover="hover.secondary= config.styles.colorSecondary" @mouseout="hover.secondary='#FFFFFF'">
                                                    XXX-XXXXXXXX
                                                </span>
                                            </div>
                                        </b-col>
                                    </b-card>
                                </b-form-group>
                                <b-form-group>
                                    <h6> Primario:</h6>
                                    <b-col>
                                        <b-form-input type="color" :color="stprimary" v-model="stprimary"></b-form-input>
                                    </b-col>
                                </b-form-group>
                                <b-form-group>
                                    <h6>Secundario:</h6>
                                    <b-col>
                                        <Colorpick :color="stsecondary" v-model="stsecondary" />
                                    </b-col>
                                </b-form-group>
                                <b-form-group>
                                    <h6>Detalles:</h6>
                                    <b-col>
                                        <Colorpick :color="staccent" v-model="staccent" />
                                    </b-col>
                                </b-form-group>
                                <b-form-group>
                                    <h6>Detalles oscuros:</h6>
                                    <b-col>
                                        <Colorpick :color="staccentd" v-model="staccentd" />
                                    </b-col>
                                </b-form-group>
                                <b-form-group>
                                    <h6>Detalles claros:</h6>
                                    <b-col>
                                        <Colorpick :color="staccentl" v-model="staccentl" />
                                    </b-col>
                                </b-form-group>
                                <b-form-group>
                                    <h6>Resaltado:</h6>
                                    <b-col>
                                        <Colorpick :color="stalert" v-model="stalert" />
                                    </b-col>
                                </b-form-group>
                            </div>
                        </b-col>
                    </b-row>
                </b-tab>
                
                <!-- Pestaña de promociones -->
                <b-tab title="Destacados">
                    <b-row>
                        <b-col cols="md-12">
                            <b-card-header>
                                <h6>Lista de productos destacados</h6>
                            </b-card-header>
                            <b-card-body>
    
                            </b-card-body>
                            <b-form-group>
                                <typeahead
                                    :name="'featuredSearch'"
                                    :placeh="'Buscar producto...'"
                                    :clean-on="true"
                                    v-on:choosed="featuredChoosen"
                                    :externalsrc="'/api/products/searchProducts'"
                                    limit-chars="2"
                                />
                            </b-form-group>
                            <b-table-simple hover small caption-top responsive sticky-header>
                                <b-thead b-thead head-variant="light">
                                    <b-tr>
                                        <b-th cols="sm-6">Producto</b-th>
                                        <b-th cols="sm-1">Cod.Interno</b-th>
                                        <b-th cols="sm-1">Cod.Barras</b-th>
                                        <b-th cols="sm-3">Creación</b-th>
                                        <b-th cols="sm-1"></b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="prod in featList" :key="prod.id">
                                        <b-td cols="sm-6">{{prod.name}}</b-td>
                                        <b-td cols="sm-1">{{prod.code}}</b-td>
                                        <b-td cols="sm-1">{{prod.barcode}}</b-td>
                                        <b-td cols="sm-3">{{formatDate(prod.created_at)}}</b-td>
                                        <b-td cols="sm-1">
                                            <b-button variant="danger" size="sm" @click="featuredRemove(prod.id)" aria-hidden="true" title="Remover"><b-icon icon="trash"/></b-button>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                </b-tab>
                
            </b-tabs>
            <div class="card-footer">
                <b-button variant="orange" size="lg" type="submit" @click="updateConfig()">Guardar configuración</b-button>
            </div>
        </b-card>
        
        <b-row>
            <div class="loading" v-if="loading">Loading&#8230;</div>
        </b-row>
    </b-container>


</template>

<script>
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import typeahead from '../global/Typeahead.vue';
import Colorpick from '../global/Colorpicker.vue';
import moment from 'moment';

export default {
  props: [],
  components: {
      VueUploadMultipleImage,
      typeahead,
      Colorpick
  },
  data() {
    return {
      activeTab: 0,
      config :{
          page_active: 0,
          show_public_prices: 0,
          show_pricelists: 0,
          show_mercadopago: 0,
          mercadopago_app_token: null,
          show_stock:0,
          pricelist_retail:0,
          pricelist_wholesale: 0,
          allow_sale_unlogged: 0,
          variant_check:0,
          sale_without_stock:0,
          page_title:'',
          contact:{
              email:null,
              phone1:null,
              phone2:null,
              address1:null,
              address2:null,
              workhours:null,
          },
          networks:{
              facebook:null,
              twitter:null,
              instagram:null,
              whatsapp:null,
              telegram:null,
          },
          fillers:{
              aboutus:'',
              featured:[],
              filters:{
                  categories:true,
                  brands:true,
                  groups:false,
              },
              alerts:{
                  news:{
                      active:false,
                      message:''
                  },
                  welcome:{
                    active:false,
                    message:''
                  },
              }
          },
          styles:{
              colorPrimary:'#BCBCBC',
              colorSecondary:'#747474',
              colorAccent:'#747474',
              colorAccentDark:'#BCBCBC',
              colorAccentLight:'#747474',
              colorAlert:'#BCBCBC',
          },
          images:{
              logo:[],
              favIcon:[],
              imgDash:[],
              imgAbout:[],
              prevLogo:[],
              prevIcon:[],
              prevAbout:[],
              prevDash:[],
          },
          activeGroups: [],
      },
      priceLists:[],
      logoPrev:[],
      dashPrev:[],
      iconPrev:[],
      aboutPrev:[],
      attGroups:[],
      featList:[],
      loading:true,
      hover:{
          secondary:'#FFFFFF',
          alert:'#FFFFFF',
          tag:{
              border:'2px solid #eeeeee',
              background:'#FFFFFF',
              text:'#555555',
          },
          button:'#747474',
      }
    }
  },
  mounted() {
    this.loadPriceLists();
    this.loadAttGroups();
    this.loadConfigs();
  },
  computed: {
        stprimary:{
            get: function () {return this.config.styles.colorPrimary;},
            set: function (value) {this.config.styles.colorPrimary=value;}
        },
        stsecondary:{
            get: function () {return this.config.styles.colorSecondary;},
            set: function (value) {this.config.styles.colorSecondary=value;}
        },
        staccent:{
            get: function () {return this.config.styles.colorAccent;},
            set: function (value) {this.config.styles.colorAccent=value;}
        },
        staccentd:{
            get: function () {return this.config.styles.colorAccentDark;},
            set: function (value) {this.config.styles.colorAccentDark=value;}
        },
        staccentl:{
            get: function () {return this.config.styles.colorAccentLight;},
            set: function (value) {this.config.styles.colorAccentLight=value;}
        },
        stalert:{
            get: function () {return this.config.styles.colorAlert;},
            set: function (value) {this.config.styles.colorAlert=value;}
        },
    },
  methods: {
    loadConfigs() {
        let vm = this;
        this.$http.get('/api/ecom/config/get')
          .then((response) => {
            if (response.data.success) {
                let r = response.data.data;
                vm.setConfigs(r);
            } else {
                
            }
          }).catch((error) => {
            console.log('error: '+error);
        })
    },
    updateConfig() {
        if(this.checkInputs() == true){
            let vm = this;
            vm.loading=true;
            this.$http.post('/api/ecom/config/set', this.config)
                .then((response) => {
                    
                    window.location.reload();
                    }).catch((error) => {
                        console.log('error: '+error);
                    })
        }
    },
    setConfigs(r){
        this.config.page_active = r.page_active;
        this.config.show_public_prices = r.show_public_prices;
        this.config.show_pricelists = r.show_pricelists;
        this.config.show_stock = r.show_stock;
        this.config.show_mercadopago = r.show_mercadopago,
        this.config.mercadopago_app_token = r.mercadopago_app_token,
        this.config.pricelist_retail = (r.pricelist_retail) ? r.pricelist_retail : 0;
        this.config.pricelist_wholesale = (r.pricelist_wholesale) ? r.pricelist_wholesale : 0;
        this.config.allow_sale_unlogged = r.allow_sale_unlogged;
        this.config.page_title = r.page_title;
        this.config.variant_check = r.variant_check;
        this.config.sale_without_stock = r.sale_without_stock;

        if(r.contact!=null){
            let rcontact = JSON.parse(r.contact);
            this.config.contact.email = rcontact.email;
            this.config.contact.phone1 = rcontact.phone1;
            this.config.contact.phone2 = rcontact.phone2;
            this.config.contact.address1 = rcontact.address1;
            this.config.contact.address2 = rcontact.address2;
            this.config.contact.workhours = rcontact.workhours;
        }

        if(r.networks!=null){
            let rnetworks = JSON.parse(r.networks);
            this.config.networks.facebook = rnetworks.facebook;
            this.config.networks.twitter = rnetworks.twitter;
            this.config.networks.instagram = rnetworks.instagram;
            this.config.networks.whatsapp = rnetworks.whatsapp;
            this.config.networks.telegram = rnetworks.telegram;
        }

        if(r.fillers!=null){
            let rfillers = JSON.parse(r.fillers);
            this.config.fillers.aboutus = rfillers.aboutus;
            if(rfillers.featured!=null){
                this.config.fillers.featured = rfillers.featured;
                this.featList = r.featured_prods;
            }
            if(rfillers.filters!=null){
                this.config.fillers.filters.categories = rfillers.filters.categories;
                this.config.fillers.filters.brands = rfillers.filters.brands;
                this.config.fillers.filters.groups = rfillers.filters.groups;
                this.config.fillers.filters.attGroups = rfillers.filters.attGroups;
            }
            if(rfillers.alerts!=null){
                this.config.fillers.alerts.news.active = rfillers.alerts.news.active;
                this.config.fillers.alerts.news.message = rfillers.alerts.news.message;
                this.config.fillers.alerts.welcome.active = rfillers.alerts.welcome.active;
                this.config.fillers.alerts.welcome.message = rfillers.alerts.welcome.message;
            }
        }

        if(r.styles!=null){
            let rstyles = JSON.parse(r.styles);
            this.config.styles.colorPrimary=rstyles.colorPrimary;
            this.config.styles.colorSecondary=rstyles.colorSecondary;
            this.config.styles.colorAccent=rstyles.colorAccent;
            this.config.styles.colorAccentDark=rstyles.colorAccentDark;
            this.config.styles.colorAccentLight=rstyles.colorAccentLight;
            this.config.styles.colorAlert=rstyles.colorAlert;

            this.hover.button = rstyles.colorAccent;
        }

        if(r.images.logo.length>0){
            this.config.images.prevLogo= r.images.logo;
            this.config.images.logo = r.images.logo;
            const logoPath = String(r.images.logo);
            const modifiedLogoPath = logoPath.replace('localhost/', 'localhost/apiecom/');
            this.logoPrev.push({
                path: modifiedLogoPath,
                default: 1,
                highlight: 0,
                caption: '',
            });
        }

        if(r.images.favIcon.length>0){
            this.config.images.prevIcon = r.images.favIcon;
            this.config.images.favIcon = r.images.favIcon;
            const logoPath = String(r.images.favIcon);
            const modifiedLogoPath = logoPath.replace('localhost/', 'localhost/apiecom/');
            this.iconPrev.push({
                path: modifiedLogoPath,
                default: 1,
                highlight: 0,
                caption: '',
            });
        }

        if(r.images.imgAbout.length>0){
            this.config.images.prevAbout = r.images.imgAbout;
            this.config.images.imgAbout = r.images.imgAbout;
            const logoPath = String(r.images.imgAbout);
            const modifiedLogoPath = logoPath.replace('localhost/', 'localhost/apiecom/');
            this.aboutPrev.push({
                path: modifiedLogoPath,
                default: 1,
                highlight: 0,
                caption: '',
            });
        }

        let dashAux=[];
        this.config.images.prevDash = r.images.imgDash;
        r.images.imgDash.forEach(e => {
            this.config.images.imgDash.push(e);
            const logoPath = String(e);
            const modifiedLogoPath = logoPath.replace('localhost/', 'localhost/apiecom/');
            dashAux.push({
                path: modifiedLogoPath,
                default: 1,
                highlight: 0,
                caption: '',
            });
        });
        this.dashPrev = dashAux;
        this.loading = false;
    },
    resetColors(){
        if(confirm('¿Restablecer colores predeterminados?')===true){
            this.config.styles.colorPrimary='#BCBCBC';
            this.config.styles.colorSecondary='#747474';
            this.config.styles.colorAccent='#747474';
            this.config.styles.colorAccentDark='#BCBCBC';
            this.config.styles.colorAccentLight='#747474';
            this.config.styles.colorAlert='#BCBCBC';

            this.hover.button = this.config.styles.colorAccent;
        }
    },
    formatDate(date){
        return moment(String(date)).format('DD/MM/YYYY');
    },
    loadPriceLists(){
        let vm= this;
        this.$http.get("/api/priceslist/getPriceList")
            .then((response) => {
                if (response.status==200) {
                    vm.priceLists = response.data.data;
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
        })
    },
    loadAttGroups(){
        let vm= this;
        this.$http.get("/api/variants/getAttGroups")
            .then((response) => {
                if (response.status==200) {
                    vm.attGroups = response.data.data;
                    vm.attGroups.forEach(e => {
                        if(e.filter){
                            vm.config.activeGroups.push(e.id);
                        }
                    });
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
        })
    },
    attGroupChoosen(data) {
        var isInArray = this.config.attGroups.findIndex(function(i){ return i === data.id }) !== -1;
        if(!isInArray && this.config.fillers.filters.groups){
            this.attGroups.push(data);
            this.config.attGroups.push(data.id);
        }
    },
    attGroupRemove(id) {
        this.attGroups.splice(this.attGroups.findIndex(function(i){return i.id === id;}), 1);
        this.config.attGroups.splice(this.config.attGroups.findIndex(function(i){return i === id;}), 1);
    },
    featuredChoosen(data){
        var isInArray = this.config.fillers.featured.findIndex(function(i){ return i === data.id }) !== -1;
        if(!isInArray){
            this.featList.push(data);
            this.config.fillers.featured.push(data.id);
        }
    },
    featuredRemove(id) {
        this.featList.splice(this.featList.findIndex(function(i){return i.id === id;}), 1);
        this.config.fillers.featured.splice(this.config.fillers.featured.findIndex(function(i){return i === id;}), 1);
    },
    beforeRemoveLogo(index, done, fileList) {
        var r = confirm("¿Desea eliminar el logo?")
        if (r == true) {
            done()
            this.config.images.logo = fileList.map(x=> x.path)
        }
    },
    uploadImageSuccessLogo(formData, index, fileList) {this.config.images.logo = fileList.map(x=> x.path)},
    editImageLogo(formData, index, fileList) {this.config.images.logo = fileList.map(x=> x.path)},
    beforeRemoveDash (index, done, fileList) {
        var r = confirm("¿Desea eliminar esta imagen?")
        if (r == true) {
            done()
            this.config.images.imgDash = fileList.map(x=> x.path)
        }
    },
    uploadImageSuccessDash(formData, index, fileList) {this.config.images.imgDash = fileList.map(x=> x.path)},
    editImageDash (formData, index, fileList) {this.config.images.imgDash = fileList.map(x=> x.path)},
    beforeRemoveIcon (index, done, fileList) {
        var r = confirm("¿Desea eliminar el icono?")
        if (r == true) {
            done()
            this.config.images.favIcon = fileList.map(x=> x.path)
        }
    },
    uploadImageSuccessIcon(formData, index, fileList) {this.config.images.favIcon = fileList.map(x=> x.path)},
    editImageIcon(formData, index, fileList) {this.config.images.favIcon = fileList.map(x=> x.path)},
    beforeRemoveAbout(index, done, fileList) {
        var r = confirm("¿Desea eliminar el logo?")
        if (r == true) {
            done()
            this.config.images.imgAbout = fileList.map(x=> x.path)
        }
    },
    uploadImageSuccessAbout(formData, index, fileList) {this.config.images.imgAbout = fileList.map(x=> x.path)},
    editImageAbout(formData, index, fileList) {this.config.images.imgAbout = fileList.map(x=> x.path)},
    tagOnMouseOver(){
        this.hover.tag.border = '2px solid ' + this.config.styles.colorAlert;
        this.hover.tag.background = this.config.styles.colorSecondary;
        this.hover.tag.text = "#FFFFFF";
    },
    tagOnMouseOut(){
        this.hover.tag.border='2px solid #eeeeee';
        this.hover.tag.background='#FFFFFF';
        this.hover.tag.text='#555555';
    },
    checkInputs(){
        if(this.config.show_mercadopago == true)
            if(this.config.mercadopago_app_token == undefined || this.config.mercadopago_app_token == null){
                alert('El token de mercado pago debe ser ingresado si quiere usar este medio de pago.');

                return false;
            }

        return true;
    }
  },

}
</script>
