<template>
  <div class="row">
    <div class="col-12">
      <b-form-group>
        <label for="input-live">Tipo de movimiento:</label>
        <b-form-select
          v-model="movement.type"
          :options="movementType"
        ></b-form-select>
      </b-form-group>
    </div>
    <div class="col-12">
      <b-form-group>
        <label for="input-live">Categoría de movimiento:</label>
        <b-form-select v-if="fundCategoriesLoaded" v-model="movement.category_id">
          <option v-for="category in filteredCategories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        ></b-form-select>
        <div v-else>
          Cargando categorías...
        </div>
      </b-form-group>
    </div>
    <div class="col-12">
      <b-form-group>
        <label class="control-label" for="input-live">Descripción:</label>
        <b-form-input
          class="form-control"
          id="input-live"
          v-model="movement.description"
          aria-describedby="input-live-help input-live-feedback"
          trim
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="col-12">
      <b-form-group>
        <label class="control-label">Monto:</label>
        <input
          v-model="movement.amount"
          name="amount"
          id="transferAmount"
          type="number"
          class="form-control"
          min="0"
          step="0.01"
          required=""
        />
      </b-form-group>
    </div>
    <b-col class="d-flex justify-content-end mt-4">
      <b-form-group>
        <b-button variant="orange" size="lg" :disabled="disabledSave" @click="saveMovement()">
          Guardar movimiento
        </b-button>
      </b-form-group>
    </b-col>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  mounted() {
    this.resetForm();
  },
  computed: {
    filteredCategories() {
      return this.fundsCategories.filter(category => category.type === this.movement.type);
    },
    ...mapState('fundsCategory',{
      fundsCategories: state => state.fundsCategories,
    }),
    fundCategoriesLoaded() {
      return Array.isArray(this.fundsCategories);
    },
  },
  created () {
    this.$store.dispatch('fundsCategory/getFundsCategories').then(() => {

    })
  },
  methods: {
    getMovement(id) {
      var vm = this;
      this.$http.get("/api/movements/view/" + id).then((response) => {
        vm.movement = response.data.data;
        console.log(vm.movement);
      });
    },

    saveMovement() {
      var vm = this;
      vm.disabledSave = true;
      this.$http
        .post("/api/funds/movement/" + this.fundId, this.movement)
        .then((response) => {
          vm.resetForm();
          vm.$emit("confirmsave");
          vm.disabledSave = false;
          
        });
    },

    resetForm() {
      this.movement = Object.assign({}, this.emptyMovement);
    },
  },
  props: ["fundId", "balance"],

  data() {
    return {
      movement: {
        id: null,
        type: "out",
        amount: null,
        fund_id: this.id,
        sale_id: null,
        coupon_id: null,
        tpcheck_id: null,
        purchase_id: null,
        category_id: null,
        cc_id: null,
        user_id: null,
        pac_id: null,
        category: null,
        origen: "",
        in: null,
        out: null,
      },

      emptyMovement: {
        id: null,
        type: "out",
        amount: null,
        notes: "",
        fund_id: this.id,
        sale_id: null,
        coupon_id: null,
        tpcheck_id: null,
        purchase_id: null,
        category_id: null,
        cc_id: null,
        user_id: null,
        pac_id: null,
        category: null,
        origen: "",
        in: null,
        out: null,
      },

      movements: [],
      disabledSave: false,
      selected: null,

      movementType: [
        { value: "out", text: "Salida" },
        { value: "in", text: "Entrada" },
      ],
      categories: [],
      movementCategory: [
        { type: "in", value: 1, text: "INICIAL" },
        { type: "in", value: 2, text: "VENTA" },
        { type: "in", value: 7, text: "COBRO" },
        { type: "in", value: 9, text: "DEVOLUCION" },
        { type: "in", value: 11, text: "DEPOSITO DE CHEQUE" },
        { type: "in", value: 12, text: "COBRO DE CHEQUE" },
        { type: "out", value: 3, text: "COMPRA" },
        { type: "out", value: 4, text: "TRANSFERENCIA" },
        { type: "out", value: 5, text: "DEPOSITO" },
        { type: "out", value: 6, text: "EXTRACCION" },
        { type: "out", value: 8, text: "PAGO" },
        { type: "out", value: 10, text: "DEVOLUCION" },
      ],
    };
  },
};
</script>