<template>
  <b-nav align="center" class="lasubnav d-flex align-items-center py-1">      
    <b-nav-item><router-link :to="indexLink" class="text-white" active-class="active">Compras</router-link></b-nav-item>
    <b-nav-item><router-link :to="remitosDeCompraLink" class="text-white" active-class="active">Remitos</router-link></b-nav-item>
    <v-guard :permissions="['PROVEEDORES']">
    <b-nav-item><router-link :to="proveedoresLink" class="text-white" active-class="active">Proveedores</router-link></b-nav-item>
    </v-guard>
    <b-nav-item><router-link :to="transportesLink" class="text-white" active-class="active">Transportes</router-link></b-nav-item>
    <v-guard :permissions="['PROVEEDORES_CTA_CORRIENTE']">
    <b-nav-item><router-link :to="ccProveedoresLink" class="text-white" active-class="active">Cuenta corriente de proveedores</router-link></b-nav-item>
    </v-guard>
    <b-nav-item><router-link :to="pagoAProveedoresLink" class="text-white" active-class="active">Pago a proveedores</router-link></b-nav-item>
    <BranchofficeSelect v-if="getHasPermission" />
  </b-nav>
</template>

<script>
import BranchofficeSelect from "./../sub-components/branchoffice-select.vue";

export default {
  components: {
      BranchofficeSelect
  },
  data() {
    return {
      hasPermission : false
    }
  },
  computed: {
    getHasPermission() {
      return this.hasPermission
    },
    indexLink() {
      return { name: 'Compras' }
    },
    remitosDeCompraLink() {
      return { name: 'Remitos de compra' }
    },
    transportesLink() {
      return { name: 'Transportes' }
    },
    proveedoresLink() {
      return { name: 'Proveedores' }
    },
    ccProveedoresLink() {
      return { name: 'Cuenta corriente de proveedores' }
    },
    pagoAProveedoresLink() {
      return { name: 'Pago a proveedores' }
    },
    // dropdown nueva compra
  },
  methods: {
    getAllBranchofficesPermission() {
      this.$http
        .get("/api/configs/role/permissions/names", {
          headers: { 
            Authorization: "Bearer " + localStorage.getItem("token")
          },
        })
        .then((response) => {
          this.hasPermission = response.data.data.includes('ACCESS_ALL_BRANCHOFFICES_PURCHASES');
        })
    },
  },
  created() {
    this.getAllBranchofficesPermission();
  },
}
</script>

<style scoped>
.lasubnav {
  animation: opacity 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  background: #2d538a;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.active {
  font-weight: bold;
  color: white; 
  background-color: orange;
  border-radius: 10px 10px 0px 0px;
  padding: 11px;
}
</style>