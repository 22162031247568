
<template>
  <b-container>
    <div class="loading" v-if="loading">Loading&#8230;</div>
  
    <b-row class="d-flex justify-content-center">
      
      <b-col cols="md-4 mt-4">
        <div class="card mb-4">
          <b-card-header>
            <h6>
              Configuraciones de Moneda
            </h6>            
          </b-card-header>
          <b-card-body>
            <b-form-group>
              <label class="control-label" for="input-live">Separador de decimales</label>
              <b-form-input
                class="form-control"
                id="input-live"
                v-model="configurations.CURRENCY_DECIMAL_SEPARATOR"
                :state="state"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Nombre de la categoría"
                trim
              ></b-form-input>
              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback v-if="state === false" id="input-live-feedback" >
                El valor debe tener un mínimo de 1 letra
              </b-form-invalid-feedback>
              <!-- This is a form text block (formerly known as help block) -->
              <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
            </b-form-group>

              <b-form-group>
              <label class="control-label" for="input-live">Separador de miles</label>
              <b-form-input
                class="form-control"
                id="input-live"
                v-model="configurations.CURRENCY_THOUSAND_SEPARATOR"
                :state="state"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Nombre de la categoría"
                trim
              ></b-form-input>
              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback v-if="state === false" id="input-live-feedback" >
                El valor debe tener un mínimo de 1 letra
              </b-form-invalid-feedback>
              <!-- This is a form text block (formerly known as help block) -->
              <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
            </b-form-group>

            <b-form-group>
              <label class="control-label" for="input-live">Cantidad de digitos decimales</label>
              <b-form-input
                class="form-control"
                id="input-live"
                v-model="configurations.CURRENCY_DECIMAL_DIGITS"
                :state="state"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Nombre de la categoría"
                trim
              ></b-form-input>
              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback v-if="state === false" id="input-live-feedback" >
                El valor debe tener un mínimo de 1 letra
              </b-form-invalid-feedback>
              <!-- This is a form text block (formerly known as help block) -->
              <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
            </b-form-group>
          </b-card-body>
          <div class="card-footer">
            <a v-if="state === null" @click="save()">
              <b-button variant="orange" size="lg">Guardar</b-button>
            </a>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>


export default {
 
  data: function() {
    return {
      configurations:{
        CURRENCY_DECIMAL_SEPARATOR:'',CURRENCY_DECIMAL_DIGITS:'',CURRENCY_THOUSAND_SEPARATOR:''
      }, 
      loading: false,
    };
  },
  mounted() {
    this.getConfigurations();
  },
  computed: {
    state() {
      return this.configurations.CURRENCY_DECIMAL_SEPARATOR.length < 1 ||
         this.configurations.CURRENCY_DECIMAL_DIGITS.length < 1 ||
          this.configurations.CURRENCY_THOUSAND_SEPARATOR.length < 1
        ? false
        : null;
    },
  },
  watch: {
  
  },
  methods: {    
    getConfigurations() {
      var vm = this;
      vm.loading = true
      this.$http.get("/api/configs/getConfigurations").then((response) => {
        vm.configurations = response.data.data;
        vm.loading = false
      });
    },

    save() {
      this.loading = true
        this.$http
          .post("/api/configs/updateConfigurations",this.configurations)
          .then((response) => {
            
            console.log(response.data);
            this.getConfigurations();
          });
    },
  },
};
</script>

<style>
  .divActions {
    float: right;
  }
  .marginButton {
    margin-left: 10px;
  }
</style>
