import { render, staticRenderFns } from "./Receipt-products.vue?vue&type=template&id=02a17680&scoped=true"
import script from "./Receipt-products.vue?vue&type=script&lang=js"
export * from "./Receipt-products.vue?vue&type=script&lang=js"
import style0 from "./Receipt-products.vue?vue&type=style&index=0&id=02a17680&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02a17680",
  null
  
)

export default component.exports