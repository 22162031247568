<template>
  
    <table class="table">
        <tr>
            <th>
                <div class="form-group">
                    <label>Numero</label>
                    <input v-model="res.number" type="number" min="0" class="form-control">
                </div>
            </th>
            <th>
                <div class="form-group">
                    <label>Banco</label>
                    <input v-model="res.bankname" type="text" class="form-control">
                </div>
            </th>
            <th>
                <div class="form-group">
                    <label>Fecha cobro</label>
                    <masked-input v-model="res.datedue" required mask="11/11/1111" placeholder="dd/mm/yyyy" class="form-control"/>
                </div>
            </th>
            <th>
                <div class="form-group">
                    <label>Monto</label>
                    <input v-model.number="res.amount" type="number" min="0" step="0.01" class="form-control">
                </div>
            </th>
            <th>
                <div class="form-group">
                    <label>Nombre de emisor</label>
                    <input v-model="res.name" class="form-control">
                </div>
            </th>
            <th>
                <div class="form-group">
                    <label>CUIT emisor</label>
                    <input v-model.number="res.cuit" type="number" min="0" class="form-control" required>
                </div>
            </th>
            <th class="col-1">
              <div class="form-group">
                    <span @click="reset()" aria-hidden="true" title="Eliminar" class="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                        </svg>
                    </span>
                    <span v-if="validate" @click="save()" class="glyphicon glyphicon-ok-sign ok-payment-method" aria-hidden="true" title="Grabar">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                    </span>
              </div>
            </th>
        </tr>
    </table>
</template>


<script>
import maskedInput from 'vue-masked-input';

export default {
    props: ['funds','emisionData'],
  data: function() {
    return {
      res: {
          amount: 0,
          datedue: null,
          number: null,
          bankname: '',
          cuit: null,
          name: null,
          id: null,
      },
      b_funds:[],
    }
  },
  mounted: function () {
    this.b_funds = this.funds.filter(fund => fund.type == "Banco");
    if(this.emisionData.bsns !=null){
        this.res.name = this.emisionData.bsns;
    }else{
        this.res.name = this.emisionData.name;
    }
    this.res.cuit = this.emisionData.cuit;
  },
  components: {
    maskedInput,
  },
  watch: {
  },
  methods: {
		reset: function () {
            this.$emit('sfcheckcancel');
            },
        save: function () {
            this.$emit('sfchecksaved',this.res);
        },
	},
	computed: {
		validate () {
            let vm = this.res;
            return (vm.number !=null && vm.amount > 0 && vm.datedue!=null && vm.bankname!='' && vm.name!=null && vm.cuit!=null);
        }
  }
}
</script>


