<template>
  <b-nav align="center" class="lasubnav d-flex align-items-center py-1" v-if="salesclerkModule === 1">   
    <b-nav-item><router-link :to="indexLink" class="text-white" active-class="active">Ventas</router-link></b-nav-item>
    <v-guard :permissions="['PRESUPUESTOS']">
    <b-nav-item><router-link :to="presupuestosLink" class="text-white" active-class="active">Presupuestos</router-link></b-nav-item>
    </v-guard>
    <b-nav-item><router-link :to="notasDePedidoLink" class="text-white" active-class="active">Notas de pedido</router-link></b-nav-item>
    <b-nav-item><router-link :to="remitosDeVentaLink" class="text-white" active-class="active">Remitos</router-link></b-nav-item>
    <v-guard :permissions="['CLIENTES']">
    <b-nav-item><router-link :to="clientesLink" class="text-white" active-class="active">Clientes</router-link></b-nav-item>
    </v-guard>
    <b-nav-item><router-link :to="vendedoresLink" class="text-white" active-class="active">Vendedores</router-link></b-nav-item>
    <v-guard :permissions="['CLIENTES_CTA_CORRIENTE']">
    <b-nav-item><router-link :to="ccClientesLink" class="text-white" active-class="active">Cuenta corriente de clientes</router-link></b-nav-item>
    <b-nav-item><router-link :to="cobranzasLink" class="text-white" active-class="active">Cobranzas</router-link></b-nav-item>
    </v-guard>
    <BranchofficeSelect v-if="hasPermission" />
  </b-nav>
  <b-nav align="center" class="lasubnav d-flex align-items-center py-1" v-else>   
    <b-nav-item><router-link :to="indexLink" class="text-white" active-class="active">Ventas</router-link></b-nav-item>
    <v-guard :permissions="['PRESUPUESTOS']">
    <b-nav-item><router-link :to="presupuestosLink" class="text-white" active-class="active">Presupuestos</router-link></b-nav-item>
    </v-guard>
    <b-nav-item><router-link :to="notasDePedidoLink" class="text-white" active-class="active">Notas de pedido</router-link></b-nav-item>
    <b-nav-item><router-link :to="remitosDeVentaLink" class="text-white" active-class="active">Remitos</router-link></b-nav-item>
    <v-guard :permissions="['CLIENTES']">
    <b-nav-item><router-link :to="clientesLink" class="text-white" active-class="active">Clientes</router-link></b-nav-item>
    </v-guard>
    <v-guard :permissions="['CLIENTES_CTA_CORRIENTE']">
    <b-nav-item><router-link :to="ccClientesLink" class="text-white" active-class="active">Cuenta corriente de clientes</router-link></b-nav-item>
    <b-nav-item><router-link :to="cobranzasLink" class="text-white" active-class="active">Cobranzas</router-link></b-nav-item>
    </v-guard>
    <BranchofficeSelect v-if="hasPermission" />
  </b-nav>     
</template>

<script>
  import BranchofficeSelect from "./../sub-components/branchoffice-select.vue";
  export default {
    components: {
        BranchofficeSelect
    },
    data() {
      return {
        hasPermission: false,
        salesclerkModule: 0
      }
    },
    methods: {
      getAllBranchofficesPermission() {
        this.$http
          .get("/api/configs/role/permissions/names", {
            headers: { 
              Authorization: "Bearer " + localStorage.getItem("token")
            },
          })
          .then((response) => {
            this.hasPermission = response.data.data.includes('ACCESS_ALL_BRANCHOFFICES_SALES');
          })
      },
      getAuthUser() {
        this.$http
          .get("/api/users/getAuthUser", {
            headers: { 
              Authorization: "Bearer " + localStorage.getItem("token")
            },
          })
          .then((response) => {
            this.salesclerkModule = response.data.data.branchoffices[0].options.salesclerk_module;
          })
      },
    },  
    computed: {
      indexLink() {
        return { name: 'Ventas' }
      },
      presupuestosLink() {
        return { name: 'Presupuestos' }
      },
      notasDePedidoLink() {
        return { name: 'Notas de pedido' }
      },
      remitosDeVentaLink() {
        return { name: 'Remitos de venta' }
      },
      clientesLink() {
        return { name: 'Clientes' }
      },
      vendedoresLink() {
        return { name: 'Vendedores' }
      },
      ccClientesLink() {
        return { name: 'Cuenta corriente de clientes' }
      },
      cobranzasLink() {
        return { name: 'Cobranzas' }
      },
      //nuevaVentaLink() {
      //  return { name: 'Nueva venta' }
      // },
    },
    mounted() {
      this.getAllBranchofficesPermission();
      this.getAuthUser();
    },
  }
</script>

<style scoped>
.lasubnav {
  animation: opacity 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  background: #264775;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.active {
  font-weight: bold;
  color: white; 
  background-color: orange;
  border-radius: 10px 10px 0px 0px;
  padding: 11px;
}
</style>
