<template>
  <b-row v-if="letters.length > 0">

    <b-col cols="md-3">
      <div class="d-flex justify-content-between">
        <label><b>Seleccionar cliente</b></label>
        <b-button @click="newClient" variant="success" size="sm" class="ml-5">Nuevo cliente <b-icon-person-plus></b-icon-person-plus></b-button>
      </div>
      <b-form-group>
        <typeahead :name="'client'" :placeh="'Ingrese cliente'" :clean-on="false" v-on:choosed="clientChoosed" :default="config.defaultClient" :externalsrc="srcClients" limit-chars="4"/>
      </b-form-group>
    </b-col>

    <b-col v-if="letters[0].letter!='P'" cols="md-1">
      <div class="form-group">
        <label class="control-label"><b>Tipo</b></label>
        <select v-if="letters.length > 1" v-model="header.letter" class="form-control select-iva">
          <option v-for="letter in letters" :key="letter.letter">{{letter.letter}}</option>
        </select>
        <input v-else v-model="header.letter" type="text" class="form-control" disabled>
      </div>
    </b-col>

    <b-col cols="md-1">
      <div class="form-group">
        <label class="control-label"><b>P. de V.</b></label>
        <select v-if="posnumbers.length > 1" v-model="header.pos" class="form-control">
          <option v-for="pos in posnumbers" :key="pos.pos" :value="pos.pos">{{pos.pos}}</option>
        </select>
        <input v-else v-model="header.pos" type="text" class="form-control" disabled>
      </div>
    </b-col>

    <b-col cols="md-1">
      <div class="form-group">
        <label class="control-label"><b>Numero</b></label>
        <input v-model="header.number" id="number" type="text" class="form-control">
      </div>
    </b-col>

    <b-col cols="md-2" v-if="showPricelist">
      <div class="form-group">
        <label class="control-label"><b>Lista de precios</b></label>
        <select v-model="header.pricelist_id" class="form-control">
          <option v-for="(priceslist,index) in priceslists" :key='index' v-bind:value="priceslist.id">{{priceslist.name}}</option>
        </select>
      </div>
    </b-col>

    <b-col cols="md-1">
      <div v-if="showChoosedate" class="form-group">
        <label class="control-label"><b>Fecha FC</b></label>
          <datepicker-component v-on:datechoosed="dateUpdated"/>
      </div>
    </b-col>

    <b-col v-if="showservicedate" cols="md-1">
      <div class="form-group pull-left">
        <label class="control-label"><b>Desde</b></label>
        <datepicker-component v-on:datechoosed="fromdateUpdated" required/>
      </div>
    </b-col>
    
    <b-col v-if="showservicedate" cols="md-1">
      <div class="form-group pull-left">
        <label class="control-label"><b>Hasta</b></label>
        <datepicker-component v-on:datechoosed="todateUpdated" required/>
      </div>
    </b-col>

    <b-col v-if="showservicedate" cols="md-1">
      <div class="form-group pull-left">
        <label class="control-label"><b>Venc. FC</b></label>
        <datepicker-component v-on:datechoosed="expdateUpdate" required/>
      </div>
    </b-col>

    <b-col cols="md-12">
        <ModalNuevoCliente 
          :clientId="clientIdParent"
          v-if="showModal"
          @edit="changeClientId"
          @close="showModal=false"
        />
      </b-col> 
    
  </b-row>
</template>

<script>
import typeahead from '../global/Typeahead.vue';
import datepickerComponent from '../global/Datepicker-component.vue';
import ModalNuevoCliente from "../../clientes/Nuevo-cliente.vue";
//import shortkey from 'vue-shortkey';

export default {
  props: ['config', 'showPricelist', 'showChoosedate', 'posnumbers', 'showservicedate'],
  data: function() {
    return {
      header:JSON.parse(localStorage.getItem('header')) || {
        client_id: null,
        cc_only: null,
        pricelist_id: null,
        number: null,
        letter: null,
        receipt_date: null,
        receipt_servicefromdate: null,
        receipt_servicetodate: null,
        receipt_serviceexpdate: null,
        pos: null
      },
      letters: [],
      showModal: false,
      clientIdParent: null,
      priceslists : [],
      srcClients: '/api/clients/getClients',
      clientIva: ''
    }
  },
  mounted: function () {
    var vm = this;
    vm.header.pos = this.config.pos_default;
    vm.letters = vm.posnumbers.filter(e => e.pos === vm.header.pos).shift().numbers;
    vm.header.letter = vm.letters[0].letter;

    vm.numberUpdated();
    vm.$emit('headerchanged', this.header);
  },
  watch: {
    'header.pos': { // Esto envia los datos de header cada vez que cambian al receipt donde seran adjuntados al payload
      handler: function () {
        var vm = this;
        vm.letters = vm.posnumbers.filter(e => e.pos === vm.header.pos).shift().numbers;
        vm.header.letter = vm.letters[0].letter;
        
        this.clientIvaType(this.header.client_id);
        this.numberUpdated();
        this.$emit('headerchanged', this.header);
      }
    },
    'header.pricelist_id': { // Esto envia los datos de header cada vez que cambian al receipt donde seran adjuntados al payload
      handler: function () {
        this.$emit('headerchanged', this.header);
      }
    },
    'header.letter': { // Esto envia los datos de header cada vez que cambian al receipt donde seran adjuntados al payload
      handler: function () {
        this.numberUpdated();
        this.$emit('headerchanged', this.header);
      }
    },
    'header.client_id': { // Esto envia los datos de header cada vez que cambian al receipt donde seran adjuntados al payload
      handler: function (a) {
        this.clientIvaType(a);
        this.clientCC(a);
        this.$emit('headerchanged', this.header);
        this.$emit('clientchanged', this.header.client_id, this.header.cc_only);
      }
    },
    priceslists: function (newpriceslists) { //Cuando se cargen las listas de precios actualiza el select con la configurada como default si existe, sino la primera de la lista
      if (newpriceslists.length) {
        this.header.pricelist_id = (this.pricelist_exist(this.config.pricelist_default) ? this.config.pricelist_default : this.priceslists[0].id );
      } else {
        this.header.pricelist_id = this.config.pricelist_default;
      }
    }
  },
  methods: {
    fromdateUpdated: function (date) {
      this.header.service_fromdate = date;
    },
    todateUpdated: function (date) {
      this.header.service_todate = date;
    },
    expdateUpdate: function (date) {
      this.header.service_expdate = date;
    },
    dateUpdated: function (date) {
      this.header.date = date;
    },
    numberUpdated: function () {
      this.header.number=this.letters.filter(e => e.letter === this.header.letter).shift().number;
    },
    clientIvaType: function($id) {
      var app = this;
      if ( ($id!=null) && ($id>0) && (this.letters.length > 1) ) {
        window.axios.get('/api/clients/getClient/'+$id)
        .then(function (response) {
          var client = response.data.data;
          if (client.iva_type=="Responsable Inscripto") {
            app.header.letter = app.letters.filter(e => e.letter !== 'B').shift().letter;
          } else {
            app.header.letter = "B";
          }
        })
        .catch(function (error) {
          alert(error.message);
        });
      }
    },
    clientCC: function($id) {
      var app = this;
      window.axios.get('/api/clients/getClient/'+$id)
      .then(function (response) {
        var cc_only = response.data.data.cc_only;
        app.header.cc_only = cc_only;
      })
      .catch(function (error) {
        alert(error.message);
      });
    },
    getPricelist: function () {
      var app = this;
      window.axios.get('/api/priceslist/getPriceList')
      .then(function (response) {
        app.priceslists = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    clientChoosed(data) {
      this.header.client_id=data.id;
      this.clientIva = data.iva;
    },
    pricelist_exist(pricelist_id) {
      const filteredList = this.priceslists.filter(e => e.id === pricelist_id);
      return !!filteredList.length;
    },
    changeClientId() {
      this.clientIdParent = this.header.client_id;
      this.showModal= true;
    },
    newClient(){
      if (this.header.client_id === 1 || this.header.client_id === null) {
        this.clientIdParent=null;
        this.showModal = true;
      }else {
        this.changeClientId();
      }
    },
   },
   created () {
    var vm = this;
    vm.getPricelist();
   },
   components:{
    typeahead,
    datepickerComponent,
    ModalNuevoCliente
  }
}
</script>
