<template>
  <form class="form" @submit.prevent>
    <div class="form-body">
      <div class="form-group">
        <label for="input-live">Proveedor: {{ provider.name }}</label>
        
      </div>
      <div class="form-group">
        <label for="date-date">Fecha:</label>            
        <input type="date" class="date ml-1" id="date-date" required v-model="pasecuenta.date">
      </div>
      <div class="form-group">
        <label class="control-label">Tipo de movimiento</label>
        <b-form-select id="type" name="type" class="form-control" v-model="pasecuenta.type" required>
            <option value="" selected>-- Seleccionar tipo de movimiento --</option>
            <option value="1">Nota de débito</option>
            <option value="2">Nota de crédito</option>
        </b-form-select>
        <div class="pull-right">
            <input id="initial" name="initial" type="checkbox" value="true">
            <label class="ml-1" for="initial">Ingresar como saldo inicial</label>
        </div>
      </div>
      <div class="form-group">
        <label for="input-live">Monto:</label>
        <b-form-input
            id="input-live"
            v-model="pasecuenta.amount"
            aria-describedby="input-live-help input-live-feedback"
            trim
        ></b-form-input>
      </div>
      <div class="form-group">
        <label for="input-live">Motivo:</label>
        <b-form-input
            id="input-live"
            v-model="pasecuenta.motive"
            aria-describedby="input-live-help input-live-feedback"
            trim
        ></b-form-input>          
        <b-form-invalid-feedback id="input-live-feedback">
        Ingrese un codigo valido
        </b-form-invalid-feedback>
      </div>
      <div class="form-group">
        <label for="date-expire">Vencimiento:</label>
        <input class="date ml-1" type="date" id="date-expire" v-model="pasecuenta.expire">
      </div>
      <div class="form-actions">
        <a    
          type="button" 
          @click="savePase()" 
          class="btn btn-btn btn-orange"
          data-toggle="tab" 
          >Agregar pase a cuenta
        </a>
      </div>
    </div>
  </form>
</template>

<script>
  export default {
    props: ['providerId'],

    data() {
      return {
        pasecuenta: {
          provider_id: null,
          date: '',
          type: '',
          motive: '',
          number: null,
          amount: null,
          expire: '',
        },
        emptyPasecuenta: {
          provider_id: null,
          date: '',
          type: '',
          motive: '',
          number: null,
          amount: null,
          expire: '',         
        },
        provider: {
          id: null,
          name: ''
        },
        emptyProvider: {
          id: null,
          name: ''
        },
        providers: [],                
      }
    },
    mounted() {
      this.getProviders();
      this.resetForm();
      this.getProvider(this.providerId);
    },
    methods: {
      getProvider() {
        var vm = this;
        this.$http.get("/api/providers/getProvider/" + this.providerId).then((response) => {
          vm.provider = response.data.data;
        });
      },
      getProviders() {
        var vm = this;
        this.$http.get('/api/providers/list').then((response) => {          
          vm.providers = response.data.data
        })
      },
      savePase() {
        var vm = this;
        this.pasecuenta.provider_id = this.providerId
          this.$http.post('/api/providers/addPase', this.pasecuenta).then((response) => {
            
            vm.resetForm();
            vm.$emit('confirmsave');
          })
      },
      resetForm() {
        this.pasecuenta = Object.assign({}, this.emptyPasecuenta);
      },
      cleanForm() {
        this.$emit('clean_id');
        this.resetForm();
      }
    },
    watch: {
      providerId: function(value) {
        if (value != null) {
          this.getProvider(value);
        } else {
          this.resetForm();
        }            
      }
    }  
  }
</script>
