export const pushSuccess = (state, success) => {
  state.success = success
}

export const clearSuccess = (state) => {
  state.success = null
}

export const setLoading = (state, value) => {
  state.loading = value;
} 

