<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Resumen de cuentas de vendedores</h4>
      </b-col>
    </div>
    <b-row text-center class="statsreport">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="stat">
          <div class="number">{{formatoDinamicoMoneda(acumulado_debe)}}</div>
          <div class="desc">Debe acumulado</div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 border-r-l">
        <div class="stat">
          <div class="number">{{formatoDinamicoMoneda(acumulado_haber)}}</div>
          <div class="desc">Haber acumulado</div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div class="stat">
          <div class="number">{{formatoDinamicoMoneda(acumulado_saldo)}}</div>
          <div class="desc">Saldo acumulado</div>
        </div>
      </div>
    </b-row>

    <b-form inline class="d-flex justify-content-between mt-2">
      <b-form-group>
        <input type="button" @click="exportPDF()" class="btn btn-md btn-light mr-1" value="Exportar PDF">
        <input type="button" @click="exportNominaPDF()" class="btn btn-md btn-light" value="Exportar Nomina PDF">
      </b-form-group>
  
      <b-form-group>
        <Daterange :opendir="'right'" :filtered="true" @datepicked="dateCreated"/>
      </b-form-group>
      <b-form-group>
          <b-form-select class="form-control" style="padding-right: 17px;" v-model="clerk_id">
            <option :value="null">Todos los vendedores</option>
            <option v-for="clerk in salesClerks" :key="clerk.id" :value="clerk.id">{{ clerk.name }}</option>
          </b-form-select>
      </b-form-group>
      <b-form-group>
          <select class="form-control btn-light load-data" v-model="client_balance">
            <option value="0">Todos los clientes</option>
            <option selected="selected" value="1">Clientes con saldo</option>
            <option value="2">Clientes sin saldo</option>
          </select>
      </b-form-group>
      <b-form inline>
          <input id="historical" v-model="historical" class="load-data mr-1" type="checkbox">
          <label for="historical">Movimientos sin saldo</label>
      </b-form>
    </b-form>
  
    <b-row >
      <b-col cols="md-12">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          ref="table"
        >
      </vdtnet-table>
      </b-col>  

    </b-row>
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import formatDateMixin from "../../mixins/formatDateMixin";
import exportsMixin from "../../mixins/exportsMixin";
import Daterange from "../global/Daterange-component.vue";
import myaxios from 'axios';
import { mapState } from 'vuex';


export default {
  components: {
    VdtnetTable,
    Daterange, 
  }, 
 mixins: [formatMoneyMixin,formatDateMixin,exportsMixin],
 data: function() {
    return {
      loading: false,
      acumulado_debe:0,
      acumulado_haber:0,
      acumulado_saldo:0,
      selectedClerk:null,
      startDate:null,
      endDate:null,
      clerk_id:null,
      client_balance:0,
      historical:false,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax:  {
          url: process.env.VUE_APP_BASE_URL+"/api/reports/cccbyclerk",
          type: "GET",
          data: (d) => {
            d.daterange = this.daterange,
            d.clerk_id = this.clerk_id,
            d.client_balance = this.client_balance,
            d.historical = this.historical 
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
        rowGroup: {
          startRender: function (rows, group) {
                return $('<tr/>').append( '<td style="text-align:left;background-color: gray;color: white;" colspan="5">'+group+'</td>' );
            },
            endRender: function (rows, group) {
                var totalDebe = rows.data().pluck('debe').reduce( function (a, b) {return parseFloat(a) + parseFloat(b);}, 0);
                var totalHaber = rows.data().pluck('haber').reduce( function (a, b) {return parseFloat(a) + parseFloat(b);}, 0);
                var totalSaldo = rows.data().pluck('saldo').reduce( function (a, b) {return parseFloat(a) + parseFloat(b);}, 0);

                return $('<tr/>').append('<td colspan="2"> Totales </td>')
                                .append('<td>$ ' + $.fn.dataTable.render.number('.', ',', 2).display(totalDebe) +'</td>')
                                .append('<td>$ ' + $.fn.dataTable.render.number('.', ',', 2).display(totalHaber) +'</td>')
                                .append('<td>$ ' + $.fn.dataTable.render.number('.', ',', 2).display(totalSaldo) +'</td>');
            },
            dataSrc: ['client'],
        },
        processing: true,
        pageLength: 15,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        order: [[1, 'desc']],
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
      },
      fields: {
        date: { label: "Fecha",  render: this.formatoFechaHora},
        doc: { label: "Comprobante" },
        debe: { label: "Debe", render: this.formatoDinamicoMoneda},
        haber: { label: "Haber", render: this.formatoDinamicoMoneda},
        saldo: { label: "Saldo", render: this.formatoDinamicoMoneda},
      },
    };
  },
  mounted(){
    this.getTotals();
    this.setDefaultDateRange();
  },
  computed:{
    daterange() {
      if (this.startDate === null || this.endDate === null) {
        return null;
      }
      const startDate = this.formatoFecha2Daterange(this.startDate);
      const endDate = this.formatoFecha2Daterange(this.endDate);
      return `${startDate} - ${endDate}`;
    },
    filters(){
      return {
        daterange : this.daterange,
        clerk_id:this.clerk_id,
        client_balance:this.client_balance,
        historical:this.historical,  
      }
    },
    ...mapState('salesClerks',{
      salesClerks: state => state.salesClerks,
    }),  
  },
  created() {
     this.$store.dispatch('salesClerks/getSalesclerks')
  },
  methods: {
    reload(){
      this.getTotals();
      this.refreshTable();
    },
    refreshTable() {  
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    },
    dateCreated(date) {
      this.startDate = date.startDate;
      this.endDate = date.endDate;
      this.refreshTable();
    },
    getTotals() {
      this.loading = true;
      this.$http.post("/api/salesclerks/getGeneralBalanceToDate" , this.filters)
      .then((response) => {
        this.acumulado_debe = response.data.data.acumuladoDebe;
        this.acumulado_haber = response.data.data.acumuladoHaber;
        this.acumulado_saldo = response.data.data.acumuladoSaldo;
        this.loading = false;
      });    
    },
    getFormattedCurrentDate() {
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear();
      return `${day}-${month}-${year}`;
    },
    exportPDF() {
      const selectedClerk = this.salesClerks.find((clerk) => clerk.id === this.clerk_id);

      if (selectedClerk) {
        this.selectedClerkName = selectedClerk.name;
      } else {
        this.selectedClerkName = "Todos los vendedores";
      }
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/printcccBySalesclerk", this.filters)
      .then((response) =>{
      });

    },
    exportNominaPDF() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/PDFNominaclientsbyclerk", this.filters)
      .then((response) =>{
      });
    },
    setDefaultDateRange() {
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - 30);

        this.startDate = this.formatDate(startDate);
        this.endDate = this.formatDate(today);
      },
      formatDate(date) {
          const year = date.getFullYear();
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const day = ('0' + date.getDate()).slice(-2);
          return `${day}/${month}/${year}`;
      },
  },
  watch: {
      filters() {
        this.reload();
      },
    },
  }
</script>