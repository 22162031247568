<template>
   <pos-documents :configurl="'/api/debitnotes/create'" />
</template>
<script>
import PosDocuments from "../pos-document/receipt/Receipt.vue";

export default {
   components: {
      PosDocuments
   },
};
</script>