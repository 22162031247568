
export const clearErrors = ({ commit }) => {
  try{
    commit('clearErrors')
    commit('setLoading', true);
    }
    finally {
      setTimeout(() => {
        commit('setLoading', false)
      }, 1000)
    }
  }