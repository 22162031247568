<template>
  <transition name="modal">

  <div class="modal-mask-product">
    <div class="modal-dialog  modal-lg">
      <div class="modal-content p-3 rounded">

        <div class="modal-header">
            <h4>
              Ingres&aacute; el art&iacute;culo
            </h4>
            <b-button variant="primary size-sm" @click="close()"><i class="bi bi-x-lg"></i></b-button>
        </div>
        
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-md-6 my-2">
              <label><b>Descripci&oacute;n</b></label>
              <input type="text" v-model="product.name" min="0" class="form-control" placeholder="Ingresar nombre...">
            </div>
            <div class="col-12 col-md-6 my-2">
              <label class="control-label"><b>Tipo de IVA</b></label>
              <select name="iva" v-model="product.taxtype" class="form-control">
                <option value="0">SIN IVA</option>
                <option value="2.5">IVA 2,5%</option>
                <option value="5">IVA 5%</option>
                <option value="10.5">IVA 10,5%</option>
                <option value="21">IVA 21%</option>
                <option value="27">IVA 27%</option>
              </select>
            </div>
            <div class="col-12 col-md-6 my-2">
              <label><b>Precio</b></label>
              <input type="number" v-model="product.price" min="0" step="0.01" class="form-control">
            </div>
            <div v-if="discount_each_line" class="col-12 col-md-6 my-2">
              <label><b>Discount</b></label>
              <input type="number" v-model="product.discount" min="0" step="0.01" class="form-control">
            </div>
            <!--SELECCIONAR PRODUCTO O SERVICIO-->
            <div class="col-12 col-md-6 my-2">
              <label class="control-label"><b>Tipo</b></label>
              <select name="tipo" class="form-control" v-model="product.type">
                <option value="PRODUCT">Producto</option>
                <option value="SERVICE">Servicio</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <b-button class=" mr-5" variant="outline-primary" size="lg" @click="close">
            Cancelar
          </b-button>
          <b-button type='submit' variant="orange" size="lg" @click='sendProducts'>Agregar Producto</b-button>
        </div>
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
export default {
  props: ['discount_each_line'],
  data() {
    return {
      product: {
        name: '',
        price: 0,
        taxtype: 21,
        discount: 0,
        type: "PRODUCT",
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    sendProducts() {
      var newitem = {
          quantity: 1,
          id: 0,
          price: this.product.price,
          bkprice: this.product.price,
          name: this.product.name,
          includetax: 1,
          taxtype: this.product.taxtype,
          type: this.product.type,
          unit: "U",
          gross: this.product.price / (1+(this.product.taxtype/100)),
          tax: (this.product.price / (1+(this.product.taxtype/100))) * this.product.taxtype/100,
          barcode: null,
          code: null,
          promorules: [],
          stock:0,
          discount: this.product.discount,
      };

      this.$emit('sendProduct', newitem);
    }
  }
}
</script>
<style scoped>
.modal-mask-product {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>