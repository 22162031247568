<template>
    <button class="btn btn-orange sm">
        <slot name="actions">            
        </slot>
    </button>
</template>

<script>
export default {

}
</script>

<style scoped>
a {
  color: white
}

</style>