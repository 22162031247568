<template>
    <b-container fluid>
        <div class="row mt-3">
            <b-col class="d-flex justify-content-start">
                <h4>Cobranzas</h4>
            </b-col>
        </div>

        <!-- --------- BUSCADOR -------------- -->
        <b-row>
            <b-col cols="md-3 mt-1">
                <b-form-group>
                        <label class="control-label"><b>Cliente:</b></label>
                            <typeahead
                            class="mt-1"
                            :name="'client'"
                            :placeh="'Busqueda de cliente por nombre'"
                            :clean-on="false"
                            @choosed="clientUpdate"
                            @empty="clientEmpty"
                            :default="'null'"
                            :externalsrc="'/api/clients/getClients'"
                            limit-chars="4"/>
                </b-form-group>
            </b-col>
            <b-col cols="md-3 mt-2">
                <b-form-group>
                    <label class="control-label"><b>Desde:</b></label>
                    <datepicker-component @datechoosed="fromDateUpdated" :dateLabel="'Seleccione una fecha'"/>
                </b-form-group>
            </b-col>
            <b-col cols="md-3 mt-2">
                <b-form-group>
                    <label class="control-label"><b>Hasta:</b></label>
                    <datepicker-component @datechoosed="toDateUpdated"/>
                </b-form-group>
            </b-col>
            <b-col cols="md-3" class="my-4">
                <b-nav align="right">
                    <b-button-group size="lg">
                        <b-button variant="secondary" @click="activeTab=0" v-bind:class="[{'active': activeTab==0}]">Cobranzas</b-button>
                        <b-button variant="secondary" @click="activeTab=1" v-bind:class="[{'active': activeTab==1}]">Recibos</b-button>
                        <b-button variant="secondary" @click="activeTab=2" v-bind:class="[{'active': activeTab==2}]">Imputaciones</b-button>
                    </b-button-group>
                </b-nav>
            </b-col>
        </b-row>
        <b-card class="bg-light mb-4 p-0">
            <div class="row d-flex justify-content-between align-items-center" v-if="payload.header.client_id" v-show="activeTab==0">
                <b-col cols="md-3">
                    <b-form-group>
                        <label><b>SALDO EN CC:</b></label>
                        <div>
                            <h3>{{balanceText}}</h3>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="md-3">
                    <b-form-group>
                        <label><b>CONCILIACION:</b></label>
                        <div>
                            <h3 v-bind:class="{'rojo': (concilValue<0)}">{{concilText}}</h3>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="md-3">
                    <b-form-group>
                        <label>COMPROBANTES: </label>
                        <select v-model="posFilter" class="form-control ml-2" name="posFilter">
                            <option :value="0">TODOS LOS COMPROBANTES</option>
                            <option :value="1">COMPROBANTES DE PRUEBA</option>
                            <option :value="2">COMPROBANTES OFICIALES</option>
                        </select>
                    </b-form-group>
                </b-col>
                <b-col cols="md-3 text-right">
                    <div class="form-check form-check-inline">
                            <label class="form-check-input" for="historical">Mostrar histórico</label>
                            <input class="form-check-label" id="historical" name="historical" type="checkbox" v-model="historical">
                    </div>
                </b-col>
            </div>
            <div v-else class="row d-flex align-items-center" style="height: 63px;">
                <div class="col">
                    <h4>Buscar un cliente para ver la información</h4>
                </div>
            </div>
        </b-card>

        <div class="d-flex justify-content-end mb-3">
            <button class='btn btn-light mr-4' @click="showModalRet = true">Pasar retencion</button>
            <button id="allocation-btn" class="btn btn-outline-primary" @click="checkAllocations()">Imputar</button>
            <button class='btn btn-orange ml-2' @click="ifModalPayment()">Nuevo Cobro</button>
        </div>
        
        <b-row v-show="activeTab==0">
            <b-col cols="md-6">
                <div  v-if="tableDocFields.length>0" class="col-sm-12 table table-light row-border dataTable no-footer">
                    <label class="control-label mt-2">Busqueda por factura:</label>
                    <input v-model="docFilter" class="form-control mb-2">
                </div>
                <table class="table table-light table-striped mb-5">

                    <thead>
                        <tr role="row">
                            <th><input type="checkbox" v-model="selectAllDocs"></th>
                            <th>Fecha</th>
                            <th>Factura</th>
                            <th>Deuda</th>
                            <th>Saldo</th>
                            <th class="col-sm-1"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <b-tr role="row" v-for="(doc, index) in tableDocFieldsFiltered" :key="index" v-bind:class="[{selected: doc.rowSelected},{'rojo': doc.payment},{'azul': doc.historical}]">
                            <b-td>
                                <input type="checkbox"
                                v-model="selectedDocs"
                                v-bind:value="doc"
                                @click="selectRow($event, doc)">
                            </b-td>
                            <b-td>{{ doc.date }}</b-td>
                            <b-td>{{ doc.docname }}</b-td>
                            <b-td>$ {{ parseFloat(doc.value).toFixed(2)}}</b-td>
                            <b-td>$ {{ parseFloat(doc.debt).toFixed(2)}}</b-td>
                            <b-td class="col-sm-1">
                                <div v-if="doc.docname !='SALDO INICIAL'" class="btn-group dropdown pull-right">
                                    <b-button-group>
                                        <b-dropdown right variant="light">
                                            <b-dropdown-item><router-link :to="'/ventas/index/detalle/' + doc.sale_id" >Ver detalle</router-link></b-dropdown-item>
                                            <b-dropdown-item v-if="doc.docname !='SALDO INICIAL'"><a @click="cancelInvoice(doc.sale_id)">Anular comprobante</a></b-dropdown-item>
                                        </b-dropdown>
                                    </b-button-group>
                                </div>
                            </b-td>
                        </b-tr>
                    </tbody>
                    
                </table>
            </b-col>

            <b-col cols="md-6">
                <div  v-if="tablePayFields.length>0" class="col-sm-12 table table-light row-border dataTable no-footer">
                    <label class="control-label mt-2">Busqueda por recibo:</label>
                    <input v-model="payFilter" class="form-control mb-2">
                </div>
                <table class="table table-light table-striped mb-5">
                    <thead>
                        <b-tr role="row">
                            <b-th><input type="checkbox" v-model="selectAllPays"></b-th>
                            <b-th>Fecha</b-th>
                            <b-th>Recibo</b-th>
                            <b-th>Valor</b-th>
                            <b-th>Saldo</b-th>
                            <th class="col-sm-1"></th>
                        </b-tr>
                    </thead>

                    <tbody>
                        <b-tr role="row" v-for="(rec, index) in tablePayFieldsFiltered" :key="index" v-bind:class="[{selected: rec.rowSelected},{'azul': rec.historical}]">
                            <b-td>
                                <input type="checkbox"
                                v-model="selectedPays"
                                v-bind:value="rec"
                                @click="selectRow($event, rec)">
                            </b-td>
                            <b-td>{{ rec.date }}</b-td>
                            <b-td>{{ rec.receipt }}</b-td>
                            <b-td>$ {{ parseFloat(rec.value).toFixed(2)}}</b-td>
                            <b-td>$ {{ parseFloat(rec.rest).toFixed(2)}}</b-td>
                            <b-td class="col-sm-1">
                                <b-button-group>
                                    <b-dropdown right variant="light">
                                        <b-dropdown-item :href="'/ventas/cobranzas/detalle-recibo/' + rec.id" >Ver detalle</b-dropdown-item>
                                        <b-dropdown-item><a @click="cancelPayment(rec.id)">Cancelar pago</a></b-dropdown-item>
                                    </b-dropdown>
                                </b-button-group>
                            </b-td>
                        </b-tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>

        <b-row v-if="activeTab==1">
            <receipts-table
            :client="payload.header.client_id"
            :fromDate="fromDate"
            :toDate="toDate"
            :watchTarget="tablePayFields"
            @cancelPay="cancelPayment"/>
        </b-row>

        <b-row v-if="activeTab==2">
            <allocations-table
            @allocationDeleted="refreshTables"
            :client="payload.header.client_id"
            :fromDate="fromDate"
            :toDate="toDate"/>
        </b-row>

        <receipt-payment-methods
        v-bind:header="payload.header"
        :fundDefault="config.fund_id"
        @paymentmethodschanged="paymentMethodsUpdated"
        @paymentmethodsclose="paymentMethodsClose"
        @close="closeModal"
        @comissionUpdated="comDataUpdate"
        v-bind:total="concilValue"
        v-bind:paymentMethods="payment_methods"
        v-if="showModal"
        :limited="false"
        :fromCC="true"/>

        <earnings-withholding
        v-if="showModalRet"
        :client_id="payload.header.client_id"
        :limited="false"
        :fromCC="true"
        :selectedDocs="selectedDocs"
        @updated="withholdingNew"
        @close="showModalRet = false"/>

    </b-container>
</template>

<script>

import receiptPaymentMethods from '../../paymentmethods/Receipt-payment-methods.vue';
import earningsWithholding from '../../paymentmethods/Earnings-withholding.vue';

import typeahead from '../../global/Typeahead.vue';
import datepickerComponent from '../../global/Datepicker-empty.vue';

import receiptsTable from './ReceiptList.vue';
import allocationsTable from './Allocations.vue';
import { mapState } from 'vuex'

export default {
  props: ['endpointurl'],
  data() {
    return {
      activeTab: 0,
      showModal: false,
      showModalRet: false,
      payload: {
        header: {
          client_id : null,
          posId: 0,
          pos: 0,
          service_fromdate:'',
          letter:''
        },
        paymentMethods: []
      },
      comissionData: {},
      fromDate: null,
      toDate:null,
      historical: null,
      posFilter:0,
      concilValue: 0.00,
      balanceValue:0.00,
      tableDocFields:[],
      tablePayFields:[],
      selectedDocs:[],
      selectedPays:[],
      docFilter:'',
      payFilter:'',
      printURL:'',
      payment_methods:{
        cuenta_corriente:0
      }
    }
  },
  created () {
    this.$store.dispatch('authUser/getAuthUser')
  },
  mounted() {
    this.payment_methods.cuenta_corriente=0;
    this.historical=false;
    this.testDocs=true;
  },
  methods: {
    SwitchTab() {
        this.activeTab = 1
    },
    fromDateUpdated: function (date) {
      this.fromDate = date;
      this.refreshTables();
    },
    toDateUpdated: function (date) {
      this.toDate = date;
      this.refreshTables();
    },
    paymentMethodsUpdated(obj) {
        this.payload.paymentMethods=obj;
    },
    paymentMethodsClose(obj) {
        this.payload.paymentMethods=obj;
        if ((obj.cash >0) || (obj.accounts.length>0) || (obj.creditcards.length>0) || (obj.tpchecks.length>0) || (obj.discount>0)){
            this.sendReceipt();
        }
    },
    withholdingNew(obj){
        this.$http.post('/api/clients/addEarningsWithholding', obj)
        .then((response) => {
            if (response.data.success) {
                alert("Se grabo correctamente la retencion de ganancias");
            } else {
                alert("Hubo un error grabando la retencion de ganancias");
            }
            }).catch((error) => {
            console.log('error: '+error);
        })
    },
    sendReceipt() {
      var vm= this;
      var outdata = {
          'header': vm.payload.header,
          'paymentMethods' :vm.payload.paymentMethods,
          'selectedDocs' : vm.selectedDocs,
          'selectedPay' : vm.selectedPays,
          'comissionData' : vm.comissionData
        };
      this.concilValue = 0.00;
      this.$http.post('/api/collections/addAllocationPayment', outdata)
          .then((response) => {
            
            if (response.data.success) {
                if (response.data.data.printInvoice == true) {
                    if (response.data.data.printReceiptTicket) {
                        window.location.href = '/cobranzas/ticket/'+response.data.data.ID;
                    }else {
                        window.open(response.data.data.urlInvoice, '_blank');
                    }
              } else {
                
              }
              this.updateBalanceValue();
              this.refreshTables();
            } else {
              
            }
          }).catch((error) => {
            console.log('error: '+error);
          })
    },
    selectRow(event, item, type, target) {
        item.rowSelected = !item.rowSelected;
    },
    clientUpdate(data) {
       
      this.payload.header.client_id=data.id;
      this.updateBalanceValue();
      this.refreshTables();
      this.selectedDocs=[];
      this.selectedPays=[];
    },
    clientEmpty(){
        this.payload.header.client_id=null;
        this.refreshTables();
        this.selectedDocs=[];
        this.selectedPays=[];
    },
    getclientList(){
        var vm = this;
        this.$http.get('/api/clients/list')
            .then(response => {
              if(response.data) {
                vm.clientList = response.data.data;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    refreshTables(){
        var vm= this;
        var outdata = {
            'client_id': vm.payload.header.client_id,
            'historical' : vm.historical,
            'posFilter':vm.posFilter,
            'fromDate':vm.fromDate,
            'toDate':vm.toDate
        };
        this.$http.post('/api/collections/getListInvoices', outdata)
            .then(response => {
              if(response.data) {
                vm.tableDocFields = response.data;
              }
            })
            .catch(error => {
              alert(error);
            })

        this.$http.post('/api/collections/getListReceipts', outdata)
            .then(response => {
              if(response.data) {
                vm.tablePayFields = response.data;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    updateConcilValue(){
        var vm=this;
        var outdata ={
            'client': vm.payload.header.client_id,
            'selectedDocs' : vm.selectedDocs,
            'selectedPay' : vm.selectedPays
        };

        this.$http.post('/api/collections/getConciliation', outdata)
            .then(response => {
              if(response.data.data) {
                vm.concilValue = parseFloat(response.data.data);
              }else{
                vm.concilValue = 0.00;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    updateBalanceValue(){
        var vm=this;
        this.$http.get('/api/clients/getBalance/' +  vm.payload.header.client_id)
            .then(response => {
              if(response.data.data) {
                vm.balanceValue = parseFloat(response.data.data);
              }else{
                vm.balanceValue = 0.00;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    checkAllocations(){
        if (this.selectedDocs.length<=0){
            alert("Necesita seleccionar comprobantes");
            return false;
        }

        if (confirm("Se realizará la imputación indicada")){
            if (this.concilValue>0){
                if(confirm("El pago no es suficiente para cubrir los comprobantes seleccionados ¿Desea ingresar un pago por el monto restante ahora?")){
                    this.showModal = true;
                }else{
                    this.allocate();
                }
            }else{
                this.allocate();
            }
        }
    },
    allocate(){
        var vm=this;
        var outdata ={
            'client': vm.payload.header.client_id,
            'selectedDocs' : vm.selectedDocs,
            'selectedPay' : vm.selectedPays
        };

        this.$http.post('/api/collections/addAllocation', outdata)
            .then(response => {
              if(response.data.success) {
                  this.refreshTables();
              }else{
                  
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    ifModalPayment(){
        if (this.concilValue>=0){ this.showModal = true;
        }else{ alert("No se pueden crear pagos con valor negativo");}
    },
    cancelPayment(data){
        if (confirm("¿Está seguro que desea eliminar este comprobante? Se desarmarán también todas las imputaciones en las que se encuentre asociado")){
            this.$http.get('/api/collections/cancelPayment/'+data)
            .then(response => {
                if(response.data.success) {
                  this.refreshTables();
                }else{
                  alert(response.message);
                }
            })
            .catch(error => {
              alert(error.message);
            })
        }
    },
    cancelInvoice(id){
        var outdata={'id':id};
        if (confirm("¿Está seguro que desea anular este comprobante? No aparecerá en el listado de cobranzas pero podrá verse en la vista de ventas")){
            this.$http.post('/api/sales/cancel/'+id)
            .then(response => {
                if(response.data.success) {
                  this.refreshTables();
                }else{
                  alert(response.message);
                }
            })
            .catch(error => {
              alert(error.message);
            })
        }
    },
    comDataUpdate(obj){
        this.comissionData=obj;
    },
    closeModal() {
      this.showModal = false;
      //window.location.href = window.location.pathname;
    },
  },
  computed: {
    ...mapState('authUser',{
        authUser: state => state.authUser,
    }),
    
    config: {
            get: function () { return this.authUser.branchoffices[0].configs;}
        },
   
    selectAllDocs: {
        get: function () {
            if((this.selectedDocs.length == this.tableDocFields.length) && (this.tableDocFields.length!=0)){
                return true;
            }else{
                return false;
            }
        },
        set: function (value) {
            var selected = [];

            if (value) {
                for (var doc in this.tableDocFields) {
                    this.tableDocFields[doc].rowSelected =true;
                    this.selectedDocs.push(this.tableDocFields[doc]);
                }
            }else{
                for (var doc in this.tableDocFields) {
                    this.tableDocFields[doc].rowSelected =false;
                }
                this.selectedDocs = selected;
            }
        }
    },
    selectAllPays: {
        get: function () {
            if ((this.selectedPays.length == this.tablePayFields.length) && (this.tablePayFields.length!=0)){
                return true;
            }else{
                return false;
            }
        },
        set: function (value) {
            var selected = [];

            if (value) {
                for (var pay in this.tablePayFields) {
                    this.tablePayFields[pay].rowSelected =true;
                    this.selectedPays.push(this.tablePayFields[pay]);
                }
            }else{
                for (var pay in this.tablePayFields) {
                    this.tablePayFields[pay].rowSelected =false;
                }
                this.selectedPays=selected;
            }
        }
    },
    concilText: {
        get: function () {
            return '$ ' + this.concilValue.toFixed(2).replace(".",",").replace(/\d(?=(\d{3})+\,)/g, '$&.');
        }
    },
    balanceText: {
        get: function () {
            return '$ ' + this.balanceValue.toFixed(2).replace(".",",").replace(/\d(?=(\d{3})+\,)/g, '$&.');
        }
    },
    tableDocFieldsFiltered:{
        get: function () {
            let vm = this;
            return this.tableDocFields.filter(e => e.docname.includes(vm.docFilter));
        }
    },
    tablePayFieldsFiltered:{
        get: function () {
            let vm = this;
            return this.tablePayFields.filter(e => e.receipt.includes(vm.payFilter));
        }
    }
},
  watch: {
    authUser: {
          handler: function (a) {
            this.payment_methods = { ...this.authUser.branchoffices[0].payments_methods}
            this.payment_methods.cuenta_corriente = 0;
        },
      },
      'selectedDocs': {
          handler: function (a) {this.updateConcilValue();}
      },
      'selectedPays': {
          handler: function (a) {this.updateConcilValue();}
      },
      'historical': {
          handler: function (a) {this.refreshTables();}
      },
      'posFilter': {
          handler: function (a) {this.refreshTables();}
      },
  },
  components: {
    receiptPaymentMethods,
    earningsWithholding,
    typeahead,
    datepickerComponent,
    receiptsTable,
    allocationsTable
  },
}
</script>