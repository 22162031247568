<template>
    <b-container fluid>
        <div class="loading" v-if="loading">Loading&#8230;</div>
        <!-- ------- DATOS DEL CLIENTE -------- -->
        <b-row class="mt-5">
            <b-col cols="sm-3">
            <b-form-group>
                <label class="control-label" v-if="client !== null">Cliente: {{ client.name }}</label>
            </b-form-group>
            </b-col>
            <b-col cols="sm-3">
            <b-form-group>
                <label class="control-label" v-if="client !== null">Nombre de Fantasia: {{ client.business }}</label>
            </b-form-group>
            </b-col>
            <b-col cols="sm-3">
            <b-form-group>
                <label class="control-label" v-if="client !== null">Direccion: {{ client.address }}</label>
            </b-form-group>
            </b-col>
            <b-col cols="sm-3">
            <b-form-group>
                <label class="control-label" v-if="client !== null">Telefono: {{ client.phone_1 }}</label>
            </b-form-group>
            </b-col>
        </b-row>  
        <b-row>
            <!-- CABECERA TOTALES -->
            <b-col>
                <div v-if="balanceFull" class="stats">
                    <b-row>
                        <b-col cols="lg-12 text-center">
                        <div class="stat">
                            <div class="number"> {{formatoDinamicoMoneda(balanceTotal)}}</div>
                            <div class="desc"> Saldo actual de cuenta corriente</div>
                              <!-- BOTON ALTERNAR INFO -->
                            <b-col cols="12 mt-2 mb-4">
                                <b-button variant="orange" @click="balanceFull= !balanceFull">Alternar Info</b-button>
                            </b-col>
                        </div>
                    </b-col>
                    </b-row>
                </div>
                <div v-else class="stats">
                    <b-row>
                        <b-col v-for="(balance,index) in balanceList" :key="index" cols="lg-6 text-center md-6 sm-12 xs-12">
                            <div class="stat">
                                <div class="number"> {{formatoDinamicoMoneda(balanceList[index])}}</div>
                                <div class="desc"> Total generado desde punto de venta {{index}} </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <!-- FILTROS -->
        <b-row class="mb-2 d-flex justify-content-between">
            <!-- BTN EXPORTAR PDF -->
            <b-col cols="md-3" class="text-left">
                <!--<input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary" value="Exportar CSV">-->
                <input type="button" @click="exportPDF()" class="btn btn-md btn-outline-primary" value="Exportar PDF">
            </b-col>
            <!-- FILTRO SALDADOS -->
            <b-form inline cols="md-3">
                <select class="form-control btn-light" v-model="filters.type">
                    <option selected="selected" :value="0">Todos los movimientos</option>
                    <option :value="1">Movimientos a saldar</option>
                    <option :value="2">Movimientos saldados</option>
                </select>
            </b-form>
            <!-- FILTRO FECHA -->
            <b-form inline cols="md-3">
                <Daterange :opendir="'left'" :filtered="true" @datepicked="dateUpdated"/>
            </b-form>
            <!-- MODAL NUEVO PASE A CUENTA -->
            <b-col cols="md-3" class="text-right">
                <b-button v-b-modal.modal-1 variant="success">Nuevo pase a cuenta</b-button>
                    <b-modal id="modal-1" title="Nuevo pase a cuenta" ok-only ok-variant="secondary" ok-title="Cancel">
                        <b-col cols="md-12">        
                            <NuevoPaseCuentaCliente :clientId="clientId" @confirmsave="refresh()" @clean_id="clientId = null"/>
                        </b-col>
                    </b-modal>     
            </b-col>
        </b-row>
        <!-- TABLA -->



        <b-row>
            <b-col cols="md-12">
                <b-table-simple striped small caption-top responsive>
                    <b-thead>
                        <b-tr role="row">
                            <b-th style="text-align:center;">Fecha</b-th>
                            <b-th style="text-align:center;">Vencimiento</b-th>
                            <b-th style="text-align:center;">Comprobante</b-th>
                            <b-th style="text-align:center;">Concepto</b-th>
                            <b-th style="text-align:center;">Debe</b-th>
                            <b-th style="text-align:center;">Haber</b-th>
                            <b-th style="text-align:center;">Saldo</b-th>
                            <th></th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="cc in accounts" :key="cc.id" role="row" class="odd">
                            <b-td>{{cc.created_at}}</b-td>
                            <b-td v-if="cc.duedate !== null">{{formatoDate2Fecha(cc.duedate)}}</b-td>
                            <b-td v-else>{{ cc.duedate }}</b-td>
                            <b-td>
                                <a v-if="cc.sale_id !== null" :href="'/ventas/index/detalle/'+cc.sale_id">{{cc.number}}</a>
                                <a v-if="cc.receipt_id !== null" :href="'/ventas/cobranzas/detalle-recibo/'+cc.id">{{cc.number}}</a>
                                <p v-if="cc.receipt_id == null && cc.sale_id == null && cc.withholding_id == null">Pase manual</p>
                            </b-td>
                            <b-td>{{cc.notes}}</b-td>
                            <b-td>{{formatoDinamicoMoneda(cc.in)}}</b-td>
                            <b-td>{{formatoDinamicoMoneda(cc.out)}}</b-td>
                            <b-td>{{formatoDinamicoMoneda(cc.saldo)}}</b-td>
                            <b-td>
                                <button title="Anular comprobante" type="button" class="btn btn-light" v-if="cc.sale_id !== null" v-on:click="cancelSale(cc.sale_id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
                                    </svg>
                                </button>
                                <button title="Descargar PDF" type="button" class="btn btn-light marginButton" v-if="cc.receipt_id !== null" href="javascript:void(0);" @click="getReceiptPDF(cc)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">'+
                                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>'+
                                    </svg>
                                </button>
                                <button title="Cancelar pago" type="button" class="btn btn-light marginButton" v-if="cc.receipt_id !== null" v-on:click="cancelPayment(cc.id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
                                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
                                    </svg>
                                </button>                 
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <!-- PAGINACION -->
        <div class="row">
            <div class="col">
                <nav class="text-center" aria-label="Page navigation example">
                    <label class="mr-2">Mostrar: </label>
                    <b-form-select v-model="filters.itemsPerPage" style="width: 55px; margin-right: 5px;">
                        <option :value="15">15</option>
                        <option :value="25">25</option>
                        <option :value="50">50</option>
                    </b-form-select>
                    <ul class="pagination justify-content-end">
                        <li class="page-item" v-bind:class="[{'disabled': filters.page === 1}]">
                            <a class="page-link" href="#" v-on:click="prevPage">Anterior</a>
                        </li>
                        <li v-if="(filters.page - (pageInterval / 2)) > 0" class="page-item">
                            <a class="page-link" href="#" v-on:click="prevPage">...</a>
                        </li>
                        <template v-if="totalPages > 1"> <!-- Asegúrate de verificar totalPages aquí -->
                            <template v-for="index in totalPages">
                            <li :key="index" class="page-item" v-bind:class="[{'active': filters.page === index}]">
                                <a class="page-link" href="#" v-on:click="changePage(index)">{{index}}</a>
                            </li>
                            </template>
                        </template>
                        <li v-if="totalPages > (filters.page + (pageInterval / 2))" class="page-item">
                            <a class="page-link" href="#" v-on:click="nextPage">...</a>
                        </li>
                        <li class="page-item" v-bind:class="[{'disabled': filters.page >= totalPages}]">
                            <a class="page-link" href="#" v-on:click="nextPage">Siguiente</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <b-row>
            <b-col cols:md-12>
                <b-button class="my-2" variant="orange" size="lg" @click="ccClientesLink">Volver</b-button>
            </b-col>
        </b-row>
    </b-container>

</template>

<script>
import Daterange from '../../global/Daterange-component.vue';
import NuevoPaseCuentaCliente from './Nuevo-pase-cuenta-cliente.vue';
import exportsMixin from "../../../mixins/exportsMixin";
import formatDateMixin from "../../../mixins/formatDateMixin";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";
import myaxios from 'axios';

export default {
  props: ['clientId'],
  components: {
        Daterange,
        NuevoPaseCuentaCliente
    },
    mixins: [exportsMixin,formatDateMixin, formatMoneyMixin],

  data:function(){
    return {
        loading: false,
        client: {
            
        },
        accounts:[],
        balanceFull:true,
        balanceList:[],
        balanceTotal:0,
        totalPages:1,
        pageInterval:22,
        filters:{
            type:0,
            startDate:null,
            endDate:null,
            page:1,
            itemsPerPage:15,
        },
    }
  },
  mounted() {
    this.setDefaultDateRange();
    this.getClient(this.clientId);
    this.getBalance();
    this.getCC();
  },
  methods: {
    refresh(){
        this.getBalance();
        this.getCC();
    },
    getClient(clientId){
        var vm= this;
        this.$http.get('/api/clients/getClient/' + clientId)
          .then(response => {
                vm.client = response.data.data;          
        })
        .catch(error => {
            alert(error);
        })
    },
    getBalance(){
        var vm= this;
        this.$http.get('/api/clients/getBalances/' + this.clientId)
          .then(response => {
                vm.balanceTotal = response.data.data.total;
                vm.balanceList = response.data.data.list;
        })
        .catch(error => {
            alert(error);
        })
    },
    getCC(){
        var vm= this;
        this.$http.post('/api/clients/getAccount/' + this.clientId, this.filters)
          .then(response => {
            if(response.status == 200) {
                vm.accounts = response.data.data.accounts;
                vm.totalPages=response.data.data.totalPages;
           }else{
                vm.accounts=[];
                vm.totalPages=0;
            }
        })
        .catch(error => {
            alert(error);
        })
    },
    prevPage(){
        if(this.filters.page>1){
            this.filters.page = this.filters.page-1;
            this.getCC();
        }
    },
    nextPage(){
        if (this.filters.page<this.totalPages){
            this.filters.page = this.filters.page +1;
            this.getCC();
        }
    },
    changePage(data){
        this.filters.page = data;
        this.getCC();
    },
    inIntervalPage(index){
        if(this.totalPages<=this.pageInterval){return true;}

        let limitprev = this.filters.page - (this.pageInterval/2);
        let limitnext = this.filters.page + (this.pageInterval/2);

        if(limitprev<0){
            limitprev=0;
            limitnext=this.pageInterval;
        }

        if(index>limitprev && index<limitnext){
            return true;
        }

        return false;
    },
    cancelSale(id){
        var vm=this;
        if (confirm("¿Está seguro que desea anular este comprobante? Los comprobantes anulados pueden verse en la vista de ventas") == true){
          const outdata={'id' : id,};
          this.$http.post('/api/sales/cancel', outdata)
          .then(response => {
            console.log(response);
              if(response.data.success){
                  vm.getCC();
                  vm.getBalance();
              }else{
                  
              }
          })
          .catch(error => {
            alert(error);
          })
        }
    },
    cancelPayment(id){
        var vm=this;
        if (confirm("¿Está seguro que desea eliminar este pago? Se desarmarán también todas las imputaciones en las que se encuentre asociado") == true){
            this.$http.get('/api/collections/cancelPayment/'+ id)
            .then(response => {
                console.log(response);
                if(response.data.success){
                    vm.getCC();
                    vm.getBalance();
                }else{
                    
                }
            })
            .catch(error => {
                alert(error);
            })
        }
    },
    dateUpdated: function (date) {
        this.filters.startDate = date.startDate;
        this.filters.endDate = date.endDate;
        this.getCC();
    },
    getReceiptPDF(data){
        let id = data.id;
        let vm = this;
        let filename = data.number +' '+ Date.now()+'.pdf';
        axios.get("/api/collections/checkReceiptPDF/" + id)
            .then((res) => {
                const uninterceptedAxiosInstance = myaxios.create();
                uninterceptedAxiosInstance.get(res.data.data.urlInvoice, { responseType: 'blob' })
                .then((response) => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = filename
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
        })
    },
    exportPDF() {
        this.$http.post("/api/reports/PDFdetailccc/" + this.clientId, this.filters)
        .then((response) => {
        });
    },
    ccClientesLink() {
        this.$router.push({name: 'Cuenta corriente de clientes'})
    },
    setDefaultDateRange() {
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - 30);

        this.filters.startDate = this.formatDate(startDate);
        this.filters.endDate = this.formatDate(today);
    },
    formatDate(date) {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${day}/${month}/${year}`;
    },
  },
  watch: {
      'filters.type': {handler: function (a) {this.getCC();}},
      'filters.itemsPerPage': {handler: function (a) {this.getCC();}},
      'filters.page': {handler: function (a) {this.getCC();}},
    },
}
</script>
