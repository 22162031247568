<template>
  <b-container fluid>
    <div class="row pt-3">
      <h2>
          <label for="">Detalle de recibo</label>
      </h2>
    </div>

    <div v-if="isReceipt">
      <b-card class="bg-light d-flex justify-content-between mb-4 p-0">
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
                <h2 class="control-label">PV: {{pos}} Recibo nro: {{number}}</h2>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <h2 class="control-label">Cliente: {{clientName}}</h2>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
                <h2 class="control-label">Fecha: {{formatoDate2Fecha(created_at)}}</h2>
            </div>
          </div>
        </div>
      </b-card>

      <div class="row">
        <!--TABLA-->
        <div class="col-8">
          <label>Forma de pago:</label>
          <b-table hover :items="receiptDetails" :fields="receiptDetailsFields"></b-table>
          
          <div v-if="invoices.length>0">
            <br>
            <label>Comprobantes asociados al generar el pago:</label>
            <b-table hover :items="invoices" :fields="invoicesFields">
              <template #cell(id)="data">
                <a :href="`/ventas/index/detalle/${data.value}`" class="btn btn-sm btn-light">Ver detalle</a>
              </template>
            </b-table>
          </div>
          <div v-if="payments.length>0">
            <br>
            <label>Otros pagos asociados al generar este pago:</label>
            <b-table hover :items="payments" :fields="paymentsFields">
              <template #cell(cc)="data">
                <a :href="`/ventas/cobranzas/detalle-recibo/${data.value}`" class="btn btn-sm btn-light">Ver detalle</a>
              </template>
            </b-table>
          </div>
        </div>
        <!--TOTALES-->
        <div class="col-4">
          <label>Detalles de pago:</label>
          <b-card bg-variant="light">
            <b-card-header>
              <h3>Total del recibo</h3>
            </b-card-header>
            <b-card-body>
              <b-form-group>
                
                  <b-row>
                    <b-col class="col-xs-7 text-left">
                        PAGO EN EFECTIVO:
                    </b-col>
                    <b-col class="col-xs-5 text-right">
                      $ <span id="htmlTotal">{{getDetailTotal('EFV')}}</span>
                    </b-col>  
                  </b-row>
                
                  <b-row>
                    <b-col class="col-xs-7 text-left">
                    PAGO CON TARJETAS:
                    </b-col>
                    <b-col class="col-xs-5 text-right">
                      $ <span id="htmlTotal">{{getDetailTotal('CUP')}}</span>
                    </b-col>
                  </b-row>
                  
                  <b-row>
                    <b-col class="col-xs-7 text-left">
                      PAGO CON CHEQUES:
                    </b-col>
                    <b-col class="col-xs-5 text-right">
                      $ <span id="htmlTotal">{{getDetailTotal('CHQ')}}</span>
                    </b-col>
                  </b-row>
                  
                  <b-row>
                    <b-col class="col-xs-7 text-left">
                      TOTAL:
                    </b-col>
                    <b-col class="col-xs-5 text-right">
                      $ <span id="htmlTotal">{{receipt.total}}</span>
                    </b-col>
                  </b-row>
              </b-form-group>
            </b-card-body>
          </b-card>
          <!--NOTA CLIENTE-->
          <div class="form-group col-border mt-4">
            <h6>
              <label for="">Nota para el cliente</label>
            </h6>
            <textarea class="form-control" rows="4">{{receipt.notes}}</textarea>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="receipt-footer">
        <a href="javascript:void(0);" @click="getPDF(receiptId)" class="paymentOptions btn btn-lg btn-orange" style="z-index:100;">Reimprimir recibo</a>
      </div>
    </div>

    <div v-if="isWithHolding">
      <WithHoldingDetail :withholding="withholding" :invoices="invoices"></WithHoldingDetail>
    </div>
  </b-container>

</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import WithHoldingDetail from './WithHolding-detail.vue';
import myaxios from 'axios';

export default {
  props: ['receiptId'],
  mixins: [formatDateMixin],
  components: {
    WithHoldingDetail
  },
  data() {
    return {
      showModal: false,
      showModalRet: false,
      withholding:'',
      receipt:'',
      receiptDetails:[],
      invoices:[],
      payments:[],
      receiptDetailsFields: [
          {
            key: 'type',
            label: 'Tipo',
            sortable: true
          },
          {
            key: 'detail',
            label: 'Detalle',
            sortable: false
          },
          {
            key: 'amount',
            label: 'Valor',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          }
      ],
      invoicesFields: [
          {
            key: 'type',
            label: 'Tipo',
            sortable: true
          },
          {
            key: 'number',
            label: 'Numero',
            sortable: false
          },
          {
            key: 'total',
            label: 'Importe',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          },
          {
            key: 'partial',
            label: 'En Recibo',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          }
          ,
          {
            key: 'id',
            label: 'Ver Detalle',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          }
      ],
      paymentsFields: [
          {
            key: 'type',
            label: 'Tipo',
            sortable: true
          },
          {
            key: 'number',
            label: 'Numero',
            sortable: false
          },
          {
            key: 'amount',
            label: 'Importe',
            sortable: true,
            variant: 'danger'
          },
          {
            key: 'partial',
            label: 'En Recibo',
            sortable: true,
            variant: 'danger'
          }
          ,
          {
            key: 'cc',
            label: 'Ver Detalle',
            sortable: true,
            variant: 'danger'
          }
      ],
    }
  },
  computed:{
    pos() {
      return this.receipt.pos
    },
    number() { return this.receipt.number },
    clientName() { return this.receipt.client_name },
    created_at() { return this.receipt.created_at },
    isReceipt() { return this.receipt!=''},
    isWithHolding() { return this.withholding!=''}
  },
  methods: {
    getDetailTotal(type) { 
      return this.receiptDetails.filter(d => d.type == type)
        .reduce((accumulator, object) => { return accumulator + object.amount; }, 0);
    },
    getReceiptDetail() {
      var vm = this;
      this.$http.get('/api/collections/getReceiptDetail/' + this.receiptId)
            .then(response => {
              if(response.data.success) {
                if(response.data.data.receipt != undefined)
                {
                  vm.receipt = response.data.data.receipt;
                  vm.receiptDetails = response.data.data.receiptDetails;
                  vm.payments = response.data.data.payments;
                }
                else{
                  vm.withholding = response.data.data.withholding;
                }
                vm.invoices = response.data.data.invoices;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    getPDF(id){
      this.$http.get('/api/collections/reprintReceipt/' + id)
        .then(res => {
          const uninterceptedAxiosInstance = myaxios.create();
          uninterceptedAxiosInstance.get(res.data.data.urlInvoice, { responseType: 'blob' })
          .then((response) => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const link = document.createElement('a')
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString().replace(/\//g, '-');
            link.href = URL.createObjectURL(blob)
            link.download = 'Recibo_'+this.receipt.number+'_'+this.receipt.client_name+'_' + formattedDate + '.pdf';
            link.click()
            URL.revokeObjectURL(link.href)
          })
        })
    }

  },
  mounted() {
    this.getReceiptDetail();
  }
}
</script>
<style>

.btn-orange {
    background: #f5c05d;
    background: -ms-linear-gradient(top, #f5c05d 0%, #F2AB27 100%);
    background: linear, to bottom, #f5c05d 0%, #F2AB27 100%;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='#ffffff',GradientType=0 );
    color: #fff;
    text-shadow: 0px 0px 2px #e4931b;
    border: 1px solid #F2AB27;
    border-bottom: 1px solid #e4931b;
}
</style>
