<template>
  <div>
    <subnavbar-funds></subnavbar-funds>
    <router-view></router-view>
  </div>
</template>

<script>
import SubnavbarFunds from '../navbars/Subnavbar-funds.vue'
export default {
  components: {
    SubnavbarFunds
  }
};
</script>