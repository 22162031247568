<template>
  <div>
    <subnavbar-configs></subnavbar-configs>
    <router-view></router-view>
  </div>
</template>

<script>
import SubnavbarConfigs from '../navbars/Subnavbar-configs.vue'
export default {
  components: {
    SubnavbarConfigs
  }
};
</script>