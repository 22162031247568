<template>
  <div>
    <subnavbar-compras></subnavbar-compras>
    <router-view></router-view>
  </div>
</template>

<script>
import SubnavbarCompras from '../navbars/Subnavbar-compras.vue'
export default {
  components: {
    SubnavbarCompras
  }
};
</script>