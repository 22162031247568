<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Notas de pedido</h4>
      </b-col>
      <b-col cols="md-2 text-right">
        <router-link :to="nuevaNotaDePedidoLink">
          <b-button variant="orange" size="lg">Nueva Nota de Pedido</b-button>
        </router-link>
      </b-col>
    </div>
    <!-- -------------------- BUSCADOR -------------------------- -->
    <b-row class="d-flex justify-content-between"
      @submit.stop.prevent="doSearch">
      <b-col cols="md-2 text-left">
        <div class="input-group">
          <input
            v-model="doSearch"
            type="search"
            placeholder="Buscar..."
            class="form-control">
        </div>
      </b-col>
      <!-- --------------------------FILTROS --------------------  -->
      <b-col cols="md-2 text-left mt-3">
        <div class="form-check form-check-inline">
          <input class="form-check-input" v-model="oninvoiced" id="oninvoiced" type="checkbox">
          <label class="form-check-label" for="oninvoiced">&nbsp;Mostrar notas ya facturadas.</label>
        </div>
      </b-col>
    </b-row>
    <!-- ----------------------- TABLA -------------------------- -->
    <b-row>
      <vdtnet-table
        class="col-md-12"
        :fields="fields"
        :opts="opts"
        :selectCheckbox="0"
        @orderNoteDetail="getOrderNoteDetail"            
        @convertOrderNoteToSale="convertOrderNoteToSaleMethod"
        @getOrderNotePDF="downloadOrderNotePDF"
        @delete="deleteOrderNote"
        @setPendiente="updateOrderNoteStatusPendiente"            
        @setPreparando="updateOrderNoteStatusPreparando"            
        @setTerminada="updateOrderNoteStatusTerminada"            
        @setAnulada="updateOrderNoteStatusAnulada"            
        ref="table"
      />        
    </b-row>
  </b-container>
</template>

<script>
import NuevaButton from '../../sub-components/Nueva-button.vue'
import VdtnetTable from 'vue-datatables-net';
import { mapState } from 'vuex'
import formatDateMixin from "../../../mixins/formatDateMixin";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";
import myaxios from 'axios';


export default {
    components: {
      VdtnetTable,
      NuevaButton
    },
    mixins: [formatDateMixin,formatMoneyMixin],
    data: function() {
      return {
        doSearch: '',
        oninvoiced: false,
        opts: {
          language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
          ajax: {
            url: process.env.VUE_APP_BASE_URL+"/api/ordernotes/list",
            type: "GET",
            data: (d) => {
              d.oninvoiced = this.oninvoiced,
              d.branchoffice_id = this.branchofficeId
            },
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        },
        fields: {
          id: { visible: false },
          number: { label: "N°"},
          created_at: { label: "Fecha", render: function(data) {
            return new Date(data).toLocaleDateString('es-AR')
          } },
          client: { label: "Cliente"},
          user_id: { label: "Vendedor"},
          branchoffice: { label: "Sucursal" },
          total: { label: "Total"},
          status: { label: "Estado", render: function(data) {
            if(data === "PENDING") {
              return "PENDIENTE"
            }
            if(data === "MAKING") {
              return "PREPARANDO"
            }
            if(data === "READY") {
              return "TERMINADA"
            }
            if(data === "CANCELED") {
              return "ANULADA"
            }
          } },
          statu: { label: ' ', 
                    isLocal: true,
                    align: 'center',
                    defaultContent: `
                                  <div class="dropdown">
                                    <select type="button" class="btn btn-light dropdown-toggle" data-toggle="dropdown" >
                                      <option class="dropdown-menu">MODIFICAR</option>
                                      <option data-action="setPendiente" title="PENDIENTE" type="button" class="btn btn-sm">PENDIENTE</option>
                                      <option data-action="setPreparando" title="PREPARANDO" type="button" class="btn btn-sm">PREPARANDO</option>
                                      <option data-action="setTerminada" title="TERMINADA" type="button" class="btn btn-sm">TERMINADA</option>
                                      <option data-action="setAnulada" title="ANULADA" type="button" class="btn btn-sm">ANULADA</option>
                                  </select>
                                </div>
` },
          stocked: { label: "Stock" },
          actions: {
                isLocal: true,
                label: 'Acciones',
                width: '85px',
                align: 'center',
                defaultContent: '<div class="divActions btn-group-sm">' +
                                  '<button data-action="orderNoteDetail" data-toggle="tooltip" title="Ver detalle" type="button" class="btn btn-light"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-ruled" viewBox="0 0 16 16">'+
                                  '<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z"/>'+
                                  '</svg></button>' +
                                  '<button data-action="getOrderNotePDF" data-toggle="tooltip" title="Descargar PDF" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">'+
                                  '<path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>'+
                                  '</svg></button>' +
                                  '<button data-action="convertOrderNoteToSale" data-toggle="tooltip" title="Convertir en venta" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-basket" viewBox="0 0 16 16">'+
                                  '<path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z"/>'+
                                  '</svg></button>' +
                                  '<button data-action="delete" data-toggle="tooltip" title="Eliminar comprobante" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
                                  '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
                                  '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
                                  '</svg></button>' +
                                '</div>'
          },
        },
        orderNote: {
          id: null,
          number: "",
          total: null
        },
        emptyOrderNote: {
          id: null,
          number: "",
          total: null
        },      
        orderNotes: [],
      };
    },
    computed: {
      nuevaNotaDePedidoLink() {
        return { name: 'Nueva nota de pedido' }
      },
      ...mapState('branchoffices',{
        branchofficeId: state => state.selectedBranchofficeId,
      }),
    },
    watch: {
      oninvoiced() {
        this.$refs.table.reload();
      },
      doSearch(newValue, oldValue) {
        if(newValue.length >= 4 || oldValue.length >= 4) {
          this.$refs.table.search(this.doSearch)
        }
        if(newValue === '' && oldValue.length > 0) {
          this.$refs.table.search(this.doSearch)
        }
      },
      branchofficeId (newValue, oldValue) {
        this.refreshTable();
      },
    },
    methods: {
      refreshTable() {
        this.$refs.table.reload();
      },     
      getOrderNoteDetail(data) {
        this.$router.push({name: 'Detalle de nota de pedido', params: { orderNoteId: data.id }})
      },      
      convertOrderNoteToSaleMethod(data) {
        this.$router.push({name: 'Nueva venta desde ON', params: { orderNoteId: data.id }})
      },
      downloadOrderNotePDF(data){
        let id = data.id;
        let vm = this;        
        vm.loading = true
        let filename = data.client+data.updated_at+'.pdf';
        axios.get("/api/ordernotes/onPDF/"+id)
        .then((response) => {
            const uninterceptedAxiosInstance = myaxios.create();
            uninterceptedAxiosInstance.get(response.data, { responseType: 'blob' })
            .then((response) => {
              const blob = new Blob([response.data], {type: 'application/pdf'});
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = filename
              link.click()
              URL.revokeObjectURL(link.href)
            })
        })
      },
      updateOrderNoteStatusPendiente(data) {
        if (data.status === "CANCELED") {
          return alert('El estado actual no puede modificarse')
        }      
        if (confirm("¿Está seguro que desea modificar el estado de esta nota de pedido?") == true) {
          this.$http
            .post("/api/ordernotes/update", { id: data.id, status: "PENDING" })
            .then((response) => {
              
              if (response.data.code == 201) {
                this.$refs.table.reload();
              }
            }
          );
        }
      },      
      updateOrderNoteStatusPreparando(data) {
        if (data.status === "CANCELED") {
          return alert('El estado actual no puede modificarse')
        }      
        if (confirm("¿Está seguro que desea modificar el estado de esta nota de pedido?") == true) {
          this.$http
            .post("/api/ordernotes/update", { id: data.id, status: "MAKING" })
            .then((response) => {
              
              if (response.data.code == 200) {
                this.$refs.table.reload();
              }
            }
          );
        }
      },      
      updateOrderNoteStatusTerminada(data) {
        if (data.status === "CANCELED") {
          return alert('El estado actual no puede modificarse')
        }
        if (confirm("¿Está seguro que desea modificar el estado de esta nota de pedido?") == true) {
          this.$http
            .post("/api/ordernotes/update", { id: data.id, status: "READY" })
            .then((response) => {
              
              if (response.data.code == 201) {
                this.$refs.table.reload();
              }
            }
          );
        }
      },      
      updateOrderNoteStatusAnulada(data) {
        if (data.status === "CANCELED") {
          return alert('El estado actual no puede modificarse')
        }      
        if (confirm("Esta acción no puede deshacerse: ¿Está seguro que desea anular esta nota de pedido?") == true) {
          this.$http
            .post("/api/ordernotes/update", { id: data.id, status: "CANCELED" })
            .then((response) => {
              
              if (response.data.code == 200) {
                this.$refs.table.reload();
              }
            }
          );
        }
      },      
      deleteOrderNote(data) {
        if (confirm("¿Está seguro que desea borrar esta nota de pedido?") == true) {
          this.$http
            .post("/api/ordernotes/delete", { id: data.id })
            .then((response) => {
              
              if (response.data.code == 201) {
                this.$refs.table.reload();
              }
            }
          );
        }
      },      
    },
  }  
</script>
