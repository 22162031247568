<template>
<html lang="en">
  <head>
    <meta charset="utf-8"/>
    <title> Qupro </title>
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta content="Qupro" name="author"/>
  </head>
  <div id="app">
    <main-navbar v-if="rendernav"/>
    <router-view/>
  </div>
</html>
</template>

<script>
import MainNavbar from "./components/navbars/MainNavbar.vue";
var Pusher = require('pusher-js');
import { mapActions, mapGetters, mapState } from 'vuex'

  export default {
    components: {
      MainNavbar
    },
    methods: {
      ...mapActions({
        getUser: 'user/getUser',
        getCategories: 'categories/getCategories',
        getDownloads: 'downloads/getDownloads',
        emitSuccessAlert: 'downloads/emitSuccessAlert',
        getBrands: 'brands/getBrands',
        getFundsCategories: 'funds-categories/getFundsCategories',
        getTransports: 'transports/getTransports',
        getSalesclerks: 'salesclerks/getSalesclerks',
        getWarehouses: 'warehouse/getWarehouses',
      }),
    },
    watch: {
      showUser: {
        handler: function (newItem) {
          Pusher.logToConsole = (process.env.NODE_ENV !== 'production');

          var pusher = new Pusher(process.env.VUE_APP_PUSHER_APPKEY, {
            cluster: 'sa1'
          });

          var channel = pusher.subscribe('refresh.' + newItem.id);

          channel.bind('createdCategories', this.getCategories);
          channel.bind('updatedCategories', this.getCategories);

          channel.bind('createdBrands', this.getBrands);
          channel.bind('updatedBrands', this.getBrands);

          channel.bind('createdFundsCategories', this.getFundsCategories);
          channel.bind('updatedFundsCategories', this.getFundsCategories);

          channel.bind('createdTransports', this.getTransports);
          channel.bind('createdTransports', this.getTransports);

          channel.bind('createdSalesclerks', this.getSalesclerks);
          channel.bind('updatedSalesclerks', this.getSalesclerks);

          channel.bind('createdWarehouses', this.getWarehouses);
          channel.bind('updetedWarehouses', this.getWarehouses);

          channel.bind('createdDownload', () => {
            this.getDownloads();
            this.emitSuccessAlert();
          });
        },
        deep:true
      }
    },
    created() {
      this.getUser();
    },
    mounted() {
      this.$store.dispatch('downloads/getDownloads');
    },
    computed: {
      ...mapGetters({
        showUser: "user/showUser"
      }),
      ...mapState('downloads',{
          downloads: state => state.downloads,
      }),
      rendernav() {
        return !(localStorage.getItem("token")===null)
      }
    },
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #424ab9;
}
</style>
