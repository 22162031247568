<template>
  <b-container fluid>
  <div class="loading" v-if="isLoading">Loading&#8230;</div>
  <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Depósitos</h4>
      </b-col>
    </div>
    <!-- ----------------------- BUSCADOR -------------------------- -->
    <b-row>
      <b-col cols="12 col-md-3">
        <b-form inline class="d-flex justify-content-between my-2"
          @submit.stop.prevent="doSearch">

          <div class="input-group">
            <input
              v-model="doSearch"
              type="search"
              placeholder="Buscar..."
              class="form-control"
            >
          </div>
        </b-form>
      </b-col>
    </b-row>
    <!-- ----------------------- TABLAS -------------------------- -->
    <b-row>
      <b-col cols="md-8">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          @edit="getWarehouse"
          @delete="deleteWarehouse"
          ref="table"
        />
      </b-col>
      <!-- ----------------------- FORMULARIO -------------------------- -->
      <b-col>
        <div class="card mb-4">
          <div class="card-header">
              <h6 class="card-title" v-if="warehouse.id == null">
                Nuevo depósito
              </h6>
              <h6 class="card-title" v-else>Editar depósito</h6>
              <b-button variant="outline-primary" size="sm"
                type="button"
                v-if="warehouse.id != null"
                @click="warehouse = emptyWarehouse">
                Agregar nuevo depósito
              </b-button>
            </div>
            <div class="card-body">
              <b-form @submit.prevent>
                <b-form-group>
                  <label class="control-label mt-3" for="input-live">Nombre:</label>
                  <b-form-input
                      id="input-live"
                      v-model="warehouse.name"
                      :state="nameState"
                      aria-describedby="input-live-help input-live-feedback"
                      placeholder="Nombre del depósito"
                      trim>
                  </b-form-input>                  
                    <b-form-invalid-feedback
                      v-if="nameState === false"
                      id="input-live-feedback"
                    >
                      El nombre debe tener un mínimo de 3 letras
                    </b-form-invalid-feedback>                  
                    <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                </b-form-group>
                <b-form-group>
                  <label class="control-label mt-3" for="input-live">Sucursal:</label>
                    <b-form-select class="form-control" v-model="warehouse.bo_id">
                        <option :value="null">Ninguno</option>
                        <option v-for="branchoffice in branchoffices" :key="branchoffice.id" :value="branchoffice.id">{{ branchoffice.name }}</option>
                    </b-form-select>
                </b-form-group>    
              </b-form>
            </div>
            <div class="card-footer">
              <a
                v-if="nameState === null"
                @click="saveWarehouse()" 
                href="#import_products"                   
                data-toggle="tab"                    
                >
                <b-button variant="orange mt-3" size="lg">Guardar Depósito</b-button>
              </a>
            </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";

export default {
    components: {
      VdtnetTable
    },
    data: function() {
      return {
        doSearch: '',   
        isLoading: false,      
        opts: {
          language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
          ajax: {
            url: process.env.VUE_APP_BASE_URL+"/api/warehouses/list",
            type: "GET",
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          },
        },
        fields: {
          name: { label: "Nombre", searchable: true },
          sucursal: { label: "Sucursal", searchable: true },
          actions: {
            isLocal: true,
            label: "Acciones",
            width: "123px",
            defaultContent:
              '<div class="divActions">' +
              '<button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">'+
              '<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>'+
              '<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>'+
              '</svg></button>' +
              '<button data-action="delete" data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
              '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
              '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
              '</svg></button>' +
              "</div>",
          },
        },
        warehouse: {
          id: null,
          name: "",
          bo_id: "",
        },
        emptyWarehouse: {
          id: null,
          name: "",
          bo_id: "",
        },             
        branchoffices: [],
        warehouses: [],
      };
    },
    mounted() {
      this.getWarehouses();
      this.getBranchoffices();
    },
    computed: {
      nameState() {
        return this.warehouse.name.length < 3 && this.warehouse.name.length > 0
          ? false
          : null;
      },    
    },
    watch: {
      doSearch(newValue, oldValue) {
        if(newValue.length >= 4 || oldValue.length >= 4) {
          this.$refs.table.search(this.doSearch)
        }
        if(newValue === '' && oldValue.length > 0) {
          this.$refs.table.search(this.doSearch)
        }
      },
    },
    methods: {
      getWarehouse(data) {
        var vm = this;
        vm.isLoading = true
        this.$http.get("/api/warehouses/getWarehouse/" + data.id).then((response) => {
          vm.warehouse = response.data.data;
          vm.isLoading = false
        });
      },
      getWarehouses() {
        var vm = this;
        vm.isLoading = true
        this.$http.get("/api/warehouses/list").then((response) => {
          vm.warehouses = response.data.data;
          vm.isLoading = false
        });
      },
      getBranchoffices() {
        var vm = this;
        vm.isLoading = true
        this.$http.get('/api/branchoffices/getBranchoffices').then((response) => {
          vm.branchoffices = response.data.data
          vm.isLoading = false
        })
      },
      saveWarehouse() {
      this.isLoading = true
      if (this.warehouse.id != null) {
        this.$http
          .post("/api/warehouses/edit/" + this.warehouse.id, this.warehouse)
          .then((response) => {
            
            console.log(response.data);
            this.$refs.table.reload();
            this.warehouse = this.emptyWarehouse;
            this.getWarehouses();
          });
        } else {
          this.$http.post("/api/warehouses/add", this.warehouse).then((response) => {
            
            this.$refs.table.reload();
            this.warehouse = this.emptyWarehouse;
            this.getWarehouses();
          });
        }
      },
      deleteWarehouse(data) {
        var vm= this;
        if (confirm("Esta seguro que desea borrar este depósito?") == true) {
          vm.isLoading = true
          this.$http
            .post("/api/warehouses/delete", { id: data.id })
            .then((response) => {
              
              if (response.data.code == 201) {
                this.$refs.table.reload();
                this.warehouse = this.emptyWarehouse;
                this.getWarehouses();
              }
              else vm.isLoading = false
            })
            .catch(function (error) {
              console.log(error.message);
              alert('Error al borrar');
              vm.isLoading = false;
            });
        }
      },
    }
  }
</script>