<template>
  <div class="col-md-12">
    <div class="form-group col-border">
      <tr>
        <th>
          <div class="form-group">
            <label>Cuenta corriente</label><label v-if="msg!=''">{{msg}}</label>
            <input v-model.number="cc.amount" type="number" min="0" step="0.01" class="form-control">
          </div>
        </th>
				<th>
          <div class="form-group">
            <label>Dias a acreditar</label>
            <input v-model.number="cc.days" type="number" min="0" step="1" class="form-control">
          </div>
        </th>
        <th valign="middle" style="width:80px">
					<div class="payment-actions">
            <span @click="reset" class="delete-product mr-2" aria-hidden="true" title="Eliminar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </span>
            <span v-if="validate" @click="save" class="ok-payment-method" aria-hidden="true" title="Grabar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>
            </span>
          </div>
        </th>
      </tr>
    </div>
  </div>
</template>

<script>

export default {
  props: ['limit'],
  data: function() {
    return {
			msg: '',
			cc: {
				amount: 0,
				days: 30
			}
    }
	},
	computed: {
		validate () {
			let vm = this;
			var isAmount = (vm.cc.amount !=null && vm.cc.amount!='' && vm.cc.amount > 0);
			if (isAmount>vm.limit) vm.msg="El monto a deber no puede sobrepasar el limite de CC";

			var isDays = (vm.cc.days !=null && vm.cc.days!='' && vm.cc.days > 0);
			
			return (isAmount && isDays);
		}
	},
  methods: {
		reset: function () {
      this.$emit('clientAccountCancel');
		},
		save: function () {			
      this.$emit('clientAccountSaved',this.cc);
    }
	}
}
</script>