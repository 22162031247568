import axios from 'axios'

export const getDownloads = ({ commit }) => {
  commit('setLoading', true);
  axios.post('/api/downloads/list')
    .then(response => {
      commit('getDownloads', response.data.data);
    })
    // fail
    .catch(e => {
      commit('getDownloads', e.response)
    })
    .finally(() => {
      commit('setLoading', false);
    });
}

export const emitSuccessAlert = ({ commit }) => {
  setTimeout(() => {
    commit('success/pushSuccess', 'Su descarga está lista', { root: true });
  }, 1000)
  
};