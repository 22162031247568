<template>
  <b-container class="mt-4">
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Regenerar</h4>
      </b-col>
    </div>
    <div class="row card-container">
      <div class="col" style="max-width: 400px;">
        <div class="card bg-light rounded-sm p-3 my-2 border">
          <b-form-group>
              <label>Ingrese ID de la factura</label>
              <b-form-input
                  v-model="saleId" 
                  type="number"
                  label="id de la Factura"
                  autocomplete="off"
                  prepend-icon="ni ni-lock-circle-open" 
                  name="id" 
                  required>
              </b-form-input>
          </b-form-group>
          <b-form-group>
              <b-button class="my-2" variant="orange" size="lg" @click="reprint">Reimprimir Factura</b-button>
          </b-form-group>
        </div>
      </div>

      </div>
  </b-container>
</template>

<script>
import axios from 'axios';
import myaxios from 'axios';



export default {
  data: function() {
    return {
        saleId: 0,
    }
  },
  methods: {
    reprint() {
      var vm=this;
      axios.get('/api/sales/reprintSale/' + this.saleId)
      .then(res => {
        const uninterceptedAxiosInstance = myaxios.create();
          uninterceptedAxiosInstance.get(res.data.data.urlInvoice, { responseType: 'blob' })
          .then((response) => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const link = document.createElement('a')
            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString().replace(/\//g, '-');
            link.href = URL.createObjectURL(blob)
            link.download = 'Reimpresion_' + formattedDate + '.pdf';
            link.click()
            URL.revokeObjectURL(link.href)
          })
        })
    }
  }
}
</script>

<style>
.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
</style>