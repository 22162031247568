<template>
  <b-container fluid>
    <div class="row pt-3">
      <h2>
          <label for="">Detalle de remito</label>
      </h2>
    </div>
    <!-- ------- DATOS DE REMITO -------- -->
    <b-card class="bg-light d-flex justify-content-between mb-4 p-0">
      <b-row class="mt-3">
        <b-col cols="sm-3">
          <b-form-group>
          <b-form-group v-if="shippingNote.letter != 'P'">                
            <h2 class="control-label">{{shippingNote.type}} {{shippingNote.letter}} #{{shippingNote.pos}}-{{shippingNote.number}}</h2>
          </b-form-group>
        <b-form-group v-else>
            <h2 class="control-label">#{{shippingNote.pos}}-{{shippingNote.number}}</h2>
          </b-form-group>
          </b-form-group>
        </b-col>
    
        <b-col cols="sm-3">
          <b-form-group>
            <h2 class="control-label">Cliente: {{shippingNote.client.name}} </h2>
          </b-form-group>
        </b-col>
        <!-- @if (Auth::user()->branchoffice->options->salesclerk_module) -->
        <b-col cols="sm-3">
          <b-form-group>
              <h2 class="control-label">Vendedor: {{clerkName}}</h2>
          </b-form-group>
        </b-col>
        <!-- @endif -->
        <b-col cols="sm-3">
          <b-form-group>
            <h2 class="control-label">Fecha: {{ createdDateFormatted }}</h2>
          </b-form-group>
        </b-col>
        <div v-if="shippingNote.response != null">
          <b-row>
            <b-col cols="md-6">
              <b-form-group>
                <p class="control-label">CAE: {{shippingNote.response['CAE']}}</p>
              </b-form-group>
            </b-col>
            <b-col cols="md-6">
              <b-form-group>
                <p class="control-label">CAE Fech. Venc.: {{date('d/m/Y', strtotime(shippingNote.response['CAEFchVto']))}}</p>
              </b-form-group>
            </b-col>
          </b-row>
        </div> 
      </b-row>         
    </b-card>
    <!-- ------ TABLA ------ -->
    <b-row>
      <b-col cols="md-8">
        <b-table-simple sticky-header small caption-top responsive>
          <b-thead head-variant="light">
            <b-tr>
              <b-th> Item </b-th>
              <b-th class="col-small"> Cantidad </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            
            <b-tr 
            v-for="value in shippingNote.shipping_note_details"
                :key="value.shipping_note_id"
                role="row"
                class="add">
              <b-td>{{value.description}}</b-td>
              <b-td>{{value.quantity}}</b-td>
            </b-tr>
            
          </b-tbody>
        </b-table-simple>    
      </b-col>
      <b-col cols="md-4">
        <div v-if="shippingNote.notes !=''" class="form-group">
          <label>Nota para el cliente</label>
          <textarea v-model="shippingNote.notes" disabled="disabled" name="note" rows="3" class="form-control"></textarea>
        </div>   
      </b-col>
    </b-row>
    <!-- BOTONES -->
    <b-row class="receipt-footer d-flex justify-content-end mb-3">
      <DownloadDoc :pdfurl="pdfurl" :open="false" :filename="shippingNote.client.name+shippingNote.created_at+'.pdf'">
        <b-button variant="outline-primary" size="lg" class="mr-4">Descargar</b-button>
      </DownloadDoc>
      <DownloadDoc :pdfurl="pdfurl" :open="true" :filename="shippingNote.client.name+shippingNote.created_at+'.pdf'">
        <b-button variant="orange" size="lg">Imprimir</b-button>
      </DownloadDoc>
    </b-row>
  </b-container>
</template>

<script>
import DownloadDoc from "../../global/DownloadDoc";

export default {
  components: {
    DownloadDoc
  },
  props: ["shippingNoteId"],

  data: function() {
    return {
      pdfurl: process.env.VUE_APP_BASE_URL+'/api/shippingnotes/snPDF/' + this.shippingNoteId,
      shippingNote: {
        letter: null,
        pos: null,
        number: null,
        client_id: null,
        subtotal: null,
        discount: null,
        taxes: null,
        total: null,
        shipping_note_details: [{
          shipping_note_id: null,
          description: null,
          quantity: null,
          price: null
        }],
        client: {
          name: null,
          clerk: {
            name: null
          }
        }
      }
    }
  },
  computed: {
    createdDateFormatted() {
      return new Date(this.shippingNote.created_at).toLocaleDateString('es-AR')
    },
    clerkName(){
      return this.shippingNote.client.clerk != null? this.shippingNote.client.clerk.name: '';
    }
  },
  mounted() {
    this.getShippingNoteDetail(this.shippingNoteId);
  },
  methods: {
    getShippingNoteDetail(shippingNoteId){
        var vm= this;
        this.$http.get('/api/shippingnotes/getShippingNote/' + shippingNoteId)
          .then(response => {
            if(response.data.success) {
                vm.shippingNote = response.data.data;                
                console.log(vm.shippingNote)
                console.log(vm.shippingNote.shipping_note_details)
            }
        })
        .catch(error => {
            alert(error);
        })
    },
  }
};
</script>

