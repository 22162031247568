<template>
    <b-container fluid>
      <div class="row mt-3">
        <b-col class="d-flex justify-content-start">
          <h4>Vendedores</h4>
        </b-col>
      </div>
        <!-- -------------------- CABECERA -------------------------- -->
        <b-col cols="md-12 stats">
            <b-row class="text-center">
                <b-col cols="lg-4 md-4 sm-12 xs-12">
                    <div class="stat">
                        <div class="number"> {{ comtotales | formatoMoneda2Dec}}</div>
                        <div class="desc">Comisiones totales este mes</div>
                    </div>
                </b-col>
                <b-col cols="lg-4 md-4 sm-12 xs-12 border-r-l">
                    <div class="stat">
                        <div class="number"> {{ comliqui | formatoMoneda2Dec}} </div>
                        <div class="desc">Comisiones liquidadas</div>
                    </div>
                </b-col>
                <b-col cols="lg-4 md-4 sm-12 xs-12">
                    <div class="stat">
                        <div class="number"> {{ comsinliqui | formatoMoneda2Dec}}</div>
                        <div class="desc">Comisiones sin liquidar</div>
                    </div>
                </b-col>
            </b-row>
        </b-col>
        <!-- -------------------- BUSCADOR -------------------------- -->
        <b-form inline class="d-flex justify-content-start my-2">
            <input
            v-model="doSearch"
            type="search"
            placeholder="Buscar..."
            class="form-control"
            >
        </b-form>
        <!-- ----------------------- TABLA -------------------------- -->
        <b-row>
          <b-col cols="md-8">
            <vdtnet-table
                :fields="fields"
                :opts="opts"
                :selectCheckbox="0"               
                @salesClerkDetail="getSalesClerkDetail"
                @edit="getSalesClerk"
                @delete="deleteSalesClerk"            
                ref="table"
            />       
          </b-col>

            <b-col cols="md-4">
              <div class="card mb-4">
                <b-button variant="primary" size="sm" v-if="salesClerk.id != null" @click="salesClerk = emptySalesClerk">
                  Nuevo vendedor
                </b-button>
                <!-- ------ EDICION ------ -->
                <div class="card-header">
                  <h6 class="card-title" v-if="salesClerk.id == null">Nuevo vendedor</h6>
                  <h6 class="card-title" v-else>Editar vendedor</h6>
                </div>

                <div class="card-body">
                <!-- <form class="form" action="/api/salesclerks/add"> -->
                  <form>

                    <b-form-group>
                        <label class="control-label" for="input-live">Nombre</label>
                        <input
                            class="form-control"
                            id="input-live"
                            v-model="salesClerk.name"                                    
                            aria-describedby="input-live-help input-live-feedback"
                            placeholder="Nombre completo"
                            trim
                        >                                
                    </b-form-group>
                    <b-form-group>
                        <label class="control-label" for="input-live" >DNI / CUIT</label>
                        <input
                            class="form-control"
                            id="input-live"
                            v-model="salesClerk.dni"                        
                            aria-describedby="input-live-help input-live-feedback"
                            placeholder="Ingrese DNI / CUIT"
                            trim
                        >
                    </b-form-group>
                    <b-form-group>
                        <label class="control-label" for="input-live">Telefono</label>
                        <input
                            class="form-control"
                            id="input-live"
                            v-model="salesClerk.phone"                        
                            aria-describedby="input-live-help input-live-feedback"
                            placeholder="Ingrese Teléfono"
                            trim
                        >
                    </b-form-group>
                    <b-form-group>
                        <label class="control-label" for="input-live">Email</label>
                        <input
                            class="form-control"
                            id="input-live"
                            v-model="salesClerk.email"                        
                            aria-describedby="input-live-help input-live-feedback"
                            placeholder="Ingrese Email"
                            trim
                        >
                    </b-form-group>
                    <b-form-group>
                        <label class="control-label" for="input-live">Dirección</label>
                        <input
                            class="form-control"
                            id="input-live"
                            v-model="salesClerk.address"                        
                            aria-describedby="input-live-help input-live-feedback"
                            placeholder="Ingrese Dirección"
                            trim
                        >
                    </b-form-group>
                    <b-form-group>
                        <label class="control-label">Comision por venta</label>
                        <input v-model="salesClerk.com_sales" 
                        min="0" step="0.01" 
                        value="0.00" 
                        type="number"
                        placeholder="%" 
                        class="calculate form-control">
                    </b-form-group>
                    <b-form-group>
                        <label class="control-label">Comision por cobro</label>
                        <input 
                        v-model="salesClerk.com_payment" 
                        min="0" 
                        step="0.01" 
                        value="0.00" 
                        type="number"
                        placeholder="%" 
                        class="calculate form-control">
                    </b-form-group>
                    <b-form-group>
                      <input id="use_rules" v-model="salesClerk.use_rules" type="checkbox" value="true">
                      <label for="use_rules" class="invoiced mr-3 ml-1">Usar reglas de comision personalizadas</label>
                      <b-button variant="primary" size="sm" v-b-modal.rulesmodal>REGLAS</b-button>
                    </b-form-group>
                    <b-form-group>
                      <b-modal id="rulesmodal" title="REGLAS ACTUALES">
                        <b-table-simple small caption-top responsive>
                            <b-thead>
                                <b-tr>
                                    <b-th>Descuento fijo a cliente</b-th>
                                    <b-th>Comisión por ventas</b-th>
                                    <b-th>Comisión por pagos</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td>0%</b-td>
                                    <b-td>10%</b-td>
                                    <b-td>10%</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>10%</b-td>
                                    <b-td>7%</b-td>
                                    <b-td>7%</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-td>12%</b-td>
                                    <b-td>5%</b-td>
                                    <b-td>5%</b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>
                      </b-modal>
                    </b-form-group>
                    
                  </form>
                </div>
                <div class="card-footer">
                  <a v-if="nameState === null" @click="saveSalesClerk()">
                      <b-button variant="orange" size="lg" data-toggle="tab">
                        Guardar vendedor
                      </b-button>
                    </a>
                </div>
              </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>

import VdtnetTable from 'vue-datatables-net';
import formatMoneyMixin from "./../../../mixins/formatMoneyMixin";
import { mapState } from 'vuex';

export default {
    components: {
      VdtnetTable
    },
    mixins: [formatMoneyMixin],
    data: function() {
    return {
      comtotales: 0,
      comliqui: 0,
      comsinliqui: 0,
      doSearch: '',   
      loading: false,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/salesclerks/list",
          type: "GET",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
        processing: true,
          pageLength: 15,
          searching: true,
          searchDelay: 500,
          destroy: true,
          ordering: true,
          lengthChange: true,
          serverSide: true,
          fixedHeader: true,
      },
      fields: {
        id: { label: 'Código' },
        name: { label: 'Nombre', sortable: false },
        phone: { label: 'Teléfono', sortable: false },
        com_sales: { label: 'Comisión venta', render: function(data){return data + " %";}, sortable: false },
        com_payment: { label: 'Comisión cobro', render: function(data){return data + " %";}, sortable: false },
        use_rules: { label: 'Reglas', render: function(data) {
            return data === 1 ? '<span style="font-size:20px;color:#4169E1;" class="glyphicon glyphicon-check btn-sm"></span>' 
            : '<span style="font-size:20px;color:gray;" class="glyphicon glyphicon-ban-circle btn-sm"></span>'
            }, sortable: false
        },
        actions: {
          isLocal: true,
          label: "Acciones",
          width: "123px",
          defaultContent:
            '<div class="divActions btn-group-sm">' +
            '<button data-action="salesClerkDetail" data-toggle="tooltip" title="Ver detalle" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">'+
            '<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>'+
            '<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>'+
            '</svg></button>' +
            '<button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">'+
            '<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>'+
            '<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>'+
            '</svg></button>' +
            '<button data-action="delete" data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
            '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
            '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
            '</svg></button>' +
            "</div>",
        },
      },
      salesClerk: {
        id: null,        
        name: "",
        email: "",
        phone: "",
        com_sales: "",
        com_payment: "",
        use_rules: null,
      },
      emptySalesClerk: {
        id: null,        
        name: "",
        email: "",
        phone: "",
        com_sales: "",
        com_payment: "",
        use_rules: null,
      },  
      commissions: [],
      generalBalanceToDate: null
    };
  },
  mounted() {
    this.getIndex();
  },
  computed: {
    nameState() {
      return this.salesClerk.name.length < 3 && this.salesClerk.name.length > 0
        ? false
        : null;
    },
    isRulesButtonDisabled() {
        return this.salesClerk.use_rules != 1 ;
    },
    ...mapState('salesClerks',{
        salesClerks: state => state.salesClerks,
      }),  
  },
  created() {
     this.$store.dispatch('salesClerks/getSalesclerks')
  },
  watch: {
    doSearch(newValue, oldValue) {
      if(newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch)
      }
      if(newValue === '' && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch)
      }
    },
   
  },
  methods: {    
    getSalesClerk(data) {
      var vm = this;
      vm.loading = true
      this.$http
        .get("/api/salesclerks/getSalesclerk/" + data.id)
        .then((response) => {
          vm.salesClerk = response.data.data;
          vm.loading = false
        });
    },
    getSalesClerkDetail(data) {
        this.$router.push({name: 'Detalle de vendedor', params: { salesClerkId: data.id }})
      },
    getIndex() {
      var vm = this;
      vm.loading = true
      this.$http.post("/api/salesclerks/getIndex").then((response) => {
        vm.comtotales = response.data.total
        vm.comliqui = response.data.debt
        vm.comsinliqui = response.data.paid
        vm.loading = false
      });
    },
    deleteSalesClerk(data) {
      if (confirm("Esta seguro que desea borrar este vendedor?") == true) {
        this.loading = true
        this.$http
          .post("/api/salesclerks/delete", { id: data.id })
          .then((response) => {
            
            if (response.data.code == 201) {
              this.$refs.table.reload();
              this.salesClerk = this.emptySalesClerk;
              this.loading = true              
            }
          });
      }
    },
    saveSalesClerk() {
      this.loading = true
      if (this.salesClerk.id != null) {        
        this.$http
          .post("/api/salesclerks/edit/" + this.salesClerk.id, this.salesClerk)
          .then((response) => {
            
            console.log(response.data);
            this.$refs.table.reload();
            this.salesClerk = this.emptySalesClerk;
            this.loading = true
          });
      } else {        
        this.$http
          .post("/api/salesclerks/add", this.salesClerk)
          .then((response) => {
            
            this.$refs.table.reload();
            this.salesClerk = this.emptySalesClerk;
            this.loading = true
          });
      }
    },
  },
}
</script>
