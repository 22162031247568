<template>
    <b-container fluid>
        <div class="loading" v-if="loading">Loading&#8230;</div>    
        <!-- -------------------- BUSCADOR -------------------------- -->
        <b-form inline class="d-flex justify-content-between my-2">
            <input type="search" class="form-control input-sm" 
                v-model="filters.q"
                placeholder="Buscar...">
            <!-- -------------------- FILTRO MOSTRAR ------------------- -->
            <label>Mostrar:
                <select class="form-control input-sm ml-2" v-model="filters.itemsPerPage">
                    <option :value="15">15</option>
                    <option :value="25" selected>25</option>
                    <option :value="50">50</option>
                </select>
            </label>           
            <router-link :to="'/ecom/catalogos/nuevo/'">
                <b-button variant="success" @click="toggleEditCatalog">Nuevo Catalogo</b-button>
            </router-link>
        </b-form>

        <!-- ----------------------- TABLA -------------------------- -->
        <b-row>
            <b-col cols="sm-12">
                <b-table-simple responsive>
                    <b-thead head-variant="light">
                        <b-tr role="row" class="ts-header">
                            <b-th class="col-sm-1">Fecha</b-th>
                            <b-th class="col-sm-6">Nombre</b-th>
                            <b-th class="col-sm-3">Código</b-th>
                            <b-th class="col-sm-3">Acciones</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-if="catalogs.length<1">
                            <b-td colspan="4">No hay catálogos disponibles</b-td>
                        </b-tr>
                        <b-tr role="row" v-for="catalog in catalogs" :key="catalog.id">
                            <b-td class="col-sm-1">{{catalog.created_at}}</b-td>
                            <b-td class="col-sm-6">{{catalog.name}}</b-td>
                            <b-td class="col-sm-3">
                                <a :href="catalog.link" target="_blank">{{catalog.uid}}</a>
                            </b-td>
                            <b-td class="col-sm-3">
                                <b-button-group>
                                    <b-button class="btn btn-light" variant="outline-primary" title="Copiar URL" @click="copyUrl(catalog.link)">
                                        <span><b-icon icon="files"></b-icon></span>
                                    </b-button>
                                </b-button-group>

                                <div class="btn-group">
                                    <button type="button" @click="getPDF(catalog.id)" title="Descargar PDF" class="btn btn-light marginButton">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                <b-button-group>
                                    
                                        <router-link :to="'/ecom/catalogos/editar-catalogo/' + catalog.id" class="btn btn-light marginButton">
                                            <b-icon icon="pencil-square"></b-icon>
                                        </router-link>
                            
                                </b-button-group>
                                <b-button-group>
                                    <b-button @click="deleteCatalog(catalog)" variant="outline-primary" title="Eliminar" class="btn btn-light marginButton">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </b-button-group>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>

        <!-- PAGINACION -->
        <div class="d-flex justify-content-end mb-4">
            <div class="dataTables_paginate paging_simple_numbers" id="table_paginate">
                <ul class="pagination">
                    <li class="paginate_button previous" v-bind:class="[{'disabled': filters.page==1}]">
                        <button class="btn btn-link" v-on:click="prevPage()">Anterior</button>
                    </li>
                    <li v-if="(filters.page - (pageInterval/2))>0" class="paginate_button">
                        <button class="btn btn-link" v-on:click="prevPage()">...</button>
                    </li>
                    <template v-for="index in totalPages">
                        <li :key="index" v-if="inIntervalPage(index)" class="paginate_button" v-bind:class="[{'active': filters.page==index}]">
                            <button class="btn btn-link" v-on:click="changePage(index)">{{index}}</button>
                        </li>
                    </template>
                    <li v-if="totalPages>(filters.page + (pageInterval/2))" class="paginate_button">
                        <button class="btn btn-link" v-on:click="nextPage()">...</button>
                    </li>
                    <li class="paginate_button next" v-bind:class="[{'disabled': filters.page>=totalPages}]">
                        <button class="btn btn-link" v-on:click="nextPage()">Siguiente</button>
                    </li>
                </ul>
            </div>        
        </div>
        
        <router-view v-if="isEditViewActive"></router-view>
    </b-container>
</template>
<script>
import Multiselect from 'vue-multiselect';
import myaxios from 'axios';

export default {
  components: {
      Multiselect
    },
  data() {
    return {
        isEditViewActive: false,
        loading:false,
        catalogs:[],
        filters:{
            q:'',
            itemsPerPage:15,
            page:1,
        },
        totalPages:0,
        pageInterval:22,
        catalogToEdit:null,
    }
  },
  mounted() {
      this.getCatalogs();
  },
  methods: {
    toggleEditCatalog() {
        this.isEditViewActive = !this.isEditViewActive
    },
    getCatalogs(){
        let vm = this;
        vm.loading=true;
        this.$http.post("/api/catalogs/getList", this.filters)
        .then((response) => {
            if (response.data.success) {
                vm.catalogs = response.data.data.list;
                vm.totalPages=response.data.data.totalPages;
                vm.loading=false;
            } else {
                vm.loading=false;
                
            }
        }).catch((error) => {
            vm.loading=false;
            console.log('error: '+error);
        })
    },
    deleteCatalog(catalog){
        let vm = this;
        if(confirm('¿Desea eliminar el catalogo "' + catalog.name + '" definitivamente? Esta acción no puede deshacerse')==true){
            this.$http.get("/api/catalogs/delete/" + catalog.id)
            .then((response) => {
                
                vm.getCatalogs();
            }).catch((error) => {
                console.log('error: '+error);
            })
        }
    },
    copyUrl(url) {
        alert('URL copiada al portapapeles');
        const el = document.createElement('textarea');
        el.value = url;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
          document.getSelection().removeAllRanges();
          document.getSelection().addRange(selected);
        }
        document.body.removeChild(el);
    },
    getPDF(id){
        this.$http.get(process.env.VUE_APP_BASE_URL+"/api/catalogs/exportPDF/" + id)
        .then((response) => {
        });        
    },
    prevPage(){
        if(this.filters.page>1){
            this.page = this.page-1;
            this.getCatalogs();
        }
    },
    nextPage(){
        if (this.filters.page<this.totalPages){
            this.page = this.page +1;
            this.getCatalogs();
        }
    },
    changePage(data){
        this.page = data;
        this.getCatalogs();
    },
    inIntervalPage(index){
        if(this.totalPages<=this.pageInterval){return true;}

        let limitprev = this.filters.page - (this.pageInterval/2);
        let limitnext = this.filters.page + (this.pageInterval/2);

        if(limitprev<0){
            limitprev=0;
            limitnext=this.pageInterval;
        }

        if(index>limitprev && index<limitnext){
            return true;
        }

        return false;
    },
  },
  computed: {
    editCatalogLink() {
        return { name: 'Editar catalogo'}
    }
  },
  watch: {
      'filters.q': {handler: function (a) {this.getCatalogs();}},
      'filters.itemsPerPage': {handler: function (a) {this.getCatalogs();}},
  },
}
</script>

<style lang="css">

.textRed{
    font-weight: bold;
    text-align: center;
    color: #e73d4a;
}
.textGreen{
    font-weight: bold;
    text-align: center;
    color: #5CB85C;
}

.modal-sm-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-sm-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-sm-container {
    width: 50vw;
    margin: 0px auto;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    height: 150px;
    padding-top: 25px;
    padding-left: 50px;
}

.modal-sm-header {
    display: flex;
    align-items: center;
}

.modal-sm-enter {
    opacity: 0;
}

.modal-sm-leave-active {
    opacity: 0;
}

.footer{
    display: flex;
    justify-content: flex-end;
    gap: 30px;
}

.modal-sm-enter .modal-sm-container,
.modal-sm-leave-active .modal-sm-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
