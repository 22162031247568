<template>
    <b-container fluid>
        <b-table-simple sticky-header striped>
            <b-thead head-variant="light">
                <b-tr role="row">
                    <b-th>Fecha</b-th>
                    <b-th>P.d.V.</b-th>
                    <b-th>Numero</b-th>
                    <b-th>Cliente</b-th>
                    <b-th>Monto</b-th>
                    <b-th class="col-sm-1"></b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr role="row " v-for="(rec, index) in receiptListFiltered" :key="index" >
                    <b-td>{{ formatDate(rec.created_at) }}</b-td>
                    <b-td>{{ rec.pos }}</b-td>
                    <b-td>{{rec.number}}</b-td>
                    <b-td>{{rec.client_name}}</b-td>
                    <b-td>$ {{ parseFloat(rec.total).toFixed(2)}}</b-td>
                    <b-td>
                        <b-button-group>
                            <b-dropdown right variant="light">
                                <b-dropdown-item :href="'/ventas/cobranzas/detalle-recibo/' + rec.cc_id">Ver detalle</b-dropdown-item>
                                <b-dropdown-item href="javascript:void(0);" @click="getPDF(rec.cc_id, rec.number)">Descargar PDF</b-dropdown-item>
                                <b-dropdown-item href="javascript:void(0);" @click="cancelPayment(rec.cc_id)">Cancelar pago</b-dropdown-item>
                            </b-dropdown>
                        </b-button-group>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-pagination>
            <b-row>
                <b-col cols="sm-12">
                    <div class="dataTables_paginate paging_simple_numbers text-right" id="table_paginate">
                        <ul class="pagination float-right">
                            <li class="paginate_button previous" v-bind:class="[{'disabled': page==1}]">
                                <a href="#" v-on:click="prevPage()">Anterior</a>
                            </li>

                            <li v-if="(page - (pageInterval/2))>0" class="paginate_button">
                                <a href="#" v-on:click="prevPage()">...</a>
                            </li>

                            <template v-for="index in totalPages">

                                <li :key="index" v-if="inIntervalPage(index)" class="paginate_button" v-bind:class="[{'active': page==index}]">
                                    <a href="#" v-on:click="changePage(index)">{{index}}</a>
                                </li>

                            </template>

                            <li v-if="totalPages>(page + (pageInterval/2))" class="paginate_button">
                                <a href="#" v-on:click="nextPage()">...</a>
                            </li>

                            <li class="paginate_button next" v-bind:class="[{'disabled': page>=totalPages}]">
                                <a href="#" v-on:click="nextPage()">Siguiente</a>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
        </b-pagination>
    </b-container>
</template>

<script>

import moment from 'moment';
import myaxios from 'axios';


export default {
    props: ['client','fromDate','toDate','watchTarget'],
    data() {
        return {
            receiptList:[],
            recFilter:'',
            page:1,
            rowCount:9,
            totalItems:0,
            totalPages:1,
            pageInterval:22,
        }
    },
    mounted(){
        this.getReceiptList();
    },
    computed: {
        receiptListFiltered:{
            get: function () {
                let vm = this;
                return this.receiptList.filter(e => e.number.includes(vm.recFilter));
            }
        }
    },
    methods: {
        getReceiptList(){
            var vm= this;
            var outdata = {
                'client_id': vm.client,
                'fromDate':vm.fromDate,
                'toDate':vm.toDate,
                'page':vm.page,
                'rowCount':vm.rowCount
            };
            this.$http.post('/api/collections/getReceipts', outdata)
            .then(response => {
                if(response.data) {
                vm.receiptList = response.data.data.list;
                vm.totalItems=response.data.data.totalItems;
              }
            })
            .catch(error => {
              alert(error);
            })
        },
        cancelPayment(value){
            this.$emit('cancelPay',value);
        },
        formatDate(value){
            if (value){
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        prevPage(){
            this.page = this.page-1;
            this.getReceiptList();
        },
        nextPage(){
            this.page = this.page +1;
            this.getReceiptList();
        },
        changePage(data){
            this.page = data;
            this.getReceiptList();
        },
        inIntervalPage(index){
            if(this.totalPages<=this.pageInterval){return true;}

            let limitprev = this.page - (this.pageInterval/2);
            let limitnext = this.page + (this.pageInterval/2);

            if(limitprev<0){
                limitprev=0;
                limitnext=this.pageInterval;
            }

            if(index>limitprev && index<limitnext){
                return true;
            }

            return false;
        },
        getPDF(id, receiptNumber){
            this.$http.get('/api/collections/checkReceiptPDF/' + id)
            .then(res => {
                const uninterceptedAxiosInstance = myaxios.create();
                uninterceptedAxiosInstance.get(res.data.data.urlInvoice, { responseType: 'blob' })
                .then((response) => {
                    const blob = new Blob([response.data], {type: 'application/pdf'});
                    const currentDate = new Date();
                    const formattedDate = currentDate.toLocaleDateString().replace(/\//g, '-');
                    const fileName = 'Recibo_'+ receiptNumber +'_'+this.receiptListFiltered[0].client_name + '_' + formattedDate + '.pdf';
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = fileName;
                    console.log(link.download);
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
            })
        }
    },
    watch: {
      'client': {
          handler: function (a) {
              this.page=1;
              this.getReceiptList();
            }
      },
      'fromDate': {
          handler: function (a) {this.getReceiptList();}
      },
      'toDate': {
          handler: function (a) {this.getReceiptList();}
      },
      'watchTarget': {
          handler: function (a) {this.getReceiptList();}
      },
      'receiptList':{
          handler: function(a){
              this.totalPages = Math.ceil(this.totalItems / this.rowCount);
          }
      }
  },

}
</script>
<style>
.disabled {
  pointer-events: none;
}
</style>
