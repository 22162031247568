<template>
  <b-container fluid>
      <!-- ------- DATOS DE CLIENTE -------- -->
      <b-row class="mt-5" v-if="client">
        <b-col cols="sm-3">
          <b-form-group>
            <label class="control-label">Cliente: {{ client.name }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="sm-3">
          <b-form-group>
            <label class="control-label">Nombre de Fantasia: {{ client.business }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="sm-3">
          <b-form-group>
              <label class="control-label">Direccion: {{ client.address }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="sm-3">
          <b-form-group>
              <label class="control-label">Telefono: {{ client.phone_1 }}</label>
          </b-form-group>
        </b-col>
      </b-row>  
    <!-- ---------- CABECERA ----------- -->
    <div class="stats oneclass" v-if="client">
      <b-row>
        <b-col cols="lg-12 text-center">
          <div class="stat">
            <div class="number">{{ formatoDinamicoMoneda(client.sold) }}</div>
            <div class="desc">Total de ventas efectuadas en el rango indicado</div>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- ---------- FILTRO FECHA ----------- -->
    <b-form inline class="d-flex justify-content-center my-2">
      <Daterange :opendir="'left'" :filtered="true" @datepicked="dateCreated"/>
    <!-- ---------- BOTON ----------- -->    
        <b-button class="ml-2"  variant="orange" @click="printSaleHistory(clientId)">Imprimir resumen</b-button>
    </b-form>
    <!-- ---------- TABLA ----------- -->
    <b-row>
      <b-col cols="md-12">
        <div>
         <vdtnet-table 
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          @saleDetail="getSaleDetail"  
          ref="table"
         />
        </div>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import VdtnetTable from 'vue-datatables-net';
import Daterange from '../../global/Daterange-component.vue';
import formatDateMixin from "../../../mixins/formatDateMixin";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";
import myaxios from 'axios';

export default {
  components: {
      VdtnetTable,
      Daterange
    },
    mixins: [formatDateMixin,formatMoneyMixin],
  props: ["clientId"],
  data: function() {
    return {
      startDate: null,
      endDate: null,
      opts: {
        language: {
            url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
          ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/sales/"+this.clientId+"/sales",
          type: "POST",
          data: (d) => {
            d.daterange = this.daterange
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
      },
      fields: {
        type: { label: "Tipo"},
        created_at: { label: "Fecha", searchable: true, render: function(data) {
          return new Date(data).toLocaleDateString('es-AR')
        } },          
        user: { label: "Vendedor" },
        fullnumber: { label: "Número" },
        paid: { label: "Condición" },
        total: { label: "Total" },        
        actions: { isLocal: true,
                label: 'Detalle',
                width: '85px',
                align: 'center',
                defaultContent: '<button data-action="saleDetail" data-toggle="tooltip" title="Ver detalle" type="button" class="btn btn-light"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-ruled" viewBox="0 0 16 16">'+
                                '<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z"/>'+
                                '</svg></i></button>' 
              }  
      },
      salesBalance: null,
      client: null,
    }
  },
  mounted() {
    this.setDefaultDateRange();
    this.getClient(this.clientId);
  },
  computed: {
    daterange() {
      if (this.startDate === null || this.endDate === null) {
        return null;
      }
      const startDate = this.formatoFecha2Daterange(this.startDate);
      const endDate = this.formatoFecha2Daterange(this.endDate);
      return `${startDate} - ${endDate}`;
    },
  },
  methods: {
    refreshTable() {
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
      console.log(vm.$refs.table);
    },
    getSaleDetail(data) {
      this.$router.push({
        name: "Detalle de venta",
        params: { saleId: data.id },
      });
    },
    getClient(clientId){
        var vm= this;
        this.$http.get('/api/clients/getClient/' + clientId)
          .then(response => {
                this.client = response.data.data;          
        })
        .catch(error => {
            alert(error);
        })
    },
    printSaleHistory(clientId) {
      var vm = this;
      this.loading = true;
      this.$http.post('/api/reports/printUserHistory/' + clientId)
      .then(response => {

        }).catch(error => {
            vm.loading=false;
            alert(error);
      })

    },
    dateCreated(date) {
      this.startDate = date.startDate;
      this.endDate = date.endDate;
      this.refreshTable();     
    },
    setDefaultDateRange() {
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - 30);

        this.startDate = this.formatDate(startDate);
        this.endDate = this.formatDate(today);
    },
    formatDate(date) {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${day}/${month}/${year}`;
    },
  }
}
</script>