<template>
  <div class="card mb-4">
    <div class="card-header">
      <h4 class="card-title" v-if="tp_check.id == null">
        Emision de Nuevo cheque de tercero
      </h4>
      <h4 class="card-title" v-else>Editar cheque</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-4">
          <label class="control-label mr-1" for="input-live">Numero:</label>
          <b-form-input
            id="input-live"
            type="number"
            v-model="tp_check.number"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="N° de cheque"
            trim
          ></b-form-input>
        </div>
        <div class="col-12 col-md-4">
          <label class="control-label mr-1" for="input-live">Nombre del Emisor:</label>
          <b-form-input
            id="input-live"
            class="form-control"
            v-model="tp_check.name"
            :state="nameState"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Nombre del Emisor"
            trim/>
        </div>
        <div class="col-12 col-md-4">
          <label class="control-label mr-1" for="input-live">Numero de CUIT:</label>
          <b-form-input
            id="input-live"
            class="form-control"
            v-model="tp_check.cuit"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="CUIT"
            trim
          />
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="form-group">
            <label class="control-label mr-1" for="input-live">
              Banco:
            </label>
            <!-- <b-form-input
              id="input-live"
              class="form-control"
              v-model="tp_check.bank_name"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="Banco"
              trim
            /> -->
            <sucursales-bancarias 
              id="input-live"
              class="form-control"
              v-model="tp_check.bank_name"
              aria-describedby="input-live-help input-live-feedback"/>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <div class="form-group ">
            <label class="control-label mr-1">Cliente:</label>
            <typeahead
            class="mt-1" 
            :name="'client'"
            :plh="'Busqueda de cliente por nombre'"
            :clean-on="false"
            v-on:choosed="clientUpdate"
            v-on:empty="clientEmpty"
            :placeh="tp_check.clientName"
            :externalsrc="'/api/clients/getClients'"
            limit-chars="1"/>
          </div>
        </div>
        <div class="col-12 col-md-4 mt-2">
          <label class="control-label mr-1" for="input-live">Monto:</label>
          <b-form-input
            id="input-live"
            class="form-control"
            type="number"
            v-model="tp_check.amount"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Monto"
            trim
          ></b-form-input>
        </div> 
        <div class="col-12 col-md-4">
          <div class="form-group">
            <label class="control-label mr-1" for="input-live"
              >Fecha de emisión:</label
            >
            <input
              class="form-control"
              type="date"
              name="date_issue"
              id="datepicker-issue"
              v-model="tp_check.date_issue"
            />
          </div>
        </div>
        <div class="col-12 col-md-4">
          <label class="control-label mr-1" for="input-live">Fecha cobro:</label>
          <input
            class="form-control"
            type="date"
            name="date_due"
            id="datepicker-expire"
            v-model="tp_check.date_due"
          />
        </div>
        <div class="col-12 col-md-4">
          <label class="control-label mr-1" for="input-live">Notas:</label>
          <b-form-textarea
            id="textarea"
            v-model="tp_check.note"
            placeholder="Notas..."
            rows="2"
            cols="50"
            max-rows="6"
          ></b-form-textarea>
        </div>
        
      </div>
    </div>
    <div class="card-footer text-right">
      <a
        type="button"
        @click="saveTpCheck()"
        class="btn btn-md btn-orange btn-lg"
        href="#import_products"
        data-toggle="tab"
        >Guardar cheque de tercero</a
      >

    </div>
  </div>
</template>

<script>
import datepickerComponent from "./../global/Datepicker-component.vue";
import formatDateMixin from "../../mixins/formatDateMixin";
import SucursalesBancarias from "./../sub-components/sucursalesBancarias.vue";
import typeahead from '../global/Typeahead.vue';

export default {
  components: {
    datepickerComponent,
    SucursalesBancarias,
    typeahead,
  },
  mixins: [formatDateMixin],
  watch: {
    tpcheckId: function (value) {
      if (value != null) {
        console.log("watch", "id no es null");
        this.getTpcheck(value);
      } else {
        this.resetForm();
      }
    },
  },
  mounted() {
    this.resetForm();
    if (this.tpcheckId != null) {
      console.log("mounted", "id no es null");
      this.getTpcheck(this.tpcheckId);
    } else {
      this.resetForm();
    }
  },
  methods: {
    validateForm() {
      if (this.tp_check.number === null || isNaN(this.tp_check.number)) {
        alert("Debe ingresar un numero de cheque");
        return false;
      }

      if (this.tp_check.name === null || this.tp_check.name === "") {
        alert("Debe ingresar un nombre ");
        return false;
      }

      if (this.tp_check.cuit === null || isNaN(this.tp_check.cuit)) {
        alert("Debe ingresar un nro de cuit ");
        return false;
      }

      if (this.tp_check.bank_name == null || this.tp_check.name === "") {
        alert("Debe ingresar el nombre del banco");
        return false;
      }

      if (this.tp_check.date_due == null || this.tp_check.date_due === "") {
        alert("Debe ingresar la fecha de cobro");
        return false;
      }

      if (this.tp_check.date_issue == null || this.tp_check.date_issue === "") {
        alert("Debe ingresar la fecha de deposito ");
        return false;
      }

      if (this.tp_check.amount == null || isNaN(this.tp_check.amount)) {
        alert("Ingrese el monto a depositar");
        return false;
      }
      return true;
    },
    getTpcheck(id) {
      var vm = this;
      this.modo = "editar";
      this.$http.get("/api/funds/tp_checks/get/" + id)
      .then((response) => {
        vm.tp_check = response.data.data;
        console.log(vm.tp_check);

        // Las siguientes 2 lineas pasan las fechas de dd/mm/yyyy a yyyy-mm-dd
        vm.tp_check.date_issue = this.formatoFecha2Date(vm.tp_check.date_issue);
        vm.tp_check.date_due = this.formatoFecha2Date(vm.tp_check.date_due);
      });
    },

    saveTpCheck() {
      var vm = this;
      if (!this.validateForm()) {
        return;
      }
      if (this.tp_check.id != null) {
        const data = {
            ...(vm.tp_check),
            date_issue: vm.formatoDate2Fecha(vm.tp_check.date_issue),
            date_due: vm.formatoDate2Fecha(vm.tp_check.date_due),
          };
        this.$http
          .post("/api/funds/tp_checks/edit/" + vm.tp_check.id, data)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      } else {
        this.$http
          .post("/api/funds/tp_checks/add", vm.tp_check)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      }
    },
    clientUpdate(data) {
       this.tp_check.client_id= data.id;
     },
     clientEmpty(){
      this.tp_check.client_id=null;
     },
    resetForm() {
      this.tp_check = Object.assign({}, this.emptyTp_check);
      this.$emit("reset-id");
    },
    dateIssueUpdated(date) {
      const app = this;
      app.tp_check.date_issue = date;
    },
    dateDueUpdated(date) {
      const app = this;
      app.tp_check.date_due = date;
    },
  },
  props: ["tpcheckId", "showNewBtn"],

  computed: {
    nameState() {
      return this.tp_check.name.length < 3 && this.tp_check.name.length > 0
        ? false
        : null;
    },
  },

  data() {
    return {
      modo: "nuevo",
      tp_check: {
        number: null,
        name: "",
        cuit: null,
        bank_name: "",
        date_issue: "",
        date_due: "",
        client_id: null,
        clientName:null,
        amount: null,
        note: "",
      },
      emptyTp_check: {
        number: null,
        name: "",
        cuit: null,
        bank_name: "",
        date_issue: "",
        date_due: "",
        client_id: null,
        clientName:'',
        amount: null,
        note: "",
      },
      selected: null,
    };
  },
};
</script>