import authUserApi from '../../api/authUser'

// initial state
const state = () => ({
  loading: false,
  authUser:{
    branchoffices:[{
      configs:{
        "id": 1,
        "pricelist_id": 1,
        "client_id": 1,
        "product_id": 1,
        "fund_id": 1,
        "pos_id": 1,
        "warehouse_id": 1,
        "bo_id": 1,
      },
      payments_methods:{
        "id": 1,
        "bo_id": 1,
        "efectivo": 1,
        "cuenta_corriente": 1,
        "cheques": 1,
        "tarjetas": 1,
      }
    }],
   } 
})  
  // getters
const getters = {
  getUser (state) { return state.authUser },
  showLastweek (state) { return state.authUser.last_week && state.authUser.OWNER==1 }
}
  
  // actions
const actions = {
  async getAuthUser ({ commit }) {
    const authUser = await authUserApi.getAuthUser()
    commit('setAuthUser', authUser)

  },
  async getLoading({ commit }) {
    commit('setLoading', true);
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));  
    } catch (error) {
    
    } finally {
      commit('setLoading', false);
    }
  }
}
  
// mutations
const mutations = {
  setAuthUser (state, authUser) {
    state.authUser = authUser
    localStorage.setItem('permissions', JSON.stringify(authUser.permissions))
    localStorage.setItem('expired', authUser.expired)
  },
  setLoading(state, value) {
    state.loading = value;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}