<template>
  <div class="card my-2">
    <div class="card-header">
      <h4>Totales de la venta</h4>
    </div>
    <div class="card-body px-3">
      <b-row>
        <b-col cols="7 text-left">
          <h5 class="txt-res">
            SUBTOTAL:
          </h5>
        </b-col>
        <b-col v-if="disciva" cols="5 text-right">
          <h5 class="txt-res">
            {{formatoDinamicoMoneda(totals.gross)}}
          </h5>
        </b-col>
        <b-col v-else cols="5 text-right">
          <h5 class="txt-res">
            {{formatoDinamicoMoneda(totals.prices)}}
          </h5>
        </b-col>
      </b-row>
      <!-- --------- DESCUENTO ---------- -->
      <b-row>
        <b-col cols="5 text-left">
          <h5 class="txt-res">
            DESCUENTO: 
          </h5>
        </b-col>
        <b-col cols="3 text-center">
          <div v-if="discount_each_line !== undefined && discount_each_line && discount_each_line_type == 'amount'">
            <h5 class="txt-res">
              {{showInputDiscountRounded()}}
            </h5>
          </div>
          <div v-if="discount_each_line == false">
              <input v-model="inputdiscount" type="text"
                class="form-control"
                placeholder="10% o $10"
                :readonly="discount_each_line==1?true:false">
          </div>
        </b-col>
        <b-col cols="4 text-right">
          <h5 class="txt-res">
            {{formatoDinamicoMoneda(totals.tdiscount)}}
          </h5>
        </b-col>
      </b-row>

      <b-row v-if="disciva">
        <b-col cols="7 text-left">
          <h5 class="txt-res">SUBTOTAL:</h5> 
        </b-col>
        <b-col cols="5 text-right">
          <h5 class="txt-res">{{formatoDinamicoMoneda(totals.net) }}</h5> 
        </b-col>
      </b-row>

      <div v-if="disciva">
      <b-row v-for="(tax, key) in totals.ftaxes" :key="key" class="d-flex justify-content-between">
        <b-col cols="7 text-left">
          <h5 class="txt-res">IVA {{key}}%:</h5> 
        </b-col>
        <b-col cols="5 text-right">
          <h5 class="txt-res">{{formatoDinamicoMoneda(tax)}}</h5>
        </b-col>
      </b-row>
      </div>

      <b-row class="d-flex justify-content-between">
        <b-col cols="7 text-left">
          <h3 class="font-weight-bolder txt-res">
          TOTAL:
          </h3>
        </b-col>
        <b-col cols="5 text-right">
          <h3 class="font-weight-bolder txt-res">
            {{formatoDinamicoMoneda(totals.total)}}
          </h3>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import formatMoneyMixin from "../../../../mixins/formatMoneyMixin";

  export default {

    props: ['products', 'disciva', 'clientDiscount','discount_each_line', 'discount_each_line_type', 'discount', 'grossTotal', 'priceTotal'],
    mixins: [formatMoneyMixin],
    data: function() {
      return {
        inputdiscount : null,
        totals : {
          gross : 0,
          taxes: {},
          ftaxes: {},
          prices: 0,
          pdiscount : 0,
          tdiscount : 0,
          net : 0,
          total : 0
        }
      }
    },
    watch: {
      products: {
        handler: function() {
          this.calculateGross();
        },
        deep: true,
      },
      disciva: {
        handler: function() {
          this.calculateGross();
        },
      },
      discount:{
        handler: function() {
          var app=this;
          if(app.discount_each_line==1){
            if(app.discount_each_line_type !== undefined && app.discount_each_line_type == 'amount')
              app.inputdiscount='$' + app.discount;
            // else
            //   if(app.disciva !== undefined && app.disciva == true)
            //     app.inputdiscount='%' + (app.discount * 100) / app.grossTotal;
            //   else
            //     app.inputdiscount='%' + (app.discount * 100) / app.priceTotal;

            app.calcdiscount(app.inputdiscount);
            app.calculate();
          }
        },
      },
      inputdiscount: function() {
        var app = this;
        app.calcdiscount(app.inputdiscount);
        app.calculate();
      },
      clientDiscount: {
        handler: function(){
          var app = this;
          app.inputdiscount = app.clientDiscount + '%';
          app.calcdiscount(app.inputdiscount);
          app.calculate();
        },
      },
    },
    methods: {
      roundtofix: function(value, decimals) {
        return (Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)).toFixed(decimals);
      },
      round: function(value, decimals) {
        return (Number(Math.round(value + 'e' + decimals) + 'e-' + decimals));
      },
      calculateGross: function() {
        var app=this;

        let gross=0;
        let prices=0;
        let taxes={};
        
        for (let i = 0; i < app.products.length; i++) {
          taxes[app.products[i].taxtype] = 0;
        }

        for (let i = 0; i < app.products.length; i++) {
          gross+= app.products[i].gross * app.products[i].quantity;
          prices+= app.products[i].price * app.products[i].quantity;
          taxes[app.products[i].taxtype] += app.products[i].tax * app.products[i].quantity;
        }
        app.totals.gross = gross;
        app.totals.prices = prices;
        app.totals.taxes = taxes;

        if(app.discount_each_line==1){
          app.inputdiscount='$' + app.discount;
        }

        app.calcdiscount(app.inputdiscount);
        app.calculate();
      },
      calcdiscount: function(inputd) {
        if (inputd!=null) {
          this.totals.pdiscount=0;
          this.totals.tdiscount=0;

          var base = 0;
          if (this.disciva) {
            base = this.totals.gross;
          } else {
            base = this.totals.prices;
          }
          if (inputd.indexOf('%')>-1) {
            var filterd = parseFloat(this.inputdiscount.replace('%',''));
            if (filterd>100) {
              this.inputdiscount='100%';
              filterd=100;
            }
            this.totals.pdiscount = filterd;
            this.totals.tdiscount = parseFloat(filterd*base/100);
          }

          if (inputd.indexOf('$')>-1) {
            filterd = parseFloat(this.inputdiscount.replace('$',''));

            if (filterd>base) {
              this.inputdiscount=this.round(base,2)+'$';
              filterd = base;
            }
            this.totals.tdiscount = filterd;
            this.totals.pdiscount = parseFloat(filterd*100/base);
          }

          if (isNaN(this.totals.pdiscount)) {this.totals.pdiscount = 0;this.totals.tdiscount = 0;}
          if (isNaN(this.totals.tdiscount)) {this.totals.pdiscount = 0;this.totals.tdiscount = 0;}
        }
      },
      calculate: function() {
        var app = this;

        //Seccion blanqueo de variables
        app.totals.net=0;
        app.totals.total=0;
        app.totals.ftaxes={};
        //Fin seccion blanqueo de variables

        //Seccion descuentos y calculo de net
        if (app.disciva) {
          app.totals.net = app.totals.gross - (app.totals.gross*app.totals.pdiscount/100);
        }
        if (!app.disciva) {
          app.totals.net = app.totals.prices - (app.totals.prices*app.totals.pdiscount/100);
        }
        app.totals.ftaxes= Object.assign({},app.totals.taxes);
        for (var key in app.totals.ftaxes) {
          app.totals.ftaxes[key] = app.totals.ftaxes[key] - (app.totals.ftaxes[key]*app.totals.pdiscount/100);
        }
        //Fin seccion descuentos y calculo de net

        //Seccion sumar iva y net en total
        if (app.disciva) {
          for (key in app.totals.ftaxes) {
            app.totals.total += app.totals.ftaxes[key];
          }
        }
        app.totals.total += app.totals.net;
        //Fin seccion sumar iva y net en total
        app.$emit('totalschanged',app.totals);
      },
      showInputDiscountRounded(){
        let inputd = this.inputdiscount;
        if(inputd !== undefined && inputd !== null){
        //   if (inputd.indexOf('%')>-1)
        //     return '% ' +this.round(parseFloat(inputd.replace('%','')),2);
          
          if (inputd.indexOf('$')>-1) 
            return '$ ' + this.round(parseFloat(inputd.replace('$','')),2);
        }

        return inputd;
      }
    }

  }
</script>

<style scoped>
@media (max-width: 576px) {
.txt-res {
  font-size: 15px;
}
}
</style>