<template>
<div>
  <b-navbar toggleable="lg" type="dark bnav">
    <b-navbar-brand href="#"><img width="120" src="@/assets/img/logo-qupro.png"></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <!-- COMPONENTES DEL NAVBAR-->
      <partial-menu/>
      <partial-usermenu/>
    </b-collapse>
  </b-navbar>
  <!--<SocialChat icon dir="rtl" :attendants="operadores">
      <p slot="header">Si tenes algun problema elegi uno de nuestros soportes para hablar por WhatsApp.</p>
      <template v-slot:button>
        <img
          src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
        >
      </template>
      <small slot="footer">Horario de atencion: 8am to 6pm</small>
    </SocialChat> -->
    <ErrorApi/>
    <SuccessApi/>
</div>
</template>

<script>
import PartialMenu from "./Partial-menu.vue";
import PartialUsermenu from "./Partial-usermenu.vue";
import { mapActions } from 'vuex'
import { SocialChat } from 'vue-social-chat'
import ErrorApi from '../global/Errores.vue'
import SuccessApi from '../global/Success.vue'

export default {
    components: {
      PartialMenu,
      PartialUsermenu,
      SocialChat,
      ErrorApi,
      SuccessApi
    },
    data: () => ({
      operadores: [
        {
          app: 'whatsapp',
          label: 'Soporte',
          name: 'Juan Francisco',
          number: '5492236936494',
          avatar: {
            src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
            alt: 'Juan Francisco avatar'
          }
        },
        // ...
      ]
    }),
    methods: {
      ...mapActions({
        getAuthUser: 'authUser/getAuthUser'
      }),
    },
    mounted() {
      this.getAuthUser();
    },
}
</script>

<style scoped>
  .bnav {
    background-color: #4477bd;
  }
</style>