import axios from '../services/index.js';

export default {
    async getAuthUser() {
        return new Promise((resolve) => {
            axios.get('/api/users/getAuthUser').then((response) => {
                resolve(response.data.data);
            })
        })  
    },
}
  