<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Puntos de venta</h4>
      </b-col>
    </div>

    <b-row>
      <b-col cols="md-12" class="d-flex justify-content-left my-2">
        <b-button variant="primary" @click="afipUpdate()">Actualizar datos Afip</b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="md-8">
        <b-table hover :items="salePoints" :fields="salePointfields" :busy="isBusy">
        <template #table-busy>
          <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(id)="data">
          <b-button-group v-if="canEdit(data.value)">
            <b-dropdown right variant="light">
              <b-dropdown-item><a href="javascript:;" @click="edit(data.value)">Editar</a></b-dropdown-item>
              <b-dropdown-item><a href="javascript:;" @click="deleteSalepoint(data.value)">Eliminar</a></b-dropdown-item>
            </b-dropdown>
          </b-button-group>
      
        </template>
        </b-table>
      </b-col>
      <b-col cols="md-4">
        <NuevoSalePoint :salePointId="salePointId" :branchoffices="branchoffices" v-on:saved="refresh" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import NuevoSalePoint from './Nuevo-punto-de-venta.vue';

export default {
  components: {
    NuevoSalePoint,
  },
  data: function () {
    return {
      salePointId:null,
      salePoints:[],
      branchoffices:[],
      loading: false,
      isBusy:false,
      salePointfields: [
          {
            key: 'number',
            label: 'Numero',
            sortable: false
          },
          {
            key: 'branchoffice.name',
            label: 'Sucursal',
            sortable: true,
          },
          {
            key: 'type',
            label: 'Tipo',
            sortable: true
          },

          {
            key: 'editable',
            label: 'Tipo de numeración',
            sortable: true,
            formatter: value => {
              return value == 1 ? "Editable" : "Automática"
            }
          },
          {
            key: 'actived',
            label: 'Estado',
            sortable: true,
            formatter: value => {
              return value == 1 ? "Habilitado" : "Deshabilitado"
            }
          },
          {
            key: 'id',
            label: 'Acciones',
            sortable: false
          },
      ],
    };
  },
  mounted(){
    this.getSalePoints();
  },
  computed: {
  },
  methods: {
    canEdit(id) {
      return this.salePoints.filter(sp => sp.id == id )[0].type != 'ELEC';
    },
    refresh() {
      this.salePointId = null;
      this.getSalePoints();
    },
    getSalePoints() {
      var vm = this;
      vm.isLoading = true
      this.$http.get("/api/configs/getSalesPoints").then((response) => {
        vm.salePoints = response.data.data.salepoints;
        vm.branchoffices = response.data.data.branchoffices;
        vm.isLoading = false    
      });
    },
    edit(salePointId){
      this.salePointId = salePointId;
    },
    deleteSalepoint(salePointId) {
      if (confirm("Esta seguro que desea borrar este Punto de venta?") == true) {
        this.isLoading = true
        this.$http
          .post("/api/configs/pos/delete", { id: salePointId })
          .then((response) => {
            
            if (response.data.success) {
              this.refresh();
            }
          });
      }
    },
    afipUpdate(){
      var vm = this;
      vm.isLoading = true
      this.$http.post("/api/configs/pos/afip").then((response) => {
        this.refresh();
      });
    }
  },
}
</script>