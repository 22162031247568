<template>
  <branch-offices 
    v-if="loaded"
    :branchoffices="branchoffices" 
    :priceslists="priceslists" 
    :funds="funds" 
    />
</template>


<script>
import BranchOffices from '../../branch-offices/Branch-offices.vue'

export default {
  components: {
    BranchOffices
  },
  data: function() {
    return {
      loaded: false,
      data: null,
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    branchoffices() {
      return this.data ? this.data.branchoffices : [];
    },
    priceslists(){
      return this.data ? this.data.priceslists : [];
    },
    funds(){
      return this.data ? this.data.funds : [];
    },
  },
  methods: {
    getData(){
      this.$http
        .get('/api/configs/branchoffice/getData', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          this.data = response.data.data;
          this.loaded = true;
        })
    }
  }
}
</script>