import axios from '../../services/index.js';

export const getBilling = ({ commit }) => {
  commit('setLoading', true);
  axios.get('/api/billing')
    .then(response => {
      commit('setCompany', response.data.company)
      commit('setPayments', response.data.payments)
      commit('setUrl', response.data.url)
      commit('setPrice', response.data.price)
  }).catch(e => {
    commit('setPayments', {})
  })
  .finally(() => {
    commit('setLoading', false);
  });
}
