<script>
import {Pie, mixins } from 'vue-chartjs'
export default {
    extends: Pie,
    props: ['config'],
    data () {
      return {
        chartData: {
          labels: [],
          datasets: []
        },
        options: {
          legend: {
            display: true,
            fullWidth: true,
            align: 'start',
            position:'left',
            labels:{
                boxWidth: 20,
                fontSize: 16,
                padding:15,
                generateLabels: function(chart) {
                    var data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                        return data.labels.map(function(label, i) {
                            var meta = chart.getDatasetMeta(0);
                            var ds = data.datasets[0];
                            var arc = meta.data[i];
                            var custom = arc && arc.custom || {};
                            var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                            var arcOpts = chart.options.elements.arc;
                            var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                            var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                            var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);
                            var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];
                            return {
                                text: label + " ( " + value +'% )',
                                fillStyle: fill,
                                strokeStyle: stroke,
                                lineWidth: bw,
                                hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                                index: i
                            };
                        });
                    } else {
                        return [];
                    }
                }
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            enabled:true,
            xPadding:5,
            yPadding:5,
            callbacks: {
                label: function(tooltipItem, data) {
                    return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
                    }
                }
          },
          plugins: {
            datalabels: {
                display:true,
                color: '#ffffff',
                backgroundColor:'#111111',
                borderRadius:5,
                formatter: function (value) {
                    return value + '%';
                },
                font: {
                    weight:'bold',
                    size: 16,
                }
            }
          }
        }
      }
    },
    mounted () {
        this.updateData();
    },
    watch: {
        'config':{
            deep: true,
            handler(){this.updateData()}
        }
    },
    methods: {
        updateData(){
            let size = this.config.labels.length;

            let brColor =[];
            for (var i=0;i<this.config.labels.length;i++){
                brColor[i]='#ffffff';
            }

            this.chartData.labels = this.config.labels;
            var set ={
                borderWidth: 0,
                borderColor: brColor,
                backgroundColor: this.config.backgroundColor,
                data: this.config.data
            };
            this.chartData.datasets = [set];
            this.renderChart(this.chartData, this.options)
        }
    },
  }
</script>
