<template>
  <div>
    <subnavbar-inventario></subnavbar-inventario>
    <router-view></router-view>
  </div>
</template>


<script>
import SubnavbarInventario from '../navbars/Subnavbar-inventario.vue'
export default {
  components: {
    SubnavbarInventario
  }
};
</script>