<template>
  <b-container class="mt-4">
      <b-row v-if="user.OWNER!=1">
        <b-col>
          <div class="alert alert-danger text-center">
            <h4>Solo el dueño de la cuenta puede pagar, ponte en contacto con el para que lo haga...</h4>
          </div>
        </b-col>
      </b-row>
      
      <b-row v-else>
        <b-col cols="sm-12">
            <div class="alert alert-danger text-center">
              <div v-if="company.expired">
                <h4>{{ message }}</h4>
              </div>
            <div v-else>
                <h4 v-if="company.last_week">Te queda menos de una semana para renovar la suscripción al sistema, te recomendamos abonar con tiempo</h4>
                <h4 v-else>Si desea puede abonar un mes de Qupro que se sumara al tiempo que ya tiene</h4>
              </div>
            </div>
        </b-col>
      </b-row>
        
      <b-row v-if="url!=''">
        <b-col>
          <form ref="myform">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Selección de plan</th>
                  <th class="text-center">Fecha Actual</th>
                  <th class="text-center">Fecha de expiración</th>
                  <th class="text-center">Boton de pago</th>
                  <th class="text-center">Fecha de extension</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">Un mes de Qupro</td>
                  <td class="text-center">{{ company.today }}</td>
                  <td class="text-center">{{ company.expdate_human }}</td>
                  <td class="text-center">
                    <a id="promo" :href="url" mp-mode="modal" name="MP-Checkout" class="btn btn-orange">${{ price }}</a>
                  </td>
                  <td class="text-center"><b>{{ company.expdate_extended_human }}</b></td>
                  <td></td>
                </tr>
            </tbody>
            </table>
          </form>
        </b-col>
      </b-row>

      <b-row v-if="payments.length>0">
        <b-col>
          <h3>Pagos registrados</h3>
            <b-table-simple small caption-top responsive>
            <b-thead>
                <b-tr>
                  <b-th class="text-center">Fecha de pago</b-th>
                  <b-th class="text-center">Descripción</b-th>
                  <b-th class="text-center">Monto</b-th>
                  <b-th class="text-center">Se extendio hasta la fecha</b-th>
                  <b-th></b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="payment in payments" :key="payment.id">
                <b-td class="text-center">{{ payment.created_at }}</b-td>
                <b-td class="text-center">{{ payment.description }}</b-td>
                <b-td class="text-center">{{ payment.amount }}</b-td>
                <b-td class="text-center">{{ payment.todate }}</b-td>
                <b-td></b-td>
              </b-tr>
            </b-tbody>
            </b-table-simple>
        </b-col>
      </b-row>
  </b-container>    
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
        user: 'user/showUser',
        company: 'billing/showCompany',
        payments: 'billing/showPayments',
        url: 'billing/showUrl',
        price: 'billing/showPrice'
    }),
    message() {
     let tmp = (this.company.trial==1) ? 'tiempo de prueba' : 'suscripción';
      return `Tu ${tmp} expiró el ${this.company.expdate_human} haz click en abonar un mes de Qupro`;
    },
  },
  methods: {
    ...mapActions({
      getBilling: 'billing/getBilling'
    }),
  },
  mounted() {
    this.getBilling();
  },
}

</script>
