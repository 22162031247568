<template>
    <b-container fluid>
        <div class="loading" v-if="loading">Cargando página&#8230;</div>
        <b-card title="Card Title" no-body class="mt-2">
            <b-card-header header-tag="nav">
                <b-nav class="tabulator">
                    <button class="col-md-4 act" @click="activeTab=0" v-bind:class="[{'tabDeactive': activeTab!=0}]">{{title}}</button>
                    <button class="col-md-4 act" @click="activeTab=1" v-bind:class="[{'tabDeactive': activeTab!=1}]">Productos relacionados</button>
                    <button class="col-md-4 act" v-if="config.ecommerce" v-show="product.ecom_active" @click="activeTab=2" v-bind:class="[{'tabDeactive': activeTab!=2}]">Opciones de e-commerce</button>
                </b-nav>
            </b-card-header>
            <!-- Datos de producto -->
            <b-card-body v-show="activeTab==0">
                <b-row>
                    <b-col cols="md-9" class="mt-4">
                        <b-row>
                            <b-col cols="md-6">
                                <b-form-group>
                                    <label class="control-label">Nombre: </label>
                                    <b-form-input v-model="product.name" class="form-control"/>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group>
                                    <label>Tipo IVA</label>
                                    <b-form-select v-model="product.iva" class="form-control">
                                        <option value="" selected>Sin IVA</option>
                                        <option value="2.5">IVA 2.5%</option>
                                        <option value="5">IVA 5%</option>
                                        <option value="10.5">IVA 10,5%</option>
                                        <option value="21" selected>IVA 21%</option>
                                        <option value="27">IVA 27%</option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group>
                                    <label class="control-label">Código de barras</label>
                                    <input v-model="product.barcode" @input="handleInput" class="form-control"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- ----------------------------- PROVEEDOR --------------------------------- -->
                            <b-col cols="md-6">
                                <b-form-group>
                                    <label class="control-label">Proveedor</label>
                                    <treeselect class="multiList" v-model="product.prov_linked"
                                                :options="providersList"
                                                :multiple="true"
                                                :flat="true"
                                                :autoSelectAncestors="true"
                                                :autoDeselectDescendants="true"
                                                :open-on-click="true"
                                                value-consists-of="ALL_WITH_INDETERMINATE"
                                                />
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <v-guard :permissions="['PRODUCTO_VER_PRECIO_COSTO']">
                                <label>Costo</label>
                                <b-form inline class="d-flex justify-content-between" @keydown.enter.prevent>
                                    <b-col v-if="!$permitsAny(['PRODUCTO_CAMBIAR_PRECIO_COSTO'])"><b-form-input disabled :value="product.base_price"/></b-col>
                                    <b-col v-else>
                                        <currency
                                            v-model.number="product.base_price"
                                            :currency="product.currency"
                                            :selective="true"
                                            v-on:change="changeCurrency"
                                            
                                        />
                                    </b-col>
                                </b-form>
                                </v-guard>
                            </b-col>
                            <b-col cols="md-3">
                                <label>Código interno</label>
                                <b-form inline>
                                    <b-input v-model="product.code"  @keydown.enter.prevent/>
                                    <b-button variant="primary" class="ml-4" @click="sugestCode()">Sugerir</b-button>
                                </b-form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- ----------------------------- TIPO --------------------------------- -->
                            <b-col cols="md-3">
                                <b-form-group>
                                    <label class="control-label">Tipo</label>
                                    <b-form-select v-model="product.type" class="form-control">
                                        <option value="PRODUCT" selected>PRODUCTO</option>
                                        <option value="SERVICE">SERVICIO</option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <div class="form-group">
                                    <label class="control-label">Unidades de medida</label>
                                    <b-form-select v-model="product.unit" class="form-control">
                                        <option value="U" selected>Unidad</option>
                                        <option value="Kg">Kilogramo</option>
                                        <option value="Tn">Tonelada</option>
                                    </b-form-select>
                                </div>
                            </b-col>
                            <b-col cols="md-3">
                                <label class="control-label">CMF</label>
                                <b-form inline @keydown.enter.prevent>
                                    <b-col>
                                        <currency
                                            v-model.number="product.exchange_rate"
                                            :currency="product.currency"
                                            :selective="true"
                                            v-on:change="changeCurrency"
                                        />
                                    </b-col>
                                </b-form>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group>
                                    <label class="control-label">Código de fábrica</label>
                                    <input v-model="product.manufacturer_code" class="form-control"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- --------------------------- CATEGORIAS -------------------------------------- -->
                            <b-col cols="md-6">
                                <b-form-group>
                                    <label class="control-label">Categorías</label>
                                    <treeselect class="multiList" v-model="product.cat_linked"
                                        :options="categoriesList"
                                        :multiple="true"
                                        :flat="true"
                                        :autoSelectAncestors="true"
                                        :autoDeselectDescendants="true"
                                        :open-on-click="true"
                                        value-consists-of="ALL_WITH_INDETERMINATE"
                                        />
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group>
                                    <label class="control-label">Marca</label>
                                    <b-form-select v-model="product.brand_id" class="form-control select-search">
                                        <option :value="null" selected>Ninguna</option>
                                        <option v-for="brand in brands" :key='brand.id' :value="brand.id">{{brand.name}}</option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group>
                                    <label class="control-label">Stock mínimo</label>
                                    <input v-model.number="product.stock_minimum" type="number" min="1" class="form-control">
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="md-3" v-if="initial">
                                <b-form-group>
                                    <label class="control-label">Stock inicial</label>
                                    <input v-model.number="product.stock_initial" type="number" min="0" class="form-control"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <!-- --------------------------- IMAGENES -------------------------------------- -->
                    <b-col cols="md-3 mt-2">
                        <h3 class="text-left"><b>Imagenes</b></h3>
                        <hr style="margin-top: 3px;margin-bottom:5px;"></hr>
                        <uploadimage :imagesprev="product.images" @imageschanged="setimages" @processing="(v) => disabledsave+=v"></uploadimage>
                        
                        <b-form inline>
                            <input v-model="product.editable" type="checkbox" class="md-check">
                            <label class="control-label ml-2">Permitir edición de precio en PdV</label>
                        </b-form>
                        <b-form inline class="mt-2" v-if="config.ecommerce">
                            <input v-model="product.ecom_active" type="checkbox" class="md-check">
                            <label class="control-label ml-2">Activar producto para E-commerce</label>
                        </b-form>
                    </b-col>
                </b-row>
                <hr>
                <!-- --------------------------- LISTAS DE PRECIO CABECERA -------------------------------------- -->
                <b-row>
                    <b-col cols="md-12" class="text-left">
                        <h2>Listas de precios</h2>
                        <hr>
                    </b-col>
                </b-row>
                <!-- --------------------------- LISTAS DE PRECIOS -------------------------------------- -->
                <b-row>
                    <b-col cols="3" class="text-left" v-for="price in pricesList" :key="price.id">
                        <label><b>{{price.name}} :</b></label>
                        <b-form inline @keydown.enter.prevent>
                            <b-row>
                                <b-col v-if="pricelistToEdit==price.id">
                                    <div>
                                        <input
                                        v-model.number="valueOverCost"
                                        type="number"
                                        class="form-control calculate"
                                        placeholder="%"
                                        step="0.1"/>
                                    </div>
                                    <b-button class="m-2" variant="primary" size="sm" @click="calculateIncrease(price)">Calcular</b-button>
                                    <b-button class="m-2" variant="outline-primary" size="sm" @click="cancelIncrease()">Cancelar</b-button>
                                </b-col>
                                <b-col v-else>
                                    <b-row>
                                        <b-col>
                                            <input
                                                :disabled="!$permitsAny(['PRODUCTO_CAMBIAR_PRECIOS'])"
                                                v-model.number="product.prices[price.id]"
                                                type="number"
                                                class="form-control calculate"
                                                step="0.01"
                                                required
                                                placeholder="$"
                                            />
                                        </b-col>
                                        <b-button variant="primary" size="sm" @click="setPricelistToEdit(price.id)">+%</b-button>
                                    </b-row>
                                    <b-row class="mt-2">
                                        <b-col>
                                            <span>Ganancia: {{getEarnignPercent(price.id)}} %</span><br>
                                            <span>Modificado: {{getLastUpdate(price.id)}}</span>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-col>
                </b-row>
                <!-- --------------------------- BTN GUARDAR -------------------------------------- -->
                <b-row class="my-3 text-right">
                    <b-col>
                        <hr>
                        <b-col>
                            <b-button type="submit" variant="orange" size="lg" :disabled="isDisabled" @click="prepareSend()">Guardar producto</b-button>
                        </b-col>
                    </b-col>
                </b-row>
            </b-card-body>
            <!-- ------------------------PRODUCTOS RELACIONADOS ------------------------ -->
            <!--
            <b-container>
                <b-row v-show="activeTab==1">                            
                    <b-col>
                        <b-form-group>
                            <label class="control-label">Producto:</label>
                            <h6>{{product.name}}</h6>
                        </b-form-group>
                        <b-form-group>
                            <label class="control-label">Cantidad máxima:</label>
                            <select v-model="product.name" class="form-control">
                                <option value="0" selected>0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                            </select>
                        </b-form-group>
                        <b-form-group>
                            <label class="control-label">Buscar productos a relacionar:</label>
                            <select v-model="product.related" class="form-control">
                                <option v-for="rel in related" :key='rel.id' :value="rel.id">{{rel.name}}</option>
                            </select>
                        </b-form-group>
                        <b-form-group>
                            <label class="control-label">Categoría:</label>
                            <select v-model="product.related" class="form-control">
                                <option v-for="rel in related" :key='rel.id' :value="rel.id">{{rel.name}}</option>
                            </select>
                        </b-form-group>
                        <b-form-group class="form-group col-md-3">
                            <label class="control-label">Marca:</label>
                            <select v-model="product.related" class="form-control">
                                <option v-for="rel in related" :key='rel.id' :value="rel.id">{{rel.name}}</option>
                            </select>
                        </b-form-group>
                        <b-form-group class="form-group col-md-3">
                            <label class="control-label">Acciones:</label>
                            <button class="form-control" @click="cleanProds()">
                                        <i class="fas fa-trash"></i> Limpiar lista
                            </button>
                        </b-form-group>                               
                        <b-col>
                            <b-table responsive>
                                <thead>
                                    <tr role="row" class="ts-header">
                                    <th class="col-sm-1">Orden</th> 
                                        <th class="col-sm-5">Producto</th>
                                        <th class="col-sm-2">Cod.Interno</th>
                                        <th class="col-sm-2">Creado</th>
                                        <th class="col-sm-2">Modificado</th>
                                        <th class="col-sm-1"></th>
                                    </tr>
                                </thead>
                            </b-table>
                            <div class="table-scroll t-prods">
                                <b-table style="width:100%;">
                                    <tbody>
                                        <tr role="row" v-for="prod in prodList" :key="prod.id">
                                            <td class="col-sm-1"></td> 
                                            <td class="col-sm-5">{{prod.name}}</td>
                                            <td class="col-sm-2">{{prod.code}}</td>
                                            <td class="col-sm-2">{{formatDate(prod.created_at)}}</td>
                                            <td class="col-sm-2">{{formatDate(prod.updated_at)}}</td>
                                            <td class="col-sm-1">
                                                <span @click="productRemove(prod.id)" class="glyphicon glyphicon-remove delete-product" aria-hidden="true" title="Remover"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </b-table>
                            </div>
                            <b-row style="margin-top:15px;">
                                <button type="submit" class="btn btn-lg btn-orange pull-right" @click="saveRelated()">Guardar</button>
                            </b-row>
                        </b-col>
                    </b-col>
                </b-row>
            </b-container>  
        -->        
                <!-- Activo para E-commerce -->
            <b-card-body fluid v-if="config.ecommerce" v-show="activeTab==2">
                <!-- ------------------------ DESCRIPCION  ------------------------ -->
                <b-row>
                    <b-col>
                        <label class="control-label">Descripcion</label>
                        <textarea rows="3" class="form-control" v-model="product.description"></textarea>
                    </b-col>
                </b-row>
                <!-- ------------------------ATRIBUTOS ------------------------ -->
                <hr style="margin-top: 10px;margin-bottom:5px;">
                <b-row>
                    <b-col>
                        <label class="control-label">Atributos</label>
                        <hr style="margin-top: 2px;margin-bottom:2px;">
                        <b-row>
                            <b-col>
                                <a @click="openAttsModal" class="link">Asignar atributos  >></a>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table responsive>
                                    <table style="width:100%;">
                                        <tbody>
                                            <tr role="row" v-for="(group, index) in attGroups" :key="index">
                                                <td class="col-sm-2"><label>{{group.name}}</label></td>
                                                <td class="col-sm-8">
                                                    <span v-for="att in group.attributes" :key="att.id">
                                                        <span v-if="group.class=='color'" class="dot" :style="getHexa(att.data)" :title="att.name"/>
                                                        <span v-if="group.class=='tag'" class="tag">{{att.name}}</span>
                                                        <span v-if="group.class=='list'"> ({{att.name}}) </span>
                                                    </span>
                                                </td>
                                                <td class="col-sm-1">
                                                    <i class="glyphicon glyphicon-remove link" @click="attGroupRemove(group.id)"/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-table>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <hr style="margin-top: 5px;margin-bottom:5px;">
                <b-row>
                    <b-col>
                        <label class="control-label">Variantes</label>
                        <hr style="margin-top: 2px;margin-bottom:2px;">
                        <div v-if="variantList.length<1 && newVariants.length<1">
                            <b-col v-if="attGroups.length<1">
                                Asigne atributos al producto para comenzar a crear variantes.
                            </b-col>
                            <b-col v-else>
                                No hay variantes para este producto, <a @click="openVariantsModal" class="link">puede comenzar a añadirlas</a>
                            </b-col>
                        </div>
                        <b-row v-else>
                            <b-col>
                                <a @click="openVariantsModal" class="link">Agregar nueva variante  >></a>
                            </b-col>
                            <b-col v-if="variantList.length>0">
                                <label>Variantes existentes</label>
                                <b-table responsive>
                                    <tr>
                                        <th class="col-md-1">ID</th>
                                        <th class="col-md-7"><span>ATRIBUTOS</span></th>
                                        <th class="col-md-2"><span>STOCK</span></th>
                                        <th class="col-md-2"><span></span></th>
                                    </tr>
                                    <tr v-for="variant in variantList" :key='variant.id'>
                                        <td class="col-md-1">{{variant.id}}</td>
                                        <td class="col-md-7">
                                            <span>{{listAtts(variant.attList)}}</span>
                                        </td>
                                        <td class="col-md-2">
                                            <input type="number" class="form-text stockbox" v-model="variant.stock">
                                        </td>
                                        <td class="col-md-2 form-inline">
                                            <span @click="updateVariant(variant)" class="glyphicon glyphicon-ok-sign ok-payment-method" title="Guardar"></span>
                                            <span @click="deleteVariant(variant.id)" class="glyphicon glyphicon-minus-sign delete-product" title="Borrar"></span>
                                        </td>
                                    </tr>
                                </b-table>
                            </b-col>
                            <b-col v-if="newVariants.length>0">
                                <label>Nuevas variantes</label>
                                <table class="col-md-12 newVariantTable">
                                    <tr>
                                        <th class="col-md-9"><span>ATRIBUTOS</span></th>
                                        <th class="col-md-2"><span>STOCK</span></th>
                                        <th class="col-md-1"><span></span></th>
                                    </tr>
                                    <tr v-for="(variant,index) in newVariants" :key='index'>
                                        <td class="col-md-9">
                                            <span>{{listAtts(variant.attributes)}}</span>
                                        </td>
                                        <td class="col-md-2">
                                            <input type="number" class="form-text stockbox" v-model="variant.stock">
                                        </td>
                                        <td class="col-md-1">
                                            <span @click="deleteNewVar(variant)" class="glyphicon glyphicon-minus-sign delete-product" title="Borrar"></span>
                                        </td>
                                    </tr>
                                </table>
                            </b-col>
                        </b-row>
    
                        <variants v-if="showVariantModal"
                        :prod_id="productId"
                        :linked_atts="getLinkedAtts()"
                        :target="target"
                        v-on:closemodal="showVariantModal=false"
                        v-on:newVariant="variantNew"
                        v-on:reload="reloadVariants"
                        v-on:linkgroup="attGroupUpdate"
                        v-on:unlinkgroup="attGroupRemove"
                        v-on:updateAtts="attGroupUpdate"
                        />
                    </b-col>
                </b-row>
            </b-card-body>               
        </b-card>
    </b-container>
</template>

<script>
import Variants from './Attributes-modal.vue'
import { mapState } from 'vuex'
import currency from '../../global/CurrencyInput.vue';
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
import typeahead from '../../global/Typeahead.vue';
import moment from 'moment';
import uploadimage from "../../general/Upload-image.vue";

export default {
  props: ['productId'],
  components: {
      Multiselect,
      Treeselect,
      Variants,
      uploadimage,
      currency,
      typeahead
  },
  data() {
    return {
        config: {
            ecommerce: false
        },
        activeTab: 0,
        loading:true,
        showVariantModal:false,
        target:'all',
        disabledsave: 0,
        isDisabled: false,
        product: {
            id: null,
            name: '',
            code: null,
            barcode: null,
            stock_critical: 0,
            stock_minimum: 0,
            stock_initial: 0,
            unit: 'U',
            base_price: 0,
            iva: '21',
            prov_linked: [],
            type: 'PRODUCT',
            brand_id: null,
            prices: {},
            ecom_active: true,
            description:'',
            manufacturer_code:null,
            currency:'ARS',
            exchange_rate:0,
            cat_linked: [],
            var_linked:[],
            vars_new:[],
            images: [],
            setimagesid: [],
            atts_linked: [],
            editable:false,
            priceUpd:[]
        },
        filters:{
            qname:'',
            qcode:'',
            qbarcode:'',
            qmanufacturercode:'',
            qwarehouse: null,
            page:1,
            itemsPerPage:15,
            active:1,
            provider_id:null,
            brand_id:null,
            category_id:null,
            sorting:{
                col:'',
                order:'desc'
            }
        },
        variantList:[],
        newVariants:[],
        attGroups:[],
        title:'',
        initial:true,
        providersList:[],
        pricesList:[],
        categoriesList:[],
        pricelistToEdit:null,
        valueOverCost:0,
        productBarcode: {},
    }
  },
  created() {
    this.$store.dispatch('brands/getBrands')
  },
  mounted() {
      this.loadCategories();
      this.loadProviders();
      this.loadPriceLists();
      this.getEditConfig();
      if (this.productId!=null) {
          this.title="Editar producto";
          this.initial=false;
          this.loadVariants(this.productId);
          this.setForEdit(this.productId);
      } else {
          this.title="Nuevo producto";
          this.loading=false;
      }
  },
  methods: {
        setimages(value) {
            this.product.setimagesid = value;
        },
      getEditConfig(){
        let vm= this;
        this.$http.get("/api/products/getEditConfig")
          .then((response) => {
              if (response.status==200) {
                  vm.config = response.data.data;
              } else {
                  
              }
          }).catch((error) => {
              console.log('error: '+error);
          })
      },
      getHexa(field){
        let data = JSON.parse(field);
        return 'background-color: ' + data.code + ';';
      },
      openAttsModal(){
          this.target='all';
          this.showVariantModal=true;
      },
      openVariantsModal(){
          this.target='product';
          this.showVariantModal=true;
      },
      changeCurrency(coin){
          this.product.currency = coin;
      },
      prepareSend() {
          let $check=true;
          this.isDisabled = true;
          if(this.product.name==''){
              alert('El producto debe tener un nombre');
              return false;
          }
          if (Object.keys(this.product.prices).length === 0){
              alert('El producto debe tener valores en todas las listas de precios');
              return false;
          }
          Object.values(this.product.prices).forEach(val => {
              if (isNaN(parseFloat(val))){
                  alert('Los valores de las listas de precios deben ser numericos');
                  $check = false;
              }
          });
          if(this.product.barcode !== null){
                return this.getProductByBarcode(this.product.barcode);
            }
          if($check){
              this.updateProduct();
          }
        },
        handleInput() {
            if (!this.product.barcode) {
                this.product.barcode = null;
            }
        },
      getProductByBarcode(data){
            let vm = this;
            this.$http.get('api/products/getProductByBarcode/'+ data)
            .then((response) => {
                if (response.data.success == true) {
                    if(response.data.data.id == this.product.id){
                        this.updateProduct()
                    }else{
                        if (confirm("El codigo de barras ya esta en uso en "+response.data.data.name+", ¿Desea compartir el mismo codigo de barras para diferentes productos?") == true){
                            this.updateProduct();
                        }else{
                            return alert('Ingrese un codigo de barras que no se este utilizando');
                        }
                    }
                }else{
                    this.updateProduct();
                }
            });
        },
      updateProduct(){
          this.loading=true;
          let vm= this;
          vm.product.vars_new = vm.newVariants;
          vm.product.atts_linked = vm.attGroups;
          var url = this.productId == null? '/api/products/add':'/api/products/edit/'+this.productId;
          vm.$http.post(url,vm.product)
          .then((response) => {
            if (response.status==201) {
                window.location.reload();
            }
          }).catch((error) => {
            alert(error);
          })
        vm.loading=false;
      },
      listAtts(attributes){
          let text='';
          attributes.forEach(e =>{text = text + e.name + ' '});
          return text;
      },
      setForEdit(id) {
          let vm= this;
          axios.get("/api/products/view/"+id)
          .then((response) => {
            if (response.status==200) {
                // "The HeadObject operation requires non-empty parameter: Bucket" problema con amazon S3?
                let result = response.data.data;
                vm.product.ecom_active = result.ecom_active;
                vm.product.id= result.id;
                vm.product.name = result.name;
                vm.product.code=result.code;
                vm.product.barcode =  result.barcode;
                vm.product.stock_critical = result.stock_critical;
                vm.product.stock_minimum = result.stock_minimum,
                vm.product.unit = result.unit;
                vm.product.base_price = result.base_price;
                vm.product.iva = result.iva;
                vm.product.prov_linked = result.prov_linked;
                vm.product.type = result.type;
                vm.product.brand_id= result.brand_id;
                vm.product.manufacturer_code = result.manufacturer_code;
                vm.product.description = result.description;
                vm.product.currency = result.currency;
                vm.product.exchange_rate = result.exchange_rate;
                vm.product.editable = result.editable;

                vm.product.prices = result.prices;
                vm.product.priceUpd = result.priceUpd;
                vm.product.cat_linked = result.cat_linked;
                vm.product.var_linked= result.var_linked;
                vm.product.images = result.images ?? [];
                vm.product.setimagesid = vm.product.images.map((e) => e.id);

                if(result.attribute_types!=null){
                    vm.attGroups = result.attribute_types;
                }

                vm.loading=false;
            } else {
              
            }
          }).catch((error) => {
            console.log('error: '+error);
          })
      },
      variantNew(obj){
        this.newVariants.push(obj);
      },
      deleteNewVar(obj){
        this.newVariants.splice(obj);
     },
      deleteVariant(vid){
            this.variantList.splice(this.variantList.findIndex(function(i){return i.id===vid;}),1);
            this.product.var_linked = this.variantList.map(function(a){return a.id});
            alert('Variante asignada para eliminación. Guarde el producto para efectuar los cambios.');
        },
      updateVariant(obj){
          let vm= this;
          this.$http.post("/api/variants/updateVariant", obj)
            .then((response) => {
                if (response.status==200) {
                    vm.loadVariants();
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
        },
      loadProviders(){
          let vm= this;
          this.$http.get("/api/providers/getProviders")
            .then((response) => {
                if (response.status==200) {
                    vm.providersList = response.data.data.map(function (item) {
                        item.label = item.name
                        return item
                    });
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
      },
      loadPriceLists(){
          let vm= this;
          this.$http.get("/api/priceslist/getPriceList")
            .then((response) => {
                if (response.status==200) {
                    vm.pricesList = response.data.data;
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
      },
      loadCategories(){
          let vm= this;
          this.$http.get("/api/categories/getCategoryTree")
            .then((response) => {
                if (response.status==200) {
                    vm.categoriesList = response.data.data;
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
      },
      sugestCode(){
          let vm= this;
          vm.loading = true;
          this.$http.get("/api/products/newCode")
            .then((response) => {
                if (response.status==200) {
                    vm.product.code = response.data.data;
                    vm.loading = false;
                } else {
                    
                    vm.loading = false;
                }
            }).catch((error) => {
                console.log('error: '+error);
                vm.loading = false;
            })
      },
      attGroupChoosen(data) {
          var isInArray = this.product.attGroups.findIndex(function(i){ return i.id === data.id }) !== -1;
          if(!isInArray){
              this.attGroups.push(data);
          }
      },
      attGroupRemove(id) {
          this.attGroups.splice(this.attGroups.findIndex(function(i){return i.id === id;}), 1);
      },
      attGroupUpdate(data){
          console.log('data',data)
          this.showVariantModal=false;
          let groups = data;
          this.attGroups = groups;
      },
      getLinkedAtts(){
          let linked=[];
          this.attGroups.forEach(function (a) {
              a.attributes.forEach(function (b) {
                  linked.push(b);
              });
          });
          return linked;
      },
      loadVariants(){
          let vm= this;
          this.$http.get("/api/variants/getVariants/" + this.productId)
            .then((response) => {
                if (response.status==200) {
                    if(response.data.data){
                        vm.variantList = response.data.data;
                    }
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
      },
      reloadVariants(id){
          this.newVariants.forEach(e =>{
              e.attributes.splice(e.attributes.findIndex(function(i){return i.id === id;}), 1);
              }
          );
          this.newVariants.splice(this.newVariants.findIndex(function(i){return i.attributes.length===0;}));
          this.loadVariants();
      },
    setPricelistToEdit(id){
        this.pricelistToEdit = id;
        this.valueOverCost = this.getEarnignPercent(id);
    },
    getEarnignPercent(id){
        return (((this.product.prices[id]-this.product.base_price)*100)/this.product.base_price).toFixed(2);
    },
    getLastUpdate(id){
        if(moment(this.product.priceUpd[id]).isValid()){
            return moment(this.product.priceUpd[id]).format('DD/MM/YYYY');
        }else{
            return 'Nunca';
        }
    },
    calculateIncrease(pl){
        this.product.prices[pl.id] = this.product.base_price + ((this.product.base_price * this.valueOverCost)/100);
        this.valueOverCost=0;
        this.pricelistToEdit=null;
    },
    cancelIncrease(){
        this.valueOverCost=0;
        this.pricelistToEdit=null;
    },  
  },
  computed: {
    ...mapState('authUser',{
        authUser: state => state.authUser,
    }),
    ...mapState('brands',{
        brands: state => state.brands,
    })
  }

}
</script>

<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.act{
    font-weight: bold;
    border: none;
}

.tabulator{
    height: 30px;
    color: #111111;
    border: none;
}



.tabDeactive{
    color: darkgrey;
    border: none;
    font-weight: bold;
    background-color: #fff;
}

.inactive{
    color:darkgrey;
    pointer-events: none;
    border: none;
    background-color: #fff;
}

.table-scroll{
	overflow-y: auto;
    max-height:300px;
    background-color: #f2f4f7;
    border-radius: 5px;
}

.table-scroll tr {
   line-height: 25px;
   min-height: 25px;
   height: 25px;
   border:2px solid #d3d9e4;
}

.table-scroll td{
    border:2px solid #d3d9e4;
}

.tag {
  display: inline-block;
  min-width: 45px;
  line-height: 25px;
  height: 30px;
  text-align: center;
  padding: 2px;
  margin: 2px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 4px;
  color: #222222;
  border:1px solid #d3d9e4a1;
  background-color: #ffffff;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  border:1px solid #222222;
  margin-top: 5px;
}

.link{
    cursor: pointer;
}

.borderonly{
    border:1px solid #d3d9e4;
    border-radius: 5px;
    padding: 2px;
    margin: 10px;
}

.newVariantTable, .newVariantTable tr, .variantTable, .variantTable tr{
    width: 100%;
}

.newVariantTable td,.newVariantTable th, .variantTable td, .variantTable th{
    text-align: center;
    border-radius: 5px;
    margin:0px;
    height: 100%;
    font-weight: bold;
}

.newVariantTable td{
    background-color: #d3d9e4;
}

.newVariantTable th{
    background-color: #8392AF;
    color: whitesmoke;
}

.variantTable td{
    background-color: #d3e4d7;
}

.variantTable th{
    background-color: #83af87;
    color: whitesmoke;
}

.stockbox{
    max-width: 100px;
}

/* Absolute Center CSS Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>

