<template>
  <b-container fluid>
    <div class="row mt-3">
      <div class="col d-flex justify-content-start">
        <h4>
          Detalle remito de compra
        </h4>
      </div>
    </div>
    <!-- ------- DATOS REMITO COMPRA -------- -->
    <b-card class="bg-light d-flex justify-content-between mb-4 p-0">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
          <div v-if="shippingPurchase.letter != 'P'">                
            <h2 class="control-label">{{shippingPurchase.type}} {{shippingPurchase.letter}} Pto.Vta: {{shippingPurchase.pos}} Numero: {{shippingPurchase.number}}</h2>
          </div>
        <div v-else>
            <h2 class="control-label">Pto.Vta: {{shippingPurchase.pos}} Numero: {{shippingPurchase.number}}</h2>
          </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <h2 class="control-label">Proveedor: {{shippingPurchase.provider.name}} </h2>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <h2 class="control-label">Fecha: {{ createdDateFormatted }}</h2>
          </div>
        </div>
      </div>

      <div class="row" v-if="shippingPurchase.response != null">
        <div class="col-sm-4">
          <div class="form-group">
            <label class="control-label">CAE: {{shippingPurchase.response['CAE']}}</label>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label class="control-label">CAE Fech. Venc.: {{date('d/m/Y', strtotime(shippingPurchase.response['CAEFchVto']))}}</label>
          </div>
        </div>
      </div>
    </b-card>
    <!-- ------ TABLA ------ -->
    <div class="row">
      <div class="col-md-8">
        <table id="invoice_table" class="table">
          <thead>
            <tr>
              <th> Item </th>
              <th class="col-small"> Cantidad </th>
            </tr>
          </thead>
          <tbody>
            
            <tr 
            v-for="value in shippingPurchase.shipping_note_details"
                :key="value.shippingpurchase_id"
                role="row"
                class="add">
              <td>{{value.description}}</td>
              <td>{{value.quantity}}</td>
            </tr>
            
          </tbody>
        </table>    
      </div>
      <div v-if="shippingPurchase.notes !=''" class="col-md-4 form-group">
        <label>Nota del proveedor</label>
        <textarea v-model="shippingPurchase.notes" disabled="disabled" name="note" rows="3" class="form-control"></textarea>
      </div>   
    </div>

    <b-row v-if="pdfUrl" aling-h="center">
      <b-col class="mt-3">
        <b-button variant="orange" size="lg" onclick="frames['frame'].print()">Imprimir comprobante</b-button>
      </b-col>
      <iframe :src="pdfUrl" style="display:none;" name="frame"></iframe>
    </b-row>
  </b-container>
</template>

<script>
import getPdfmixin from "../../../mixins/getPdfmixin";

export default {
  props: ["shippingPurchaseId"],
  mixins: [getPdfmixin],
  data: function() {
    return {
      pdfUrl: null,
      shippingPurchase: {
        letter: null,
        pos: null,
        number: null,
        provider_id: null,
        subtotal: null,
        discount: null,
        taxes: null,
        total: null,
        shipping_note_details: [{
          shipping_purchase_id: null,
          description: null,
          quantity: null,
          price: null
        }],
        provider: {
          name: null
        }
      }
    }
  },
  computed: {
    createdDateFormatted() {
      return new Date(this.shippingPurchase.created_at).toLocaleDateString('es-AR')
    }
  },
  mounted() {
    this.getShippingPurchaseDetail(this.shippingPurchaseId);
    //this.getPdf(process.env.VUE_APP_BASE_URL+'/shippingPurchase/spPDF/' + this.shippingPurchaseId).then((res) => {this.pdfUrl = URL.createObjectURL(res);})
  },
  methods: {
    getShippingPurchaseDetail(shippingPurchaseId){
        var vm= this;
        axios.get('/api/shippingpurchases/detail/' + shippingPurchaseId)
          .then(response => {
            if(response.data.success) {
                vm.shippingPurchase = response.data.data;                
            }
        })
        .catch(error => {
            alert(error);
        })
    },
  }
};
</script>
