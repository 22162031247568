
export default {
  methods: {
    getPdf(url) {
        return this.$http.get(url, { responseType: 'blob' })
                .then((response) => {
                  return new Blob([response.data], {type: 'application/pdf'});;
                })
                .catch((error) => {
                  console.log(error);
                });
        }
  },
}