<template>
    <b-container fluid>
        <div class="row mt-3">
            <b-col class="d-flex justify-content-start">
                <h4>Usuarios de la tienda online</h4>
            </b-col>
        </div>
        <!-- ----------------------- FORMULARIO -------------------------- -->
        <b-row class="d-flex justify-content-between">
            <!-- -------------------- BUSCADOR -------------------------- -->
            <b-col cols="md-2 text-left">
                <input type="search" class="form-control input-sm" 
                v-model="filters.q"
                placeholder="Buscar...">
            </b-col>
            <!-- -------------------- FILTRO MOSTRAR ------------------- -->
            <b-col cols="md-2 text-left mt-3">
                <b-form inline>
                    <label>Mostrar
                        <select class="form-control input-sm ml-2" v-model="filters.itemsPerPage">
                            <option :value="15">15</option>
                            <option :value="25" selected>25</option>
                            <option :value="50">50</option>
                        </select>
                    </label>
                </b-form>
            </b-col>
        </b-row>
        <!-- ----------------------- TABLA -------------------------- -->
        <b-row>
            <b-col cols="sm-12">
                <b-table-simple sticky-header striped small caption-top responsive style="max-height: 900px">
                    <b-thead head-variant="light">
                        <b-tr role="row" class="ts-header">
                            <b-th class="col-sm-1">Fecha</b-th>
                            <b-th class="col-sm-3">Usuario</b-th>
                            <b-th class="col-sm-3">Email</b-th>
                            <b-th class="col-sm-3">Cliente</b-th>
                            <b-th class="col-sm-1">Estado</b-th>
                            <b-th class="col-sm-1"></b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr role="row" v-for="user in users" :key="user.id">
                            <b-td class="col-sm-1">{{user.created_at}}</b-td>
                            <b-td class="col-sm-3">{{user.name}}</b-td>
                            <b-td class="col-sm-3">{{user.email}}</b-td>
                            <b-td class="col-sm-3">
                                <span v-if="user.client==null">Sin asociar</span>
                                <span v-else><label><a>{{user.client.name}}</a></label></span>
                            </b-td>
                            <b-td class="col-sm-1">
                                <b-icon-check-square-fill style="width: 18px; height: 18px; color: green;" v-if="user.active"></b-icon-check-square-fill>
                                <b-icon-slash-circle-fill style="width: 18px; height: 18px; color: red;" v-else></b-icon-slash-circle-fill>
                            </b-td>
                            <b-td>
                                <b-button-group>
                                    <b-dropdown right variant="light">
                                        <b-dropdown-item>
                                            <a v-if="user.active" v-on:click="toggleActive(user)">Bloquear acceso</a>
                                            <a v-else v-on:click="toggleActive(user)">Habilitar acceso</a>
                                        </b-dropdown-item>
                                        <b-dropdown-item>
                                            <a v-if="user.client==null" v-on:click="openClientModal(user)">Asignar cliente</a>
                                            <a v-else v-on:click="openClientModal(user)">Reasignar cliente</a>
                                        </b-dropdown-item>
                                        <b-dropdown-item>
                                            <a v-on:click="deleteUser(user.id)">Eliminar</a>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-button-group>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <!-- PAGINACION -->
        <div class="row vdtnet-footer d-flex align-items-center">
            <div class="col-md-2 d-flex align-items-center">
                <label class="mr-2 mb-0">Mostrar: </label>
                <b-form-group class="mb-0">
                    <b-form-select v-model="filters.itemsPerPage" style="width: 60px;">
                        <option :value="15" selected="selected">15</option>
                        <option :value="25">25</option>
                        <option :value="50">50</option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col-md-8">
                <div class="dataTables_paginate paging_simple_numbers">
                <ul class="pagination">
                    <li class="page-item" v-bind:class="[{'disabled': filters.page==1}]">
                    <a class="page-link" href="#" v-on:click="prevPage()">Anterior</a>
                    </li>
                    <li v-if="(filters.page - (pageInterval/2))>0" class="page-item">
                    <a class="page-link" href="#" v-on:click="prevPage()">...</a>
                    </li>
                    <template v-for="index in totalPages">
                    <li :key="index" v-if="inIntervalPage(index)" class="page-item" v-bind:class="[{'active': filters.page==index}]">
                        <a class="page-link" href="#" v-on:click="changePage(index)">{{index}}</a>
                    </li>
                    </template>
                    <li v-if="totalPages>(filters.page + (pageInterval/2))" class="page-item">
                    <a class="page-link" href="#" v-on:click="nextPage()">...</a>
                    </li>
                    <li class="page-item" v-bind:class="[{'disabled': filters.page>=totalPages}]">
                    <a class="page-link" href="#" v-on:click="nextPage()">Siguiente</a>
                    </li>
                </ul>
                </div>
            </div>
        </div>
        <!-- ----------------------- MODAL RESAIGNAR  -------------------------- -->
        <transition v-if="clientModal" name="modal">
            <div class="modal-sm-mask">
                <div class="modal-sm-wrapper">
                    <div class="modal-sm-container">
                        <div class="modal-sm-body">
                            <slot name="body" v-if="clients">
                                <b-row>
                                    <b-col cols="md-6">
                                        <Multiselect
                                        v-model="selectedClient"
                                        :options="clients"
                                        :searchable="true"
                                        label="name"
                                        track-by="id"
                                        placeholder="Asignar cliente"
                                        :allow-empty="true"
                                        :close-on-select="true"
                                        />
                                    </b-col>
                                    <b-col cols="md-6">
                                        <b-button variant="primary" @click="searchMail()">
                                            Buscar por mail de usuario
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col cols="md-6">
                                        <input type="email" class="form-control" :value="mailText" disabled>
                                    </b-col>
                                        <b-row>
                                            <b-col>
                                                <b-button variant="primary" @click="saveClient()">
                                                    <b-icon icon="save"></b-icon>
                                                </b-button>
                                            </b-col>
                                            <b-col>
                                                <b-button variant="primary" @click="cleanClient()">
                                                    <b-icon icon="trash"></b-icon>
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    <b-col>
                                        <b-button variant="primary" @click="closeClientModal()">
                                            Cancelar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

    </b-container>  
</template>
<script>
import Multiselect from 'vue-multiselect';
import VdtnetTable from "vue-datatables-net";
export default {
  components: {Multiselect, VdtnetTable},
  data() {
    return {
        users:[],
        filters:{
            q:'',
            itemsPerPage:15,
            page:1,
        },
        totalPages:0,
        pageInterval:22,
        clientModal:false,
        clients:[],
        userToEdit:null,
        selectedClient:null,
        mailText:'Sin asignar',
    }
  },
  mounted() {
      this.getClients();
      this.getUsers();
  },
  methods: {
    getUsers(){
        let vm = this;
        this.$http.post("/api/ecom/users/getUsers", this.filters)
            .then((response) => {
                if (response.data.success) {
                    vm.users = response.data.data.list;
                    vm.totalPages=response.data.data.totalPages;
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
        })
    },
    getClients(){
        let vm = this;
        this.$http.get("/api/clients/getMailList")
            .then((response) => {
                if (response.data.success) {
                    vm.clients = response.data.data;
                }
            }).catch((error) => {
                console.log('error: '+error);
        })
    },
    toggleActive(user){
        let message=(user.active) ? 'Confirme si desea DESACTIVAR el logueo de este usuario' : 'Confirme si desea PERMITIR el acceso a este usuario';
        if(confirm(message)==true){
            let vm = this;
            this.$http.get("/api/ecom/users/toggleActive/" + user.id)
            .then((response) => {
                
                vm.getUsers();
            }).catch((error) => {
                console.log('error: '+error);
            })
        }
    },
    openClientModal(user){
        this.userToEdit=user;
        if(user.client!==null){
            let client =  this.clients.filter(e => e.id === user.client.id);
            this.selectedClient = client[0];
        }
        this.clientModal=true;
    },
    closeClientModal(){
        this.userToEdit=null;
        this.selectedClient=null;
        this.mailText='Sin asignar';
        this.clientModal=false;
    },
    searchMail(){
        let result =  this.clients.filter(e => e.email === this.userToEdit.email);
        if(result.length===1){
            this.selectedClient= result[0];
            this.mailText = result[0].email;
        }else{
            this.mailText='Mail no encontrado';
        }
    },
    cleanClient(){
        this.selectedClient=null;
        this.mailText='Sin asignar';
    },
    saveClient(){
        let vm = this;
        let outdata={
            'user' : vm.userToEdit.id,
            'client' : (vm.selectedClient!==null) ? vm.selectedClient.id : null,
        };
        this.$http.post("/api/ecom/users/setClient", outdata)
            .then((response) => {
                if (response.data.success) {
                    
                    vm.closeClientModal();
                    vm.getUsers();
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
        })
    },
    deleteUser(id){
        if(confirm('Esta acción eliminará el registro PERMANENTEMENTE, ¿Desea continuar?')==true){
            let vm=this;
            this.$http.get("/api/ecom/users/deleteUser/" + id)
            .then((response) => {
                
                vm.getUsers();
            }).catch((error) => {
                console.log('error: '+error);
            })
        }
    },
    prevPage(){
        if(this.filters.page>1){
            this.page = this.page-1;
            this.getUsers();
        }
    },
    nextPage(){
        if (this.filters.page<this.totalPages){
            this.page = this.page +1;
            this.getUsers();
        }
    },
    changePage(data){
        this.page = data;
        this.getUsers();
    },
    inIntervalPage(index){
        if(this.totalPages<=this.pageInterval){return true;}

        let limitprev = this.filters.page - (this.pageInterval/2);
        let limitnext = this.filters.page + (this.pageInterval/2);

        if(limitprev<0){
            limitprev=0;
            limitnext=this.pageInterval;
        }

        if(index>limitprev && index<limitnext){
            return true;
        }

        return false;
    },
  },
  watch: {
      'filters.q': {handler: function (a) {this.getUsers();}},
      'filters.itemsPerPage': {handler: function (a) {this.getUsers();}},
      'selectedClient':{handler: function(a){this.mailText=a.email}}
  },
}
</script>

<style lang="css">

.modal-sm-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-sm-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-sm-container {
    width: 50vw;
    margin: 0px auto;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    height: 150px;
    padding-top: 25px;
    padding-left: 50px;
}

.modal-sm-header {
    display: flex;
    align-items: center;
}

.modal-sm-enter {
    opacity: 0;
}

.modal-sm-leave-active {
    opacity: 0;
}

.footer{
    display: flex;
    justify-content: flex-end;
    gap: 30px;
}

.modal-sm-enter .modal-sm-container,
.modal-sm-leave-active .modal-sm-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
