
export const clearSuccess = ({ commit }) => {
  try{
  commit('clearSuccess')
  commit('setLoading', true);
  }
  finally {
    setTimeout(() => {
      commit('setLoading', false)
    }, 1000)
  }
}