import { render, staticRenderFns } from "./iva-ventas.vue?vue&type=template&id=b6bb72c8&scoped=true"
import script from "./iva-ventas.vue?vue&type=script&lang=js"
export * from "./iva-ventas.vue?vue&type=script&lang=js"
import style0 from "./iva-ventas.vue?vue&type=style&index=0&id=b6bb72c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6bb72c8",
  null
  
)

export default component.exports