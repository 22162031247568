<template>
  <transition name="modal">
    <div class="modal-mask-product">
      <div class="modal-dialog">
        <div class="modal-content p-3 rounded">
          <div class="modal-header">
            <h4 v-if="client.id == null">Nuevo cliente</h4>
            <h4 v-else>Editar cliente</h4>
            <b-button variant="primary size-sm" @click="close()"><i class="bi bi-x-lg"></i></b-button>
          </div>
          <div class="modal-body">
            <b-row>
                <div class="col-12 my-2">
                  <form v-if="client.id != null" class="form-inline" @submit.prevent>
                    <input v-model="client.active" id="active" type="checkbox">
                    <label class="ml-1" for="active" >Activo</label>
                  </form>
                </div>
                <!-- Nombre cliente -->
                <div class="col-12 my-2">
                  <label class="control-label">Nombre cliente:</label>
                  <b-form-input
                  class="form-control"
                  id="input-live"
                  v-model="client.name"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Nombre cliente..."
                  trim>
                  </b-form-input>
                </div>
                <!-- Razon social -->
                <div class="col-12 my-2">
                  <label class="control-label" for="input-live">Nombre razón social:</label>
                  <b-form-input
                  class="form-control"
                  id="input-live"
                  v-model="client.business"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Razon social..."
                  trim
                  ></b-form-input>
                </div>
                <!-- CUIT - DNI -->
                <div class="col-12 my-2">
                  <label class="control-label" for="input-live">CUIT / DNI:</label>
                  <b-form-input
                    class="form-control"
                    id="input-live"
                    v-model="client.cuit"
                    :state="codigoValido"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="CUIT / DNI..." 
                    trim>
                  </b-form-input>
                  <br>
                  <b-button :disabled="!client.cuit || client.cuit.length !== 11" variant="orange" size="lg" @click="getAfipClientData()">Obtener datos de AFIP</b-button>
                </div>
                <!-- Tipo IVA -->
                <div class="col-12 my-2">
                  <label class="control-label" for="input-live">Tipo de IVA:</label>
                  <b-form-select class="form-control" v-model="client.iva_type" :options="optionsUnit"></b-form-select>
                </div>
                <!-- Telefono -->
                <div class="col-12 my-2">
                  <label class="control-label" for="input-live">Teléfono:</label>
                  <b-form-input
                  id="input-live"
                  v-model="client.phone_1"
                  :state="codigoValido"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Telefono..."
                  trim
                  ></b-form-input>
                </div>
                <!-- Telefono celular -->
                <div class="col-12 my-2">
                    <label class="control-label" for="input-live">Teléfono celular:</label>
                      <b-form-input
                      class="form-control"
                          id="input-live"
                          v-model="client.phone_2"
                          :state="codigoValido"
                          aria-describedby="input-live-help input-live-feedback"
                          placeholder="Telefono celular..."
                          trim
                      ></b-form-input>
                </div>
                <!-- E-mail -->
                <div class="col-12 my-2">
                  <label class="control-label" for="input-live">E-Mail:</label>
                    <b-form-input
                    class="form-control"
                        id="input-live"
                        v-model="client.email"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="E-mail..."
                        trim>
                    </b-form-input>
                </div>
                <!-- Direccion -->  
                <div class="col-12 my-2">
                  <label class="control-label" for="input-live">Dirección:</label>
                  <b-form-input
                    class="form-control"
                    id="input-live"
                    v-model="client.address"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="Direccion..."
                    trim>
                  </b-form-input>
                </div>
                <!-- Provincia -->
                <div class="col-12 my-2">
                  <label class="control-label" for="input-live">Provincia:</label>
                    <b-form-input
                    class="form-control"
                        id="input-live"
                        v-model="client.province"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="Provincia..."
                        trim>
                    </b-form-input>
                </div>
                <!-- Localidad -->
                <div class="col-12 my-2">
                  <label class="control-label" for="input-live">Localidad:</label>
                    <b-form-input
                    class="form-control"
                        id="input-live"
                        v-model="client.city"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="Localidad..."
                        trim>
                    </b-form-input>
                </div>
                <!-- Codigo postal -->
                <div class="col-12 my-2">
                  <label class="control-label" for="input-live">Código Postal:</label>
                    <b-form-input
                    class="form-control"
                        id="input-live"
                        v-model="client.postalcode"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="Codigo postal..."
                        trim
                    ></b-form-input>
                </div>
                <!-- Lista de precio -->
                <div class="col-12 my-2">
                  <label class="control-label" for="input-live">Lista(s) de precios:</label>
                  <b-form-select class="form-control" v-model="client.price_list_id">
                    <option :value="null">Ninguno</option>
                    <option v-for="pricelist in pricelists" :key="pricelist.id" :value="pricelist.id">{{ pricelist.name }}</option>
                  </b-form-select>
                </div>
                <!-- Saldo inicial -->
                <div class="col-12 my-2" v-if="client.id == null">
                  <label class="control-label" for="input-live">Saldo inicial:</label>
                  <b-form-input
                  class="form-control"
                      id="input-live"
                      v-model="client.amount"
                      :state="codigoValido"
                      aria-describedby="input-live-help input-live-feedback"
                      trim
                  ></b-form-input>
                </div>
                <!-- Limite cuenta corriente -->
                <div class="col-12 my-2">
                  <label class="control-label"  for="input-live">Límite de C. Corriente:</label>
                  <b-form-input
                    v-model="client.limit"
                    class="form-control"
                    id="input-live"
                    type="number"
                    placeholder="0"
                    :state="codigoValido"
                    aria-describedby="input-live-help input-live-feedback"
                    trim>
                  </b-form-input>
                </div>
                <!-- Defcto cuenta corriente checkbox -->
                <div class="col-12 my-2">
                  <form v-if="paymentMethodCC === 1" class="form-inline">
                    <input v-model="client.cc_only" id="cc_only" type="checkbox">
                    <label class="ml-1" for="cc_only" >Vender por defecto a Cuenta Corriente</label>
                  </form>
                </div>
                <!-- Vendedor asociado -->
                <div class="col-12 my-2" v-if="salesclerkModule === 1">
                  <label class="control-label" for="input-live">Vendedor asociado:</label>
                  <b-form-select class="form-control" v-model="client.salesclerk_id">
                    <option :value="null">Ninguno</option>
                    <option v-for="clerk in salesClerks" :key="clerk.id" :value="clerk.id">{{ clerk.name }}</option>
                  </b-form-select>
                </div>
                <!-- Desc % -->
                <div class="col-12 my-2">
                    <label class="control-label">Descuento en %</label>
                    <input v-model="client.discount" name="discount" type="number" step="0.01" class="form-control" placeholder="0.00 %">
                </div>
                <!-- Notas -->
                <div class="col-12 my-2">
                  <label class="control-label" for="input-live">Notas:</label>
                  <textarea v-model="client.notes" name="notes" class="form-control" rows="2"></textarea>
                </div>
                <!-- Transporte -->
                <div class="col-12 my-2">
                  <SelectTransports
                    :initialTransports="client.t_linked"
                    @updateTransports="updateTransports"
                  /> 
                </div>
            </b-row>
          </div>
          <!-- FOOTER BOTON -->
          <div class="modal-footer">
            <b-button class=" mr-5" variant="outline-primary" size="lg" @click="close">
              Cancelar
            </b-button>
            <a @click="saveClient()">  
            <b-button variant="orange" size="lg" data-toggle="tab">
                {{ btnmessage }}
              </b-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
import SelectTransports from "./Select-transports.vue";
import { mapState } from 'vuex';

  export default {
    components: { SelectTransports },
    props: ['clientId'],        
    data() {
      return {
        hasPermission: false,
        salesclerkModule: 0,
        paymentMethodCC: 0,
        btnmessage: "Agregar Cliente",
        client: {
          id: null,
          name: "",
          code: null,
          address: null,
          postalcode: null,
          phone_1: null,
          phone_2: null,
          email: null,
          cuit: null,
          iva_type: null,
          business: null,
          discount: 0,
          limit: null,
          cc_only: null,
          city: null,
          province: null,
          price_list_id: null,
          salesclerk_id: null,
          amount: null,
          t_list: null,
          notes: null,
          active:1
        },

        emptyClient: {
          id: null,
          name: "",
          code: null,
          address: null,
          postalcode: null,
          phone_1: null,
          phone_2: null,
          email: null,
          cuit: null,
          iva_type: "Consumidor Final",
          business: null,
          discount: 0,
          limit: null,
          cc_only: null,
          city: null,
          province: null,
          price_list_id: 1,
          salesclerk_id: null,
          amount: null,
          t_list: null,
          notes: null,
          active:1
        },
        
        clients: [],
        pricelists: [],
        optionsUnit: [
          { value: 'Consumidor Final', text: 'Consumidor Final' },
          { value: 'Responsable Inscripto', text: 'Responsable Inscripto' },
          { value: 'Exento', text: 'Exento' },
          { value: 'Monotributo', text: 'Monotributo' },
        ]
      }
    },
    mounted() {
      this.clientIdExist();
      this.getPriceLists();
      this.resetForm();
      this.getAuthUser();
    },
    created() {
     this.$store.dispatch('salesClerks/getSalesclerks')
    },
    methods: {
      clientIdExist(){
        if (this.clientId != null) {
            let id = this.clientId;
            this.getClient(id);
            this.btnmessage="Editar Cliente";
          } else {
            this.btnmessage="Agregar Cliente";
            this.resetForm();
          }
        },
      close() {
        this.$emit('close');
      },
      getClient(id) {
        var vm = this;
        this.$http.get('/api/clients/getClient/' + id).then((response) => {
          vm.client = response.data.data
        })
      },
      getPriceLists() {
        console.log(this.clientId);
        var vm = this;
        this.$http.get('/api/priceslist/getPriceList').then((response) => {
          vm.pricelists = response.data.data
        })
      },
      saveClient() {
        var vm = this;
        if (this.client.id != null) {
          this.$http.post('/api/clients/edit/' + this.client.id, this.client).then((response) => {
            
            vm.resetForm();
            vm.$emit('confirmsave');
            vm.$emit('close');
          })
        } else {
          this.$http.post('/api/clients/add', this.client).then((response) => {
            vm.resetForm();
            vm.$emit('confirmsave');
            vm.$emit('close');
          })
        }       
      },
      resetForm() {
        this.client = Object.assign({}, this.emptyClient);
      },
      getAuthUser() {
        this.$http
          .get("/api/users/getAuthUser", {
            headers: { 
              Authorization: "Bearer " + localStorage.getItem("token")
            },
          })
          .then((response) => {
            this.hasPermission = response.data.data.permissions.includes('ACCESS_ALL_BRANCHOFFICES_SALES');
            this.salesclerkModule = response.data.data.branchoffices[0].options.salesclerk_module;
            this.paymentMethodCC = response.data.data.branchoffices[0].payments_methods.cuenta_corriente;
          })
      },
      updateTransports(t_list){
        this.client.t_list= t_list;
      },
      getAfipClientData() {
        this.$http.get('/api/clients/getAfipClientData/' + this.client.cuit).then((response) => {
          if(response.data.success != true) return;
          var afipData= response.data.data;
          console.log(afipData);
          this.client.cuit = afipData.cuit;
          this.client.name = afipData.nombre;
          this.client.business = afipData.razonSocial;
          this.client.address = afipData.domicilios[0].direccion;
          this.client.city = afipData.domicilios[0].localidad;
          this.client.province = afipData.domicilios[0].descripcionProvincia;
          this.client.postalcode = afipData.domicilios[0].codigoPostal;

          if(afipData.tipoPersona === "FISICA") {
            this.client.iva_type = 'Monotributo';
          } else {
            this.client.iva_type = 'Responsable Inscripto';
          }

          console.log(this.client);

        })
      }
    },    
    computed: {
      codigoValido() {
          return isNaN(this.client.code) ? false : null
      },
      ...mapState('salesClerks',{
        salesClerks: state => state.salesClerks,
      })
    }
  }
</script>

<style scoped>

.modal-mask-product {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow:hidden;
}

.modal-body {
  max-height: 70vh; /* Ajusta esto según tus necesidades */
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>