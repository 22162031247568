<template>
  <b-container fluid>
    <div class="loading" v-if="isLoading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Tarjetas</h4>
      </b-col>
    </div>
    <b-form inline @submit.stop.prevent="doSearch" class="mt-2">
      <div class="input-group">
        <input
          v-model="doSearch"
          type="search"
          placeholder="Buscar..."
          class="form-control"
        >
      </div>
    </b-form>
    <b-row>
      <!-- TABLA -->
      <b-col cols="md-8">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          @edit="getCard"
          @delete="deleteCard"
          ref="table"
        />
      </b-col>
      <!-- EDICION -->
      <b-col cols="md-4 my-2">
        <div class="card mb-4"> 
          <b-button variant="primary size-sm" v-if="card.id != null" @click="newCard()">
            Nueva Tarjeta
          </b-button>
          <b-card-header>
            <h6 v-if="card.id == null">
              Nueva Tarjeta
            </h6>
            <h4 v-else>Editar Tarjeta</h4>
          </b-card-header>
          <b-card-body>
            <b-form-group>
              <label class="control-label" for="input-live">Nombre:</label>
              <b-form-input
                id="input-live"
                v-model="card.name"
                :state="nameState"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Nombre de la Tarjeta"
                trim
              ></b-form-input>

              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback
                v-if="nameState === false"
                id="input-live-feedback"
              >
                El nombre debe tener un mínimo de 3 letras
              </b-form-invalid-feedback>

              <!-- This is a form text block (formerly known as help block) -->
              <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
            </b-form-group>
            <b-form-group>
              <label class="control-label" for="input-live">Tipo:</label>
                <b-form-select class="form-control" v-model="card.card_type">
                    <b-form-select-option value="debit">Debito</b-form-select-option >
                    <b-form-select-option value="credit">Credito</b-form-select-option>
                </b-form-select>

              <!-- This is a form text block (formerly known as help block) -->
              <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
            </b-form-group>
            <b-form-group>
                <label>
                <input v-model="card.active" type="checkbox" class="md-check">
                Activa
                </label>
            </b-form-group>

            <div class='config' id='config'>
              <div class="form-group">
                <label class="control-label">Configuración de Cuotas</label>
              </div>

              <div v-for="fee in card.fee" :key="fee.id">
                  <div class="form-group">
                      <div class="row">
                          <div class='col-md-12'>
                          <label>{{ fee.quotas }} Cuota{{fee.quotas>1? 's':'' }} </label>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-md-1">
                          <input v-model="fee.activate" type='checkbox'  />
                          </div>
                          <div class='col-md-4'>
                          <input v-model="fee.tem" type='text' class='form-control' placeholder='Recargo (%)'/>
                          </div>
                          <div class='col-md-6'>
                          <input v-model="fee.acreditation"  type='text' class='form-control' placeholder='Días de Acreditacion'/>
                          </div>
                      </div>
                  </div>
              </div>
              <b-form-invalid-feedback
                v-if="quotasState === false"
                id="input-live-feedback"
              >
                Debe seleccionar al menos una cuota
              </b-form-invalid-feedback>
            </div>
          </b-card-body>
          <div class="card-footer">
            <div class="form-actions">
              <a
                type="button"
                v-if="nameState === null && quotasState === null"
                @click="saveCard()"
                class="btn btn-lg btn-orange"
                href="#import_products"
                data-toggle="tab"
                
                >Guardar</a
              >
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
 
 
 
 
 <script>
 
 import VdtnetTable from "vue-datatables-net";
 
 export default {
   components: {
     VdtnetTable
   },
   data: function() {
     return {
       doSearch: '',   
       isLoading: false,
       opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
        },
         ajax: {
           url: process.env.VUE_APP_BASE_URL+"/api/creditcards/list",
           type: "GET",
           headers: { Authorization: "Bearer " + localStorage.getItem("token") },
         },
       },
       fields: {
         name: { label: "Tarjeta", searchable: true },
         card_type: { label: "Tipo" },
         id: { visible: false },
         active: { label: "Activa", render: function (data) {
             return `${data == 1 ? 'Activa':'Inactiva'}`;
           },},
         actions: {
           isLocal: true,
           label: "Acciones",
           width: "123px",
           defaultContent:
             '<div class="divActions">' +
             '<button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light">'+
                '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">'+
                  '<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>'+
                  '<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>'+
                '</svg>'+
              '</button>' +
             '<button data-action="delete" data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton">'+
                '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
                  '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
                  '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
                '</svg>'+
              '</button>' +
             "</div>",
         },
       },
       card: {
         id: null,
         name: "",
         card_type: "",
         active: '',
         fee:[]
       },
 
       emptyCard: {
         id: null,
         name: "",
         card_type: "credit",
         active: 1,
         fee:[]
       },
       emptyFee:[
           {
             id:0,
             quotas:1,
             activate: 0,
             tem:'',
             acreditation:''
           },
           {
             id:1,
             quotas:3,
             activate: 0,
             tem:'',
             acreditation:''
           },{
             id:2,
             quotas:6,
             activate: 0,
             tem:'',
             acreditation:''
           },{
             id:3,
             quotas:12,
             activate: 0,
             tem:'',
             acreditation:''
           },{
             id:4,
             quotas:18,
             activate: 0,
             tem:'',
             acreditation:''
           },{
             id:5,
             quotas:24,
             activate: 0,
             tem:'',
             acreditation:''
           },
       ]
     };
   },
   mounted() {
     this.newCard();
   },
   computed: {
     nameState() {
       return this.card.name.length < 3 && this.card.name.length > 0
         ? false
         : null;
     },
     quotasState() {
       return this.card.fee.filter(q=> q.activate ==1 ).length==0 && this.card.card_type == 'credit'
         ? false
         : null;
     },
 
   },
   watch: {
     doSearch(newValue, oldValue) {
       if(newValue.length >= 4 || oldValue.length >= 4) {
         this.$refs.table.search(this.doSearch)
       }
       if(newValue === '' && oldValue.length > 0) {
         this.$refs.table.search(this.doSearch)
       }
     },
     'card.card_type': {handler: function (a) {
       if(a=='debit'){
         this.card.fee = [] ;
       }
       else
       {
         this.card.fee = JSON.parse(JSON.stringify(this.emptyFee)) ;
       }
     }
     },
   },
   methods: {
     newCard(){
       this.card = Object.assign({}, this.emptyCard);
       this.card.fee = JSON.parse(JSON.stringify(this.emptyFee)) ;
     },
 
     getCard(data) {
       this.newCard();
       var vm = this;
       vm.isLoading = true
       this.$http.get("/api/creditcards/getCreditCard/" + data.id).then((response) => {
         vm.card.id = response.data.data.id;
         vm.card.name = response.data.data.name;
         vm.card.card_type = response.data.data.card_type;
         vm.card.active = response.data.data.active;
         response.data.data.credit_card_coefficients.forEach(a=> {
           var q = vm.card.fee.filter(b=> b.quotas == a.quotas)[0];
           q.tem = a.tem;
           q.acreditation = a.acreditation;
           q.activate = a.activate;
         });
         vm.isLoading = false
       });
     },
 
     deleteCard(data) {
       if (confirm("Esta seguro que desea borrar esta tarjeta?") == true) {
         this.isLoading = true
         this.$http
           .post("/api/creditcards/delete", { id: data.id })
           .then((response) => {
             
             if (response.data.code == 200) {
               this.$refs.table.reload();
               this.newCard();
             }
             this.isLoading = false;
 
           });
       }
     },
     saveCard() {
       if (this.card.id != null) {
         this.$http
           .post("/api/creditcards/edit/" + this.card.id, this.card)
           .then((response) => {
             
             if (response.data.code == 201) {
               this.$refs.table.reload();
               this.newCard();
             }
             this.isLoading = false;
           });
       } else {
         this.isLoading = true
         this.$http.post("/api/creditcards/add", this.card).then((response) => {
           
           if (response.data.code == 201) {
               this.$refs.table.reload();
               this.newCard();
             }
             this.isLoading = false;
 
         });
       }
     },
   },
 };
 
 </script>
 