<template>
  <div class="card mb-4">
    <div class="card-header">
      <h6 class="card-title" v-if="fund_category.id == null">
        Nueva categoría de caja
      </h6>
      <h6 v-else class="card-title">Editar categoría de caja</h6>
      <a
        type="button"
        class="btn btn-btn btn-light panel-action border"
        v-if="fund_category.id != null"
        @click="resetForm"
        >Nueva categoría de caja</a
      >
    </div>
    <div class="card-body">
      <form class="form" @submit.prevent>
        <div class="form-body">
          <div class="form-group">
            <label class="control-label" for="input-live"
              >Nombre de la categoría:</label
            >
            <b-form-input
              id="input-live"
              v-model="fund_category.name"
              aria-describedby="input-live-help input-live-feedback"
              trim
            ></b-form-input>
            <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
          </div>
          <div class="form-group">
            <label class="control-label" for="input-live">Descripcion:</label>
            <b-form-input
              id="input-live"
              v-model="fund_category.description"
              aria-describedby="input-live-help input-live-feedback"
              trim
            ></b-form-input>
          </div>
          <div class="form-group">
            <label class="control-label" for="input-live">Tipo:</label>
            <b-form-select
              class="form-control"
              v-model="fund_category.type"
              :options="optionsUnit"
            ></b-form-select>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <a
        type="button"
        @click="saveFundCategory()"
        class="btn btn-btn btn-orange btn-lg mb-4"
        href="#import_funds"
        data-toggle="tab"
        >Guardar categoría</a
      >
    </div>
  </div>
</template>

<script>
export default {
   watch: {
    fundCategoryId: function (value) {
      if (value != null) {
        console.log("watch", "id no es null");
        this.getFundCategory(value);
      } else {
        this.resetForm();
      }
    },
  },
  mounted() {
    this.resetForm();
    if (this.fundCategoryId != null) {
      console.log("mounted", "id no es null");
      this.getFundCategory(this.fundCategoryId);
    } else {
      this.resetForm();
    }
  },
  methods: {
    saveFundCategory() {
      var vm = this;
      if (this.fund_category.id != null) {
        this.$http
          .post(
            "/api/fundsCategories/edit/" + this.fund_category.id,
            this.fund_category
          )
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      } else {
        this.$http
          .post("/api/fundsCategories/add", this.fund_category)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      }
    },
     getFundCategory(id) {
      var vm = this;
      this.modo = "editar";
      this.$http.get("/api/fundsCategories/view/" + id).then((response) => {
        vm.fund_category = response.data.data;

      });
    },

    resetForm() {
      this.fund_category = Object.assign({}, this.empty_fund_category);
    },
  },

  props: ["fundCategoryId","showNewBtn"],

  data() {
    return {
      fund_category: {
        name: "",
        description: "",
        type: "",
      },

      empty_fund_category: {
        name: "",
        description: "",
        type: "",
      },

      optionsUnit: [
        { value: "in", text: "Ingreso" },
        { value: "out", text: "Egreso" },
      ],
    };
  },
};
</script>