<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Cupones de tarjetas</h4>
      </b-col>
    </div>
    <div class="modal-new" v-if="showNewCouponModal">
      <div class="close-button" @click="showNewCouponModal = false">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <DepositModal 
      v-if="showModal" 
      :funds="funds" 
      @fundSelected="depositForm" 
      @close="showModal = false" 
    />
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <!-- ------------ CABECERA--------------- -->
    <b-row class="row text-center">
      <b-col>
        <div class="stats">
          <div class="stat">
            <div class="number">
              {{ formatoDinamicoMoneda(balanceCupones)}}
            </div>
            <div class="desc"> Monto total de cupones en cartera</div>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- ------------ NUEVO CUPON --------------- -->
    <div class="modal-card">
        <NuevoCuponTarjeta 
         :couponId="couponIdParent" 
         v-if="showNewCoupon" 
         @reset-id="couponIdParent = null"
         @confirmsave="refreshTable()" 
        />
      </div>
    <!-- ------------- Filtros ----------------- -->
    <b-form inline class="d-flex justify-content-between my-2"
      @submit.stop.prevent="doSearch">
      <input v-model="doSearch" type="search" class="form-control input-sm" placeholder="Buscar..."
          aria-controls="coupons" />
          
      <!--<b-button @click="openNewcoupon" variant="success">Nuevo Cupon</b-button>-->
    </b-form>
    <!-- TABLA -->
    <b-row>
      <b-col>
        <vdtnet-table :fields="fields" :opts="opts" :selectCheckbox="0" @edit="changeCouponId"
          @cash="showcashCouponModal" @delete="deleteCoupon" ref="table" />
      </b-col>
    </b-row>
  </b-container>
</template>



<script>
import moment from 'moment';
import VdtnetTable from "vue-datatables-net";
import NuevoCuponTarjeta from "./../sub-components/Nuevo-cupon-tarjeta.vue";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import DepositModal from "../sub-components/depositModal.vue";
import formatDateMixin from "../../mixins/formatDateMixin";

export default {
  components: {
    VdtnetTable,
    NuevoCuponTarjeta,
    DepositModal,
  },
  mixins: [formatMoneyMixin, formatDateMixin],
  data: function () {
    return {
      doSearch: "",
      loading: false,
      fundIdParent: null,
      couponIdParent: null,
      showNewCoupon: true,
      showNewCouponModal: false,
      showModal: false,
      fund: null,
      depositCheckId: null,
      coupons: [],
      funds: [],
      // balanceCupones: 0,
      balanceAccredit: 0,
      balanceBank: 0,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        // ajax: {
        //   url: process.env.VUE_APP_BASE_URL+"/api/funds/coupons/list",
        //   type: "GET",
        //   headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        // },
        ajax: this.getTpChecks,
        serverSide: false,
        searching: true,
      },

      fields: {
        id: { visible: false },
        "client.name": {
          label: "Cliente",
          searchable: true,
          defaultContent: "Ninguno",
        },
        number: { label: "Número", searchable: true },
        date_due: {
          label: "Cobro",
          searchable: true,
          render: (data) => moment(data, 'DD-MM-YYYY').format('DD/MM/YYYY'),
        },
        origen: { label: "Orígen", searchable: true },
        amount: {
          label: "Monto",
          render: this.formatoDinamicoMoneda,
        },
        status: { label: "Estado", searchable: true },
        actions: {
          isLocal: true,
          label: "Acciones",
          width: "115px",
          align: "center",
          render: (data, type, row) => {
            const defaultContent = `
                    <div class="divActions">
                      <button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg></button>
                      <button data-action="cash" data-toggle="tooltip" title="Cobrar / Depositar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bank" viewBox="0 0 16 16">
                      <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z"/>
                      </svg></button>
                      <button data-action="delete" data-toggle="tooltip" title="Borrar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                      </svg></button>
                    </div>`;
            if (!row.action) {
              return "";
            }
            return defaultContent;
          },
        },
      },
    };
  },
  watch: {
    doSearch(newValue, oldValue) {
      if (newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch);
      }
      if (newValue === "" && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch);
      }
    },
  },
  computed: {
    balanceCupones() {
      let balance = 0;
      for (let i = 0; i < this.coupons.length; i++) {
        balance = balance + Number(this.coupons[i].amount);
      }
      return balance;
    },
  },
  methods: {
    depositForm(fundId) {
      this.showModal = false;
      this.loading = true;
      const depositData = {
        fund_id: fundId,
        coupon_id: this.depositCheckId,
      };
      this.$http
        .post("/api/funds/coupons/deposit", depositData)
        .then((response) => {
          
          this.loading = false;
          if (response.data.code == 201) {
            this.refreshTable();
          }
        });
    },
    deleteCoupon(data) {
      if (confirm("¿Está seguro que desea borrar este cupon?") == true) {
        this.$http
          .post("/api/funds/coupons/delete", { id: data.id })
          .then((response) => {
            
            if (response.data.code == 201) {
              this.refreshTable();
            }
          });
      }
    },
    getTpChecks(d, cb) {
      var vm = this;
      this.$http
        .get("/api/funds/coupons/list")
        .then((response) => {
          vm.coupons = response.data.data;
          cb(response.data);
        });
    },

    getBalance() {
      var vm = this;
      this.$http.get("/api/funds/coupons/list").then((response) => {
        vm.coupons = response.data.data;
      });
    },

    changeCouponId(data) {
      this.couponIdParent = data.id;
      this.showNewCouponModal = true;
    },
    openNewcoupon() {
      this.couponIdParent = null;
      this.showNewCouponModal = true;
    },
    showcashCouponModal(data) {
      this.depositCheckId = data.id;
      this.showModal = true;
    },
    getFunds() {
      var vm = this;
      this.$http.get("/api/funds/list").then((response) => {
        vm.funds = response.data.data;
      });
    },
    refreshTable() {
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
      console.log(vm.$refs.table);
      this.getBalance();
    },
  },
  mounted() {
    // this.getBalance();
    this.getFunds();
  },
};
</script>