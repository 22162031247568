<template>
  <div class="card mb-4"> 
    <b-button variant="primary size-sm" v-if="role.id != null" @click="resetForm">
      Nuevo Rol
    </b-button>
    <b-card-header>
      <h6 v-if="role.id == null">Nuevo Rol</h6>
      <h6 v-else>Editar Rol</h6>

    </b-card-header>
    <b-card-body>
      <b-form method="POST" action="/api/configs/role/add">
        
        <b-form-group>
          <label>Nombre</label>
          <input 
          v-model="role.name" 
          type="text" 
          class="form-control"
          placeholder="Nombre..."  
          required>
        </b-form-group>
        <b-form-group>
          <label>Descripción</label>
          <input 
          v-model="role.description" 
          type="text" 
          class="form-control"
          placeholder="Descripcion..." 
          required>
        </b-form-group>
        <hr>
        <h6>Puede acceder a: </h6>

        <b-form-group class="overflow-auto" style="height: 35vh;">
          <div v-for="permission in permissionsList" :key="permission.id" class="checkbox check-container d-flex align-items-start flex-column">
            <label :for="`permiso-${permission.id}`" class="checkbox-label">
              <input :id="`permiso-${permission.id}`" v-model="role.allowed" :name="`allowed[${permission.id}]`"
                :value="permission.id" type="checkbox" class="md-check">
              {{ permission.description }}
            </label>
          </div>
        </b-form-group>

        
        
      </b-form>
    </b-card-body>
    <div class="card-footer">
      <b-button variant="orange" size="lg" type="submit" @click.prevent="saveRole()">Guardar Rol</b-button>
    </div>
  </div>

</template>

<script>

export default {
  props: ['roleId', 'permissionsList'],
  mounted() {
    if (this.roleId != null) {
      this.getRole(this.roleId);
    } else {
      this.resetForm();
    }
  },
  data() {
    return {
      modo: 'nuevo',
      role: {
        name: '',
        description: '',
        allowed: [],
      },
      emptyRole: {
        name: '',
        description: '',
        allowed: [],
      },
    }
  },
  watch: {
    roleId: function(value) {
      if (value != null) {
        this.getRole(value);
      } else {
        this.resetForm();
      }            
    }
  },
  methods: {
    saveRole() {
      var vm = this;
      if (vm.role.id != null) {
        vm.$http
          .post(`/api/configs/role/update/${vm.role.id}`, this.role)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      } else {
        this.$http
          .post("/api/configs/role/add", this.role)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      }
    },
    getRole(id) {
      var vm = this;
      this.modo = "editar";
      this.$http.get(`/api/configs/role/permissions/list/${id}`)
      .then((response) => {
        const { id, description, name, permissions } = response.data.data;
        const allowed = permissions.map(perm => perm.id);
        vm.role = { id, description, name, allowed };
      });
    },
    resetForm() {
      this.role = Object.assign({}, this.emptyRole);
    },
  },
}

</script>