<template>
   <pos-documents :configurl="createFromONLink" />
</template>
<script>
import PosDocuments from "../pos-document/receipt/Receipt.vue";

export default {
   props: ['orderNoteId'],
   components: {
      PosDocuments
   },
   computed: {
      createFromONLink() {
         return "/api/sales/createfromON/" + this.orderNoteId
      }
   }
};
</script>