<template>
    <b-container>
        <b-row class="mt-3">
            <b-col cols="md-8 d-flex justify-content-start">
                <h4>Notificaciones</h4>
            </b-col>
            <b-col cols="md-4 text-right">
                <div class="d-flex align-items-center justify-content-end">
                    <label class="mr-2 mb-0">Mostrar: </label>
                    <b-form-group class="mb-0">
                        <b-form-select v-model="filters.itemsPerPage">
                            <option :value="15">15</option>
                            <option :value="25">25</option>
                            <option :value="50">50</option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </b-col>
        </b-row>
        <!-- TARJETAS DE NOTIFICACIONES-->
        <b-row>
            <label v-if="showNotifications.length<1">No hay alertas disponibles</label>
            <b-col cols="md-12" v-for="alert in paginatedNotifications" :key="alert.id" v-bind:class="[{'state-disabled': alert.seen==1}]">
                <!-- TARJETA -->
                <b-card class="my-4 p-4 bg-light text-left">
                    <b-row>
                        <b-col cols="2">
                            <div v-if="alert.type=='stock'" class="alert-icon icon-stock" v-bind:class="[{'state-disabled': alert.seen==1}]">
                                <b-icon icon="exclamation-triangle"></b-icon>
                            </div>
                            <div v-if="alert.type=='order'" class="alert-icon icon-order" v-bind:class="[{'state-disabled': alert.seen==1}]">
                                <b-icon icon="basket"></b-icon>
                            </div>
                        </b-col>
                        <b-col cols="8">
                            <h4 v-if="alert.type=='stock'" class="titulo">STOCK</h4>
                            <h4 v-if="alert.type=='order'" class="titulo">NUEVO PEDIDO</h4>
                            <div class="alert-message">{{alert.message}}</div>
                            <span>{{formatDate(alert.created_at)}}</span>
                        </b-col>
                        <b-button-group class="d-flex align-items-center">

                            <b-button href="#" @click="checkSeen(alert)"  variant="primary">Ver
                                <b-icon icon="eye"></b-icon>
                            </b-button>

                            <b-button href="#" @click="delNotification(alert.id)" variant="danger">Borrar
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </b-button-group>

                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <!-- PAGINACION -->
        <div class="row vdtnet-footer">
            <div class="col">
                <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination">
                    <li class="page-item" v-bind:class="[{'disabled': filters.page==1}]">
                    <a class="page-link" href="#" v-on:click="prevPage()">Anterior</a>
                    </li>
                    <li v-if="(filters.page - (pageInterval/2))>0" class="page-item">
                    <a class="page-link" href="#" v-on:click="prevPage()">...</a>
                    </li>
                    <template v-for="index in totalPages">
                    <li :key="index" v-if="inIntervalPage(index)" class="page-item" v-bind:class="[{'active': filters.page==index}]">
                        <a class="page-link" href="#" v-on:click="changePage(index)">{{index}}</a>
                    </li>
                    </template>
                    <li v-if="totalPages>(filters.page + (pageInterval/2))" class="page-item">
                    <a class="page-link" href="#" v-on:click="nextPage()">...</a>
                    </li>
                    <li class="page-item" v-bind:class="[{'disabled': filters.page>=totalPages}]">
                    <a class="page-link" href="#" v-on:click="nextPage()">Siguiente</a>
                    </li>
                </ul>
                </div>
            </div>
        </div>
    </b-container>
</template>


<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex'

export default {
  data: function() {
      return {
            totalPages:1,
            pageInterval:22,
            notifications:0,
            filters:{
                page:1,
                itemsPerPage:15
            }
        }
	},
  mounted(){
  },
  created() {
    this.loadNotifications();
  },
  computed: {
    ...mapGetters({
      showNotifications: 'alerts/showNotifications'
    }),
    ...mapGetters({
        showCountNotifications: 'alerts/showCountNotifications'
    }),
    paginatedNotifications() {
        const start = (this.filters.page - 1) * this.filters.itemsPerPage;
        const end = start + this.filters.itemsPerPage;
        return this.showNotifications.slice(start, end);
    },
  },
  methods: {
    ...mapActions({
      getNotifications: 'alerts/getNotifications',
      delNotification: 'alerts/delNotification'
    }),
    loadNotifications() {
      this.getNotifications();
      this.calculateTotalPages();
    },
    checkSeen(alert){
        let redirect='';
        switch(alert.type){
            case 'order':
                let link ='/ecom/order/' + alert.target_id;
                window.open(link, "_blank") || window.location.replace(link);
                break;
            case 'stock':
                let { href } = this.$router.resolve({ name: 'Movimientos de stock' });
                localStorage.setItem('productId', alert.target_id);
                window.open(href, '_blank');
                break;
            }
        axios.get('/api/notifications/checkSeen/' + alert.id)
        this.loadNotifications();

    },
    formatDate(value){
        if (value){
            return moment(String(value)).format('DD/MM/YYYY');
        }
    },
    calculateTotalPages() {
        var vm = this;
        if(vm.showCountNotifications > 0){
            vm.totalPages = Math.ceil(vm.showCountNotifications / vm.filters.itemsPerPage);
        }else{
            axios.post('/api/notifications/list')
            .then(response => {
                if(response.data.success) {
                    vm.notifications = response.data.data;
                    vm.totalPages = Math.ceil(vm.notifications.length / vm.filters.itemsPerPage);

                }
            })
            .catch(error => {
                alert(error);
            })
        }
    },
    prevPage(){
        if(this.filters.page>1){
            this.filters.page = this.filters.page-1;
            this.loadNotifications();
        }
    },
    nextPage(){
        if (this.filters.page<this.totalPages){
            this.filters.page = this.filters.page +1;
            this.loadNotifications();
        }
    },
    changePage(data){
        this.filters.page = data;
        this.loadNotifications();
    },
    inIntervalPage(index){
        if(this.totalPages<=this.pageInterval){return true;}

        let limitprev = this.filters.page - (this.pageInterval/2);
        let limitnext = this.filters.page + (this.pageInterval/2);

        if(limitprev<0){
            limitprev=0;
            limitnext=this.pageInterval;
        }

        if(index>limitprev && index<limitnext){return true;}

        return false;
    },
  }
}
</script>

<style scoped>
.alert-message{
    font-size: 18px;
    font-weight: bold;
}

.alert-icon{
    font-size: 39px;
    font-weight: bold;
    text-align: center;
}

.icon-stock{
    color:#4477bd;
}

.icon-order{
    color:#009a1f;
}

.bi-basket{
    font-size: larger;
}
.bi-exclamation-triangle{
    font-size: larger;
}
.button-see{
    background-color: #4477bd;
}

.state-disabled{
    color:#828282;
}

.page-item{
    margin: inherit;
}

</style>

<style scoped>
.main-card {
    height: 110px;
    display: grid;
    grid-template-columns: 100px auto 120px;
    padding: 15px;
    box-sizing: border-box;
}

.text-section h4 {
    margin: 0;
}

@media screen and (max-width: 500px) {
    .main-card {
        height: 90px;
        grid-template-columns: 60px auto 55px;
        padding: 10px;
    }
    .text-section h4 {
        font-size: 14px;
    }
    .text-section .alert-message {
        font-size: 12px;
        line-height: 15px;
    }
    .text-section span {
        font-size: 10px;
    }
    .button-text { display: none; }
    .alert-button{
        width: 50px;
        height: 30px;
    }
}
</style>


