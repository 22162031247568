<template>
    <b-container fluid>
        <b-row class="text-left mt-3">
            <b-col>
                <h4>Edicion de Catalogo</h4>
            </b-col>
        </b-row>
        <!-- -------------------- CONFIG ------------------- -->
        <b-row cols="mt-2">
            <b-col cols="md-12">
                <div class="card bg-light rounded-sm p-3 my-2 border">
                    <h2>Detalle de catalogo</h2>
                    <b-row>
                        <b-col cols="md-4" class="d-flex justify-content-start">
                            <label>Nombre:</label>
                            <input 
                            v-model="catalog.name" 
                            class="form-control calculate"
                            placeholder="Ingresar nombre"/>
                        </b-col>
                        <b-col cols="md-4" class="d-flex justify-content-start">
                            <label>Lista de precios:</label>
                            <select v-model="catalog.pricelist" class="form-control">
                                <option v-for="price in pricesList" :key='price.id' :value="price.id">{{price.name}}</option>
                            </select>
                        </b-col>
                        <b-col cols="md-4" class="d-flex justify-content-start">
                            <label>Ordenado por:</label>
                            <select v-model="catalog.order" class="form-control">
                                <option value="abc" selected>Alfabetico</option>
                                <option value="old">Creados más antiguamente</option>
                                <option value="new">Creados más recientemente</option>
                                <option value="upd">Modificados recientemente</option>
                                <!-- <option value="priority">Posición</option> -->
                            </select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <!-- -------------------- FILTRO MOSTRAR ------------------- -->
            <b-col cols="md-3">
                <div class="card bg-light rounded-sm p-3 my-2 border">
                    <h2>Filtros por lote</h2>
                    <b-row>
                        <b-col cols="md-12 mt-4">
                            <b-form-group>
                                <label>Periodo de creación:</label>
                                <Daterange :opendir="'right'" @datepicked="dateCreated"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="md-12">
                            <b-form-group>
                                <label>Última modificación::</label>
                                <Daterange :opendir="'right'" @datepicked="dateUpdated"/>
                            </b-form-group>
                        </b-col>
                        <b-col cols="md-12 my-2">
                            <label>Categorías:</label>
                            <Treeselect class="multiList" v-model="filters.catList"
                            :options="categoryList"
                            :multiple="true"
                            :flat="true"
                            :open-on-click="true"
                            value-consists-of="ALL_WITH_INDETERMINATE"/>
                        </b-col>
                        <b-col cols="md-12 my-4">
                            <button class="btn-primary form-control" @click="filterProds()">
                                <i class="fas fa-search"></i> Agregar productos >>
                            </button>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <!-- -------------------- TABLA ------------------- -->
            <b-col cols="md-9">
                <div class="card bg-light rounded-sm p-2 my-2 border" style="min-height: 62vh;">
                    <h2 class="mt-2">Productos del catalogo</h2>
                    <!-- -------------------- BUSCADOR ------------------- -->
                    <b-col cols="12 mt-4">
                        <typeahead
                            :name="'productSearch'"
                            :placeh="'Buscar un producto para agregar al catalogo...'"
                            :clean-on="true"
                            v-on:choosed="productChoosen"
                            :externalsrc="'/api/products/searchProducts'"
                        />
                    </b-col>
                    <!--
                    <b-col cols="md-6">
                        <b-button variant="outline-danger" @click="cleanProds()">
                            Limpiar lista <b-icon icon="trash"></b-icon> 
                        </b-button>
                    </b-col>
                    -->
                    <!-- -------------------- TABLA ------------------- -->
                    <b-col bordered class="mt-2">
                        <b-table-simple striped caption-top sticky-header>
                            <b-thead variant="secondary">
                                <b-tr>
                                    <!-- <th class="col-sm-1">Orden</th> -->
                                    <b-th>Cod.Interno</b-th>
                                    <b-th>Producto</b-th>
                                    <b-th>Creado</b-th>
                                    <b-th>Modificado</b-th>
                                    <b-th class="col-sm-1"></b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody v-if="prodList.length >0">
                                <b-tr role="row" v-for="prod in prodList" :key="prod.id">
                                    <!-- <td class="col-sm-1"></td> -->
                                    <b-td>{{prod.code}}</b-td>
                                    <b-td>{{prod.name}}</b-td>
                                    <b-td>{{formatDate(prod.created_at)}}</b-td>
                                    <b-td>{{formatDate(prod.updated_at)}}</b-td>
                                    <b-td class="col-sm-1">
                                        <b-button variant="danger">
                                            <b-icon icon="trash" @click="productRemove(prod.id)"></b-icon>
                                        </b-button>
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                            <b-tbody v-else>
                                <b-row>
                                    <b-col class="my-4">
                                        <h4>No hay productos en el catalogo</h4>
                                    </b-col>
                                </b-row>
                            </b-tbody>
                        </b-table-simple>
                    </b-col>
                        <b-col cols="md-12" class="d-flex justify-content-end">
                            <b-button variant="orange" size="lg" @click="saveCatalog()">Guardar Catalogo</b-button>
                        </b-col>
                    </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import Daterange from '../../global/Daterange-component.vue';
import Treeselect from '@riophae/vue-treeselect'
import typeahead from '../../global/Typeahead.vue';
import moment from 'moment';

export default {
  props: ['sourceid'],
  components: {
      Daterange,
      Treeselect,
      typeahead
    },
  data() {
    return {
        config:{
            endpoint :'/api/catalogs/add',
            sourceurl :'/ecom/catalogos'
        },
        catalog:{
            name:'',
            pricelist:1,
            order:'abc',
            products:[],
        },
        filters:{
            catList:[],
            createdFromDate:null,
            createdToDate:null,
            updatedFromDate:null,
            updatedToDate:null,
        },
        categoryList:[],
        pricesList:[],
        prodList:[],
        title:'',
        loading:true,
    }
  },
  mounted() {
    this.loadCategories();
    this.loadPriceLists();

    if(this.sourceid!=null){
        this.title="Editar catálogo";
        this.config.endpoint= '/api/catalogs/edit/' + this.sourceid;
        this.setForEdit(this.sourceid);
    }else{
        this.title="Nuevo catálogo";
        this.loading=false;
    }
  },
  methods: {
    loadCategories(){
        let vm= this;
        this.$http.get("/api/categories/getCategoryTree")
        .then((response) => {
            if (response.status==200) {
                vm.categoryList = response.data.data;
            } else {
                
            }
        }).catch((error) => {
            console.log('error: '+error);
        })
    },
    loadPriceLists(){
        let vm= this;
        this.$http.get("/api/priceslist/getPriceList")
        .then((response) => {
            if (response.status==200) {
                vm.pricesList = response.data.data;
            } else {
                
            }
        }).catch((error) => {
            console.log('error: '+error);
        })
    },
    setForEdit(id) {
          let vm= this;
          this.$http.get("/api/catalogs/getCatalog/"+id)
          .then((response) => {
            if (response.status==200) {
                let result = response.data.data;
                console.log('result',result);
                vm.catalog.name = result.name;
                vm.catalog.pricelist = result.pricelist;
                vm.catalog.order = result.order;
                vm.prodList = result.products;

                vm.loading=false;
            } else {
              
            }
          }).catch((error) => {
            console.log('error: '+error);
          })
    },
    formatDate(date){
        if(date!=null){
            return moment(String(date)).format('DD/MM/YYYY');
        }else{
            return '';
        }
    },
    dateCreated: function (date) {
        this.filters.createdFromDate = date.startDate;
        this.filters.createdToDate=date.endDate;
    },
    dateUpdated: function (date) {
        this.filters.updatedFromDate = date.startDate;
        this.filters.updatedToDate=date.endDate;
    },
    filterProds() {
        let vm = this;
        let outdata= vm.filters;
        outdata.order = vm.catalog.order;
        this.$http.post("/api/catalogs/filterProds", outdata)
        .then((response) => {
            if (response.status==200) {
                vm.prodList = response.data.data;
            } else {
                
            }
        }).catch((error) => {
            console.log('error: '+error);
        })
    },
    cleanProds(){
        this.prodList=[];
        this.catalog.products=[];
    },
    productChoosen(a){
        var data = JSON.parse(JSON.stringify(a));
        var isInArray = this.catalog.products.findIndex(function(i){ return i === data.id }) !== -1;
        if(!isInArray){
            this.prodList.push(data);
        }
    },
    productRemove(id) {
        this.prodList.splice(this.prodList.findIndex(function(i){return i.id === id;}), 1);
    },
    saveCatalog(){
        let vm=this;
        if(this.catalog.name==''){
            alert('Es necesario ingresar un nombre');
            return false;
        }
        if(this.catalog.products.length<1){
            alert('El catalogo no tiene productos asignados');
            return false;
        }

        this.$http.post(vm.config.endpoint, vm.catalog)
        .then((response) => {
            if (response.data.code ==201) {
                
                window.location.href = vm.config.sourceurl;
            } else {
                
            }
        }).catch((error) => {
            console.log('error: '+error);
        })

    }
  },
  watch: {
	'prodList':{handler: function (a) {
        let aux=[];
        this.prodList.forEach(function(prod) {
            aux.push({'id':prod.id,'priority':0});
        });
        this.catalog.products=aux;
    }}
  },
}
</script>


