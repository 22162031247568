<template>
  <b-container fluid>
    <div class="row pt-3">
      <h2>
          <label for="">Detalle de orden de pago</label>
      </h2>
    </div>
    <b-card class="bg-light d-flex justify-content-between mb-4 p-0">
      <div class="row">
        <div class="col-sm-4">
          <h2 class="control-label">Orden de pago #{{payorder.number}}</h2>
        </div>
        <div class="col-sm-4">
          <h2 class="control-label">Proveedor: {{payorder.provider_name}}</h2>
        </div>
        <div class="col-sm-4">
          <h2 class="control-label">Fecha: {{formatoDate2Fecha(payorder.created_at)}}</h2>
        </div>
      </div>
    </b-card>
    
    <div class="row">
      <!-- TABLA -->
      <div class="col-8 overflow-auto">
        <label>Forma de pago:</label>
        <div v-if="payments.length>0">
          <b-table hover :items="payments" :fields="paymentsFields" >
          </b-table>
        </div>
        
        <div v-if="purchases.length>0" class="mt-4">
          <label>Comprobantes asociados al generar el pago:</label>
            <b-table :items="purchases" :fields="purchasesFields">
              <template #cell(id)="data">
                <a :href="`/compras/index/detalle/${data.value}`" class="btn btn-sm btn-light">Ver detalle</a>
              </template>
            </b-table>
        </div>
        <div v-if="payorders.length>0" class="mt-4">
          <label>Otras ordenes de pago asociadas al generar este pago:</label>
          <b-table :items="payorders" :fields="payordersFields">
            <template #cell(id)="data">
              <router-link :to="`/compras/detalle-orden-de-pago/${data.value}`" class="btn btn-sm btn-light">Ver detalle</router-link>
            </template>
          </b-table>
        </div>
      </div>
      <!-- TOTALES -->
      <div class="col-4">
        <label>Detalles de pago:</label>
        <b-card bg-variant="light">
          <b-card-header>
            <h3>Total de la orden de pago</h3>
          </b-card-header>
          <b-card-body>
            <b-form-group>
              <b-row>
                <b-col class="col-xs-7 text-left">
                  PAGO EN EFECTIVO:
                </b-col>
                <b-col class="col-xs-5 text-right">
                  $ <span id="htmlTotal">{{totalFunds}}</span>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="col-xs-7 text-left">
                  PAGO CON CHEQUES DE TERCEROS:
                </b-col>
                <b-col class="col-xs-5 text-right">
                  $ <span id="htmlTotal">{{totalTpchecks}}</span>
                </b-col>
              </b-row>
              
              <b-row>
                <b-col class="col-xs-7 text-left">
                  PAGO CON CHEQUES PROPIOS:
                </b-col>
                <b-col class="col-xs-5 text-right">
                  $ <span id="htmlTotal">{{totalChecks}}</span>
                </b-col>
              </b-row>
              
              <b-row style="font-weight: bold;" class="mt-3">
                <b-col class="col-xs-7 text-left">
                  TOTAL:
                </b-col>
                <b-col class="col-xs-5 text-right">
                  $ <span id="htmlTotal">{{payorder.total}}</span>
                </b-col>
              </b-row>
              
            </b-form-group>
          </b-card-body>
        </b-card>
        <div class="form-group col-border mt-4">
            <h6>
              <label for="">Nota para el proveedor</label>
            </h6>
            <textarea class="form-control" rows="4">{{payorder.notes}}</textarea>
        </div>
      </div>
    </div>
    <b-row class="receipt-footer d-flex justify-content-end mb-3">
      <DownloadDoc :pdfurl="pdfurl" :open="false" :filename="payorder.provider_name+payorder.created_at+'.pdf'">
        <b-button variant="outline-primary" size="lg">Descargar</b-button>
      </DownloadDoc>
      <DownloadDoc :pdfurl="pdfurl" :open="true" :filename="payorder.provider_name+payorder.created_at+'.pdf'">
        <b-button variant="orange" size="lg" class="ml-4">Imprimir Orden</b-button>
      </DownloadDoc>
    </b-row>
  </b-container>
</template>
  
<script>
import DownloadDoc from "../../global/DownloadDoc";
import formatDateMixin from "../../../mixins/formatDateMixin";

  export default {
    props: ['payOrderId'],
    mixins: [formatDateMixin],
    components: {
      DownloadDoc
    },
    data() {
      return {
        pdfurl: process.env.VUE_APP_BASE_URL+'/api/payments/poPDF/' + this.payOrderId,
        payorder:{fund_movement:[],tpchecks:[],checks:[]},
        purchases:[],
        payorders:[],
        payments:[],
        paymentsFields: [
            {
              key: 'type',
              label: 'Tipo',
              sortable: true
            },
            {
              key: 'detail',
              label: 'Detalle',
              sortable: false
            },
            {
              key: 'amount',
              label: 'Valor',
              sortable: true,
              // Variant applies to the whole column, including the header and footer
            }
        ],
        purchasesFields: [
            {
              key: 'type',
              label: 'Tipo',
              sortable: true
            },
            {
              key: 'number',
              label: 'Numero',
              sortable: false
            },
            {
              key: 'total',
              label: 'Importe',
              sortable: true,
              // Variant applies to the whole column, including the header and footer
            },
            {
              key: 'partial',
              label: 'En Recibo',
              sortable: true,
              // Variant applies to the whole column, including the header and footer
              variant: 'danger'
            }
            ,
            {
              key: 'id',
              label: 'Ver Detalle',
              sortable: true,
              // Variant applies to the whole column, including the header and footer
              variant: 'danger'
            }
        ],
        payordersFields: [
            {
              key: 'dateHour',
              label: 'Tipo',
              sortable: true
            },
            {
              key: 'number',
              label: 'Numero',
              sortable: false
            },
            {
              key: 'amount',
              label: 'Importe',
              sortable: true,
              variant: 'danger'
            },
            {
              key: 'partial',
              label: 'En Recibo',
              sortable: true,
              variant: 'danger'
            }
            ,
            {
              key: 'id',
              label: 'Ver Detalle',
              sortable: true,
              variant: 'danger'
            }
        ],
      }
    },
    computed:{
      pos() {
        return this.payorder.pos
      },
      number() { return this.payorder.number },
      providerName() { return this.payorder.provider_name },
      created_at() { return this.payorder.created_at },
      totalFunds() {
        return this.payorder.fund_movement.reduce(
          (accumulator, mov) => accumulator + mov.amount, 0 );
      },
      totalTpchecks() {
        return this.payorder.tpchecks.reduce(
          (accumulator, mov) => accumulator + mov.amount, 0 );
      },
      totalChecks() {
        return this.payorder.checks.reduce(
          (accumulator, mov) => accumulator + mov.amount, 0 );
      },
    },
    methods: {
      getPayorderDetail() {
        var vm = this;
        axios.get('/api/payments/getPayorderDetail/' + this.payOrderId)
              .then(response => {
                if(response.data.success) {
                    vm.payorder = response.data.data.payorder;
                    console.log(vm.payorder);
                    let fundMov= vm.payorder.fund_movement.map(p=> { return {type:'Efectivo', detail: p.notes, amount:p.amount}});
                    let tpchecks= vm.payorder.tpchecks.map(p=> { return {type:'Cheque 3ro', detail: `Nº ${p.number} - Bco ${p.bank_name} - Fecha de cobro ${p.date_due}`, amount:p.amount}});
                    let checks= vm.payorder.checks.map(p=> { return {type:'Cheque Propio', detail: p.note, amount:p.amount}});
                    vm.payments = fundMov.concat(tpchecks).concat(checks);
                    console.log(vm.payments);
                    vm.purchases = response.data.data.purchases;
                    vm.payorders = response.data.data.payorders;
                }
              })
              .catch(error => {
                alert(error);
              })
      } 
    },
    mounted() {
      this.getPayorderDetail();
    }
  }
</script>
