<template>
  <b-container fluid>
    <v-guard :permissions="['INICIO_VER_GRAFICO']">
      <div class="row mt-3">
        <b-col class="d-flex justify-content-start">
          <h4>Estadisticas</h4>
        </b-col>
      </div>
    <div>
      <b-row class="stats text-center">
        <b-col cols="lg-4 md-4 sm-12 xs-12">
          <div class="stat">
            <div class="number">{{ formatoDinamicoMoneda(monthSales) }}</div>
            <div class="desc">En ventas {{filterLabel}}</div>
          </div>
        </b-col>
        <b-col cols="lg-4 md-4 sm-12 xs-12 border-r-l">
          <div class="stat">
            <div class="number">{{monthSalesCount}}</div>
            <div class="desc"> Ventas {{filterLabel}}</div>
          </div>
        </b-col>
        <b-col cols="lg-4 md-4 sm-12 xs-12">
          <div class="stat">
            <div class="number">{{ formatoDinamicoMoneda(promedio) }}</div>
            <div class="desc"> En promedio por venta </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2 dflex justify-content-end">
        <b-col cols="md-2">
          <form class="filter" method="GET" action="/?">
          <select :value="filter" onchange="this.form.submit()" name="filter" class="form-control btn-light" aria-labelledby="dropdownMenu1">
            <option value="1">Ultimos 30 días</option>
            <option value="2">Este mes</option>
            <option value="3">Ultimos 7 días</option>
            <option value="4">Esta semana</option>
          </select>
          </form>
        </b-col>
      </b-row>
      <chartjs-line v-if="!loading" :linetension="0.1" :scalesdisplay="false" :height="82" datalabel="Total de ventas"
        :data="data.chartData" :labels="data.chartLabels" :option="data.graphOptions" bordercolor="rgba(68, 119, 189,1)"
        backgroundcolor="rgba(68,119,189,0.5)" :pointborderwidth="4" :pointbordercolor="'rgba(68, 119, 189,1)'"
        :pointhoverborderwidth="3" :pointhoverbackgroundcolor="'rgba(68, 119, 189,1)'"
        :pointhoverbordercolor="'rgba(68, 119, 189,1)'" :fill="true" />
      
    </div>

    </v-guard>


    <b-row class="my-5">
      <b-col cols="md-4">
        <h5 class="font-weight-bold">Ultimos 5 comprobantes</h5>
        <b-table-simple striped small caption-top responsive>
          <b-thead>
            <b-tr>
              <b-th>Numero</b-th>
              <b-th>Tipo</b-th>
              <b-th>Monto</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-td v-if="lastFiveSales.length < 1" colspan="4">
              Todavía no tenés ventas, realiza la primera <a href="/ventas/index/nueva-venta">desde acá</a>
            </b-td>
            <b-tr v-for="sale in lastFiveSales" :key="sale.id">
              <b-td><a :href="`/ventas/index/detalle/${sale.id}`">#{{sale.full_number}}</a></b-td>
              <b-td>{{sale.type}} {{sale.letter}}</b-td>
              <b-td>{{ formatoDinamicoMoneda(sale.total) }} </b-td>
              <b-td>
                <a :href="`/ventas/index/detalle/${sale.id}`" class="btn btn-sm btn-info">
                  Ver venta
                </a>
              </b-td>
            </b-tr>

            <b-td v-if="lastFiveSales.length <= 0" colspan="4">
              Todavía no tenés ventas, realiza la primera <a href="/ventas/index/nueva-venta">desde acá</a>
            </b-td>
          </b-tbody>
        </b-table-simple>
      </b-col>

      <b-col cols="md-4">
        <h5 class="font-weight-bold">5 productos más vendidos</h5>
        <b-table-simple striped small caption-top responsive>
          <b-thead>
            <b-tr>
              <b-th>Producto </b-th>
              <b-th class="col-small">Total de ventas </b-th>
            </b-tr>
          </b-thead>

          <b-tbody>
            <b-tr>
              <b-td v-if="topProductsSales.length < 1" colspan="4" text-align="center">Todavía no tenés ventas, realiza la primera <a href="/ventas/index/nueva-venta">desde acá</a></b-td>
            </b-tr>
            <b-tr v-for="productSale in topProductsSales" :key="productSale.id">
              <b-td>
                {{productSale.name}}
              </b-td>
              <b-td> {{ formatoDinamicoMoneda(productSale.total) }} </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>

      <b-col cols="md-4">
        <h5 class="font-weight-bold">Cuenta corriente de clientes</h5>
        <b-table-simple striped small caption-top responsive>
          <b-thead>
            <b-tr>
              <b-th> Cliente </b-th>
              <b-th> Deuda total </b-th>
              <b-th> </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>

            <b-tr v-for="client in topDebtsClients" :key="client.id">
              <b-td>
                <a :href="`/ventas/clientes/cc-clientes/CC-clientes/${client.id}`"> {{client.name}} </a>
              </b-td>
              <b-td> {{ formatoDinamicoMoneda(client.balance) }} </b-td>
              <b-td>
                <a href="/ventas/clientes/cc-clientes/CC-clientes/${client.id}" class="btn btn-sm btn-info">
                  Ver</a>
              </b-td>
            </b-tr>

          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapState } from 'vuex';
import formatMoneyMixin from '../../mixins/formatMoneyMixin';

export default {
  components: {
  },
  mixins: [formatMoneyMixin],

  data() {
    return {
      loading: true,
      data: null,

    };
  },
  computed: {
    ...mapState('branchoffices', {
      branchofficeId: (state) => state.selectedBranchofficeId,
    }),
    filter() {
      const queryFilter = Number(this.$route.query.filter);
      
      return isNaN(queryFilter) ? 1 : queryFilter;
    },
    filterLabel() {
      if (this.filter === 1) {
        return 'los ultimos 30 días';
      }
      if (this.filter === 2) {
        return 'este mes';
      }
      if (this.filter === 3) {
        return 'los ultimos 7 días';
      }
      if (this.filter === 4) {
        return 'esta semana';
      }
      return '';
    },
    monthSales() {
      if (this.data && this.data.thisMonthSales) {
        return this.data.thisMonthSales;
      }
      return 0;
    },
    monthSalesCount() {
      if (this.data && this.data.thisMonthSalesCount) {
        return this.data.thisMonthSalesCount;
      }
      return 0;
    },
    promedio() {
      if (this.monthSales && this.monthSalesCount !== 0) {
        return this.monthSales / this.monthSalesCount;
      }
      return 0;
    },
    lastFiveSales() {
      if (this.data && this.data.lastFiveSales) {
        return this.data.lastFiveSales;
      }
      return 0;
    },
    topProductsSales() {
      if (this.data && this.data.topProductsSales) {
        return this.data.topProductsSales;
      }
      return 0;

    },
    topDebtsClients() {
      if (this.data && this.data.topDebtsClients) {
        return this.data.topDebtsClients;
      }
      return 0;
    }



  },
  mounted() {
    this.getDashboardData();
  },
  methods: {
    getDashboardData() {
      const vm = this;
      this.$http
        .get('/api/dashboard/getData', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: {
            filter: vm.filter,
          }
        })
        .then((response) => {
          vm.data = response.data.data;
        })
        .then(() => {
          vm.loading = false;
        });
    },
  },
};
</script>
