<template>
  <div class="col-md-12">
    <div class="form-group col-border">
      <tr>
        <th>
          <div class="form-group">
            <label>Numero</label>
            <input v-model="number" type="number" min="0" class="form-control">
          </div>
        </th>
        <th>
          <div class="form-group">
            <label>Banco</label>
            <input v-model="bankname" type="text" class="form-control">
          </div>
        </th>
        <th>
          <div class="form-group">
            <label>Fecha cobro</label>
            <masked-input v-model="datedue" required mask="11/11/1111" placeholder="dd/mm/yyyy" class="form-control"/>
          </div>
        </th>
        <th>
          <div class="form-group">
            <label>Monto</label>
            <input v-model.number="amount" type="number" min="0" step="0.01" class="form-control">
          </div>
        </th>
        <th>
          <div class="form-group">
            <label>Nombre cliente</label>
            <input v-model="clientname" class="form-control">
          </div>
        </th>
        <th>
          <div class="form-group">
            <label>CUIT cliente</label>
            <input v-model.number="clientcuit" type="number" min="0" class="form-control" required>
          </div>
        </th>
        <th valign="middle" style="width:80px">
          <div class="payment-actions">
            <span id="resetPaymentAction" @click="reset()" class="delete-product mr-2" aria-hidden="true" title="Eliminar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </span>
            <span id="savePaymentAction" v-if="validate" @click="save()" class="ok-payment-method" aria-hidden="true" title="Grabar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>
            </span>
          </div>
        </th>
      </tr>
    </div>
  </div>
</template>
<style scoped>
  #savePaymentAction{
    margin:5px 5px;
  }
  #resetPaymentAction{
    margin:5px 0;
  }
  #resetPaymentAction.glyphicon-minus-sign{
    font-size:25px;
  }
  #savePaymentAction.glyphicon-ok-sign{
    font-size:25px;color:green;
  }
</style>
<script>
import maskedInput from 'vue-masked-input';

export default {
  data: function() {
    return {
      res: {},
      clientcuit: null,
      clientname: null,
      amount: 0,
      datedue: null,
      bankname: '',
      number: null,
    }
  },
  components: {
    maskedInput
	},
  methods: {
		reset: function () {
      this.$emit('tpcheckcancel');
    },
    save: function () {
			this.res.clientcuit = this.clientcuit;
			this.res.clientname = this.clientname;
			this.res.amount = this.amount;
      this.res.datedue = this.datedue;
      this.res.bankname = this.bankname;
      this.res.number = this.number;
			
      this.$emit('tpchecksaved',this.res);
    }
	},
	computed: {
		validate () {
			let vm = this;
      return (vm.number !=null && vm.bankname != '' && vm.amount > 0 && vm.datedue!=null && vm.clientname!=null && vm.clientcuit!=null);
    }
  }
}
</script>