<template>
  
  <b-container fluid>
    <br>
    <b-row class="justify-content-center mt-5">
      <b-col cols="md-4">
        <b-card>
            <h6 class="m-0">CAMBIAR CONTRASEÑA</h6>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="justify-content-center mt-5">
      <b-col cols="md-4">
        <b-card>
          <b-card-header>
            <h6 class="m-0">{{user.name}}</h6>
          </b-card-header>
          <b-card-body>
            <b-form>
              <b-form-group>
                <label class="control-label">Email</label>
                <input name="email" type="text" class="form-control" readonly v-model="user.email">
              </b-form-group>
              <b-form-group>
                <label class="control-label">Cambiar Contraseña</label>
                <input name="password" v-model="password" type="password" class="form-control" >
              </b-form-group>
              <b-form-group>
                <label class="control-label">Confirmar Contraseña</label>
                <input name="password_confirmation" v-model="password_confirmation" type="password" class="form-control">
              </b-form-group>
              <button @click="submit" class="btn btn-lg btn-orange">Modificar perfil</button>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>


</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      password: '',
      password_confirmation: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'authUser/getUser',
    }),
  },
  methods: {
    submit(e) {
      this.loading = true;
      e.preventDefault();
      if(this.checkPassword() == false)
        alert('La contraseña no coincide');
      else {
        let vm = this;
        const userData = {
                            name: this.user.name,
                            email: this.user.email,
                            password: this.password,
                            password_confirmation: this.password_confirmation
                          };
        axios.post('/api/users/editpassword', userData)
          .then((response) => {
            if (response.data.success) {
              
              location.href="/logout";
              vm.loading = false;
            } else {
              alert(response.data.data);
              vm.loading = false;
            }
          }).catch((error) => {
            alert('error: '+error);
            vm.loading = false;
        })
      }
    },
    checkPassword(){
      const check = (this.password_confirmation == null || this.password_confirmation == '' || this.password == null || this.password == '' || this.password_confirmation != this.password)
      return !check
    }
  },

}
</script>

