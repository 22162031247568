import axios from 'axios'
import Store from '../store'


axios.interceptors.request.use((config) => {
    if (localStorage.getItem('token')!==null) {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    }
    config.baseURL = process.env.VUE_APP_BASE_URL;
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    if (response.data.code === 201) {
      Store.commit('success/pushSuccess', response.data.message);
    }
    return response;
}, (error) => {
    if (error.response.status === 401) {
      console.log("You are not authorized");
      localStorage.removeItem('token')
    }
    if (error.response.status === 422) {
      Store.commit('errors/pushMsg', "asdsadadsdsads");
      Store.commit('errors/pushErrors', error.response.data.errors);
    }
    return error.response
});

export default axios