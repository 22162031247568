<template>
    <b-container fluid>
      <div class="loading" v-if="isLoading">Loading&#8230;</div>
      <div class="row mt-3">
        <b-col class="d-flex justify-content-start">
            <h4>Marcas de producto</h4>
        </b-col>
      </div>
      <!-- ----------------------- BUSCAR ------------------------ -->
      <b-row class="d-flex justify-content-between mt-2">
        <b-col cols="md-2 text-left">
          <div
            @submit.stop.prevent="doSearch" >
            <div class="input-group">
              <input
                v-model="doSearch"
                type="search"
                placeholder="Buscar..."
                class="form-control"
              >
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- ----------------------- TABLAS ----------------------- -->
      <b-row>
        <b-col cols="md-8">
          <vdtnet-table
            :fields="fields"
            :opts="opts"
            :selectCheckbox="0"
            @edit="getBrand"
            @delete="deleteBrand"
            ref="table"
          />
        </b-col>
        <!-- ----------------------- FORMULARIO ------------------- -->
        <b-col>
          <div class="card mb-4"> 
            <div class="card-header">
              <h6 v-if="brand.id == null">
                Nueva marca de productos
              </h6>
              <h6 v-else>Editar marca</h6>
              <b-button variant="outline-primary" size="sm"
                type="button"
                v-if="brand.id != null"
                @click="brand = emptyBrand">
                Nueva marca
              </b-button>
            </div>
            <div class="card-body">
              <b-form @submit.prevent>
                <b-form-group>
                  <label class="control-label" for="input-live">Nombre:</label>
                  <b-form-input
                    id="input-live"
                    v-model="brand.name"
                    :state="nameState"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="Nombre de la marca"
                    trim>
                  </b-form-input>
                  <!-- This will only be shown if the preceding input has an invalid state -->
                  <b-form-invalid-feedback
                    v-if="nameState === false"
                    id="input-live-feedback"
                  >
                    El nombre debe tener un mínimo de 3 letras
                  </b-form-invalid-feedback>
                  <!-- This is a form text block (formerly known as help block) -->
                  <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                </b-form-group>
                <b-form-group>
                  <label class="control-label mt-3" for="input-live">Descripción:</label>
                  <b-form-input
                  class="form-control"
                    id="input-live"
                    v-model="brand.description"
                    :state="nameState"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="Descripción de la marca"
                    trim
                  ></b-form-input>
                  <!-- This will only be shown if the preceding input has an invalid state -->
                  <b-form-invalid-feedback
                    v-if="nameState === false"
                    id="input-live-feedback"
                  >
                    La descripción debe tener un mínimo de 3 letras
                  </b-form-invalid-feedback>
                  <!-- This is a form text block (formerly known as help block) -->
                  <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                </b-form-group>
              </b-form>
            </div>
            <div class="card-footer">
              <b-button variant="orange" size="lg" class="mt-3"
                  v-if="nameState === null" @click="saveBrand()">
                  Guardar marca 
                </b-button>
            </div>
          
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import { mapState } from 'vuex';

export default {
  components: {
    VdtnetTable
  },
  data: function() {
    return {
      doSearch: '',   
      isLoading: false,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/brands/list",
          type: "GET",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
      },
      fields: {
        name: { label: "Marca", searchable: true },
        description: { label: "Descripción" },
        id: { visible: false },
        actions: {
          isLocal: true,
          label: "Acciones",
          width: "123px",
          defaultContent:
            '<div class="divActions">' +
            '<button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">'+
            '<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>'+
            '<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>'+
            '</svg></button>' +
            '<button data-action="delete" data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
            '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
            '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
            '</svg></button>' +
            "</div>",
        },
      },
      brand: {
        id: null,
        name: "",
        description: "",
      },

      emptyBrand: {
        id: null,
        name: "",
        description: "",
      },
    };
  },
  computed: {
  ...mapState('brands',{
        brands: state => state.brands,
      }),
    nameState() {
      return this.brand.name.length < 3 && this.brand.name.length > 0
        ? false
        : null;
    },
  },
  created() {
    this.$store.dispatch('brands/getBrands')
  },
  watch: {
    doSearch(newValue, oldValue) {
      if(newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch)
      }
      if(newValue === '' && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch)
      }
    },
  },
  methods: {
    getBrand(data) {
      var vm = this;
      vm.isLoading = true
      this.$http.get("/api/brands/getBrand/" + data.id).then((response) => {
        vm.brand = response.data.data;
        vm.isLoading = false
      });
    },

    deleteBrand(data) {
      if (confirm("Esta seguro que desea borrar esta marca?") == true) {
        this.isLoading = true
        this.$http
          .post("/api/brands/delete", { id: data.id })
          .then((response) => {
            
            if (response.data.code == 201) {
              this.$refs.table.reload();
              this.brand = this.emptyBrand;
              this.isLoading = false;
            }
          });
      }
    },

    saveBrand() {
      if (this.brand.id != null) {
        this.isLoading = true
        this.$http
          .post("/api/brands/edit/" + this.brand.id, this.brand)
          .then((response) => {
            this.$refs.table.reload();
            this.brand = this.emptyBrand;
            this.isLoading = false;
          });
      } else {
        this.isLoading = true
        this.$http.post("/api/brands/add", this.brand).then((response) => {
          this.$refs.table.reload();
          this.brand = this.emptyBrand;
          this.isLoading = false;
        });
      }
    },
  },
};
</script>
