<template>
    <b-container fluid>
        <div class="row mt-3">
            <b-col class="d-flex justify-content-start">
                <h4>Pedidos de la tienda online</h4>
            </b-col>
        </div>
        <b-row class="d-flex justify-content-between my-2">
        <!-- -------------------- BUSCADOR -------------------------- -->
        <b-col cols="md-2 text-left">
            <input type="search" class="form-control input-sm" 
            v-model="filters.q"
            placeholder="Buscar...">
        </b-col>
        <!-- -------------------- FILTRO MOSTRAR ------------------- -->
        <b-col cols="md-2 text-left">
            <b-form inline>
                <label>Mostrar  
                    <select class="form-control input-sm ml-2" v-model="filters.itemsPerPage">
                        <option :value="10">10</option>
                        <option :value="25" selected>25</option>
                        <option :value="50">50</option>
                    </select>
                </label>
            </b-form>
        </b-col>
        <b-col cols="md-2 text-left">
            <b-form inline>
                <input class="mr-2" type="checkbox" v-model="filters.oninvoiced">
                <label for="oninvoiced" class="invoiced">Mostrar pedidos terminados</label>
            </b-form>
        </b-col>
        </b-row>

        <!-- ----------------------- TABLA -------------------------- -->
        <b-row>
            <b-col cols="sm-12">
                <b-table-simple striped small caption-top responsive>
                    <b-thead>
                        <b-tr role="row">
                            <b-th class="col-sm-1" style="text-align:center;">Fecha</b-th>
                            <b-th class="col-sm-1" style="text-align:center;">N°</b-th>
                            <b-th class="col-sm-3" style="text-align:center;">Titular</b-th>
                            <b-th class="col-sm-2" style="text-align:center;">E-mail</b-th>
                            <b-th class="col-sm-2" style="text-align:center;">Total</b-th>
                            <b-th class="col-sm-1" style="text-align:center;">Pago</b-th>
                            <b-th class="col-sm-1" style="text-align:center;">Estado</b-th>
                            <b-th class="col-sm-1" style="text-align:center;"></b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-if="orders.length<1"><b-td colspan="8">No hay registros disponibles</b-td></b-tr>
                        <b-tr role="row" v-for='order in orders' :key='order.id'>
                            <b-td class="col-sm-1">{{order.created_at}}</b-td>
                            <b-td class="col-sm-1">{{order.number}}</b-td>
                            <b-td class="col-sm-3">{{order.client_name}}</b-td>
                            <b-td class="col-sm-2">{{order.client_email}}</b-td>
                            <b-td class="col-sm-2">{{order.total}}</b-td>
                            <b-td class="col-sm-1">{{order.paymethod}}</b-td>
                            <b-td class="col-sm-1">
                                <b-button-group v-if="order.status!='CANCELED'" class="btn-group">
                                    <button type="button" class="btn btn-light btn-sm">
                                        <template v-if="order.status=='PENDING'">PENDIENTE</template>
                                        <template v-if="order.status=='MAKING'">PREPARANDO</template>
                                        <template v-if="order.status=='READY'">TERMINADA</template>
                                        <span class="caret"></span>
                                    </button>
                                    <b-dropdown right variant="light">
                                        <b-dropdown-item v-bind:class="{'selected': (order.status=='PENDING')}">
                                            <a @click="setStatus(order.id,'PENDING')" >PENDIENTE</a>
                                        </b-dropdown-item>
                                        <b-dropdown-item v-bind:class="{'selected': (order.status=='MAKING')}">
                                            <a @click="setStatus(order.id,'MAKING')">PREPARANDO</a></b-dropdown-item>
                                        <b-dropdown-item v-bind:class="{'selected': (order.status=='READY')}">
                                            <a @click="setStatus(order.id,'READY')">TERMINADA</a>
                                        </b-dropdown-item>
                                        <b-dropdown-item>
                                            <a @click="setStatus(order.id,'CANCELED')">ANULADA</a>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-button-group>
                                <div v-else><label>ANULADA</label></div>
                            </b-td>
                            <b-td class="col-sm-1">
                                <b-button-group>
                                    <div class="divActions btn-group-sm">
                                        <button  @click="seeDetail(order.id)" data-action="seeDetail" data-toggle="tooltip" title="Ver detalle" type="button" class="btn btn-light marginButton">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-ruled" viewBox="0 0 16 16">
                                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z"/>'+
                                            </svg>
                                        </button>
                                        <button v-if="order.status=='CANCELED'" @click="deleteOrder(order.id)" data-action="deleteOrder" data-toggle="tooltip" title="Eliminar nota" type="button" class="btn btn-light marginButton">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
                                            </svg>
                                        </button>
                                    </div>                                   
                                </b-button-group>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
        <!-- ----------------------- PAGINACION -------------------------- -->
        <div class="row vdtnet-footer">
          <div class="col-sm-10 col-md-3">
            <div class="vdtnetable1_info">
              Mostrando {{ (filters.page - 1) * filters.itemsPerPage + 1 }} a {{
                (Math.min(filters.page * filters.itemsPerPage) > orders.length)
                ? totalItems
                : Math.min(filters.page * filters.itemsPerPage)
              }} de {{ totalItems }} registros
            </div>
          </div>
          <div class="col-sm-10 col-md-7">
            <div class="dataTables_paginate paging_simple_numbers">
              <ul class="pagination">
                <li class="page-item" v-bind:class="[{'disabled': filters.page==1}]">
                  <a class="page-link" href="#" v-on:click="prevPage()">Anterior</a>
                </li>
                <li v-if="(filters.page - (pageInterval/2))>0" class="page-item">
                  <a class="page-link" href="#" v-on:click="prevPage()">...</a>
                </li>
                <template v-for="index in totalPages">
                  <li :key="index" v-if="inIntervalPage(index)" class="page-item" v-bind:class="[{'active': filters.page==index}]">
                    <a class="page-link" href="#" v-on:click="changePage(index)">{{index}}</a>
                  </li>
                </template>
                <li v-if="totalPages>(filters.page + (pageInterval/2))" class="page-item">
                  <a class="page-link" href="#" v-on:click="nextPage()">...</a>
                </li>
                <li class="page-item" v-bind:class="[{'disabled': filters.page>=totalPages}]">
                  <a class="page-link" href="#" v-on:click="nextPage()">Siguiente</a>
                </li>
              </ul>
            </div>
            <div class="dataTables_length">
              <label class="mr-2">Mostrar: </label>
              <b-form-select v-model="filters.itemsPerPage" style="width: 60px;">
                <option :value="15" selected="selected">15</option>
                <option :value="25">25</option>
                <option :value="50">50</option>
              </b-form-select>
              <label class="ml-2 mr-5">registros</label>
            </div>
          </div>
        </div>


    </b-container>
</template>
<script>

export default {
  props: [],
  data() {
    return {
        orders:[],
        filters:{
            q:'',
            oninvoiced:false,
            itemsPerPage:25,
            page:1,
            sorting:{
                col:'',
                order:'desc'
            }
        },
        totalPages:1,
        pageInterval:16,
        totalItems: 0,
    }
  },
  mounted() {
      this.getOrders();
  },
  methods: {
    getOrders(){
        var vm= this;
        this.$http.post('/api/ecom/orders/getOrders', this.filters)
          .then(response => {
            if(response.data.success) {
                vm.orders = response.data.data.list;
                vm.totalPages=response.data.data.totalPages;
                vm.totalItems=response.data.data.totalItems;
            }else{
                vm.orders=[];
                vm.totalPages=0;
            }
        })
        .catch(error => {
            alert(error);
        })
    },
    sortList(col){
        if(this.filters.sorting.col == col){
            if(this.filters.sorting.order=='asc'){
                this.filters.sorting.order='desc';
            }else{
                this.filters.sorting.order='asc';
            }
        }else{
            this.filters.sorting.col=col;
            this.filters.sorting.order='asc';
        }
        this.getOrders();
    },
    prevPage(){
        if(this.filters.page>1){
            this.filters.page = this.filters.page-1;
            this.getOrders();
        }
    },
    nextPage(){
        if (this.filters.page<this.totalPages){
            this.filters.page = this.filters.page +1;
            this.getOrders();
        }
    },
    changePage(data){
        this.filters.page = data;
        this.getOrders();
    },
    inIntervalPage(index){
        if(this.totalPages<=this.pageInterval){return true;}

        let limitprev = this.filters.page - (this.pageInterval/2);
        let limitnext = this.filters.page + (this.pageInterval/2);

        if(limitprev<0){
            limitprev=0;
            limitnext=this.pageInterval;
        }

        if(index>limitprev && index<limitnext){return true;}

        return false;
    },
    setStatus(id, status){
        var vm= this;
        let outdata={
            'id':id,
            'status':status,
        };
        if(outdata.status == 'CANCELED'){
            if(confirm("¿Está seguro que desea anular esta orden de pedido? Los cambios son irreversibles") == true){
                this.$http.post('/api/ecom/orders/updateStatus', outdata)
                .then(response => {
                    vm.getOrders();
                })
                .catch(error => {
                    alert(error);
                })
            }
        }else{
            this.$http.post('/api/ecom/orders/updateStatus', outdata)
            .then(response => {
                vm.getOrders();
            })
            .catch(error => {
                alert(error);
            })
        }
    },
    deleteOrder(id){
        var vm=this;
        this.$http.get('/api/ecom/orders/deleteOrder/' + id)
          .then(response => {
            vm.getOrders();
          })
          .catch(error => {
            alert(error);
          })
    },
    seeDetail(id){
        window.location.href = '/ecom/order/' + id;
    }
  },
  watch: {
      'filters.q': {handler: function (a) {this.getOrders();}},
      'filters.oninvoiced': {handler: function (a) {this.getOrders();}},
      'filters.itemsPerPage': {handler: function (a) {this.getOrders();}},
  },
}
</script>


