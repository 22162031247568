<template>
  <b-container>
    <div class="row">
      <b-col class="d-flex justify-content-star mt-4">
        <h3>Consultar precio</h3>
      </b-col>
    </div>
    <!------------- FILTROS ----------------->
    <div class="card bg-light rounded-sm p-4 my-3 border">
      <div class="input-group input-group-lg">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-lg">Codigo: </span>
        </div>
        <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" v-model="code" v-on:keypress="lookupProducts" v-focus placeholder="Ingresa cod. de producto...">
      </div>
    </div>

    <b-card class="my-4 p-4 bg-light text-left" v-if="product.id != null">
      <div class="row">
        <div class="col-12 col-md-4 my-4">
          <img class="product-image rounded" style="height: 250px;" :src="prodImage.length === 0 ? '/ph-product.png' : createurl(prodImage)" />
        </div>
        <div class="col-12 col-md-8 my-4">
          <h1>{{ product.name }}</h1>
          <h1 class="display-2 my-4" >{{ formatoDinamicoMoneda(product.price) }} </h1>
          <h5 class="mx-2 mt-4">Cod Int: {{ product.code }}</h5>
          <h5 class="mx-2 mt-4">Cod Barra: {{ product.barcode }}</h5>
        </div>
      </div>
    </b-card>
    <b-card class="bg-light d-flex justify-content-center" v-else style="height: 316px!important;">
      <div class="row mt-5">
        <div class="col-12 mt-5">
          <h1>ESCANEA EL CODIGO DE BARRA DEL PRODUCTO</h1>
        </div>
      </div>
    </b-card>
  </b-container>
</template>

  <script>
  import { mapState } from 'vuex'
  import formatMoneyMixin from "../../../mixins/formatMoneyMixin";

  export default {
    mixins: [formatMoneyMixin],
    directives: {
      focus: {
        inserted: function (el) {
          el.focus();
        }
      }
    },
    data: function() {
      return {
        bucket: 'productimages-qupro-prod',
        region: 'sa-east-1',
        code: '',
        id_code: null,
        typeah: !this.showBarcodes,
        product: [],
        prodImage: [],        
      };
    },
    computed: {
      ...mapState('authUser',{
        authUser: state => state.authUser,
      }),
    },
    created () {
      this.$store.dispatch('authUser/getAuthUser')      
    },
    methods: {
      createurl(img) {
        return 'https://'+this.bucket+'.s3.'+this.region+'.amazonaws.com/company_'+this.authUser.company_id+'/'+img;
      },
      refreshTable() {
        var vm = this;
        if (vm.$refs.table !== undefined) vm.$refs.table.reload();
      },
      lookupProducts: function(e) {
        var app = this;
        if (e.key === 'Enter' ) {
          app.addProductByCode();
        }
      },
      addProductByCode: function(e){
        var app = this;

        var code = app.code.split(app.separator);
        var qu=(code.length !== 1) ? parseInt(code[0]) : 1;
        var co=(code.length !== 1) ? code[1] : code;

        if (co!=null && co>0) {
          axios.get('/api/products/getProductByCodeAndBarcode/'+co+'/1')
          .then(function(response) {
            app.product = response.data.data.products;
            if(response.data.data.prodImage.length > 0){
              app.prodImage = response.data.data.prodImage[0].url;
            }
          });
        }
        setTimeout(() => {
          location.reload();
        }, 3000);
      },
      
    },
  };
  </script>

  <style>
  .active {
    font-weight: 800;
  }
  </style>