import axios from 'axios'

export const getUser = ({ commit }) => {
  commit('setLoading', true);
  axios.get('/api/users/me')
    .then(response => {
      commit('setUser', response.data.data)
    })
    // fail
    .catch(e => {
      commit('setUser', e.response)
    })
    .finally(() => {
      commit('setLoading', false);
    });
}