export const pushMsg = (state, message) => {
  state.message = message
}
export const pushErrors = (state, errors) => {
  state.errors = errors
}

export const clearErrors = (state) => {
  state.errors = null
}

export const setLoading = (state, value) => {
  state.loading = value;
} 
