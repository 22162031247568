<template>
    <b-container>
        <div v-if="orderNote" class="ticket">
            <!-- ORIGINAL -->
            <p>
                <br><span id="tipo"></span>
                <span><p>NOTA DE PEDIDO</p></span>
                <span><br>COMPROBANTE NO VALIDO COMO FACTURA</span>
            </p>
            <br>PEDIDO Nro: {{orderNote.number}}
            <br>FECHA: {{ moment().format('DD-MM-YYYY') }}
            <br>HORA: {{ moment().format('HH:mm:SS')  }}
            <br>
    
            <br><strong>PEDIDO:</strong>
            <br>
            <template v-for="value in orderNote.ordernote_details">
                <br :key="value.id">{{ value.quantity }}X {{ value.description.toUpperCase() }}
                <br>            
            </template>
            <br>
     
        </div>
    
        <button class="btn btn-lg btn-orange" @click="imprimir">Imprimir</button>
        <button class="btn btn-lg btn-primary ml-5" onclick="window.location.href = '/ventas/notas-de-pedido'">Volver</button>
    </b-container>
    </template>
    
    <script>
    import formatMoneyMixin from "../../mixins/formatMoneyMixin";
    import { mapState } from 'vuex'
    
    export default {
        props: ['orderNoteId'],
        mixins: [formatMoneyMixin],
        data: function() {
        return {
                orderNote: null,
                clientId: null,
            }
        },
        mounted() {
            this.getOrdernote(this.orderNoteId);
        },
        created () {
          this.$store.dispatch('authUser/getAuthUser')
        },
        computed: {
            ...mapState('authUser',{
             authUser: state => state.authUser,
          }),
          totalca () {
                return this.sale.client_accounts.reduce((partialSum, e) => partialSum + e.amount, 0);
            }
        },
        methods: {
            getOrdernote(orderNoteId){
                var vm=this;
                axios.get('/api/ordernotes/getOrdernote/' + orderNoteId)
                .then(response => {
                    if(response.data.success) {
                        vm.orderNote = response.data.data;
                        vm.clientId = response.data.data.client_id;
                    }
                })
                .catch(error => {
                    alert(error);
                })
            },
            imprimir() {
                var ua = navigator.userAgent.toLowerCase();
                var isAndroid = ua.indexOf("android") > -1;
    
                if (isAndroid) {
                    document.getElementById('tipo').innerHTML = 'ORIGINAL';
                    window.print();
                }else{
                    document.getElementById('tipo').innerHTML = 'ORIGINAL';
                    window.print();
                }
            },
        },
        
    }
    </script>
    
    <style>
    * {
        font-size: 12px;
        font-family: Arial, Helvetica, sans-serif;
    }
        
    .ticket {
        color: black;
        width: 155px;
        max-width: 155px;
    }
    
    @media print {
        .oculto-impresion,
        .oculto-impresion * {
            display: none !important;
            height: 0;
        }
    }
    </style>