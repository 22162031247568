<template>
  <div class="form-inline text-right">
    <b-form-group>
      <b-form-select v-model="branchofficeId" ref="select">
          <b-form-select-option value="">Todas las sucursales</b-form-select-option>
          <b-form-select-option v-for="branchoffice in branchoffices" :key="branchoffice.id" :value="branchoffice.id">{{ branchoffice.name }}</b-form-select-option>
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: function () {
    return {
       branchofficeId: ''
    }
  },
  computed: {
    ...mapState('branchoffices',{
      branchoffices: state => state.branchoffices,
      selectedBranchofficeId: state => state.selectedBranchofficeId,
    })
  },
 
  watch: {
    branchoffices (newValue, oldValue) {
      this.branchofficeId = this.selectedBranchofficeId
    },
    selectedBranchofficeId(newValue, oldValue)  {
      this.branchofficeId = newValue
    },
    branchofficeId: {
      handler: function (value) {
        this.$store.dispatch('branchoffices/setSelectedBranchofficeId', value)
      }
    }
  },
  created () {
    this.$store.dispatch('branchoffices/readSelectedBranchofficeId')
    this.$store.dispatch('branchoffices/getAllBranchoffices')
  }
};
</script>
