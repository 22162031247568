<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>
          Actualizador de precios por lote
        </h4>
      </b-col>
      </div> 
    <transition name="fade">
        <div class="alert alert-danger alert-dismissible fade show d-flex mt-2" role="alert">
          <i class="bi bi-info-circle mr-2"></i>
          <strong>Ayuda:</strong>
          <p class="ml-1">
            Esta herramienta realiza cambios en la base de datos que son irreversibles. Primero filtre los productos y luego verifique bien los precios a actualizar antes de realizar la acción.
          </p>
        </div>
      </transition>
    <!-- ----------------------- TABLAS -------------------------- -->
    <b-row>
      <b-col cols="md-8">
        <vdtnet-table
              v-if="showTable"
              :fields="fields"
              :opts="opts" 
              :selectCheckbox = 0
              ref="table"              
            />
      </b-col>
      <b-col cols="md-4" class="mt-1">
          <ActualizarProducto
            @filters="applyFilters"
            @increase-change="updateIncrease"
            @confirmsave="refreshTable()" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import VdtnetTable from 'vue-datatables-net';
import ActualizarProducto from './Actualizar-producto.vue';
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";

export default {
    components: {
      VdtnetTable,
      ActualizarProducto
    },
    mixins: [formatMoneyMixin],
    data: function() {
        return {               
          doSearch: '',      
          showTable: true,
          new_price: 0,
          increase: {
            percentage: 0,
            multiple: 1
          },
          multiples: 1,
          newPrice: null,
          fund: null,
          opts: {
            language: {
            url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
            },
            ajax: {
              url: process.env.VUE_APP_BASE_URL+"/api/products/listdt",
              type: "POST",
              headers: { Authorization: "Bearer "+localStorage.getItem("token") },
                data: {
                  priceList_id: 'all',
                  provider_id: 'all',
                  category_id: 'all',
                  brand_id: 'all',
                  percentage: -1,
                  multiple: 0
                }
              },
            serverSide: true
          },
          fields: {
              id: { visible: false },
              name: { label: "Producto", searchable: true },
              stock: { label: "Stock actual"},
              price: { label: "Precio actual"},
              new_price: { label: "Precio nuevo", data: 'price', render: (data) => {
                if (!data) {
                  return ''
                } else {
                  var total = parseFloat(data) + ((data / 100) * this.increase.percentage);
                  return `${ parseFloat(Math.round(total/parseFloat(this.multiples ?? 1))*this.multiples) }`
                }
              }
            },
          },
        }
    },
    watch: {
      doSearch(newValue, oldValue) {
        if(newValue.length >= 4 || oldValue.length >= 4) {
          this.$refs.table.search(this.doSearch)
        }
        if(newValue === '' && oldValue.length > 0) {
          this.$refs.table.search(this.doSearch)
        }
      },
    },
    methods: {
      applyFilters(value) {
       
        this.opts.ajax.data = value

        this.showTable = false

        this.$nextTick(() => {
          this.showTable = true
        });

      },
      updateIncrease(data) {
        this.increase = data
        this.multiples = data.multiple
        this.refreshTable()
      },
      refreshTable() {
        if (this.$refs.table !== undefined) this.$refs.table.reload();
      }
    }
  }
</script>

