<template>
  <transition name="modal">
    <div class="modal-mask-product">
      <div class="modal-dialog">
        <div class="modal-content p-3 rounded">
          <div class="modal-header">
            <h4 class="card-title" v-if="provider.id == null">Nuevo proveedor</h4>
            <h4 class="card-title" v-else>Editar proveedor</h4>
            <b-button variant="primary size-sm" @click="close()"><i class="bi bi-x-lg"></i></b-button>
          </div>
          <div class="modal-body">
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <label class="control-label" for="input-live">Nombre del proveedor:</label>
                  <b-form-input
                  class="form-control"
                  id="input-live"
                  v-model="provider.name"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Nombre proveedor..."
                  trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <!-- Razon social -->
              <b-col cols="12">
                <b-form-group>
                  <label class="control-label" for="input-live">Nombre razón social:</label>
                  <b-form-input
                  class="form-control"
                  id="input-live"
                  v-model="provider.business"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Razon social..."
                  trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <!-- CUIT - DNI -->
              <b-col cols="12">
                <b-form-group>
                  <label class="control-label" for="input-live">CUIT / DNI:</label>
                  <b-form-input
                    class="form-control"
                    id="input-live"
                    v-model="provider.cuit"
                    :state="codigoValido"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="CUIT / DNI..." 
                    trim
                  ></b-form-input>     
                </b-form-group>
              </b-col>
              <!-- Tipo IVA -->
              <b-col cols="12">
                <b-form-group>
                  <label class="control-label" for="input-live">Tipo de IVA:</label>
                  <b-form-select class="form-control" v-model="provider.iva_type" :options="optionsUnit"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- Telefono -->
              <b-col cols="12">
                <b-form-group>
                  <label class="control-label" for="input-live">Teléfono:</label>
                  <b-form-input
                  id="input-live"
                  v-model="provider.phone_1"
                  :state="codigoValido"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Telefono..."
                  trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <!-- Telefono celular -->
              <b-col cols="12">
                <b-form-group>
                    <label class="control-label" for="input-live">Teléfono celular:</label>
                    <b-form-input
                    class="form-control"
                    id="input-live"
                        v-model="provider.phone_2"
                        :state="codigoValido"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="Telefono celular..."
                        trim
                    ></b-form-input>
                </b-form-group>
              </b-col>
              <!-- E-mail -->
              <b-col cols="12">
                <b-form-group>
                    <label class="control-label" for="input-live">E-Mail:</label>
                    <b-form-input
                    class="form-control"
                        id="input-live"
                        v-model="provider.email"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="E-mail..."
                        trim
                    ></b-form-input>
                </b-form-group>
              </b-col>              
              <!-- Direccion -->
              <b-col cols="12">
                <b-form-group>
                    <label class="control-label" for="input-live">Dirección:</label>
                    <b-form-input
                    class="form-control"
                        id="input-live"
                        v-model="provider.address"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="Direccion..."
                        trim
                    ></b-form-input>
                </b-form-group>
              </b-col>
              <!-- Provincia -->
              <b-col cols="12">
                <b-form-group>
                    <label class="control-label" for="input-live">Provincia:</label>
                    <b-form-select class="form-control" v-model="provider.province">
                      <b-form-select-option :value="null" disabled
                        >Seleccionar</b-form-select-option
                      >
                      <b-form-select-option value="Capital Federal"
                        >CABA</b-form-select-option
                      >
                      <b-form-select-option value="Buenos Aires"
                        >Buenos Aires</b-form-select-option
                      >
                      <b-form-select-option value="Catamarca"
                        >Catamarca</b-form-select-option
                      >
                      <b-form-select-option value="Chaco"
                        >Chaco</b-form-select-option
                      >
                      <b-form-select-option value="Chubut"
                        >Chubut</b-form-select-option
                      >
                      <b-form-select-option value="Corrientes"
                        >Corrientes</b-form-select-option
                      >
                      <b-form-select-option value="Cordoba"
                        >Córdoba</b-form-select-option
                      >
                      <b-form-select-option value="Entre Rios"
                        >Entre Ríos</b-form-select-option
                      >
                      <b-form-select-option value="Formosa"
                        >Formosa</b-form-select-option
                      >
                      <b-form-select-option value="Jujuy"
                        >Jujuy</b-form-select-option
                      >
                      <b-form-select-option value="La Pampa"
                        >La Pampa</b-form-select-option
                      >
                      <b-form-select-option value="La Rioja"
                        >La Rioja</b-form-select-option
                      >
                      <b-form-select-option value="Mendoza"
                        >Mendoza</b-form-select-option
                      >
                      <b-form-select-option value="Misiones"
                        >Misiones</b-form-select-option
                      >
                      <b-form-select-option value="Neuquen"
                        >Neuquen</b-form-select-option
                      >
                      <b-form-select-option value="Rio Negro"
                        >Río Negro</b-form-select-option
                      >
                      <b-form-select-option value="Salta"
                        >Salta</b-form-select-option
                      >
                      <b-form-select-option value="San Juan"
                        >San Juan</b-form-select-option
                      >
                      <b-form-select-option value="San Luis"
                        >San Luis</b-form-select-option
                      >
                      <b-form-select-option value="Santa Cruz"
                        >Santa Cruz</b-form-select-option
                      >
                      <b-form-select-option value="Santa Fe"
                        >Santa Fe</b-form-select-option
                      >
                      <b-form-select-option value="Santiago del Estero"
                        >Santiago del Estero</b-form-select-option
                      >
                      <b-form-select-option value="Tierra del Fuego"
                        >Tierra del Fuego</b-form-select-option
                      >
                      <b-form-select-option value="Tucuman"
                        >Tucumán</b-form-select-option
                      >
                    </b-form-select>
                    <b-form-text id="input-live-help"
                      >Campo obligatorio</b-form-text>
                </b-form-group> 
              </b-col>
              <!-- Localidad -->
              <b-col cols="12">
                <b-form-group>
                    <label class="control-label" for="input-live">Localidad:</label>
                    <b-form-input
                    class="form-control"
                        id="input-live"
                        v-model="provider.city"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="Localidad..."
                        trim
                    ></b-form-input>
                </b-form-group>
              </b-col>           
              <!-- Codigo postal -->
              <b-col cols="12">
                <b-form-group>
                    <label class="control-label" for="input-live">Código Postal:</label>
                    <b-form-input
                    class="form-control"
                        id="input-live"
                        v-model="provider.postalcode"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="Codigo postal..."
                        trim
                    ></b-form-input>
                </b-form-group>
              </b-col>
              <!-- Limite cuenta corriente -->
              <!--
              <b-form-group>
                
                <label class="control-label"  for="input-live">Límite de Cuenta Corriente:</label>
                <b-form-input
                class="form-control"
                id="input-live"
                v-model="provider.limit"
                :state="codigoValido"
                aria-describedby="input-live-help input-live-feedback"
                trim
                ></b-form-input>
              </b-form-group>
              -->
              <!-- Notas -->
              <b-col cols="12">
                <b-form-group>
                    <label class="control-label" for="input-live">Observaciones</label>
                    <b-form-textarea
                    id="input-live"
                    v-model="provider.notes"
                    aria-describedby="input-live-help input-live-feedback"
                    trim
                    rows="3"
                    max-rows="6"
                    ></b-form-textarea>
                </b-form-group>
              </b-col>
              <!-- Transporte -->
              <!--
              <b-form-group>
                <b-row>
                  <b-col>     
                      <label class="control-label" for="input-live">Transportes:</label>
                      <b-form-select class="form-control" v-model="filters.transports_id">
                          <option :value="null">Ninguno</option>
                          <option v-for="transport in transports" :key="transport.id" :value="transport.id">{{ transport.name }}</option>
                      </b-form-select>
                    </b-col>
                  <b-col cols="md-2">
                    <input type="button" @click="addTransport()" id="addT"/>
                  </b-col>
                  <b-col cols="md-2">
                      <input type="button" @click="removeTransport()" id="remT"/>
                  </b-col>
                </b-row>  
              </b-form-group>
              -->
            </b-row>
          </div>
          <!-- BOTON -->
          <div class="modal-footer">
            <b-button class=" mr-5" variant="outline-primary" size="lg" @click="close">
              Cancelar
            </b-button>
            <a
            @click="saveProvider()"
            href="#import_providers"
            data-toggle="tab">
              <b-button v-if="provider.id == null" variant="orange" size="lg">Agregar proveedor</b-button>
              <b-button v-if="provider.id !== null" variant="orange" size="lg">Actualizar proveedor</b-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
  import SelectTransports from "../../sub-components/Select-transports.vue";

  export default {
    components: { SelectTransports },
    props: ['providerId'],
    data() {
      return {
        provider: {
          id: null,
          name: "",
          code: null,
          address: null,
          phone_1: null,
          phone_2: null,
          email: null,
          cuit: null,
          iva_type: null,
          business: null,
          postalcode: null,
          city: null,
          province: null,
          notes: null,
          t_linked: null
        },
        emptyProvider: {
          id: null,
          name: "",
          code: null,
          address: null,
          phone_1: null,
          phone_2: null,
          email: null,
          cuit: null,
          iva_type: "Consumidor Final",
          business: null,
          postalcode: null,
          city: null,
          province: null,
          notes: null,
          t_linked: null
        },
        filters: {
          transport_id: null
        },
        providers: [],
        transports: [],
        selected: null,     
        showNewProvider: true,
        // showNewProviderModal: false,
        providerIdParent: null,
        pricelists: [],
        optionsUnit: [
          { value: 'Consumidor Final', text: 'Consumidor Final' },
          { value: 'Responsable Inscripto', text: 'Responsable Inscripto' },
          { value: 'Exento', text: 'Exento' },
          { value: 'Monotributo', text: 'Monotributo' },
        ]
      }
    },
    mounted() {
      this.providerIdExist();
      this.resetForm();
    },
    methods: {
      providerIdExist() {
        if(this.providerId != null) {
          let id = this.providerId;
          this.getProvider(id);
        } else{
          this.resetForm();
        }
        
      },
      getProvider(id) {
        var vm = this;
        this.$http.get('/api/providers/getProvider/' + id).then((response) => {
          vm.provider = response.data.data
        })
      },
      updateTransports(t_list){
        this.provider.t_list= t_list;
      },
      saveProvider() {
        var vm = this;
        if (this.provider.id != null) {
          this.$http.post('/api/providers/edit/' + this.provider.id, this.provider)
          .then((response) => {
            
              vm.resetForm();
              vm.$emit('confirmsave');
              vm.$emit('close');
          })
        } else {
          this.$http.post('/api/providers/add', this.provider)
          .then((response) => {
            vm.resetForm();
            vm.$emit('confirmsave');
            vm.$emit('close');
          })
        }
      },
      resetForm() {
        this.provider = Object.assign({}, this.emptyProvider);
      },
      close() {
        this.$emit('close');
      }
    },
    computed: {
      codigoValido() {
        return isNaN(this.provider.code) ? false : null
      }
    },
  }
</script>

<style scoped>

.modal-mask-product {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow:hidden;
}

.modal-body {
  max-height: 70vh; /* Ajusta esto según tus necesidades */
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>