<template>
  <b-container fluid>
    <div class="row mt-3">
        <b-col class="d-flex justify-content-start">
          <h4>Saldos de Cuentas corrientes de clientes</h4>
        </b-col>
    </div>
    <b-row class="d-flex justify-content-center">
      <b-col cols="lg-12 md-12 sm-12 xs-12">
        <div class="stats">
          <div class="stat">
            <div class="number">{{ formatoDinamicoMoneda(total)}}</div>
            <div class="desc" > Balance de cuentas corrientes <p v-if="toDate!= null"> hasta {{ toDate }}</p></div>
          </div>
        </div>
      </b-col>
    </b-row>
    
    
    <div class="card bg-light rounded-sm p-3 my-2 border">
      <div class="row d-flex justify-content-between">
        <div class="col col-md-2">
          <label class="control-label">Hasta fecha: </label>
          <datepicker-component v-on:datechoosed="toDateUpdated" :dateLabel="'Seleccione una fecha'"/>
        </div>
        <div class="col col-md-2">
            <input v-model="noclerks" class="load-data" type="checkbox" value="true" checked="checked">
            <label for="onlymovs">Sólo clientes sin vendedores</label>
        </div>
        <div class="col col-md-2">
          <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary mr-2" value="Exportar CSV">
          <input type="button" @click="exportPDF()" class="btn btn-md btn-outline-primary" value="Exportar PDF">
        </div>
      </div>
    </div>

    <b-row>
      <b-col cols="lg-12 md-12 sm-12 xs-12">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          ref="table"
        />
      </b-col>  
    </b-row>
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import formatDateMixin from "../../mixins/formatDateMixin";
import exportsMixin from "../../mixins/exportsMixin";
import datepickerComponent from '../global/Datepicker-empty.vue';

export default {
  components: {
  VdtnetTable,
  datepickerComponent,  
 }, 
 mixins: [formatMoneyMixin,formatDateMixin,exportsMixin],
 data: function() {
    return {
      loading: false,
      toDate:null,
      total:0,
      noclerks:false,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax:  {
          url: process.env.VUE_APP_BASE_URL+"/api/reports/cccbalancestodate",
          type: "GET",
          data: (d) => {
            d.toDate = this.toDate,
            d.noclerks = this.noclerks;
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
        processing: true,
        pageLength: 15,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        order: [[0, 'asc']],
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
      },
      fields: {
        name: { label: "Nombre" },
        cuit: { label: "CUIT" },
        total: { label: "Total",
          searchable: true,
          render: this.formatoDinamicoMoneda,
        },
      },
      
    };
  },
  mounted(){
    this.getTotals();
  },
  computed:{
    filters(){
      return {
        toDate : this.toDate,
        noclerks : this.noclerks
      }
    }

  },
  methods: {
    reload(){
      this.getTotals();
      this.refreshTable();
    },
    refreshTable() {  
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    },

    toDateUpdated: function (date) {
      this.toDate = date;
    },
    getTotals() {
      this.loading = true;
      this.$http.get(process.env.VUE_APP_BASE_URL+"/api/reports/cccbalancestodatetotal" , {params:this.filters})
      .then((response) => {
        this.total = response.data.data;
        this.loading = false;
      });    
    },
    exportCSV() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/CSVcccbalances", this.filters)
      .then((response) => {
      });
    },
    exportPDF() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/PDFcccbalances", this.filters)
      .then((response) => {
      });
    },
  },
  watch: {
      filters() {
        this.reload();
      },
    },
  }
</script>