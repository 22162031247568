import Principal from '../components/configs/backend/Principal.vue';
import SalesPoint from '../components/configs/backend/Puntos-de-venta.vue';
import Rules from '../components/configs/backend/Rules.vue';
import Users from '../components/configs/backend/Users.vue';
import Roles from '../components/configs/backend/Roles.vue';
import Cards from '../components/configs/Tarjetas.vue';
import Configurations from '../components/configs/Configurations.vue';
import Ecommerce from '../components/configs/Ecommerce.vue';
import EmptyConfig from '../components/configs/EmptyConfig.vue';

const config = {
    path: '/configuracion',
    meta: {
        requiresAuth: true
    },
    component: EmptyConfig,
    // se crea un componente vacio para que funcione bien, siguiendo esta guia https://dev.to/berniwittmann/my-approach-on-vue-router-architecture-and-nested-routes-2kmo
    children: [
        { path: 'principal', component: Principal, name: 'Principal' },
        { path: 'puntos-de-venta', component: SalesPoint, name: 'Puntos de venta' },
        { path: 'tarjetas', component: Cards, name: 'Tarjetas' },
        { path: 'promociones', component: Rules, name: 'Promociones' },
        { path: 'usuarios', component: Users, name: 'Usuarios del sistema' },
        { path: 'roles', component: Roles, name: 'Roles y permisos' },
        { path: 'general', component: Configurations, name: 'General' },
        { path: 'ecommerce', component: Ecommerce, name: 'E-commerce' },
    ],
};

export default config;