export const setCompany = (state, company) => {
  state.company = company
}

export const setPayments = (state, payments) => {
  state.payments = payments
}

export const setUrl = (state, url) => {
  state.url = url
}

export const setPrice = (state, price) => {
  state.price = price
}

export const setLoading = (state, value) => {
  state.loading = value;
}