import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { createLogger } from 'vuex'
import branchoffices from './modules/branchoffices'
import authUser from './modules/authUser'
import alerts from './alerts'
import config from './config'
import errors from './errors'
import success from './success'
import billing from './billing'
import warehouse from './warehouse'
import priceLists from './PriceLists'
import categories from './categories'
import downloads from './downloads'
import fundsCategory from './funds-category'
import brands from './brands'
import transports from './transports'
import salesClerks from './salesclerks'
import user from './user'

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    branchoffices,
    authUser,
    billing,
    config,
    errors,
    alerts,
    success,
    warehouse,
    priceLists,
    categories,
    downloads,
    fundsCategory,
    brands,
    transports,
    salesClerks,
    user
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
});
