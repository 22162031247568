<template>
    <vue-upload-multiple-image
        @upload-success="uploadImageSuccess"
        @before-remove="beforeRemove"
        dragText="Arrastre las imagenes aqui"
        browseText="Buscar imagen"
        primaryText=""
        markIsPrimaryText=""
        popupText="Imagenes"
        dropText="Arrastre y suelte"
        :showEdit="false"
        :multiple="true"
        :maxImage="10"
        :data-images="images"
    />
  </template>
  
  
  <script>
  import VueUploadMultipleImage from 'vue-upload-multiple-image';
  import { mapState } from 'vuex';
  import myaxios from 'axios';
  
  export default {
    components: {
      VueUploadMultipleImage,
    },
    props: ['imagesprev'],
    data() {
      return {
        bucket: 'productimages-qupro-prod',
        region: 'sa-east-1',
        images: [],
        ids: []
      }
    },
    created () {
        this.$store.dispatch('authUser/getAuthUser')
     },
    computed: {
      ...mapState('authUser',{
        authUser: state => state.authUser,
      })
    },
    watch: {
      'ids': {
        handler: function() {
          this.$emit('imageschanged', this.ids);
        }
      },
      imagesprev: {
        handler: function(imgs) {
          var vm = this;
          vm.images = imgs.map( (e) => {
          vm.ids.push(e.id);
          return {
              id: e.id,
              path: vm.createurl(e.url),
              default: 1,
              highlight: 0,
              caption: '',
          };
        });
        }
      }
    },
    methods: {
      createurl(img) {
        return 'https://'+this.bucket+'.s3.'+this.region+'.amazonaws.com/company_'+this.authUser.company_id+'/'+img;
      },
      beforeRemove (index, done, fileList) {
        if (true == confirm("quitar imagen")) {
          this.ids.splice(this.ids.indexOf(fileList[index].id), 1);
          this.images = this.images.filter((e)=> e.id != fileList[index].id)
          unset(fileList[index]);
          done()
        }
      },
      uploadImageSuccess(formData, index, fileList) {
        var vm = this;
        this.$emit('processing', 1);
        axios.get(vm.createHash(fileList[index])).then((resp) => {
          const uninterceptedAxiosInstance = myaxios.create();
          uninterceptedAxiosInstance.put(resp.data, formData.get('file')).then((response) => {
            if (response.status==200) {
              var name = resp.data.split('/').pop().split('?')[0];
              axios.get("/api/save-product-db/"+ name).then((r) => {
                this.ids.push(r.data.data);
                this.$emit('processing', -1);
                var tmp = fileList[index];
                tmp.id = r.data.data;
                vm.images.push(tmp);
              });
            } else {
              
            }
          }).catch((error) => {
              console.log('error: '+error);
          })
        })
      },
      makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
      },
      createHash (filename) {
        var ts = Date.now();
        var random = this.makeid(14);
        var extension = filename.name.split('.').pop();
        return `/api/temporary-s3-url/${random}${ts}.${extension}`;
      },
    }
  }
  </script>
  
  <style>
  #my-strictly-unique-vue-upload-multiple-image {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
   
  h1, h2 {
    font-weight: normal;
  }
   
  ul {
    list-style-type: none;
    padding: 0;
  }
   
  
  </style> 