<template>
  <div @click="printDoc"><slot></slot></div>
</template>

<script>
import myaxios from 'axios';

export default {
  props: ["pdfurl", "filename", "open"],
  methods: {
    printDoc(){
      axios.get(this.pdfurl)
      .then((res) => {
          const uninterceptedAxiosInstance = myaxios.create();
          uninterceptedAxiosInstance.get(res.data, { responseType: 'blob' }).then((response) => {
          const blob = new Blob([response.data], {type: 'application/pdf'});
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = this.filename
          if (this.open) {
            window.open(link, "_blank") || window.location.replace(link);
          } else {
            link.click()
          }
          URL.revokeObjectURL(link.href)
        })
      })
    }
    }
}
</script>