import VentasPlaceholder from '../components/ventas/Ventas-placeholder.vue';

import IndexVentas from '../components/ventas/index/Index-ventas.vue';
import NuevaVenta from '../components/ventas/index/Nueva-venta.vue';
import EditarVenta from '../components/ventas/index/Editar-venta.vue';
import NuevaNCDesdeVenta from '../components/ventas/index/Nueva-nc-desde-venta.vue';
import NuevaNDDesdeVenta from '../components/ventas/index/Nueva-nd-desde-venta.vue';
import NuevaNotaDeCredito from '../components/ventas/index/Nueva-nota-de-credito.vue';
import NuevaNotaDeDebito from '../components/ventas/index/Nueva-nota-de-debito.vue';
import DetalleVenta from '../components/ventas/index/Detalle-venta.vue';
import Presupuestos from '../components/ventas/presupuestos/Presupuestos.vue';
import NuevoPresupuesto from '../components/ventas/presupuestos/Nuevo-presupuesto.vue';
import NuevaVentaDesdePresupuesto from '../components/ventas/presupuestos/Nueva-venta-desde-presupuesto.vue';
import NuevaONDesdePresupuesto from '../components/ventas/presupuestos/Nueva-on-desde-presupuesto.vue';
import DetallePresupuesto from '../components/ventas/presupuestos/Detalle-presupuesto.vue';
import NotasDePedido from '../components/ventas/notas-de-pedido/Notas-de-pedido.vue';
import NuevaNotaDePedido from '../components/ventas/notas-de-pedido/Nueva-nota-de-pedido.vue';
import DetalleNotaPedido from '../components/ventas/notas-de-pedido/Detalle-nota-de-pedido.vue';
import NuevaVentaDesdeON from '../components/ventas/notas-de-pedido/Nueva-venta-desde-on.vue';
import RemitosDeVenta from '../components/ventas/remitos/Remitos-de-venta.vue';
import NuevoRemitoDeVenta from '../components/ventas/remitos/Nuevo-remito-de-venta.vue';
import DetalleRemitoDeVenta from '../components/ventas/remitos/Detalle-remito-de-venta.vue';
import NuevaVentaDesdeSN from '../components/ventas/remitos/Nueva-venta-desde-remito.vue';
import Clientes from '../components/ventas/clientes/Clientes.vue';
import HistorialCliente from '../components/ventas/clientes/Historial-cliente.vue';
import Vendedores from '../components/ventas/vendedores/Vendedores.vue';
import DetalleVendedor from '../components/ventas/vendedores/Detalle-vendedor.vue';
import CCClientes from '../components/ventas/cc-clientes/CC-clientes.vue';
import DetalleCCCliente from '../components/ventas/cc-clientes/Detalle-cc-cliente.vue';
import Collections from '../components/ventas/cobranzas/Collections.vue';
import DetalleRecibo from '../components/receipt/Receipt-detail.vue';
import DetalleImputacion from '../components/receipt/Allocation-detail.vue'

const sales = {
    path: '/ventas',
    meta: {
      requiresAuth: true
    },
    component: VentasPlaceholder,
    children:
      [
        { path: '', redirect: '/ventas/index'},
        { path: 'index', component: IndexVentas, name: 'Ventas'},
        { path: 'index/nueva-venta', component: NuevaVenta, name: 'Nueva venta' },
        { path: 'index/nueva-nota-de-credito', component: NuevaNotaDeCredito, name: 'Nueva nota de credito' },
        { path: 'index/nueva-nota-de-debito', component: NuevaNotaDeDebito, name: 'Nueva nota de debito' },
        {
          path: 'index/editar-venta/:saleId', component: EditarVenta, name: 'Editar venta', props: true,
        },
        {
          path: 'index/nueva-nc-desde-venta/:saleId', component: NuevaNCDesdeVenta, name: 'Nueva NC desde venta', props: true,
        },
        {
          path: 'index/nueva-nd-desde-venta/:saleId', component: NuevaNDDesdeVenta, name: 'Nueva ND desde venta', props: true,
        },
        {
          path: 'index/detalle/:saleId', component: DetalleVenta, name: 'Detalle de venta', props: true,
        },
        { path: 'presupuestos', component: Presupuestos, name: 'Presupuestos' },
        { path: 'presupuestos/nuevo', component: NuevoPresupuesto, name: 'Nuevo presupuesto' },
        {
          path: 'presupuestos/venta-desde-presupuesto/:budgetId', component: NuevaVentaDesdePresupuesto, name: 'Nueva venta desde presupuesto', props: true,
        },
        {
          path: 'presupuestos/nota-pedido-desde-presupuesto/:budgetId', component: NuevaONDesdePresupuesto, name: 'Nueva ON desde presupuesto', props: true,
        },
        {
          path: 'presupuestos/detalle/:budgetId', component: DetallePresupuesto, name: 'Detalle de presupuesto', props: true,
        },
        { path: 'notas-de-pedido', component: NotasDePedido, name: 'Notas de pedido' },
        { path: 'notas-de-pedido/nueva', component: NuevaNotaDePedido, name: 'Nueva nota de pedido' },
        {
          path: 'notas-de-pedido/venta-desde-nota-pedido/:orderNoteId', component: NuevaVentaDesdeON, name: 'Nueva venta desde ON', props: true,
        },
        {
          path: 'notas-de-pedido/detalle/:orderNoteId', component: DetalleNotaPedido, name: 'Detalle de nota de pedido', props: true,
        },
        { path: 'remitos', component: RemitosDeVenta, name: 'Remitos de venta' },
        { path: 'remitos/nuevo', component: NuevoRemitoDeVenta, name: 'Nuevo remito de venta' },
        {
          path: 'remitos/venta-desde-remito/:shippingNoteId', component: NuevaVentaDesdeSN, name: 'Nueva venta desde SN', props: true,
        },
        {
          path: 'remitos/detalle/:shippingNoteId', component: DetalleRemitoDeVenta, name: 'Detalle de remito de venta', props: true,
        },
        { path: 'clientes', component: Clientes, name: 'Clientes' },
        {
          path: 'clientes/historial-de-ventas/:clientId', component: HistorialCliente, name: 'Historial de ventas del cliente', props: true,
        },
        { path: 'vendedores', component: Vendedores, name: 'Vendedores' },
        {
          path: 'vendedores/detalle/:salesClerkId', component: DetalleVendedor, name: 'Detalle de vendedor', props: true,
        },
        { path: 'cc-clientes', component: CCClientes, name: 'Cuenta corriente de clientes' },
        {
          path: 'cc-clientes/detalle/:clientId', component: DetalleCCCliente, name: 'Detalle de cuenta corriente de cliente', props: true,
        },
        { path: 'cobranzas', component: Collections, name: 'Cobranzas' },
        { path: 'cobranzas/detalle-recibo/:receiptId', component: DetalleRecibo, name: 'DetalleRecibo', props: true, },
        { path: 'cobranzas/detalle-imputacion/:allocationId', component: DetalleImputacion, name: 'DetalleImputacion', props: true},
      ],
};

export default sales;