import axios from '../services/index.js';

export default {
    async getBranchoffices() {
        return new Promise((resolve) => {
            axios.get('/api/branchoffices/getBranchoffices').then((response) => {
                resolve(response.data.data);
            })
        })  
    },
}