<template>
  <!-- ----------------------- SELECT LISTA DE PRECIO -------------------------- -->
  <div class="card mb-4">
    <div class="card-header">
      <h6 class="card-title">Lista de precios</h6>                    
    </div>
    <div class="card-body">
      <b-form-group>
        <label class="control-label" for="input-live">Lista de precios:</label>
        <b-form-select class="form-control" v-model="filters.priceList_id">
          <option :value="all">Ninguno</option>
          <option :value="0">COSTO</option>
          <option v-for="priceList in priceLists" :key="priceList.id" :value="priceList.id">{{ priceList.name }}</option>
        </b-form-select>
      </b-form-group>
      <b-form-group>
        <label class="control-label" for="input-live">Categoría:</label>
        <b-form-select class="form-control" v-model="filters.category_id">
          <option :value="all">Todas</option>
          <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
        </b-form-select>
      </b-form-group>
      <b-form-group>
        <label class="control-label" for="input-live">Proveedor:</label>
        <b-form-select class="form-control" v-model="filters.provider_id">
          <option :value="all">Todos</option>
          <option v-for="provider in providers" :key="provider.id" :value="provider.id">{{ provider.name }}</option>
        </b-form-select>
      </b-form-group>
      <b-form-group>
        <label class="control-label" for="input-live">Marca:</label>
        <b-form-select class="form-control" v-model="filters.brand_id">
          <option :value="all">Todas</option>
          <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
        </b-form-select>
      </b-form-group>
      <b-form-group>
        <label>Porcentaje:</label>
        <b-form-input class="form-control" v-model="increase.percentage" type="number"></b-form-input>
      </b-form-group>
      <b-form-group>
        <label>Ingrese multiplo a redondear:</label>
          <b-form-input type="number" v-model.number="increase.multiple"></b-form-input>
      </b-form-group>
    </div>
    <div class="card-footer">
      <b-button variant="orange" size="lg" @click="actualizarprecios()">Actualizar productos</b-button>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex';

  export default {
    data() {
      return {
        all: 'all',
        increase: {
          category_id: null,
          provider_id: null,
          brand_id: null,
          pricelist_id: null,
          percentage: 0,
          multiple: 1
        },
        filters: {
          category_id: 'all',
          provider_id: 'all',
          brand_id: 'all',
          priceList_id: 'all'
        },              
        providers: [],
        priceLists: [],
        quantity: 0,
      }
    },
    computed: {
      ...mapState('categories',{
          categories: state => state.categories,
        }),
      ...mapState('brands',{
        brands: state => state.brands,
      }),  
    },
    created() {
     this.$store.dispatch('categories/getCategories'),
     this.$store.dispatch('brands/getBrands')
    },
    mounted() {
      this.getPriceLists();
      this.getProviders();
    },
    methods: {
      actualizarprecios() {
        let vm = this;
        this.$http.post('/api/products/getQuantityProduct', vm.increase)
        .then(response => {
          vm.quantity = response.data.message;
          if (confirm(`Se van a actualizar ${vm.quantity} producto/s, los cambios seran irreversibles. Desea continuar?`) == true) {
            this.$http.post('/api/products/updateprices', vm.increase)
            .then(response => {
              
              if(response.data.success)
                setTimeout(() => {
                  location.reload();
                }, 1000);
            })
            .catch(error => {
              alert(error);
            })
          }
        })
        .catch(error => {
          alert(error);
        })
      },
      getPriceLists() {
        var vm = this;
        this.$http.get('/api/priceslist/list').then((response) => {
          vm.priceLists = response.data.data
        })
      },
      getProviders() {
        var vm = this;
        this.$http.get('/api/providers/getProviders').then((response) => {
          vm.providers = response.data.data
        })
      },  
    },
    watch: {
      filters: {
        handler: function () {
          this.increase.category_id = this.filters.category_id
          this.increase.provider_id = this.filters.provider_id
          this.increase.brand_id = this.filters.brand_id
          this.increase.pricelist_id = this.filters.priceList_id
          this.$emit('filters', this.filters)
        },
        deep: true 
      },
      'increase.zz': function(data) {
        this.increase.percentage = data
        this.$emit('increase-change', this.increase)            
      },
      'increase.multiple': function(data) {
        this.increase.multiple = data
        this.$emit('increase-change', this.increase)
      },    
    } 
  }
</script>