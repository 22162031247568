<template>
  <!--
        <Receipt 
          :endpointurl="JSON.stringify('/api/purchases/add')"
          :company_typetax="{{json_encode($user->branchoffice->iva_type)}}"
          :debug="{{json_encode(env('APP_DEBUG'))}}"
          :prexistprd="{{json_encode((isset($products)) ? $products : [])}}"
          :payment_methods="{{json_encode($user->branchoffice->paymentsMethods)}}"
          :config="{{json_encode($config)}}"
          :show-prices="true"
          :show-barcodes="{{json_encode($user->branchoffice->options->showbarcodes)}}"
          :show-paymentmethods="true"
          :show-pos="true"
          :show-choosedate="true"
          :show-totals="true"
        />
        -->
   <Receipt 
      :endpointurl="'/api/purchases/add'"
      :show-prices="true"
      :show-paymentmethods="true"
      :show-pos="true"
      :show-choosedate="true"
      :show-totals="true"

      :config="config"
      :payment_methods="payments_methods"
      :branchofficeData="branchofficeData"
   />
</template>

<script>
import Receipt from './Receipt.vue'
import { mapState } from 'vuex'

export default {
   components: {
      Receipt
   },
   created () {
      this.$store.dispatch('authUser/getAuthUser')
   },
   computed: {
      ...mapState('authUser',{
         authUser: state => state.authUser,
      }),
    
      config: {
         get: function () { return  this.authUser.branchoffices[0].configs;}
      },
      payments_methods:{
         get: function () { return  this.authUser.branchoffices[0].payments_methods;}
      },
      branchofficeData:{
         get: function () { 
            return {
               'name' : this.authUser.branchoffices[0].name,
               'bsns_name' : this.authUser.branchoffices[0].business_name,
               'cuit' : this.authUser.branchoffices[0].cuit,
            }
         }
      },
   }
   
}
</script>
