<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Categorias de producto</h4>
      </b-col>
    </div>
    <!-- ----------------------- BUSCADOR -------------------------- -->
    <b-row class="d-flex justify-content-between mt-2">
      <b-col cols="md-2 text-left">
        <input v-model="filters.qname" class="form-control input-sm" placeholder="Buscar nombre...">
      </b-col>
    </b-row>
    <!-- ----------------------- TABLAS -------------------------- -->
    <b-row>
      <b-col cols="md-8">
        <b-table-simple striped small caption-top responsive class="mt-2">
            <b-thead>
                <b-tr>
                    <b-th>Categoria <a href="#" v-on:click="sortList('name')" class="texto-negro">▼</a></b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
              <ul class="list-group">
                <li class="list-group-item" v-for="(category, index) in categoryTree" :key="index">
                  <div class="row">
                    <div class="col-2">
                      <button v-if="Array.isArray(category.children) && category.children.length > 0" class="widget-categories__expander" type="button" @click="toggleSubMenu($event)"></button>
                    </div>
                    <div class="col-6">
                      <a :for="'category_'+category.id" href="#" @click="link(category.id)">{{ category.label }}</a>
                    </div>
                    <div class="col-4">
                      <button  data-toggle="tooltip" title="Editar" type="button" class="btn btn-light" v-on:click="getCategory(category)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                      </button>
                      <button data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton" v-on:click="deleteCategory(category)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                          <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                      </button>
                    </div>
                  </div>                  
                  <ul v-if="Array.isArray(category.children) && category.children.length > 0" class="list-group widget-categories__item">
                    <li class="list-group-item mt-3" v-for="(subcategory, index) in category.children" :key="index">
                      <div class="row">
                        <div class="col-2">
                          <button v-if="Array.isArray(subcategory.children) && subcategory.children.length > 0" class="widget-categories__expander" type="button" @click="toggleSubMenu($event)"></button>
                        </div>
                        <div class="col-6">
                          <a :for="'category_'+subcategory.id" href="#" @click="link(subcategory.id)">{{subcategory.label}}</a>  
                        </div>
                        <div class="col-4">
                          <button  data-toggle="tooltip" title="Editar" type="button" class="btn btn-light ml-4" v-on:click="getCategory(subcategory)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                              <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                          </button>
                          <button data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton" v-on:click="deleteCategory(subcategory)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                              <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                      <ul v-if="Array.isArray(subcategory.children) && subcategory.children.length > 0" class="list-group widget-categories__item">
                        <li class="list-group-item mt-3" v-for="(sucsubcategory, index) in subcategory.children" :key="index">
                          <div class="row">
                            <div class="col-2">
                              <button v-if="Array.isArray(sucsubcategory.children) && sucsubcategory.children.length > 0" class="widget-categories__expander" type="button" @click="toggleSubMenu($event)"></button>
                            </div>
                            <div class="col-6">
                              <a :for="'category_'+sucsubcategory.id" href="#" @click="link(sucsubcategory.id)">{{sucsubcategory.label}}</a>
                            </div>
                            <div class="col-4">
                              <button  data-toggle="tooltip" title="Editar" type="button" class="btn btn-light ml-5" v-on:click="getCategory(sucsubcategory)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                              </button>
                              <button data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton" v-on:click="deleteCategory(sucsubcategory)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                              </button>
                            </div>  
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </b-tbody>
        </b-table-simple>
        <!-- ----------------------- PAGINACION -------------------------- -->
        <div class="row vdtnet-footer">
          <div class="col-sm-10 col-md-3">
            <div class="vdtnetable1_info">
              Mostrando {{ (filters.page - 1) * filters.itemsPerPage + 1 }} a {{
                (Math.min(filters.page * filters.itemsPerPage) > categoryTree.length)
                ? totalItems
                : Math.min(filters.page * filters.itemsPerPage)
              }} de {{ totalItems }} registros
            </div>
          </div>
          <div class="col-sm-10 col-md-7">
            <div class="dataTables_paginate paging_simple_numbers">
              <ul class="pagination">
                <li class="page-item" v-bind:class="[{'disabled': filters.page==1}]">
                  <a class="page-link" href="#" v-on:click="prevPage()">Anterior</a>
                </li>
                <li v-if="(filters.page - (pageInterval/2))>0" class="page-item">
                  <a class="page-link" href="#" v-on:click="prevPage()">...</a>
                </li>
                <template v-for="index in totalPages">
                  <li :key="index" v-if="inIntervalPage(index)" class="page-item" v-bind:class="[{'active': filters.page==index}]">
                    <a class="page-link" href="#" v-on:click="changePage(index)">{{index}}</a>
                  </li>
                </template>
                <li v-if="totalPages>(filters.page + (pageInterval/2))" class="page-item">
                  <a class="page-link" href="#" v-on:click="nextPage()">...</a>
                </li>
                <li class="page-item" v-bind:class="[{'disabled': filters.page>=totalPages}]">
                  <a class="page-link" href="#" v-on:click="nextPage()">Siguiente</a>
                </li>
              </ul>
            </div>
            <div class="dataTables_length">
              <label class="mr-2">Mostrar: </label>
              <b-form-select v-model="filters.itemsPerPage" style="width: 60px;">
                <option :value="15" selected="selected">15</option>
                <option :value="25">25</option>
                <option :value="50">50</option>
              </b-form-select>
              <label class="ml-2 mr-5">registros</label>
            </div>
          </div>
        </div>
      </b-col>
      <!-- ----------------------- FORMULARIO -------------------------- -->
      <b-col>
        <div class="card mb-4">
          <div class="card-header">
            <h6 class="card-title">Nueva Categoria</h6> 
          </div>
          <div class="card-body">
            <b-form-group>
              <label class="control-label" for="input-live">Nombre:</label>
              <b-form-input
                class="form-control"
                id="input-live"
                v-model="category.name"
                :state="nameState"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Nombre de la categoría"
                trim>
              </b-form-input>
              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback
                v-if="nameState === false"
                id="input-live-feedback">
                El nombre debe tener un mínimo de 3 letras
              </b-form-invalid-feedback>
              <!-- This is a form text block (formerly known as help block) -->
              <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
            </b-form-group>
            <b-form-group>  
              <label class="control-label mt-3" for="input-live"
              >Categoría padre:</label>
              <b-form-select
                id="categories"
                v-model="category.parent_id">
                <option :value="null">Ninguna</option>
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id"
                  >{{ category.name }}</option>
              </b-form-select>
            </b-form-group>  
            
          </div>
          <div class="card-footer">
            <b-button variant="orange" size="lg" class="mt-3"
            v-if="nameState === null" @click="saveCategory()">
              Guardar categoría
            </b-button>
          </div> 
        </div>
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
data: function() {
    return {
      doSearch: '',   
      loading: false,
      timeoutId: null,
      totalPages:1,
      pageInterval:16,
      totalItems: 0,
      categoryTree: [],
      category: {
        id: null,
        name: "",
        parent_id: null,
      },
      emptyCategory: {
        id: null,
        name: "",
        parent_id: null,
      },
      filters:{
          qname:'',
          page:1,
          itemsPerPage:15,
          sorting:{
            col:'',
            order:'desc'
          }
        },      
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState('categories',{
          categories: state => state.categories,
        }),
    nameState() {
      return this.category.name.length < 3 && this.category.name.length > 0
        ? false
        : null;
    },
  },
  created() {
    this.$store.dispatch('categories/getCategories')
  },
  watch: {
    'filters.qname': {handler: function (a) {this.getDataWithDelay();}},
    'filters.itemsPerPage': {handler: function (a) {this.getData();}},
  },
  methods: {
    getData(){
        var vm= this;
        axios.post('/api/categories/list', vm.filters)
          .then(response => {
            if(response.data.success) {
                vm.categoryTree = response.data.data.categories;
                vm.totalPages = response.data.data.totalPages;
                vm.totalItems = response.data.data.totalItems;
            }else{
                vm.Categories=[];
            }
        })
        .catch(error => {
            alert(error);
        })
      },   

    getCategory(data) {
      console.log(data);
      var vm = this;
      vm.loading = true
      this.$http
        .get("/api/categories/getCategory/" + data.id)
        .then((response) => {
          vm.category = response.data.data;
          console.log(vm.category);

          vm.loading = false
        });
    },

    deleteCategory(data) {
      if (confirm("Esta seguro que desea borrar esta categoría?") == true) {
        this.loading = true
        this.$http
          .post("/api/categories/delete", { id: data.id })
          .then((response) => {
            if (response.data.code == 201) {
              this.refreshTable();
              this.loading = false;
              this.category = this.emptyCategory;
            }
          });
      }
    },

    saveCategory() {
      this.loading = true
      if (this.category.id != null) {        
        this.$http
          .post("/api/categories/edit/" + this.category.id, this.category)
          .then((response) => {
            this.refreshTable();
            this.loading = false;
            this.category = this.emptyCategory;
          });
      } else {        
        this.$http
          .post("/api/categories/add", this.category)
          .then((response) => {
            this.refreshTable();
            this.loading = false;
            this.category = this.emptyCategory;
          });
      }
    },

    toggleSubMenu(event, forceExpand) {
      const buttonElement = event.currentTarget;
      const liElement = buttonElement.closest("li");
      const ulElement = liElement.querySelector(".widget-categories__item");
      const isExpanded = ulElement.classList.contains("widget-categories__item--open");

      const expand = typeof forceExpand === "boolean" ? forceExpand : !isExpanded;
      ulElement.classList.toggle("widget-categories__item--open", expand);
      buttonElement.classList.toggle("button__open", expand);
    },

    refreshTable() {
      var vm = this;
      if (vm.$refs.table == undefined) window.location.reload();
    },

    getDataWithDelay(){
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(()=>this.getData(), 1500);
    },

    link(data) {
      let routeData = this.$router.push({
        name: "Inventario",
        params: { categoryId: data },
      });
    },

    sortList(col){
        if(this.filters.sorting.col == col){
            if(this.filters.sorting.order=='asc'){
                this.filters.sorting.order='desc';
            }else{
                this.filters.sorting.order='asc';
            }
        }else{
            this.filters.sorting.col=col;
            this.filters.sorting.order='asc';
        }
        this.getData();
    },

    prevPage(){
        if(this.filters.page>1){
            this.filters.page = this.filters.page-1;
            this.getData();
        }
    },

    nextPage(){
        if (this.filters.page<this.totalPages){
            this.filters.page = this.filters.page +1;
            this.getData();
        }
    },

    changePage(data){
        this.filters.page = data;
        this.getData();
    },

    inIntervalPage(index){
        if(this.totalPages<=this.pageInterval){return true;}

        let limitprev = this.filters.page - (this.pageInterval/2);
        let limitnext = this.filters.page + (this.pageInterval/2);

        if(limitprev<0){
            limitprev=0;
            limitnext=this.pageInterval;
        }

        if(index>limitprev && index<limitnext){return true;}

        return false;
    },
  },
};
</script>

<style scoped>

.texto-negro {
  color: black;
}

.widget-categories__item {
  overflow: hidden;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.widget-categories__expander {
  position: relative;
  border: none;
  border-radius: 1.5px;
  cursor: pointer;
  padding: 10px;
  background: transparent;
  color: #494949;
  transition: all 0.2s;
  display: grid;
}
.widget-categories__expander:focus,
.widget-categories__expander:hover {
  background: #7c7979;
  color: #fffefe;
}
.widget-categories__expander:focus {
  outline: none;
}
.widget-categories__expander:after,
.widget-categories__expander:before {
  display: block;
  position: absolute;
  content: '';
  background: currentColor;
}
.widget-categories__expander:before {
  width: 8px;
  height: 2px;
  left: calc(50% - 4px);
  top: calc(50% - 1px);
}
.widget-categories__expander:after {
  width: 2px;
  height: 8px;
  left: calc(50% - 1px);
  top: calc(50% - 4px);
  transition: transform 0.2s;
}

.button__open.widget-categories__expander:after {
  transform: rotate(90deg);
}
.widget-categories__item--open.widget-categories__item {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
}
</style>
