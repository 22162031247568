<template>
  <div>
    <b-card-header>
      <h6>Comprobantes de prueba</h6>
    </b-card-header>
    <b-card-body>
      <b-form-group>
        <label class="simple-spacing">Factura de prueba:</label>
        <input disabled id="template_test" name="template_test" v-model="bo_configs.template_test" placeholder="Template de prueba" type="text" maxlength="36">
        <input type="button" value="Vista previa">
      </b-form-group>
      <b-form-group>
        <label class="simple-spacing">Factura electrónica:</label>
        <input disabled id="template_digital" name="template_digital" v-model="bo_configs.template_digital" placeholder="Template electrónico" type="text" maxlength="36">
        <input type="button" value="Vista previa">
      </b-form-group>
      <b-form-group>
        <label class="simple-spacing">Factura fiscal:</label>
        <input disabled id="template_fiscal" name="template_fiscal" v-model="bo_configs.template_fiscal" placeholder="Template fiscal" type="text" maxlength="36">
        <input type="button" value="Vista previa">
      </b-form-group>
      <b-form-group>
        <label class="simple-spacing">Presupuesto:</label>
        <input disabled id="template_budget" name="template_budget" v-model="bo_configs.template_budget" placeholder="Template presupuesto" type="text" maxlength="36">
        <input type="button" value="Vista previa">
      </b-form-group>
      <b-form-group>
        <label class="simple-spacing">Recibo:</label>
        <input disabled id="template_receipt" name="template_receipt" v-model="bo_configs.template_receipt" placeholder="Template recibo" type="text" maxlength="36">
        <input type="button" value="Vista previa">
      </b-form-group>
      <b-form-group>
        <label class="simple-spacing">Remito</label>
        <input disabled id="template_shipping_note" name="template_shipping_note" v-model="bo_configs.template_shipping_note" placeholder="Template remito" type="text" maxlength="36">
        <input type="button" value="Vista previa">
      </b-form-group>
      <b-form-group>
        <label class="simple-spacing">Nota de Pedido</label>
        <input disabled id="template_order_note" name="template_order_note" v-model="bo_configs.template_order_note" placeholder="Template nota de pedido" type="text" maxlength="36">
        <input type="button" value="Vista previa">
      </b-form-group>
    </b-card-body>
  </div>
</template>

<script>
export default {
  props: ['bo_configs'],
  data: function() {
    return {
    }
  },
  mounted: function() {
  },
}
</script>

<style>
  .simple-spacing{
    width:25%;
    font-weight: normal;
  }
</style>
