import EmptyFunds from '../components/funds/EmptyFunds.vue';

import IndexCajas from '../components/funds/Index-cajas.vue';
import DetalleCaja from '../components/funds/Detalle-caja.vue';
import CategoriasCajas from '../components/funds/Categorias-cajas.vue';
import ChequesCartera from '../components/funds/Cheques-cartera.vue';
import CuponesCartera from '../components/funds/Cupones-cartera.vue';
import ChequesPropios from '../components/funds/Cheques-propios.vue';
import CierreCajaModal from '../components/funds/Cierre-caja-modal.vue';

const funds = {
  path: '/cajas',
  meta: {
    requiresAuth: true
  },
  component: EmptyFunds,
  children:
    // se crea un componente vacio para que funcione bien, siguiendo esta guia https://dev.to/berniwittmann/my-approach-on-vue-router-architecture-and-nested-routes-2kmo
    [
      { path: '', redirect: '/cajas/index' },
      { path: 'index', component: IndexCajas, name: 'Cajas' },
      { path: 'detalle-caja/:idCaja', component: DetalleCaja, name: 'Detalle de Caja' },
      { path: 'categoria-cajas', component: CategoriasCajas, name: 'Categorias Cajas' },
      { path: 'cheques-terceros', component: ChequesCartera, name: 'Cheques de Terceros' },
      { path: 'cupones-cartera', component: CuponesCartera, name: 'Cupones en cartera' },
      { path: 'cheques-propios', component: ChequesPropios, name: 'Cheques Propios' },
      { path: 'Cierre-caja-modal/:idCaja', component: CierreCajaModal, name: 'Cierre de caja Modal' },
    ],
};

export default funds;
