<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <!-- CABECERA TOTALES -->
          <div class="row">
            <div class="col d-flex justify-content-start">
              <h4>
                Metodos de pago
              </h4>
            </div>
          </div>
          <b-row class="modal-header">
            <b-col cols="md-12">
              <b-row>
                <b-col cols="md-6 text-left">
                  <h3 class="font-weight-bolder">
                    Total: $ {{ round(total,2) }}
                  </h3>
                </b-col>
                <b-col cols="md-6 pull-right">
                  <b-button variant="white" v-if="limited" class="modal-default-button" @click="modified">
                    Modificar
                  </b-button>
                  <b-button b-button variant="light" size="lg" class="modal-default-button" @click="cancel">
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="md-6 text-left">
                  <ul class="list-unstyled">
                    <li><h5>Efectivo: ${{ payarray.cash }}</h5></li>
                    <li v-for="tpcheck in payarray.tpchecks" :key="tpcheck.number">
                      Cheque - Numero: <b>{{tpcheck.number}}</b> / Monto: <b>${{ round(tpcheck.amount,2) }}</b>
                    </li>
                    <li v-for="sfcheck in payarray.sfchecks" :key="sfcheck.number">
                      Cheque propio - Numero: <b>{{sfcheck.number}}</b> / Monto: <b>${{ round(sfcheck.amount,2) }}</b>
                    </li>
                    <li v-for="creditcard in payarray.creditcards" :key="creditcard.number">
                      {{creditcard.name}} - Cupon: <b>{{creditcard.coupon}}</b> / Monto <b>${{ round(creditcard.amount,2) }}</b>
                    </li>
                    <li v-for="cc in payarray.accounts" :key="cc.amount">
                      Cuenta corriente - Monto: <b> ${{cc.amount}}</b> / Vencimiento: <b>{{ cc.days }}</b>
                    </li>
                  </ul>
                </b-col>
              </b-row>

              <b-col cols="md-3 text-left">
              <b-row>
                  <div v-if="limited">
                    <h5 v-if="change>0">Vuelto: $ {{ round(change,2) }}</h5>
                    <h5 v-if="diference!=0">Diferencia: $ {{ round(diference,2) }}</h5>
                  </div>
                </b-row>
              </b-col>
            </b-col>
          </b-row>

          <!-- CUERPO DEL MODAL -->
          <b-col class="modal-body overflowy-auto" style="margin-bottom: 100px;">
            <slot name="body">
                <b-row>
                  <b-col cols="md-3">
                    <div class="form-group col-border">
                      <label>Efectivo</label>
                      <input v-model.number="payarray.cash" type="number" min="0" step="0.01" class="form-control">
                    </div>
                  </b-col>
                  <b-col cols="md-3">
                    <div class="form-group col-border">
                      <label>Caja destino</label>
                      <select v-model="payarray.fund_id" class="form-control" name="fund_id">
                        <option v-for="fund in funds" :key='fund.id' :value="fund.id">{{fund.name.toUpperCase()}}</option>
                      </select>
                    </div>
                  </b-col>
                  <div class="clearfix"></div>
                </b-row>

                <b-row v-if="showTpCheck">
                  <b-col cols="md-12">
                    <div class="form-group">
                        <tpchecktable
                        v-on:checkcancel="showTpheck=false"
                        v-on:updateChecks="tpCheckSave"
                        :checks="payarray.tpchecks"
                        endpoint='/api/funds/tp_checks/getTpChecksOnWallet'/>
                    </div>
                  </b-col>
                </b-row>

                <b-row v-if="showSfCheck">
                  <div  class="col-md-12">
                    <div class="form-group">
                        <div v-bind:class="{'header': seeSfList}">
                            <div>
                                <button class="btn btn-link" @click="seeSfList = false" >
                                    <b>    Emitir nuevo cheque  >></b>
                                </button>
                            </div>
                            <div v-show="!seeSfList">
                                <sfcheck
                                    v-on:sfcheckcancel="showSfCheck=false"
                                    v-on:sfchecksaved="sfCheckSave"
                                    :funds='funds'
                                    :emisionData='emisionData'/>
                            </div>
                        </div>
                    </div>
                  </div>

                  <div  class="col-12">
                    <div class="form-group">
                        <div v-bind:class="{'header': !seeSfList}">
                            <div>
                                <button class="btn btn-link" @click="seeSfList = true" >
                                    <b>O Selecciona cheques propios en cartera  >></b>
                                </button>
                            </div>
                            <div class="row">
                              <b-col>
                                <div id="tableContainer" v-show="seeSfList">
                                    <sfchecktable
                                        v-on:checkcancel="showSfCheck=false"
                                        v-on:updateChecks="sfCheckListSave"
                                        :checks="payarray.sfchecks"
                                        endpoint='/api/funds/checks/getChecksOnWallet'/>
                                </div>
                              </b-col>
                            </div>
                        </div>
                    </div>
                  </div>

                </b-row>

                <b-row v-if="showCc">
                  <client-account v-on:clientAccountSaved="clientAccountSaved" v-on:clientAccountCancel="showCc=false"/>
                </b-row>
               
                <div class="col-12 col-md-6">
                  <div class="form-group col-border">
                    <h6>
                      <label for="">Nota para el proveedor</label>
                    </h6>
                    <textarea v-model="payarray.orderNote" class="form-control" rows="2"></textarea>
                  </div>
                </div>
            </slot>
          </b-col>
          <!-- BOTONES DEL FOOTER -->
          <div class="modal-footer">
            <b-button variant="light" size="lg" class="mr-2" v-if="paymentMethods.cuenta_corriente==1" @click="showCc=!showCc" type="button">Cuenta corriente</b-button>
            <b-button variant="light" size="lg" class="mr-2" v-if="paymentMethods.cheques==1" @click="showTpCheck=!showTpCheck" type="button">Cheques de terceros</b-button>
            <b-button variant="light" size="lg" class="mr-2" v-if="paymentMethods.cheques==1" @click="showSfCheck=!showSfCheck" type="button">Cheques propios</b-button>
            <b-button class="modal-default-button ml-5" variant="orange" size="lg" v-if="(round(diference,2)==0) || (limited==0)" type="button" @click="finishReceipt">Finalizar</b-button>    
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
import clientAccount from './Client-account.vue';
import tpchecktable from './Tpcheck-table.vue';
import sfcheck from './Sf-check.vue';
import sfchecktable from './Sfcheck-table.vue';
import roundMixin from "./../../mixins/roundMixin.js";


export default {
  mixins: [roundMixin ],
  props: ['total', 'paymentMethods', 'header', 'limited', 'fundDefault','emisionData'],
  data: function() {
    return {
      showCreditCard: false,
      showTpCheck: false,
      showSfCheck: false,
      showCc: false,
      totalpayed: 0,
      diference: 0,
      change: 0,
      funds: [],
      payarray: {
        cash: 0,
        accounts: [],
        creditcards: [],
        tpchecks: [],
        orderNote:'',
        sfchecks:[]
      },
      limitcc: null,
      seeSfList:false,
    }
  },
  mounted: function () {
    this.payarray.fund_id = this.fundDefault;
    this.getFunds();
    this.calc();
  },
  watch: {
    payarray: {
      handler: function () {
        this.calc();
      }, deep: true
    }
  },
  methods: {
    calc: function () {
      this.totalpayed = 0;

      var interestCreditCards = this.payarray.creditcards.map(el => el.interest).reduce((acc, currentValue) => acc + currentValue, 0);
      var totalCreditCards = this.payarray.creditcards.map(el => el.amount).reduce((acc, currentValue) => acc + currentValue, 0);
      var totalTpChecks = this.payarray.tpchecks.map(el => el.amount).reduce((acc, currentValue) => acc + currentValue, 0);
      var totalSfChecks = this.payarray.sfchecks.map(el => el.amount).reduce((acc, currentValue) => acc + currentValue, 0);
      var totalAccounts = this.payarray.accounts.map(el => el.amount).reduce((acc, currentValue) => acc + currentValue, 0);

      var withoutcash = this.round(totalCreditCards,2) + 
        this.round(interestCreditCards,2) + 
        this.round(totalTpChecks,2) + 
        this.round(totalAccounts,2) +
        this.round(totalSfChecks,2) ;

      var cosa = this.total - withoutcash;
      var totalcash = this.payarray.cash;

      this.change = totalcash - cosa;

      this.totalpayed = this.round(totalcash,2) + this.round(withoutcash,2);
      this.diference = this.round(this.total,2) - this.round(this.totalpayed,2);
    },
    tpCheckSave: function(obj) {
      this.payarray.tpchecks= obj;
      this.showTpCheck=false;
    },
    sfCheckSave: function(obj) {
      this.payarray.sfchecks.push(obj);
      this.showSfCheck=false;
    },
    sfCheckListSave: function(list) {
        var toadd = this.payarray.sfchecks.filter(function (el) {return el.id == null;});
        this.payarray.sfchecks= list.concat(toadd);
        this.showSfCheck=false;
        this.seeSfList=false;
    },
    fromdateUpdated: function (date) {
      this.header.service_fromdate = date;
    },
    clientAccountSaved: function (obj) {
      this.payarray.accounts.push(obj);
      this.showCc=false;
    },
    modified: function () {
      this.$emit('paymentmethodschanged',{});
      this.$emit('close');
    },
    cancel: function () {
        this.$emit('paymentmethodschanged',{});
        this.$emit('close');
    //   if (!this.limited) {
    //     window.location.href = '/compras/cc-proveedores/detalle/'+this.header.provider_id;
    //   } else {
    //     window.location.href = '/compras/index/detalle/create/detail';
    //   }
    },
    finishReceipt: function () {
      this.$emit('paymentmethodsclose',this.payarray);
      this.$emit('close')
    },
    getFunds() {
      var vm = this;
      this.$http.get('/api/funds/list')
          .then(response => {
            if(response.data) {
              vm.funds = response.data.data.filter(fund => fund.type != "Tarjeta de Credito");
            }
          })
          .catch(error => {
            alert(error);
          })
    },
  },
  components: {
    clientAccount,
    tpchecktable,
    sfcheck,
    sfchecktable
  }
}
</script>

<style scoped>

  .modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }
  
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .modal-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    overflow-y: scroll;
  }
  
  .modal-header {
    display: flex;
    align-items: center;
  }
  
  .modal-default-button {
    float: right;
    margin-left: 10px;
  }
  
  .modal-footer {
    position: fixed;
    bottom:0;
    left:0;
    z-index:9998;
    background: #F2F4F7;
    border-top: 1px solid #4477BD;
    padding:20px;
    width: 100%;
  }
  
  .modal-enter {
    opacity: 0;
  }
  
  .modal-leave-active {
    opacity: 0;
  }
  
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>

