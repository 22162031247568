
<template>
  <div class="modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      style="display: block">
    <div class="modal-dialog" role="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">
            Depositar o cobrar cheque
          </h4>
          <b-button variant="primary size-sm" @click="close()"><i class="bi bi-x-lg"></i></b-button>
        </div>
        <form id="deposit-form" @submit.prevent>
          <div class="modal-body">
            <b-form-select class="form-control" name="fund_id" v-model="fund">
              <b-form-select-option :value="null" disabled
                >Elija una caja de destino</b-form-select-option
              >
              <b-form-select-option
                v-for="fund in funds"
                :key="fund.id"
                :value="fund.id"
                >{{ fund.name }}</b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-lg btn-orange"
              @click="depositForm"
            >
              Depositar o cobrar cheque
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ['funds'],
  methods: {
    depositForm() {
      this.$emit('fundSelected', this.fund);
    },
    close() {
      console.log('close');
      this.$emit('close');
    }
  },
  data: function () {
    return {
      fund: null,
    };
  },
};
</script>
