<template>
	<b-navbar-nav class="ml-auto">
		
		<b-nav-item v-if="showLastweek" href="/billing" class="nav-link" style="font-size:10px;text-align:center;">
			Ultima semana de suscripción al sistema,<br>te recomendamos abonar con tiempo
		</b-nav-item>

		<b-button v-b-toggle href="#example-sidebar" @click.prevent class="bbutton">
			<b-icon icon="question-circle-fill" style="width: 25px; height: 25px;" aria-label="Help"></b-icon>
		</b-button>

		<b-button v-b-toggle href="/downloads/list" class="bbutton">
			<b-icon icon="file-earmark-arrow-down-fill" style="width: 25px; height: 25px;"></b-icon>
		</b-button>

		<b-sidebar id="example-sidebar" title="Capacitación">
			<b-card class="mt-3">
				<h6>Accedé a una serie de video tutoriales en los cuales aprenderás a usar el sistema.</h6>
				<a href="https://www.youtube.com/channel/UC7Si9L9dIqmX1zPn7lasVdA" target="_blank">
				<b-button variant="primary">
					Ir al canal
				</b-button>
				</a>
			</b-card>
			<!-- <iframe class="mt-3" height="150" src="https://www.youtube.com/embed/0_j7PXyg-Fk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>  -->		
		</b-sidebar>

		<a href="/news" style="white-space:normal">
		<b-button class="bbutton">
			<b-icon icon="bell-fill" class="rounded-circle bbutton" style="width: 25px; height: 25px;" variant="light"></b-icon>
			<b-badge v-if="showCountNotifications > 0" pill variant="danger">{{ showCountNotifications }}</b-badge>
		</b-button>
		</a>
		<b-nav-item-dropdown right :text="user.name">
			<v-guard :permissions="['CONFIGURACION_PERFIL_EMPRESA']">
			<b-dropdown-item href="/configuracion/principal">Datos de mi empresa</b-dropdown-item>
			</v-guard>
			<b-dropdown-item href="/mi-perfil">Cambiar contraseña</b-dropdown-item>
			<b-dropdown-item href="/logout">Salir del sistema</b-dropdown-item>
		</b-nav-item-dropdown>

	</b-navbar-nav>
	
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	computed: {
		...mapGetters({
			user: 'user/showUser',
			showCountNotifications: 'alerts/showCountNotifications',
			showLastweek: 'authUser/showLastweek'
		}),
	},
	mounted(){
		this.getNotifications();
	},
	methods: {
		...mapActions({
			getNotifications: 'alerts/getNotifications'
		}),
	}
}
</script>
<style scoped>
	.bbutton {
		background-color: #4477bd;
		border: none;
	}
	.bbutton:hover {
		background-color: #345a8f !important;
	}
</style>

