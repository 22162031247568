<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <b-row class="text-left mt-2">
      <b-col>
        <h4>Reporte Z</h4>
        <hr>
      </b-col>
    </b-row>

    <b-row align-h="center" aling-w="center">
        <b-form-group>
            <select v-model="selectedPos" class="form-control btn-light">
                <option :value="null" selected>- Seleccione punto de venta -</option>
                <option v-for="pos in posList" :key="pos.id" :value="pos.id">{{pos.branchoffice.name}} - {{pos.number}}</option>
            </select>
        </b-form-group>
        <b-form-group>
            <input v-if="selectedPos!=null" type="button" @click="getReport()" value="Generar Reporte" class="btn btn-light"/>
            </b-form-group>
            <b-form-group>
            <input v-if="report!=null" type="button" @click="printReport()" value="Imprimir Reporte" class="btn btn-light"/>
        </b-form-group>
        <!-- ---------------- TICKET IMPRESION --------------- -->
        <div v-if="report!=null" class="row mt-4 d-flex justify-content-center">
        
            <div class="col-sm-8 col-md-3">
                <div class="row">
                    <div class="col-md-12" style="text-align:center;">
                        <h6 class="text-dark">REPORTE Z</h6>
                    </div>
                    <div class="col-md-12">{{report.company}}</div>
                    <div class="col-md-12">Generado: {{report.date}}</div>
                    <div class="col-md-12">Hora: {{report.time}}</div>
                    <div class="col-md-12">Punto de venta: {{report.pos}}</div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-6">Ventas Total:</div>
                    <div class="col-6">$ {{report.FCtotal}}</div>
                    <div class="col-6">Ventas Neto:</div>
                    <div class="col-6">$ {{report.FCneto}}</div>
                    <div class="col-6">Ventas IVA:</div>
                    <div class="col-6">$ {{report.FCiva}}</div>
                    <div class="col-12"><hr></div>
                    <div class="col-6" v-if="report.FCiva25>0">&nbsp;&nbsp; - IVA 2.5%:</div>
                    <div class="col-6" v-if="report.FCiva25>0">$ {{report.FCiva25}}</div>
                    <div class="col-6" v-if="report.FCiva5>0">&nbsp;&nbsp; - IVA 5%:</div>
                    <div class="col-6" v-if="report.FCiva5>0">$ {{report.FCiva5}}</div>
                    <div class="col-6" v-if="report.FCiva105>0">&nbsp;&nbsp; - IVA 10.5%:</div>
                    <div class="col-6" v-if="report.FCiva105>0">$ {{report.FCiva105}}</div>
                    <div class="col-6" v-if="report.FCiva21>0">&nbsp;&nbsp; - IVA 21%:</div>
                    <div class="col-6" v-if="report.FCiva21>0">$ {{report.FCiva21}}</div>
                    <div class="col-6" v-if="report.FCiva27>0">&nbsp;&nbsp; - IVA 27%:</div>
                    <div class="col-6" v-if="report.FCiva27>0">$ {{report.FCiva27}}</div>
            
                    <div class="col-6">Percepciones:</div>
                    <div class="col-6">$ {{report.FCpercep}}</div>
            
                    <div class="col-12"><hr></div>
            
                    <div class="col-6">N.Credito Total:</div>
                    <div class="col-6">$ {{report.NCtotal}}</div>
                    <div class="col-6">N.Credito Neto:</div>
                    <div class="col-6">$ {{report.NCneto}}</div>
                    <div class="col-6">N.Credito IVA:</div>
                    <div class="col-6">$ {{report.NCiva}}</div>
                    <div class="col-6">Percepciones:</div>
                    <div class="col-6">$ {{report.NCpercep}}</div>
            
                    <div class="col-12"><hr></div>
            
                    <div class="col-6">Ultima Venta A:</div>
                    <div class="col-6">{{report.FClastA}}</div>
                    <div class="col-6">Ultima Venta B:</div>
                    <div class="col-6">{{report.FClastB}}</div>
                    <div class="col-6">Ultima N.Credito A:</div>
                    <div class="col-6">{{report.NClastA}}</div>
                    <div class="col-6">Ultima N.Credito B:</div>
                    <div class="col-6">{{report.NClastB}}</div>
            
                    <div class="col-12"><hr></div>
            
                    <div class="col-6">Facturas emitidas:</div>
                    <div class="col-6">{{report.FCcount}}</div>
                    <div class="col-6">N.Credito emitidas:</div>
                    <div class="col-6">{{report.NCcount}}</div>
                    <div class="col-6">Comprobantes con CAE:</div>
                    <div class="col-6">{{report.CAEcount}}</div>
                </div>
            </div>
        </div>
    </b-row>
  </b-container>
</template>
<script>

export default {
  data: function() {
    return {
      loading:false,
      report: null,
      selectedPos:null,
      posList:[],
    }
	},
  mounted(){
    this.loadSalepoints();
  },
  computed: {
  },
  methods: {
      loadSalepoints() {
          let vm = this;
          axios.get(process.env.VUE_APP_BASE_URL+'/api/configs/pos/list')
            .then((response) => {
                if (response.data.success) {
                    vm.posList = response.data.data;
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
      },
      getReport(){
          let vm= this;
          vm.loading=true;
          axios.get(process.env.VUE_APP_BASE_URL+'/api/reports/reportZ/' + vm.selectedPos)
              .then(response => {
                  if(response.data.success) {
                      vm.report = response.data.data;
                  }else{
                      
                  }
                  vm.loading=false;
              })
              .catch(error => {
                  vm.loading=false;
                  alert(error);
              })
      },
      printReport(){
          let vm= this;
          if(confirm('¿Imprimir reporte?')){
              window.open(process.env.VUE_APP_BASE_URL+'/reports/reportZprint/' + vm.selectedPos) || window.location.replace(process.env.VUE_APP_BASE_URL+'/reports/reportZprint/' + vm.selectedPos);
          }
      }
	},
}
</script>


