<template>
  <div class="card mb-4">
    <div class="card-header">
      <h6 class="card-title">Nueva Regla de precio</h6>
      <button type="button" class="btn btn-primary panel-action reset">Nueva Regla</button>
    </div>
    <div class="card-body">
      <form class="form" action="/api/configs/promo/add">
        <div class="form-body">
          <div class="form-group">
            <label class="control-label">Producto</label>
            <select v-model="rule.product_id" name="product_id" class="form-control">
              <option  v-for="product in products" :key="product.id" :value="product.id">{{product.name}}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="control-label">Desde Cantidad</label>
            <input v-model="rule.fromqty" name="fromqty" type="text" class="form-control">
          </div>
          <div class="form-group">
            <label class="control-label">Hasta cantidad</label>
            <input v-model="rule.toqty" name="toqty" type="text" class="form-control">
          </div>
          <div class="form-group">
            <label class="control-label">Lista de Precio</label>
            <select v-model="rule.pricelist_id" class="form-control">
              <option v-for="priceList in priceLists" :key="priceList.id" :value="priceList.id">{{priceList.name}} {{$priceList.name}}</option>
            </select>
          </div> 
          <div class="form-group">
            <label class="control-label">Precio</label>
            <input v-model="rule.price" name="price" type="text" class="form-control" >
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="form-actions">
        <button @click.prevent="saveRule" type="submit" class="btn btn-lg btn-orange">Agregar regla</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['products'],
  data: function() {
    return {
      rule: {
        id: null,
        product_id: null,
        fromqty: null,
        toqty: null,
        pricelist_id: null,
        price: null
      },
      emptyRule: {
        id: null,
        product_id: null,
        fromqty: null,
        toqty: null,
        pricelist_id: null,
        price: null
      }
    };
  },
  methods: {
    saveRule() {
      var vm = this;
      if (vm.rule.id != null) {
        vm.$http
          .post(`/api/configs/promo/edit/${vm.rule.id}`, this.rule)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      } else {
        this.$http
          .post("/api/configs/promo/add", this.rule)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      }
    },
     getRule(id) {
      var vm = this;
      this.modo = "editar";
      this.$http.get(`/api/configs/promo/getRule/${id}`)
      .then((response) => {
        vm.rule = response.data.data;
      });
    },

    resetForm() {
      this.rule = Object.assign({}, this.emptyRule);
    },
  }
}
</script>