<template>
    <b-container fluid>
        <div class="loading bg-primary" v-if="loading">Loading&#8230;</div>
        <b-row class="mt-3">
            <b-col class="d-flex justify-content-start">
                <h4>Ranking de Ventas</h4>
            </b-col>
        </b-row>
        <hr>
        <b-row class="d-flex justify-content-between">
            <b-col cols="md-2 text-left">
                <b-form-group class="form-group">
                    <label>Desde:</label>
                    <datepicker-component :default-date="'month'" v-on:datechoosed="fromDateUpdated"/>
                </b-form-group>
            </b-col>
            <b-col cols="md-2 text-left">
                <b-form-group class="form-group">
                    <label>Hasta:</label>
                    <datepicker-component v-on:datechoosed="toDateUpdated"/>
                </b-form-group>
            </b-col>
            <b-col cols="md-2 text-left">
                <b-form-group class="form-group">
                    <label>Registros:</label>
                    <select v-model="statusFilter" class="form-control" name="filter">
                        <option selected :value="0">Todos</option>
                        <option :value="1">Sin descuento</option>
                        <option :value="2">Con descuento</option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col cols="md-2 text-left">
                <b-form-group  class="form-group">
                    <label>Top:</label>
                    <select v-model="filter" class="form-control" name="filter">
                        <option selected :value="5">Top 5</option>
                        <option :value="10">Top 10</option>
                        <option :value="15">Top 15</option>
                    </select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <!--PIE CHART-->
            <b-col cols="md-8">
                <b-row>
                    <b-col cols="md-2">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" id="withCashier" name="withCashier" type="checkbox" v-model="withCashier">
                            <label class="form-check-label" for="withCashier">Con mostrador</label>
                        </div>
                    </b-col>
                    <b-col cols="md-10">
                        <piechart :config="chartData" id="piechart"/>
                    </b-col>
                </b-row>
            </b-col>
            <!--TABLA RANKING-->
            <b-col cols="md-4">
                <b-row>
                    <b-col>
                        <h5>Ranking de vendedores</h5>
                        <table class="table">
                            <thead class="bg-primary text-light">
                                <b-tr>
                                    <b-th>Posicion</b-th>
                                    <b-th>Vendedor</b-th>
                                    <b-th>Total</b-th>
                                </b-tr>
                            </thead>
                            <tbody>
                                <b-tr role="row" v-for="(rank, index) in topList" :key="index">
                                    <b-td># {{ rank.pos }}</b-td>
                                    <b-td>{{ rank.name }}</b-td>
                                    <b-td>{{ formatoDinamicoMoneda(rank.amount)}}</b-td>
                                </b-tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    
                </b-row>
            </b-col>
        </b-row>
        <!--TABLA DE VENTAS-->
        <b-row class="mt-3">
            <b-col class="d-flex justify-content-start">
                <h4>Tabla de Ventas</h4>
            </b-col>
            <b-col class="text-right">
                <b-form-group class="form-group">
                    <input type="button" @click="printChart" value="Imprimir Grafico" class="btn btn-outline-primary mx-2"/>
                </b-form-group>
            </b-col>
            <b-col class="text-right">
                <input type="button" @click="printRank" value="Imprimir tabla de ventas" class="btn btn-orange mx-2"/>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="col-md-1">FECHA</th>
                                <th class="col-md-8">COMPROBANTE</th>
                                <th class="col-md-1">CANTIDAD</th>
                                <th class="col-md-2">TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(clerk, index) in rankList">
                                <tr class="bg-primary text-light" :key="clerk.id">
                                    <td class="col-md-1"># {{index + 1}}</td>
                                    <td class="col-md-6">{{clerk.name}}</td>
                                    <td class="col-md-1">{{clerk.count}}</td>
                                    <td class="col-md-4">{{formatoDinamicoMoneda(clerk.total)}}</td>
                                </tr>
                                <template v-for="client in clerk.clients">
                                    <tr class="bg-warning" :key="client.id">
                                        <td class="col-md-1"></td>
                                        <td cols="md-6">{{client.name}}</td>
                                        <td class="col-md-1">{{client.count}}</td>
                                        <td class="col-md-4">{{formatoDinamicoMoneda(client.total)}}</td>
                                    </tr>
                                    <template v-for="sale in client.sales">
                                        <b-tr :key="sale.id">
                                            <td class="col-md-2">{{ sale.created_at| formatoFechaHora}}</td>
                                            <td class="col-md-5">{{sale.type}}{{sale.letter}} {{sale.pos}}-{{sale.number}}</td>
                                            <td class="col-md-1"></td>
                                            <td v-if="sale.type=='NC'" class="col-md-4"> -{{formatoDinamicoMoneda(sale.total)}}</td>
                                            <td v-else class="col-md-4">{{formatoDinamicoMoneda(sale.total)}}</td>
                                        </b-tr>
                                    </template>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.3.1/jspdf.umd.min.js"></script>
<script>

import piechart from '../global/Piechart.vue';
import datepickerComponent from '../global/Datepicker-component.vue';
import exportsMixin from "../../mixins/exportsMixin";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import formatDateMixin from "../../mixins/formatDateMixin";


export default {
  mixins: [formatMoneyMixin,formatDateMixin,exportsMixin],
  data: function() {
      return {
            loading:false,
			withCashier:false,
			filter:10,
            statusFilter:0,
            fromDate: null,
            toDate:null,
            chartData:{
                data: null,
                labels:[],
                backgroundColor: null
            },
            topList:[],
            rankList:[],
        }
	},
  components: {
      piechart,
      datepickerComponent
    },
  mounted(){
        this.loadChart();
        this.loadTables();
  },
  computed: {
  },
  methods: {
      loadChart() {
          var vm= this;
          var outdata = {
              'withCashier': vm.withCashier,
              'filter' :vm.filter,
              'fromDate' : vm.fromDate,
              'toDate' : vm.toDate,
              'statusFilter':vm.statusFilter,
            };
            axios.post(process.env.VUE_APP_BASE_URL+'/api/reports/getTopRankingClerkChart', outdata)
              .then((response) => {
                if (response.data.success) {
                    vm.chartData.data = response.data.data.data;
                    vm.chartData.labels = response.data.data.labels;
                    vm.chartData.backgroundColor = response.data.data.backgroundColors;
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
        },
        loadTables(){
            var vm= this;
            vm.loading=true;
            var outdata = {
                'withCashier': vm.withCashier,
                'filter' :vm.filter,
                'fromDate' : vm.fromDate,
                'toDate' : vm.toDate,
                'statusFilter':vm.statusFilter,
                };
            axios.post(process.env.VUE_APP_BASE_URL+'/api/reports/getTopRankingClerkList', outdata)
                .then(response => {
                    if(response.data) {
                        vm.topList = response.data.data;
                    }
                })
                .catch(error => {
                    alert(error);
                })

            axios.post(process.env.VUE_APP_BASE_URL+'/api/reports/getTopRankingClerk', outdata)
                .then(response => {
                    if(response.data) {
                        vm.rankList = response.data.data;
                        vm.loading=false;
                    }
                })
                .catch(error => {
                    vm.loading=false;
                    alert(error);
                })
        },
        fromDateUpdated: function (date) {
            this.fromDate = date;
            this.loadTables();
            this.loadChart();
        },
        toDateUpdated: function (date) {
            this.toDate = date;
            this.loadTables();
            this.loadChart();
        },
        printRank: function(){
            this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/printTopRankingClerk", this.rankList)
            .then((response) => {
            });
        },
        printChart: function(){
            var canvas = document.getElementById("pie-chart");
            var imgcanvas = canvas.toDataURL();
            var myWindow = window.open();
            myWindow.document.write("<img src = '" + imgcanvas + "'/>");
            myWindow.document.close();
            myWindow.focus();
            myWindow.print();
            myWindow.close();
        },
	},
    watch: {
      'withCashier': {
          handler: function (a) {this.loadTables();this.loadChart();}
      },
      'filter': {
          handler: function (a) {this.loadTables();this.loadChart();}
      },
      'fromDate': {
          handler: function (a) {this.loadTables();this.loadChart();}
      },
      'toDate': {
          handler: function (a) {this.loadTables();this.loadChart();}
      },
      'statusFilter': {
          handler: function (a) {this.loadTables();this.loadChart();}
      },
    },
}
</script>
<style scoped>
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: blue;
  }
</style>

