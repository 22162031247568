<template>
  <b-container fluid>
    <div class="row my-3">
      <b-col class="d-flex justify-content-start">
          <h4>Roles y permisos</h4>
      </b-col>
    </div>
    <b-row>
      <b-col cols="md-8">
        <b-table-simple cols="md-6" small caption-top responsive>
          <thead>
            <tr>
              <th>Roles</th>
              <th>Descripcion</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
    
            <b-tr v-for="role in roles" :key="role.id">
              <b-td valign="middle">
                <div class="pull-left user-info">
                  <span>{{ role.name }}</span>
                </div>
              </b-td>
              <b-td valign="middle">
                <div class="pull-left user-info">
                  <span>{{ role.description }}</span>
                </div>
              </b-td>
    
              <b-td>
                <div v-if="role.company_id !== null" class="divActions">
                  <b-button variant="light" size="sm" @click="changeId(role)" data-action="edit" data-toggle="tooltip" title="Editar"
                    type="button"><b-icon icon="pencil-square"></b-icon></b-button>
                  <b-button class="ml-2" variant="light" size="sm" @click="remove(role)" data-action="delete" data-toggle="tooltip" title="Borrar"
                    type="button"><b-icon icon="trash"></b-icon></b-button>
                </div>
              </b-td>
            </b-tr>
          </tbody>
        </b-table-simple>
      </b-col>
      <b-col cols="md-4">
        <NewRole :permissionsList="permissions" :roleId="roleId" @confirmsave="refreshTable()" />
      </b-col>
    </b-row>

  

  </b-container>
</template>

<script>
import NewRole from "../../sub-components/New-role.vue";

export default {
  components: {
    NewRole,
  },
  data: function () {
    return {
      loading: false,
      doSearch: true,
      roleId: null,
      roles:[],
      permissions:[],
    }
  },
  mounted() {
    this.refreshTable();
  },
  methods: {
    refreshTable() {
      const vm = this;
      vm.loading = true;
      this.roleId = null;
      this.$http
        .get('/api/configs/role/getData', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          vm.roles = response.data.data.roles;
          vm.permissions = response.data.data.permissions;
        })
        .then(() => {
          vm.loading = false;
        });
    },
    changeId(data) {
      this.roleId = data.id;
    },
    remove(data) {
      const vm = this;
      if (
        confirm("¿Está seguro que desea borrar este Rol?") == true
      ) {
        this.$http
          .post(`/api/configs/role/delete/${data.id}`)
          .then((response) => {
            
            if (response.data.code == 200) {
              vm.refreshTable();
            }
          });
      }
    }
  },


}
</script>
   