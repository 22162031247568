<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Detalle de caja</h4>
      </b-col>
      <v-guard :permissions="['CAJAS_HACER_MOVIMIENTOS']">
      <b-col class="text-right">
        <b-button @click="openModalTrans" variant="orange" size="lg">Nueva transferencia <i class="bi bi-arrow-left-right"></i></b-button>
      </b-col>
    </v-guard>
    </div>
    <!-- ---------------- CABECERA -------------------- -->
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row stats text-center">
      <b-col cols="md-12">
        <div class="stat">
          <div class="number" :class="{ 'text-danger': movementsTotal < 0}" v-if="currency == 'US$'">{{ movementsTotal | formatoAme2Dec }}</div>
          <div class="number" :class="{ 'text-danger': movementsTotal < 0}" v-else>{{ movementsTotal | formatoMoneda2Dec }}</div>
          <div class="desc">Saldo actual de caja {{ nombreCaja }}</div>
        </div>
      </b-col>
    </div>
    <!-- MODAL MOVIMIENTOS -->
    <transition name="modal">
      <div v-if="isModalVisible" class="modal-mask-product">
        <div class="modal-dialog">
          <div class="modal-content p-3 rounded">
            <div class="modal-header">
              <h4>Movimiento de caja</h4>
              <b-button variant="primary size-sm" @click="close()"><i class="bi bi-x-lg"></i></b-button>
            </div>
            <div class="modal-body">
              <NuevoMovimientoCaja
              :fundId="idCaja"
              :balance="movementsTotal"
              @confirmsave="modalSucess()"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- MODAL TRANSFERENCIA -->
    <transition name="modal">
      <div v-if="isModalVisibleTrans" class="modal-mask-product">
        <div class="modal-dialog">
          <div class="modal-content p-3 rounded">
            <div class="modal-header">
              <h4>Transferencia entre cajas</h4>
              <b-button variant="primary size-sm" @click="close()"><i class="bi bi-x-lg"></i></b-button>
            </div>
            <div class="modal-body">
              <TransferenciaFondos
              :fundId="idCaja"
              @confirmsave="modalSucess()"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- ---------------- BUSCADOR --------------- -->
    <div class="card bg-light rounded-sm p-3 my-2 border">
      <div class="row d-flex justify-content-between">
        <div class="col col-md-2">
          <input
              v-model="doSearch"
              type="search"
              class="form-control input-sm"
              placeholder="Buscar..."
              aria-controls="categories"
              />   
        </div>
        <v-guard :permissions="['CAJAS_HACER_MOVIMIENTOS']">
          <div class="col col-md-2">
            <button @click="setBalance()" id="extractAll" class="btn btn-md btn-outline-primary mt-3">
              Vaciar caja
            </button>
          </div>
          <div class="col col-md-2 text-right">
            <b-button @click="openModal" variant="success" size="lg">Nuevo Movimiento <i class="bi bi-arrow-down-up"></i></b-button>
          </div>
        </v-guard>
      </div>
    </div>

    <!-- ------------------ TABLA --------------- -->
    <b-row>
      <b-col cols="md-12">
        <vdtnet-table
        :fields="fields"
        :opts="opts"
        :selectCheckbox="0"
        ref="table"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import NuevoMovimientoCaja from "./../sub-components/Nuevo-movimiento-caja.vue";
import TransferenciaFondos from "./../sub-components/Transferencia-fondos.vue";
import formatDateMixin from "../../mixins/formatDateMixin";
import formatMoneyMixin from "./../../mixins/formatMoneyMixin";

export default {
  components: {
    VdtnetTable,
    NuevoMovimientoCaja,
    TransferenciaFondos,
  },
  mixins: [formatDateMixin, formatMoneyMixin],

  mounted() {
    this.getTotal();
    this.getFund();
  },
  watch: {
    doSearch(newValue, oldValue) {
      if (newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch);
      }
      if (newValue === "" && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch);
      }
    },
  },
  computed: {
    idCaja() {
      return this.$route.params.idCaja;
    },
  },
  methods: {
    getTotal() {
      var vm = this;
      this.$http
        .get("/api/funds/getBalance/" + vm.idCaja)
        .then((response) => {
          vm.movementsTotal = response.data.data;
        });
    },
    setBalance() {
      var vm = this;
      if(vm.movementsTotal > 0){
        if (confirm("¿Está seguro que desea vaciar esta caja?") == true) {
          this.movement.amount = vm.movementsTotal;
          this.movement.category_id = 6;
          this.movement.description = "Vaciar Caja";
          this.movement.type = "out";
        
          this.$http
          .post("/api/funds/movement/" + this.idCaja, this.movement)
          .then((response) => {
            
            vm.refreshTable();
            vm.$emit("confirmsave");
          });
        }
      }else{
        if (confirm("¿Está seguro que desea vaciar esta caja?") == true) {
          this.movement.amount = -vm.movementsTotal;
          this.movement.category_id = 7;
          this.movement.description = "Vaciar Caja";
          this.movement.type = "in";
        
          this.$http
          .post("/api/funds/movement/" + this.idCaja, this.movement)
          .then((response) => {
            
            vm.refreshTable();
            vm.$emit("confirmsave");
          });
        }
      }
    },
    openModal() {
      this.isModalVisible = true;
    },
    close() {
      this.isModalVisible = false;
      this.isModalVisibleTrans = false;
    },
    openModalTrans() {
      this.isModalVisibleTrans = true;
    },
    modalSucess(){
      this.close();
      this.refreshTable();
    },
    getFund() {
      var vm = this;
      this.$http
        .get("/api/funds/list")
        .then((response) => {
          vm.funds = response.data.data;
        })
        .then(function () {
          for (let i = 0; i < vm.funds.length; i++) {
            if (vm.funds[i].id == vm.idCaja) {
              vm.nombreCaja = vm.funds[i].name;
              vm.currency = vm.funds[i].currency;
            }
          }
        });
    },
    refreshTable() {
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
      vm.getTotal();
    },
  },
  data: function () {
    return {
      isModalVisibleTrans: false,
      isModalVisible: false,
      doSearch: "",
      loading: false,
      fundIdParent: null,
      showNewMovement: true,
      movementsTotal: 0,
      nombreCaja: "",
      currency: "",
      movements: [],
      movement: {
        id: null,
        type: "out",
        amount: null,
        fund_id: null,
        sale_id: null,
        coupon_id: null,
        tpcheck_id: null,
        purchase_id: null,
        category_id: null,
        cc_id: null,
        user_id: null,
        pac_id: null,
        category: null,
        origen: "",
        in: null,
        out: null,
      },

      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/funds/getMovements/" + this.$route.params.idCaja,
          type: "GET",
          data: (d) => {
            d.filter = this.selectedTypeFilter;
            d.branchoffice_id = this.branchofficeId;
            return d.data
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },

        processing: true,
        pageLength: 15,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        order: [[0, 'desc']],
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true
      },
      

      fields: {
        id: { visible: false },
        created_at: {
          label: "Fecha",
          searchable: true,
          render: (data) => this.formatoFechaHora(data),
        },
        notes: { label: "Descripción",searchable: true },
        category: { label: "Categoría",searchable: true },
        origen: { label: "Orígen",searchable: true },
        in: {
          label: "Ingresos",
          render: function (data, type, row, meta) {
            if(data[0] == '$'){
              var num = $.fn.dataTable.render.number(".", ",", 2).display(data[1]);
              return '$' + ' ' + num;
            }else{
              var num = $.fn.dataTable.render.number(",", ".", 2).display(data[1]);
              return 'US$' + ' ' + num;
            }
          },
        },
        out: {
          label: "Egresos",
          render: function (data, type, row, meta) {
            if(data[0] == '$'){
              var num = $.fn.dataTable.render.number(".", ",", 2).display(data[1]);
              return '$' + ' ' + num;
            }else{
              var num = $.fn.dataTable.render.number(",", ".", 2).display(data[1]);
              return 'US$' + ' ' + num;
            }
          },
        },
        balance: {
          label: "Saldo",
          render:(data, type, row, meta) => {
            if(this.doSearch == ""){
              if(data[0] == '$'){
                var num = $.fn.dataTable.render.number(".", ",", 2).display(data[1]);
                if (!num) {
                  return '$'+' '+ "0,00";
                }else{
                  return '$' + ' ' + num;
                }
              }else{
                var num = $.fn.dataTable.render.number(",", ".", 2).display(data[1]);
                if(!num){
                  return 'US$' + ' ' + "0.00";  
                }else{
                  return 'US$' + ' ' + num;
                } 
              }
            }else{
              return '';
            }  
          },         
        },
      },
    };
  },
};
</script>