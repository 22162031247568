import Sales from '../components/reports/Vision-general-de-ventas.vue';
import Ranking from '../components/reports/Ranking-por-producto.vue';
import Reportz from '../components/reports/Report-Z.vue';
import Ivasales from '../components/reports/iva-ventas.vue';
import ReportReceipt from '../components/reports/Receipt-report.vue';
import ReportPayorder from '../components/reports/Payorder-report.vue';
import Ivapurchases from '../components/reports/iva-compras.vue';
import Purchases from '../components/reports/Vision-general-de-compras.vue';
import Stock from '../components/reports/Stock-minimo.vue';
import Stockvalued from '../components/reports/Stock-valorizado.vue';
import Funds from '../components/reports/Movimientos-de-caja.vue';
import Cccbalancestodate from '../components/reports/ccc-balances-to-date.vue';
import Pacbalancestodate from '../components/reports/Lista-saldo-proveedores.vue';
import Salesclerksranking from '../components/reports/Ranking-piechart.vue';
import Cccbysalesclerk from '../components/reports/ccc-por-vendedor.vue';
import EmptyReport from '../components/reports/EmptyReports.vue';
import yearlyConsolidated from '../components/reports/Consolidado-anual.vue';
import Consolidated from '../components/reports/Consolidado.vue';

const reports = {
    path: '/reports',
    meta: {
        requiresAuth: true
    },
    component: EmptyReport,
    // se crea un componente vacio para que funcione bien, siguiendo esta guia https://dev.to/berniwittmann/my-approach-on-vue-router-architecture-and-nested-routes-2kmo
    children: [
        { path: 'sales', component: Sales, name: 'Sales' },
        { path: 'ranking', component: Ranking, name: 'Ranking' },
        { path: 'reportz', component: Reportz, name: 'Reportz' },
        { path: 'ivasales', component: Ivasales, name: 'Ivasales' },
        { path: 'reportReceipt', component: ReportReceipt, name: 'ReportReceipt' },
        { path: 'reportPayorder', component: ReportPayorder, name: 'ReportPayorder' },
        { path: 'ivapurchases', component: Ivapurchases, name: 'Ivapurchases' },
        { path: 'purchases', component: Purchases, name: 'Purchases' },
        { path: 'stock', component: Stock, name: 'Stock' },
        { path: 'stockvalued', component: Stockvalued, name: 'Stockvalued' },
        { path: 'funds', component: Funds, name: 'Funds' },
        { path: 'cccbalancestodate', component: Cccbalancestodate, name: 'Cccbalancestodate' },
        { path: 'pacbalancestodate', component: Pacbalancestodate, name: 'Pacbalancestodate' },
        { path: 'salesclerksranking', component: Salesclerksranking, name: 'Salesclerksranking' },
        { path: 'cccbysalesclerk', component: Cccbysalesclerk, name: 'Cccbysalesclerk' },
        { path: 'Consolidado', component: yearlyConsolidated, name: 'Consolidado' },
        { path: 'Consolidated', component: Consolidated, name: 'Consolidated' },
    ],
};

export default reports;