import axios from 'axios'

export const getWarehouses = ({ commit }) => {
  commit('setLoading', true);
  axios.get('/api/warehouses/list')
    .then(response => {
      commit('getWarehouse', response.data.data)
    })
    // fail
    .catch(e => {
      commit('getWarehouse', e.response)
    })
    .finally(() => {
      commit('setLoading', false);
    });
}