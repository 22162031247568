<template>
  <div>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="d-flex align-items-center justify-content-center bg py-7 py-lg-8 pt-lg-9" style="height: 100vh;">
      <b-container>
        <!-- ------------------------ALERTS------------------------------------------------- 
        <div class="alert" v-model="showDismissibleAlert" variant="danger" dismissible>
          <span> alerta </span>
        </div>
        <div class="alert" v-model="showDismissibleAlert" variant="danger" dismissible>
          <span> warning </span>
        </div>
        <div class="alert" v-model="showDismissibleAlert" variant="danger" dismissible>
          <span>Tu email o contraseña no son validos. </span>
        </div>
        -->
        
        <div class="header-body text-center mb-7">
          <b-row b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5 mt-4">
              <h2><img width="180" src="@/assets/img/logo-qupro.png"></h2>
            </b-col>
          </b-row>
        </div>
        
    
    
        <!-- ------------------------ FORMULARIO ------------------------------------------------- -->
        <b-row align-h="center">
          <b-col cols="lg-5 sm-10 my-4">
            <b-card no-body class="bg-light border-0 mb-0">
              <ErrorApi/>
              <SuccessApi/>
              <b-card-header class="bg-light">
                <h4 class="text-center">Ingresar a tu cuenta</h4>
              </b-card-header>
              <b-card-body class="px-4 py-4">
                <b-form role="form" @keyup.enter="login">
                  <b-form-group>
                    <b-form-input 
                    v-model="credentials.email" 
                    type="text"
                    placeholder="E-mail"
                    label="E-mail" 
                    autocomplete="off"
                    name="email" 
                    required>
                    </b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <b-form-input 
                      v-model="credentials.password" 
                      type="password"
                      placeholder="Contraseña"
                      label="Contraseña"
                      autocomplete="off"
                      prepend-icon="ni ni-lock-circle-open" 
                      name="password" 
                      required></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <b-button class="my-2" variant="orange" size="lg" @click="login">Ingresar al sistema</b-button>
                  </b-form-group>
                    <a href="/password/reset">Perdiste tu contraseña?</a>
                </b-form>    
              </b-card-body>
              <b-card-footer>
                <b-col class="text-center s-12">
                  Necesitas una cuenta? <a href="/register">Registrate acá</a>
                </b-col>
              </b-card-footer>
            </b-card>
            
            
            
            <!-- ------------------------ FOOTER ------------------------------------------- -->
          </b-col>  
        </b-row>

      </b-container>
    </div>
  </div>

</template>

<script>
import ErrorApi from '../global/Errores.vue'
import SuccessApi from '../global/Success.vue'
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    ErrorApi,
    SuccessApi
  },
  data: function() {
    return {
      credentials: {
        email: null,
        password: null,
        device: 'qupro-app'
      }
    }
  },
  created () {
      this.$store.dispatch('authUser/getLoading')
   },
  computed: {
    ...mapState('authUser',{
         loading: state => state.loading,
      }),
  },
  methods: {
    ...mapActions('authUser', ['getLoading']),
    login() {
      var vm=this;
      vm.getLoading();
      vm.$http.post(process.env.VUE_APP_BASE_URL+'/login', this.credentials)
      .then(response => {
        if(response.data.message=='Success') {
          localStorage.setItem('token', response.data.token)
          vm.$http.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

          window.location.href = '/ventas'
        }
      })
      .catch(error => {
        vm.getLoading();
        console.log(error);
      })
    }
  }
}
</script>

<style scoped>
.bg {
  background-color: #4477bd;
}
</style>