import Vue from 'vue'
import VueRouter from 'vue-router'

import sales from './sales';
import purchases from './purchases';
import ecommerce from './ecommerce';
import inventory from './inventory';
import funds from './funds';
import config from './config';
import dashboard from './dashboard';
import reports from './reports';
import ReprintSale from '../components/general/Reprint-sale.vue';
import Downloads from '../components/general/Download.vue';
import Billing from '../components/Billing.vue';
import ReceiptTicket from '../components/general/Receipt-Ticket.vue';
import OrderNoteTicket from '../components/general/Order-notes-Ticket.vue';
import Login from '../components/auth/Login.vue';
import Register from '../components/auth/Register.vue';
import News from '../components/general/Alerts-list.vue';
import Ticket from '../components/general/Ticket.vue';
import Ticketg from '../components/general/TicketGrido.vue';
import Changepassword from '../components/configs/ChangePassword.vue';

Vue.use(VueRouter)

const FakeError = {
  template: '<div>No se encuentra la pagina que usted esta buscando</div>',
};
const BlockError = {
  template: '<div>No tiene permiso para entrar a esta seccion</div>',
};

const routes = [

  // REIMPLEMENTAR GUARDS EN TODO
  dashboard,
  sales,
  purchases,
  ecommerce,
  inventory,
  funds,
  config,
  reports,

  // ERRORES
  { path: '/login', name: 'Login', component: Login, meta: {hideForAuth: true}},
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to, from, next) => {
      localStorage.removeItem("token");
      next({ name: 'Login' })
    }
  },
  { path: '/billing', name: 'Billing', component: Billing, meta: {requiresAuth: true}},
  { path: '/register', name: 'Register', component: Register, meta: {hideForAuth: true}},
  { path: '/sales/ticket/:sale_id', name: 'Ticket', component: Ticket, props: true, meta: {requiresAuth: true}},
  { path: '/sales/ticketg/:sale_id', name: 'Ticketg', component: Ticketg, props: true, meta: {requiresAuth: true}},
  { path: '/cobranzas/ticket/:receipt_id', name: 'Receipt-Ticket', component: ReceiptTicket, props: true, meta: {requiresAuth: true}},
  { path: '/ordernotes/ticket/:orderNoteId', name: 'Order-notes-Ticket', component: OrderNoteTicket, props: true, meta: {requiresAuth: true}},
  { path: '/news', name: 'News', component: News, meta: {requiresAuth: true}},
  { path: '/nopodes', component: BlockError },
  { path: '/mi-perfil', component: Changepassword, meta: {requiresAuth: true} },
  { path: '/sales/reprintSale', name: 'ReprintSale', component: ReprintSale, meta: {requiresAuth: true}},
  { path: '/downloads/list', name: 'Downloads', component: Downloads, meta: {requiresAuth: true}},



  { path: '/:notFound(.*)', component: FakeError },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const PAGE_TITLE = 'Qupro'

router.beforeEach((to, from, next) => {
  
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!localStorage.getItem('token')) {
        next({ name: 'Login' })
      } else {
        if (localStorage.getItem('expired') === 'true' && to.name != 'Billing') {
          next({ name: 'Billing' })
        }
      }
    } else {
      next() // does not require auth, make sure to always call next()!
    }
    
    if (to.matched.some(record => record.meta.hideForAuth)) {
      if (localStorage.getItem('token')) {
        next({ path: '/ventas' });
      } else {
        next();
      }
    } else {
      next();
    }
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
      document.title = to.name + ' - ' + PAGE_TITLE || PAGE_TITLE;
  });
});

import Permissions from "@waju/vue-guard"

Vue.use(Permissions, { router, errorRoute: "/403" });

export default router
