<template>
    <div>
        <div class="modal-mask-product">
            <div class='modal-wrapper-product'>
                <div class='modal-container-product'>
                    <div class='modal-header-product row'>
                        <div class='col-md-12'>
                            <label>
                                Ingresá el articulo
                            </label>
                            <button class="modal-default-button btn btn-sm btn-white ml-2" @click="close">
                                Cancelar
                            </button>
                        </div>
                    </div>
                    <div class='modal-body-product row'>
                        <div>
                            <div class="form-group">
                                <label>Descripcion</label>
                                <input type="text" v-model='product.name' min="0" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Precio</label>
                                <input type="number" v-model='product.price' min="0" step="0.01" class="form-control">
                            </div>
                            <div class="form-group">
                                <label class="control-label">Tipo de IVA</label>
                                <select name="iva" v-model='product.taxtype' class="form-control">
                                  <option value="0">SIN IVA</option>
                                  <option value="2.5">IVA 2.5%</option>
                                  <option value="5">IVA 5%</option>
                                  <option value="10.5">IVA 10,5%</option>
                                  <option value="21">IVA 21%</option>
                                  <option value="18">IVA 27%</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class='modal-footer-product row'>
                        <button type='submit' class='btn btn-lg btn-orange' @click='sendProducts'>Agregar Producto</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return{
                product: {
                    name: '',
                    price: 0,
                    taxtype: 21,
                }
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            sendProducts() {
                var newitem = {
                    quantity: 1, 
                    id: 0,
                    price: this.product.price, 
                    bkprice: this.product.price, 
                    name: this.product.name, 
                    includetax: 1,
                    taxtype: this.product.taxtype,
                    type: "LABEL",
                    unit: "U",
                    gross: this.product.price / (1+(this.product.taxtype/100)),
                    tax: (this.product.price / (1+(this.product.taxtype/100))) * this.product.taxtype/100,
                    barcode: null,
                    code: null,
                    promorules: [],
                    stock:0,
                };

                this.$emit('sendProduct', newitem);
            }
        }
        
    }
</script>
<style>
.modal-container-product {
  width: 50%;
  height: 42%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  overflow-y: auto;
}
.modal-mask-product {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}
.modal-wrapper-product {
  display: table-cell;
  vertical-align: middle;
}
.modal-header-product {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4477BD;
}
.modal-header-product button {
    margin: 3px;
}
.modal-body-product {
  margin: 20px 0;
  height: 45svb;
}
.modal-container-product .modal-footer-product {
  position: fixed;
  z-index:9998;
  border-top: 1px solid #4477BD;
  text-align: right;
  padding:20px;
  width: 46%;
  bottom: 230px;
}
</style>