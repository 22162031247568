<template>
  <b-container fluid>
    <div class="row pt-3">
    <h2>
        <label for="">Detalle de presupuesto</label>
    </h2>
  </div>
    <!-- ------- DATOS DEL PRESUPUESTO -------- -->
    <b-card class="bg-light d-flex justify-content-between mb-4 p-0">
      <b-row class="mt-3">
        <b-col class="sm-3">
          <b-form-group v-if="budget.letter != null">
            <h2 class="control-label">FC {{ budget.letter }} <!--"%04d"--> {{ budget.pos }}-<!--"%08d"--> {{ budget.number
              }}</h2>
          </b-form-group>
        </b-col>
        <b-col cols="sm-3">
          <b-form-group>
            <h2 class="control-label"
            >Cliente: {{ budget.client.name }}</h2
            >
          </b-form-group>
        </b-col>
          <!-- <div v-if="@if (Auth::user()->branchoffice->options->salesclerk_module)*/"> -->
          <b-col cols="sm-3">
            <b-form-group>
            <h2 class="control-label"
              >Vendedor: {{ clerkname }}</h2
            >
            </b-form-group>
          </b-col>
          <b-col cols="sm-3">
            <b-form-group>
              <h2 class="control-label"
                >Fecha:
                {{ createdDateFormatted }}</h2
              >
            </b-form-group>
          </b-col>
          <!--  </div> -->
          
          <div v-if="budget.response != null">
            <b-row>
              <b-col cols="md-6">
                <label class="control-label"
                  >CAE: {{ budget.response["CAE"] }} <!-- eh???? --></label
                >
              </b-col>
              <b-col cols="md-6">
                <p class="text-secondary">
                  CAE Fech. Venc.:
                  {{
                    date("d/m/Y", strtotime(budget.response["CAEFchVto"]))
                  }} <!-- eh???? -->
                </p>
              </b-col>
            </b-row>
          </div>
      </b-row>
    </b-card>
    <!-- ------ TABLA ------ -->
    <b-row>
      <b-col cols="md-8">
        <b-table-simple sticky-header small caption-top responsive>
          <b-thead head-variant="light">
            <b-tr>
              <b-th class="col-small"> Cod </b-th>
              <b-th>Item</b-th>
              <b-th class="col-small">Cantidad</b-th>
              <b-th class="col-small">Precio unitario</b-th>
              <b-th class="col-small">Total</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <!-- @foreach ($budget->budgetDetails as $value) -->
            <b-tr
              v-for="value in budget.budget_details"
              :key="value.id"
              role="row"
              class="add"
            >
              <b-td>{{value.code}}</b-td>
              <b-td>{{ value.description }}</b-td>
              <b-td>{{ value.quantity }}</b-td>
              <b-td>{{ formatoDinamicoMoneda(value.price) }}</b-td>
              <b-td>{{ formatoDinamicoMoneda(value.price * value.quantity) }}</b-td>
            </b-tr>
            <!-- @endforeach -->
          </b-tbody>
        </b-table-simple>
        <!-- ------ NOTA PARA CLIENTE ------ -->
      </b-col>
      <!-- ------ TABLA TOTALES ------ -->
      <!-- ------ TOTALES ------ -->
      <b-col cols="md-4">
        <div class="card mb-4">
          <div class="card-header">
            <h3>Totales del Presupuesto</h3>
          </div>
          <b-card-body style="font-size: medium;">
            <b-form-group>
              <b-row v-if="budget.discount > 0">
                <!-- ------ Sub total ------ -->
                <b-col cols="md-6 text-left">
                  SUBTOTAL:
                </b-col>
                <b-col cols="md-6 text-left">
                  <span>{{ formatoDinamicoMoneda(budget.subtotal + budget.discount) }}</span>
                </b-col>
              </b-row>
              <!-- ------ Descuento ------ -->
              <b-row>
                <b-col cols="md-6 text-left">
                  DESCUENTO:
                </b-col>
                <b-col cols="md-6 text-left">
                  <span>{{formatoDinamicoMoneda (budget.discount) }}</span>
                </b-col>
              </b-row>
              <!-- ------ Sub total ------ -->
              <b-row>
                <b-col cols="md-6 text-left">
                  SUBTOTAL:
                </b-col>
                <b-col cols="md-6 text-left">
                  <span>{{formatoDinamicoMoneda(budget.subtotal) }}</span>
                </b-col>
              </b-row>
              
              <b-row v-if="
                  (budget.taxes && budget.letter === 'A') || budget.letter === 'M'
                "
              ></b-row>
              <!-- ----------- IVA ---------- -->
              <b-row v-for="(tax, key) in budget.taxes" :key="key">
                <b-col cols="md-6 text-left">IVA {{key}}%:</b-col>
                <b-col cols="md-6 text-left">
                  <span>{{formatoDinamicoMoneda(tax) }}</span>
                </b-col>
              </b-row>
              <!-- ---- TOTAL -------- -->
              <b-row style="font-weight: bold;">
                <b-col cols="md-6 text-left mt-3">
                  TOTAL:
                </b-col>
                <b-col cols="md-6 text-left mt-3">
                  <span id="htmlTotal">{{formatoDinamicoMoneda(budget.total) }}</span>
                </b-col>
              </b-row>
          

            </b-form-group>
          </b-card-body>
        </div>
        <div v-if="budget.notes !=''" class="form-group" style="margin-top:10px">
          <label>Nota para el cliente</label>
          <textarea v-model="budget.notes" disabled="disabled" name="note" rows="3" class="form-control"></textarea>
        </div>
      </b-col>
    </b-row>
    <!-- ------ BOTON ------ -->
    <b-row class="receipt-footer d-flex justify-content-end mb-3">
      <DownloadDoc :pdfurl="pdfurl" :open="false" :filename="budget.client.name+budget.created_at+'.pdf'">
        <b-button variant="outline-primary" size="lg" class="mr-4">Descargar</b-button>
      </DownloadDoc>
      <DownloadDoc :pdfurl="pdfurl" :open="true" :filename="budget.client.name+budget.created_at+'.pdf'">
        <b-button variant="orange" size="lg">Imprimir</b-button>
      </DownloadDoc>
    </b-row>
  </b-container>
</template>

<script>
import DownloadDoc from "../../global/DownloadDoc";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";

export default {
  components: {
    DownloadDoc
  },
  mixins: [formatMoneyMixin],
  props: ["budgetId"],
  data: function() {
    return {
      pdfurl: process.env.VUE_APP_BASE_URL+'/api/budgets/budPDF/' + this.budgetId,
      budget: {
        letter: null,
        pos: null,
        number: null,
        client_id: null ,
        subtotal: null,
        discount: null,
        taxes: null,
        total: null,
        budget_details: [{
          budget_id: null,
          description: null,
          quantity: null,
          price: null
        }],
        client: {
          name: null,
          clerk: {
            name: null
          }
        }
      }
    }
  },
  computed: {
    createdDateFormatted() {
      return new Date(this.budget.created_at).toLocaleDateString('es-AR')
    },
    clerkname(){
      return this.budget.client.clerk != undefined ? this.budget.client.clerk.name: ''
    }
  },
  mounted() {
    this.getBudgetDetail(this.budgetId);
  },
  methods: {
    getBudgetDetail(budgetId){
        var vm= this;
        this.$http.get('/api/budgets/getBudget/' + budgetId)
          .then(response => {
            if(response.status == "200") {
                vm.budget = response.data.data;                
                console.log(vm.budget);
                console.log(vm.budget.budget_details);
            }
        })
        .catch(error => {
            alert(error);
        })
    },

  }
};
</script>
