<template>
    <b-container fluid>
        <div class="loading" v-if="loading">Loading&#8230;</div>
        <b-row class="mt-3">
            <b-col class="d-flex justify-content-start">
                <h4>Descargas</h4>
            </b-col>
        </b-row>    
        <!-- TABLA -->
        <b-table-simple striped small caption-top responsive class="mt-2">
            <b-thead head-variant="light">
                <b-tr>
                    <b-th>Fecha</b-th>
                    <b-th>Nombre</b-th>
                    <b-th>Estado</b-th>
                    <b-th>Descargar</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="file in downloads" :key="file.id">
                    <b-th>{{formatoFechaHora(file.created_at)}}</b-th>
                    <b-th>{{getName(file.url)}}</b-th>
                    <b-th v-if="file.status == 0" class="bg-success">Pendiente de descarga</b-th>
                    <b-th v-else class="bg-danger">Descargado</b-th>
                    <b-th v-if="file.url.includes('.pdf')">
                        <button title="Descargar PDF" type="button" class="btn btn-light" @click="downloadPDF(file)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
                            </svg>
                        </button>
                    </b-th>
                    <b-th v-else>
                        <button title="Descargar CSV" type="button" class="btn btn-light" @click="downloadPDF(file)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-csv" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z"/>
                            </svg>
                        </button>
                    </b-th>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </b-container>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import exportsMixin from "../../mixins/exportsMixin";
import { mapState, mapActions } from 'vuex';
import myaxios from 'axios';


export default {
  mixins: [formatDateMixin, exportsMixin],
  data: function() {
    return {
        loading: false,
        listDownload: []
      }
    },
  computed: {
    ...mapState('downloads',{
          downloads: state => state.downloads,
        }),
    },
  mounted() {
        this.$store.dispatch('downloads/getDownloads')
    },
  methods: {
    ...mapActions({
        getDownloads: 'downloads/getDownloads',
    }),
    async downloadPDF(data) {
      try {
        const { url, id } = data;
        const isPDF = url.includes('.pdf');
        const filename = isPDF ? `${url}.pdf` : url;

        const presignedResponse = await axios.post(`/api/downloads/getPresigned/${id}`);
        const fileURL = presignedResponse.data.data;

        const uninterceptedAxiosInstance = myaxios.create();
        const fileResponse = await uninterceptedAxiosInstance.get(fileURL, { responseType: 'blob' });

        const blobType = isPDF ? 'application/pdf' : 'text/csv';
        const blob = new Blob([fileResponse.data], { type: blobType });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        URL.revokeObjectURL(link.href);

      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    getName(data){
        var name = data.split('/')
        return name[1];
    }
  }
}
</script>