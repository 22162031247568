<template>

<b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <!-- ------- DATOS DEL PROVEEDOR -------- -->
    <b-row class="mt-5">
        <b-col cols="sm-3">
          <b-form-group>
            <label class="control-label" v-if="provider !== null">Proveedor: {{ provider.name }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="sm-3">
          <b-form-group>
            <label class="control-label" v-if="provider !== null">Nombre de Fantasia: {{ provider.business }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="sm-3">
          <b-form-group>
              <label class="control-label" v-if="provider !== null">Direccion: {{ provider.address }}</label>
          </b-form-group>
        </b-col>
        <b-col cols="sm-3">
          <b-form-group>
              <label class="control-label" v-if="provider !== null">Telefono: {{ provider.phone_1 }}</label>
          </b-form-group>
        </b-col>
      </b-row>  
    <!-- HEADER-->
    <div class="stats">
        <b-row>
            <b-col cols="lg-12 text-center">
            <div class="stat">
                <div class="number"> {{formatoDinamicoMoneda(balance)}}</div>
                <div class="desc"> Saldo actual de cuenta corriente </div>
            </div>
            </b-col>
        </b-row>
    </div>
    <!-- FILTROS-->
    <b-row class="mb-2 d-flex justify-content-between">
        <!-- BTN EXPORTAR PDF -->
        <b-col cols="md-3" class="text-left">
            <input type="button" @click="exportPDF()" class="btn btn-md btn-light" value="Exportar PDF">
        </b-col>
        <b-form inline cols="md-3">
            <b-select v-model="filters.type">
                <option selected="selected" value="0">Todos los movimientos</option>
                <option value="1">Movimientos a saldar</option>
                <option value="2">Movimientos saldados</option>
            </b-select>
        </b-form>
        <!-- FILTRO FECHA -->
        <b-form inline cols="md-3">
            <Daterange :opendir="'left'" :filtered="true" @datepicked="dateUpdated"/>
        </b-form>
    <!----------------- MODAL PASE A CUENTA  MODAL------------------>
        <b-col cols="md-3" class="text-right">
            <b-button v-b-modal.modal-1 variant="success">Nuevo pase a cuenta</b-button>
            <b-modal id="modal-1" ok-only ok-variant="secondary" ok-title="Cancel" title="Nuevo pase a cuenta">
                <b-col cols="md-12">
                    <NuevoPaseCuentaProveedor :providerId="providerId" v-if="showNewProvider" @confirmsave="refreshTable()" @clean_id="providerIdParent = null"/>
                </b-col>
            </b-modal>  
        </b-col>
    </b-row>

    <!-- TABLA -->
    <b-row>
        <b-col cols="sm-12">
            <b-table-simple striped small caption-top responsive>
                <b-thead>
                    <b-tr role="row">
                        <b-th style="text-align:center;">Fecha</b-th>
                        <b-th style="text-align:center;">Vencimiento</b-th>
                        <b-th style="text-align:center;">Comprobante</b-th>
                        <b-th style="text-align:center;">Concepto</b-th>
                        <b-th style="text-align:center;">Debe</b-th>
                        <b-th style="text-align:center;">Haber</b-th>
                        <b-th style="text-align:center;">Saldo</b-th>
                        <b-th style="text-align:center;"></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="cc in accounts" :key="cc.id" role="row" class="odd">
                        <b-td>{{cc.created_at}}</b-td>
                        <b-td>{{cc.duedate}}</b-td>
                        <b-td>
                            <a v-if="cc.menu!==null && cc.menu['type']=='purchase'" :href="'/compras/index/detalle/'+cc.purchase_id">{{cc.number}}</a>
                            <a v-if="cc.menu!==null && cc.menu['type']=='payorder'" :href="'/compras/detalle-orden-de-pago/' + cc.payorder_id">{{cc.number}}</a>
                        </b-td>
                        <b-td>{{cc.notes}}</b-td>
                        <b-td>{{formatoDinamicoMoneda(cc.in)}}</b-td>
                        <b-td>{{formatoDinamicoMoneda(cc.out)}}</b-td>
                        <b-td>{{formatoDinamicoMoneda(cc.saldo)}}</b-td>
                        <b-td>
                            <button title="Anular comprobante" type="button" class="btn btn-light" v-if="cc.purchase_id !== null" v-on:click="cancelPurchase(cc.purchase_id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
                                </svg>
                            </button>
                            <button title="Descargar PDF" type="button" class="btn btn-light marginButton" v-if="cc.payorder_id !== null" href="javascript:void(0);" @click="getPDF(cc)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">'+
                                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>'+
                                </svg>
                            </button>
                            <button title="Cancelar pago" type="button" class="btn btn-light marginButton" v-if="cc.payorder_id !== null" v-on:click="cancelPayorder(cc.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
                                </svg>
                            </button>                 
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-col>
    </b-row>
    <!-- PAGINACION -->
    <div class="row">
        <div class="col">
            <nav class="text-center" aria-label="Page navigation example">
                <label class="mr-2">Mostrar: </label>
                <b-form-select v-model="filters.itemsPerPage" style="width: 55px; margin-right: 5px;">
                    <option :value="15">15</option>
                    <option :value="25">25</option>
                    <option :value="50">50</option>
                </b-form-select>
                <ul class="pagination justify-content-end">
                    <li class="page-item" v-bind:class="[{'disabled': filters.page === 1}]">
                        <a class="page-link" href="#" v-on:click="prevPage">Anterior</a>
                    </li>
                    <li v-if="(filters.page - (pageInterval / 2)) > 0" class="page-item">
                        <a class="page-link" href="#" v-on:click="prevPage">...</a>
                    </li>
                    <template v-if="totalPages > 1"> <!-- Asegúrate de verificar totalPages aquí -->
                        <template v-for="index in totalPages">
                        <li :key="index" class="page-item" v-bind:class="[{'active': filters.page === index}]">
                            <a class="page-link" href="#" v-on:click="changePage(index)">{{index}}</a>
                        </li>
                        </template>
                    </template>
                    <li v-if="totalPages > (filters.page + (pageInterval / 2))" class="page-item">
                        <a class="page-link" href="#" v-on:click="nextPage">...</a>
                    </li>
                    <li class="page-item" v-bind:class="[{'disabled': filters.page >= totalPages}]">
                        <a class="page-link" href="#" v-on:click="nextPage">Siguiente</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>

</b-container>


</template>

<script>

import Daterange from '../../global/Daterange-component.vue';
import NuevoPaseCuentaProveedor from './Nuevo-pase-cuenta-proveedor.vue';
import exportsMixin from "../../../mixins/exportsMixin";
import formatDateMixin from "../../../mixins/formatDateMixin";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";
import myaxios from 'axios';

export default {
  components: { Daterange, NuevoPaseCuentaProveedor},
  props: ['providerId'],
  mixins: [exportsMixin,formatDateMixin, formatMoneyMixin],
  data:function(){
    return {
        accounts:[],
        loading: false,
        balance:0,
        totalPages:1,
        pageInterval:22,
        showNewProvider: true,
        providerIdParent: null,
        provider:null,
        filters:{
            type:0,
            fromDate:null,
            toDate:null,
            page:1,
            itemsPerPage:15,
        },
    }
  },
  mounted() {
      this.setDefaultDateRange();
      this.getBalance();
      this.getCC();
      this.getProvider();
  },
  methods: {
    refreshTable() {
        this.getCC();
        this.getBalance();
    },  
    getBalance(){
        var vm= this;
        this.$http.get('/api/providers/getBalance/' + this.providerId)
          .then(response => {
            if(response.data.success) {
                vm.balance = response.data.data;
            }
        })
        .catch(error => {
            alert(error);
        })
    },
    getCC(){
        var vm= this;
        this.$http.post('/api/providers/getAccount/' + this.providerId, this.filters)
          .then(response => {
            if(response.data.success) {
                vm.accounts = response.data.data.accounts;
                vm.totalPages=response.data.data.totalPages;
            }else{
                vm.accounts=[];
                vm.totalPages=0;
            }
        })
        .catch(error => {
            alert(error);
        })
    },
    prevPage(){
        if(this.filters.page>1){
            this.filters.page = this.filters.page-1;
            this.getCC();
        }
    },
    nextPage(){
        if (this.filters.page<this.totalPages){
            this.filters.page = this.filters.page +1;
            this.getCC();
        }
    },
    changePage(data){
        this.filters.page = data;
        this.getCC();
    },
    inIntervalPage(index){
        if(this.totalPages<=this.pageInterval){return true;}

        let limitprev = this.filters.page - (this.pageInterval/2);
        let limitnext = this.filters.page + (this.pageInterval/2);

        if(limitprev<0){
            limitprev=0;
            limitnext=this.pageInterval;
        }

        if(index>limitprev && index<limitnext){
            return true;
        }

        return false;
    },
    cancelPurchase(id){
        var vm=this;
        if (confirm("¿Está seguro que desea anular este comprobante? Los comprobantes anulados pueden verse en la vista de compras") == true){
          this.$http.post("/api/purchases/cancel", { id: id })
          .then(response => {
              if(response.data.success){
                  vm.getCC();
                  vm.getBalance();
              }else{
                  alert(response.data.message);
              }
          })
          .catch(error => {
            alert(error);
          })
        }
    },
    cancelPayorder(id){
        var vm=this;
        if (confirm("¿Está seguro que desea eliminar este pago? Se desarmarán también todas las imputaciones en las que se encuentre asociado") == true){
            this.$http.get('/api/payments/cancelPayorder/'+ id)
            .then(response => {
                if(response.data.success){
                    vm.getCC();
                    vm.getBalance();
                }else{
                    alert(response.data.message);
                }
            })
            .catch(error => {
                alert(error);
            })
        }
    },
    dateUpdated: function (date) {
        this.filters.fromDate = date.startDate;
        this.filters.toDate=date.endDate;
        this.getCC();
    },
    getPDF(cc){
        let id = cc.payorder_id;
        axios.get('/api/payments/poPDF/' + id)
        .then(res => {
            const uninterceptedAxiosInstance = myaxios.create();
            uninterceptedAxiosInstance.get(res.data, { responseType: 'blob' })
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = cc.id +'.pdf'
                link.click()
                URL.revokeObjectURL(link.href)
            })
            .catch(error => {
            alert(error);
            })
            .catch(error => {
                alert(error);
            })
        })   
    },
    getProvider(){
        var vm = this;
        this.$http.get("/api/providers/getProvider/" + this.providerId)
        .then((response) => {
          vm.provider = response.data.data;
        });
    },
    exportPDF() {
        this.$http.post("/api/reports/PDFdetailccp/" + this.providerId, this.filters)
        .then((response) => {
        });
    },
    setDefaultDateRange() {
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - 30);

        this.filters.fromDate = this.formatDate(startDate);
        this.filters.toDate = this.formatDate(today);
    },
    formatDate(date) {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${day}/${month}/${year}`;
    },
  },
  watch: {
    filters: {
        handler: function () {
            this.getCC();
        },
    }, deep: true,
    'filters.itemsPerPage': {handler: function (a) {this.getCC();}},
  },
}
</script>

