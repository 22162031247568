<template>
  <div class="col-md-12 ">

    <div class="row">
      <div class="col-md-12 ">
        <div class="row">
          <div  class="col-md-8">
            <div class="row">
              <div class="col-sm-12" >
                <div class="row">
                  <div class="col-sm-2">
                    <div class="form-group">
                        <label class="control-label">RT # {{number}}</label>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label">Cliente: {{clientName}}</label>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                        <label class="control-label">Fecha: {{created_at}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-8">
        
        <div v-if="invoices.length>0">
          <br>
          <label>Comprobantes asociados:</label>
          <b-table hover :items="invoices" :fields="invoicesFields">
            <template #cell(id)="data">
              <a :href="`/ventas/index/detalle/${data.value}`" class="btn btn-sm btn-light">Ver detalle</a>
            </template>
          </b-table>
        </div>
       
      </div>
      
      <div class="col-md-4 visible-md visible-lg">
        <div data-spy="affix" data-offset-top="200" class="panel panel-default affix-top">
          <div class="panel-heading">
            <h2 class="panel-title">Total </h2>
          </div>
          <div class="panel-body">
            <ul class="list-unstyled totals">
           
              <li>
                <div class="row">
                  <div class="col-xs-7">
                    TOTAL:
                  </div>
                  <div class="col-xs-5 text-right">
                    $ <span id="htmlTotal">{{withholding.amount}}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <b-row aling-h="center">
      <b-col class="mt-3">
        <DownloadDoc :pdfurl="pdfurl" :open="true" :filename="withholding.client.name+withholding.created_at+'.pdf'">
          <b-button variant="orange" size="lg">Imprimir</b-button>
        </DownloadDoc>
      </b-col>
      <b-col class="mt-3">
        <DownloadDoc :pdfurl="pdfurl" :open="false" :filename="withholding.client.name+withholding.created_at+'.pdf'">
          <b-button variant="orange" size="lg">Descargar</b-button>
        </DownloadDoc>
      </b-col>
    </b-row>


  </div>

</template>
<script>
import DownloadDoc from "../global/DownloadDoc";

export default {
  components: {
    DownloadDoc
  },
  props: ['withholding','invoices'],
 
  data() {
    return {
      pdfurl: process.env.VUE_APP_BASE_URL+'/api/collections/recPDF/' + this.withholding.id,
      invoicesFields: [
          {
            key: 'type',
            label: 'Tipo',
            sortable: true
          },
          {
            key: 'number',
            label: 'Numero',
            sortable: false
          },
          {
            key: 'total',
            label: 'Importe',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          },
          {
            key: 'partial',
            label: 'En Recibo',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          }
          ,
          {
            key: 'id',
            label: 'Ver Detalle',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          }
      ],
    }
  },
  computed:{
    number() { return this.withholding.id },
    clientName() { return this.withholding.client != undefined?this.withholding.client.name :''},
    created_at() { return this.withholding.created_at },
  },
}
</script>