<template>
  <b-container fluid>
    <div class="loading" v-if="isLoading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Listas de precios</h4>
      </b-col>
    </div>
      <!-- ----------------------- BUSCADOR -------------------------- -->
    <b-row class="d-flex justify-content-between mt-2"
      @submit.stop.prevent="doSearch"
    >
      <b-col cols="md-2 text-left">
          <input
            v-model="doSearch"
            type="search"
            placeholder="Buscar..."
            class="form-control"
          >
      </b-col>
    </b-row>
    <!-- ----------------------- TABLAS -------------------------- -->
    <b-row>
      <b-col cols="md-8">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          @edit="getPriceList"
          @delete="deletePriceList"
          ref="table"
        />
      </b-col>
      <!-- ----------------------- FORMULARIO -------------------------- -->
      <b-col cols="md-4">
        <div class="card mb-4">
          <div class="card-header">
            <h6 class="card-title" v-if="priceList.id == null">Nueva lista de precios</h6>
            <h6 class="card-title" v-else>Editar lista de precios</h6>
            <b-button variant="primary size-sm"
              v-if="priceList.id != null" @click="priceList = emptyPriceList">
              Nueva lista
            </b-button>
          </div>
          <div class="card-body">
            <form class="form" @submit.prevent>
              <div class="form-body">
                <div class="form-group">
                  <label class="control-label mt-3" for="input-live">Nombre:</label>
                  <b-form-input
                    id="input-live"
                    v-model="priceList.name"
                    :state="nameState"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="Nombre de la lista de precios"
                    trim
                  ></b-form-input>

                  <!-- This will only be shown if the preceding input has an invalid state -->
                  <b-form-invalid-feedback
                    v-if="nameState === false"
                    id="input-live-feedback"
                  >
                    El nombre debe tener un mínimo de 3 letras
                  </b-form-invalid-feedback>

                  <!-- This is a form text block (formerly known as help block) -->
                  <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                </div>
                <div class="form-group">
                  <label class="control-label" for="input-live">Moneda:</label>
                  <b-form-select class="form-control" v-model="priceList.currency" :options="optionsCurrency"></b-form-select>
                  <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                </div>
                <div class="form-group">
                  <label class="control-label" for="input-live">Lista vinculada:</label>
                  <b-form-select class="form-control" v-model="priceList.price_list_id">
                    
                    <b-form-select-option :value="null"></b-form-select-option >
                    <b-form-select-option v-for="priceList in priceLists" :key="priceList.id" :value="priceList.id">{{ priceList.name }}</b-form-select-option>
                  </b-form-select> 
                  <!-- REQUIERE OTROS METHODS ETC <b-form-input
                  class="form-control"
                    id="input-live"
                    v-model="priceList.price_list_id"
                    :state="nameState"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="Nombre de la lista vinculada"
                    trim
                  ></b-form-input>-->

                  <!-- This will only be shown if the preceding input has an invalid state -->
                  <b-form-invalid-feedback
                    v-if="nameState === false"
                    id="input-live-feedback"
                  >
                    El nombre de la lista vinculada debe tener un mínimo de 3 letras
                  </b-form-invalid-feedback>

                  <!-- This is a form text block (formerly known as help block) -->
                  <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                </div>
                <div class="form-group">
                <!-- REQUIERE OTROS METHODS ETC     <label class="control-label" for="input-live">Porcentaje:</label>
              <b-form-input class="form-control" v-model="increase.percentage" type="number"></b-form-input>
                        
              <b-form-group>
                <b-form-radio inline v-model="increase.rounding" name="some-radios" value="A" checked>Sin redondeo</b-form-radio>
                <b-form-radio inline v-model="increase.rounding" name="some-radios" value="B">Redondear 0.1</b-form-radio>
                <b-form-radio inline v-model="increase.rounding" name="some-radios" value="C">Redondear 1</b-form-radio>
              </b-form-group> -->
                  <label class="control-label" for="input-live">% de incremento:</label>
                  <b-form-input
                  class="form-control"
                    id="input-live"
                    v-model="priceList.increment"
                    type="number"                    
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="Porcentaje de incremento"
                    trim
                  ></b-form-input>

                  <!-- This is a form text block (formerly known as help block) -->
                  <b-form-text id="input-live-help">Campo obligatorio</b-form-text>
                </div>
              </div>
            </form>
          </div>
          <div class="card-footer">
            <a v-if="nameState === null" @click="savePriceList()">
              <b-button variant="orange" size="lg">Guardar lista</b-button>
            </a>
          </div>
        </div>
    </b-col>
      
    </b-row>
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";

export default {
    components: {
      VdtnetTable
    },
    data: function() {
      return {
        doSearch: '',   
        isLoading: false,
        opts: {
          language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
          ajax: {
            url: process.env.VUE_APP_BASE_URL+"/api/priceslist/list",
            type: "GET",
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          },
        },
        fields: {
          name: { label: "Lista de precios", searchable: true },
          price_list_id: { label: "Lista vinculada" },
          increment: { label: "% de incremento" },
          // posible error con la siguiente linea (id)
          id: { visible: false },
          actions: {
            isLocal: true,
            label: "Acciones",
            width: "123px",
            render: (data, type, priceList) => {
              const isContado = priceList.name === 'CONTADO';
              const icons = isContado ?
              '<div class="divActions">' +
              "</div>" : 
              '<div class="divActions">' +
              '<button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">'+
              '<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>'+
              '<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>'+
              '</svg></button>' +
              '<button data-action="delete" data-toggle="tooltip" title="Eliminar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
              '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
              '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
              '</svg></button>' +
              "</div>"
              return icons;  
            },
          },
        },
        priceList: {
          id: null,
          name: "",
          price_list_id: null,
          increment: "",
          currency: "",
        },
        emptyPriceList: {
          id: null,
          name: "",
          price_list_id: null,
          increment: "",
        },
        optionsCurrency: [
          { value: "$", text: "Pesos" },
          { value: "US$", text: "Dolares" }
        ],
        priceLists: [],
      };
    },
    mounted() {
      this.getPriceLists();
    },
    computed: {
      nameState() {
        return this.priceList.name.length < 3 && this.priceList.name.length > 0
          ? false
          : null;
      },    
      priceListsWithoutMe() {
        let vm = this
        return this.priceLists.filter((item) => {
          return (item.id === vm.priceList.id);
        })
      }
    },
    watch: {
      doSearch(newValue, oldValue) {
        if(newValue.length >= 4 || oldValue.length >= 4) {
          this.$refs.table.search(this.doSearch)
        }
        if(newValue === '' && oldValue.length > 0) {
          this.$refs.table.search(this.doSearch)
        }
      },
    },
    methods: {
      getPriceList(data) {
        var vm = this;
        vm.isLoading = true
        this.$http.get("/api/priceslist/getPriceList/" + data.id).then((response) => {
          console.log(response.data.data);
          vm.priceList = response.data.data;
          vm.isLoading = false
        });
      },
      getPriceLists() {
        var vm = this;
        vm.isLoading = true
        // que metodo de la api sería? en este caso copié todo de marcas de productos
        // pero en pricelistapi no existen estos metodos, los que hay son getPriceList y getByProduct
        this.$http.get("/api/priceslist/list").then((response) => {
          vm.priceLists = response.data.data;
          vm.isLoading = false
        });
      },
      savePriceList() {
      this.isLoading = true
      if (this.priceList.id != null) {        
        this.$http
          .post("/api/priceslist/edit/" + this.priceList.id, this.priceList)
          .then((response) => {
            
            console.log(response.data);
            this.$refs.table.reload();
            this.priceList = this.emptyPriceList;
            this.getPriceLists();
          });
        } else {
          this.$http.post("/api/priceslist/add", this.priceList).then((response) => {
            /* if(=== null) {
              alert('Si no desea asignar una lista vinculada, elija la opción "Costo"')
            } */
            
            this.$refs.table.reload();
            this.priceList = this.emptyPriceList;
            this.getPriceLists();
          });
        }
      },
      deletePriceList(data) {
      this.isLoading = true
        // borra de la db, no queda con deleted_at
      if (confirm("Esta seguro que desea borrar esta lista de precios?") == true) {
        this.$http
          .post("/api/priceslist/delete", { id: data.id })
          .then((response) => {
            
            if (response.data.code == 201) {
              this.$refs.table.reload();
              this.priceList = this.emptyPriceList;
              this.getPriceLists();
            }
          });
      }
    },
    }
  }
</script>