<template>
  <div>
    <div class="d-flex align-items-center justify-content-center bg py-7 py-lg-8 pt-lg-9" style="height: 100vh;">
      <b-container>
        <!-- ------------------------ALERTS------------------------------------------------- 
        <div v-if="validationErrors">
            <ul class="alert alert-danger">
                <li v-for="(value, key, index) in validationErrors">@{{ value }}</li>
            </ul>
        </div>
        -->
        
        <b-row>
          <b-col cols="md-12 text-center mt-4">
            <h2><img width="180" src="@/assets/img/logo-qupro.png"></h2>
          </b-col>
        </b-row>

        <!-- ------------------------ FORMULARIO ------------------------------------------------- -->
        <b-row align-h="center">
          <b-col cols="lg-5 sm-10 my-4">
            <b-card no-body class="bg-light border-0 mb-0">
              <ErrorApi/>
              <b-card-header class="bg-light">
                <h4 class="text-center">¡Empezá 14 días gratis!</h4>
              </b-card-header>
              <b-card-body class="px-4 py-4">
                <b-form>
                  <b-form-group>
                    <b-form-input 
                      v-model="credentials.company" 
                      type="text"
                      placeholder="Nombre de la empresa..."
                      autocomplete="off" 
                      name="company" 
                      required>
                    </b-form-input>
                  </b-form-group>
    
                  <b-form-group>
                    <b-form-input 
                      v-model="credentials.name" 
                      type="text"
                      placeholder="Nombre completo..."
                      autocomplete="off" 
                      name="name" 
                      required>
                    </b-form-input>
                  </b-form-group>
    
                  <b-form-group>
                    <b-form-input 
                      v-model="credentials.email" 
                      type="text"
                      placeholder="Ingrese un E-mail"
                      autocomplete="off" 
                      name="email" 
                      required>
                    </b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <b-form-input 
                      v-model="credentials.password" 
                      type="password"
                      placeholder="Contraseña"
                      label="Contraseña"
                      autocomplete="off" 
                      name="password" 
                      required></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <span class="font-weight-bold">Estoy de acuerdo con los
                      <a href="http://qupro.co/termino-y-condiciones" target="_blank">terminos y condiciones.</a>
                    </span>
                  </b-form-group>
                  <b-button class="my-2" variant="orange" size="lg" @click="register">¡Empezar ahora!</b-button>
                </b-form>    
              </b-card-body>
              <b-card-footer>
                <b-col class="text-center s-12">
                  Ya estas registrado? <a href="/login">Ingresá acá</a>
                </b-col>
              </b-card-footer>
            </b-card>
            <!-- ------------------------ RECAPTCHA ------------------------------------------- -->
            <vue-recaptcha 
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              size="invisible"
              sitekey="6LdA4MUUAAAAABuHm2SpXnTMchR2sKhphqlf_wm6">
            </vue-recaptcha>
          </b-col>  
        </b-row>
      </b-container>
    </div>
  </div>

</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import ErrorApi from '../global/Errores.vue'
export default {
  components: { 
    VueRecaptcha, 
    ErrorApi 
  },
  data: function() {
    return {
      errors: {},
      credentials: {
        bussinessname: null,
        name: null,
        email: null,
        password: null,
        recaptcha_token: null,
        device: 'qupro-app'
      }
    }
  },
  computed: {
    validationErrors(){
        let errors = Object.values(this.errors);
        errors = errors.flat();
        return errors;
    }
  },
  methods: {
    register() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    onCaptchaVerified(recaptchaToken) {
      var vm=this;
      vm.$refs.recaptcha.reset();
      vm.credentials.recaptcha_token=recaptchaToken
      vm.$http.post(process.env.VUE_APP_BASE_URL+'/register', this.credentials)
      .then(response => {
        if(response.data.message=='Success') {
          localStorage.setItem('token', response.data.data)
          vm.$http.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          window.location.href = '/ventas'
        }
      })
      .catch(error => {
        if (error.response.status == 422){
          vm.errors = error.response.data.errors;
        }
      })
    }
  }
}
</script>

<style scoped>
.bg {
  background-color: #4477bd;
}
</style>