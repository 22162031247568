<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Movimientos de caja</h4>
      </b-col>
    </div>
    <b-row class="d-flex justify-content-center">
      <b-col cols="lg-12 md-12 sm-12 xs-12">
        <div class="stats">
          <div class="stat">
            <div class="number">{{ formatoDinamicoMoneda(total)}}</div>
            <div class="desc" > Total</div>
          </div>
        </div>
      </b-col>
    </b-row>
      
    <div class="card bg-light rounded-sm p-3 my-2 border">
      <div class="row d-flex justify-content-between">
        <div class="col col-md-2">
          <b-form-group>
            <b-form-select class="form-control" v-model="branchoffice_id" style="width: 280px;">
              <b-form-select-option :value="null">Todas las Sucursales</b-form-select-option >
              <b-form-select-option v-for="branchoffice in branchoffices" :key="branchoffice.id" :value="branchoffice.id">{{ branchoffice.name }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col col-md-2">
          <b-form-group>
              <b-form-select class="form-control" v-model="fund_id" style="width: 280px;">
              <b-form-select-option :value="null">Todas las cajas</b-form-select-option >
                <b-form-select-option v-for="fund in funds" :key="fund.id" :value="fund.id">{{ fund.name }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col col-md-2">
          <b-form-group>
            <label class="control-label">Fecha: </label>
            <Daterange :opendir="'left'" @datepicked="dateCreated"/>
          </b-form-group>
        </div>
        <div class="col col-md-2">
        <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary mr-2" value="Exportar CSV">
        <input type="button" @click="exportPDF()" class="btn btn-md btn-outline-primary" value="Exportar PDF">
        </div>
      </div>
    </div>
      

      <b-row>
        <div class="col-md-12">
          <vdtnet-table
            :fields="fields"
            :opts="opts"
            :selectCheckbox="0"
            ref="table"
          />
        </div>
      </b-row>

  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import Daterange from "../global/Daterange-component.vue";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import formatDateMixin from "../../mixins/formatDateMixin";
import exportsMixin from "../../mixins/exportsMixin";
import { mapState } from 'vuex'

export default {
  components: {
    VdtnetTable,
    Daterange,
  },
  mixins: [formatMoneyMixin,formatDateMixin,exportsMixin],
  data: function() {
    return {
      loading: false,
      branchoffice_id:null,
      fund_id:null,
      fundcategory_id:null,
      branchoffices: [],
      funds: [],
      startDate:null,
      endDate:null,
      total:0,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/reports/funds",
          type: "GET",
          data: (d) => {
            d.branchoffice_id = this.branchoffice_id,
            d.fund_id = this.fund_id,
            d.fundcategory_id = this.fundcategory_id,
            d.daterange = this.daterange
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
        processing: true,
        pageLength: 50,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        order: [[0, 'desc']],
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
      },
      fields: {
        created_at: { label: "Fecha", searchable: true,
          render: (data) => this.formatoFechaHora(data),
        },
        notes: { label: "Descripcion" },
        fund_name: { label: "Caja" },
        fund_category: { label: "Categoria" },
        amount: { label: "Monto",
          searchable: true,
          render: this.formatoDinamicoMoneda,
        },
      },
    };
  },
  computed:{
    ...mapState('authUser',{
         authUser: state => state.authUser,
    }),
    paymentsMethods:{
        get: function () { return  this.authUser.branchoffices[0].payments_methods;}
    },
    daterange() {
      if (this.startDate === null || this.endDate === null) {
        return null;
      }
      const startDate = this.formatoFecha2Daterange(this.startDate);
      const endDate = this.formatoFecha2Daterange(this.endDate);
      return `${startDate} - ${endDate}`;
    },
    filters(){
      return {
        branchoffice_id : this.branchoffice_id,
        fund_id : this.fund_id,
        fundcategory_id : this.fundcategory_id,
        daterange : this.daterange
      }
    }
  },
  created () {
      this.$store.dispatch('authUser/getAuthUser')
   },
  mounted() {
    this.getBranchoffices();
    this.getFunds();
    this.getTotals();
  },
  methods: {
    reload(){
      this.getTotals();
      this.refreshTable();
    },
    refreshTable() {  
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    },
    dateCreated(date) {
      this.startDate = date.startDate;
      this.endDate = date.endDate;
    },
    getBranchoffices() {
      var vm = this;
      this.$http.get(process.env.VUE_APP_BASE_URL+'/api/branchoffices/getBranchoffices').then((response) => {
        vm.branchoffices = response.data.data
      })
    },
    getFunds() {
      var vm = this;
      this.$http.get(process.env.VUE_APP_BASE_URL+'/api/funds/list').then((response) => {
        vm.funds = response.data.data
      })
    },
    getTotals() {
      this.$http.get(process.env.VUE_APP_BASE_URL+"/api/reports/fundstotal" , {params:this.filters})
      .then((response) => {
        this.total = response.data.data.total;
      });    
    },
    exportCSV() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/CSVFundMovReport", this.filters)
      .then((response) => {
      });
    },
    exportPDF() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/PDFFundMovReport", this.filters)
      .then((response) => {
      });
    },

  },
  watch: {
    filters() {
      this.reload();
    },
  },

};
</script>
