<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>
            Reglas de promociones
        </h4>
      </b-col>
    </div> 
    <div class="row">
      <div class="col-md-8">
        <table class="table overflow-auto">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Unidad</th>
              <th>Desde cantidad</th>
              <th>Hasta cantidad</th>
              <th>Precio</th>
              <th class="col-small"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="rule in rules" :key="rule.id">
              <td>{{rule.producto}}</td>
              <td>{{rule.unidad}}</td>
              <td>{{rule.fromqty}}</td>
              <td>{{rule.toqty}}</td>
              <td>{{rule.price}}</td>
              <td class="col-small">{{rule.action}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-4">
        <NewRule :products="products" />
      </div>
    </div>
  </b-container>
</template>
<script>
import NewRule from '../../sub-components/New-rule.vue';
export default {
  components: {NewRule},

  data: function () {
    return {
      loading: false,
      doSearch: true,
      showNew: true,
      showNewModal: false,
      showModal: false,
      idParent: null,
      rules: [],
      products:[]
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
  },
  methods: {
    loadData() {
      this.$http
        .get('/api/configs/promo/getData', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          this.rules = response.data.rules;
          this.products = response.data.products;
          this.pricelists = response.data.pricelists;
        })
    }
  },
  changeId(data) {
      this.idParent = data.id;
      this.showNewModal = true;
  },
  remove(data) {
    const vm = this;
    if (
      confirm("¿Está seguro que desea borrar este Rol?") == true
    ) {
      this.$http
        .post(`/api/configs/rule/delete/${data.id}`)
        .then((response) => {
          
          if (response.data.code == 200) {
            vm.loadData();
          }
        });
    }
  }
}
</script>