<template>
  <b-row>
    <b-col cols="12">
      <label class="control-label" for="input-live">Producto:</label>
      <label class="control-label" for="input-live">{{ product?.name }}</label>
      <b-form-group>
        <typeahead v-if="productId==null" 
                class="mt-1"
                :name="'product'"
                :default="productDefault"
                :placeh="'Buscar producto por nombre'"
                :custom-class="[]"
                :clean-on="false"
                v-on:choosed="productChoosed"
                :externalsrc="'/api/products/getProducts'"
                limit-chars="1"/>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group>
        <label class="control-label" for="input-live">Depósito destino:</label>
        <b-form-select class="form-control" v-model="movement.destination_select">
          <option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">{{ warehouse.name }}</option>
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group>
        <label class="control-label"  for="input-live">Cantidad:</label>
        <b-form-input
        class="form-control" 
          v-model="movement.quantity"
          type="number"
        ></b-form-input>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-button variant="orange" class="d-flex justify-content-end mt-4">
        <a
                type="button"
                @click="saveStockMovement()"
                data-toggle="tab"
                >Guardar movimiento</a
              >
      </b-button>
    </b-col> 
  </b-row>

</template>

<script>
import typeahead from '../../global/Typeahead.vue';

export default {
  props: ["productId"],
   components: {
      typeahead
    },
    data() {
    return {
      product:null,
      warehouses:[],
      movement: {
        product_id: null,
        quantity: null,
        origin_select: null,
        destination_select: null,
      },
    };
  },
  watch: {
    productId: function(value) {
      if (value != null) {
        this.getProduct(value);
      } else {
        this.product = this.emptyProduct;
      }
    },
  },
  mounted() {
    this.getWarehouses();
    if(this.productId != null) {
      this.movement.product_id = this.productId;
      this.getProduct(this.productId);
    }

  },
  methods: {
    getProduct(id) {
      var vm = this;
      this.$http.get("/api/products/view/" + id).then((response) => {
        vm.product = response.data.data;
        console.log(vm.product.name);
      });
    },
    getWarehouses() {
      var vm = this;
      vm.isLoading = true
      this.$http.get("/api/warehouses/list").then((response) => {
        vm.warehouses = response.data.data;
        vm.isLoading = false    
      });
    },
    saveStockMovement() {
      var vm = this;
      this.$http.post("/api/movements/addWarehouse", this.movement)
      .then((response) => {
        vm.$emit("confirmsavemovement");
      });
    },
    productChoosed(data){
      this.movement.product_id = data.id;
      console.log(data.id)
    },
  },


  computed: {
    idState() {
      return this.movement.product_id != null
    },
    productDefault(){
      return this.product != null ? (this.product.barcode!= null? this.product.barcode: this.product.name): ''
    }
  },

 
};
</script>
