<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>IVA Ventas</h4>
      </b-col>
    </div>
    <b-row class="statsreport mt-2">
      <b-col>
        <div class="stat">
          <div class="number"><span class="balance">{{minStockNumber}}</span></div>
          <div class="desc">PRODUCTOS DEBAJO DEL MINIMO DE STOCK</div>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="md-2">
        <b-button class="mr-1" variant="outline-primary" type="button" @click="exportCSV()" value="Exportar CSV">Exportar CSV</b-button>
        <b-button class="ml-1" variant="outline-primary" type="button" @click="exportPDF()" value="Exportar PDF">Exportar PDF</b-button>
      </b-col>
    </b-row>
    
    <b-row>
      <b-col cols="md-12">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          ref="table"
        />
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
import VdtnetTable from "vue-datatables-net";

export default {
  components: {
    VdtnetTable,
  },
  data: function() {
    return {
      isLoading: false,
      minStockNumber: 0,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/reports/stockmin",
          type: "GET",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
      },
      fields: {
        name: { label: "Nombre" },
        stock_minimum: { label: "Mínimo" },
        stock: { label: "Cantidad" },
        provname: { label: "Proveedor" },
        phone: { label: "Teléfono" },
        email: { label: "email" },
      },
    };
  },
  mounted() {
    this.getMinStockReport();
  },
  methods: {
    getMinStockReport() {
      var vm = this;
      vm.isLoading = true;
      this.$http.get(process.env.VUE_APP_BASE_URL+"/api/reports/stockmin_number").then((response) => {
        vm.minStockNumber = response.data.data;
        console.log(vm.minStockNumber);
        vm.isLoading = false;
      });
    },
    exportCSV() {
        // no entiendo como lo hace en el blade

      /*let vm = this;
      vm.loading = true;
      axios
        .post("/api/reports/CSVpurchaseReport", this.filters)
        .then((response) => {
          console.log("response", response);
          if (response.data) {
            vm.loading = false;
            var filename = "Vision-general-de-compras.cvs";
            var blob = new Blob([response.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            vm.loading = false;
            
          }
        })
        .catch((error) => {
          vm.loading = false;
          alert(error);
        }); */
    },
    exportPDF() {
         // no entiendo como lo hace en el blade

        /*
      let vm = this;
      vm.loading = true;
      axios
        .post("/api/reports/PDFpurchaseReport", this.filters)
        .then((response) => {
          if (response.status == 200) {
            axios
              .get("/api/reports/PDFlistReport")
              .then((response) => {
                if (response.status == 200) {
                  vm.loading = false;
                  window.open("/api/reports/PDFlistReport", "_blank") ||
                    window.location.replace("/api/reports/PDFlistReport");
                } else {
                  vm.loading = false;
                  
                }
              })
              .catch((error) => {
                console.log("error: " + error);
              });
          } else {
            vm.loading = false;
            
          }
        })
        .catch((error) => {
          vm.loading = false;
          alert(error);
        }); */
    },
  },
};
</script>

<style></style>
