<template>
  <div class="form-group">
      <date-picker v-on:change="dateUpdated" :date="startTime" :option="option"/>
  </div>
</template>
<script>
import myDatepicker from 'vue-datepicker'
import moment from 'moment';

export default {
  props:['defaultDate'],
  data () {
    return {
      dateLabel : '',
      startTime: {
        time: ''
      },
      endtime: {
        time: ''
      },
      option: {
        type: 'day',
        week: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
        month: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        format: 'DD/MM/YYYY',
        placeholder: '',
        inputStyle: {
          'padding': '6px 3px',
          'border': '1px solid #c2cad8',
          'width':'100%',
          'border-radius':'4px'
        },
        buttons: {
          ok: 'Ok',
          cancel: 'Cancelar'
        },
        overlayOpacity: 0.5, // 0.5 as default
        dismissible: true // as true as default
      },
      timeoption: {
        type: 'min',
        week: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
        month: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        format: 'YYYY-MM-DD HH:mm'
      },
      multiOption: {
        type: 'multi-day',
        week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        month: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        format:"YYYY-MM-DD HH:mm"
      },
    }
  },
  methods: {
    dateUpdated: function (date) {
      var app = this;
      app.$emit('datechoosed',date);
    }
  },
  mounted: function () {
      if(this.defaultDate!=null){
          this.startTime.time = moment.startOf(defaultDate).format('DD/MM/YYYY');
      }
  },
  components: {
    'date-picker': myDatepicker
  }
}
</script>

<style lang="css">

.cov-vue-date {
  width: 100%;
}

.cov-date-body {
  font-family: 'Nunito Sans', sans-serif !important;
}

.cov-date-monthly {
  height: 70px !important;
}
.cov-date-monthly > .cov-date-caption, .cov-date-monthly > .cov-date-previous, .cov-date-monthly > .cov-date-next {
  height: 70px !important;
}
.cov-date-monthly > .cov-date-caption {
  margin-top: -37px!important;
}
.day:hover,
.checked {
  background: #f5bc52 !important;
  color:#fff !important;
  text-shadow: 0px 0px 2px #e4931b !important;
  border-radius:4px !important;
  border: 1px solid #F2AB27 !important;
  border-bottom: 1px solid #e4931b !important;
}
</style>
