<template>

   <!--

      <pos-documents :configurl="/api/shippingpurchases/create" />

        <Receipt 
          :endpointurl="JSON.stringify('/api/purchases/add')"
          :company_typetax="{{json_encode($user->branchoffice->iva_type)}}"
          :debug="{{json_encode(env('APP_DEBUG'))}}"
          :prexistprd="{{json_encode((isset($products)) ? $products : [])}}"
          :payment_methods="{{json_encode($user->branchoffice->paymentsMethods)}}"
          :config="{{json_encode($config)}}"
          :show-prices="true"
          :show-barcodes="{{json_encode($user->branchoffice->options->showbarcodes)}}"
          :show-paymentmethods="true"
          :show-pos="true"
          :show-choosedate="true"
          :show-totals="true"
        />
        -->
        <Receipt 
            :endpointurl="'/api/shippingpurchases/add'"
            :show-prices="false"
            :show-paymentmethods="false"
            :show-pos="true"
            :show-choosedate="true"
            :show-totals="false"

            :config="config"
            :payment_methods="payments_methods"
            :branchofficeData="branchofficeData"
      />
</template>

<script>
import Receipt from '../index/nueva-compra/Receipt.vue'
import { mapState } from 'vuex'

export default {
   components: {
      Receipt
   },
   data: function() {
      return {
         config: null,
      }
    },
   created () {
      this.$store.dispatch('authUser/getAuthUser')
   },
   computed: {
      ...mapState('authUser',{
         authUser: state => state.authUser,
      }),
      payments_methods:{
         get: function () { return  this.authUser.branchoffices[0].payments_methods;}
      },
      branchofficeData:{
         get: function () { 
            return {
               'name' : this.authUser.branchoffices[0].name,
               'bsns_name' : this.authUser.branchoffices[0].business_name,
               'cuit' : this.authUser.branchoffices[0].cuit,
            }
         }
      },
   },
   watch:{
      authUser: function(value){
         if(value==null)return;
         this.config = Object.assign({},this.authUser.branchoffices[0].configs);
         this.config.doctype='R';
         this.config.loadproducts=true;
      }
   }
   
}
</script>
