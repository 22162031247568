<template>
  <div class="col-md-12">
    <div class="form-group col-border">
      <tr>
        <th>
          <div class="form-group">
            <label>Monto</label>
            <input v-model.number="amount" required type="number" min="0" step="0.01" class="form-control">
          </div>
        </th>
        <th>
          <div class="form-group">
            <label>Tarjeta</label>
            <select v-model="creditcard_id" @change="changeCreditcard" class="form-control">
              <option disabled selected>Seleccione tipo de tarjeta</option>
              <option v-for="creditcard in creditcards" :key='creditcard.id' :value="creditcard.id">
                <span v-if="creditcard.card_type === 'credit'">
                    {{creditcard.name}} (Cr&eacute;dito)
                </span>
                <span v-else>
                    {{creditcard.name}} (D&eacute;bito)
                </span>
              </option>
            </select>
          </div>
        </th>
        <th v-if="showQuotas">
          <div class="form-group">
            <label>Cuotas</label>
            <select v-model="creditcard_quota" class="form-control">
              <option disabled selected>Cuotas</option>
              <option v-for="quota in creditcard_quotas" :key='quota.id' :value="quota.quotas">{{quota.quotas}} cuotas de ${{ (amount / quota.quotas) + (amount * quota.tem / 100 / quota.quotas) | toTwo }}</option>
            </select>
          </div>
        </th>
        <th>
          <div class="form-group">
            <label>Cup&oacute;n</label>
            <input v-model.number="coupon" required type="number" min="0" class="form-control">
          </div>
        </th>
        <th valign="middle">
          <div class="payment-actions">
        
            <span id="resetPaymentAction" @click="reset()" class="delete-product mr-2" aria-hidden="true" title="Eliminar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
            </span>
            <span id="savePaymentAction" v-if="validate" @click="save()" class="ok-payment-method" aria-hidden="true" title="Grabar">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>
            </span>
          </div>
        </th>
        <th valign="middle" v-if="showQuotas" style="width:215px">
          <div class="payment-actions">
            <p>El interes total es de ${{showInterest}}</p>
          </div>
        </th>
      </tr>
    </div>
  </div>
</template>


<script>
export default {
  data: function() {
    return {
            res: {},
            creditcard_id: null,
            creditcard_quota: 0,
            creditcard_quotas: [],
            coupon: '',
            amount: 0,
            creditcards: []
          }
  },
  mounted: function () {
    this.getCreditcards();
  },
  filters: {
    toTwo: function (value) {
      if (!value) return ''
      return value.toFixed(2);
    }
  },
  computed: {
    validate () {
      let vm = this;
      if (vm.showQuotas) {
        return (vm.creditcard_id !=null && vm.coupon!='' && vm.amount > 0 && vm.creditcard_quota!=null);
      } else {
        return (vm.creditcard_id !=null && vm.coupon!='' && vm.amount > 0);
      }
    },
    showQuotas () {
      if (this.creditcard_id === null)
        return false;
      return this.creditcards.find(element => element.id === this.creditcard_id).card_type==='credit';
    },
    showInterest () {
      if (this.creditcard_quota === null)
        return 0;

      if (this.creditcard_quota > 0) {
        var tem = this.creditcard_quotas.find(element => (element.quotas === this.creditcard_quota)).tem;
        return (parseFloat(this.amount) * parseFloat(tem) / 100).toFixed(2);
      } else
        return 0;
    }
  },
  methods: {
    reset: function () {
      this.$emit('creditcardcancel');
    },
    save: function () {
      this.res.creditcard_id = this.creditcard_id;
      this.res.coupon = this.coupon;
      this.res.amount = this.amount;

      this.res.creditcard_quotas = this.creditcard_quota;
      this.res.interest = this.showInterest;
      
      this.$emit('creditcardsaved',this.res);
    },
    changeCreditcard: function () {
      let vm = this;
      vm.creditcard_quotas = vm.creditcards.find(element => element.id === vm.creditcard_id).credit_card_coefficients;
    },
    getCreditcards: function () {
      var vm = this;
      window.axios.get('/api/creditcards/getActiveCreditCard')
      .then(function (response) {
        vm.creditcards = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }
}
</script>