<template>
    <b-container fluid>
        <div class="row pt-3">
            <h2>
                <label for="">Detalle de pedido</label>
            </h2>
        </div>
        <!-- ------- DETALLE DEL PEDIDO -------- -->
        <b-card class="bg-light d-flex justify-content-between mb-4 p-0">
            <b-row class="my-3">
                <b-col cols="md-12">
                    <b-row>
                        <b-col cols="sm-4">
                            <h2>Orden de Pedido Nro: {{order.number}}</h2>
                        </b-col>
                        <b-col cols="sm-4">
                            <h2>Fecha: {{formatoDate2Fecha(order.created_at)}}</h2>
                        </b-col>
                        <b-col cols="sm-4" v-if="order.client">
                            <h2>Cliente: {{order.client.name}}</h2>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <!-- ------- TABLAS -------- -->
        <b-row>
            <b-col cols="md-8 sm-12">
                <b-table-simple striped small caption-top responsive class="mt-2 text-center">
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th>Item</b-th>
                            <b-th>Cantidad</b-th>
                            <b-th>Precio unitario</b-th>
                            <b-th>Total</b-th>
                            <b-th>Variante</b-th>
                        </b-tr>
                    </b-thead>

                    <b-tbody>
                        <b-tr v-for="details in order.details" :key="details.id">
                            <b-td>{{details.name}}</b-td>
                            <b-td class="text-center">{{details.amount}}</b-td>
                            <b-td class="text-center">{{formatoDinamicoMoneda(details.price)}}</b-td>
                            <b-td class="text-center">{{formatoDinamicoMoneda(details.amount * details.price)}}</b-td>
                            <b-td class="text-center">{{details.variant}}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <b-form-group class="form-group" style="margin-top:10px">
                    <label>Nota del cliente</label>
                    <b-form-textarea
                    id="textarea"
                    label="Notas"
                    v-model="text"
                    rows="3"
                    max-rows="6">
                    </b-form-textarea>
                </b-form-group>
            </b-col>

            <b-col cols="md-4">
                <div class="card mb-4">
                    <div class="card-header">
                        <h3 class="m-0">Totales de la orden</h3>
                    </div>
                    <b-card-body>
                        <b-row>
                            <b-col cols="4" class="text-center">
                                <p>SUBTOTAL: <span>{{formatoDinamicoMoneda(order.subtotal)}}</span></p>
                            </b-col>

                            <b-col cols="4" class="text-center">
                                <p>ENVIO: <span>{{formatoDinamicoMoneda(order.shipping_fee)}}</span></p>
                            </b-col>

                            <b-col cols="4" class="text-center" style="font-weight: bold;">
                                <p>TOTAL: <span>{{formatoDinamicoMoneda(order.total)}}</span></p>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </div>

                <div class="card mb-4">
                    <div class="card-header">
                        <h3 class="m-0">Datos del titular</h3>
                    </div>
                    <b-card-body>
                        <b-row>
                            <b-col cols="1"></b-col>
                            <b-col cols="5 text-left" v-if="order.client">
                                <p>Nombre: <span>{{order.client.name}}</span></p>
                                <p>Email: <span>{{order.client.email}}</span></p>
                                <p>Direccion: <span>{{order.client.address}}</span></p>
                                <p>Localidad: <span>{{order.client.city}}</span></p>
                            </b-col>
                            <b-col cols="1"></b-col>

                            <b-col cols="5 text-left" v-if="order.client">
                                <p>Teléfono: <span>{{order.client.phone}}</span></p>
                                <p>Metodo de envio: <span>{{order.retire_data.retire_name }}</span><span v-if="order.retire_data.retire_dni">{{ ' DNI: '+order.retire_data.retire_dni }}</span></p>
                                <p>Metodo de pago: <span>{{order.paymethod}}</span></p>
                                <p>Facturacion: <span>{{order.billing_type}}</span></p>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </div>
            </b-col>
        </b-row>
        <b-row class="receipt-footer d-flex justify-content-end mb-3">
            <b-row class="my-3">
                <b-col cols="md-12">
                    <b-button variant="paymentOptions btn btn-lg btn-orange" @click="getPDF(order.id)">Imprimir comprobante</b-button>
                </b-col>
            </b-row>
        </b-row>
    </b-container>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import myaxios from 'axios';

export default {
  props:['orderId'],
  mixins: [formatDateMixin,formatMoneyMixin],
  data() {
    return {
      text: '',
      html:null,
      order: {}
    }
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    getOrder(){
        var vm = this;
        this.$http.get('/api/ecom/orders/' + vm.orderId)
          .then(response => {
            vm.order = response.data.data;
            vm.text = response.data.data.notes;
        })
        .catch(error => {
            alert(error);
        })
    },
    getPDF(id) {
        let vm = this;
        vm.loading=true;
        this.$http.get('/api/ecom/orders/regeneratePDF/' + id)
        .then(response => {
            const uninterceptedAxiosInstance = myaxios.create();
            uninterceptedAxiosInstance.get(response.data.data, { responseType: 'blob' })
            .then((response) => {
                vm.loading=false;
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = id+".pdf"
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch((error) => {
                vm.loading=false;
                console.log('error: '+error);
            })
        }).catch(error => {
            vm.loading=false;
            alert(error);
        })
        
    },
    

  },
}
</script>
  
