<template>
  <div>
    <b-card-header>
      <h6>Medios de Pago</h6>
    </b-card-header>
    <b-card-body class="d-flex align-items-start flex-column">
      <div class="checkbox">
        <label>
          <input type="checkbox" class="md-check" checked disabled>
          Efectivo
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_paymentmethods.cuenta_corriente" type="checkbox" class="md-check">
          Cuenta Corriente
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_paymentmethods.cheques" type="checkbox" class="md-check">
          Cheques
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_paymentmethods.tarjetas" type="checkbox" class="md-check">
          Tarjetas
        </label>
      </div>
    </b-card-body>
    <a href="/configuracion/tarjetas"><b-button variant="primary" style="margin-top: 10px;margin-bottom: 20px;" v-if="newbo_paymentmethods.tarjetas">Tarjetas</b-button></a>
  </div>
</template>

<script>
export default {
  props: ['bo_paymentmethods'],
  data: function() {
    return {
      newbo_paymentmethods: {}
    }
  },
  mounted: function () {
    this.newbo_paymentmethods = this.bo_paymentmethods;
  },
  methods: {

  }
}
</script>
