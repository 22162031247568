<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Cheques de terceros</h4>
      </b-col>
      <b-col class="text-right">
          <b-button @click="openModal" variant="success" size="lg">Nuevo Cheque de tercero</b-button>
        </b-col>
    </div>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="modal-new" v-if="showNewTpCheckModal">
      <div class="close-button" @click="showNewTpCheckModal = false">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <DepositModal
      v-if="showModal"
      :funds="funds"
      @fundSelected="depositForm"
      @close="showModal = false"
    />
    <DepositModal
      v-if="showMultipleModal"
      :funds="funds"
      @fundSelected="multiDepositForm"
      @close="showMultipleModal = false"
    />
    <!-- ------------ CABECERA--------------- -->
    <b-row class="text-center">
      <b-col cols="md-12">
        <div class="stats">
          <div class="stat">
            <div class="number">
              {{ formatoDinamicoMoneda(balanceCheques)  }}
            </div>
            <div class="desc">Monto total de cheques en cartera</div>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- ------------ NUEVO CHEQUE --------------- -->
    <transition name="modal">
      <div v-if="showNewTpCheck" class="modal-mask-product">
        <div class="modal-dialog modal-xl">
          <div class="modal-content p-3 rounded">
            <div class="modal-header">
              <h4>Nuevo cheque</h4>
              <b-button variant="primary size-sm" @click="close()"><i class="bi bi-x-lg"></i></b-button>
            </div>
            <div class="modal-body">
              <b-row>
                <b-col cols="md-12">
                  <NuevoChequeTercero
                    :tpcheckId="tpcheckIdParent"
                    v-if="showNewTpCheck"
                    @reset-id="tpcheckIdParent = null"
                    @confirmsave="modalSucess()"
                  />
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- ------------ TABLA CABECERA --------------- -->
    <b-form inline class="d-flex justify-content-between my-2">
      <input
        v-model="doSearch"
        type="search"
        class="form-control input-sm"
        placeholder="Buscar..."
        aria-controls="coupons"
        />

      <div class="col-md-4">
        <div class="form-group col-md-12">
          <label class="control-label">Fecha de emision:</label>
          <Daterange ref="emision" :opendir="'right'" @datepicked="(date) => dateCreated('emision', date)" />
        </div>
      </div>
      <div class="form-group col-md-4">
        <label class="control-label">Fecha de cobro:</label>
        <Daterange ref="cobro" :opendir="'right'" @datepicked="(date) => dateCreated('cobro', date)" />
      </div>
      

      <b-form inline>
        <input 
          v-model="onlyWallet"
          id="onlyWallet"
          name="onlyWallet"
          type="checkbox"
          class="load-data"
        />
        <label for="onlyWallet" class="invoiced ml-2"
        >Sólo cheques en Cartera</label>
      </b-form>
        
      <button
        type="button"
        class="btn btn-btn btn-primary"
        :class="{ disabled: !buttonBatchDeposit }"
        id="multi-deposit"
        @click="batchDeposit"
      >
        Depositar en lote
      </button>
    </b-form>
    <!-- ------------ TABLA  --------------- -->
    <div class="row">
      <div class="col-md-12">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="1"
          @edit="changeTp_checkId"
          @cash="showCashTpCheckModal"
          @delete="deleteTpCheck"
          @row-select="selectedTpCheck"
          @row-deselect="deselectedTpCheck"
          ref="table"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import Daterange from "../global/Daterange-component.vue";
import DepositModal from "../sub-components/depositModal.vue";
import NuevoChequeTercero from "../sub-components/Nuevo-cheque-tercero.vue";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import formatDateMixin from "../../mixins/formatDateMixin";

export default {
  components: {
    VdtnetTable,
    NuevoChequeTercero,
    Daterange,
    DepositModal,
  },
  mixins: [formatMoneyMixin, formatDateMixin],
  data: function () {
    return {
      doSearch: "",
      loading: false,
      fundIdParent: null,
      tpcheckIdParent: null,
      showNewTpCheck: false,
      showNewTpCheckModal: false,
      showModal: false,
      showMultipleModal: false,
      fund: null,
      depositCheckId: null,
      tp_checks: [],
      funds: [],
      // balanceCheques: 0,
      balanceCash: 0,
      balanceBank: 0,
      onlyWallet: true,
      startDate: null,
      endDate: null,
      startDate2: null,
      endDate2: null,
      issue: false,
      selectedIndexes: [],
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: this.getTpChecks,
        serverSide: false,
        ordering: true,
        order: [],
        searching: true,
        select: { style: "multi" },
      },

      fields: {
        id: { visible: false },
        "client.name": {
          label: "Nombre",
          searchable: true,
          defaultContent: "Ninguno",
        },
        number: { label: "Número", searchable: true },
        date_issue: { label: "Emision", searchable: true },
        date_due: { label: "Cobro", searchable: true },
        origen: {
          label: "Orígen",
          defaultContent: "Ninguno",
          searchable: true,
        },
        amount: { label: "Monto", searchable: true, render: this.formatoDinamicoMoneda},
        status: { label: "Estado", searchable: true },
        provider: { label: "a proveedor", searchable: true },
        actions: {
          isLocal: true,
          label: "Acciones",
          width: "115px",
          align: "center",
          render: (data, type, row) => {
            const defaultContent = `
                    <div class="divActions">
                      <button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg></button>
                      <button data-action="cash" data-toggle="tooltip" title="Cobrar / Depositar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bank" viewBox="0 0 16 16">
                      <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.501.501 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89L8 0ZM3.777 3h8.447L8 1 3.777 3ZM2 6v7h1V6H2Zm2 0v7h2.5V6H4Zm3.5 0v7h1V6h-1Zm2 0v7H12V6H9.5ZM13 6v7h1V6h-1Zm2-1V4H1v1h14Zm-.39 9H1.39l-.25 1h13.72l-.25-1Z"/>
                      </svg></button>
                      <button data-action="delete" data-toggle="tooltip" title="Borrar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                      </svg></button>
                    </div>`;
            if (!row.action) {
              return "";
            }
            return defaultContent;
          },
        },
      },
    };
  },
  watch: {
    doSearch(newValue, oldValue) {
      if (newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch);
      }
      if (newValue === "" && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch);
      }
    },
    onlyWallet(newValue) {
      this.refreshTable();
    },
    daterange(newValue) {
      this.refreshTable();
    },
  },
  computed: {
    balanceCheques() {
      let balance = 0;
      for (let i = 0; i < this.tp_checks.length; i++) {
        balance = balance + Number(this.tp_checks[i].amount);
      }
      return balance;
    },
    daterange() {
      if (this.startDate === null && this.startDate2 === null) {
        return null;
      }
      if(this.startDate != null){
        this.issue = true;
        const startDate = this.formatoFecha2Daterange(this.startDate);
        const endDate = this.formatoFecha2Daterange(this.endDate);
        return this.issue + ' ' +`${startDate} - ${endDate}`;
      }else{
        this.issue = false;
        const startDate = this.formatoFecha2Daterange(this.startDate2);
        const endDate = this.formatoFecha2Daterange(this.endDate2);
        return this.issue + ' ' +`${startDate} - ${endDate}`;
      }
    },
    selectedTpCheckIds() {
      return this.selectedIndexes.map((index) => this.tp_checks[index].id);
    },
    buttonBatchDeposit() {
      if (this.selectedIndexes.length > 1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    dateCreated(range, date) {
      if(range === 'emision'){
        this.startDate = date.startDate;
        this.endDate = date.endDate;
      }else if(range === 'cobro'){
        this.startDate2 = date.startDate;
        this.endDate2 = date.endDate;
      }
    },
    openModal() {
      this.showNewTpCheck = true;
    },
    close() {
      this.showNewTpCheck = false;
    },
    modalSucess(){
      this.close();
      this.refreshTable();
    },
    depositForm(fundId) {
      this.showModal = false;
      this.loading = true;
      const depositData = {
        fund_id: fundId,
        check_id: this.depositCheckId,
      };
      this.$http
        .post("/api/funds/tp_checks/deposit", depositData)
        .then((response) => {
          
          this.loading = false;
          if (response.data.code == 201) {
            this.refreshTable();
          }
        });
    },
    multiDepositForm(fundId) {
      this.showMultipleModal = false;
      this.loading = true;
      /*
        fund_id: 4
        check_id: 17,15,16
        */
      const depositData = {
        fund_id: fundId,
        check_id: this.selectedTpCheckIds.join(),
      };
      this.$http
        .post("/api/funds/tp_checks/multideposit", depositData)
        .then((response) => {
          
          this.loading = false;
          if (response.data.code == 201) {
            this.refreshTable();
          }
        });
    },
    deleteTpCheck(data) {
      if (confirm("¿Está seguro que desea borrar este cheque?") == true) {
        this.loading = true;
        this.$http
          .post("/api/funds/tp_checks/delete", { id: data.id })
          .then((response) => {
            
            this.loading = false;
            if (response.data.code == 201) {
              this.refreshTable();
            }
          });
      }
    },
    getTpChecks(d, cb) {
      var vm = this;
      this.$http
        .get("/api/funds/tp_checks/list", {
          params: {
            onlyWallet: vm.onlyWallet,
            daterange: vm.daterange,
          },
        })
        .then((response) => {
          vm.tp_checks = response.data.data;
          cb(response.data);
        });
    },
    changeTp_checkId(data) {
      this.tpcheckIdParent = data.id;
      this.openModal();
      console.log(this.tpcheckIdParent);
    },
    openNewTpCheck() {
      this.tpcheckIdParent = null;
      this.showNewTpCheckModal = true;
    },
    showCashTpCheckModal(data) {
      this.depositCheckId = data.id;
      this.showModal = true;
    },
    getFunds() {
      var vm = this;
      this.$http.get("/api/funds/list").then((response) => {
        vm.funds = response.data.data;
      });
    },
    refreshTable() {
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
      console.log(vm.$refs.table);
    },
    selectedTpCheck({ indexes }) {
      var vm = this;
      // Si no selecciona todos
      if (indexes.length !== vm.tp_checks.length) {
        // recorro el arreglo y los agrego a la lista
        indexes.forEach((element) => {
          if (!vm.selectedIndexes.includes(element)) {
            vm.selectedIndexes.push(element);
          }
        });
        console.log(vm.selectedIndexes);
        return;
      }
      // Si selecciona todos, piso la lista
      vm.selectedIndexes = indexes;
      console.log(vm.selectedIndexes);
    },
    deselectedTpCheck({ indexes }) {
      var vm = this;
      // Si no deselecciona todos
      if (indexes.length !== vm.tp_checks.length) {
        // recorro el arreglo y los borro de la lista
        indexes.forEach((element) => {
          const posicion = vm.selectedIndexes.findIndex(
            (indice) => indice === element
          );
          if (posicion != -1) {
            vm.selectedIndexes.splice(posicion, 1);
          }
        });
        console.log(vm.selectedIndexes);
        return;
      }
      // Si deselecciona todos, piso la lista
      vm.selectedIndexes = [];
      console.log(vm.selectedIndexes);
    },
    batchDeposit() {
      if (
        confirm("Se depositaran " + this.selectedIndexes.length + " cheques") ==
        true
      ) {
        this.showMultipleModal = true;
      }
    },
  },
  mounted() {
    // this.getBalance();
    this.getFunds();
    this.$refs.table.dataTable.on('user-select', function ( e, dt, type, cell, originalEvent ) {
      // DT_RowClass: "reactive"
      const status = dt.row(cell.node()).data().DT_RowClass;
      if(status !== 'reactive') {
        return false;
      }
      return true;
    });
  },
};
</script>