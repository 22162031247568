<template>
  <div>
    <subnavbar-ecom></subnavbar-ecom>
    <router-view></router-view>
  </div>
</template>

<script>
import SubnavbarEcom from '../navbars/Subnavbar-ecom.vue'
export default {
  components: {
    SubnavbarEcom
  }
};
</script>