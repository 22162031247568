<template>
   <pos-documents :configurl="createFromFCLink" />
</template>
<script>
import PosDocuments from "../pos-document/receipt/Receipt.vue";

export default {
   props: ['saleId'],
   components: {
      PosDocuments
   },
   computed: {
      createFromFCLink() {
         return "/api/creditnotes/createfromFC/" + this.saleId
      }
   }
};
</script>