<template>
    <span :class="[{'currency row form-inline': selective}]">
        <select v-if="selective"
            class="form-control"
            v-model="displayCoin" 
        >
            <option value="ARS" selected>$</option>
            <option value="USD">U$D</option>
            <option value="CNY">¥</option>
            <option value="EUR">€</option>
        </select>
        <input type="number" placeholder="Introducir valor" v-model="displayValue" class="ml-2 form-control"/>
    </span>
</template>
<script>


export default {
  props:['value','currency','selective'],
  data () {
    return {
      isInputActive: false,
      currencyValue:''
    }
  },
  watch: {
      //'value': {handler: function (a) {this.amount = this.value;;}},
      'currency': {handler: function (a) {this.currencyvalue = this.currency;}},
  },
  mounted(){
      this.amount = this.value;
      this.currencyvalue = this.currency;
  },
  computed: {
        displayValue: {
            get: function() {
                
                    let res ='';
                    if(!this.selective){res = res + '$ ';}
                    res = res + this.value//.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
                    return res;                    
                
            },
            set: function(modifiedValue) {
                let newValue = modifiedValue//parseFloat(modifiedValue.replace(/[^\d\.]/g, ""))
                if (isNaN(newValue)) {
                    newValue = 0
                   
                }
                else
                this.$emit('input', newValue)
            }
        },
        displayCoin: {
            get: function() {return this.currency;},
            set: function(value) {
                this.currencyvalue = value;
                this.$emit('change', value);
            }
        },
    },
}
</script>

