<template>
  <b-container fluid>
    <div class="card mb-4"> 
      
      <b-card-header>
        <h6 v-if="salePointId == null">Nuevo punto de venta manual</h6>
        <h6 v-else>Editar punto de venta manual</h6>
        <b-button v-if="salePointId != null" @click="resetForm()">Nuevo PDV</b-button>
      </b-card-header>

      <b-card-body>
        <form>
          
          <b-form-group>
            <label class="control-label">Número de punto de venta</label>
            <input type="number" class="form-control" v-model="salePoint.number">
          </b-form-group>

          <b-form-group>
            <label class="control-label">Sucursal</label>
            <b-form-select class="form-control"
                  v-model="salePoint.bo_id">
              <option v-for="branchoffice in branchoffices" :key="branchoffice.id" :value="branchoffice.id">{{ branchoffice.name }}</option>
            </b-form-select>
          </b-form-group>

          <b-form-group>
            <label class="control-label">Tipo</label>
            <select class="form-control" v-model="salePoint.type">
              <option value="PRUEBA">Prueba</option>
              <option value="FISCAL">Fiscal</option>
            </select>
          </b-form-group>

          <b-form-group>
            <label class="control-label">Tipo de Numeración</label>
            <select v-model="salePoint.editable" class="form-control">
              <option value="1">Editable</option>
              <option value="0">Automática</option>
            </select>
          </b-form-group>

          <b-form-group>
            <label class="control-label">Estado</label>
            <select v-model="salePoint.actived" class="form-control">
              <option value="1">Habilitado</option>
              <option value="0">Deshabilitado</option>
            </select>
          </b-form-group>
            
        </form>
      </b-card-body>
      <div class="card-footer">
        <b-button v-if="salePointId" variant="orange" size="lg" @click="save()">Modificar</b-button>
        <b-button v-else variant="orange" size="lg" @click="save()">Guardar</b-button>
      </div>
    </div>



	</b-container>
</template>

<script>
export default {
  components: {
  },
  props: ['salePointId','branchoffices'],
  data: function() {
    return {
      isLoading: false,
      salePoint: {
        id:null,
        bo_id:null,
        number: null,
        type: null,
        editable: null,
        actived: null,
      },
      emptySalePoint: {
        id:null,
        bo_id:null,
        number: null,
        type: null,
        editable: null,
        actived: null,
      },

    };
  },
  watch: {
    salePointId: {
      handler: function (salePointId) {
        if(salePointId != null)
          this.getSalePoint();
        else
          this.resetForm();
      }
    }
  },  
  methods: {
    getSalePoint() {
      var vm = this;
      vm.isLoading = true
      this.$http.get("/api/configs/pos/show/" + this.salePointId).then((response) => {
        vm.salePoint = response.data.data;
        vm.isLoading = false
      });
    },
    resetForm(){
      this.salePoint =  Object.assign({}, this.emptySalePoint);
      this.$emit('saved')
    },
    save() {
      if (this.salePoint.id != null) {
        this.isLoading = true
        this.$http
          .post("/api/configs/pos/update/" + this.salePoint.id, this.salePoint)
          .then((response) => {
            
            this.salePoint = this.emptySalePoint;
            this.$emit('saved');
          });
      } else {
        this.isLoading = true
        this.$http.post("/api/configs/pos/create", this.salePoint).then((response) => {
          
          this.salePoint = this.emptySalePoint;
          this.$emit('saved');
        });
      }
    },
  },
};
</script>