<template>
  <b-container fluid class="mt-2">
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Datos de mi empresa</h4>
      </b-col>
    </div>
    <b-card
      v-for="branchoffice in newbranchoffices"
      :key="branchoffice.id"
      >
      <branch-office-header :branchoffice="branchoffice" :new="false"></branch-office-header>
      <branch-office-body
        :branchoffice="branchoffice"
        :first_child="first_child"
        :last_child="last_child"
        :new="false"
        :priceslists="priceslists"
        :funds="funds"
      ></branch-office-body>
    </b-card>
    <b-card>
      <branch-office-header :branchoffice="newbranchoffice" :new="true"></branch-office-header>
      <branch-office-body :branchoffice="newbranchoffice" :new="true"></branch-office-body>
    </b-card>
  </b-container>
</template> 

<script>
import branchOfficeBody from "./Branch-office-body";
import branchOfficeHeader from "./Branch-office-header";

export default {
  props: ["branchoffices", "priceslists", "funds"],
  data: function(caracteristicas) {
    return {
      endpointurl: "/api/configs/",
      newbranchoffices: [],
      newbranchoffice: {},
      profile_image: null,
      selected: null,
      last_child: null,
      first_child: null
    };
  },
  computed: {},
  mounted: function() {
    this.newbranchoffices = this.branchoffices;
    this.selected = this.branchoffices[0];
    this.first_child = this.selected.id;
    this.last_child = this.newbranchoffices[
      this.newbranchoffices.length - 1
    ].id;
    this.newbranchoffices.forEach(function(bo) {
      bo.options.elecbilling = bo.elecbilling;
    });
  },
  methods: {},
  components: {
    branchOfficeBody,
    branchOfficeHeader
  }
};
</script>
