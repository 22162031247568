<template>
<b-container fluid>
  <div class="row my-3">
    <b-col class="d-flex justify-content-start">
      <h4>Detalle de compra</h4>
    </b-col>
  </div>
  <!-- ------- DATOS DE FACTURA COMPRA -------- -->
  <b-card class="bg-light d-flex justify-content-between mb-4 p-0">
    <b-row class="mt-3">
      <b-col v-if="purchase.letter != null" cols="sm-3">
          <h2 class="control-label">Pto.Vta: {{purchase.pos}} Numero: {{purchase.number}}</h2>
      </b-col>
      <b-col cols="sm-3">
        <b-form-group>
          <h2 class="control-label">Proveedor: {{ purchase.provider_name }}</h2>
        </b-form-group>
      </b-col>
      <b-col cols="sm-3">
        <b-form-group>
            <h2 class="control-label">Fecha: {{createdDateFormatted}}</h2>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="purchase.response != null">
      <b-col cols="sm-12">
          <b-col cols="sm-6">
              <b-form-group>
                  <label class="control-label">CAE: {{purchase.response['CAE']}}</label>
              </b-form-group>
          </b-col>
          <b-col cols="sm-6">
              <b-form-group>
                  <label class="control-label">CAE Fech. Venc.: {{date('d/m/Y', strtotime(purchase.response['CAEFchVto']))}}</label>
              </b-form-group>
            </b-col>
      </b-col>
    </b-row>
  </b-card>
  <!-- --------- TABLAS ------ -->
  <b-row>
    <b-col cols="md-8">
      <b-table-simple sticky-header small caption-top responsive>
        <b-thead head-variant="light">
          <b-tr>
            <b-th> Item </b-th>
            <b-th class="col-small"> Cantidad</b-th>
            <b-th class="col-small"> Precio unitario</b-th>
            <b-th class="col-small"> Desc<span class="th-mobile">uento</span></b-th>
            <b-th class="col-small"> Total</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          
          <b-tr
            v-for="value in purchase.purchase_details"
            :key="value.purchase_id"
            role="row"
            class="add"
          >
            <b-td>{{value.description}}</b-td>
            <b-td>{{value.quantity}}</b-td>
            <b-td>{{formatoDinamicoMoneda(value.price)}}</b-td>
            <b-td>{{formatoDinamicoMoneda(value.price * value.quantity)}}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-col>
    <!-- TOTALES -->
    <b-col cols="md-4">
      <div class="card mb-4">
        <div class="card-header">
          <h3>Totales de la compra</h3>
        </div>
        <div class="card-body">
          <b-row>
            <!-- ------ Sub total ------ -->
            <b-col cols="md-6 text-left">
              SUBTOTAL:
            </b-col>
            <b-col cols="md-6 text-left">
              <span>{{formatoDinamicoMoneda(purchase.subtotal)}}</span>
            </b-col>
          </b-row>
          <b-row v-if="purchase.discount > 0">
            <b-col cols="md-6 text-left">
              DESCUENTO:
            </b-col>
            <b-col cols="md-6 text-left">
              <span>{{formatoDinamicoMoneda(purchase.discount)}}</span>
            </b-col>
          </b-row>
            <!-- ----------- IVA ---------- -->
          <div v-if="purchase.letter === 'A' || purchase.letter=== 'M'">
            <b-row>
              <b-col cols="md-6 text-left">
                NETO GRAVADO:
              </b-col>
              <b-col cols="md-6 text-left">
                <span>{{formatoDinamicoMoneda(purchase.nettaxed)}}</span>
              </b-col>
            </b-row>
            <b-row v-for="(tax, key) in purchase.taxes" :key="key">
              <b-col cols="md-6 text-left">
                IVA {{key}}%:
              </b-col>
              <b-col cols="md-6 text-left">
                <span>{{formatoDinamicoMoneda(tax)}}</span>
              </b-col>
            </b-row>
          </div>
          <br>
          <!-- ---- TOTAL -------- -->
          <b-row style="font-weight: bold;">
            <b-col cols="md-6 text-left mt-3">
              TOTAL:
            </b-col>
            <b-col cols="md-6 text-left mt-3">
              <span id="htmlTotal">{{formatoDinamicoMoneda(purchase.total)}}</span>
            </b-col>
          </b-row>
          <!-- ---- Efectivo -------- -->
          <div v-if="purchase.fundMovement > 0">
            <b-row>
              <b-col cols="md-6 text-left">
                PAGO EN EFECTIVO:
              </b-col>
              <b-col cols="md-6 text-left">
                <span id="htmlTotal">{{formatoDinamicoMoneda(purchase.fundMovement.amount)}}</span>
              </b-col>
            </b-row>
          </div>
          <!-- ---- Cheques -------- -->
            <div v-if="purchase.tpchecks > 0">
              <div v-for="tpcheck in purchase.tpchecks"
              :key="tpcheck.purchase_id"
              role="row"
              >
              <b-row>
                <b-col cols="md-6 text-left">
                  PAGO CON CHEQUE: {{tpcheck.number}}
                </b-col>
                <b-col cols="md-6 text-left">
                  <span id="htmlTotal">{{formatoDinamicoMoneda(tpcheck.amount)}}</span>
                </b-col>
              </b-row>
            </div>
          </div>
          <div v-if="totalca > 0">
            <b-row>
              <b-col cols="md-6 text-left">
                PAGO EN CTA. CTE.:
              </b-col>
              <b-col ccols="md-6 text-left">
                <span id="htmlTotal">{{formatoDinamicoMoneda(totalca)}}</span>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <b-form-group v-if="purchase.notes !=''" style="margin-top:20px">
        <label>Nota del proveedor</label>
        <b-textarea v-model="purchase.notes" disabled="disabled" name="note" rows="3"></b-textarea>
      </b-form-group>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";

export default {
  props: ["purchaseId"],
  mixins: [formatMoneyMixin],
  data: function() {
    return {
      purchase: {
        letter: null,
        pos: null,
        number: null,
        provider_id: null,
        subtotal: null,
        discount: null,
        taxes: null,
        total: null,
        purchase_details: [{
          purchase_id: null,
          description: null,
          quantity: null,
          price: null
        }],
        provider: {
          name: null
        }
      }
    }
  },
  computed: {
    createdDateFormatted() {
      return new Date(this.purchase.created_at).toLocaleDateString('es-AR')
    },
    totalCtaCte()
    {
      return this.purchase.accountmovements != null ? this.purchase.accountmovements.amount: 0;
    }
  },
  mounted() {
    this.getPurchaseDetail(this.purchaseId);
  },
  methods: {
    getPurchaseDetail(purchaseId){        
        var vm= this;
        axios.get('/api/purchases/detail/' + purchaseId)
        .then(response => {
            if(response.data.success) {
                vm.purchase = response.data.data;                
                console.log(vm.purchase)
                }
            }
        )
        .catch(error => {
            alert(error);
            }
        )
    },
  }
};
</script>
