import InventarioPlaceholder from '../components/inventario/Inventario-placeholder.vue';

import ProductsList from '../components/inventario/productos/Products-list.vue';
import NuevoProducto from '../components/inventario/productos/Edit-modal.vue';
import CategoriasInventario from '../components/inventario/categorias-de-inventario/Categorias-de-inventario.vue';
import MarcasProductos from '../components/inventario/marcas-de-productos/Marcas-de-productos.vue';
import ExistenciaStock from '../components/inventario/existencias-en-stock/existencia-en-stock.vue';
import ConsultaPrecio from '../components/inventario/consulta-de-precio/consulta-de-precio.vue';
import MovimientoStock from '../components/inventario/movimientos-de-stock/Movimiento-de-stock.vue';
import ActualizarPrecios from '../components/inventario/actualizar-precios/Actualizar-precios.vue';
import ListasPrecios from '../components/inventario/listas-de-precios/Lista-de-precios.vue';
import Importexcel from '../components/inventario/productos/Importexcel.vue';
import Depositos from '../components/inventario/depositos/Depositos.vue';
import SerialNumbers from '../components/inventario/productos/numeros-de-serie/SerialNumbers.vue';

const inventory = {
    path: '/inventario',
    meta: {
      requiresAuth: true
    },
    component: InventarioPlaceholder,
    children:
      // se crea un componente vacio para que funcione bien, siguiendo esta guia https://dev.to/berniwittmann/my-approach-on-vue-router-architecture-and-nested-routes-2kmo
      [
        { path: '', redirect: '/inventario/productos', props: true,},
        { path: 'productos', component: ProductsList, name: 'Inventario', props: true, },
        { path: 'import', component: Importexcel, name: 'Importexcel' },
        { path: 'productos/nuevo', component: NuevoProducto, name: 'Nuevo producto' },
        { path: 'productos/editar-producto/:productId', component: NuevoProducto, name: 'Editar producto', props: true, },
        { path: 'categorias-de-inventario', component: CategoriasInventario, name: 'Categorias de inventario' },
        { path: 'marcas-de-productos', component: MarcasProductos, name: 'Marcas de productos' },
        { path: 'existencias-en-stock', component: ExistenciaStock, name: 'Existencias en stock' },
        { path: 'consulta-de-precio', component: ConsultaPrecio, name: 'Consulta de Precio' },
        { path: 'movimientos-de-stock', component: MovimientoStock, name: 'Movimientos de stock', props: true, },
        { path: 'actualizacion-de-precios', component: ActualizarPrecios, name: 'Actualizacion de precios' },
        { path: 'listas-de-precios', component: ListasPrecios, name: 'Listas de precios' },
        { path: 'depositos', component: Depositos, name: 'Depositos' },
        { path: 'transfers', component: Depositos, name: 'Transferencia de stock' },
        { path: ':productId/numeros-de-serie', component: SerialNumbers, name: 'Numeros de serie', props: true, },
      ],
};

export default inventory;
