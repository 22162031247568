<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Usuarios del sistema</h4>
      </b-col>
    </div>
    <!--
    <b-row class="d-flex justify-content-between mt-2">
      <b-col cols="md-2 text-left">
        <input v-model="doSearch" type="search" class="form-control input-sm" placeholder="Buscar..."
            aria-controls="categories" />
      </b-col>
    </b-row> -->

    <b-row>
      <b-col cols="md-8">
        <vdtnet-table 
        :fields="fields" 
        :opts="opts" 
        :selectCheckbox="0"
        @edit="changeUserId"
        @delete="deleteUser"
        ref="table" />
      </b-col>
      <b-col cols="md-4">
        <NewUser :userId="userId" @confirmsave="refreshTable()" />
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
import VdtnetTable from "vue-datatables-net";
import NewUser from "../../sub-components/New-user.vue";

export default {
  components: {
    VdtnetTable,
    NewUser,
  },
  data: function () {
    return {
      loading: false,
      doSearch: true,
      userId: null,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/users/list",
          type: "GET",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
      },
      fields: {
        usuario: {
          label: "Detalles de usuario",
          searchable: true,
          align: "left",
          render: (data, type, row) => {
            const activo = '<small class="text-success">(Activo)</small>';
            const inactivo = '<small class="text-danger">(Inactivo)</small>';
            const estado = row.active == 1 ? activo : inactivo;
            return `
            <div class="pull-left user-info">
              <a data-id="${row.id}" class="edit-button" href="javascript:;">${row.name}</a>
              ${estado}
              <br/>
              <small>${row.email}</small>
            </div>
            `;
          },
        },
        sucursal: { label: "Sucursal", searchable: true, align: "left" },
        role: {
          label: "Funciones",
          searchable: true
        },
        actions: {
          isLocal: true,
          label: "Acciones",
          align: "center",
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const estadoIcon = row.active == 1 ? '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-slash text-danger" viewBox="0 0 16 16">'+
                                                  '<path d="M13.879 10.414a2.501 2.501 0 0 0-3.465 3.465l3.465-3.465Zm.707.707-3.465 3.465a2.501 2.501 0 0 0 3.465-3.465Zm-4.56-1.096a3.5 3.5 0 1 1 4.949 4.95 3.5 3.5 0 0 1-4.95-4.95ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z"/>'+
                                                  '</svg>' : '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check text-success" viewBox="0 0 16 16">'+
                                                  '<path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>'+
                                                  '<path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z"/>'+
                                                  '</svg>';
            const estadoTitle = row.active == 1 ? 'Desactivar' : 'Activar';
            const defaultContent = `
                    <div class="divActions">
                      <button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light "><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg></button>
                      <button data-action="delete" data-toggle="tooltip" title="${estadoTitle}" type="button" class="btn btn-light marginButton">${estadoIcon}</button>
                    </div>`;

            return defaultContent;
          },
        },
      },

    }
  },
  watch: {
    doSearch(newValue, oldValue) {
      if (newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch);
      }
      if (newValue === "" && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch);
      }
    },

  },
  methods: {
    refreshTable() {
      var vm = this;
      vm.userId = null;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    },
    changeUserId(data) {
      this.userId = data.id;
    },
    deleteUser(data) {
      const accion = data.active == 1 ? 'Desactivar' : 'Activar';
      if (confirm(`¿Está seguro que desea ${accion} este usuario?`) == true) {
        this.$http
          .get(`/api/users/toggleuser/${data.id}`)
          .then((response) => {
            if (!response.data.code == 200) {
                            
            }
            alert(`Usuario modificado correctamente`);              
            this.refreshTable();
          });
      }
    },
  }


}
</script>