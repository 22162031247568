<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Categorias de cajas</h4>
      </b-col>
    </div>
    <div class="modal-new" v-if="showNewfundCategoryModal">
      <div class="close-button" @click="showNewfundCategoryModal = false">
        <i class="fas fa-times"></i>
      </div>
      <div class="modal-card">
        <NuevaCategoriaCaja
          :fundCategoryId="fundCategoryIdParent"
          v-if="showNewfundCategory"
          @reset-id="fundCategoryIdParent = null"
          @confirmsave="refreshTable()"
        />
      </div>
    </div>
    <b-form inline class="d-flex justify-content-between my-2"
      @submit.stop.prevent="doSearch">
      <input
          v-model="doSearch"
          type="search"
          class="form-control input-sm"
          placeholder="Buscar..."
          aria-controls="categories"
      />

      </b-form>
      <b-row>
        <b-col cols="md-8">
          <vdtnet-table
            :fields="fields"
            :opts="opts"
            :selectCheckbox="0"
            @edit="changeFundCategoryId"
            @delete="deleteFundCategory"
            ref="table"
          />

        </b-col>
        <b-col cols="md-4">
          <NuevaCategoriaCaja
            :fundCategoryId="fundCategoryIdParent"
            v-if="showNewfundCategory"
            @reset-id="fundCategoryIdParent = null"
            @confirmsave="refreshTable()"
          />
        </b-col>
      </b-row>

  </b-container>
</template>

<script>
import NuevaCategoriaCaja from "./../sub-components/Nueva-categoria-caja.vue";
import VdtnetTable from "vue-datatables-net";

export default {
  components: {
    NuevaCategoriaCaja,
    VdtnetTable,
  },
  methods: {
    refreshTable() {
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
      console.log(vm.$refs.table);
    },
    changeFundCategoryId(data) {
      this.fundCategoryIdParent = data.id;
      this.showNewfundCategoryModal = true;
    },

    deleteFundCategory(data) {
      if (
        confirm("¿Está seguro que desea borrar esta categoría de caja?") == true
      ) {
        this.$http
          .post("/api/fundsCategories/delete", { id: data.id })
          .then((response) => {
            
            if (response.data.code == 200) {
              this.refreshTable();
            }
          });
      }
    },
  },
  watch: {
    doSearch(newValue, oldValue) {
      if (newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch);
      }
      if (newValue === "" && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch);
      }
    },
  },
  data: function () {
    return {
      doSearch: "",
      loading: false,
      fundCategoryIdParent: null,
      showNewfundCategory: true,
      showNewfundCategoryModal: false,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/fundsCategories/list",
          type: "GET",
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
      },
      fields: {
        name: { label: "Nombre", searchable: true, align: "left" },
        description: { label: "Descripción", searchable: true, align: "left" },
        type: { label: "Tipo", searchable: true },
        actions: {
          isLocal: true,
          label: "Acciones",
          align: "center",
          orderable: false,
          searchable: false,
          render: (data, type, row) => {
            const defaultContent = `
                    <div class="divActions">
                      <button data-action="edit" data-toggle="tooltip" title="Editar" type="button" class="btn btn-light"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                      </svg></button>
                      <button data-action="delete" data-toggle="tooltip" title="Borrar" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                      </svg></button>
                    </div>`;
            if (!row.action) {
              return "";
            }
            return defaultContent;
          },
        },
      },
    };
  },
};
</script>