<template>
  <div class="form-group">
      <date-range-picker
        :locale-data="localeData"
        :singleDatePicker="options.singleDatePicker"
        :timePicker="options.timePicker"
        :timePicker24Hour="options.timePicker24Hour"
        :showWeekNumbers="options.showWeekNumbers"
        :showDropdowns="options.showDropdowns"
        :autoApply="options.autoApply"
        :ranges="ranges"
        :opens="opendir"
        :filtered="filtered"
        :close-on-esc="options.closeOnEsc"
        :linkedCalendars="options.linkedCalendars"
        v-model="dateRange"
        @select="selectValues"
        @toggle="checkOpen"
      >
        <template style="min-width: 500px;">
            {{ startDate }} - {{ endDate }}
        </template>
      </date-range-picker>
  </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker/src/components/DateRangePicker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment';

export default {
  props: ['opendir', 'filtered'],
  components: {
    DateRangePicker
  },
  data () {
    return {
      dateRange:{
          startDate: null,
          endDate: null
      },
      options:{
          singleDatePicker:false,
          timePicker:false,
          timePicker24Hour:true,
          showWeekNumbers:false,
          showDropdowns:true,
          autoApply:true,
          linkedCalendars:false,
          closeOnEsc:true,
      },
      localeData: {
          direction: 'ltr',
          format: 'dd/mm/yyyy',
          separator: ' || ',
          applyLabel: 'Aceptar',
          cancelLabel: 'Cancelar',
          weekLabel: 'Sem',
          customRangeLabel: 'Rango personalizado',
          daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          firstDay: 0
      },
      ranges:{}
    }
  },
  mounted: function () {
      this.initializeDefaultDateRange();
      this.ranges = {
          'Hoy': [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
          'Ayer': [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()],
          'Esta semana': [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
          'Este mes': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
          'El mes pasado': [moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()],
          'Este año': [moment().startOf('year').toDate(), moment().endOf('year').toDate()],
          'Todos': [null, null],
      }
  },
  methods: {
    initializeDefaultDateRange() {
        if(this.filtered === true){
            const today = new Date();
            const startDate = new Date();
            startDate.setDate(today.getDate() - 30);

            this.dateRange.startDate = moment(startDate).toDate();
            this.dateRange.endDate = moment(today).toDate();
        }else {
            this.dateRange.startDate = null;
            this.dateRange.endDate = null;
        }
    },
      selectValues: function (val) {
          let data={
              'startDate':(moment(val.startDate).isValid()) ? moment(val.startDate).format("DD/MM/YYYY") : null,
              'endDate':(moment(val.endDate).isValid()) ? moment(val.endDate).format("DD/MM/YYYY") : null,
          };
          this.$emit('datepicked',data);
      },
      checkOpen: function () {},
  },
  computed: {
        startDate: {
            get: function () {
                if(this.dateRange.startDate!==null){
                    return moment(this.dateRange.startDate).format("DD/MM/YYYY");
                }
                return '';
            },
            set: function (value) {
                this.dateRange.startDate = value;
            }
        },
        endDate: {
            get: function () {
                if(this.dateRange.endDate!==null){
                    return moment(this.dateRange.endDate).format("DD/MM/YYYY");
                }
                return '';
            },
            set: function (value) {
                this.dateRange.endDate = value;
            }
        },
    }
}
</script>

<style lang="css">

</style>
