<template>
  <b-container fluid>
    <div class="loading" v-if="isLoading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Compras</h4>
      </b-col>
      <b-col cols="md-2 text-right">
        <router-link :to="nuevaCompraLink">
          <b-button variant="orange" size="lg">
            Nueva Compra
          </b-button>
        </router-link>
      </b-col>
    </div>

    <div v-if="purchases = null">
      <div class="row" style="margin-top:10vh;">
        
        <div class="col-md-5 col-md-offset-2 text-center">

          <h2>Pasá todas tus compras, es facil!</h2>
          <p style="padding:10px 35px 10px; margin:0; font-size:15px"><b>Antes de empezar, no te olvides de cargar tus <router-link :to="indexInventarioLink">productos</router-link> y <router-link :to="indexProveedoresLink">proveedores</router-link>, son necesarios para utilizar el sistema.</b></p>
            <p style="padding:00px 35px 20px; margin:0; font-size:15px">Pasar una factura de compra es muy fácil y no te va a llevar más de un minuto.
              Si realizas facturas electrónicas, bajate el manual
            <a href="/other/configuracion-inicial-para-hacer-facturas-electronicas-en-qupro.pdf">desde acá</a> y configuralo con tu contador para sincronizarlo con la AFIP.</p>
            <nueva-button>
              <template #actions>
                <router-link :to="nuevaCompraLink">Realizá tu primera compra!</router-link>
              </template>
            </nueva-button>

        </div>

        <div class="col-md-3 visible-md visible-lg">
          <img src="/img/invoice_icon.png">
        </div>

      </div>      
    </div>

    <div v-else >
      <!-- -------------------- BUSCADOR -------------------------- -->
      <b-row class="d-flex justify-content-between mt-3"
          @submit.stop.prevent="doSearch">
          <b-col cols="md-2 text-left">
            <div class="input-group">
              <input
                v-model="doSearch"
                type="search"
                placeholder="Buscar..."
                class="form-control input-sm"
              />
            </div>
          </b-col>
          <!-- --------------------------FILTROS --------------------  -->
          <b-col cols="md-2">
              <b-select class="form-control" 
                  v-model="selectedTypeFilter" 
                  name="filter" >
                  <option value="all" selected="selected">Todos los comprobantes</option>
                  <option value="invoices">Facturas</option>
                  <option value="credit-notes">Notas de crédito</option>
                  <option value="debit-notes">Notas de débito</option>
                  <option value="other">Otros comprobantes</option>
                  <option value="deleted">Comprobantes anulados</option>
                </b-select>
          </b-col>
          <!-- ------------------------- BOTON --------------------  -->
      </b-row>
      <!-- -------------------- TABLA -------------------------- -->
      <b-row>
        <b-col cols="md-12">
          <vdtnet-table
            :fields="fields"
            :opts="opts"
            :selectCheckbox="0"
            @purchaseDetail="getPurchaseDetail"
            @cancel="cancelOrDeletePurchase"
            ref="table"
          />
        </b-col>
      </b-row>
      

    </div>
  </b-container>
</template>

<script>

  import VdtnetTable from "vue-datatables-net";
  import NuevaButton from "../../sub-components/Nueva-button.vue";
  import formatDateMixin from "../../../mixins/formatDateMixin";
  import formatMoneyMixin from "../../../mixins/formatMoneyMixin";

  export default {
    components: {
      NuevaButton,
      VdtnetTable,
      
    },
    mixins: [formatDateMixin,formatMoneyMixin],
    data: function () {
      return {
        selectedTypeFilter: 'all',
        isLoading: false,
        doSearch: "",
        opts: {
          language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
          ajax: {
            url: process.env.VUE_APP_BASE_URL+"/api/purchases/list",
            type: "GET",
            data: (d) => {
              d.filter = this.selectedTypeFilter         
              //return d.data
            },
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          },
          processing: true,
          pageLength: 15,
          searching: true,
          searchDelay: 1500,
          destroy: true,
          ordering: true,
          lengthChange: true,
          serverSide: true,
          fixedHeader: true,
          order: [[ 0, "desc" ]], 
        },
        fields: {
          id: { visible: false },
          type: { label: "Tipo" },
          number: { label: "N°" },
          created_at: {
            label: "Fecha",
            searchable: true,
            render: (data) => this.formatoFechaHora(data),
          },
          provider_name: { label: "Proveedor" },
          user: { label: "Usuario" },
          status: { label: "Estado" },
          total: {
            label: "Total",
            render: this.formatoDinamicoMoneda,
          },
          actions: {
            isLocal: true,
            label: "Acciones",
            width: "85px",
            align: "center",
            defaultContent:
              '<div class="divActions btn-group-sm">' +
              '<button data-action="purchaseDetail" data-toggle="tooltip" title="Ver detalle" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-ruled" viewBox="0 0 16 16">'+
              '<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z"/>'+
              '</svg></button>' +
              '<button data-action="cancel" data-toggle="tooltip" title="Anular/Eliminar comprobante" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
              '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
              '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
              '</svg></button>' +
              "</div>",
          },
        },
        purchase: {
          id: null,
          number: "",
        },
        emptyPurchase: {
          id: null,
          number: "",
        },
      };
    },
    watch: {
      doSearch(newValue, oldValue) {
        if (newValue.length >= 4 || oldValue.length >= 4) {
          this.$refs.table.search(this.doSearch);
        }
        if (newValue === "" && oldValue.length > 0) {
          this.$refs.table.search(this.doSearch);
        }
      },
      selectedTypeFilter() {
        this.$refs.table.reload();
      },
    },
    methods: {
      refreshTable() {
        this.$refs.table.reload();
      },
      getPurchaseDetail(data) {
        this.$router.push({
          name: "Detalle de compra",
          params: { purchaseId: data.id },
        });
      },
      cancelOrDeletePurchase(data) {
        if (data.status === "CANCELED") {
          if (
            confirm("¿Está seguro que desea borrar este comprobante?") == true
          ) {
            this.$http
              .post("/api/purchases/delete", { id: data.id })
              .then((response) => {
                
                if (response.data.code == 201) {
                  this.refreshTable();
                }
              });
          }
        } else {
          if (
            confirm("¿Está seguro que desea cancelar este comprobante?") == true
          ) {
            this.$http
              .post("/api/purchases/cancel", { id: data.id })
              .then((response) => {
                
                if (response.data.code == 201) {
                  this.refreshTable();
                }
              });
          }
        }
      },
      downloadPDF(data) { // TODO: revisar que carajo es esto
        let id = data.id;
        let vm = this;
        vm.loading = true;
        this.$http.get("/api/purchases/checkPDF/" + id).then((response) => {
          if (response.status == 200) {
            this.$http.get("/api/purchases/invPDF/" + id)
              .then((response) => {
                if (response.status == 200) {
                  vm.loading = false;
                  window.open("/api/purchases/invPDF/" + id, "_blank") ||
                    window.location.replace("/api/purchases/invPDF/" + id);
                } else {
                  vm.loading = false;
                  
                }
              })
              .catch((error) => {
                vm.loading = false;
                console.log("error: " + error);
              });
          } else {
            vm.loading = false;
            
          }
        });
      },
      createCreditNote() {
        alert("XD");
      },
    },
    computed: {
      nuevaCompraLink() {
        return { name: "Nueva compra" };
      },
      indexInventarioLink() {
        return { name: "Inventario" };
      },
      indexProveedoresLink() {
        return { name: "Proveedores" };
      },
    },
  };
</script>
