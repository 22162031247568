<template>
  <div>
    <b-card-header>
      <h6>Caracteristicas y Opciones</h6>
    </b-card-header>
    <b-card-body class="d-flex align-items-start flex-column">
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.elecbilling" type="checkbox" class="md-check">
          Habilitar Facturacion Electronica
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.showbarcodes" type="checkbox" class="md-check">
            Habilitar búsqueda por código en punto de venta
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.allow_inventories" type="checkbox" class="md-check">
          Habilitar los movimientos de stock
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.allow_sale_without_stock" type="checkbox" class="md-check">
          Permitir realizar ventas sin stock
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.allow_price_edit" type="checkbox" class="md-check">
          Permitir cambio de precios en venta
        </label>
      </div>
      <div class="checkbox">
        <label>
        <input v-model="newbo_options.allow_purchases" type="checkbox" class="md-check">
          Habilitar modulo compras
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.print_bill" type="checkbox" class="md-check">
          Imprimir comprobantes por cada acción que se realice
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.print_bill_ticket" type="checkbox" class="md-check">
          Imprimir ticket en ventas
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.print_receipt_ticket" type="checkbox" class="md-check">
          Imprimir ticket en Recibos
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.print_ordernote_ticket" type="checkbox" class="md-check">
          Imprimir ticket en Ordenes de pedido
        </label>
      </div>
      <!-- <div class="checkbox">
        <label>
          <input v-model="newbo_options.send_email" type="checkbox" class="md-check">
          Enviar factura al email
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.send_email_atm" type="checkbox" :disabled="bo_options.send_email!==true" class="md-check">
          Enviar factura al email automaticamente
        </label>
      </div> -->
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.use_prodimage" type="checkbox" class="md-check">
          Habilitar uso de imágenes en los productos
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.salesclerk_module" type="checkbox" class="md-check">
          Habilitar modulo de vendedores
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.use_catalogs" type="checkbox" class="md-check">
          Habilitar catálogos de productos
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.alert_stock" type="checkbox" class="md-check">
          Mostrar alertas por falta de stock
        </label>
      </div>
    </b-card-body>
    <b-card-header>
      <h6>Habilitar documentos de venta</h6>
    </b-card-header>
    <b-card-body class="d-flex align-items-start flex-column">
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.use_budget" type="checkbox" class="md-check">
          Habilitar presupuestos
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.discount_each_line" type="checkbox" class="md-check">
          Habilitar descuentos individuales por producto
        </label>
      </div>
    </b-card-body>
  </div>
</template>

<script>
export default {
  props: ['bo_options'],
  data: function() {
    return {
      newbo_options: {}
    }
  },
  watch: {
    'newbo_options.send_email': {
      handler: function(products) {
        this.newbo_options.send_email_atm = (this.newbo_options.send_email==0) ? 0 : this.newbo_options.send_email_atm;
      }
    }
  },
  mounted: function () {
    this.newbo_options = this.bo_options;
  },
  methods: {
    
  }
}
</script>
