<template>
  <b-nav align="center" class="lasubnav d-flex align-items-center py-1">      
    <b-nav-item><router-link :to="listLink" class="text-white" active-class="active">Pedidos</router-link></b-nav-item>
    <b-nav-item><router-link :to="usersLink" class="text-white" active-class="active">Usuarios</router-link></b-nav-item>
    <b-nav-item><router-link :to="catalogosLink" class="text-white" active-class="active">Catálogos</router-link></b-nav-item>   
    <BranchofficeSelect v-if="getHasPermission" />
  </b-nav>
</template>

<script>
import BranchofficeSelect from "./../sub-components/branchoffice-select.vue";
export default {
  components: {
    BranchofficeSelect
  },
  data : function () { 
    return {
      hasPermission : false
    }
  },
  computed: {
    getHasPermission() {
        return this.hasPermission
    },
    listLink() {
      return { name: 'Pedidos Ecommerce' }
    },
    usersLink() {
      return { name: 'Usuarios Ecommerce' }
    },
    catalogosLink() {
      return { name: 'Catalogos' }
    },
  },
  methods: {
    getAllBranchofficesFundsPermission() {
      this.$http
        .get("/api/configs/role/permissions/names", {
           headers: { 
            Authorization: "Bearer " + localStorage.getItem("token")
          },
        })
        .then((response) => {
          this.hasPermission = response.data.data.includes('ACCESS_ALL_BRANCHOFFICES_FUNDS');
        })
    },
  },
  created() {
    this.getAllBranchofficesFundsPermission();
  },
}
</script>

<style scoped>
.lasubnav {
  animation: opacity 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  background: #2d538a;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.active {
  font-weight: bold;
  color: white; 
  background-color: orange;
  border-radius: 10px 10px 0px 0px;
  padding: 11px;
}
</style>