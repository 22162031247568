<template>
  <div>
    <b-row>
      <b-col cols="md-4">
        <b-form-group>
          <label class="control-label">Caja para compras y ventas</label>
          <b-form-select name="fund_id" v-model="bo_configs.fund_id" class="form-control" required>
            <option v-for="fund in funds" :key="fund.id" :value="fund.id">{{ fund.name }}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="md-4">
        <b-form-group>
          <label class="control-label">Punto de Venta por defecto</label>
          <b-form-select name="pos_id" v-model="bo_configs.pos_id" class="form-control" required>
            <option v-for="pos in bo_pos" :key="pos.id" :value="pos.id">{{ pos.number }}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="md-4">
        <b-form-group>
          <label class="control-label">Lista de venta por defecto</label>
          <b-form-select class="form-control" v-model="bo_configs.pricelist_id" name="pricelist">
            <option
              v-for="priceslist in priceslists"
              :key="priceslist.id"
              :value="priceslist.id"
            >{{ priceslist.name }}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="md-4">
        <b-form-group>
          <label class="control-label">Token de MP</label>
          <b-form-input name="mptoken" v-model="bo_configs.mptoken" class="form-control" required>
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="md-2">
        <b-form-group v-if="bo_configs.mptoken!=null">
          <b-button size="lg" @click="fetchqrs">Traer QRs</b-button>
        </b-form-group>
      </b-col>
      <b-col cols="md-6">
        <b-form-group v-if="qrs.length>0">
          <label class="control-label">Seleccion QR</label>
          <b-form-select name="mpqr" v-model="bo_configs.mpqr" class="form-control" required>
            <option v-for="qr in qrs" :key="qr.id" :value="qr.id">{{ qr.name }}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["bo_configs", "bo_pos", "funds", "bo_id"],
  data: function() {
    return {
      prueba: null,
      priceslists: [],
      qrs: []
    };
  },
  mounted: function() {
    return this.loadPriceLists();
  },
  methods: {
    fetchqrs(){
        let vm = this;
        this.$http.get("/api/configs/fetchqrs/"+vm.bo_configs.mptoken)
        .then((response) => {
            if (response.status==200) {
              vm.qrs = response.data.data;
            }
        }).catch((error) => {
            console.log('error: '+error);
        })
    },
    loadPriceLists(){
        let vm = this;
        this.$http.get("/api/priceslist/getPriceList")
        .then((response) => {
            if (response.status==200) {
                vm.priceslists = response.data.data;
            } else {
                
            }
        }).catch((error) => {
            console.log('error: '+error);
        })
    },
  }
};
</script>
