<template>
   <pos-documents :configurl="updateSale" />
</template>
<script>
import PosDocuments from "../pos-document/receipt/Receipt.vue";

export default {
   props: ['saleId'],
   components: {
      PosDocuments
   },
   computed: {
      updateSale() {
         localStorage.removeItem('header');
         localStorage.removeItem('products');
         return "/api/sales/draftSale/" + this.saleId
      }
   },
   methods: {

   },
};
</script>