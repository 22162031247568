import axios from 'axios'

export const getPriceLists = ({ commit }) => {
  axios.get('/api/priceslist/list')
    .then(response => {
      commit('getPriceLists', response.data.data)
    })
    // fail
    .catch(e => {
      commit('getPriceLists', e.response)
    })
}