<template>
  <b-card-header data-toggle="collapse" data-parent="#accordion"
      :href="isNew ? '#newbranchoffice' : '#branchoffice'+branchoffice.id"
  >
  <h6>
    {{ isNew ? 'Nueva Sucursal' : 'Sucursal ' + branchoffice.name }}
  </h6>
  </b-card-header>
</template>

<script>
export default {
  props: ['branchoffice', 'new'],
  data: function (f) {
    return {
      newbranchoffice: null,
      isNew: false
    }
  },
  computed: {

  },
  mounted() {
    this.isNew = this.new;
    this.newbranchoffice = this.branchoffice;
  }
}
</script>