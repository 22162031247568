import { render, staticRenderFns } from "./Nuevo-cliente.vue?vue&type=template&id=48e92608&scoped=true"
import script from "./Nuevo-cliente.vue?vue&type=script&lang=js"
export * from "./Nuevo-cliente.vue?vue&type=script&lang=js"
import style0 from "./Nuevo-cliente.vue?vue&type=style&index=0&id=48e92608&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e92608",
  null
  
)

export default component.exports