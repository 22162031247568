<template>
    <b-container fluid>
        <div class="loading" v-if="loading">Loading&#8230;</div>
        <b-row class="mt-3">
            <b-col class="d-flex justify-content-start">
                <h4>Inventario</h4>
            </b-col>
            <b-col class="text-right">
                <v-guard :permissions="['CREAR_PRODUCTOS']">
                <b-button class="pull-right" variant="success" @click="nuevoProductoLink()">Nuevo Producto <b-icon-plus></b-icon-plus></b-button>
                </v-guard>
            </b-col>
        </b-row>
        <!-- FILTROS -->
        <div class="card bg-light rounded-sm p-2 my-2 border">
            <h2>Filtros: </h2>
            <b-row class="d-flex justify-content-between">
                <b-col cols="md-2">
                    <b-form-select v-model="filters.provider_id"  class="mx-2">
                        <b-form-select-option :value="null">- Proveedor -</b-form-select-option>
                        <b-form-select-option :value="'Sin proveedor'">Sin Proveedor</b-form-select-option>
                        <b-form-select-option v-for="provider in providers" :key="provider.id" :value="provider.id">{{provider.name}}</b-form-select-option>
                    </b-form-select>
                </b-col>
                <b-col cols="md-2">
                    <b-form-select v-model="filters.brand_id" class="mx-2">
                        <b-form-select-option :value="null">- Marca -</b-form-select-option>
                        <b-form-select-option :value="'Sin marca'">Sin Marca</b-form-select-option>
                        <b-form-select-option v-for="brand in brands" :key="brand.id" :value="brand.id">{{brand.name}}</b-form-select-option>
                    </b-form-select>
                </b-col>
                <b-col cols="md-2">
                    <b-form-select v-model="filters.category_id" class="mx-2">
                        <b-form-select-option :value="null">- Categoría -</b-form-select-option>
                        <b-form-select-option v-for="category in categories" :key="category.id" :value="category.id" v-html="category.name"></b-form-select-option>
                    </b-form-select>
                </b-col>
                <b-col cols="md-2">
                    <b-form-select v-model="filters.active" class="mx-2">
                        <b-form-select-option :value="0" id="todos">Todos los productos</b-form-select-option>
                        <b-form-select-option :value="1" id="activos" selected="selected">Productos activos</b-form-select-option>
                        <b-form-select-option :value="2" id="inactivos">Productos Inactivos</b-form-select-option>
                    </b-form-select>
                </b-col>
                <b-col cols="md-2">
                    <b-form-select v-model="filters.qwarehouse" class="mx-2">
                        <b-form-select-option :value="null">Todos los depositos</b-form-select-option>
                        <b-form-select-option v-for="warehouses in warehouse" :key="warehouses.id" :value="warehouses.id" >{{ warehouses.name }}</b-form-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
            <hr>
            <h2>Buscadores: </h2>
            <b-row class="d-flex justify-content-between">
                <b-col cols="md-2">
                    <input v-model="filters.qcode" class="form-control mx-2" placeholder="Buscar codigo interno...">
                </b-col>
                <b-col cols="md-2">
                    <input v-model="filters.qbarcode" class="form-control mx-2" placeholder="Buscar codigo de barra...">
                </b-col>
                <b-col cols="md-2">
                    <input v-model="filters.qmanufacturercode" class="form-control mx-2" placeholder="Buscar codigo de fabrica...">
                </b-col>
                <b-col cols="md-2">
                    <input v-model="filters.qname" class="form-control mx-2" placeholder="Buscar nombre...">
                </b-col>
                <!-- BOTONES -->
                <b-col cols="md-1">
                    <b-form-group class="mx-2">
                        <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary mr-2" value="Exportar CSV">
                        <!--<input type="button" @click="exportPDF()" class="btn btn-md btn-light" value="Exportar PDF">-->
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <!-- TABLA -->
        <b-table-simple striped small caption-top responsive class="mt-2" style="min-height: 180px;">
            <b-thead head-variant="light">
                <b-tr>
                    <b-th cols="sm-2">Img</b-th>
                    <b-th>Cod. Interno</b-th>
                    <b-th cols="sm-3">
                        Nombre
                        <a href="#" v-on:click="sortList('name')" class="texto-negro">▼</a>
                    </b-th>
                    <b-th>Stock</b-th>
                    <!-- <b-th>Categoría</b-th> -->
                    <b-th>Precio</b-th>
                    <b-th>
                        Ultimo cambio
                        <a href="#" v-on:click="sortList('update')" class="texto-negro">▼</a>
                    </b-th>
                    <b-th>
                        Ultimo cambio de precio
                        <a href="#" v-on:click="sortList('priceUpdated')" class="texto-negro">▼</a>
                    </b-th>
                    <b-th >Estado</b-th>
                    <b-th >Acccion</b-th>
                </b-tr>
            </b-thead>

            <b-tbody>
                <b-tr v-for="prod in productList" :key="prod.id">
                    <b-td>
                        <img class="product-image rounded" style="height: 70px;" :src="prod.image ? createurl(prod.image) : '/ph-product.png'" />
                    </b-td>
                    <b-td>
                        <b-row>
                            <b-col class="d-flex flex-column">
                                <b-icon 
                                v-b-tooltip.hover.bottom :title="'Codigo de barra: ' + prod.barcode"
                                icon="layout-text-sidebar-reverse" rotate="90"/>
                                <b-icon class="mt-2" 
                                v-b-tooltip.hover.bottom :title="'Codigo de fabrica: ' + prod.manufacturer_code"  
                                icon="box-seam"/>
                            </b-col>
                            <b-col class="d-flex align-items-center text-left font-weight-bolder">
                                {{prod.code}} 
                            </b-col>
                        </b-row>
                    </b-td>
                    <b-td class="text-left">{{prod.name}}</b-td>
                    <b-td >{{prod.stock}}</b-td>
                    <!-- <b-td >{{prod.category}}</b-td> -->
                    <b-td><b>{{formatoDinamicoMoneda(prod.price)}}</b></b-td>
                    <b-td >{{prod.updated}}</b-td>
                    <b-td >{{prod.priceUpdated}}</b-td>
                    <b-td>
                        <b-icon-check-square-fill
                            v-if="prod.active == 1"
                            @click="toggleProduct(prod.id)"
                            style="width: 18px; 
                            height: 18px; 
                            color: rgb(40, 167, 69);
                            cursor: pointer;" 
                        ></b-icon-check-square-fill>
                        <b-icon-dash-circle-fill
                            v-else
                            @click="toggleProduct(prod.id)"
                            style="width: 18px; 
                            height: 19px; 
                            color: #dc3545;
                            cursor: pointer;"
                        ></b-icon-dash-circle-fill>
                    </b-td>
                    <b-td>
                        <b-button-group>
                            <b-dropdown right variant="light">
                                <v-guard :permissions="['EDITAR_PRODUCTOS']">
                                <b-dropdown-item><a @click="getProductDetail(prod.id)" style="cursor:pointer;">Editar Producto</a></b-dropdown-item>
                                <hr>
                                </v-guard>
                                <v-guard :permissions="['EDITAR_STOCK']">
                                <b-dropdown-item><a @click="editProductStock(prod.id)">Editar Stock</a></b-dropdown-item>
                                <b-dropdown-item><a @click="transferProductStock(prod.id)">Transferir Stock</a></b-dropdown-item>
                                </v-guard>
                                <b-dropdown-item><a @click="deleteProduct(prod.id)" style="cursor:pointer;">Eliminar</a></b-dropdown-item>
                                <b-dropdown-item>
                                    <a @click="loadPriceLists(prod.id)" style="cursor:pointer;">Generar etiqueta</a>
                                </b-dropdown-item>
                                <b-dropdown-item :href="'/inventario/' + prod.id + '/numeros-de-serie'">Numeros de serie</a></b-dropdown-item>                                        
                            </b-dropdown>
                        </b-button-group>
                    </b-td>
                </b-tr>
                <b-tr v-if="productList.length<=0"><b-td colspan="12" class="text-center">No se encontraron productos</b-td></b-tr>
            </b-tbody>
        </b-table-simple>
        <!-- PAGINACION -->
        <div class="row vdtnet-footer d-flex align-items-center">
            <div class="col-md-2 d-flex align-items-center">
                <label class="mr-2 mb-0">Mostrar: </label>
                <b-form-group class="mb-0">
                    <b-form-select v-model="filters.itemsPerPage" style="width: 60px;">
                        <option :value="15" selected="selected">15</option>
                        <option :value="25">25</option>
                        <option :value="50">50</option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col-md-2 text-center">
                <div class="vdtnetable1_info">
                    Mostrando {{ (filters.page - 1) * filters.itemsPerPage + 1 }} a {{
                        (Math.min(filters.page * filters.itemsPerPage) > productList.length)
                        ? totalItems
                        : Math.min(filters.page * filters.itemsPerPage)
                    }} de {{ totalItems }} items.
                </div>
            </div>
            <div class="col-md-8">
                <div class="dataTables_paginate paging_simple_numbers">
                <ul class="pagination">
                    <li class="page-item" v-bind:class="[{'disabled': filters.page==1}]">
                    <a class="page-link" href="#" v-on:click="prevPage()">Anterior</a>
                    </li>
                    <li v-if="(filters.page - (pageInterval/2))>0" class="page-item">
                    <a class="page-link" href="#" v-on:click="prevPage()">...</a>
                    </li>
                    <template v-for="index in totalPages">
                    <li :key="index" v-if="inIntervalPage(index)" class="page-item" v-bind:class="[{'active': filters.page==index}]">
                        <a class="page-link" href="#" v-on:click="changePage(index)">{{index}}</a>
                    </li>
                    </template>
                    <li v-if="totalPages>(filters.page + (pageInterval/2))" class="page-item">
                    <a class="page-link" href="#" v-on:click="nextPage()">...</a>
                    </li>
                    <li class="page-item" v-bind:class="[{'disabled': filters.page>=totalPages}]">
                    <a class="page-link" href="#" v-on:click="nextPage()">Siguiente</a>
                    </li>
                </ul>
                </div>
            </div>
        </div>
        
        <TicketModal v-if="showTicketModal"
        :prod_id="ticketId"
        @load="loading = true"
        @ticketdone="getTicket"
        @closemodal="closeTicketModal"/>

    </b-container>

</template>

<script>
import { mapState } from 'vuex'
import exportsMixin from "../../../mixins/exportsMixin"
import formatDateMixin from "../../../mixins/formatDateMixin";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";
import myaxios from 'axios';



export default {
    props: ['categoryId'],
    mixins: [exportsMixin,formatDateMixin,formatMoneyMixin],
    data:function(){
    return {
        bucket: 'productimages-qupro-prod',
        region: 'sa-east-1',
        timeoutId:null,
        loading:false,
        productList:[],
        priceLists:[],
        selectedPrice:null,
        prod_id:null,
        providers:[],
        totalPages:1,
        pageInterval:16,
        totalItems: 0,
        filters:{
            qname:'',
            qcode:'',
            qbarcode:'',
            qmanufacturercode:'',
            qwarehouse: null,
            page:1,
            itemsPerPage:15,
            active:1,
            provider_id:null,
            brand_id:null,
            category_id:null,
            sorting:{
                col:'',
                order:'desc'
            }
        },
        showTicketModal : false,
        ticketId : null
    }
  },
  created () {
      this.$store.dispatch('authUser/getAuthUser'),
      this.$store.dispatch('categories/getCategories'),
      this.$store.dispatch('warehouse/getWarehouses'),
      this.$store.dispatch('brands/getBrands')
      
    },
  mounted() {
    this.getData();
    if(this.categoryId != null) {
      this.filters.category_id = this.categoryId;
      this.getProducts(this.categoryId);
    }
  },
  methods: {
    createurl(img) {
        return 'https://'+this.bucket+'.s3.'+this.region+'.amazonaws.com/company_'+this.authUser.company_id+'/'+img.split(".").join("_thumb.");
    },
    getData(){
        this.getProviders();
        this.getProducts();
    },
    getProviders(){
        let vm= this;
        axios.get("/api/providers/getProviders")
            .then((response) => {
                if (response.status==200) {
                    vm.providers = response.data.data;
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
    }, 
    getProducts(){
        var vm= this;
        axios.post('/api/products/list',vm.filters)
          .then(response => {
            if(response.status === 200) {
                vm.productList = response.data.data.products;
                vm.totalPages = response.data.data.totalPages;
                vm.totalItems = response.data.data.totalItems;
            }else{
                vm.productList=[];
                vm.totalPages=0;
            }
        })
        .catch(error => {
            alert(error);
        })
    },
    getProductsWithDelay(){
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(()=>this.getProducts(), 1500);
    },
    getProductDetail(prodId) {
        let routeData = this.$router.resolve({
        name: "Editar producto",
        params: { productId: prodId },
      });
      window.open(routeData.href, '_blank');
    },
    editProductStock(prodId) {
      this.$router.push({
        name: "Movimientos de stock",
        params: { productIdParent: prodId, modal:"edit" },
      });

    // let routeData = this.$router.resolve({
    //     name: "Movimientos de stock",
    //     params: { productIdParent: prodId },
    //   });
    //   window.open(routeData.href, '_blank');
    },
    transferProductStock(prodId) {
      this.$router.push({
        name: "Movimientos de stock",
        params: { productIdParent: prodId, modal: "transfer" },
      });
    },
    sortList(col){
        if(this.filters.sorting.col == col){
            if(this.filters.sorting.order=='asc'){
                this.filters.sorting.order='desc';
            }else{
                this.filters.sorting.order='asc';
            }
        }else{
            this.filters.sorting.col=col;
            this.filters.sorting.order='asc';
        }
        this.getProducts();
    },
    prevPage(){
        if(this.filters.page>1){
            this.filters.page = this.filters.page-1;
            this.getProducts();
        }
    },
    nextPage(){
        if (this.filters.page<this.totalPages){
            this.filters.page = this.filters.page +1;
            this.getProducts();
        }
    },
    changePage(data){
        this.filters.page = data;
        this.getProducts();
    },
    inIntervalPage(index){
        if(this.totalPages<=this.pageInterval){return true;}

        let limitprev = this.filters.page - (this.pageInterval/2);
        let limitnext = this.filters.page + (this.pageInterval/2);

        if(limitprev<0){
            limitprev=0;
            limitnext=this.pageInterval;
        }

        if(index>limitprev && index<limitnext){return true;}

        return false;
    },
    toggleProduct(id){
        var vm=this;
        let outdata={'id' : id,};
        axios.post('/api/products/switchactive', outdata)
          .then(response => {
              if(response.data.success){
                  
                  vm.getProducts();
              }else{
                  
              }
          })
          .catch(error => {
            alert(error);
        })
    },
    refreshTable() {
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    },
    deleteProduct(id){
        var vm=this;
        if (confirm("¿Desea borrar este producto? Si continua se borraran tambien los movimientos de stock y sus precios asociados.") == true){
          let outdata={'id' : id,};
          axios.post('/api/products/delete', outdata)
          .then(response => {
              if(response.data.code == 201){
                  vm.refreshTable();
                  vm.getProducts();
              }else{
                  
              }
          })
          .catch(error => {
            alert(error);
          })
        }
    },
    generateTicket(id){
        this.loading=true;
        let vm= this;
        vm.prod_id = id;
        let outdata = {
            'prod_id' : this.prod_id,
            'price' : this.formatoDinamicoMoneda(this.selectedPrice.amount)
        };
        this.$emit('load');
        axios.post('/api/products/makeTicket', outdata)
        .then(response => {
            if(response.status==200){
                this.$emit('ticketdone');
                vm.getTicket(vm.prod_id);
            }else{
                
            }
        })
        .catch(error => {
            alert(error);
        })
        
    },
    loadPriceLists(id){
        let vm= this;
        vm.prod_id = id;
        axios.get("/api/priceslist/getByProductT/" + vm.prod_id)
            .then((response) => {
                if (response.status==200) {
                    vm.priceLists = response.data.data;
                    vm.selectedPrice = vm.priceLists[0];
                    vm.generateTicket(vm.prod_id);
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
        })
    },
    getTicket(id){
        let vm = this;
        vm.ticketId = id;
        let url = "/api/products/PDFprodticket/" + vm.ticketId;
        vm.loading=true;
        axios.get(url)
            .then((res) => {
                vm.loading=false;
                const uninterceptedAxiosInstance = myaxios.create();
                uninterceptedAxiosInstance.get(res.data, { responseType: 'blob' })
                .then((response) => {
                    const blob = new Blob([response.data], { type: 'application/pdf' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'Ticket '+Date.now()+'.pdf'
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
            })
            .catch((error) => {
             console.log('error: '+error);
        })
    },
    exportCSV(){
        this.$http.post(process.env.VUE_APP_BASE_URL+"/api/products/exportCSV", this.filters)
        .then((response) => {
        });
    },
    exportPDF() {
        let vm = this;
        let url = "/api/products/PDFinventory";
        axios.post(url, this.filters)
            .then((response) => {
                const uninterceptedAxiosInstance = myaxios.create();
                uninterceptedAxiosInstance.get(response.data.data, { responseType: 'blob' })
                .then((response) => {
                    const blob = new Blob([response.data], { type: 'application/pdf' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = "a.pdf"
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
            })
            .catch((error) => {
             console.log('error: '+error);
        })
    },
    getSortClass(col){
        if(this.filters.sorting.col==col){
            return "fas fa-sort active";
        }else{
            return "fas fa-sort inactive";
        }
    },
    nuevoProductoLink() {
      let routeData = this.$router.resolve({ name: "Nuevo producto" });
      window.open(routeData.href, '_blank');
    }
  },
  computed: {
    ...mapState('authUser',{
         authUser: state => state.authUser,
    }),
    ...mapState('categories',{
          categories: state => state.categories,
    }),
    ...mapState('warehouse',{
         warehouse: state => state.warehouse,
    }),
    ...mapState('brands',{
         brands: state => state.brands,
    }), 
  },
  watch: {
    warehouse_id: {handler: function (a) {
        this.filters.qwarehouse= a}
    },
    'filters.page': {handler: function (a) {this.getProducts();}},
    'filters.active': {handler: function (a) {this.getProducts();}},
    'filters.itemsPerPage': {handler: function (a) {this.getProducts();}},
    'filters.qname': {handler: function (a) {this.getProductsWithDelay();}},
    'filters.qcode': {handler: function (a) {this.getProductsWithDelay();}},
    'filters.qbarcode': {handler: function (a) {this.getProductsWithDelay();}},
    'filters.qmanufacturercode': {handler: function (a) {this.getProductsWithDelay();}},
    'filters.qwarehouse': {handler: function (a) { this.getProducts();}},
    'filters.category_id': {handler: function (a) {this.getProducts();}},
    'filters.brand_id': {handler: function (a) {this.getProducts();}},
    'filters.provider_id': {handler: function (a) {this.getProducts();}},
  },
}
</script>

<style scoped>
    .paginationc {
    display: flex;
    list-style: none;
    margin-top: 5px;
    }

    .paginationc a {
    text-decoration: none;
    padding: 6px;
    background-color: #fff;
    color: #007BFF;
    border: 1px solid #bbbbbb;
    transition: background-color 0.3s;
    }

    .paginationc a:hover {
        background-color: #f5f4f4;
    }

    .paginationc .current-page {
        background-color: #0056b3;  
    }

    .texto-negro {
        color: black;
    }

</style>
