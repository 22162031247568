<template>
    <b-container fluid>
      <div class="row mt-3">
        <b-col class="d-flex justify-content-start">
          <h4>Remitos de compra</h4>
        </b-col>
        <!-- -------------------- BOTON -------------------------- -->    
        <b-col cols="md-2 text-right">
          <router-link :to="nuevoRemitoDeCompraLink">
            <b-button variant="orange" size="lg">Nuevo Remito</b-button>
          </router-link>  
        </b-col>
      </div>
      <b-row class="d-flex justify-content-between"
        @submit.stop.prevent="doSearch">
        <b-col cols="md-2 text-left">
          <!-- -------------------- BUSCADOR -------------------------- -->
            <input
              v-model="doSearch"
              type="search"
              placeholder="Buscar..."
              class="form-control" />
        </b-col>
      </b-row>        
      <!-- ----------------------- TABLA -------------------------- -->
      <b-row>   
        <b-col cols="md-12" >
          <vdtnet-table
          
            :fields="fields"
            :opts="opts"
            :selectCheckbox="0"
            @shippingPurchaseDetail="getShippingPurchaseDetail"
            @delete="deleteShippingPurchase"         
            ref="table"            
          />    
        </b-col>    
      </b-row>
    </b-container>
</template>
<script>
import NuevaButton from '../../sub-components/Nueva-button.vue'
import VdtnetTable from 'vue-datatables-net';

export default {
    components: {
      VdtnetTable,
      NuevaButton
    },
    data: function() {
      return {
        doSearch: '',
        shippingPurchaseIdParent: null,
        opts: {
          language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        'ajax': {
          'url': process.env.VUE_APP_BASE_URL+"/api/shippingpurchases/list",
          'type': 'GET',
          'headers': { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
          }
        },
        fields: {
          number: { label: "N°"},
          created_at: { label: "Fecha", render: function(data) {
            return new Date(data).toLocaleDateString('es-AR')
          } },
          provider: { label: "Proveedor"},
          user: { label: "Comprador"},
          actions: {
                isLocal: true,
                label: 'Acciones',
                width: '85px',
                align: 'center',
                defaultContent: 
                '<div class="divActions btn-group-sm">' +
                  '<button data-action="shippingPurchaseDetail" data-toggle="tooltip" title="Ver detalle" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-ruled" viewBox="0 0 16 16">'+
                  '<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z"/>'+
                  '</svg></button>' +
                  '<button data-action="delete" data-toggle="tooltip" title="Eliminar comprobante" type="button" class="btn btn-light marginButton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">'+
                  '<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>'+
                  '<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>'+
                  '</svg></button>' +
                '</div>'
          },
        },
        shippingPurchases: []
      }        
    },
    computed: {
      nuevoRemitoDeCompraLink() {
        return { name: 'Nuevo remito de compra' }
      },      
    },
    watch: {
      doSearch(newValue, oldValue) {
        if(newValue.length >= 4 || oldValue.length >= 4) {
          this.$refs.table.search(this.doSearch)
        }
        if(newValue === '' && oldValue.length > 0) {
          this.$refs.table.search(this.doSearch)
        }
      },
    },
    methods: {
   
      getShippingPurchaseDetail(data) {
        this.$router.push({
          name: "Detalle de remito de compra", 
          params: { shippingPurchaseId: data.id 
        }})
      },  
      deleteShippingPurchase(data) {
        if (confirm("¿Está seguro que desea borrar este comprobante?") == true) {
          this.$http
            .post("/api/shippingpurchases/delete", { id: data.id })
            .then((response) => {
              
              if (response.data.code == 201) {
                this.refreshTable();
              }
            });
        }
      },
      refreshTable() {
        var vm = this;
        if (vm.$refs.table !== undefined) vm.$refs.table.reload();
        console.log(vm.$refs.table);
      },
    }
  }
</script>

<style scoped>
.display-button {
  display: grid;
}

@media (max-width: 991px) {
  .display-button {
    display: block;
  }
}
</style>