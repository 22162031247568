
const round = (value, precision) => {
  const shift = Math.pow(10, precision);
  return Math.round(value * shift) / shift;
};
export default {
  filters: {
    round,
  },
  methods: {
    round,
  },
}