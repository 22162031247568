<template>
  <b-row>
    <!--<div :class="{'d-none':!showNewProduct}">-->
    <b-col cols="12">
      <label for="input-live">Producto:&nbsp;</label>
      <label for="input-live">{{ product?.name }}</label>
      <b-form-group>
          <typeahead v-if="productId==null && prodId == null"
            class="mt-1" 
            :name="'product'"
            :default="productDefault"
            :placeh="'Buscar producto por nombre'"
            :custom-class="[]"
            :clean-on="false"
            v-on:choosed="productChoosed"
            :externalsrc="'/api/products/getProducts'"
            limit-chars="1"/>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group>
        <label class="control-label" for="input-live">Depósito destino:</label>
              <b-form-select class="form-control" v-model="movement.destination_select">
                <option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">{{ warehouse.name }}</option>
              </b-form-select>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group>
        <label class="control-label" for="input-live">Movimiento:</label>
          <b-form-select class="form-control" v-model="movement.type">
            <option value="in">ENTRADA</option>
            <option value="out">SALIDA</option>
          </b-form-select>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group>
        <label class="control-label" for="input-live">Cantidad:</label>
          <b-form-input class="form-control" v-model="movement.quantity" type="number"></b-form-input>
      </b-form-group>
    </b-col>
    <b-col cols="12" class="text-right mt-4">
      <b-button 
          variant="orange"
          size="lg"
          type="button"
          @click="saveStockMovement()"
          data-toggle="tab"
          >Guardar movimiento
      </b-button>
    </b-col>

  </b-row>
</template>

<script>
import typeahead from '../../global/Typeahead.vue';

export default {
  props: ["productId", "prodId"],
  components: {
      typeahead
    },
  data() {
    return {
      warehouses:[],
      product:null,
      movement: {
        product_id: null,
        quantity: null,
        type: null,
        destination_select: null,
      },
      emptyMovement: {
        product_id: null,
        quantity: null,
        type: null,
        destination_select: null,
      },
    };
  },  
  mounted() {
    this.getWarehouses();
    if(this.productId != null) {
      this.movement.product_id = this.productId;
      this.getProduct(this.productId);
    }
    if(this.prodId != null) {
      this.movement.product_id = this.prodId;
      this.getProduct(this.prodId);
    }
  },
  methods: {
    productChoosed(data){
      this.movement.product_id = data.id;
      console.log(data.id)
    },
    empty(){
      this.movement.product_id = null;
    },
    getWarehouses() {
      var vm = this;
      vm.isLoading = true
      this.$http.get("/api/warehouses/list").then((response) => {
        vm.warehouses = response.data.data;
        vm.isLoading = false    
      });
    },
    getProduct(id) {
      var vm = this;
      this.$http.get("/api/products/view/" + id).then((response) => {
        vm.product = response.data.data;
        console.log(vm.product.name);
      });
    },
    saveStockMovement() {
      var vm = this;
      this.$http.post("/api/movements/add", this.movement).then((response) => {
        
        vm.$emit("confirmsavemovement");
      });
    },
  },  
  computed: {
    formState() {
      return this.movement.product_id != null && this.movement.destination_select != null && this.movement.quantity>0 && this.movement.type != null
    },
    productDefault(){
      return this.product != null ? (this.product.barcode!= null? this.product.barcode: this.product.name): ''
    }
  },
  watch: {
    productId: function(value) {
      if (value != null) {
        this.getProduct(value);
      } else {
        this.product = this.emptyProduct;
      }
    },
  },
};
</script>
