<template>
  <div class="form-group">
    <label class="control-label">Transportes</label>
    <textarea v-model="transports_desc" class="form-control" disabled></textarea>
    <div class="row">
        <div class="col-md-9">
            
          <b-form-select class="form-control" v-model="transportId">
              <option :value="null">Ninguno</option>
              <option v-for="transport in transports" :key="transport.id" :value="transport.id">{{ transport.name }}</option>
          </b-form-select>
        </div>
        <div class="col-md-1">
            <input type="button" @click="addTransport()" id="addT" class="btn btn-sm btn-light glyphicon glyphicon-ok" value="+"/>
        </div>
        <div class="col-md-1">
            <input type="button" @click="removeTransport()" id="remT" class="btn btn-sm btn-light glyphicon glyphicon-minus" value="-"/>
        </div>
    </div>
  </div> 


</template>

<script>

  export default {
    mounted() {
      this.getTransports();
    },
    methods: {
      
      getTransports() {
        var vm = this;
        vm.isLoading = true
        this.$http.get("/api/transports/list").then((response) => {
          vm.transports = response.data.data;
          vm.isLoading = false;
          this.setInitialValues();
        });
      },
      setInitialValues() {     
        if(this.initialTransports == null)
          this.selectedTransports = [];
        else
          this.selectedTransports = this.initialTransports.map(t=>t.id);
      },
      addTransport(){
        if(this.selectedTransports.includes(this.transportId))
          return;
        this.selectedTransports.push(this.transportId);
      },
      removeTransport(){
        if(!this.selectedTransports.includes(this.transportId))
          return;
        this.selectedTransports = this.selectedTransports.filter(t => t != this.transportId)
      }
    },
    watch: {
      selectedTransports: function(value) {
        var names_list = this.selectedTransports.map((id) => {
          var t = this.transports.filter(t => t.id == id)[0];
          return t.name + (t.phone_1 != null ? ' (' +t.phone_1+')':'');
        });
        const initialValue = "";
        this.transports_desc = names_list.reduce(
          (accumulator, currentValue) => accumulator + " - " + currentValue, initialValue );
        this.$emit('updateTransports',this.selectedTransports);
      },
      initialTransports: function(value) {
        this.setInitialValues();
      },
    },
    props: [
      'initialTransports'
    ],
    
    data() {
      return {
        transports: [],
        selectedTransports: [],
        transportId:null,
        transports_desc:""
      }
    }
  }
</script>