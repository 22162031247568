<template>
<div class="container">
  <div class="card-header">
    <h4 v-if="check.id == null" class="card-title">
      Emision de nuevo cheque propio 
    </h4>
    <h4 v-else class="card-title">Editar cheque</h4>
  </div>
    <div class="card-body bg-light">
      <div class="row">
        <div class="col-12 col-md-3">
          <label class="control-label mr-1" for="input-live">Numero:</label>
          <b-form-input
            id="input-live"
            type="number"
            v-model="check.number"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="N° de cheque..."
            trim
          ></b-form-input>
        </div>
        <div class="col-12 col-md-3">
          <label class="control-label mr-1" for="input-live">Nombre del emisor:</label>
          <b-form-input
            id="input-live"
            class="form-control"
            v-model="check.name"
            :state="nameState"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Nombre del emisor..."
            trim
          />
        </div>
        <div class="col-12 col-md-3">
          <label class="control-label mr-1" for="input-live">Numero de CUIT:</label>
          <b-form-input
            id="input-live"
            class="form-control"
            v-model="check.cuit"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="CUIT..."
            trim
          />
        </div>
        <div class="col-12 col-md-3">
          <label class="control-label mr-1" for="input-live">
            Banco:
          </label>
          <!--
    <b-form-input
            id="input-live"
            class="form-control"
            v-model="check.bank_name"
            aria-describedby="input-live-help input-live-feedback"
            placeholder="Banco"
            trim
          />
          -->
        
          <sucursales-bancarias 
            id="input-live"
            class="form-control"
            v-model="check.bank_name"
            aria-describedby="input-live-help input-live-feedback"/>
        </div>
        <div class="col-12 col-md-3 mt-2">
          <div class="form-group">
            <label class="control-label mr-1" for="input-live">Monto:</label>
            <b-form-input
              id="input-live"
              class="form-control"
              type="number"
              v-model="check.amount"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="Monto..."
              trim
            ></b-form-input>
          </div>
        </div>
        <div class="col-12 col-md-3 mt-2">
          <div class="form-group">
            <label class="control-label mr-1" for="input-live"
              >Fecha de emisión:</label
            >
            <input
              class="form-control"
              type="date"
              name="date_issue"
              id="datepicker-issue"
              v-model="check.date_issue"
            />
          </div>
        </div>
        <div class="col-12 col-md-3 mt-2">
          <div class="form-group">
            <label class="control-label mr-1" for="input-live">Fecha cobro:</label>
            <input
            class="form-control"
            type="date"
            name="date_due"
            id="datepicker-expire"
            v-model="check.date_due"/>
          </div>
        </div>
        <div class="col-12 col-md-3 mt-2">
          <div class="form-group">
            <label class="control-label mr-1" for="input-live">Notas:</label>
            <b-form-textarea
              id="textarea"
              class="form-control"
              v-model="check.note"
              placeholder="Notas..."
              rows="2"
              cols="40"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </div>
      </div>
    </div> 
    <div class="card-footer text-right">
      <div class="form-actions pull-right">
        <a
          type="button"
          @click="saveCheck()"
          class="btn btn-md btn-orange btn-lg"
          href="#import_products"
          data-toggle="tab"
          >Guardar cheque </a>
      </div>
    </div> 
</div>           
</template>
<script>
import datepickerComponent from './../global/Datepicker-component.vue';
import formatDateMixin from "../../mixins/formatDateMixin";
import SucursalesBancarias from "./../sub-components/sucursalesBancarias.vue";

export default {
  components: {
    datepickerComponent,
    SucursalesBancarias,
  },
   mixins: [formatDateMixin],

    watch: {
        checkId: function(value) {
          if (value != null) {
            this.getCheck(value);
          } else {
            this.resetForm();
          }
            
        }
    },
    mounted() { 
      this.resetForm();
      if (this.checkId != null) {
        this.getCheck(this.checkId);
      } else {
        this.resetForm();
      }
    },
    methods: {
      validateForm() {
        if (this.check.number === null || isNaN(this.check.number)) {
          alert('Debe ingresar un numero de cheque');
          return false;
        }
        
        if(this.check.name === null || this.check.name === '') {
          alert('Debe ingresar un nombre ');
          return false;
        }
        
        if(this.check.cuit === null || isNaN(this.check.cuit)){
          alert('Debe ingresar un nro de cuit ');
          return false;
        }
        
        if(this.check.bank_name == null || this.check.name === ''){
          alert('Debe ingresar el nombre del banco');
          return false;
        }
        
        if(this.check.date_due == null || this.check.date_due === ''){
          alert('Debe ingresar la fecha de cobro');
          return false;
        }
        
        if(this.check.date_issue == null || this.check.date_issue === ''){
          alert('Debe ingresar la fecha de deposito ');
          return false;
        }

        if(this.check.amount == null || isNaN(this.check.amount)){
          alert('Ingrese el monto a depositar');
          return false;
        }
        return true;


      },
       getCheck(id) {
        var vm = this;
        this.modo = 'editar';
        this.$http.get('/api/funds/checks/get/' + id).then((response) => {
          vm.check = response.data.data;
          vm.check.date_issue = this.formatoFecha2Date(vm.check.date_issue);
          vm.check.date_due = this.formatoFecha2Date(vm.check.date_due);
          // Las siguientes 2 lineas pasan las fechas de dd/mm/yyyy a yyyy-mm-dd

        })
      },

      saveCheck() {
        var vm = this;
         if (!this.validateForm()){
          return;
         }
        if (this.check.id != null) {
          const data = {
            ...(vm.check),
            date_issue: vm.formatoDate2Fecha(vm.check.date_issue),
            date_due: vm.formatoDate2Fecha(vm.check.date_due),
          };
          this.$http
          .post('/api/funds/checks/edit/' + this.check.id, data)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit('confirmsave');
          })
        } else {
          this.$http
          .post('/api/funds/checks/add', this.check)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit('confirmsave');
          })
        }
        
        
      },
      
      resetForm() {
        this.check = Object.assign({}, this.emptycheck);
        this.$emit('reset-id');
      },
      dateIssueUpdated(date){
        const app = this;
        app.check.date_issue = date;
      },
      dateDueUpdated(date){
        const app = this;
        app.check.date_due = date;
      },


    },
    props: [
      'checkId',
      'showNewBtn'
    ],
    
    computed: {
       nameState() {
      return this.check.name.length < 3 && this.check.name.length > 0
        ? false
        : null;
    },
  },
    
    data() {
      return {
        modo: 'nuevo',
        check: {
          number: null,
          name: '',
          cuit: null,
          bank_name: '',
          date_issue: '',
          date_due: '',
          amount: null,
          note: ''
        },
        emptycheck: {
          number: null,
          name: '',
          cuit: null,
          bank_name: '',
          date_issue: '',
          date_due: '',
          amount: null,
          note: ''
        },
      selected: null,
        
        
      }
    }
}

</script>
