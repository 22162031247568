<template>
  <b-container fluid>
    <div class="row pt-3">
      <h2>
          <label for="">Detalle de imputacion ordenes de pago</label>
      </h2>
    </div>
    <b-card class="bg-light d-flex justify-content-between mb-4 p-0">
      <div class="row">
        <div class="col-sm-4">
          <h2 class="control-label">Imputación #{{allocation.id}}</h2>
        </div>
        <div class="col-sm-4">
          <h2 class="control-label">Proveedor: {{ provider }}</h2>
        </div>
        <div class="col-sm-4">
          <h2 class="control-label">Fecha: {{formatoDate2Fecha(allocation.created_at)}}</h2>
        </div>
      </div>
    </b-card>

    <div class="row">
      <!-- TABLA -->
        <div class="col-md-6">
          <b-table :items="purchases" :fields="purchasesFields" :busy="isBusy">
            <template #cell(purchase.id)="data">
              <a :href="`/compras/index/detalle/${data.value}`" class="btn btn-sm btn-light">Ver detalle</a>
            </template>
          </b-table>
          <br>
        </div>
        <div class="col-md-6">
          <b-table :items="payments" :fields="paymentsFields" :busy="isBusy">
            <template #cell(payorder_id)="data">
              <router-link :to="`/compras/detalle-orden-de-pago/${data.value}`" class="btn btn-sm btn-light">Ver detalle</router-link>
            </template>
          </b-table>
        </div>
    </div>
    <!-- BOTONES -->
    <b-row class="receipt-footer d-flex justify-content-end mb-3">
      <DownloadDoc :pdfurl="pdfurl" :open="false" :filename="'a.pdf'">
        <b-button variant="outline-primary" size="lg" class="mr-4">Descargar</b-button>
      </DownloadDoc>
      <DownloadDoc :pdfurl="pdfurl" :open="true" :filename="'a.pdf'">
        <b-button variant="orange" size="lg" >Imprimir</b-button>
      </DownloadDoc>
    </b-row>
  </b-container>
</template>
<script>
import DownloadDoc from "../../global/DownloadDoc";
import formatDateMixin from "../../../mixins/formatDateMixin";

export default {
  components: {
    DownloadDoc
  },
  props: ['allocationId'],
  mixins: [formatDateMixin],

  data() {
    return {
      showModal: false,
      showModalRet: false,
      pdfurl: process.env.VUE_APP_BASE_URL+'/api/collections/locPDF/' + 5,
      allocation: null,
      allocationDetails:[],
      purchases:[],
      payments:[],
      provider: null,
      purchasesFields: [
          {
            key: 'type',
            label: 'Tipo',
            sortable: true
          },
          {
            key: 'number',
            label: 'Numero',
            sortable: false
          },
          {
            key: 'amount',
            label: 'Importe',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          },
          {
            key: 'pivot.partial',
            label: 'En Recibo',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          }
          ,
          {
            key: 'purchase.id',
            label: 'Ver Detalle',
            sortable: true,
            // Variant applies to the whole column, including the header and footer
            variant: 'danger'
          }
      ],
      paymentsFields: [
          {
            key: 'created_at',
            label: 'Fecha',
            sortable: true
          },
          {
            key: 'type',
            label: 'Tipo',
            sortable: true
          },
          {
            key: 'number',
            label: 'Numero',
            sortable: false
          },
          {
            key: 'amount',
            label: 'Importe',
            sortable: true,
            variant: 'danger'
          },
          {
            key: 'pivot.partial',
            label: 'Imputado',
            sortable: true,
            variant: 'danger'
          }
          ,
          {
            key: 'payorder_id',
            label: 'Ver Detalle',
            sortable: true,
            variant: 'danger'
          }
      ],
    }
  },
  computed:{
    isBusy() { return this.allocation == '' }
  },
  methods: {
    getDetailTotal(type) { 
      return this.receiptDetails.filter(d => d.type == type)
        .reduce((accumulator, object) => { return accumulator + object.amount; }, 0);
    },
    getAllocationDetail() {
      var vm = this;
      axios.get('/api/payments/getAllocationDetail/' + this.allocationId)
      .then(response => {
        if(response.data.success) {

          vm.allocation = response.data.data.allocation;
          vm.purchases = response.data.data.purchases;
          vm.purchases.forEach(element => {
              element.type = element.purchase.type + '-' + element.purchase.letter;
              element.number = element.purchase.pos + '-' + element.purchase.number;
          });

          vm.payments =  response.data.data.payorders;
          vm.payments.forEach(element => {
              element.type = element.payorder_id != null? 'OP': 'RT';
              element.number = element.payorder_id != null? element.payorder.number: element.withholding.number;
          });
        }
        axios.get('/api/providers/getProvider/'+ vm.allocation.provider_id)
        .then(res => {
          vm.provider = res.data.data.name;
        })
      })
      .catch(error => {
        alert(error);
      })
    },
  },
  mounted() {
    this.getAllocationDetail();
  }
}
</script>
<style>

.btn-orange {
    background: #f5c05d;
    background: -ms-linear-gradient(top, #f5c05d 0%, #F2AB27 100%);
    background: linear, to bottom, #f5c05d 0%, #F2AB27 100%;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='#ffffff',GradientType=0 );
    color: #fff;
    text-shadow: 0px 0px 2px #e4931b;
    border: 1px solid #F2AB27;
    border-bottom: 1px solid #e4931b;
}
</style>

<style lang="css">
    .fix-footer {
      padding-bottom: 100px;
    }
    .receipt-footer {
      position: fixed;
      bottom:0;
      left:0;
      z-index:100;
      background:rgb(242, 244, 247,0.5);
      border-top: 1px solid #4477BD;
      text-align: right;
      padding:20px;
      width: 100%;
    }
    .totals {
      margin-bottom: 0;
    }
    
    .totals li {
      border-bottom:1px solid #d3d9e4;
      padding:10px 0;
      font-size: 1.75rem;
    }
    
    .totals li:first-child {
      padding-top:0;
    }
    
    .totals li:last-child {
      border-bottom:0;
      padding-bottom:0;
    }
    
    .totals li .form-group .input-group .input-group-addon {
      padding:0 12px;
      background:#fff;
      border-radius: 0px;
      border-left:0;
      border-top:0;
    }
    
    .totals li .form-group .input-group input {
      border-radius:0px;
      border-right: 0;
      border-top:0;
      background: #fff;
      height: 20px;
    }
    
    .affix {
      margin-right:15px;
    }
    
    
    </style>
