<template>
  <div v-if="showErrors" class="alertaerror">
    <span v-for="(errorMessages, fieldName) in showErrors" :key="fieldName" class="m-0">
      <span v-for="message in errorMessages" :key="message">{{ message }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </span> 
    <button type="button" @click="clearb">×</button>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ErrorApi',
  computed: {
    ...mapGetters({
      showErrors: 'errors/showErrors'
    }),
  },
  methods: {
    ...mapActions({
      clear: 'errors/clearErrors'
    }),
    clearb () {
      this.clear();
    },
  }
}
</script>
<style scoped>
  .alertaerror {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding-right: 4rem;
    width: 30%;
    position: fixed;
    top: 210px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .alertaerror span {
    color: #491217;
    font-weight: 700;
    transition: all 200ms ease-in;
    margin: 0;
    padding: 5px 0;
  }
  .alertaerror button {
    position: absolute;
    top: 6PX;
    right: 6PX;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit;
    padding: 0;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
</style>