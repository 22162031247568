import myaxios from 'axios';


export default {
  data: function() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    getCSV(url, filters, filename) {
        let vm = this;
        vm.loading = true;
        axios
          .post(url, filters)
          .then((response) => {
            if (response.data) {
              vm.loading = false;
              var blob = new Blob([response.data], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = filename;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              vm.loading = false;
              
            }
          })
          .catch((error) => {
            vm.loading = false;
            alert(error);
          });
      },
    getPDF(url, filters) {
      let vm = this;
      vm.loading = true;
      axios
        .post(url, filters)
        .then((response) => {
          if (response.status == 200) {
            let generatedReportUrl = response.data.data;
            axios
              .get(generatedReportUrl,{ responseType: 'blob' })
              .then((response) => {
                if (response.status == 200) {
                  vm.loading = false;
                  const blob = new Blob([response.data], { type: 'application/pdf' })
                  const link = document.createElement('a')
                  link.href = URL.createObjectURL(blob)
                  link.download = generatedReportUrl+".pdf"
                  link.click()
                  URL.revokeObjectURL(link.href)
                  // window.open(generatedReportUrl, "_blank") || window.location.replace(generatedReportUrl);
                } else {
                  vm.loading = false;
                  
                }
              })
              .catch((error) => {
                vm.loading = false;
                alert(error);
              });
          } else {
            vm.loading = false;
            
          }
        })
        .catch((error) => {
          vm.loading = false;
          alert(error);
        });
    },

    downloadPDF(url, filters, filename){
      let vm = this;
      vm.loading = true;
      axios.post(url, filters)
      .then((res) => {
        console.log(filters);
        const uninterceptedAxiosInstance = myaxios.create();
        uninterceptedAxiosInstance.get(res.data.data, { responseType: 'blob' })
        .then((response) => {
          vm.loading = false;
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = filename
          link.click()
          URL.revokeObjectURL(link.href)
        })
      }).catch((error) => {
        vm.loading = false;
        console.log('error: '+error);
      })
    }
  },
};
