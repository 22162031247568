<template>
  <div>
    <b-card-header>
      <h6>Habilitar documentos de venta</h6>
    </b-card-header>
    <b-card-body class="d-flex align-items-start flex-column">
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.use_budget" type="checkbox" class="md-check">
          Habilitar presupuestos
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.use_ordernote" type="checkbox" class="md-check">
          Habilitar notas de pedido
        </label>
      </div>
      <div class="checkbox">
        <label>
          <input v-model="newbo_options.use_shippingnote" type="checkbox" class="md-check">
          Habilitar remitos
        </label>
      </div>
    </b-card-body>

    <b-card-header>
      <h6>Habilitar documentos de compra</h6>
    </b-card-header>
    <b-card-body class="d-flex align-items-start flex-column">
        <div class="checkbox">
        <label>
          <input v-model="newbo_options.use_shippingpurchase" type="checkbox" class="md-check">
          Habilitar remitos de compra
        </label>
      </div>
    </b-card-body>
  </div>
</template>

<script>
export default {
  props: ['bo_options'],
  data: function() {
    return {
      newbo_options: {}
    }
  },
  mounted: function () {
    this.newbo_options = this.bo_options;
  },
  methods: {

  }
}
</script>
