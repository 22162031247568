import axios from '../../services/index.js';

export const getNotifications = ({ commit }) => {
  commit('setLoading', true);
  axios.post('/api/notifications/list')
    .then(response => {
      if(response.data.success) {
        commit('setNotifications', response.data.data)
      }
  })
  .catch(e => {
    commit('setClient', {})
  })
  .finally(() => {
    commit('setLoading', false);
  });
}

export const delNotification = ({ commit },id) => {
  commit('setLoading', true);
  axios.post('/api/notifications/delete/' + id)
    .then(response => {
      if(response.data.code == 201) {
        if(response.data.code == 201) {
          axios.post('/api/notifications/list')
          .then(response => {
            if(response.data.success) {
              commit('setNotifications', response.data.data)
            }
          })
        }
      }
  })
  .catch(e => {
    commit('setClient', {})
  })
  .finally(() => {
    commit('setLoading', false);
  });
}

