<template>
  <div class="card mb-4">
    <a
      type="button"
      class="btn btn-btn btn-light mb-2"
      v-if="coupon.id != null"
      @click="resetForm"
      >Nuevo cupón</a
    >
    <div class="card-header">
      <h6 v-if="coupon.id == null" class="card-title">
        Nuevo cupón de tarjeta
      </h6>
      <h6 v-else class="card-title">Editar cupón</h6>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-3">
          <label class="control-label mr-1" for="input-live">Numero:</label>
          <div class="form-body">
            <b-form-input
              class="form-control"
              id="input-live"
              v-model="coupon.number"
              :state="nameState"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="N° de cupón"
              trim
            />
          </div>  
        </div>
        <div class="col-12 col-md-3">
          <label class="control-label mr-1" for="input-live">Nombre del Emisor:</label>
          <div class="form-group">
            <b-form-input
              id="input-live"
              class="form-control"
              v-model="coupon.name"
              :state="nameState"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="Nombre del Emisor"
              trim
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <label class="control-label mr-1" for="input-live">Numero:</label>
          <div class="form-group">
            <b-form-input
              id="input-live"
              class="form-control"
              v-model="coupon.cuit"
              :state="nameState"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="CUIT"
              trim
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <label class="control-label mr-1" for="input-live">
              Banco:
            </label>
            <!-- <b-form-input
              id="input-live"
              class="form-control"
              v-model="coupon.bank_name"
              :state="nameState"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="Banco"
              trim
            /> -->
    
            <sucursales-bancarias 
              id="input-live"
              class="form-control"
              v-model="coupon.bank_name"
              aria-describedby="input-live-help input-live-feedback"/>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group ">
            <label class="control-label mr-1"
              >Cliente:</label
            >
            <typeahead
            class="mt-1" 
            :name="'client'"
            :placeh="'Busqueda de cliente por nombre'"
            :clean-on="false"
            v-on:choosed="clientUpdate"
            v-on:empty="clientEmpty"
            :default="clientname"
            :externalsrc="'/api/clients/getClients'"
            limit-chars="1"/>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <label class="control-label mr-1" for="input-live"
              >Monto:</label
            >
            <b-form-input
              class="form-control"
              id="input-live"
              v-model="coupon.amount"
              :state="nameState"
              aria-describedby="input-live-help input-live-feedback"
              placeholder="Monto"
              trim
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <label class="control-label mr-1" for="input-live"
              >Fecha de cobro:</label
            >
            <input
              id="datepicker-expire"
              type="date"
              name="date_due"
              class="form-control"
              v-model="coupon.date_due"
              placeholder="Fecha"
              required
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <b-form-textarea
              id="textarea"
              v-model="coupon.note"
              placeholder="Notas..."
              rows="2"
              cols="50"
              max-rows="6"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer text-right">
      <div class="form-actions pull-right">
        <a
          type="button"
          @click="saveCoupons()"
          class="btn btn-btn btn-orange btn-lg"
          href="#import_products"
          data-toggle="tab"
          >Guardar cupón</a
        >
      </div>
    </div>
  </div>
</template>


<script>
import datepickerComponent from "./../global/Datepicker-component.vue";
import formatDateMixin from "../../mixins/formatDateMixin";
import SucursalesBancarias from "./../sub-components/sucursalesBancarias.vue";
import typeahead from '../global/Typeahead.vue';


export default {
  components: {
    datepickerComponent,
    SucursalesBancarias,
    typeahead,
  },
    mixins: [formatDateMixin],
  watch: {
    couponId: function (value) {
      if (value != null) {
        this.getCoupon(value);
      } else {
        this.resetForm();
      }
    },
  },
  mounted() {
    this.getClients();
    this.resetForm();
    if (this.couponId != null) {
      this.getCoupon(this.couponId);
    } else {
      this.resetForm();
    }
  },
  methods: {
    validateForm() {
      if (this.coupon.number === null || isNaN(this.coupon.number)) {
        alert("Debe ingresar un numero de cheque");
        return false;
      }

      if (this.coupon.name === null || this.coupon.name === "") {
        alert("Debe ingresar un nombre ");
        return false;
      }

      if (this.coupon.cuit === null || isNaN(this.coupon.cuit)) {
        alert("Debe ingresar un nro de cuit ");
        return false;
      }

      if (this.coupon.bank_name == null || this.coupon.name === "") {
        alert("Debe ingresar el nombre del banco");
        return false;
      }

      if (this.coupon.date_due == null || this.coupon.date_due === "") {
        alert("Debe ingresar la fecha de cobro");
        return false;
      }

      if (this.coupon.amount == null || isNaN(this.coupon.amount)) {
        alert("Ingrese el monto a depositar");
        return false;
      }
      return true;
    },
    getCoupon(id) {
      var vm = this;
      this.modo = "editar";
      this.$http.get("/api/funds/coupons/get/" + id).then((response) => {
        vm.coupon = response.data.data;
        // Las siguientes 2 lineas pasan las fechas de dd/mm/yyyy a yyyy-mm-dd
        vm.coupon.date_issue = this.formatoFecha2Date(vm.coupon.date_issue);
        vm.coupon.date_due = this.formatoFecha2Date(vm.coupon.date_due);
        this.$http.get("/api/clients/getClient/"+ vm.coupon.client_id).then((res) => {
          vm.clientname = res.data.data.name;
        });
      });
    },

    saveCoupons() {
      var vm = this;
      if (!this.validateForm()) {
        return;
      }

      if (this.coupon.id != null) {
        const data = {
            ...(vm.coupon),
            date_issue: vm.formatoDate2Fecha(vm.coupon.date_issue),
            date_due: vm.formatoDate2Fecha(vm.coupon.date_due),
          };
        this.$http
          .post("/api/funds/coupons/edit/" + vm.coupon.id, data)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      } else {
        this.$http
          .post("/api/funds/coupons/add", vm.coupon)
          .then((response) => {
            
            vm.resetForm();
            vm.$emit("confirmsave");
          });
      }
    },
    clientUpdate(data) {
       this.coupon.client_id= data.id;
     },
     clientEmpty(){
      this.coupon.client_id=null;
     },
    dateUpdated: function (date) {
      var app = this;
      app.coupon.date_due = date;
    },

    getClients() {
      var vm = this;
      this.$http.get("/api/clients/accounts").then((response) => {
        vm.clients = response.data.data;
      });
    },

    resetForm() {
      this.coupon = Object.assign({}, this.emptyCoupon);
      this.clientname = null,
      this.$emit("reset-id");
    },
  },
  props: ["couponId"],

  computed: {
    nameState() {
      return this.product.name.length < 3 && this.product.name.length > 0
        ? false
        : null;
    },

    codigoValido() {
      return isNaN(this.product.barcode) ? false : null;
    },
  },

  data() {
    return {
      coupon: {
        number: null,
        name: "",
        cuit: null,
        bank_name: "",
        date_due: "",
        client_id: null,
        amount: null,
        note: "",
        
      },
      emptyCoupon: {
        number: null,
        name: "",
        cuit: null,
        bank_name: "",
        date_due: "",
        client_id: null,
        amount: null,
        note: "",
      },
      product: {
        id: null,
        name: "",
        barcode: null,
        provider: null,
        type: null,
        category_id: null,
        base_price: null,
        unit: null,
        stock_initial: null,
        stock_minimum: null,
        prices: {},
      },

      emptyProduct: {
        id: null,
        name: "",
        barcode: null,
        provider: null,
        type: "PRODUCT",
        category_id: null,
        base_price: null,
        unit: "U",
        stock_initial: null,
        stock_minimum: null,
        prices: {},
      },

      products: [],
      clients: [],
      clientname: null,

      selected: null,
    };
  },
};
</script>