<template>
  <div>
    <b-row class="my-2">
      <b-col cols="md-11">
        <receipt-add-product :discount_each_line="discount_each_line" v-if="showModal" @sendProduct='sendProduct' @close="showModal=false"/>
      </b-col>
      <b-col cols="md-11">
        <receipt-products-search separator="*" :priceList='priceList'
        :product_default="product_default" :show-barcodes="showBarcodes" @productadded="incrementOrAdd"/>
      </b-col>      
      <b-col cols="md-1">
        <v-guard :permissions="['VER_AGREGAR_ETIQUETA']">
        <b-button block
        class="mg-r"
        variant="info" 
        @click="showModal = true">
        <b-icon-pencil-square></b-icon-pencil-square>
        </b-button>
        </v-guard>
      </b-col>
    
    </b-row>
    <b-row>
      <b-col class="py-0">
        <b-table-simple striped small caption-top sticky-header="64vh" responsive="sm">
          <b-thead head-variant="light">
            <b-tr>
              <b-th v-if="showBarcodes" class="col-1"> Cod </b-th>
              <b-th class="col-2"> Item </b-th>
              <b-th class="col-1 col-cantidad"> Cantidad </b-th>
              <b-th v-if="showPrices" class="col-1"> Precio unitario </b-th>
              <b-th v-if="showPrices && discount_each_line" class="col-1 col-cantidad">Descuento</b-th>
              <b-th v-if="showPrices && disciva" class="col-1"> % IVA </b-th>
              <b-th v-if="showPrices" class="col-1">Total</b-th>
              <b-th class="col-1"><b-button variant="danger" @click="cleanAll()">Vaciar</b-button></b-th>
            </b-tr>
          </b-thead>
          <b-tbody v-if="products.length > 0">
            <b-tr v-for="(prod, index) in products.slice().reverse()" :key="prod.id">
              <!-- CODIGO -->
              <b-td v-if="showBarcodes">{{prod.code}}</b-td>
              <!-- NOMBRE -->
              <b-td>{{prod.name}}</b-td>
              <!-- CANTIDAD -->
              <b-td>
                <b-input-group>
                  <b-form-input :disabled="prod.id==0" v-model.number="prod.quantity" min="0" type="number">
                  </b-form-input>
                  <b-input-group-append>
                    <b-button variant="info" class="mr-2" style="z-index: -999999;">{{prod.unit}}</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-td>
  
              <!-- PRECIO UNITARIO -->
              <template v-if="showPrices">
  
                <template v-if="disciva">
                  <b-td v-if="allow_edit_price && prod.editable">
                    <b-input-group>
                      <b-input-group-addon>$</b-input-group-addon>
                      <b-form-input v-model.number="prod.gross" min="0" class="form-control input-sm quantity" type="number"></b-form-input>
                    </b-input-group>
  
                  </b-td>
                  <b-td v-else>
                    {{formatoDinamicoMoneda(prod.gross)}}
                  </b-td>
                </template>
  
                <template v-else>
                  <b-td v-if="allow_edit_price && prod.editable">
                    <b-input-group prepend="$">
                      <input v-model.number="prod.price" min="0" class="form-control input-sm quantity" type="number">
                    </b-input-group>
                  </b-td>
                  <b-td v-else>
                    {{formatoDinamicoMoneda(prod.price)}}  
                  </b-td>
                </template>
                
              </template>
  
              <!-- DESCUENTO -->
              <b-td v-if="discount_each_line">
                <b-input-group>
                  <input v-model.number="prod.discount" min="0" class="form-control input-sm quantity" type="number">
                  <b-input-group-append>
                    <b-button style="z-index: -999999;">%</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-td>
  
              <!-- IVA -->
              <b-td v-if="showPrices && disciva">({{prod.taxtype}})</b-td>
  
              <!-- TOTAL -->
              <b-td v-if="showPrices">{{calcItemTotal(prod)}}</b-td>
              
              <!-- BTN BORRAR -->
              <b-td>
                <span style="cursor: pointer;" @click="deleteproduct(index)" aria-hidden="true" title="Borrar producto">
                  <button class="btn btn-outline-danger"><i class="bi bi-dash-circle"></i></button>
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-col class="d-flex justify-content-center align-items-center emptyP py-0" style="height: 54vh;"  v-if="products.length<1">
          <h5 class="font-weight-bold">
            Todav&iacute;a no hay productos. Busc&aacute; con la lupa o ingres&aacute; el c&oacute;digo mas arriba
          </h5> 
        </b-col>
      </b-col>
    </b-row>



  </div>
</template>

<script>
import formatMoneyMixin from "../../../../mixins/formatMoneyMixin";
import receiptProductsSearch from './Receipt-products-search.vue';
import receiptAddProduct from './Receipt-addProduct.vue';

export default {
  props: [
    'showPrices',
    'config',
    'prexistprd',
    'showBarcodes',
    'keepItems',
    'product_default',
    'disciva',
    'allow_sale_without_stock',
    'allow_edit_price',
    'discount_each_line',
    'discount_each_line_type',
    'priceList',
    'productsAux'],
    mixins: [formatMoneyMixin],
  data: function() {
    return {
      prex: this.prexistprd,
      customlabel: false,
      products: JSON.parse(localStorage.getItem('products')) || [],
      showModal: false
    };
  },
  watch: {
    products: {
      handler: function() {
        for (var i = 0; i < this.products.length; i++) {
          if (typeof this.products[i].quantity == 'number' && this.products[i].id!=0) {
            if (this.products[i].quantity<0) this.products[i].quantity=0;
            this.products[i].quantity=(this.products[i].unit!='U') ? this.products[i].quantity : this.round(this.products[i].quantity,0);
            if ((this.products[i].quantity>this.products[i].stock) && (this.allow_sale_without_stock!=1)) {
              alert('Este producto tiene '+this.products[i].stock+((this.products[i].unit!='U') ? ' '+this.products[i].unit : ' unidades')+', no deberias vender mas de esta cantidad')
              this.products[i].quantity=this.products[i].stock;
            }
          }
          if(this.discount_each_line){
            if (typeof this.products[i].discount == 'number') {
              if(this.discount_each_line_type !== undefined && this.discount_each_line_type == 'amount'){
                if(this.products[i].discount>this.products[i].price) this.products[i].discount = this.products[i].price;
                if(this.products[i].discount<0) this.products[i].discount=0;
              } else {
                if(this.products[i].discount>100) this.products[i].discount = 100;//TODO que pasa si tengo que discriminar iva?
                if(this.products[i].discount<0) this.products[i].discount=0;
              }
            }
          }else{
            this.products[i].discount=0;
          }
        }
        this.$emit('productschanged', this.products);
      }, deep: true,
    },
    priceList(){
      let newProduct = [];
      let app=this;
      if(app.keepItems!=true){
        app.productsAux.forEach( element => {
          window.axios.get('/api/products/getProduct/'+element.id+'/'+app.priceList)
            .then(response => {
              response.data.data.quantity = element.quantity;
              response.data.data.price = parseFloat(response.data.data.price);
              newProduct.push(response.data.data);
          });
          app.products = newProduct;
        });
      }
    }
  },
  mounted: function () {
    this.customlabel = this.config.customlabel
    if (this.prex) {
      for (var i = 0; i < this.prexistprd.length; i++) {
        this.products.push(this.prex[i]);
      }
      this.prex=null;
      this.$emit('productschanged', this.products);
    }
  },
  methods: {
    deleteproduct: function(key) {
      this.products.splice(this.products.length - 1 - key, 1);
    },
    round: function(value, digits) {
        const factor = Math.pow(10, digits);
        return Math.ceil(value * factor) / factor;
    },
    sendProduct (product) {
      this.$emit('sendProduct', product);
      this.showModal = false;
    },
    incrementOrAdd: function(item) {
      item.price = parseFloat(item.price);

      for (var i = 0; i < this.products.length; i++) { // Suma la cantidad nueva al producto
      
        if (this.products[i].id === item.id) {
          this.products[i].quantity = this.products[i].quantity + item.quantity;
          this.products[i].total = this.products[i].quantity * this.products[i].price;
          return;
        }

      }

      this.products.push(item);
    },
    cleanAll(){
      if(confirm("Esta seguro que desea borrar todos los productos?") == true){
        this.products=[];
      }
    },
    calcItemTotal(prod){
      let total = 0;
      if(this.disciva !== undefined && this.disciva == true)
      {
        if(this.discount_each_line_type == 'amount')
          total = this.formatoDinamicoMoneda(prod.quantity * (prod.gross - prod.discount));
        else
          total = this.formatoDinamicoMoneda(prod.quantity * (prod.gross - ((prod.discount*prod.gross)/100)));
      } else {
        if(this.discount_each_line_type == 'amount')
          total = this.formatoDinamicoMoneda(prod.quantity * (prod.price - prod.discount),2);
        else
          total = this.formatoDinamicoMoneda(prod.quantity * (prod.price - ((prod.discount*prod.price)/100)));
      }
      
      return total;
    }
  },
  components: {
    receiptProductsSearch,
    receiptAddProduct
  }

}

</script>

<style scoped>
.emptyP {
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 20px;
  top: -10px;
}

@media (max-width: 576px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .col-cantidad {
  min-width: 120px;
  }
  .mg-r {
    margin-top: 6px;
  }

  .table td,
  .table th {
    white-space: nowrap;
  }
}
</style>


