<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Cuenta corriente de proveedores</h4>
      </b-col>
    </div>
    <!------------ HEADER ------------------------------>
    <b-row class="stats text-center">
      <b-col cols="lg-12 text-center">
        <div class="stat">
          <div class="number">{{  formatoDinamicoMoneda(generalBalance)  }}</div>
          <div class="desc"> Saldo acumulado de cuentas corrientes </div>
        </div>
      </b-col>
    </b-row>
    <!------------ BUSCADOR --------------------------->         
    <b-row class="d-flex justify-content-between"
      @submit.stop.prevent="doSearch">
      <b-col cols="md-2 text-left">
        <b-input-group>
          <input
            v-model="doSearch"
            type="search"
            placeholder="Buscar..."
            class="form-control"
          >
        </b-input-group>
      </b-col>
      <b-col class="form-inline d-flex justify-content-end">          
        <input v-model="onlymovs" id="onlymovs" type="checkbox">
        <label for="onlymovs">&nbsp;Mostrar sólo proveedores con movimientos.</label>
      </b-col>
      <b-col class="form-inline d-flex justify-content-end">          
        <input v-model="withzero" id="withzero" type="checkbox">
        <label for="withzero">&nbsp;Mostrar proveedores con saldo cero.</label>
      </b-col>    
    </b-row>

    <!------------ TABLA --------------->
    <b-row>
      <b-col cols="md-12">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox=0
          @viewcc="viewCC"        
          ref="table"        
        />
      </b-col>
    </b-row> 
  </b-container>
</template>

<script>

import VdtnetTable from 'vue-datatables-net';
import formatDateMixin from "../../../mixins/formatDateMixin";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";

export default {
    components: {
      VdtnetTable,
    },
    mixins: [formatDateMixin,formatMoneyMixin],
    data: function() {
      return {
        doSearch: '',
        onlymovs: false,
        withzero: false,
        opts: {
          language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
          'ajax': {                
            'url': process.env.VUE_APP_BASE_URL+"/api/providers/accounts",  
            'data': (d) => {
              d.onlymovs = this.onlymovs,
              d.withzero = this.withzero       
            },          
            'type': 'GET',
            'headers': { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
          },
          processing: true,
          pageLength: 15,
          searching: true,
          searchDelay: 100,
          destroy: true,
          ordering: true,
          lengthChange: true,
          serverSide: true,
          fixedHeader: true,

        },
        fields: {
          name: { label: "Proveedor"},
          phone_1: { label: "Teléfono"},
          balance: { label: "Saldo actual", render: (data) => {
            if(data >= 0) {                
              return '<div style="color: green"> ' + this.formatoDinamicoMoneda(data) +'</div>'
            }
            if(data < 0) {
              return '<div style="color: red"> ' + this.formatoDinamicoMoneda(data) +'</div>'
            }
          }},
          cuit: { label: "Cuit"},
          actions: {
            isLocal: true,
            label: 'Detalle',
            width: '40px',
            defaultContent: '<div class="divActions btn-group-sm">' +
                              '<button data-action="viewcc" data-toggle="tooltip" title="Ver detalle completo" type="button" class="btn btn-light marginButton">Ver detalle</button>' +
                            '</div>'
          },
        },
        account: {
          id: null,
          name: "",
          balance: null
        },
        emptyAccount: {
          id: null,
          name: "",
          balance: null
        },      
        accounts: [],
        generalBalance: null,
    }},
    watch: {
      onlymovs() {
        this.$refs.table.reload()
      },
      withzero() {
        this.$refs.table.reload()
      },
      doSearch(newValue, oldValue) {
        if(newValue.length >= 4 || oldValue.length >= 4) {
          this.$refs.table.search(this.doSearch)
        }
        if(newValue === '' && oldValue.length > 0) {
          this.$refs.table.search(this.doSearch)
        }
      },      
    },
    mounted() {
      this.getGeneralBalance();
    },
    methods: {
      refreshTable() {
        var vm = this;
        if (vm.$refs.table !== undefined) vm.$refs.table.reload();
      },   
      viewCC (data) {
        this.$router.push({name: 'Detalle de cuenta corriente de proveedor', params: { providerId: data.id }})
      },
      getGeneralBalance() {
        var vm = this;
        vm.loading = true        
        vm.$http.get("/api/providers/getGeneralBalance").then((response) => {
          let num = response.data.data
          vm.generalBalance = num.toFixed(2).replace(".",",")
          vm.loading = false
        });
      },
    }
  }
</script>