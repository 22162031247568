import EmptyEcommerce from '../components/ecommerce/EmptyEcommerce.vue';

import Ordernotes from '../components/ecommerce/Ordernotes.vue';
import Users from '../components/ecommerce/Users.vue';
import OrderDetail from '../components/ecommerce/Detalle.vue';
import Catalogos from '../components/ecommerce/catalogos/Catalogs.vue';
import NuevoCatalogo from '../components/ecommerce/catalogos/Edit-catalog.vue';


const ecommerce = {
    path: '/ecom',
    meta: {
        requiresAuth: true
    },
    component: EmptyEcommerce,
    children:
    [
        { path: '', redirect: 'ecom/list' },
        { path: 'list', component: Ordernotes, name: 'Pedidos Ecommerce' },
        { path: 'order/:orderId', props: true,component: OrderDetail, name: 'Pedido Ecommerce' },
        { path: 'users', component: Users, name: 'Usuarios Ecommerce' },
        { path: 'catalogos', component: Catalogos, name: 'Catalogos' },
        { path: 'catalogos/nuevo', component: NuevoCatalogo, name: 'Nuevo catalogo' },
        { path: 'catalogos/editar-catalogo/:sourceid', component: NuevoCatalogo, name: 'Editar catalogo', props: true },
    ],
};

export default ecommerce;
