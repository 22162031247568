import moment from 'moment';


const formatoDate2Fecha = (date) => {
  return moment(date).format('DD/MM/YYYY');
};
const formatoFecha2Date = (fecha) => {
  return moment(fecha, 'DD/MM/YYYY').format('YYYY-MM-DD');
}
const formatoFecha2Daterange = (fecha) => {
  return moment(fecha, 'DD/MM/YYYY').format('DD-MM-YYYY');
}
const formatoFechaHora = (date) =>{
  return moment(date).format('DD/MM/YYYY HH:mm:ss');
}
export default {
  filters: {
    formatoDate2Fecha,
    formatoFecha2Date,
    formatoFecha2Daterange,
    formatoFechaHora,
  },
  methods: {
    formatoDate2Fecha,
    formatoFecha2Date,
    formatoFecha2Daterange,
    formatoFechaHora,
  },
}