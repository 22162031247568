<template >
  <div class="row">
    <div class="col-sm-12">
      <div v-if="typeah==false" class="input-group" style="margin:0;">
        <div class="input-group-btn">
          <button class="btn btn-light" @click="typeah=true"><i class="glyphicon glyphicon-search"></i></button>
        </div>
        <input class="form-control input input-search" type="text" v-model="code" v-on:keypress="lookupProducts" placeholder="Ingresa cod. de producto. Podes ingresar una cantidad. Por Ej. 3*1001">
        <div class="input-group-btn">
          <button type="button" class="btn btn-block btn-orange" @click="addProductByCode">Agregar producto</button>
        </div>
      </div>
      <!--
      <div v-else>
        <button class="btn btn-md btn-light" @click='showButton'>Atras</button>
      </div>
      -->
      <typeahead
      :name="'product'"
      class="m-t-5"
      :placeh="'Buscar producto por nombre'"
      :custom-class="[]"
      :clean-on="true"
      v-on:choosed="productChoosed"
      :default="product_default"
      v-if="typeah==true"
      :externalsrc="'/api/products/getProducts'"
      limit-chars="1"/>
    </div>

  </div>
</template>

<script>
  import typeahead from '../../../global/Typeahead.vue';


  export default {
    props: ['separator', 'showBarcodes', 'product_default'],
    data: function() {

      return {
        code: '',
        id_code: null,
        typeah: !this.showBarcodes
      }
    },
    methods: {
      productChoosed(data){
        this.togglet();
        this.id_code = data.id;
        this.addProduct();
      },
      lookupProducts: function(e) {
        var app = this;
        if (e.key === 'Enter' ) {
          app.addProductByCode();
        }
      },
      addProductByCode: function(e){
        var app = this;

        var code = app.code.split(app.separator);
        var qu=(code.length !== 1) ? parseInt(code[0]) : 1;
        var co=(code.length !== 1) ? code[1] : code;

        if (co!=null && co>0) {
        axios.get('/api/products/getProductByCode/'+co+'/0').then(function(response) {
          response.data.data.quantity = qu;
          app.code='';
          app.$emit('productadded',response.data.data);
        });
        }
      },
      addProduct: function(e){
        var app = this;

        if (app.id_code!=null && app.id_code>0) {
        axios.get('/api/products/getProduct/'+app.id_code+'/0').then(function(response) {
          response.data.data.quantity = 1;
          app.$emit('productadded',response.data.data);
        });
        app.id_code = null;
        }
      },
      togglet: function(){
        var app = this;
        if (app.showBarcodes) app.typeah=!app.typeah;
      },
      showButton(){
        this.typeah = false;
      }
    },
    components: {
      typeahead
    },
  }
</script>

<style scoped>
  .input-group {
    margin:5px 0 15px;
  }
</style>
