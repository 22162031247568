
import EmptyDashboard from '../components/home/EmptyDashboard.vue';

import DashboardPanel from '../components/home/DashboardPanel.vue';

const dashboard = {
    path: '/',
    meta: {
      requiresAuth: true
    },
    component: EmptyDashboard,
    // se crea un componente vacio para que funcione bien, siguiendo esta guia https://dev.to/berniwittmann/my-approach-on-vue-router-architecture-and-nested-routes-2kmo
    children: [
      { path: '', component: DashboardPanel, name: 'Inicio' },
    ],
};

export default dashboard;