import EmptyCompras from '../components/compras/EmptyCompras.vue';

import IndexCompras from '../components/compras/index/Index-compras.vue';
import NuevaCompra from '../components/compras/index/nueva-compra/Index-nueva-compra.vue';
import DetalleCompra from '../components/compras/index/Detalle-compra.vue';
import RemitosDeCompra from '../components/compras/remitos/Remitos-de-compra.vue';
import NuevoRemitoDeCompra from '../components/compras/remitos/Nuevo-remito-de-compra.vue';
import Transportes from '../components/compras/transportes/Transportes.vue';
import DetalleRemitoDeCompra from '../components/compras/remitos/Detalle-remito-de-compra.vue';
import Proveedores from '../components/compras/proveedores/Proveedores.vue';
import NuevoProveedor from '../components/compras/proveedores/Nuevo-proveedor.vue';
import CCProveedores from '../components/compras/cc-proveedores/CC-proveedores.vue';
import DetalleCCProveedor from '../components/compras/cc-proveedores/Detalle-cc-proveedor.vue';
import PagoProveedores from '../components/compras/pago-proveedores/Pagos.vue';
import DetalleOrdenDePago from '../components/compras/pago-proveedores/Detalle-orden-de-pago.vue';
import DetalleImputacionProveedor from '../components/compras/pago-proveedores/Detalle-imputacion-proveedor.vue';

const purchases = {
    path: '/compras',
    meta: {
      requiresAuth: true
    },
    component: EmptyCompras,
    children:
      // se crea un componente vacio para que funcione bien, siguiendo esta guia https://dev.to/berniwittmann/my-approach-on-vue-router-architecture-and-nested-routes-2kmo
      [
        { path: '', redirect: '/compras/index' },
        { path: 'index', component: IndexCompras, name: 'Compras'},
        { path: 'index/nueva-compra', component: NuevaCompra, name: 'Nueva compra' },
        { path: 'index/detalle/:purchaseId', component: DetalleCompra, name: 'Detalle de compra', props: true },
        { path: 'remitos', component: RemitosDeCompra, name: 'Remitos de compra' },
        { path: 'transportes', component: Transportes, name: 'Transportes' },
        { path: 'remitos/nuevo', component: NuevoRemitoDeCompra, name: 'Nuevo remito de compra' },
        { path: 'remitos/detalle/:shippingPurchaseId', component: DetalleRemitoDeCompra, name: 'Detalle de remito de compra', props: true},
        { path: 'proveedores', component: Proveedores, name: 'Proveedores' },
        { path: 'proveedores/nuevo', component: NuevoProveedor, name: 'Nuevo proveedor' },
        { path: 'cc-proveedores', component: CCProveedores, name: 'Cuenta corriente de proveedores' },
        { path: 'cc-proveedores/detalle/:providerId', component: DetalleCCProveedor, name: 'Detalle de cuenta corriente de proveedor', props: true },
        { path: 'pago-a-proveedores', component: PagoProveedores, name: 'Pago a proveedores' },
        { path: 'detalle-orden-de-pago/:payOrderId', component: DetalleOrdenDePago, name: 'Detalle de orden de pago', props: true },
        { path: 'detalle-imputacion-proveedor/:allocationId', component: DetalleImputacionProveedor, name: 'Detalle de imputacion de proveedor', props: true },
      ],
};

export default purchases;
