<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Cuenta corriente de clientes</h4>
      </b-col>
    </div>
    <b-row class="stats text-center">
      <b-col cols="lg-12">
        <div class="stat">
          <div class="number">{{ formatoDinamicoMoneda(generalBalance) }}</div>
          <div class="desc"> Saldo acumulado de cuentas corrientes </div>
        </div>
      </b-col>
    </b-row>
    
    <b-row class="d-flex justify-content-between"
    @submit.stop.prevent="doSearch">
      <b-col cols="md-2 mb-2">          
          <div class="input-group">
            <input
              v-model="doSearch"
              type="search"
              placeholder="Buscar..."
              class="form-control"
            >
          </div>
      </b-col>     
    
      <b-col class="form-inline d-flex justify-content-end">          
        <input v-model="onlymovs" id="onlymovs" type="checkbox">
        <label for="onlymovs">&nbsp;Mostrar sólo clientes con movimientos.</label>
      </b-col>
      <b-col class="form-inline d-flex justify-content-end">          
        <input v-model="withzero" id="withzero" type="checkbox">
        <label for="withzero">&nbsp;Mostrar clientes con saldo cero.</label>
      </b-col>
    </b-row>     
    <b-row>   
      <vdtnet-table
        class="col-md-12"
        :fields="fields"
        :opts="opts"
        :selectCheckbox=0
        @viewcc="viewCC"        
        ref="table"        
      />
    </b-row> 
  </b-container>
</template>

<script>

import VdtnetTable from 'vue-datatables-net';
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";

export default {
    components: {
      VdtnetTable,
    },
    mixins: [formatMoneyMixin],
    data: function() {
      return {
        doSearch: '',
        onlymovs: false,
        withzero: false,
        clientIdParent: null,
        opts: {
          language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
          'ajax': {                
            'url': process.env.VUE_APP_BASE_URL+"/api/clients/accounts",  
            'data': (d) => {
              d.onlymovs = this.onlymovs,
              d.withzero = this.withzero
            },          
            'type': 'GET',
            'headers': { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
          },
          processing: true,
          pageLength: 8,
          searching: true,
          searchDelay: 100,
          destroy: true,
          ordering: true,
          lengthChange: true,
          serverSide: true,
          fixedHeader: true,    
        },
        fields: {
          name: { label: "Cliente"},
          phone_2: { label: "Teléfono celular"},
          balance: { label: "Saldo actual", render: (data) => {
           
           if(data >= 0) {                
             return '<div style="color: green">' + this.formatoDinamicoMoneda(data) +'</div>'
           }
           if(data < 0) {
             return '<div style="color: red">' + this.formatoDinamicoMoneda(data) +'</div>'
           }
         }
        
        },
          cuit: { label: "CUIT"},
          actions: {
            isLocal: true,
            label: 'Detalle',
            width: '40px',
            defaultContent: '<div class="divActions btn-group-sm">' +
                            '<button data-action="viewcc" data-toggle="tooltip" title="Ver detalle completo" type="button" class="btn btn-light marginButton">Ver detalle</button>' +
                            '</div>'
          },
        },
        account: {
          id: null,
          name: "",
          balance: null
        },
        emptyAccount: {
          id: null,
          name: "",
          balance: null
        },      
        accounts: [],
        generalBalance: null,
    }},
    watch: {
      onlymovs() {
        this.$refs.table.reload()
      },
      withzero() {
        this.$refs.table.reload()
      }, 
      doSearch(newValue, oldValue) {
        if(newValue.length >= 4 || oldValue.length >= 4) {
          this.$refs.table.search(this.doSearch)
        }
        if(newValue === '' && oldValue.length > 0) {
          this.$refs.table.search(this.doSearch)
        }
      },      
    },
    mounted() {
      this.getGeneralBalance();
    },
    methods: {
      getAccounts() { 
        var vm = this;
        vm.loading = true        
          this.$http.post("/api/clients/accounts", this.onlymovs).then((response) => {
          console.log(response.data)
          vm.accounts = response.data.data;
          vm.loading = false
        });
      },        
      viewCC (data) {
        this.$router.push({name: 'Detalle de cuenta corriente de cliente', params: { clientId: data.id }})
      },
      getGeneralBalance() {
        var vm = this;
        vm.loading = true        
          this.$http.get("/api/clients/getGeneralBalance").then((response) => {
          let num = response.data.data
          vm.generalBalance = num.toFixed(2).replace(".",",")
          vm.loading = false
        });
      },
    }
  }
</script>