<template>
  <div class="form-group">
      <date-picker v-on:change="dateUpdated" :date="startTime" :option="option"/>
  </div>
</template>
<script>
import myDatepicker from 'vue-datepicker'
import moment from 'moment';

export default {
  props:{
    date: {
      time: '' // string
    }
  },
  data () {
    return {
      dateLabel : '',
      startTime: {
        time: moment(new Date()).format('DD/MM/YYYY')
      },
      endtime: {
        time: ''
      },
      option: {
        type: 'day',
        week: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
        month: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        format: 'DD/MM/YYYY',
        placeholder: '',
        inputStyle: {
          'padding': '6px 3px',
          'border': '1px solid #c2cad8',
          'width':'100%',
          'border-radius':'4px'
        },
        buttons: {
          ok: 'Ok',
          cancel: 'Cancelar'
        },
        overlayOpacity: 0.5, // 0.5 as default
        dismissible: true // as true as default
      },
      timeoption: {
        type: 'min',
        week: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
        month: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        format: 'YYYY-MM-DD HH:mm'
      },
      multiOption: {
        type: 'multi-day',
        week: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        month: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        format:"YYYY-MM-DD HH:mm"
      },
    }
  },
  methods: {
    dateUpdated: function (date) {
      var app = this;
      app.$emit('datechoosed',date);
    }
  },
  mounted: function () {
    this.dateUpdated(moment(new Date()).format('DD/MM/YYYY'));
  },
  components: {
    'date-picker': myDatepicker
  }
}
</script>
